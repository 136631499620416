import { useRef, useState } from 'react';

/**
 * Hook for controlling starting and stopping animation frame in react
 *
 * @param {Func} onUpdate
 * @returns {Object}
 * {
 *   controls: {
 *     start: Func, - starts the animation
 *     stop: Func, - stops the animation
 *   }
 *   id: Number, - the reference to the animation frame request
 * }
 */
export default (
  onUpdate: (delta: number) => void
): {
  controls: {
    start: () => void;
    stop: () => void;
  };
  isPlaying: boolean;
  id?: number;
} => {
  const [playing, setPlaying] = useState(false);
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = useRef<number>();
  const previousTimeRef = useRef<number>();

  const animate = (time: number) => {
    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;
      onUpdate(deltaTime);
    }

    previousTimeRef.current = time;

    if (requestRef.current) {
      requestRef.current = requestAnimationFrame(animate);
    }
  };

  const start = () => {
    // Cancel the current animationFrame
    if (requestRef.current) cancelAnimationFrame(requestRef.current);
    requestRef.current = requestAnimationFrame(animate);
    setPlaying(true);
  };

  const stop = () => {
    if (requestRef.current) cancelAnimationFrame(requestRef.current);

    // Reset the previousTimeRef
    previousTimeRef.current = undefined;

    // Stop the animation loops
    requestRef.current = undefined;
    setPlaying(false);
  };

  return {
    controls: { start, stop },
    isPlaying: playing,
    id: requestRef.current,
  };
};
