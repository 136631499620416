import PropTypes from 'prop-types';

const { shape, string, number } = PropTypes;

export default shape({
  taskID: number,
  userID: number,
  data: shape({
    text: string,
    notes: string,
    dueDate: string,
    projectID: number,
    creatorUserID: number,
    completedOn: string,
  }),
  status: number,
});
