import React, { CSSProperties, ReactNode } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Box from './Box';

type ContainerProps = {
  backgroundColor?: string;
  color?: string;
};

const Container = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  background-color: ${(props: ContainerProps) =>
    props.backgroundColor || 'black'};
  color: ${(props) => props.color || 'white'};
  border-radius: 100%;
  font-size: 16px;
`;

type Props = {
  style: CSSProperties;
  size?: number;
  children: ReactNode;
};

const Circle = (props: Props) => {
  const { style, size, children } = props;

  return (
    <Container style={{ height: size, width: size, ...style }}>
      {children}
    </Container>
  );
};

const { shape, number } = PropTypes;

Circle.propTypes = {
  style: shape({}),
  size: number,
};

Circle.defaultProps = {
  style: {},
  size: 25,
};

export default Circle;
