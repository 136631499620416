import React, { useState, useMemo, MutableRefObject } from 'react';

import dPathParse from 'd-path-parser';

import { getSvgCoords } from '../../app/editor/coords';
import pathUtil from '../../app/editor/path';

import PenPathEditor from './path/PenPathEditor';

import { MixedElementProps } from './types/ElementProps';
import { addAnimation, animations } from '../../app/editor/animations';

type Props = {
  element: MixedElementProps;
  svgRef: MutableRefObject<SVGElement | null>;
  currentTime: number;
  handleUpdateElement: (elementIds: string[]) => void;
};

const AnimationPathEditor = (props: Props) => {
  const { element, currentTime, svgRef, handleUpdateElement } = props;

  const [, update] = useState({});

  const { animationPathPoint } = element.currentProps;
  const animationPathX = animationPathPoint?.x || 0;
  const animationPathY = animationPathPoint?.y || 0;

  const parsedPath = useMemo(() => {
    return element.animationPathD
      ? dPathParse(element.animationPathD)
      : dPathParse('M0,0');
  }, [element.animationPathD]);

  const dString = pathUtil.getDString(parsedPath);

  const handlePenPathMove = (e: React.MouseEvent) => {
    const coords = svgRef.current ? getSvgCoords(svgRef.current, e) : null;

    const { scaleX, scaleY, translateX, translateY } = element.currentProps;

    const newTranslateX = translateX - animationPathX;
    const newTranslateY = translateY - animationPathY;

    const newParsedPath = pathUtil.translateControlPoints(
      coords,
      parsedPath,
      parsedPath.length - 1,
      { scaleX, scaleY, translateX: newTranslateX, translateY: newTranslateY },
      true
    );

    const newD = pathUtil.getDString(newParsedPath);
    element.update('animationPathD', newD);

    update({});
  };

  const handlePenPathDown = (e: React.MouseEvent) => {
    const { scaleX, scaleY, translateX, translateY } = element.currentProps;
    const coords = svgRef.current ? getSvgCoords(svgRef.current, e) : null;

    const newTranslateX = translateX - animationPathX;
    const newTranslateY = translateY - animationPathY;

    const newCommand = pathUtil.addPoint(coords, parsedPath, undefined, {
      scaleX,
      scaleY,
      translateX: newTranslateX,
      translateY: newTranslateY,
    });
    element.update('animationPathD', dString + newCommand);

    // If the element doesn't already have a path keyframe group, add one
    if (!element.keyframes.path?.length) {
      const pathAnimation = animations.find((a) => a.id === 'follow-path');
      if (pathAnimation) {
        addAnimation(pathAnimation, [element], currentTime);

        handleUpdateElement([element.id]);
      }
    }

    update({});
  };

  return (
    <g>
      <g
        transform={`translate(${-animationPathPoint?.x} ${-animationPathPoint?.y})`}
      >
        <PenPathEditor
          d={dString}
          svgRef={svgRef}
          pathData={parsedPath}
          onMouseMove={handlePenPathMove}
          onMouseDown={handlePenPathDown}
          isNew={parsedPath.length === 2}
        />
      </g>
    </g>
  );
};

export default AnimationPathEditor;
