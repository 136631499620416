import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components/macro';

import { motion, AnimatePresence } from 'framer-motion';

import useKeypress from '../app/hooks/useKeypress';

import Button from './Button';
import Box from './Box';

import { colors, fade } from './styles/colors';

type Props = {
  children?: ReactNode;
  isVisible: boolean;
  hasHeader?: boolean;
  headerColor?: string;
  headerBackground?: string;
  headerContent?: string;
  darkMode?: boolean;
  handleHideDialog: () => void;
  onEnter?: () => void;
};

const Dialog = (props: Props) => {
  const {
    children,
    isVisible,
    hasHeader,
    headerColor,
    headerBackground,
    headerContent,
    darkMode,
    handleHideDialog,
    onEnter,
  } = props;

  // Call enter on enter
  useKeypress('enter', onEnter);
  // Close on escape
  useKeypress('escape', handleHideDialog);

  return (
    <Container isVisible={isVisible}>
      <AnimatePresence>
        {isVisible && (
          <Background
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            key="background"
          />
        )}
        {isVisible && (
          <ContentContainer
            initial={{ opacity: 0, y: 40, scale: 0.75 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 20, transition: { easing: 'easeOut' } }}
            key="content"
            onClick={handleHideDialog}
          >
            <ContentDialog
              onClick={(e) => e.stopPropagation()}
              darkMode={!!darkMode}
            >
              {hasHeader && (
                <DialogHeader
                  background={headerBackground || colors['light-grey-50']}
                  color={headerColor || colors.grey}
                >
                  {headerContent}
                  <Button
                    onClick={handleHideDialog}
                    color={headerColor}
                    box
                    transparent
                    icon
                  >
                    <FontAwesomeIcon icon={['fas', 'times']} />
                  </Button>
                </DialogHeader>
              )}
              <Box style={{ padding: 30 }}>{children}</Box>
            </ContentDialog>
          </ContentContainer>
        )}
      </AnimatePresence>
    </Container>
  );
};

type ContainerProps = {
  isVisible: boolean;
};

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  z-index: 2;

  pointer-events: none;

  ${(props: ContainerProps) =>
    props.isVisible &&
    css`
      pointer-events: initial;
    `}
`;

type DialogHeaderProps = {
  background: string;
  color: string;
};
const DialogHeader = styled.div`
  padding: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top-right-radius: 5px;
  border-top-left-radius: 5px;

  background-color: ${(props: DialogHeaderProps) => props.background};
  color: ${(props: DialogHeaderProps) => props.color};
`;

const Background = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  z-index: 2;

  background: ${fade('black', 50)};
`;

const ContentContainer = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;
`;

type ContentDialogProps = {
  darkMode: boolean;
};
const ContentDialog = styled.div`
  background: white;
  border-radius: 5px;

  min-width: 350px;
  max-width: 50%;

  ${(props: ContentDialogProps) =>
    props.darkMode &&
    css`
      background-color: ${colors.night};
    `}
`;

export default Dialog;
