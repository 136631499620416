import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

import React, { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import Button from '../RebassButton';
import Dialog from '../Dialog';
import EditProjectName from '../dialogs/EditProjectName';

import { HeaderSection, SectionTitle } from '../views/ProjectContentsStyles';
import { post } from '../../util/fetchUtil';
import { ProjectProps } from '../editor/types/ProjectProps';
import Box, { Flex } from '../Box';
import Label from '../Label';
import { isAllowed } from '../../shared/grants';
import UserProps from '../../types/UserProps';

type Props = {
  activeProject: ProjectProps;
  user: UserProps;
};

function NameSection(props: Props) {
  const {
    activeProject: { projectID, name },
    user,
  } = props;

  const [status, setStatus] = useState();
  const [editNameDialogVisible, setEditNameDialogVisible] = useState(false);
  const history = useHistory();

  const getNodeData = useCallback(async () => {
    if (projectID) {
      const res = await post('/osmosis/getLinkedStatus', {
        projectID,
      });

      if (res?.status) {
        setStatus(res.status);
      }
    }
  }, [projectID]);

  useEffect(() => {
    console.log('running again');
    if (projectID) getNodeData();
  }, [projectID, getNodeData]);

  return (
    <Container>
      <Dialog
        handleHideDialog={() => setEditNameDialogVisible(false)}
        isVisible={editNameDialogVisible}
      >
        <EditProjectName
          handleHideDialog={() => setEditNameDialogVisible(false)}
        />
      </Dialog>
      <HeaderSection>
        <SectionTitle>Project Name</SectionTitle>
      </HeaderSection>
      <ProjectNameContainer>
        <Name>{name}</Name>

        <ButtonContainer>
          <Button
            onClick={() => setEditNameDialogVisible(true)}
            variant="transparent"
            color="dark-7"
            size="s"
          >
            <FontAwesomeIcon icon={['fad', 'edit']} />
          </Button>
        </ButtonContainer>
      </ProjectNameContainer>

      {typeof status !== 'undefined' && (
        <Box mt={2}>
          {status === 1 && (
            <Label color="green" round>
              <Flex>
                <Box mr={2}>
                  <FontAwesomeIcon icon={['fas', 'check']} />
                </Box>
                Live on Osmosis
              </Flex>
            </Label>
          )}
          {status === 0 && (
            <Label round>
              <Flex>
                <Box mr={2}>
                  <FontAwesomeIcon icon={['fas', 'check']} />
                </Box>
                Draft on Osmosis
              </Flex>
            </Label>
          )}
          {status === -1 && isAllowed('content.osmosis', user.grants) && (
            <Button
              size="s"
              startIcon={<FontAwesomeIcon icon={['fad', 'link']} />}
              onClick={() => history.push(`/project/${projectID}/osmosis`)}
            >
              Link to Osmosis
            </Button>
          )}
        </Box>
      )}
    </Container>
  );
}

const Container = styled.div``;
const ButtonContainer = styled.div`
  display: flex;
  margin-left: 10px;
`;

const ProjectNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.h2`
  margin: 0;
`;

NameSection.defaultProps = {};

export default NameSection;
