import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import useModifier from '../../../app/hooks/useModifier';

import Draggable from '../../Draggable';

import useClickOutside from '../../../app/hooks/useClickOutside';

import { colors, lighten } from '../../styles/colors';

import ElementProps from '../props/ElementProps';
import KeyframeProps from '../props/KeyframeProps';

const keyframeSize = 10;

const KeyframeContainer = styled.div`
  position: absolute;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  height: inherit;
`;

const StyledKeyframe = styled.div`
  width: ${keyframeSize}px;
  height: ${keyframeSize}px;
  transform: rotate(45deg);
  background-color: ${colors['light-lavender-10']};

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${lighten(colors.teal, 5)};
    `}
`;

/**
 * A single keyframe on the timeline
 */
function Keyframe(props) {
  const {
    pixelsPerMs,
    keyframe,
    element,
    keyframeKey,
    isActive,
    selectedKeyframes,
    handleSelectKeyframes,
    handleDragKeyframe,
  } = props;

  const shiftKeyDown = useModifier('shift').isDown;

  const containerRef = useRef();

  useClickOutside(containerRef, () => {
    if (selectedKeyframes.some((k) => k.id === keyframe.id) && !shiftKeyDown) {
      handleSelectKeyframes();
    }
  });

  const handleClickKeyframe = () => {
    const keyframes = shiftKeyDown
      ? [...selectedKeyframes, keyframe]
      : [keyframe];

    handleSelectKeyframes(element, keyframeKey, keyframes);
  };

  return (
    <KeyframeContainer
      ref={containerRef}
      left={keyframe.time * pixelsPerMs}
      style={{
        left: `${keyframe.time * pixelsPerMs - keyframeSize / 2}px`,
      }}
      onClick={handleClickKeyframe}
    >
      <Draggable
        onMouseMove={(move, e) => handleDragKeyframe(move, e, keyframe)}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <StyledKeyframe isActive={isActive} />
      </Draggable>
    </KeyframeContainer>
  );
}

const { number, string, func, arrayOf, bool } = PropTypes;

Keyframe.propTypes = {
  pixelsPerMs: number.isRequired,
  keyframe: KeyframeProps.isRequired,
  element: ElementProps.isRequired,
  keyframeKey: string.isRequired,
  isActive: bool.isRequired,
  selectedKeyframes: arrayOf(KeyframeProps).isRequired,
  handleSelectKeyframes: func.isRequired,
  handleDragKeyframe: func.isRequired,
};

export default Keyframe;
