import React, { useState } from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import { Flex } from './Box';
import Input from './RebassInput';
import Button from './RebassButton';

interface Props {
  currentRefinement: string;
  refine: (refinement: string) => void;
  placeholder?: string;
  darkMode?: boolean;
  refresh?: boolean;
  setRefresh?: any;
}

const SearchBox = ({
  currentRefinement,
  refine,
  placeholder,
  darkMode,
}: Props) => {
  const [val, setVal] = useState(currentRefinement);

  const handleRefine = (value: string) => {
    refine(value);
  };

  const handleChange = (event: any) => {
    const { value } = event.currentTarget;

    setVal(value);
    if (!value) handleRefine(value);
  };

  const handleKeyDown = (event: any) => {
    const { value } = event.currentTarget;
    if (event.key === 'Enter') {
      handleRefine(value);
    }
  };

  return (
    <Flex>
      <Input
        type="search"
        value={val}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder || ''}
        darkMode={darkMode || false}
      />
      <Button
        onClick={() => handleRefine(val)}
        ml={2}
        variant="outline"
        color="dark-6"
      >
        Search
      </Button>
    </Flex>
  );
};

const CustomSearchBox = connectSearchBox(SearchBox);
export default CustomSearchBox as any;
