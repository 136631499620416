import styled, { css } from 'styled-components/macro';

import { colors, lighten, fade } from '../styles/colors';

export const Container = styled.div`
  /* border-bottom: 1px solid ${colors['light-grey-50']}; */
  margin-top: 5px;
  overflow: hidden;
  border-radius: 3px;

  transition: 0.1s all ease-in-out;

  background-color: ${(props) => {
    if (!props.darkMode) {
      if (props.isResolved) return '#efefef';
      else if (props.isSelected) return lighten(colors.blue, 44);
      return 'white';
    }

    if (props.isResolved) return colors.night;
    else if (props.isSelected) return fade(lighten(colors.blue, 20), 50);
    return lighten(colors.night, 10);
  }};

  ${(props) =>
    !props.isSelected &&
    !props.isResolved &&
    css`
      &:hover,
      &:focus {
        background-color: ${props.darkMode
          ? lighten(colors.night, 7)
          : lighten(colors.grey, 58)};
      }
      &:active {
        background-color: ${props.darkMode
          ? lighten(colors.night, 5)
          : lighten(colors.grey, 55)};
      }
    `}

  ${(props) =>
    props.isResolved &&
    css`
      opacity: 0.5;

      :hover {
        opacity: 0.9;
      }
    `}
`;
export const ParentCommentContainer = styled.div``;
export const RepliesContainer = styled.div`
  padding-left: 15px;
`;
export const ReplyContainer = styled.div``;
