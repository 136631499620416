import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import { motion, AnimatePresence } from 'framer-motion';

import fetchUtil from '../../../util/fetchUtil';

import useDroppable from '../../../app/hooks/useDroppable';

import { isMediaType } from '../../../shared/utilities/fileTypes';
import svgParser from '../../../app/editor/svgParser';
import filesUtil from '../../../utilities/files';
import { getFilesFromEvent } from '../../../app/fileManager';

import Box from '../../Box';
import Button from '../../Button';
import SvgPreview from './SvgPreview';

import UIMessage from '../../Message';

import { fade, colors, lighten } from '../../styles/colors';
import { P } from '../../styles/typography';

const Actions = styled(Box)`
  padding: 5px;
`;

const Container = styled.div`
  width: 100%;
  flex: 1;

  height: 100%;
  overflow: auto;

  position: relative;

  transition: 0.1s all ease-in-out;

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${fade(colors.teal, 75)};
    `}
`;

const Message = styled(motion.div)`
  color: white;
  background-color: ${fade('white', 80)};
  padding: 15px;
  font-size: 20px;
  border-radius: 3px;
`;

const MessageContainer = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  left: 0;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const PreviewContainer = styled(Box)`
  padding: 10px;
  margin: 10px;
  width: 40%;
  border-radius: 5px;

  position: relative;

  cursor: pointer;

  :hover,
  :focus {
    background-color: ${fade('black', 95)};
  }

  :active {
    background-color: ${fade('black', 90)};
  }
`;

const CheckContainer = styled(Box)`
  position: absolute;
  left: 5px;
  top: 5px;

  opacity: 0;

  transition: 0.1s all ease-in-out;

  ${PreviewContainer}:hover & {
    opacity: 1;
  }

  ${(props) =>
    props.isActive &&
    css`
      opacity: 1;
      i, svg {
        color: ${lighten(colors.blue, 30)};
      }
    `}
`;

const CheckButton = styled(Button)`
  width: 20px;
  height: 20px;

  opacity: 0.5;

  :hover,
  :focus {
    opacity: 0.75;
  }
  ${(props) =>
    props.isActive &&
    css`
      opacity: 1;
    `}
`;

const PreviewsContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`;

/**
 * List of symbols added to editor by user
 */
function SymbolsList(props) {
  const { handleAddElement } = props;

  const [assets, setAssets] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);

  const containerRef = useRef();

  const { isDraggingOver } = useDroppable(containerRef);

  const handleAddSelected = (e, asset) => {
    e.stopPropagation();
    setSelectedAssets([...selectedAssets, asset]);
  };

  const handleRemoveSelected = (e, asset) => {
    e.stopPropagation();
    const index = selectedAssets.findIndex((a) => a.assetID === asset.assetID);
    const newSelectedAssets = [...selectedAssets];
    newSelectedAssets.splice(index, 1);
    setSelectedAssets(newSelectedAssets);
  };

  const getAssets = async () => {
    const res = await fetchUtil.post('/scene/getEditorAssets');

    setAssets(res.assets);
  };

  const handleDropFile = async (e) => {
    e.preventDefault();
    const files = getFilesFromEvent(e);

    const svgs = Object.values(files).filter((file) =>
      isMediaType(filesUtil.getExtension(file.name).slice(1), 'svg')
    );

    if (svgs && svgs.length) {
      const svgTextArray = [];
      svgs.forEach((svg) => {
        svgTextArray.push(svg.text());
      });

      const res = await Promise.all(svgTextArray);

      const svgImports = await Promise.all(
        res.map((svgText) => svgParser.stringToJson(svgText))
      );

      await Promise.all(
        svgImports.map((json, i) =>
          fetchUtil.post('/scene/saveEditorAsset', {
            name: svgs[i].name,
            svgson: json,
            type: 'svg',
          })
        )
      );

      getAssets();
    }
  };

  const handleDeleteAssets = async () => {
    await fetchUtil.post('/scene/deleteEditorAssets', {
      assetIDs: selectedAssets.map((asset) => asset.assetID),
    });

    setSelectedAssets([]);

    getAssets();
  };

  useEffect(() => {
    getAssets();
  }, []);

  const handleClick = (asset) => {
    const { children } = asset.data.svgson;
    if (children && children.length) {
      let node = null;

      // If multiple children exist as the first children of the svg, group them into one node
      if (children.length > 1) {
        node = {
          type: 'g',
          children,
          attributes: {},
        };
      } else {
        [node] = children;
      }

      const [newElement] = svgParser.createElementFromData(node);
      handleAddElement(newElement);
    }
  };

  const previews = assets
    .filter((asset) => asset.data.type === 'svg')
    .map((asset) => {
      const { svgson } = asset.data;
      const isSelected = !!selectedAssets.find(
        (a) => a.assetID === asset.assetID
      );

      return (
        <PreviewContainer
          key={asset.id}
          padding={10}
          onClick={() => handleClick(asset)}
        >
          <CheckContainer isActive={isSelected}>
            <CheckButton
              thin
              icon
              color="white"
              onClick={(e) =>
                isSelected
                  ? handleRemoveSelected(e, asset)
                  : handleAddSelected(e, asset)
              }
              isActive={isSelected}
            >
              <FontAwesomeIcon icon={['fas', 'check-circle']} />
            </CheckButton>
          </CheckContainer>
          <SvgPreview svgson={svgson} />
          <P>{asset.data.name}</P>
        </PreviewContainer>
      );
    });

  return (
    <Container
      ref={containerRef}
      onDrop={handleDropFile}
      isActive={isDraggingOver}
    >
      {!assets.length && (
        <UIMessage style={{ margin: 10 }} darkMode={true}>
          Drag and drop SVG shapes
        </UIMessage>
      )}
      {!!selectedAssets.length && (
        <Actions>
          <Button
            thin
            box
            leftIcon
            transparent
            color={lighten(colors.red, 20)}
            style={{ fontSize: 13 }}
            onClick={handleDeleteAssets}
          >
            <FontAwesomeIcon icon={['fad', 'trash-alt']} />
            Delete
          </Button>
        </Actions>
      )}
      <AnimatePresence>
        {isDraggingOver && (
          <MessageContainer>
            <Message
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{
                scale: 0.5,
                opacity: 0,
                transition: { easing: 'easeOut' },
              }}
            >
              Drop file!
            </Message>
          </MessageContainer>
        )}
      </AnimatePresence>
      <PreviewsContainer>{previews}</PreviewsContainer>
    </Container>
  );
}

const { func } = PropTypes;

SymbolsList.propTypes = {
  handleAddElement: func,
};

export default SymbolsList;
