import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import useSnackbar from '../../../app/hooks/useSnackbar';
import KitProps from '../../../types/KitProps';
import { post } from '../../../util/fetchUtil';
import Autocomplete from '../../Autocomplete';
import Box from '../../Box';
import FileProps, { FileKitProps } from '../../editor/types/FileProps';
import Snackbar from '../../Snackbar';
import SnackbarContainer from '../../SnackbarContainer';
import { Heading } from '../AssetStyles';
import FileKitDirectory from './FileKitDirectory';

interface Props {
  files: FileProps[];
  addToKit: (kit: KitProps) => Promise<void>;
  removeFromKit: (kitID: number) => Promise<void>;
  addToKitDirectory?: (kitID: number, directoryID: number) => Promise<void>;
}

const KitActions = (props: Props) => {
  const { files, addToKit, removeFromKit, addToKitDirectory } = props;

  const [kits, setKits] = useState<KitProps[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const snackbar = useSnackbar();

  const fileKits = files
    // Get list of kits
    .reduce((kits: FileKitProps[], file) => [...kits, ...(file.kits || [])], [])
    .reduce(
      // Filter out repeats
      (set: FileKitProps[], current: FileKitProps) =>
        set.find((k) => k.kitID === current.kitID) ? set : [...set, current],
      []
    )
    // Filter out kits / directories that aren't included in all other files
    .filter((k) =>
      files.every((f) =>
        f.kits?.find(
          (kit) => kit.kitID === k.kitID && kit.directoryID === k.directoryID
        )
      )
    );

  const getKits = useCallback(async () => {
    const reqs = await Promise.all([post('/assets/getKits')]);

    setKits(reqs[0].kits);
    // eslint-disable-next-line
  }, [files]);

  const handleAddToKit = async (k: KitProps) => {
    setSearchValue('');
    await addToKit(k);

    setSnackbarMessage(`Added to ${k.name}`);
    snackbar.show();

    getKits();
  };

  const handleRemoveFromKit = async (kitID: number) => {
    const linksToRemove = fileKits.filter((l) => l.kitID === kitID);
    if (linksToRemove.length) {
      removeFromKit(kitID);

      const kitData = kits.find((k) => k.kitID === fileKits[0].kitID);
      setSnackbarMessage(
        `Removed ${linksToRemove.length} file${
          linksToRemove.length > 1 ? 's' : ''
        } from ${kitData?.name}`
      );

      snackbar.show();
      getKits();
    }
  };

  useEffect(() => {
    getKits();
  }, [getKits, files]);

  return (
    <Box p={10}>
      <Snackbar isVisible={snackbar.isVisible}>
        <SnackbarContainer
          bg="dark-3"
          startIcon={<FontAwesomeIcon icon={['fas', 'check']} />}
        >
          {snackbarMessage}
        </SnackbarContainer>
      </Snackbar>

      <Heading>
        Kits{files.length > 1 ? ` (${files.length} files)` : ''}
      </Heading>
      <Autocomplete
        value={searchValue}
        options={kits}
        placeholder="Add to kit"
        onItemSelect={handleAddToKit}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <Box>
        {fileKits.map((kit: KitProps) => {
          return (
            <Box width="100%" key={kit.kitID} mt={2}>
              <FileKitDirectory
                kit={kit}
                handleRemoveKit={handleRemoveFromKit}
                addToKitDirectory={addToKitDirectory}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default KitActions;
