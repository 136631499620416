import roles from '../../shared/roles';

export default (projects) => {
  // Create tasks array
  const tasks = [];

  for (let i = 0; i < projects.length; i += 1) {
    const project = projects[i];
    if (project.data.tasks) {
      for (let j = 0; j < project.data.tasks.length; j += 1) {
        const task = project.data.tasks[j];
        const taskData = roles.find((t) => t.taskID === task.taskID);

        const taskIndex = tasks.findIndex((t) => t.taskID === task.taskID);
        // Filter out all tasks that don't match this task
        const taskToAdd = {
          ...task,
          projectName: project.name,
          projectID: project.projectID,
          wordCount: project.wordCount,
        };
        // If index already exists, increment hours and wordCount and add the project for reference
        if (tasks[taskIndex]) {
          tasks[taskIndex].hours += task.hours;
          tasks[taskIndex].wordCount += project.wordCount;
          tasks[taskIndex].list.push(taskToAdd);
        } else {
          tasks.push({
            taskID: task.taskID,
            taskName: taskData.taskName,
            icon: taskData.icon,
            color: taskData.color,
            hours: task.hours,
            wordCount: project.wordCount,
            list: [taskToAdd],
          });
        }
      }
    }
  }

  return tasks;
};
