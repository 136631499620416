import { ElementProps, ElementTypes } from '../../ui/editor/types/ElementProps';
import flattenElements from './util/flattenElements';

const updateAll = (elements: ElementProps[], currentTime: number): void => {
  const flattenedElements = flattenElements(elements);
  flattenedElements.forEach((el) => el.setCurrentProps(currentTime));
};

export const getAllOfType = (
  type: ElementTypes,
  elements: ElementProps[]
): ElementProps[] => flattenElements(elements).filter((el) => el.type === type);

export default { updateAll, getAllOfType };
