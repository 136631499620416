import styled from 'styled-components/macro';

import React, { useEffect, useState } from 'react';

import CreditsPreview from './CreditsPreview';
import { post } from '../../util/fetchUtil';
import { getSearchParams } from '../../app/params';

const CreditsExporter = () => {
  const [credits, setCredits] = useState();

  const getCredits = async () => {
    const { projectID, key } = getSearchParams(window.location.href);

    const res = await post('/exporter/getExporterCredits', {
      key,
      projectID,
    });

    setCredits(res.credits);

    window.creditsLoaded = true;
  };

  useEffect(() => {
    getCredits();
  }, []);

  return (
    <Container>
      {!!credits?.data && (
        <CreditsPreview
          handleUpdatePartnerLogo={() => {}}
          handleRemovePartnerLogo={() => {}}
          credits={credits.data}
        />
      )}
    </Container>
  );
};

const Container = styled.div``;

export default CreditsExporter;
