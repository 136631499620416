import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { useHistory } from 'react-router-dom';

import { Store } from '../../state/store';

import VideoPlayer from '../VideoPlayer';
import usePlayer from '../../app/hooks/usePlayer';
import players from '../../app/videoPlayer';
import { ReviewVideoContainer } from '../videojs/CustomStyles';

import AnnotationArea from '../comments/AnnotationArea';

import { getHash } from '../../shared/links';

import { Container } from './VideoViewerStyles';

function hotkeys(event) {
  switch (event.keyCode) {
    case 190: // . Forward 1 frames
      if (!this.paused()) this.pause();
      if (event.shiftKey) {
        this.currentTime(this.currentTime() + 10 / 30); // Ten frames
      } else {
        this.currentTime(this.currentTime() + 1 / 30); // 1 frame
      }
      break;
    case 188: // , - Rewind 1 frame
      if (!this.paused()) this.pause();
      if (event.shiftKey) {
        this.currentTime(this.currentTime() - 10 / 30); // Ten frames
      } else {
        this.currentTime(this.currentTime() - 1 / 30); // 1 frame
      }
      break;
    case 76: // L - forward 5s
      if (!this.paused()) this.pause();
      this.currentTime(this.currentTime() + 5);
      break;
    case 75: // K - play / pause
      if (!this.paused()) this.pause();
      else this.play();
      break;
    case 74: // J - forward 5s
      if (!this.paused()) this.pause();
      this.currentTime(this.currentTime() - 5);
      break;
    case 39: // >
      if (!this.paused()) this.pause();
      this.currentTime(this.currentTime() + 10);
      break;
    case 37: // <
      if (!this.paused()) this.pause();
      this.currentTime(this.currentTime() - 10);
      break;
    default:
      break;
  }
}

/**
 * Displays the selected MP4 file
 *
 * @class VideoViewer
 * @extends {Component}
 */
function VideoViewer(props) {
  const { comments } = props;

  const {
    state: { activeFile, activeProject },
  } = useContext(Store);

  const history = useHistory();

  // Initialize video player
  const [videoRef, player] = usePlayer({
    src: activeFile.data && activeFile.data.url,
    type: activeFile.data.type,
    autoplay: false,
    textTracks: [],
    components: [
      {
        name: 'CommentBar',
        parent: 'ProgressControl',
        id: 'custom-comment-bar',
        args: {
          comments: comments.map((comment) => ({
            ...comment,
            onClick(e) {
              e.stopPropagation();
              history.push(
                getHash({
                  view: 'project',
                  projectID: activeProject.projectID,
                  fileID: activeFile.fileID,
                  commentID: comment.commentID,
                })
              );
            },
            id: comment.commentID,
            time: parseFloat(comment.data.timestamp),
          })),
        },
      },
    ],
    options: {
      playbackRates: [1, 1.5, 2],
      controlBar: {
        volumePanel: { inline: false },
        currentTimeDisplay: {
          hidden: false,
        },
        children: [
          'playToggle',
          'timeDivider',
          'currentTimeDisplay',
          'progressControl',
          'remainingTimeDisplay',
          'durationDisplay',
          'volumePanel',
          'PlaybackRateMenuButton',
          'captionsButton',
          'fullscreenToggle',
          'pictureInPictureToggle',
        ],
      },
      userActions: {
        hotkeys,
      },
      // Fills the container
      fill: true,
    },
    events: {
      // loadeddata() {},
    },
  });

  // Set global variable to be used in other places
  players.reviewPlayer = player;

  return (
    <Container>
      <AnnotationArea id="video-annotations" />
      <ReviewVideoContainer>
        <VideoPlayer tracks={activeFile.data.tracks} videoRef={videoRef} />
      </ReviewVideoContainer>
    </Container>
  );
}

VideoViewer.propTypes = {
  comments: PropTypes.array,
};

VideoViewer.defaultProps = {
  comments: [],
};

export default VideoViewer;
