import styled from 'styled-components/macro';

import { colors } from '../styles/colors';

export const DragHandle = styled.div`
  /* opacity: 0; */

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 15px;

  cursor: grab;
  padding: 5px;

  i,
  svg {
    color: ${colors['light-grey-40']};
  }
`;

export default DragHandle;
