import styled, { css } from 'styled-components/macro';

import { motion } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from './Button';
import Box from './Box';

import elevations from './styles/elevations';
import { colors, lighten } from '../ui/styles/colors';
import { H4 } from '../ui/styles/typography';

export const Container = styled.div``;
export const TextInput = styled.div`
  padding: 10px;
  input {
    width: 100%;
  }
`;
export const Options = styled.div`
  max-height: 220px;
  overflow: auto;
`;
export const StyledButton = styled(Button)`
  display: flex;
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const Header = styled(H4)`
  padding: 10px;

  font-size: 14px;

  color: ${lighten(colors.grey, 30)};
`;

type OptionsContainerProps = {
  zindex?: number;
  position?: {
    left: string;
    top: string;
  };
};

export const OptionsContainer = styled(motion.div)`
  position: fixed;
  left: 0;

  min-width: 175px;
  max-width: 300px;
  z-index: ${(props: OptionsContainerProps) => props.zindex};

  background: white;

  margin-top: 10px;
  border-radius: 3px;

  box-shadow: ${elevations['3']};

  ${(props: OptionsContainerProps) =>
    props.position &&
    props.position.left &&
    css`
      left: ${props.position.left};
    `}

  ${(props: OptionsContainerProps) =>
    props.position &&
    props.position.top &&
    css`
      top: ${props.position.top};
    `}
`;
export const Option = styled.div`
  padding: 5px 10px;

  display: flex;
  /* justify-content: space-between; */
  align-items: center;

  cursor: pointer;

  :hover,
  :focus {
    background: ${lighten(colors.grey, 55)};
  }

  :active {
    background: ${lighten(colors.grey, 52)};
  }
`;

type TextProps = {
  error?: boolean;
};
export const Text = styled.div`
  display: inline-block;

  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;

  ${(props: TextProps) =>
    props.error &&
    css`
      color: ${lighten(colors.red, 10)};
    `}
`;

export const Placeholder = styled(Text)`
  color: ${colors['light-grey-30']};

  ${(props: TextProps) =>
    props.error &&
    css`
      color: ${lighten(colors.red, 20)};
    `}

  max-width: unset;
`;
export const OptionsText = styled(Box)`
  margin: 0;
  color: ${colors['light-grey-10']};

  overflow: hidden;
  text-overflow: ellipsis;
`;

type IconProps = {
  error?: boolean;
  isOpen?: boolean;
};
export const Icon = styled(Box)`
  margin-left: 30px;
  transition: 0.1s ease-in-out all;
  color: ${colors['light-grey-20']};

  ${(props: IconProps) =>
    props.isOpen &&
    css`
      transform: rotate(180deg);
    `}

  ${(props: IconProps) =>
    props.error &&
    css`
      color: ${lighten(colors.red, 20)};
    `}
`;
export const CheckIcon = styled(FontAwesomeIcon)`
  margin-left: 30px;
  color: ${colors.green};
`;
export const OptionsIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  width: 20px;
  color: ${colors['light-grey-10']};
`;
