import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import fetchUtil from '../../util/fetchUtil';

import Table from '../Table';
import Button from '../Button';
import Box from '../Box';
import LabelDialog from './labels/LabelDialog';
import { ColorBlock } from './labels/LabelStyles';

function AdminLabels() {
  const [labels, setLabels] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState({});
  const [dialogVisible, setDialogVisible] = useState(false);

  const getLabels = async () => {
    const res = await fetchUtil.post('/admin/adminGetSceneLabels');
    setLabels(res.labels);
  };

  useEffect(() => {
    getLabels();
  }, []);

  const headers = [
    {
      id: 'labelID',
      value: 'ID',
      width: 75,
    },
    {
      id: 'name',
      value: 'Name',
    },
    {
      id: 'color',
      value: 'Color',
    },
  ];

  const rows = labels.map((label) => ({
    labelID: label.labelID,
    name: label.name,
    color: {
      component: <ColorBlock color={label.data.color} />,
    },
  }));

  return (
    <Container>
      <LabelDialog
        handleHideDialog={() => setDialogVisible(false)}
        isVisible={dialogVisible}
        label={selectedLabel}
        update={getLabels}
      />
      <Box style={{ padding: 20 }}>
        <Button
          leftIcon
          box
          onClick={() => {
            setSelectedLabel({});
            setDialogVisible(true);
          }}
        >
          <FontAwesomeIcon icon={['fas', 'plus']} />
          Add label
        </Button>
      </Box>
      <Table
        rows={rows}
        headers={headers}
        onRowClick={(label) => {
          setSelectedLabel(labels.find((l) => label.labelID === l.labelID));
          setDialogVisible(true);
        }}
      />
    </Container>
  );
}

const Container = styled.div``;

export default AdminLabels;
