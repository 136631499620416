import React from 'react';

import { StoreProvider } from '../../state/store';
import TextChecker from './TextChecker';

const TextCheckerWrapper = () => (
  <StoreProvider>
    <TextChecker />
  </StoreProvider>
);

export default TextCheckerWrapper;
