import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

import React from 'react';

import moment from 'moment';

import { post } from '../../util/fetchUtil';

import Table from '../Table';
import Button from '../Button';

import Dialog from '../Dialog';

import AddResource from './resources/AddResource';

import { A, P } from '../styles/typography';

class AdminResources extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: [],
      rowInfo: {},
      rowInfoDialogVisible: false,
      searchValue: '',
      addItemDialogVisible: false,
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.getAll = this.getAll.bind(this);
  }

  componentDidMount() {
    this.getAll();
  }

  handleSearchChange(e) {
    const { value } = e.target;
    this.setState({ searchValue: value });

    this.searchProjects(value);
  }

  getAll() {
    post('/resources/getAll').then((res) =>
      this.setState({ rowData: res.resources })
    );
  }

  handleDelete(e, id) {
    e.stopPropagation();

    post('/admin/adminDeleteResource', {
      resourceID: id,
    }).then(() => this.getAll());
  }

  onRowClick(row) {
    this.setState({ addItemDialogVisible: true, rowInfo: row });
  }

  render() {
    const { rowData, addItemDialogVisible, rowInfo } = this.state;

    const headers = [
      {
        id: 'image',
        value: 'Image',
      },
      {
        id: 'name',
        value: 'Name',
      },
      {
        id: 'link',
        value: 'Source',
      },
      {
        id: 'attribution',
        value: 'Attribution',
      },
      {
        id: 'copyright',
        value: 'Copyright',
      },
      {
        id: 'projects',
        value: 'Projects',
      },
      {
        //   id: 'groups',
        //   value: 'Folders',
        // }, {
        id: 'notes',
        value: 'Notes',
      },
      {
        id: 'actions',
        value: '',
      },
    ];

    const rows = rowData.map((row) => ({
      image: {
        component: (
          <Image>
            <img src={row.data.image} alt="Admin resource" />
          </Image>
        ),
        data: row.data.image,
      },
      name: row.name,
      link: { component: <A>{row.data.link}</A>, data: row.data.link },
      attribution: row.data.attribution,
      copyright: row.data.copyright,
      notes: { component: <Pre>{row.data.notes}</Pre>, data: row.data.notes },
      created: moment(row.createdOn).format('MM-DD-YYYY'),
      // groups: { component: row.data.groups.map(group => <Group key={group}>{group.name}</Group>), data: row.data.groups},
      projects: {
        component: <P>{row.data.projects.length}</P>,
        data: row.data.projects,
      },
      resourceID: row.resourceID,
      actions: (
        <div>
          {/* {row.status === 1 && (
              <Icon
              icon={['fad', 'archive']}
              onClick={() => this.handleArchiveProject(row.projectID)}
              />
            )}
            {(row.status === 2 || row.status === 0) && (
              <Icon
              icon={['fad', 'feather']}
              onClick={() => this.handleReviveProject(row.projectID)}
              />
            )} */}
          {row.status > 0 && (
            <Icon
              icon={['fad', 'trash-alt']}
              onClick={(e) => this.handleDelete(e, row.resourceID)}
            />
          )}
        </div>
      ),
    }));

    return (
      <Container>
        <HeaderContainer>
          <Button
            className="box left-icon"
            onClick={() =>
              this.setState({ rowInfo: {}, addItemDialogVisible: true })
            }
          >
            <FontAwesomeIcon icon={['fas', 'plus']} />
            <span>Add resource</span>
          </Button>
          {/* <TextInput className="text-input">
            <input
              type="text"
              onChange={this.handleSearchChange}
              placeholder="Filter projects..."
            />
          </TextInput> */}
          <StatusContainer>{/* {checkboxes} */}</StatusContainer>
          <Dialog
            isVisible={addItemDialogVisible}
            handleHideDialog={() =>
              this.setState({ addItemDialogVisible: !addItemDialogVisible })
            }
          >
            <AddResource
              rowInfo={rowInfo}
              handleHideDialog={() =>
                this.setState({ addItemDialogVisible: !addItemDialogVisible })
              }
              onAfterSave={this.getAll}
            />
          </Dialog>
        </HeaderContainer>
        <Table headers={headers} rows={rows} onRowClick={this.onRowClick} />
        <FooterContainer>
          {/* {pages > 1 && (
            <Pagination
              pages={pages}
              page={page}
              handlePageChange={this.handlePageChange}
            />
          )} */}
        </FooterContainer>
      </Container>
    );
  }
}

const Container = styled.div``;

const StatusContainer = styled.div`
  display: flex;
  > * {
    margin-right: 20px;
  }
`;

const FooterContainer = styled.div`
  padding: 15px;
`;

const HeaderContainer = styled.div`
  padding: 15px;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 10px;
  &.button.icon {
    font-size: 14px;
  }
`;

const Pre = styled.div`
  white-space: pre-wrap;
`;

const Image = styled.div`
  height: 100px;
  overflow: hidden;
  img {
    height: 100%;
  }
`;

AdminResources.propTypes = {};

AdminResources.defaultProps = {};

export default AdminResources;
