import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import fileManager, { fetchFile } from '../../app/fileManager';
import handleExportComments from '../../app/comments/exportComments';
import FileItemInfo from '../projectContents/FileItemInfo';
import Label from '../Label';
import Box, { Flex } from '../Box';

import { colors } from '../styles/colors';
import { H4 } from '../styles/typography';

import { Option, OptionsText } from '../OptionSelectorStyles';

const download = (file, onProgress, onError = () => {}) => {
  fetchFile(file.data.url, onProgress)
    .catch(() => {
      onError(file);
    })
    .then((blob) => {
      fileManager.downloadFile(blob, file.name);
    });
};
export const downloadFile = (file, onProgress, onError) => ({
  key: 'download',
  id: 'downloadFile',
  name: 'Download',
  component: (
    <Option key="download" onClick={() => download(file, onProgress, onError)}>
      <OptionsText>
        <FontAwesomeIcon
          style={{ width: 'unset' }}
          icon={['fad', 'file-download']}
        />
        &nbsp;&nbsp;Download
      </OptionsText>
    </Option>
  ),
});
export const deleteFile = (file, onClick) => ({
  key: 'delete',
  id: 'deleteFile',
  name: 'Delete',
  component: (
    <Option key="delete" onClick={() => onClick(file)}>
      <OptionsText style={{ color: colors.red }}>
        <FontAwesomeIcon
          style={{ width: 'unset' }}
          icon={['fad', 'trash-alt']}
        />
        &nbsp;&nbsp;Delete
      </OptionsText>
    </Option>
  ),
});
export const exportSvgFile = (file, onClick) => ({
  key: 'export',
  id: 'exportFile',
  name: 'Export',
  component: (
    <Option key="export" onClick={() => onClick([file])}>
      <OptionsText>
        <FontAwesomeIcon
          style={{ width: 'unset' }}
          icon={['fad', 'file-video']}
        />
        &nbsp;&nbsp;Export&nbsp;&nbsp;
        <Label color="teal">Beta</Label>
      </OptionsText>
    </Option>
  ),
});
export const fileInfo = (file) => ({
  key: 'info',
  id: 'fileItemInfo',
  name: 'File Item Info',
  component: <FileItemInfo key="info" file={file} />,
});

function SortOption(props) {
  const { selectSort, currentSort, name, id, icon, reverseSort } = props;
  return (
    <Option key={id} onClick={() => selectSort(id)}>
      <Flex style={{ justifyContent: 'space-between', width: '100%' }}>
        <Box>
          <OptionsText>
            <i className={icon} />
            &nbsp;&nbsp;{name}
          </OptionsText>
        </Box>
        <Box>
          {currentSort === id && (
            <Box style={{ color: colors['light-grey-20'] }}>
              {typeof reverseSort === 'undefined' && (
                <FontAwesomeIcon
                  style={{ color: colors.green, width: 'unset' }}
                  icon={['fas', 'check']}
                />
              )}
              {typeof reverseSort === 'boolean' && reverseSort && (
                <FontAwesomeIcon
                  style={{ width: 'unset' }}
                  icon={['fas', 'arrow-up']}
                />
              )}
              {typeof reverseSort === 'boolean' && !reverseSort && (
                <FontAwesomeIcon
                  style={{ width: 'unset' }}
                  icon={['fas', 'arrow-down']}
                />
              )}
            </Box>
          )}
        </Box>
      </Flex>
    </Option>
  );
}

SortOption.propTypes = {
  currentSort: PropTypes.string.isRequired,
  selectSort: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  icon: PropTypes.string,
  reverseSort: PropTypes.bool,
};
export const sort = (options, currentSort, selectSort, reverseSort) => ({
  key: 'sort',
  id: 'fileSort',
  name: 'Sort files',
  component: (
    <Box>
      <H4
        style={{
          padding: '10px 10px 5px 10px',
          color: colors['light-grey-30'],
        }}
      >
        Sort
      </H4>
      {options.map((option) => (
        <SortOption
          key={option.id}
          selectSort={selectSort}
          currentSort={currentSort}
          name={option.name}
          id={option.id}
          icon={option.icon}
          reverseSort={reverseSort}
        />
      ))}
    </Box>
  ),
});

export const exportComments = (projectID, type) => ({
  key: 'export-comments',
  id: 'exportComments',
  name: 'Export comments',
  component: (
    <Option
      key="export-comments"
      onClick={() => handleExportComments(projectID, type)}
    >
      <OptionsText>
        <FontAwesomeIcon icon={['fad', 'comments']} />
        &nbsp;&nbsp;Print comments
      </OptionsText>
    </Option>
  ),
});
