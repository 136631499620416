import { ReactNode, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

function copyStyles(sourceDoc: Document, targetDoc: Document) {
  Array.from(sourceDoc.styleSheets).forEach((styleSheet) => {
    if (styleSheet.cssRules) {
      // for <style> elements
      const newStyleEl = sourceDoc.createElement('style');

      Array.from(styleSheet.cssRules).forEach((cssRule) => {
        // write the text of each rule into the body of the style element
        newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
      });

      targetDoc.head.appendChild(newStyleEl);
    } else if (styleSheet.href) {
      // for <link> elements loading CSS from a URL
      const newLinkEl = sourceDoc.createElement('link');

      newLinkEl.rel = 'stylesheet';
      newLinkEl.href = styleSheet.href;
      targetDoc.head.appendChild(newLinkEl);
    }
  });
}

type Props = {
  children: ReactNode;
  width?: number;
  height?: number;
  left?: number;
  top?: number;
  styleDeps?: any[];
  onExternalWindowClose?: (e: any) => void;
};

export default function WindowPortal(props: Props) {
  const {
    children,
    width = 600,
    height = 400,
    styleDeps = [],
    left = 200,
    top = 200,
    onExternalWindowClose,
  } = props;

  const containerEl = useRef(document.createElement('div'));
  const externalWindow = useRef<any>(null);

  const handleUnloadExternalWindow = () => {
    // Close the external window
    externalWindow.current.close();
  };

  useEffect(() => {
    externalWindow.current = window.open(
      '',
      '',
      `width=${width},height=${height},left=${left},top=${top}`
    );

    externalWindow.current.document.body.appendChild(containerEl.current);

    // Close external window when window closes
    window.addEventListener('beforeunload', handleUnloadExternalWindow);

    if (onExternalWindowClose) {
      externalWindow.current.addEventListener(
        'beforeunload',
        onExternalWindowClose
      );
    }

    return () => {
      externalWindow.current.close();

      window.removeEventListener('beforeunload', handleUnloadExternalWindow);
      if (onExternalWindowClose) {
        window.removeEventListener('beforeunload', onExternalWindowClose);
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    copyStyles(document, externalWindow.current.document);
    // eslint-disable-next-line
  }, styleDeps);

  return ReactDOM.createPortal(children, containerEl.current);
}
