import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Comment from './Comment';

import useClickOutside from '../../app/hooks/useClickOutside';

import {
  Container,
  ParentCommentContainer,
  RepliesContainer,
} from './CommentWrapperStyles';

function CommentWrapper(props) {
  const {
    comment,
    totalComments,
    isSelected,
    isResolved,
    replies,
    resolved,
    handleDeleteComment,
    handleResolveComment,
    handleSaveReply,
    handleUpdateComment,
    onClickOutside,
    darkMode,
  } = props;

  const containerRef = useRef();

  useClickOutside(containerRef, () => onClickOutside(comment));
  const authorName = comment.data.user
    ? `${comment.data.user.givenName} ${comment.data.user.familyName}`
    : 'Unknown';
  return (
    <Container
      ref={containerRef}
      isSelected={isSelected}
      isResolved={isResolved}
      darkMode={darkMode}
    >
      <ParentCommentContainer>
        <Comment
          key={comment.commentID}
          commentID={comment.commentID}
          timestamp={comment.data.timestamp}
          userID={comment.userID}
          totalComments={totalComments}
          isResolved={isResolved}
          resolved={resolved}
          handleDeleteComment={handleDeleteComment}
          handleResolveComment={handleResolveComment}
          handleSaveReply={handleSaveReply}
          handleUpdateComment={handleUpdateComment}
          anonymous={comment.data.anonymous}
          authorName={authorName}
          authorDate={comment.createdOn}
          text={comment.data.text}
          hasSvg={
            !!comment.data.svg ||
            !!(comment.data.annotation && comment.data.annotation.svg) ||
            !!comment.data.annotations
          }
          darkMode={darkMode}
        />
      </ParentCommentContainer>
      <RepliesContainer>
        {replies.reverse().map((commentReply) => (
          <Comment
            key={commentReply.commentID}
            commentID={commentReply.commentID}
            timestamp={commentReply.data.timestamp}
            userID={commentReply.userID}
            totalComments={totalComments}
            handleDeleteComment={handleDeleteComment}
            handleSaveReply={handleSaveReply}
            handleUpdateComment={handleUpdateComment}
            authorName={`${commentReply.data.user.givenName} ${commentReply.data.user.familyName}`}
            authorDate={commentReply.createdOn}
            text={commentReply.data.text}
            anonymous={commentReply.data.anonymous}
            hasSvg={
              !!commentReply.data.svg ||
              !!(
                commentReply.data.annotation && commentReply.data.annotation.svg
              ) ||
              commentReply.data.annotations
            }
            isReply={true}
            parentCommentID={comment.commentID}
            darkMode={darkMode}
          />
        ))}
      </RepliesContainer>
    </Container>
  );
}

const { shape, number, bool, func, array, string } = PropTypes;

CommentWrapper.propTypes = {
  comment: shape({
    commentID: number.isRequired,
    userID: number.isRequired,
    data: shape({}).isRequired,
    createdOn: string.isRequired,
  }),
  isResolved: bool,

  totalComments: number.isRequired,

  handleDeleteComment: func.isRequired,
  handleResolveComment: func.isRequired,
  handleSaveReply: func.isRequired,
  handleUpdateComment: func.isRequired,

  replies: array,
  resolved: shape({}),
  isSelected: bool,

  onClickOutside: func,
  darkMode: bool,
};

CommentWrapper.defaultProps = {
  replies: [],
  isSelected: false,
  isResolved: false,
  resolved: {},
  darkMode: false,
  onClickOutside: () => {},
};

export default CommentWrapper;
