import { useRef, useEffect } from 'react';

import axios from 'axios';

import Debouncer from '../utilities/debouncer';
/**
 * A React hook for using an abort controller on a route
 *
 * @param {String} route - the route to send a post request to
 * @param {Object} [body={}] - data to send with the post request
 * @param {Array} [deps=[]] - array of props to trigger the timeout
 * @param {Object} [options={}] - additional callbacks and options
 */
const useAbortController = (
  route,
  body = {},
  deps = [],
  onSuccess = () => {},
  options = {}
) => {
  const {
    onAbort = () => {},
    onError = () => {},
    onBeforeFetch = () => {},
    debounce = 250,
  } = options;

  const debouncerRef = useRef(new Debouncer(body, debounce));
  const abortControllerRef = useRef(null);

  useEffect(() => {
    onBeforeFetch();

    const debouncer = debouncerRef.current;
    debouncer.setValue(body, () => {
      // If there's an abortController active
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
        // Fire abort callback
        onAbort();
      }

      // Create abort controller to stop fetch if a subsequent fetch is initiated
      abortControllerRef.current = new window.AbortController();
      const { signal } = abortControllerRef.current;

      axios
        .post(
          `/${route}`,
          {
            ...body,
          },
          { signal }
        )
        .then((res) => {
          abortControllerRef.current = null;
          onSuccess(res.data);
        })
        .catch((err) => {
          // Remove abortController
          abortControllerRef.current = null;
          onError(err);
        });
    });
    // eslint-disable-next-line
  }, deps);

  return [abortControllerRef.current];
};

export default useAbortController;
