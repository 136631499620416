import { useState, useEffect } from 'react';

/**
 * A hook that returns whether or not the referenced element is being dragged over
 *
 * @param {ReactRef} ref
 * @param {[Any]} [deps=[]] - array of dependencies
 * @param {Object} options
 * {
 *   onMouseDown: func,
 *   onMouseUp: func,
 *   onMouseMove: func,
 *   onDrag: func,
 * }
 * @returns
 */
export default (ref, deps = [], options = {}) => {
  const {
    onDrop = () => {},
    onDragEnter = () => {},
    onDragLeave = () => {},
    onDragOver = () => {},
  } = options;

  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const handleDragEnter = (e) => {
    setIsDraggingOver(true);

    onDragEnter(e);
  };

  const handleDragLeave = (e) => {
    if (!ref.current.contains(e.relatedTarget)) {
      setIsDraggingOver(false);

      onDragLeave(e);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();

    onDragOver(e);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);

    onDrop(e);
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('dragenter', handleDragEnter);
      ref.current.addEventListener('dragleave', handleDragLeave);
      ref.current.addEventListener('dragover', handleDragOver);
      ref.current.addEventListener('drop', handleDrop);
      return () => {
        ref.current.removeEventListener('dragenter', handleDragEnter);
        ref.current.removeEventListener('dragover', handleDragOver);
        ref.current.removeEventListener('dragleave', handleDragLeave);
        // eslint-disable-next-line
        ref.current.removeEventListener('drop', handleDrop);
      };
    }

    return () => {};
    // eslint-disable-next-line
  }, deps);

  return { isDraggingOver };
};
