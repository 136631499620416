import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import EntryPreview from './EntryPreview';
import SectionPreview from './SectionPreview';
import PartnerLogo from './PartnerLogo';
import CreditsSpacer from './CreditsSpacer';

import { colors, lighten, darken } from '../styles/colors';

function CreditsPreview(props) {
  const {
    credits,
    handleUpdatePartnerLogo,
    handleRemovePartnerLogo,
    handleUpdateSpacer,
  } = props;

  const creditsComponents = credits.items.map((credit) => {
    if (credit.type === 'entry') {
      return <EntryPreview key={credit.id} credit={credit} />;
    } else if (credit.type === 'section') {
      return <SectionPreview key={credit.id} title={credit.title} />;
    }

    return null;
  });

  return (
    <Container>
      <PreviewHeader>
        <CreditsLogoContainer>
          <img alt="Osmosis logo" src="https://i.imgur.com/ofKFtwa.png" />
          {credits.partnerLogos && !!credits.partnerLogos.length && (
            <Ampersand>&amp;</Ampersand>
          )}
          {credits.partnerLogos && !!credits.partnerLogos.length && (
            <CreditsSpacer
              handleUpdateSpacer={handleUpdateSpacer}
              spacer={credits.spacer}
            ></CreditsSpacer>
          )}
          {credits.partnerLogos &&
            credits.partnerLogos.map((partnerLogo, i) => (
              <PartnerLogo
                key={i}
                handleUpdatePartnerLogo={handleUpdatePartnerLogo}
                handleRemovePartnerLogo={handleRemovePartnerLogo}
                partnerLogo={partnerLogo}
              />
            ))}
        </CreditsLogoContainer>
        <Header>CREDITS</Header>
      </PreviewHeader>
      <PreviewBody>{creditsComponents}</PreviewBody>
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
  overflow: auto;

  font-family: 'Tannerscript-Regular';

  padding: 60px 0;
`;

const PreviewHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PreviewBody = styled.div``;

const CreditsLogoContainer = styled.div`
  width: 600px;

  margin-bottom: 150px;
  img {
    width: 100%;
  }
`;

const Header = styled.div`
  font-weight: 700;
  font-size: 48px;

  color: ${darken(colors.blue, 10)};

  margin-bottom: 15px;
`;

const Ampersand = styled.div`
  text-align: center;
  font-size: 48px;
  font-weight: 400;

  color: ${lighten(colors.blue, 10)};
`;

CreditsPreview.propTypes = {
  handleUpdatePartnerLogo: PropTypes.func.isRequired,
  handleRemovePartnerLogo: PropTypes.func.isRequired,
  handleUpdateSpacer: PropTypes.func.isRequired,
  credits: PropTypes.shape({
    items: PropTypes.array,
    spacer: PropTypes.shape({}),
    partnerLogos: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string,
        style: PropTypes.shape({
          width: PropTypes.string,
          transform: PropTypes.shape({
            x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          }),
        }),
      })
    ),
  }),
};

CreditsPreview.defaultProps = {
  credits: {},
};

export default CreditsPreview;
