import React from 'react';
import styled from 'styled-components/macro';

import Kit from './Kit';

import { colors, lighten } from '../styles/colors';
import { KitProps } from './assetsProps';

function KitPublic(props) {
  const { kit } = props;

  return (
    <Container>{kit && <Kit kit={kit} canEdit={!!window.userID} />}</Container>
  );
}

const Container = styled.div`
  height: 100vh;
  background-color: ${lighten(colors.grey, 57)};
`;

KitPublic.propTypes = {
  kit: KitProps,
};

KitPublic.defaultProps = {};

export default KitPublic;
