import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { post } from '../../../util/fetchUtil';

import Box from '../../Box';
import { fade } from '../../styles/colors';
import { TextInput } from '../../Input';
import create from '../../../editor/create';
import { ElementProps } from '../types/ElementProps';
import FileProps from '../types/FileProps';

const Preview = styled(Box)`
  padding: 5px;
  margin: 5px;
  background: ${fade('white', 86)};
  border-radius: 5px;

  transition: 0.1s all ease-in-out;

  cursor: pointer;

  :hover,
  :focus {
    background: ${fade('white', 89)};
  }

  :active {
    background: ${fade('white', 92)};
  }
`;

const PreviewsContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`;

type Props = {
  duration: number;
  handleAddElement: (element: ElementProps) => void;
};

/**
 * Allow user to search the image assets stored in osmosify
 */
function ImagesList(props: Props) {
  const { handleAddElement, duration } = props;

  const [searchValue, setSearchValue] = useState('');
  const [files, setFiles] = useState([]);

  const handleEnterPress = async () => {
    const res = await post('/files/searchFiles', {
      text: searchValue,
      count: 50,
      types: ['jpeg', 'jpg', 'png', 'svg'],
    });

    setFiles(res.files);
  };

  const handlePreviewClick = (file: FileProps) => {
    handleAddElement(
      create.image({
        name: file.name,
        src: file.data.url,
        fileID: file.fileID,
        duration,
      })
    );
  };

  const previews = files.map((file: FileProps) => (
    <Preview
      key={file.fileID}
      style={{ width: '45%' }}
      onClick={() => handlePreviewClick(file)}
    >
      <img alt="Preview" style={{ width: '100%' }} src={file.data.url} />
    </Preview>
  ));

  return (
    <Box>
      <TextInput
        style={{ width: '100%' }}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder="Search assets..."
        handleEnterPress={handleEnterPress}
      />
      <PreviewsContainer>{previews}</PreviewsContainer>
    </Box>
  );
}

export default ImagesList;
