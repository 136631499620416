import styled from 'styled-components/macro';

import { motion } from 'framer-motion';

import Avatar from '../Avatar';

import breakpoints from '../styles/breakpoints';
import { colors, lighten } from '../styles/colors';

export const Container = styled.div``;
export const ActivityText = styled.div``;
export const StyledAvatar = styled(Avatar)`
  margin-right: 10px;
`;
export const CommentTextAreaContainer = styled.div`
  flex: 1;
`;
export const CommentBox = styled.div`
  display: flex;
  margin-top: 15px;
`;
export const ProjectCommentsContainer = styled.div`
  max-height: 600px;
  overflow: auto;

  @media ${breakpoints.desktopMin} {
    max-height: unset;
  }
`;
export const FileText = styled.div`
  margin: 0;
  margin-right: 5px;
  display: inline;

  color: ${colors['light-grey-20']};
`;
export const FileName = styled.p`
  margin: 0;
  display: inline-block;
  font-weight: bold;

  background: ${lighten(colors.grey, 52)};
  color: ${colors['light-grey-10']};

  padding: 3px 6px;
  border-radius: 5px;
`;
export const UserName = styled.h4`
  margin: 0;
  display: inline-block;
`;
export const ActivityAnimation = styled(motion.div)``;
