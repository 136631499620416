import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useEffect } from 'react';

import fetchUtil from '../../../util/fetchUtil';

import Dialog from '../../Dialog';

import { Flex } from '../../Box';
import Button from '../../Button';
import { TextInput } from '../../Input';
import { ColorBlock } from './LabelStyles';

import { H4 } from '../../styles/typography';
import { colors } from '../../styles/colors';

import LabelProps from '../../editor/props/LabelProps';

const colorOptions = ['default', 'red', 'orange', 'yellow', 'green', 'blue'];

const Container = styled.div``;

const Section = styled.div`
  margin-bottom: 15px;
`;

const SectionHeader = styled(H4)`
  color: ${colors['light-grey-30']};
  margin-bottom: 10px;
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
`;

function LabelDialog(props) {
  const { handleHideDialog, isVisible, update, label } = props;

  const [name, setName] = useState('');
  const [color, setColor] = useState('');

  useEffect(() => {
    if (!isVisible) {
      setName('');
      setColor('');
    } else if (label.name || label.data) {
      setName(label.name);
      setColor(label.data.color);
    }
    // eslint-disable-next-line
  }, [isVisible]);

  const handleSave = async () => {
    if (label.labelID) {
      await fetchUtil.post('/admin/adminUpdateSceneLabel', {
        labelID: label.labelID,
        name,
        color,
      });
    } else {
      await fetchUtil.post('/admin/adminCreateSceneLabel', {
        name,
        color,
      });
    }

    update();
    handleHideDialog();
  };

  return (
    <Dialog
      handleHideDialog={handleHideDialog}
      isVisible={isVisible}
      onEnter={handleSave}
    >
      <Container>
        <Section>
          <SectionHeader>Name</SectionHeader>
          <StyledTextInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter a name..."
          />
        </Section>
        <Section>
          <SectionHeader>Color</SectionHeader>
          <Flex style={{ flexWrap: 'wrap' }}>
            {colorOptions.map((op) => (
              <ColorBlock
                flex
                c
                key={op}
                color={op}
                onClick={() => setColor(op)}
              >
                {(color === op || (!color && op === 'default')) && (
                  <FontAwesomeIcon
                    style={{ color: 'white' }}
                    icon={['fas', 'check']}
                  />
                )}
              </ColorBlock>
            ))}
          </Flex>
        </Section>

        <Button
          backgroundColor={colors.green}
          color="white"
          onClick={handleSave}
        >
          Save
        </Button>
      </Container>
    </Dialog>
  );
}

const { func, bool } = PropTypes;

LabelDialog.propTypes = {
  label: LabelProps,
  handleHideDialog: func.isRequired,
  isVisible: bool.isRequired,
};

LabelDialog.defaultProps = {};

export default LabelDialog;
