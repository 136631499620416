import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import React, { useState } from 'react';

import LoadingSpinner from './LoadingSpinner';
import {
  colors,
  lighten,
  fade,
  darken,
  getColorCombination,
} from './styles/colors';

function Button(props) {
  const {
    children,
    classes,
    className,
    enable,
    onClick,
    dataTippyContent,
    error,
    wide,
    icon,
    basic,
    leftIcon,
    rightIcon,
    thin,
    box,
    color,
    backgroundColor,
    style,
    transparent,
    svgIcon,
    fontSize,
    ...rest
  } = props;

  const [isLoading, setLoading] = useState(false);

  const classNameString = [
    ...classes,
    className,
    isLoading ? 'loading' : '',
  ].join(' ');

  const handleClick = (e) => {
    const promise = onClick(e);

    // If passed onClick function is a promise
    if (promise && promise.then) {
      // Set loading spinner and wait until response
      setLoading(true);
      promise.then((res) => {
        // Set loading and return the event
        setLoading(false);
        return new Promise((resolve) => resolve(res));
      });
    }
  };

  return (
    <StyledButton
      style={style}
      error={error}
      isLoading={isLoading}
      data-tippy-content={dataTippyContent}
      className={classNameString}
      disabled={!enable}
      onClick={handleClick}
      wide={wide}
      icon={icon}
      svgIcon={svgIcon}
      basic={basic}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      thin={thin}
      box={box}
      backgroundColor={backgroundColor}
      color={color}
      transparent={transparent}
      fontSize={fontSize}
      {...rest}
    >
      {isLoading && (
        <LoadingSpinnerContainer className="loading-spinner">
          <LoadingSpinner size={15} />
        </LoadingSpinnerContainer>
      )}
      {isLoading && <LoadingDiv>{children}</LoadingDiv>}
      {!isLoading && children}
    </StyledButton>
  );
}

const LoadingDiv = styled.div`
  opacity: 0;
`;

const StyledButton = styled.button`
  position: relative;

  cursor: pointer;
  user-select: none;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  min-height: 1em;
  height: 2.57142858em;

  line-height: 1em;
  padding: 0.78571429em 1.5em 0.78571429em;

  outline: 0;
  border: none;
  border-radius: 10rem;
  box-shadow: 0 0 0 1px transparent inset;

  font-family: 'Nunito', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  text-transform: none;
  text-shadow: none;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  white-space: nowrap;

  background: ${(props) => props.backgroundColor};
  color: ${(props) => props.color || colors.grey};

  :hover,
  :focus {
    background: ${(props) => darken(props.backgroundColor, 5)};
    color: ${(props) => props.color};
  }

  :active {
    background: ${(props) => darken(props.backgroundColor, 10)};
    color: ${(props) => props.color};
  }

  transition: all 0.1s ease-in-out;

  &.transparent {
    background-color: transparent;
    padding: 0.25em 0.5em;

    color: ${lighten(colors.grey, 15)};

    :hover,
    :focus {
      color: ${lighten(colors.grey, 10)};
    }

    :active {
      color: ${lighten(colors.grey, 5)};
    }
  }

  ${(props) =>
    props.wide &&
    css`
      display: flex;
      width: 100%;
    `}

  ${(props) =>
    props.isLoading &&
    css`
      &.left-icon i,
      svg {
        margin: 0;
      }
    `}

  ${(props) =>
    props.basic &&
    css`
      background: ${props.color
        ? getColorCombination(props.color).background
        : fade('black', 95)};
      box-shadow: 0 0 0 1px
        ${props.color
          ? lighten(getColorCombination(props.color).color, 30)
          : fade('black', 80)}
        inset;
      color: ${props.color
        ? getColorCombination(props.color).color
        : colors.grey};
      font-weight: 400;

      :hover,
      :focus {
        background: ${props.color
          ? darken(getColorCombination(props.color).background, 2)
          : fade('black', 92)};
        box-shadow: 0 0 0 2px
          ${props.color ? lighten(props.color, 30) : fade('black', 80)} inset;
        color: ${props.color
          ? getColorCombination(props.color).color
          : colors.grey};
      }

      :active {
        background: ${props.color
          ? darken(getColorCombination(props.color).background, 5)
          : fade('black', 90)};
        box-shadow: 0 0 0 2px
          ${props.color ? lighten(props.color, 30) : fade('black', 80)} inset;
        color: ${props.color
          ? getColorCombination(props.color).color
          : colors.grey};
      }
    `}

  ${(props) =>
    props.leftIcon &&
    css`
      display: flex;
      i,
      svg {
        margin-right: 10px;
      }
    `}

  ${(props) =>
    props.rightIcon &&
    css`
      display: flex;
      i,
      svg {
        margin-left: 10px;
      }
    `}
  
  ${(props) =>
    props.thin &&
    css`
      padding: 0.5em 1em;
      height: 2em;
    `}
  
  ${(props) =>
    props.box &&
    css`
      padding: 0.5em 1em;
      font-size: 16px;
      border-radius: 5px;

      text-align: left;
    `}

  ${(props) =>
    props.error &&
    css`
      color: ${lighten(colors.red, 10)};
      background: ${lighten(colors.red, 35)};
      :hover,
      :focus {
        background: ${lighten(colors.red, 30)};
      }

      :active {
        background: ${lighten(colors.red, 25)};
      }
    `}


  ${(props) =>
    props.transparent &&
    css`
      background-color: transparent;
      color: ${props.color || 'white'};

      :hover,
      :focus {
        background: ${fade('black', 95)};
      }

      :active {
        background: ${fade('black', 92)};
      }
    `}
  
  ${(props) =>
    props.disabled &&
    css`
      color: ${props.color ? fade(props.color, 50) : colors['light-grey-30']};
      background: ${props.backgroundColor
        ? fade(props.color, 80)
        : fade('black', 90)};
      cursor: default;

      border: none;
      box-shadow: none;

      &:hover,
      &:active,
      &:focus {
        color: ${props.color ? fade(props.color, 50) : colors['light-grey-30']};
        background: ${props.backgroundColor
          ? fade(props.color, 80)
          : fade('black', 90)};
        border: none;
        box-shadow: none;
      }

      &.basic {
        color: ${fade('white', 35)};
        box-shadow: 0 0 0 1px ${fade('white', 35)} inset;
        background: none;

        &:hover,
        &:active,
        &:focus {
          background: none;
          box-shadow: 0 0 0 1px ${fade('white', 35)} inset;
        }
      }
    `}

  ${(props) =>
    props.icon &&
    css`
      font-size: ${props.fontSize || 18}px;
      height: 2.25em;
      width: 2.25em;

      padding: 0;

      background: transparent;

      border-radius: 4px;

      :hover,
      :focus {
        background: ${fade('black', 97)};
      }

      :active {
        background: ${fade('black', 94)};
      }

      :disabled {
        background: transparent;
        opacity: 0.25;

        :hover,
        :focus,
        :active {
          background: transparent;
          opacity: 0.25;
        }
      }
    `}
`;

const LoadingSpinnerContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;

const {
  array,
  string,
  bool,
  func,
  element,
  oneOfType,
  arrayOf,
  node,
  shape,
  number,
} = PropTypes;

Button.propTypes = {
  classes: array,
  className: string,
  enable: bool,
  onClick: func,
  dataTippyContent: string,
  error: bool,
  wide: bool,
  icon: bool,
  basic: bool,
  leftIcon: bool,
  rightIcon: bool,
  thin: bool,
  box: bool,
  color: string,
  backgroundColor: string,
  children: oneOfType([arrayOf(node), node]),
  style: shape({}),
  transparent: bool,
  svgIcon: element,
  fontSize: number,
};

Button.defaultProps = {
  classes: [],
  className: '',
  enable: true,
  dataTippyContent: null,
  onClick: () => {},
  error: false,
  wide: false,
  icon: false,
  basic: false,
  leftIcon: false,
  rightIcon: false,
  thin: false,
  box: false,
  color: '',
  backgroundColor: colors['light-grey-50'],
  children: null,
  style: {},
  transparent: false,
};

export default Button;
