import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Box, { Flex } from '../../Box';
import Lightbox from '../../Lightbox';

const Object = styled.object`
  border-radius: 3px;
  overflow: hidden;

  max-width: 100%;
  max-height: 100%;
  object-fit: contain;

  width: 100%;
  height: 100%;
`;

const ObjectLightbox = styled.object`
  border-radius: 3px;
  overflow: hidden;

  width: 100%;
  height: 50vh;

  background: white;
`;

function SvgAsset(props) {
  const { file, showPreview, setShowPreview } = props;

  return (
    <Flex c style={{ height: '100%' }}>
      <Box style={{ pointerEvents: 'none', width: '100%', height: '100%' }}>
        <Object data={file.data.url} type="image/svg+xml" />
      </Box>
      <Lightbox
        isVisible={showPreview}
        handleHideLightbox={() => setShowPreview(false)}
      >
        <ObjectLightbox data={file.data.url} type="image/svg+xml" />
      </Lightbox>
    </Flex>
  );
}

SvgAsset.propTypes = {
  file: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  showPreview: PropTypes.bool,
  setShowPreview: PropTypes.func.isRequired,
};

SvgAsset.defaultProps = {
  file: {},
  showPreview: false,
};

export default SvgAsset;
