import React, { useState, useRef, useEffect, MutableRefObject } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import SvgStage from './SvgStage';
import Child from './elements/Child';
import SelectionMarquee from './SelectionMarquee';
import PencilPathEditor from './path/PencilPathEditor';

import useModifier from '../../app/hooks/useModifier';
import SvgStageChildren from './SvgStageChildren';
import Annotations from './Annotations';

import Box from '../Box';
import ElementProps from './props/ElementProps';

import create from '../../editor/create';
import OverlayStage from './OverlayStage';
import group from '../../app/editor/group';
import transformUtil from '../../app/editor/transform';
import {
  ElementsArrayProps,
  GroupElementProps,
  isGroupElement,
  MixedElementProps,
} from './types/ElementProps';
import { ToolOptionProps } from './tools';
import ErrorProps from './types/ErrorProps';
import RectEditor from './path/RectEditor';

// Can't for the of me figure out how to dynamically fit the <svg>
// subtracting the height of the toolbar
const Container = styled(Box)`
  position: relative;
  margin: 5px;
  min-height: 20%;
  flex: 1;
`;

type Props = {
  elements: ElementsArrayProps;
  selectedElements: ElementsArrayProps;
  selectedTool: ToolOptionProps;
  handleUpdateElement: (elementIds: string[]) => void;
  handleSelectElements?: (elements: ElementsArrayProps) => void;
  currentTime: number;
  handleStageClick?: (svg: SVGElement, e: MouseEvent) => void;
  handleStageMouseDown: (svg: SVGElement, e: React.MouseEvent) => void;
  handleStageMouseUp: (svg: SVGElement, e: React.MouseEvent) => void;
  handleStageDrag: (svg: SVGElement, e: React.DragEvent) => void;
  handleStageDrop?: (e: React.DragEvent) => Promise<void>;
  handleAddPath?: (
    e: MouseEvent,
    initialPoint: { x: number; y: number }
  ) => void;
  handleAddErrors: (errors: ErrorProps[]) => void;
  handleAddElement: (newElement: MixedElementProps) => void;
  handleSetCurrentTime: (newTime: number) => void;
  svgRef: MutableRefObject<SVGElement | null>;
  isPlaying: boolean;
  stageDimensions: {
    width: number;
    height: number;
  };
  annotations: any[];
  gridVisible?: boolean;
  className?: string;
  isAnnotating?: boolean;
};

/**
 * Viewer container that holds the stage
 */
function Viewer(props: Props) {
  const {
    elements,
    selectedElements,
    selectedTool,
    handleUpdateElement,
    handleSelectElements,
    currentTime,
    handleStageClick,
    handleStageMouseDown,
    handleStageMouseUp,
    handleStageDrag,
    handleStageDrop,
    handleAddPath,
    handleAddErrors,
    handleAddElement,
    handleSetCurrentTime,
    svgRef,
    gridVisible,
    className,
    isPlaying,
    stageDimensions,
    annotations,
    isAnnotating,
  } = props;

  const [containerGroup, setContainerGroup] = useState<GroupElementProps>();
  const shiftKeyDown = useModifier('shift').isDown;
  const [viewBox, setViewBox] = useState({
    x: 0,
    y: 0,
    w: stageDimensions.width,
    h: stageDimensions.height,
  });

  const selectedElementsRef = useRef<MixedElementProps[]>([]);

  const handlePencilMouseUp = (d: string) => {
    const animDuration = 1000;
    const newElement = create.path(
      {
        currentProps: {
          d,
        },
      },
      {
        animStart: currentTime,
        animDuration,
      }
    );

    handleAddElement(newElement);
    handleSetCurrentTime(currentTime + animDuration);
  };

  useEffect(() => {
    if (selectedElements.length > 1) {
      // Clone the selected elements and add them to the group
      const newContainerGroup = create.group({
        elements: [...selectedElements],
      });

      newContainerGroup.elements.forEach((el) => {
        if (el.cache.transform) {
          // Apply cached transform if it exists
          el.update('currentProps', {
            ...el.currentProps,
            ...el.cache.transform,
          });
        }

        // Save the current transform for later
        el.cacheTransform();

        // Merge all parent transforms acting on the element
        const parentTransforms = transformUtil.mergeParents(el, elements);

        // eslint-disable-next-line no-param-reassign
        el.update('currentProps', {
          // Temporarily update the current props with new merged transform while in the container
          ...el.currentProps,
          ...transformUtil.merge(el.currentProps, parentTransforms),
        });
      });

      setContainerGroup(newContainerGroup);
      // Save reference to the selected elements
      selectedElementsRef.current = [...selectedElements];
    } else {
      if (containerGroup) {
        selectedElementsRef.current.forEach((el) => {
          // Apply cached transform if it exists
          el.update('currentProps', {
            ...el.currentProps,
            ...el.cache.transform,
          });

          el.clearTransformCache();

          // Get merged parent transforms and swap locations to get resulting new transform on child
          const parentTransforms = transformUtil.mergeParents(el, elements);

          const newTransform = parentTransforms
            ? transformUtil.swapChildParent(
                parentTransforms,
                containerGroup.currentProps
              )
            : containerGroup.currentProps;

          el.update('currentProps', {
            ...el.currentProps,
            ...transformUtil.merge(el.currentProps, newTransform),
          });
        });
      }

      setContainerGroup(undefined);
      selectedElementsRef.current = [];
    }
    // eslint-disable-next-line
  }, [selectedElements]);

  const containerGroupElementIDs = containerGroup
    ? containerGroup.elements.map((el) => el.id)
    : null;

  // Elements that are not selected
  const filteredElements = containerGroup
    ? group.filterElements(
        elements,
        (el) => !containerGroupElementIDs?.includes(el.id)
      )
    : elements;

  const mappedElements = (filteredElements as ElementsArrayProps)
    .filter(
      (
        element:
          | MixedElementProps
          | { ref: MixedElementProps; elements?: ElementsArrayProps }
      ) => {
        const elementRef = (element.ref || element) as MixedElementProps;
        return (
          currentTime >= elementRef.start &&
          currentTime <= elementRef.start + elementRef.duration
        );
      }
    )
    .map((element) => {
      return (
        <Child
          key={element.id}
          element={element.ref || element}
          childElements={isGroupElement(element) ? element.elements : []}
          currentTime={currentTime}
          selectedElements={selectedElements}
          selectedTool={selectedTool}
          handleUpdateElement={handleUpdateElement}
          handleSelectElements={handleSelectElements}
          handleAddErrors={handleAddErrors}
          svgRef={svgRef}
          isPlaying={isPlaying}
          shiftKeyDown={shiftKeyDown}
        />
      );
    });

  return (
    <Container className={className}>
      <OverlayStage viewBox={viewBox} style={{ zIndex: 1 }}>
        <Annotations annotations={annotations} />
      </OverlayStage>

      {selectedTool === 'pencil' && (
        <OverlayStage viewBox={viewBox} style={{ zIndex: 1 }}>
          <PencilPathEditor
            svgRef={svgRef}
            onFinishDraw={handlePencilMouseUp}
            isNew={false}
          />
        </OverlayStage>
      )}

      {selectedTool === 'rect' && (
        <OverlayStage viewBox={viewBox} style={{ zIndex: 1 }}>
          <RectEditor
            svgRef={svgRef}
            handleAddElement={handleAddElement}
            // onFinishDraw={handlePencilMouseUp}
            // isNew={false}
          />
        </OverlayStage>
      )}

      <SelectionMarquee
        elements={elements}
        handleSelectElements={handleSelectElements}
        enabled={selectedTool === 'move' && !selectedElements.length}
        selectionBoxStyle={{
          border: '1px solid #00a8ff',
          background: 'rgba(0, 168, 255, 0.15)',
        }}
      >
        <SvgStage
          handleStageClick={handleStageClick}
          handleStageMouseDown={handleStageMouseDown}
          handleStageMouseUp={handleStageMouseUp}
          handleStageDrag={handleStageDrag}
          handleStageDrop={handleStageDrop}
          selectedTool={selectedTool}
          selectedElements={selectedElements}
          svgRef={svgRef}
          gridVisible={gridVisible}
          handleAddPath={handleAddPath}
          stageWidth={stageDimensions.width}
          stageHeight={stageDimensions.height}
          isAnnotating={isAnnotating}
          viewBox={viewBox}
          setViewBox={setViewBox}
          style={{ position: 'absolute' }}
        >
          <SvgStageChildren isAnnotating={isAnnotating}>
            {!!containerGroup && (
              <>
                {mappedElements}
                <Child
                  element={containerGroup}
                  childElements={containerGroup.elements}
                  currentTime={currentTime}
                  selectedElements={[containerGroup]}
                  selectedTool={selectedTool}
                  handleUpdateElement={handleUpdateElement}
                  handleSelectElements={handleSelectElements}
                  handleAddErrors={handleAddErrors}
                  svgRef={svgRef}
                  isPlaying={isPlaying}
                  shiftKeyDown={shiftKeyDown}
                />
              </>
            )}
            {!containerGroup && mappedElements}
          </SvgStageChildren>
        </SvgStage>
      </SelectionMarquee>
    </Container>
  );
}

const { bool, func, shape, instanceOf, number, string, arrayOf } = PropTypes;

Viewer.propTypes = {
  gridVisible: bool.isRequired,
  elements: arrayOf(ElementProps).isRequired,
  selectedElements: arrayOf(ElementProps).isRequired,
  handleUpdateElement: func.isRequired,
  currentTime: number.isRequired,
  isPlaying: bool.isRequired,
  svgRef: shape({
    current: instanceOf(Element),
  }).isRequired,
  handleStageClick: func,
  handleStageDrag: func,
  handleStageDrop: func,
  handleStageMouseDown: func,
  handleStageMouseUp: func,
  handleAddPath: func,
  selectedTool: string,
  handleSelectElements: func,
  className: string,
  stageDimensions: shape({
    height: number,
    width: number,
  }).isRequired,
  annotations: arrayOf(shape({})),
  isAnnotating: bool,
  handleAddErrors: func,
  handleAddElement: func,
  handleSetCurrentTime: func,
};

Viewer.defaultProps = {
  className: '',
  annotations: [],
  isAnnotating: false,
  selectedTool: '',
  handleStageMouseDown: () => {},
  hanldeStageClick: () => {},
  handleStageDrag: () => {},
  handleStageDrop: () => {},
  handleAddPath: () => {},
  handleSelectElements: () => {},
  handleAddErrors: () => {},
  handleAddElement: () => {},
  handleSetCurrentTime: () => {},
};

export default Viewer;
