import styled from 'styled-components/macro';

export const Container = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Needed for proper SVG sizing at different video widths / heightsexport const VideoContainer = styled.div`
export const VideoContainer = styled.div`
  position: relative;
  width: ${(props) => props.videoWidth};
  display: ${(props) => (props.visible ? 'none' : 'initial')};
`;
