import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import React, { useState, useContext, useEffect } from 'react';

import moment from 'moment';

import fetchUtil from '../../util/fetchUtil';

import { Store } from '../../state/store';
import { colors, fade, darken, lighten } from '../styles/colors';

import OptionSelector from '../OptionSelector';
import TaskDialog from '../projectContents/TaskDialog';

import { getDurationUntilDatetime } from '../../app/utilities/durations';

import Button from '../Button';
import Dialog from '../Dialog';
import { P, H3 } from '../styles/typography';

import TaskProps from '../props/TaskProps';

function TaskRow(props) {
  const { task, getAllTasks } = props;

  const {
    state: { team },
  } = useContext(Store);
  const [taskDialogVisible, setTaskDialogVisible] = useState(false);

  const duration = Math.ceil(
    getDurationUntilDatetime(task.data.dueDate, 'days')
  );
  let color = null;
  if (duration < 3) color = colors.orange;
  if (duration < 0) color = colors.red;

  let durationString = `${duration} days`;
  if (duration < 2) durationString = `${duration} day`;
  if (duration < 0) durationString = `${Math.abs(duration)} day ago`;
  if (duration < 1) durationString = `${Math.abs(duration)} days ago`;

  return (
    <Container>
      <Task onClick={() => setTaskDialogVisible(true)}>
        <Upper>
          <Text>{task.data.text}</Text>
          {task.data.dueDate && (
            <DateText color={color}>{durationString}</DateText>
          )}
        </Upper>
        <Lower>
          <Name>{task.user.fullName}</Name>
          {task.project && <Project>{task.project.name}</Project>}
        </Lower>
      </Task>
      <Dialog
        isVisible={taskDialogVisible}
        handleHideDialog={() => setTaskDialogVisible(false)}
      >
        <TaskDialog
          handleHideDialog={() => setTaskDialogVisible(false)}
          onAfterSave={getAllTasks}
          team={team}
          task={task}
        />
      </Dialog>
    </Container>
  );
}

TaskRow.propTypes = {
  task: TaskProps,
  getAllTasks: PropTypes.func,
};

function AdminTasks() {
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [addTaskDialogVisible, setAddTaskDialogVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedAssigner, setSelectedAssigner] = useState({});
  const [selectedProject, setSelectedProject] = useState({});

  const {
    state: { team },
  } = useContext(Store);

  const getAllTasks = () => {
    fetchUtil
      .post('/tasks/getAllTasks')
      .then((res) => {
        setTasks(res.tasks);

        const projectIDs = res.tasks.map((task) => task.data.projectID);
        if (projectIDs.length) {
          return fetchUtil.post('/admin/getProjects', {
            projectIDs,
          });
        }

        return [];
      })
      .then((res) => {
        setProjects(res.projects);
      });
  };

  useEffect(() => {
    getAllTasks();
  }, []);

  // Add user objects to each entry
  const mappedTasks = tasks.map((task) => ({
    ...task,
    user: team.find(
      (user) => user.userID.toString() === task.userID.toString()
    ),
    project: projects.find(
      (project) => project.projectID === task.data.projectID
    ),
  }));

  const allTasks = mappedTasks
    .filter((task) =>
      selectedAssigner.id === 'all' || !selectedAssigner.id
        ? true
        : task.data.creatorUserID === selectedAssigner.userID
    )
    .filter((task) =>
      selectedUser.id === 'all' || !selectedUser.id
        ? true
        : task.userID === selectedUser.userID
    )
    .filter((task) =>
      selectedProject.id === 'all' || !selectedProject.id
        ? true
        : task.project && task.project.projectID === selectedProject.projectID
    )
    .sort((a, b) => new Date(a.data.dueDate) - new Date(b.data.dueDate));

  const incomplete = allTasks.filter((task) => task.status === 1);

  const due = incomplete
    .filter(
      (task) =>
        task.data.dueDate &&
        moment(task.data.dueDate).diff(moment(), 'days') >= 3
    )
    .map((task) => (
      <TaskRow key={task.taskID} task={task} getAllTasks={getAllTasks} />
    ));

  const dueSoon = incomplete
    .filter((task) => {
      if (task.data.dueDate) {
        const dueDate = moment(task.data.dueDate).diff(moment(), 'days');
        if (dueDate < 3 && dueDate >= 0) return true;
      }

      return false;
    })
    .map((task) => (
      <TaskRow key={task.taskID} task={task} getAllTasks={getAllTasks} />
    ));

  const overdue = incomplete
    .filter(
      (task) =>
        task.data.dueDate &&
        moment(task.data.dueDate).diff(moment(), 'days') < 0
    )
    .map((task) => (
      <TaskRow key={task.taskID} task={task} getAllTasks={getAllTasks} />
    ));

  return (
    <Container>
      <Header>
        <Button thin box leftIcon onClick={() => setAddTaskDialogVisible(true)}>
          <FontAwesomeIcon icon={['fas', 'plus']} />
          Create task
        </Button>
        <Filters>
          <OptionSelector
            options={[
              { name: 'All', id: 'all' },
              ...team.map((teammate) => ({
                ...teammate,
                name: teammate.fullName,
                id: teammate.userID,
              })),
            ]}
            selectedOption={selectedAssigner}
            onSelectOption={(option) => setSelectedAssigner(option)}
            buttonClasses="thin box basic"
            placeholder="Filter by assigner..."
            hasAutocomplete={true}
            autocompletePlaceholder="Filter users..."
          />
          <OptionSelector
            options={[
              { name: 'All', id: 'all' },
              ...team.map((teammate) => ({
                ...teammate,
                name: teammate.fullName,
                id: teammate.userID,
              })),
            ]}
            selectedOption={selectedUser}
            onSelectOption={(option) => setSelectedUser(option)}
            buttonClasses="thin box basic"
            placeholder="Filter by assignee..."
            hasAutocomplete={true}
            autocompletePlaceholder="Filter users..."
          />
          <OptionSelector
            options={[
              { name: 'All', id: 'all' },
              ...projects.map((project) => ({
                ...project,
                name: project.name,
                id: project.projectID,
              })),
            ]}
            selectedOption={selectedProject}
            onSelectOption={(option) => setSelectedProject(option)}
            buttonClasses="thin box basic"
            placeholder="Filter by project..."
            hasAutocomplete={true}
            autocompletePlaceholder="Filter projects..."
          />
        </Filters>
      </Header>
      <Content>
        <Column>
          <ColumnHeader color={colors.grey}>
            <FontAwesomeIcon icon={['fad', 'calendar-alt']} />
            Due <DueTime>(&gt; 3 days)</DueTime>
          </ColumnHeader>
          {due}
        </Column>
        <Column>
          <ColumnHeader color={colors.orange}>
            <FontAwesomeIcon icon={['fad', 'calendar-alt']} />
            Due soon
          </ColumnHeader>
          {dueSoon}
        </Column>
        <Column>
          <ColumnHeader color={colors.red}>
            <FontAwesomeIcon icon={['fad', 'calendar-exclamation']} />
            Overdue
          </ColumnHeader>
          {overdue}
        </Column>
      </Content>
      <Dialog
        isVisible={addTaskDialogVisible}
        handleHideDialog={() => setAddTaskDialogVisible(false)}
      >
        <TaskDialog
          handleHideDialog={() => setAddTaskDialogVisible(false)}
          onAfterSave={getAllTasks}
        />
      </Dialog>
    </Container>
  );
}

const Container = styled.div``;
const Filters = styled.div`
  display: flex;
  margin-top: 15px;

  > * {
    margin-right: 10px;
  }
`;

const Header = styled.div`
  padding: 10px;
`;

const Name = styled(P)`
  color: ${colors['light-grey-20']};
`;

const Text = styled(P)``;

const Content = styled.div`
  display: flex;
`;

const Column = styled.div`
  flex: 1;

  margin: 10px;
`;
const ColumnHeader = styled(H3)`
  border-radius: 3px;
  padding: 5px 10px;

  i,
  svg {
    margin-right: 7px;
  }

  ${(props) =>
    props.color &&
    css`
      background: ${fade(props.color, 75)};
      color: ${darken(props.color, 10)};
    `}
`;

const Upper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Lower = styled.div``;

const Project = styled(P)`
  padding: 3px 5px;
  background: ${colors['light-grey-50']};
  border-radius: 3px;

  display: inline-block;
`;

const DateText = styled(P)`
  color: ${colors['light-grey-20']};

  ${(props) =>
    props.color &&
    css`
      color: ${fade(props.color, 25)};
    `}
`;

const Task = styled.div`
  padding: 5px 10px;
  margin: 10px;
  background: #f7f7f7;
  border-radius: 3px;

  cursor: pointer;

  :hover,
  :focus {
    background: ${lighten(colors.grey, 55)};
  }

  :active {
    background: ${lighten(colors.grey, 52)};
  }
`;

const DueTime = styled.span`
  color: ${fade(colors.grey, 40)};
`;

AdminTasks.propTypes = {};

AdminTasks.defaultProps = {};

export default AdminTasks;
