import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components/macro';

import { useHistory } from 'react-router-dom';

import { motion, AnimatePresence } from 'framer-motion';

import tippy from 'tippy.js';

import Tippy from '@tippyjs/react';
import { post } from '../../../util/fetchUtil';

import { fetchFile, downloadFile } from '../../../app/fileManager';
import sceneScreenshots from '../../../app/editor/sceneScreenshots';
import HOST from '../../../app/host';

import OptionSelector from '../../OptionSelector';
import Box, { Flex } from '../../Box';
import Checkbox from '../../Checkbox';
import Icon from '../../Icon';
import Button from '../../Button';
import Label from '../../Label';

import { H3, P } from '../../styles/typography';
import { colors, darken, fade, lighten } from '../../styles/colors';
import SceneProps from '../props/SceneProps';
import SceneExportProps from '../props/SceneExportProps';
import CommentProps from '../props/CommentProps';

const colorMap = {
  default: {
    color: '',
    bg: '',
  },
  red: {
    color: darken(colors.red, 20),
    bg: lighten(colors.red, 25),
  },
  orange: {
    color: darken(colors.orange, 20),
    bg: lighten(colors.orange, 25),
  },
  yellow: {
    color: darken(colors.yellow, 20),
    bg: lighten(colors.yellow, 20),
  },
  green: {
    color: darken(colors.green, 20),
    bg: lighten(colors.green, 35),
  },
  blue: {
    color: darken(colors.blue, 10),
    bg: lighten(colors.blue, 35),
  },
};

const Image = styled.div`
  width: '100%';
  flex: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const ExportPendingIcon = styled(FontAwesomeIcon)`
  color: ${colors['light-grey-40']};
  animation: ${spin} 0.5s linear infinite;
`;

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;

  margin: 15px;

  width: 300px;

  border-radius: 5px;

  background-color: ${colors['light-night-10']};
  box-shadow: 0 0 0 1px ${colors['light-night-20']} inset;

  transition: 0.1s all ease-in-out;

  overflow: hidden;

  :hover,
  :focus {
    box-shadow: 0 0 0 2px ${colors['light-night-30']} inset;
  }

  :active {
    background-color: ${lighten(colors.night, 5)};
  }

  ${(props) =>
    props.isSelected &&
    css`
      box-shadow: 0 0 0 3px ${colors['light-blue-20']} inset;
      :hover,
      :focus,
      :active {
        box-shadow: 0 0 0 3px ${colors['light-blue-20']} inset;
      }
    `}
`;
const CheckButton = styled(Box)`
  position: absolute;
  top: 10px;
  left: 10px;
  opacity: ${(props) => (props.isSelected ? 1 : 0)};

  transition: 0.1s all ease-in-out;

  z-index: 1;

  ${Container}:hover & {
    opacity: 1;
  }
`;

const CircleButton = styled(Button)`
  border-radius: 100%;
  font-size: 12px;
`;

function SceneCard(props) {
  const {
    scene,
    link,
    handleDelete,
    handleCloneScene,
    projectID,
    sceneExports,
    getScenes,
    comments,
    isSelected,
    canSelect,
    handleSelectScene,
    labels,
  } = props;

  const sortedSceneExports = sceneExports.sort(
    (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
  );

  const latestExport = sortedSceneExports
    .filter((ex) => ex.status === 2)
    .sort(
      (a, b) => new Date(b.data.completedOn) - new Date(a.data.completedOn)
    )[0];

  const isProcessing =
    sortedSceneExports.length && sortedSceneExports[0].status === 1;

  const history = useHistory();

  const handleGetThumbnail = async (e) => {
    e.stopPropagation();
    const screenshots = await sceneScreenshots([
      {
        s3Key: scene.data.s3Key,
        time:
          scene.data.duration > 5000
            ? scene.data.duration - 5000
            : scene.data.duration,
        temp: false,
      },
    ]);

    await post('scene/updateThumbnail', {
      sceneID: scene.sceneID,
      thumbnail: screenshots[0].url,
    });

    getScenes();
  };

  const handleDownloadExport = (e) => {
    e.stopPropagation();
    if (latestExport) {
      fetchFile(latestExport.data.url).then((blob) => {
        downloadFile(blob, `${scene.data.name}.mp4`);
      });
    }
  };

  const handleAddLabel = async (label) => {
    await post('/scene/addSceneLabel', {
      sceneID: scene.sceneID,
      labelID: label.labelID,
    });

    getScenes();
  };

  const handleRemoveLabel = async (e, label) => {
    e.stopPropagation();

    await post('/scene/removeSceneLabel', {
      sceneID: scene.sceneID,
      labelID: label.labelID,
    });

    getScenes();
  };

  const handleDeleteClick = () => {
    handleDelete(scene);
  };

  const handleExportScene = async () => {
    const res = await post('/export/addSceneExport', {
      sceneIDs: [scene.sceneID],
    });

    await post('/exportVideoV2', {
      sceneExportIDs: res.sceneExportIDs,
      projectID,
      notify: true,
    });

    await getScenes();
  };

  const handleReviewScene = async () => {
    let { reviewUrl } = scene.data;
    if (!reviewUrl) {
      ({ reviewUrl } = await post('/scene/generateSceneReviewUrl', {
        sceneID: scene.sceneID,
      }));
    }

    window.open(`${HOST}/review/scene/${scene.data.name}-${reviewUrl}`, {
      target: '_blank',
    });
  };

  const handleCheckboxCheck = (e) => {
    e.stopPropagation();
    handleSelectScene(scene);
  };

  const handleCardClick = (e) => {
    e.stopPropagation();
    history.push(link);
  };

  useEffect(() => {
    tippy('[data-tippy-content]');
  }, [isProcessing]);

  const sceneLabels = labels.filter(
    (label) =>
      scene.data.labels &&
      scene.data.labels.some((labelID) => labelID === label.labelID)
  );

  const activeComments = comments?.filter((c) => c.data.status === 1);
  const resolvedComments = comments?.filter((c) => c.data.status === 2);

  return (
    <Container
      onClick={canSelect ? () => handleSelectScene(scene) : handleCardClick}
      isSelected={isSelected}
    >
      <CheckButton isSelected={isSelected} onClick={handleCheckboxCheck}>
        <Checkbox
          checked={isSelected}
          onChange={() => {}}
          color={lighten(colors.blue, 20)}
          checkColor={'white'}
          size={22}
          onClick={(e) => e.stopPropagation()}
        />
      </CheckButton>
      <Box
        style={{
          width: '100%',
          flex: 1,
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Image>
          {!scene.data.thumbnail && (
            <Button
              box
              transparent
              style={{ color: fade('white', 50) }}
              onClick={handleGetThumbnail}
            >
              Get thumbnail
            </Button>
          )}
          {scene.data.thumbnail && (
            <img alt="Thumbnail" src={scene.data.thumbnail} />
          )}
        </Image>
      </Box>
      <Box
        style={{
          padding: 15,
          borderTop: `1px solid ${colors['light-night-20']}`,
        }}
      >
        <Flex alignItems="center" style={{ justifyContent: 'space-between' }}>
          <Flex c>
            <H3 style={{ color: 'white', opacity: 0.8 }}>{scene.data.name}</H3>
          </Flex>
          <Flex c>
            {!scene.data.label && (
              <Box style={{ marginRight: 5 }} data-tippy-content="Add label">
                <OptionSelector
                  options={labels}
                  header="Add Label"
                  onSelectOption={handleAddLabel}
                  selectedOption={null}
                >
                  <CircleButton icon color={fade('white', 50)}>
                    <FontAwesomeIcon icon={['fas', 'plus']} />
                  </CircleButton>
                </OptionSelector>
              </Box>
            )}

            {!!activeComments.length && (
              <Tippy content={`${comments.length} comments`}>
                <Box color={lighten(colors.red, 20)}>
                  <FontAwesomeIcon icon={['fad', 'comment-exclamation']} />
                </Box>
              </Tippy>
            )}

            {!!resolvedComments.length && !activeComments.length && (
              <Tippy content="All comments resolved">
                <Box color={lighten(colors.green, 20)}>
                  <FontAwesomeIcon icon={['fad', 'comment-check']} />
                </Box>
              </Tippy>
            )}

            <Box style={{ display: 'inline-flex' }}>
              <OptionSelector
                onClick={(e) => e.stopPropagation()}
                options={[
                  {
                    id: 'review',
                    name: 'Review',
                    onClick: handleReviewScene,
                  },
                  {
                    id: 'duplicate',
                    name: 'Duplicate',
                    onClick: () => handleCloneScene(scene),
                  },
                  {
                    id: 'export',
                    name: 'Export',
                    onClick: handleExportScene,
                  },
                  {
                    id: 'delete',
                    name: 'Delete',
                    onClick: handleDeleteClick,
                  },
                ]}
              >
                <Button icon transparent>
                  <FontAwesomeIcon icon={['fas', 'ellipsis-v']} />
                </Button>
              </OptionSelector>
            </Box>
          </Flex>
        </Flex>
        <Box>
          <AnimatePresence initial={false}>
            {sceneLabels.length && (
              <Box style={{ marginBottom: 10, display: 'inline-block' }}>
                {!!sceneLabels.length &&
                  sceneLabels.map((label) => (
                    <motion.div
                      initial={{ scale: 0.5, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{
                        scale: 0.5,
                        opacity: 0,
                        transition: { easing: 'ease-in-out' },
                      }}
                      key={label.labelID}
                    >
                      <Label
                        round
                        onClose={(e) => handleRemoveLabel(e, label)}
                        color={colorMap[label.data.color].color}
                        backgroundColor={colorMap[label.data.color].bg}
                      >
                        {label.name}
                      </Label>
                    </motion.div>
                  ))}
              </Box>
            )}
          </AnimatePresence>
          {!!sceneExports.length && (
            <Flex alignItems="center">
              <Box style={{ marginRight: 5 }}>
                {!isProcessing && (
                  <Box data-tippy-content="Download MP4">
                    <Button
                      icon
                      onClick={(e) => handleDownloadExport(e)}
                      style={{
                        color: fade('white', 30),
                        fontSize: 14,
                      }}
                    >
                      <Icon icon="download" />
                    </Button>
                  </Box>
                )}
                {isProcessing && (
                  <Box
                    style={{ marginRight: 5, display: 'flex' }}
                    data-tippy-content="Export in progress"
                  >
                    <ExportPendingIcon icon={['fad', 'spinner-third']} />
                  </Box>
                )}
              </Box>
              <P style={{ color: fade('white', 50) }}>{scene.data.name}.mp4</P>
            </Flex>
          )}
        </Box>
      </Box>
    </Container>
  );
}

const { string, number, shape, func, arrayOf, bool } = PropTypes;

SceneCard.propTypes = {
  scene: SceneProps.isRequired,
  link: string.isRequired,
  handleDelete: func.isRequired,
  handleCloneScene: func.isRequired,
  getScenes: func.isRequired,
  projectID: number.isRequired,
  sceneExports: arrayOf(SceneExportProps),
  comments: arrayOf(CommentProps),
  handleSelectScene: func.isRequired,
  isSelected: bool,
  canSelect: bool,
  labels: arrayOf(shape({})),
};

SceneCard.defaultProps = {
  comments: [],
  isSelected: false,
  canSelect: false,
  labels: [],
  sceneExports: [],
};

export default SceneCard;
