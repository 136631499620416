import React, { useState, useEffect } from 'react';
import { post } from '../../util/fetchUtil';
import Box from '../Box';
import Text from '../Text';

interface Props {}

const AdminStats = (props: Props) => {
  const [stats, setStats] = useState({
    projects: 0,
  });

  const init = async () => {
    const res = await post('/admin/stats').catch((err) => console.log(err));

    setStats(res.data);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Box p={4}>
      {Object.entries(stats).map(([key, value]) => (
        <Box key={key} mb={3}>
          <Text as="h3" color="dark-6">
            {key}
          </Text>
          <Text as="h1">{value}</Text>
        </Box>
      ))}
    </Box>
  );
};

export default AdminStats;
