import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState } from 'react';

import Dialog from '../../Dialog';
import Permissions from '../../dialogs/Permissions';

import { colors, lighten } from '../../styles/colors';

function AddPermissions(props) {
  const { user, handleSavePermissions } = props;

  const [dialogVisible, setDialogVisible] = useState(false);

  const handleToggleDialog = () => setDialogVisible(!dialogVisible);

  const savePermissions = () =>
    handleSavePermissions().then(() => setDialogVisible(false));

  return (
    <Wrapper>
      <Dialog isVisible={dialogVisible} handleHideDialog={handleToggleDialog}>
        <Permissions
          permissions={user.permissions}
          userID={user.userID}
          userName={user.fullName}
          onSave={savePermissions}
          handleToggleDialog={handleToggleDialog}
        />
      </Dialog>
      <Container className="left-icon box thin" onClick={handleToggleDialog}>
        {!!user.permissions.length && (
          <div>
            <span>Edit</span>
            <FontAwesomeIcon icon={['fad', 'edit']} />
          </div>
        )}
        {!user.permissions.length && (
          <div>
            <span>Add</span>
            <FontAwesomeIcon icon={['fas', 'plus']} />
          </div>
        )}
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: inline-flex;
`;

const Container = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  padding: 3px 6px;

  background: ${lighten(colors.grey, 55)};
  color: ${colors['light-grey-10']};

  margin-right: 5px;

  cursor: pointer;

  :hover,
  :focus {
    background: ${lighten(colors.grey, 50)};
  }

  :active {
    background: ${lighten(colors.grey, 45)};
  }

  i,
  svg {
    color: ${colors['light-grey-20']};
    margin-left: 10px;
  }
`;

AddPermissions.propTypes = {
  user: PropTypes.shape({
    fullName: PropTypes.string,
    userID: PropTypes.number,
    permissions: PropTypes.array,
    roles: PropTypes.array,
  }).isRequired,
  handleSavePermissions: PropTypes.func.isRequired,
};

AddPermissions.defaultProps = {};

export default AddPermissions;
