import PropTypes from 'prop-types';
import React, { useState, memo } from 'react';

import fetchUtil from '../../../util/fetchUtil';

import notifications from '../../../app/notifications/notifications';

import { getArrayString } from '../../../utilities/array';

import Box, { Flex } from '../../Box';
import CommentsHeader from './CommentsHeader';
import CommentTextArea from '../../comments/CommentTextArea';
import CommentWrapper from '../../comments/CommentWrapper';

import CommentProps from '../props/CommentProps';
import SceneProps from '../props/SceneProps';
import ToolProps from '../props/ToolProps';
import { UserProps } from '../../../props/user';
import { AnnotationProps } from '../props/scene';

import { colors, darken } from '../../styles/colors';
import { P } from '../../styles/typography';

/**
 * Panel that contains the comment panel for the editor
 */
const EditorCommentPanel = (props) => {
  const {
    scene,
    currentTime,
    comments,
    handleGetComments,
    activeTool,
    setActiveTool,
    annotations,
    onClickComment,
    selectedCommentID,
    setSelectedCommentID,
    team,
    showTools,
  } = props;

  const [mentionedUsers, setMentionedUsers] = useState([]);
  const [commentInput, setCommentInput] = useState('');

  const activeComments = comments.filter(
    (comment) => comment.data.status === 1
  );

  const resolvedComments = comments.filter(
    (comment) => comment.data.status === 2
  );

  const handleSelectUser = (value, mentionedUser) => {
    // Add new mentioned user if it doesn't already exist in list
    const newMentionedUsers = [...mentionedUsers];
    if (!mentionedUsers.find((user) => user.userID === mentionedUser.userID)) {
      newMentionedUsers.push(mentionedUser);
    }

    setCommentInput(value);
    setMentionedUsers(newMentionedUsers);
  };

  const handleSetCommentInput = (text) => {
    setCommentInput(text);
  };

  const handleDeleteComment = (commentID) => async (e) => {
    e.stopPropagation();

    // Remove this comment and all child reply comments
    const commentIDs = [
      commentID,
      ...comments
        .filter((comment) => comment.data.parentCommentID === commentID)
        .map((comment) => comment.commentID),
    ];

    await fetchUtil.post('/editor/deleteSceneComments', {
      commentIDs,
    });

    handleGetComments();
  };

  const handleResolveComment = (commentID) => async (event) => {
    event.stopPropagation();

    const comment = comments.find((c) => c.commentID === commentID);
    let resolved = true;
    if (comment.data.status === 2) resolved = false;

    await fetchUtil.post('/editor/resolveSceneComment', {
      commentID,
      resolved,
    });

    handleGetComments();
  };

  const handleSelectComment = () => {};

  const handleUpdateComment = async (commentID, newText) => {
    await fetchUtil.post('/editor/updateSceneCommentText', {
      commentID,
      text: newText,
    });

    handleGetComments();
  };

  const handleClickComment = (e, comment) => {
    e.nativeEvent.stopImmediatePropagation();
    setSelectedCommentID(comment.commentID);

    onClickComment(comment);
  };

  const handleSaveReply = async (
    replyText,
    parentCommentID,
    replyToCommentID
    // mentionedUsers
  ) => {
    if (replyText === '') return;

    const res = await fetchUtil.post('/editor/saveSceneComment', {
      sceneID: scene.sceneID,
      text: replyText,
      parentCommentID,
      replyToCommentID,
      annotations,
    });

    // Send comment reply notifications
    const replyToUserID = comments.find(
      (c) => c.commentID === replyToCommentID
    ).userID;

    if (replyToUserID !== window.userID) {
      await notifications.send({
        recipientUserID: replyToUserID,
        action: 'sceneCommentReply',
        actionData: {
          commentingUserID: window.userID,
          sceneID: scene.sceneID,
          parentCommentID,
          replyCommentID: res.comment.commentID,
          replyCommentText: res.comment.data.text,
        },
      });
    }

    handleGetComments();
  };

  const handleClickOutside = (comment) => {
    if (selectedCommentID === comment.commentID) setSelectedCommentID(null);
  };

  const handleSaveComment = async () => {
    const res = await fetchUtil.post('/editor/saveSceneComment', {
      sceneID: scene.sceneID,
      text: commentInput,
      timestamp: currentTime,
      annotations,
    });

    // Send mention notifications
    const promises = [];
    for (let i = 0; i < mentionedUsers.length; i += 1) {
      promises.push(
        notifications.send({
          recipientUserID: mentionedUsers[i].userID,
          action: 'sceneCommentMention',
          actionData: {
            commentingUserID: window.userID,
            sceneID: scene.sceneID,
            parentCommentID: res.comment.commentID,
            replyCommentID: res.comment.commentID,
            replyCommentText: res.comment.data.text,
          },
        })
      );
    }

    setCommentInput('');

    handleGetComments();
  };

  const options = team.map((u) => ({
    ...u,
    id: u.userID,
    name: u.fullName,
    text: `@${u.fullName}`,
    subheader: u.roles ? getArrayString(u.roles, ' ,') : null,
  }));

  return (
    <Box style={{ flex: 1, margin: 5 }}>
      <Flex style={{ justifyContent: 'space-between', paddingBottom: 5 }}>
        <CommentsHeader
          handleSave={handleSaveComment}
          canSave={!!annotations.length || !!commentInput}
          toolsVisible={showTools}
          activeTool={activeTool}
          setActiveTool={setActiveTool}
        />
      </Flex>
      <CommentTextArea
        value={commentInput}
        onSelect={handleSelectUser}
        onChange={handleSetCommentInput}
        onEnterPress={handleSaveComment}
        placeholder="New comment..."
        options={options}
        darkMode={true}
      />
      {activeComments &&
        activeComments
          .reverse() // Show comments from oldest to most recent
          .filter((comment) => !comment.data.parentCommentID) // Show only comments without parent comments
          .map((comment) => {
            // Pass any comments that are replies to this comment
            const replies = comments.filter(
              (c) => c.data.parentCommentID === comment.commentID
            );

            // Check if this comment or one of this comment's replies are selected
            const isSelected = !![comment, ...replies].find(
              (c) => c.commentID === selectedCommentID
            );

            return (
              <div
                key={comment.commentID}
                onClick={(e) => handleClickComment(e, comment)}
              >
                <CommentWrapper
                  replies={replies}
                  comment={comment}
                  totalComments={activeComments.length}
                  isSelected={isSelected}
                  handleDeleteComment={handleDeleteComment}
                  handleResolveComment={handleResolveComment}
                  handleSelectComment={handleSelectComment}
                  handleSaveReply={handleSaveReply}
                  handleUpdateComment={handleUpdateComment}
                  darkMode={true}
                  onClickOutside={handleClickOutside}
                />
              </div>
            );
          })}
      {!!resolvedComments.length && (
        <Box
          style={{
            padding: '5px 10px',
            backgroundColor: darken(colors.night, 5),
            borderRadius: 3,
            margin: '10px 0',
          }}
        >
          <P darkMode>Resolved</P>
        </Box>
      )}
      {!!resolvedComments.length &&
        resolvedComments.reverse().map((comment) => {
          // Pass any comments that are replies to this comment
          const replies = comments.filter(
            (c) => c.data.parentCommentID === comment.commentID
          );
          return (
            <div
              key={comment.commentID}
              onClick={(e) => handleClickComment(e, comment)}
            >
              <CommentWrapper
                key={comment.commentID}
                comment={comment}
                replies={replies}
                totalComments={resolvedComments.length}
                isSelected={selectedCommentID === comment.commentID}
                isResolved={true}
                resolved={comment.data.resolved}
                handleDeleteComment={handleDeleteComment}
                handleResolveComment={handleResolveComment}
                handleSaveReply={handleSaveReply}
                handleUpdateComment={handleUpdateComment}
                onClickOutside={handleClickOutside}
                darkMode={true}
              />
            </div>
          );
        })}
    </Box>
  );
};

const { arrayOf, string, func, bool, number } = PropTypes;

EditorCommentPanel.propTypes = {
  isAnnotating: bool,
  showTools: bool,
  scene: SceneProps.isRequired,
  currentTime: number.isRequired,
  comments: arrayOf(CommentProps),
  handleGetComments: func,
  activeTool: string,
  setActiveTool: func,
  tools: arrayOf(ToolProps),
  annotations: arrayOf(AnnotationProps),
  onClickComment: func,
  selectedCommentID: number,
  setSelectedCommentID: func,
  team: arrayOf(UserProps),
  isPlaying: PropTypes.bool.isRequired,
};

EditorCommentPanel.defaultProps = {
  isAnnotating: false,
  showTools: true,
  comments: [],
  activeTool: '',
  tools: [],
  annotations: [],
  team: [],
  handleGetComments: () => {},
  onClickComment: () => {},
  setSelectedCommentID: () => {},
  setActiveTool: () => {},
};

export default memo(EditorCommentPanel, (prevProps, nextProps) => {
  if (nextProps.isPlaying || nextProps.isAnnotating) return true;
  return false;
});
