import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import { ChildrenProps } from '../../props/general';

const StyledSvg = styled.svg`
  pointer-events: none;
  position: absolute;

  ${(props) =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
`;

/**
 * A general stage to draw on that allows bottom-most stage to handle events
 */
function OverlayStage(props) {
  const { className, viewBox, children, cursor, style } = props;

  return (
    <StyledSvg
      viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`}
      cursor={cursor}
      width="100%"
      height="100%"
      className={className}
      style={style}
    >
      {children}
    </StyledSvg>
  );
}

const { shape, string, number } = PropTypes;

OverlayStage.propTypes = {
  className: string,
  children: ChildrenProps,
  viewBox: shape({
    width: number,
    height: number,
  }).isRequired,
  cursor: string,
  style: shape({}),
};

OverlayStage.defaultProps = {
  className: '',
  cursor: '',
  style: {},
};

export default OverlayStage;
