const getLegacyScene = (node) => {
  if (node.children && node.children.length > 1) {
    const sceneIndex = node.children.findIndex(
      (c) => c.attributes.id && c.attributes.id.toLowerCase() === 'scene'
    );
    const animationIndex = node.children.findIndex(
      (c) => c.attributes.id && c.attributes.id.toLowerCase() === 'animation'
    );

    if (node.children[sceneIndex] && node.children[animationIndex]) {
      const scene = node.children[sceneIndex];
      // Remove the scene group
      node.children.splice(sceneIndex, 1);

      return scene;
    }
  }

  return null;
};

export default { getLegacyScene };
