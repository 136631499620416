import { isOnDesktop } from '../../app/device';
import font from '../../app/font';

export const loadAssets = async (dispatch) => {
  if (isOnDesktop()) {
    await font.init();
  }

  return dispatch({
    type: 'LOAD_ASSETS',
    payload: true,
  });
};

export const setSceneDimensions = (dimensions, dispatch) =>
  dispatch({
    type: 'SET_DIMENSIONS',
    payload: dimensions,
  });

export const setObjects = (objects, dispatch) =>
  dispatch({
    type: 'SET_OBJECTS',
    payload: objects,
  });

export const setActiveFile = (activeFile, dispatch) =>
  dispatch({
    type: 'SET_ACTIVE_FILE',
    payload: activeFile,
  });

export const setLocalFile = (localFile, dispatch) =>
  dispatch({
    type: 'SET_LOCAL_FILE',
    payload: localFile,
  });

export const setSceneMode = (sceneMode, dispatch) =>
  dispatch({
    type: 'SET_SCENE_MODE',
    payload: sceneMode,
  });
