import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, SetStateAction } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { colors } from '../../styles/colors';
import VideoPlayer from '../../VideoPlayer';
import usePlayer from '../../../app/hooks/usePlayer';
import Lightbox from '../../Lightbox';
import FileProps from '../../editor/types/FileProps';

const ImageContainer = styled.div`
  width: 75%;
  height: 75%;
  display: flex;
  justify-content: center;

  margin: auto;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  color: ${colors['light-grey-30']};

  i,
  svg {
    font-size: 32px;
  }
`;

type VideoLightboxProps = {
  file: FileProps;
};

function VideoLightbox(props: VideoLightboxProps) {
  const { file } = props;
  // Initialize video player
  const [videoRef] = usePlayer({
    src: file.data && file.data.url,
    autoplay: false,
    options: {
      playbackRates: [1, 1.5, 2],
      // Fills the container
      fill: true,
    },
  });

  return (
    <ImageContainer>
      <VideoPlayer videoRef={videoRef} />
    </ImageContainer>
  );
}

VideoLightbox.propTypes = {
  file: PropTypes.shape({
    data: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
};

type Props = {
  file: any;
  showPreview: boolean;
  setShowPreview: Dispatch<SetStateAction<boolean>>;
};

function VideoAsset(props: Props) {
  const { file, showPreview, setShowPreview } = props;

  return (
    <Container>
      <FontAwesomeIcon icon={['fad', 'camera-movie']} />
      <Lightbox
        handleHideLightbox={() => setShowPreview(false)}
        isVisible={showPreview}
      >
        <VideoLightbox file={file} />
      </Lightbox>
    </Container>
  );
}

VideoAsset.defaultProps = {
  file: {},
  showPreview: false,
};

export default VideoAsset;
