import React from 'react';

import { memo } from 'react';

import TimelineRowWrapper from './TimelineRowWrapper';

/**
 * A wrapper for the timeline rows that gets memoized when we're scrubbing or playing the animations
 */
const TimelineRowContainer = (props) => {
  const {
    elements,
    elementsToUpdate,
    selectedElements,
    handleUpdateElement,
    duration,
    pixelsPerMs,
    rowHeight,
    handleSelectKeyframes,
    selectedKeyframes,
  } = props;

  return elements.map((element) => (
    <TimelineRowWrapper
      height={rowHeight}
      key={element.id}
      element={element}
      isSelected={selectedElements.find((e) => e.id === element.id)}
      elementsToUpdate={elementsToUpdate}
      selectedElements={selectedElements}
      pixelsPerMs={pixelsPerMs}
      handleUpdateElement={handleUpdateElement}
      handleSelectKeyframes={handleSelectKeyframes}
      selectedKeyframes={selectedKeyframes}
      duration={duration}
    />
  ));
};

// Memoize the timeline rows when we're actively playing or scrubbing the timeline
export default memo(TimelineRowContainer, (prevProps, nextProps) => {
  if (nextProps.isPlaying) return true;
  return false;
});
