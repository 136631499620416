// WARNING: ANY CHANGES TO THIS FILE SHOULD ALSO BE CHANGED IN local/osmosify/app/grantsUtil
// Is supposed to be isomorphic but (I think) TS causes the build to fail when trying
// to import this as a commonjs file.

const levels = {
  view: 0,
  edit: 1,
};

export const grants = [
  {
    name: 'content.assets',
    levels: ['view', 'edit'],
    description: 'Access, download and/or modify assets in the asset manager.',
  },
  {
    name: 'content.projects',
    levels: ['view', 'edit'],
    description: 'Access and/or modify projects.',
  },
  {
    name: 'content.osmosis',
    levels: ['view', 'edit'],
    description: 'Access to Osmosis projects.',
  },
  {
    name: 'admin.projects',
    description:
      'Admin level priveleges for projects, like delete and archive.',
  },
  {
    name: 'admin.permissions',
    description: "Add or change other users's permissions.",
  },
  {
    name: 'admin.users',
    description: 'Change user settings.',
  },
  {
    name: 'admin.folders',
    description: 'Edit and delete and project folders.',
  },
  {
    name: 'admin.analytics',
    description: 'Access analytics and stats for users.',
  },
  {
    name: 'admin.errors',
    description: 'Access log of client and server errors.',
  },
  {
    name: 'admin.resources',
    description: 'Delete and edit external resources.',
  },
  {
    name: 'admin.tasks',
    description: 'Delete and edit user tasks.',
  },
  {
    name: 'admin.reports',
    description: 'Get reports from the database.',
  },
  {
    name: 'admin.assets',
    description: 'Delete and manage files.',
  },
  {
    name: 'admin.launch',
    description: 'EC2 instances, etc.',
  },
  {
    name: 'admin.exports',
    description: 'File exports.',
  },
  {
    name: 'admin.labels',
    description: 'Scene labels.',
  },
  {
    name: 'admin.feedback',
    description: 'Submitted feedback forms.',
  },
];

export const parse = (grant) => {
  const [type, rest] = grant.split('.');
  const [subtype, level] = (rest || '').split('-');

  return {
    full: grant, // e.g. content.projects-edit
    name: `${type}.${subtype}`, // e.g. content.projects
    type, // e.g. admin, content
    subtype, // e.g. projects, assets
    level, // e.g. view, edit
  };
};

export const isAllowed = (grant, userGrants) => {
  const parsedGrant = parse(grant);

  if (userGrants.includes('root')) return true;

  if (userGrants) {
    const matchingGrants = userGrants
      .filter(
        (g) => g.includes(parsedGrant.type) && g.includes(parsedGrant.subtype)
      )
      .map((g) => parse(g));

    for (let i = 0; i < matchingGrants.length; i += 1) {
      const userGrant = matchingGrants[i];

      if (!parsedGrant.level) return true; // If the parsed grant doesn't have a level defined but we matched the type and subtype
      if (levels[userGrant.level] >= levels[parsedGrant.level]) return true;
    }
  }

  return false;
};

export default {
  parse,
  grants,
  isAllowed,
};
