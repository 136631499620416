import { Path } from 'paper';
import { parse } from 'svgson';

/* eslint-disable no-param-reassign */
import uuid from '../utilities/uuid';
import create from '../../editor/create';
import importText from './import/importText';
import importImage from './import/importImage';
import importPath from './import/importPath';
import getElementArgs from './import/getElementArgs';

import legacySvgParser from './legacySvgParser';

import uploadImages from './import/uploadImages';
import { stringToObject, decomposeMatrix, isFlipped } from '../matrix';
import { isNumber } from '../../utilities/numbers';
import { partition } from '../../utilities/array';
import { kebabToCamel } from '../utilities/string';
import { pxToNumber } from '../utilities/styles';
import { ungroupByName } from './group';
import { toAbsolute } from './path';
import { rotate } from '../trig';

const ignoreKeywords = ['area', 'safety'];

export const parseViewBox = (viewBoxString) => {
  const [x, y, width, height] = viewBoxString.split(' ').map((val) => +val);
  return { x, y, width, height };
};

export const getTransform = (node, previousTransform) => {
  const matrix = stringToObject(node.attributes.transform);
  const newTransform = decomposeMatrix(matrix);
  const invert = isFlipped(newTransform) ? -1 : 1;

  // Transform to (0, 0) and adjust for rotation
  const x = node.attributes.x || 0;
  const y = node.attributes.y || 0;
  const { dx, dy } = rotate(x, y, newTransform.rotate);

  const transform = {
    ...previousTransform,
    ...newTransform,
    scaleX: newTransform.scaleX * invert,
    translateX:
      x * newTransform.scaleX +
      newTransform.translateX +
      dx * newTransform.scaleX,
    translateY:
      y * newTransform.scaleY +
      newTransform.translateY +
      dy * newTransform.scaleY,
  };

  return transform;
};

const createEraseMask = (
  node,
  startTime,
  duration,
  currentTime,
  dimensions
) => {
  const paperPath = new Path({});
  paperPath.setPathData(
    'M123.423,2115.81c0,0 -52.983,-1811.02 49.49,-1958.38c102.473,-147.363 59.755,2024.65 147.98,2035.58c88.225,10.924 57.301,-2053.36 152.43,-2049.28c95.129,4.08 107.988,2024.68 186.749,2027.58c78.761,2.903 40.915,-2053.45 119.289,-2045.58c78.375,7.869 123.469,2064.33 183.655,2067.28c60.185,2.949 40.877,-2103.05 103.179,-2083.14c62.302,19.908 90.235,2092.03 152.787,2083.14c62.551,-8.894 67.369,-2076.53 117.177,-2081.01c91.931,-8.272 51,2064.56 134.42,2081.01c87.884,17.336 57.301,-2053.36 152.43,-2049.28c95.129,4.08 107.988,2024.68 186.749,2027.58c78.761,2.903 40.915,-2053.45 119.29,-2045.58c78.374,7.869 123.468,2064.33 183.654,2067.28c60.185,2.949 40.877,-2103.05 103.179,-2083.14c62.302,19.908 90.235,2092.03 152.787,2083.14c62.552,-8.894 33.914,-2075.01 83.669,-2081.01'
  );

  const bounds = paperPath.getBounds();
  // eslint-disable-next-line no-underscore-dangle
  const pathWidth = bounds._width;
  // eslint-disable-next-line no-underscore-dangle
  const pathHeight = bounds._height;

  const eraseElement = create.path(
    {
      name: 'Erase',
      duration,
      props: {
        d: paperPath.getPathData(),
        style: {
          stroke: 'white',
          strokeWidth: 300,
        },
        translateX: -(pathWidth / 2) + dimensions.width / 2,
        translateY: -(pathHeight / 2) + dimensions.height / 2,
      },
    },
    {
      animStart: startTime + currentTime,
      animDuration: 4000,
      usePreferenceStore: false,
    }
  );

  const area = node.children.find((child) => child.attributes.tag === 'area');
  const element = create.element({
    name: 'Background',
    duration,
    type: area ? area.type : 'rect',
    props: {
      ...(area
        ? area.attributes
        : { width: dimensions.width, height: dimensions.height }), // Default to square filling entire canvas
      ...(area ? getTransform(area, {}) : {}),
      style: {
        fill: 'white',
      },
    },
  });

  const maskElement = create.group({
    maskElements: [eraseElement],
    elements: [element],
    duration,
    locked: true,
  });

  return maskElement;
};

export const getAnimationPathD = (node) => {
  const index = node.children.findIndex(
    (child) => child.attributes.tag === 'motion'
  );

  if (node.children[index]) {
    const [motionPath] = node.children.splice(index, 1);
    if (motionPath.attributes.d) return motionPath.attributes.d;
  }

  return false;
};

/**
 * Convert an SVG string to a parsed svg object
 *
 * @param {String} svgString
 * @returns {Object}
 */
export const stringToJson = async (svgString) => {
  const parsed = await parse(svgString, {
    transformNode: (node) => {
      // Convert px attributes to numbersr
      Object.entries(node.attributes).forEach(([key, value]) => {
        node.attributes[key] = pxToNumber(value);
      });

      // Transform style attribute to object if it exists
      let style = {};
      if (node.attributes.style) {
        style = node.attributes.style.split(';').reduce(
          // Transform style attributes to camelCase and pixels to numbers
          (prev, curr) => ({
            ...prev,
            ...(curr.split(':').length === 2
              ? {
                  [kebabToCamel(curr.split(':')[0])]: pxToNumber(
                    curr.split(':')[1]
                  ),
                }
              : {}),
          }),
          {}
        );
      }

      // Remove the xlink:href attribute
      if (node.attributes['xlink:href']) {
        node.attributes.href = node.attributes['xlink:href'];
        delete node.attributes['xlink:href'];
      }

      // Make sure all tagged items have a serif:id
      if (!node.attributes['serif:id'] && node.attributes.id) {
        node.attributes['serif:id'] = node.attributes.id;
      }

      // Add the "serif:id" as a tag attribute
      if (node.attributes['serif:id'])
        node.attributes.tag = node.attributes['serif:id']
          .toString()
          .toLowerCase();

      return {
        ...node,
        id: uuid(),
        type: node.name,
        attributes: {
          ...node.attributes,
          style,
        },
      };
    },
  });
  // console.log(parsed);
  return parsed;
};

/**
 * Create an SvgElement using the data for a node
 *
 * @param {Object} node - a parsed Svg element object
 * @param {number} [duration=10000]
 * @param {number} [currentTime=0]
 * @returns {[SvgElement]}
 */
export const createElementFromData = (
  node,
  defs = [],
  duration = 10000,
  currentTime = 0,
  start = 0,
  dimensions = { width: 1920, height: 1080 }
) => {
  let startTime = start;
  let syncTime = null;
  let legacyScene = null;
  let legacySceneLocation = null;

  /**
   *
   *
   * @param {Object} n - the node object
   * @param {[Number]} map - an array of indexes describing the current node's nested location in the original node
   * @param {Object} [options={}]
   * {
   *  sync: {Boolean}, // if true, will not increment start time and will "syncronize" subsequent animations
   * }
   * @returns
   */
  const createElement = (n, map, options = {}) => {
    const { hasAnimation = true, sync = false } = options;
    let newNode = n;

    // Skip any elements matching the ignore keyword
    if (
      newNode.attributes.tag &&
      ignoreKeywords.find((k) => k === newNode.attributes.tag.toLowerCase())
    )
      return null;

    // Handle legacy import
    if (!legacyScene) {
      legacyScene = legacySvgParser.getLegacyScene(n);
      if (legacyScene) legacySceneLocation = map;
    }

    if (newNode.type === 'use') {
      // See if this node is using a def
      const def = defs.find(
        (d) => `#${d.attributes.id}` === newNode.attributes.href
      );

      if (def) {
        newNode = {
          ...def,
          attributes: {
            ...def.attributes,
            // Override attributes with this "use" instance
            ...newNode.attributes,
            href: def.attributes.href,
          },
        };
      } else {
        return null;
      }
    }

    // Get the transform if it exists
    let transform = {
      translateX: 0,
      translateY: 0,
    };

    let increment = 0;

    // Decompose and add transforms
    if (newNode.attributes.transform) {
      transform = getTransform(newNode, transform);

      delete newNode.attributes.transform;
      delete newNode.attributes.x;
      delete newNode.attributes.y;
    }

    if (newNode.attributes.x) {
      transform.translateX += newNode.attributes.x;
      delete newNode.attributes.x;
    }

    if (newNode.attributes.y) {
      transform.translateY += newNode.attributes.y;
      delete newNode.attributes.y;
    }

    let newElement = null;

    if (newNode.type === 'text') {
      newElement = importText(
        newNode,
        transform,
        startTime,
        duration,
        currentTime,
        hasAnimation
      );
    } else if (newNode.type === 'image') {
      newElement = importImage(
        newNode,
        transform,
        startTime,
        duration,
        currentTime,
        hasAnimation
      );
    } else if (newNode.type === 'path') {
      newElement = importPath(
        newNode,
        transform,
        startTime,
        duration,
        currentTime,
        hasAnimation,
        legacyScene, // Pass in the legacy "Scene" group
        legacySceneLocation,
        map
      );
    } else if (newNode.attributes.tag === 'erase') {
      newElement = createEraseMask(
        newNode,
        startTime,
        duration,
        currentTime,
        dimensions
      );

      // Get the erase path duration
      const { min, max } = newElement.maskElements[0].getMinMaxKeyframes();
      increment += max - min;

      newNode.children.forEach((child, i) =>
        // Iterate through children, use map of node.children indexes to keep track of nested location
        newElement.elements.push(
          createElement(child, [...map, i], {
            hasAnimation: false,
          })
        )
      );
    } else if (newNode.type === 'g') {
      newElement = create.group(
        ...getElementArgs(
          newNode,
          {
            props: { ...transform },
            duration,
          },
          hasAnimation
            ? {
                startTime,
                currentTime,
              }
            : {}
        )
      );

      if (newElement.name.toLowerCase() === 'move') {
        const animationPathD = getAnimationPathD(newNode);

        // Add animation path d
        newElement.update('animationPathD', toAbsolute(animationPathD));

        // Add animation
        newElement.update('keyframes', {
          ...newElement.keyframes,
          path: [
            {
              pathProgress: 0,
              time: startTime,
            },
            {
              pathProgress: 1,
              time: startTime + 1000,
            },
          ],
        });
      }

      const hasSync = newElement.name.toLowerCase() === 'sync';
      if (hasSync) syncTime = startTime;

      newNode.children.forEach((child, i, arr) => {
        // Iterate through children, use map of node.children indexes to keep track of nested location
        newElement.elements.push(
          createElement(child, [...map, i], {
            // Sync timing if group name is "sync" as long as it's not the last child
            sync: hasSync && i < arr.length - 1, // Sync the child unless it's the last in the array
            hasAnimation:
              // Disable animations for 'image' and 'erase' groups
              hasAnimation !== false && // If this is a child element with animation disabled, continue disabling
              (newElement.name !== 'image' ||
                newElement.name.toLowerCase() === 'erase'),
          })
        );
      });
    } else {
      // Create default catchall SvgElement
      newElement = create.element(
        ...getElementArgs(newNode, {
          type: newNode.type,
          props: { ...transform },
          duration,
        })
        // NEED ANIMATION ?
      );
    }

    // Add fade in animation if it's a node with tag 'image'
    if (newNode.attributes.tag === 'image' && hasAnimation) {
      newElement.keyframes = {
        opacity: [
          {
            time: startTime + currentTime,
            opacity: 0,
          },
          {
            time: startTime + currentTime + 1000,
            opacity: 1,
          },
        ],
      };
    }

    // Filter out any null elements
    if (newElement.elements)
      newElement.elements = newElement.elements.filter((el) => el);

    const { min, max } = newElement.getMinMaxKeyframes();

    if (isNumber(min) && isNumber(max)) {
      increment += max - min;
    }

    startTime += increment;

    if (sync) startTime = syncTime;

    return newElement;
  };

  return [
    createElement(node, []),
    { time: startTime, isLegacy: !!legacyScene },
  ];
};

/**
 * Import svg elements from an SVG string
 *
 * @param {String} svgString
 * @param {number} [duration=10000]
 * @param {number} [currentTime=0]
 * @returns
 */
export const importSvgElements = async (
  svgString,
  duration = 10000,
  currentTime = 0
) => {
  const data = await stringToJson(svgString);

  if (data) {
    if (data.name === 'svg' && data.children) {
      const defsIndex = data.children.findIndex(
        (child) => child.type === 'defs'
      );

      let defs = [];
      if (defsIndex > -1) {
        defs = data.children[defsIndex].children;
        data.children.splice(defsIndex, 1);

        // Get image defs that have a data uri
        const [imageDefs, otherDefs] = partition(
          defs,
          (def) =>
            def.type === 'image' &&
            def.attributes.href &&
            def.attributes.href.startsWith('data')
        );

        // Blobify image data uris and upload to S3
        const newImageDefs = await uploadImages(imageDefs);

        defs = [...newImageDefs, ...otherDefs];
      }

      const startTime = 0;

      // Get viewBox
      const { width, height } = parseViewBox(data.attributes.viewBox);

      // Change "svg" to "g"
      data.type = 'g';

      const [svgElement, importData] = createElementFromData(
        data,
        defs,
        duration,
        currentTime,
        startTime,
        { width, height }
      );

      if (importData.isLegacy) {
        // Expand the "Animation" group
        ungroupByName(svgElement, 'Animation');
      }

      // Return just the child elements
      return svgElement.elements;
    }
  }

  return [];
};

export default {
  stringToJson,
  importSvgElements,
  createElementFromData,
  getAnimationPathD,
  parseViewBox,
  getTransform,
};
