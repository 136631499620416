import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';

import fetchUtil from '../../util/fetchUtil';

import { Store } from '../../state/store';

import { fetchActiveProject } from '../../state/projects/actions';

import Button from '../Button';
import Message from '../Message';
import Box, { Flex } from '../Box';
import ListItemSelector from '../ListItemSelector';
import Checkbox from '../Checkbox';

import { H3, P } from '../styles/typography';
import { colors, lighten } from '../styles/colors';

function ExportVideo(props) {
  const { files, handleHideDialog, projectID } = props;
  const { dispatch } = useContext(Store);

  const [notifyOnComplete, setNotifyOnComplete] = useState(true);

  const [svgs, setSvgs] = useState(
    files.map((f) => ({
      ...f,
      id: f.fileID,
      name: f.name,
      checked: true,
    }))
  );

  const [file] = files;

  const handleSvgClick = (svg) => {
    const newSvgs = Array.from(svgs);
    const newSvg = newSvgs.find((s) => s.id === svg.id);
    newSvg.checked = !newSvg.checked;

    setSvgs(newSvgs);
  };

  const handleUpdateAll = (checked) => {
    const newSvgs = svgs.map((svg) => ({
      ...svg,
      checked,
    }));

    setSvgs(newSvgs);
  };

  const handleExport = async () => {
    const res = await fetchUtil.post('/export/addFileExport', {
      fileIDs: svgs.filter((svg) => svg.checked).map((svg) => svg.fileID),
    });

    if (res.fileExportIDs) {
      fetchUtil.post('/exportVideo', {
        fileExportIDs: res.fileExportIDs,
        projectID,
        notify: notifyOnComplete,
      });

      fetchActiveProject(file.projectID, dispatch);
      handleHideDialog();
    }

    return null;
  };

  const checkedFiles = svgs.filter((svg) => svg.checked);

  return (
    <Box style={{ maxWidth: 400 }}>
      <Message
        style={{
          width: '100%',
          borderRadius: 3,
          alignItems: 'center',
          marginBottom: 15,
        }}
        color={colors.teal}
        backgroundColor={lighten(colors.teal, 45)}
        emoji="☝️"
      >
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          This is still in beta, please feel free to reach out with bugs,
          suggestions, and questions.
        </Box>
      </Message>
      <H3 style={{ marginBottom: 10 }}>Export SVGs</H3>
      <P style={{ marginBottom: 10 }}>
        Exporting should take a few minutes. You can navigate away from this
        page and the export will continue.
      </P>
      <ListItemSelector
        items={svgs}
        onItemClick={handleSvgClick}
        handleSelectAll={() => handleUpdateAll(true)}
        handleDeselectAll={() => handleUpdateAll(false)}
      />
      <Box>
        <Checkbox
          name="slack"
          checked={notifyOnComplete}
          onChange={() => setNotifyOnComplete(!notifyOnComplete)}
          label="Notify me in Slack when the finish"
        ></Checkbox>
      </Box>
      <Flex c style={{ marginTop: 20 }}>
        <Button box onClick={handleHideDialog} style={{ marginRight: 10 }}>
          Cancel
        </Button>
        <Button
          box
          backgroundColor={colors.blue}
          color="white"
          onClick={handleExport}
          enable={!!checkedFiles.length}
        >
          Export&nbsp;
          {!!checkedFiles.length && (
            <span>
              {checkedFiles.length > 1
                ? `${checkedFiles.length} files`
                : checkedFiles[0].name}
            </span>
          )}
        </Button>
      </Flex>
    </Box>
  );
}

ExportVideo.propTypes = {
  projectID: PropTypes.number,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      projectID: PropTypes.number,
      fileID: PropTypes.number,
      data: PropTypes.shape({
        url: PropTypes.string,
      }),
    })
  ),
  handleHideDialog: PropTypes.func.isRequired,
};

ExportVideo.defaultProps = {
  files: [],
  projectID: null,
};

export default ExportVideo;
