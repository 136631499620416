import styled, { css } from 'styled-components/macro';

import { Flex } from '../../Box';

import { colors } from '../../styles/colors';

export const ColorBlock = styled(Flex)`
  width: 50px;
  height: 30px;
  border-radius: 3px;
  margin: 5px;
  border: 3px solid transparent;

  cursor: pointer;

  ${(props) =>
    props.color &&
    css`
      background-color: ${colors[props.color] || '#bfbfbf'};
    `}
`;

export default {
  ColorBlock,
};
