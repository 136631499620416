import React from 'react';
import styled from 'styled-components/macro';

import { P } from './styles/typography';
import { fade } from './styles/colors';
import LoadingSpinner from './LoadingSpinner';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.85);
  z-index: 10000000;
`;

const LoadingText = styled(P)`
  color: ${fade('white', 50)};
  margin-bottom: 10px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

function LoadingScreen({ message }: { message: string }) {
  return (
    <Wrapper>
      <Container>
        <LoadingText>{message}</LoadingText>
        <LoadingSpinner size={64} />
      </Container>
    </Wrapper>
  );
}

export default LoadingScreen;
