import { post } from '../../util/fetchUtil';

export const fetchUserData = async (dispatch) => {
  const res = await post('/user/getUser', {
    userID: window.userID,
  });

  if (res?.user) {
    return dispatch({
      type: 'FETCH_USER',
      payload: res.user,
    });
  }

  return null;
};

export const fetchTeamData = async (dispatch) => {
  const res = await post('/user/getTeam');

  return dispatch({
    type: 'FETCH_TEAM',
    payload: res.team,
  });
};
