import React from 'react';

import { Text as RebassText, TextProps } from 'rebass/styled-components';

type Props = TextProps;

export default function Text(props: Props) {
  const { children } = props;
  return <RebassText {...props}>{children}</RebassText>;
}
