import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import TimelineElementRow from './TimelineElementRow';

import { HeaderRow, RowContainer } from './TimelineStyles';

import ElementProps from '../props/ElementProps';
import KeyframeProps from '../props/KeyframeProps';

const { number, func, arrayOf } = PropTypes;

const minDuration = 100;

function TimelineRow(props) {
  const {
    element,
    selectedElements,
    handleUpdateElement,
    handleSelectKeyframes,
    selectedKeyframes,
    pixelsPerMs,
    duration,
    height,
  } = props;

  const [elementDuration, setElementDuration] = useState(element.duration);
  const [elementStart, setElementStart] = useState(element.start);

  // Update duration if set from outside of component
  useEffect(() => {
    if (element.duration !== duration) setElementDuration(element.duration);
    // eslint-disable-next-line
  }, [element.duration]);

  const { keyframes, keyframesOpen } = element;
  const containerRef = useRef();

  const handleSelectAll = (key) => {
    handleSelectKeyframes(element, key, keyframes[key]);
  };

  const handleMouseUp = () => {
    // Update UI and save on mouseup
    handleUpdateElement([element.id]);
  };

  const handleDragElement = (move) => {
    const change = move.dx / pixelsPerMs;
    let newElementStart = elementStart + change;

    if (elementStart < 0) newElementStart = 0;
    else if (elementStart + elementDuration > duration) {
      newElementStart = duration - elementDuration;
    }

    element.update('start', newElementStart);
    setElementStart(newElementStart);
  };

  const handleDragEnd = (move) => {
    const change = move.dx / pixelsPerMs;
    const currentEnd = elementDuration + elementStart;
    const newEnd = currentEnd + change;
    let newElementDuration = elementDuration + change;

    // If we're less than the start, then set to the min duration
    if (newEnd < elementStart + minDuration) newElementDuration = minDuration;
    // If we're greater than the timeline, set to the timeline
    else if (newEnd > duration) newElementDuration = duration - elementStart;

    // Otherwise set as the change
    element.update('duration', newElementDuration);
    setElementDuration(newElementDuration);
  };

  const handleDragStart = (move) => {
    const change = move.dx / pixelsPerMs;
    const newStart = change + elementStart;
    const maxStart = elementStart + elementDuration - minDuration;

    let newElementStart = elementStart + change;
    let newElementDuration = elementDuration - change;

    if (newStart < 0) {
      // FIXME: ELEMENTS LENGTH SHRINKS SLIGHTLY WHEN FIRST SETTING TO 0
      newElementStart = 0;
      newElementDuration = elementDuration;
    } else if (newStart > maxStart) {
      newElementStart = maxStart;
      newElementDuration = minDuration;
    } else {
      // Shift all keyframes
      element.moveAllKeyframes(-change);
    }

    element.update('duration', newElementDuration);
    element.update('start', newElementStart);

    setElementStart(newElementStart);
    setElementDuration(newElementDuration);
  };

  const isSelected = selectedElements.some((el) => el.id === element.id);

  const keyframeComponents = Object.keys(keyframes).map((key) => {
    return (
      <TimelineElementRow
        key={key}
        height={height}
        handleDragElement={handleDragElement}
        element={element}
        elementDuration={elementDuration}
        elementStart={elementStart}
        pixelsPerMs={pixelsPerMs}
        isSelected={isSelected}
        handleDragEnd={handleDragEnd}
        handleDragStart={handleDragStart}
        handleSelectAll={handleSelectAll}
        keyframeKey={key}
        selectedKeyframes={selectedKeyframes}
        handleSelectKeyframes={handleSelectKeyframes}
        handleMouseUp={handleMouseUp}
      />
    );
  });

  // Create an element that has no keyframes to
  const noKeyframesElement = (
    <TimelineElementRow
      height={height}
      handleDragElement={handleDragElement}
      element={element}
      elementDuration={elementDuration}
      elementStart={elementStart}
      pixelsPerMs={pixelsPerMs}
      isSelected={isSelected}
      handleDragEnd={handleDragEnd}
      handleDragStart={handleDragStart}
      handleMouseUp={handleMouseUp}
    />
  );

  return (
    <RowContainer
      active={isSelected}
      ref={containerRef}
      id={`timeline-row-${element.id}`}
    >
      {!!keyframeComponents.length && keyframesOpen && (
        <>
          <HeaderRow height={height} />
          {keyframeComponents}
        </>
      )}
      {(!keyframeComponents.length || !keyframesOpen) && noKeyframesElement}
    </RowContainer>
  );
}

TimelineRow.propTypes = {
  height: number,
  element: ElementProps.isRequired,
  pixelsPerMs: number.isRequired,
  selectedKeyframes: arrayOf(KeyframeProps).isRequired,
  handleSelectKeyframes: func.isRequired,
  selectedElements: arrayOf(ElementProps).isRequired,
  handleUpdateElement: func.isRequired,
  duration: number.isRequired,
};

export default TimelineRow;
