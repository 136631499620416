import { transparentize, darken, lighten } from 'polished';
import React, { useState } from 'react';

import {
  Box,
  Button as RebassButton,
  ButtonProps,
  SxStyleProp,
} from 'rebass/styled-components';
import styled from 'styled-components/macro';
import colors from '../styles/themeColors';
import LoadingSpinner from './LoadingSpinner';

const sizes: {
  [index: string]: { px: string; py: string; fontSize: string };
} = {
  s: {
    px: '0.65em',
    py: '0.5em',
    fontSize: '14px',
  },
};

const LoadingSpinnerContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;

type Props = ButtonProps & {
  sx?: SxStyleProp;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  children?: React.ReactNode;
  variant?: 'transparent' | 'outline';
  color?: string;
  bg?: string;
  onClick?: (e: React.MouseEvent) => void | Promise<void>;
  width?: string;
  size?: 's' | 'm' | 'l';
  enabled?: boolean;
};

const outline = (color: string) => ({
  bg: 'transparent',
  color,
  boxShadow: `0 0 0 1px ${transparentize(0.5, colors[color] || color)} inset`,

  ':hover': {
    bg: color
      ? transparentize(0.9, colors[color] || color)
      : transparentize(0.9, 'black'),
  },
  ':active': {
    bg: color
      ? transparentize(0.85, colors[color] || color)
      : transparentize(0.85, 'black'),
  },
});

const transparent = (color: string) => ({
  bg: 'transparent',
  boxShadow: 'none',
  color,

  ':hover': {
    bg: color
      ? transparentize(0.9, colors[color] || color)
      : transparentize(0.9, 'black'),
  },
  ':active': {
    bg: color
      ? transparentize(0.85, colors[color] || color)
      : transparentize(0.85, 'black'),
  },
});

const disabledStyles = (isDisabled: boolean, bg: string) => {
  const styles = { opacity: 0.5, cursor: 'initial', bg };
  if (isDisabled)
    return {
      ...styles,
      ':focus,:active,:hover': { ...styles },
    };

  return {};
};

export default function Button(props: Props) {
  const {
    children,
    color,
    sx,
    startIcon,
    variant,
    onClick,
    bg = 'primary',
    endIcon,
    width = '',
    enabled = true,
    size,
    ...rest
  } = props;

  const [isLoading, setLoading] = useState(false);

  const handleClick = (e: React.MouseEvent) => {
    if (onClick) {
      const promise = onClick(e);

      // If passed onClick function is a promise
      if (promise && promise.then) {
        // Set loading spinner and wait until response
        setLoading(true);
        promise.then((res) => {
          // Set loading and return the event
          setLoading(false);
          return new Promise((resolve) => resolve(res));
        });
      }
    }
  };

  const content = (
    <>
      {startIcon && <Box mr="10px">{startIcon}</Box>}
      {children}
      {endIcon && <Box ml="10px">{endIcon}</Box>}
    </>
  );

  return (
    <RebassButton
      disabled={!enabled}
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        fontFamily: 'Nunito',
        transition: '0.12s all ease-in-out',
        width,
        fontWeight: 'bold',
        color,
        bg,
        ':focus': {
          outlineColor: lighten(0.1, colors[bg] || bg),
        },
        ':hover': {
          bg: darken(0.05, colors[bg] || bg),
        },
        ':active': {
          bg: darken(0.1, colors[bg] || bg),
        },

        ...(size && sizes[size] ? sizes[size] : {}),
        ...(variant === 'outline' && color ? outline(color) : {}),
        ...(variant === 'transparent' && color ? transparent(color) : {}),
        ...disabledStyles(!enabled, variant === 'outline' ? 'transparent' : bg),
        ...sx,
      }}
      variant={variant}
      onClick={handleClick}
      {...rest}
    >
      {isLoading && (
        <LoadingSpinnerContainer className="loading-spinner">
          <LoadingSpinner size={15} />
        </LoadingSpinnerContainer>
      )}
      {isLoading && <Box opacity="0">{content}</Box>}
      {!isLoading && content}
    </RebassButton>
  );
}
