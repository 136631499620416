import { isNumber } from './numbers';

// Get just the leading number in a string, e.g. 1_file -> 1
const getSortNumber = (string) => {
  let number = '';
  string.split('').forEach((letter, i) => {
    const digit = parseInt(letter, 10);
    if (isNumber(digit) && i === number.length) number += digit;
  });

  return number;
};

export default [
  {
    id: 'number',
    name: 'Number',
    sort: (a, b) => {
      const numA = getSortNumber(a.name);
      const numB = getSortNumber(b.name);

      return numA - numB;
    },
    reverse: (a, b) => {
      const numA = getSortNumber(a.name);
      const numB = getSortNumber(b.name);

      return numB - numA;
    },
  },
  {
    id: 'alphabetical',
    name: 'Alphabetical',
    sort: (a, b) => (a.name > b.name ? 1 : -1),
    reverse: (a, b) => (a.name < b.name ? 1 : -1),
  },
  {
    id: 'newest',
    name: 'Newest',
    sort: (a, b) => new Date(b.createdOn) - new Date(a.createdOn),
  },
  {
    id: 'oldest',
    name: 'Oldest',
    sort: (a, b) => new Date(a.createdOn) - new Date(b.createdOn),
  },
];
