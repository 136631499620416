import styled, { css } from 'styled-components/macro';

import { colors, lighten, fade } from '../styles/colors';

export const ViewContainer = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: space-between;
`;
export const IconContainer = styled.div`
  display: flex;

  height: 100%;
`;
export const IconButton = styled.div`
  color: white;
  font-size: 20px;

  padding: 10px 15px;

  height: 100%;

  cursor: pointer;

  display: flex;
  align-items: center;

  position: relative;

  ${(props: { isActive?: boolean }) =>
    props.isActive &&
    css`
      background: ${lighten(colors.blue, 5)};
    `}

  ${(props: { isActive?: boolean }) =>
    !props.isActive &&
    css`
      &:hover,
      &:focus {
        background: ${fade('white', 95)};
      }

      &:active {
        background: ${fade('white', 90)};
      }
    `}
`;
export const NotificationDot = styled.div`
  position: absolute;
  top: 9px;
  right: 6px;
  font-size: 15px;
  color: #d53939;
  z-index: 1;
`;
