import create from '../../editor/create';
import {
  ElementProps,
  ElementsArrayProps,
  GroupElementProps,
  MixedElementProps,
  isGroupElement,
} from '../../ui/editor/types/ElementProps';

type Result = {
  element: ElementProps;
  elements: ElementProps[];
  index: number;
  parent?: GroupElementProps;
};

type ElementRef = { ref: MixedElementProps; elements?: ElementRef[] };

/**
 * Recursively finds an element by its id in an array of SvgElements
 *
 * @param {String} elementId
 * @param {[SvgElement]} elements
 * @returns {SvgElement | null}
 */
export const findElement = (
  elementId: string,
  elements: ElementProps[]
): Result | null => {
  let result: Result | undefined;
  const findRecursive = (
    id: string,
    els: ElementProps[],
    parent?: GroupElementProps
  ) => {
    const elementIndex = els.findIndex((el) => el.id === id);

    if (elementIndex > -1) {
      result = {
        parent,
        element: els[elementIndex],
        index: elementIndex,
        elements: els,
      };
    } else {
      els.forEach((el) => {
        if (isGroupElement(el)) {
          findRecursive(id, el.elements, el);
        }
      });
    }
  };

  findRecursive(elementId, elements);

  return result?.element ? result : null;
};

/**
 * addToGroup - adds a set of elements to a GroupElement
 *
 * @param {[SvgElement]} elementsToAdd
 * @param {GroupElement} groupElement
 * @param {[SvgElement]} elements
 * @returns {[SvgElement]} - an updated array of elements
 */
export const addToGroup = (
  elementsToAdd: ElementProps[],
  groupElement: GroupElementProps,
  elements: ElementProps[]
): ElementProps[] => {
  if (isGroupElement(groupElement)) {
    const newElements = elements.filter((el) =>
      elementsToAdd.some((e) => e.id !== el.id)
    );

    groupElement.elements.push(...elementsToAdd);

    return newElements;
  }

  return elements;
};

/**
 * group - creates a new GroupElement and adds the elementsToGroup to it
 *
 * @param {[SvgElement]} elementsToGroup
 * @param {[SvgElement]} elements
 * @param {Object} - arguments passed to the new GroupElement (see GroupElement.js)
 * @returns {[SvgElement]}
 */
export const group = (
  elementsToGroup: ElementsArrayProps,
  elements: ElementsArrayProps,
  groupArgs: any = {}
) => {
  const newElements = [...elements];

  const [firstEl, ...restEls] = elementsToGroup;

  // Remove the rest of the els from wherever they currently exist
  restEls.forEach((el) => {
    const searchRes = findElement(el.id, newElements);
    if (searchRes) {
      if (searchRes?.parent) {
        const newElsArray = [...(searchRes.parent.elements || [])];
        newElsArray.splice(searchRes.index, 1);
        // UPDATES ELEMENT
        searchRes.parent.update('elements', newElsArray);
      } else {
        newElements.splice(searchRes.index, 1);
      }
    }
  });

  const newElementGroup = create.group({
    elements: elementsToGroup,
    ...groupArgs,
  });

  const searchRes = findElement(firstEl.id, newElements);
  if (searchRes) {
    if (searchRes.parent) {
      const newParentElements: ElementsArrayProps = [
        ...(searchRes.parent.elements || []),
      ];
      newParentElements.splice(searchRes.index, 1, newElementGroup);

      // UPDATES ELEMENT
      searchRes.parent.update('elements', newParentElements);
    } else {
      newElements.splice(searchRes.index, 1, newElementGroup);
    }
  }

  return newElements;
};

/**
 * Ungroups a group element and adds it's contents back to the
 * elements array
 *
 * @param {[GroupElement]} elementsToUngroup
 * @param {[SvgElement]} elements
 * @param {HTMLElement} svg
 * @returns {[SvgElement]}
 */
export const ungroup = (
  elementsToUngroup: ElementsArrayProps,
  elements: ElementsArrayProps
): ElementsArrayProps => {
  const newElements = [...elements];

  elementsToUngroup.forEach((element) => {
    const groupElementCast = element as GroupElementProps;
    if (groupElementCast.elements) {
      // Apply the parent group's transforms to each child
      groupElementCast.onUngroup();

      const searchRes = findElement(element.id, elements);
      if (searchRes && searchRes.index > -1) {
        // Update the parent elements array
        if (searchRes.parent) {
          const newParentElementsArray = [...searchRes.parent.elements];
          // Remove group element
          const groupElement = newParentElementsArray.splice(
            searchRes.index,
            1
          )[0] as GroupElementProps;
          // Add group elements children
          newParentElementsArray.splice(
            searchRes.index,
            0,
            ...(groupElement.elements || [])
          );
          searchRes.parent.update('elements', newParentElementsArray);
        } else {
          // Update the root elements array
          // Remove group element
          const groupElement = newElements.splice(
            searchRes.index,
            1
          )[0] as GroupElementProps;
          // Add group elements children
          newElements.splice(
            searchRes.index,
            0,
            ...(groupElement.elements || [])
          );
        }
      }
    }
  });

  return newElements;
};

/**
 *
 *
 * @param {String} droppedElementId
 * @param {String} targetElementId
 * @param {Number} indexModifier - 0 if should go above the target element, 1 if should go below
 * @param {[Element]} elements
 * @returns
 */
export const reorder = (
  droppedElementId: string,
  targetElementId: string,
  indexModifier: 0 | 1,
  elements: ElementsArrayProps
): ElementsArrayProps => {
  const newElements = [...elements];

  const droppedElementRes = findElement(droppedElementId, newElements);

  if (droppedElementRes) {
    // Remove the dropped element from its array
    droppedElementRes.elements.splice(droppedElementRes.index, 1);

    const targetElementRes = findElement(targetElementId, newElements);

    if (targetElementRes) {
      // Splice the dropped element into target element's array
      targetElementRes.elements.splice(
        targetElementRes.index + indexModifier,
        0,
        droppedElementRes.element
      );

      return newElements;
    }
  }

  return elements;
};

// Used to flatten a tree that's been filtered with filterElements
export const flattenFilteredTree = (tree: ElementRef[]) => {
  const flatTree: ElementsArrayProps = [];
  const recursiveMap = (els: ElementRef[]) => {
    els.forEach((el) => {
      if (el.elements) {
        recursiveMap(el.elements);
      } else {
        flatTree.push(el.ref);
      }
    });
  };
  recursiveMap(tree);
  return flatTree;
};

/**
 * flattenElementTree - returns a flattened array of elements with GroupElements filtered out
 */
export const flattenElementTree = (tree: ElementsArrayProps) => {
  const flatTree: ElementsArrayProps = [];
  const recursiveMap = (els: ElementsArrayProps) => {
    els.forEach((el) => {
      if (isGroupElement(el)) {
        recursiveMap(el.elements);
      } else {
        flatTree.push(el);
      }
    });
  };
  recursiveMap(tree);
  return flatTree;
};

/**
 * Finds a group by name and ungroups them to the parent
 *
 * @param {SvgElement} element
 * @param {String} name
 */
export const ungroupByName = (g: GroupElementProps, name: string): void => {
  const expandAnimation = (el: MixedElementProps) => {
    if (isGroupElement(el)) {
      const index = el.elements.findIndex((e) => e.name === name);
      if (index > -1) {
        const elToUngroup = el.elements.splice(
          index,
          1
        )[0] as GroupElementProps;

        el.elements.push(...(elToUngroup.elements || []));
      } else {
        el.elements.forEach((e) => expandAnimation(e));
      }
    }
  };

  expandAnimation(g);
};

/**
 * Returns a new filtered array of elements with object references (so as not to mutate the element itself)
 */
export const filterElements = (
  els: ElementsArrayProps,
  filterFunc: (el: MixedElementProps) => boolean
): ElementRef[] => {
  const filteredElements: ElementsArrayProps = [];
  // FIXME:
  const filter = (elements: any, filtered: any) => {
    elements.forEach((el: MixedElementProps) => {
      if (filterFunc(el)) {
        if (isGroupElement(el)) {
          filtered.push({
            ref: el,
            elements: filter(el.elements, []),
          });
        } else {
          filtered.push({
            ref: el,
          });
        }
      }
    });

    return filtered;
  };

  return filter(els, filteredElements);
};

export default {
  flattenElementTree,
  flattenFilteredTree,
  addToGroup,
  findElement,
  group,
  reorder,
  filterElements,
  ungroup,
  ungroupByName,
};
