import { useEffect } from 'react';

/**
 * Simple hook for capturing a keypress on document
 *
 * @param {String} key
 * @param {Func} onPress
 */
export default (key, onPress = () => {}) => {
  const handleKeydown = (e) => {
    if (e.key.toLowerCase() === key.toLowerCase()) {
      onPress(e);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
    // eslint-disable-next-line
  }, [key, onPress]);
};
