import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import useModifier from '../../../app/hooks/useModifier';

import TimelineRowContainer from './TimelineRowContainer';
import TimelineRowInfoContainer from './TimelineRowInfoContainer';

import deleteUtil from '../../../app/editor/delete';
import hotkeyManager from '../../../app/editor/editorHotkeys';

import Box from '../../Box';
import { colors } from '../../styles/colors';

import ElementProps from '../props/ElementProps';
import { DomRefProps, RefProps } from '../../../props/general';

/**
 * Gets the total number of rows for calculating the container
 * heights
 *
 * @param {[SvgElement]} elements
 */
const getNumRows = (elements) => {
  const getRowsRecursive = (els) => {
    let totalRows = els.reduce((total, el) => {
      let newTotal = total;
      newTotal += 1; // For the element itself
      newTotal += Object.keys(el.keyframes).length;

      return newTotal;
    }, 0);

    els.forEach((el) => {
      if (el.elements) {
        totalRows += getRowsRecursive(el.elements);
      }

      if (el.maskElements.length) {
        totalRows += getRowsRecursive(el.maskElements);
      }
    });

    return totalRows;
  };

  return getRowsRecursive(elements);
};

const TimelinePlayLine = styled.div`
  position: absolute;
  width: 1px;
  background-color: white;
  height: 100%;
  left: ${(props) => props.progress};
  top: 0;
  z-index: 1;
`;

const ContentContainer = styled(Box)`
  display: flex;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
`;

const LeftLayerContainer = styled(Box)`
  width: ${(props) => props.leftWidth}%;
  background-color: ${colors['light-night-10']};
  height: ${(props) => props.rows * props.rowHeight}px;
`;

const RightLayerContainer = styled(Box)`
  width: ${(props) => 100 - props.leftWidth}%;
  position: relative;
  border-left: 1px solid ${colors['light-night-30']};
  background-color: ${colors['light-night-10']};
  overflow-x: auto;
  overflow-y: hidden;
  height: ${(props) => props.rows * props.rowHeight}px;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
`;

const RightLayerInnerContainer = styled(Box)`
  overflow-x: hidden;

  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `}

  position: relative;
`;

/**
 * The content of the timeline containing the rows for each element
 */
const TimelineContent = (props) => {
  const {
    elements,
    elementsToUpdate,
    selectedElements,
    handleUpdateElement,
    handleSelectElements,
    progress,
    setProgress,
    duration,
    leftWidth,
    lowerScrollContainerRef,
    rightControlsContainerRef,
    verticalScrollRef,
    pixelsPerMs,
    rowHeight,
    isPlaying,
  } = props;

  const [selectedKeyframes, setSelectedKeyframes] = useState([]);
  const [selectedKeyframeGroups, setSelectedKeyframeGroups] = useState([]);

  const cmdKeyDown = useModifier('command').isDown;

  const totalRows = getNumRows(elements);

  const handleSelectElement = (element) => {
    if (cmdKeyDown) {
      handleSelectElements([...selectedElements, element]);
    } else {
      handleSelectElements([element]);
    }
  };

  const handleSelectKeyframeGroup = (element, key) => {
    setSelectedKeyframeGroups([
      {
        elementId: element.id,
        key,
      },
    ]);
  };

  const handleDeselectKeyframeGroup = (element, key) => {
    setSelectedKeyframeGroups(
      selectedKeyframeGroups.filter(
        (group) => !(element.id === group.elementId && key === group.key)
      )
    );
  };

  const handleSelectKeyframes = (element, key, keyframes = []) => {
    // Set only to one keyframe for now
    let newSelectedKeyframes = [];

    if (element && key && keyframes) {
      newSelectedKeyframes = [...selectedKeyframes];

      newSelectedKeyframes.push(
        ...keyframes
          .filter(
            // Don't reselect the same keyframes
            (keyframe) => !selectedKeyframes.some((k) => k.id === keyframe.id)
          )
          .map((keyframe) => ({
            id: keyframe.id,
            key,
            elementId: element.id,
          }))
      );
    }

    if (newSelectedKeyframes.length === 1) {
      // find the original keyframe
      const keyframe = keyframes.find(
        (k) => k.id === newSelectedKeyframes[0].id
      );
      // snap playhead to that time
      setProgress(keyframe.time / duration);
    }

    setSelectedKeyframes(newSelectedKeyframes);
  };

  const handleAddKeyframe = (element, key) => {
    element.addKeyframe(key, null, progress * duration, () =>
      handleUpdateElement([element.id])
    );
  };

  const handleDeleteKeyframes = (keyframes) => {
    keyframes.forEach((selectedKeyframe) => {
      const element = elements.find((e) => e.id === selectedKeyframe.elementId);
      if (element) {
        element.deleteKeyframe(selectedKeyframe.key, selectedKeyframe.id);
      }
    });

    handleUpdateElement(keyframes.map((keyframe) => keyframe.elementId));
  };

  const handleScrollElements = (e) => {
    // Keep two containers scroll-x in sync
    if (rightControlsContainerRef.current.scrollLeft !== e.target.scrollLeft) {
      rightControlsContainerRef.current.scrollLeft = e.target.scrollLeft;
    }
  };

  /**
   * Removes a type of keyframe and all its keyframes
   *
   * @param {SvgElement} element
   * @param {String} key - key identifier, e.g. 'opacity'
   */
  const handleRemoveKeyframeGroup = (element, key) => {
    deleteUtil.keyframeGroup(element.id, key, elements);
    handleUpdateElement([element.id]);
  };

  hotkeyManager.register({
    DELETE_TIMELINE_KEYFRAME: {
      names: ['backspace', 'delete'],
      priority: 1,
      onPress(e) {
        // Remove individual keyframes
        if (selectedKeyframes.length) {
          e.preventDefault();
          e.stopPropagation();

          handleDeleteKeyframes(selectedKeyframes);
          setSelectedKeyframes([]);

          // Should stop propagation
          return true;
        }

        return false;
      },
    },
  });

  return (
    <ContentContainer ref={verticalScrollRef}>
      <LeftLayerContainer
        leftWidth={leftWidth}
        rows={totalRows}
        rowHeight={rowHeight}
      >
        <TimelineRowInfoContainer
          handleUpdateElement={handleUpdateElement}
          elementsToUpdate={elementsToUpdate}
          handleAddKeyframe={handleAddKeyframe}
          selectedElements={selectedElements}
          handleSelectElement={handleSelectElement}
          rowHeight={rowHeight}
          selectedKeyframeGroups={selectedKeyframeGroups}
          handleSelectKeyframeGroup={handleSelectKeyframeGroup}
          handleDeselectKeyframeGroup={handleDeselectKeyframeGroup}
          elements={elements}
          handleRemoveKeyframeGroup={handleRemoveKeyframeGroup}
          isPlaying={isPlaying}
        />
      </LeftLayerContainer>
      <RightLayerContainer
        rows={totalRows}
        leftWidth={leftWidth}
        rowHeight={rowHeight}
        onScroll={handleScrollElements}
        ref={lowerScrollContainerRef}
      >
        <RightLayerInnerContainer width={pixelsPerMs * duration}>
          <TimelinePlayLine progress={`${progress * 100}%`} />
          <TimelineRowContainer
            elements={elements}
            elementsToUpdate={elementsToUpdate}
            selectedElements={selectedElements}
            handleUpdateElement={handleUpdateElement}
            duration={duration}
            pixelsPerMs={pixelsPerMs}
            rowHeight={rowHeight}
            handleSelectKeyframes={handleSelectKeyframes}
            selectedKeyframes={selectedKeyframes}
            isPlaying={isPlaying}
          />
        </RightLayerInnerContainer>
      </RightLayerContainer>
    </ContentContainer>
  );
};

const { number, func, arrayOf, bool, string, shape } = PropTypes;

TimelineContent.propTypes = {
  elements: arrayOf(ElementProps).isRequired,
  selectedElements: arrayOf(ElementProps).isRequired,
  handleSelectElements: func.isRequired,
  handleUpdateElement: func.isRequired,
  progress: number.isRequired,
  setProgress: func.isRequired,
  duration: number.isRequired,
  leftWidth: number.isRequired,
  lowerScrollContainerRef: DomRefProps.isRequired,
  rightControlsContainerRef: DomRefProps.isRequired,
  verticalScrollRef: RefProps.isRequired,
  pixelsPerMs: number.isRequired,
  rowHeight: number.isRequired,
  isPlaying: bool.isRequired,
  elementsToUpdate: shape({
    current: arrayOf(string),
  }).isRequired,
};

export default TimelineContent;
