/* eslint-disable import/no-named-as-default-member */
import SvgElement from './SvgElement';
import PathElement from './PathElement';
import ImageElement from './ImageElement';
import RectElement from './RectElement';
import TextElement from './TextElement';
import GroupElement from './GroupElement';
import uuid from '../app/utilities/uuid';
import editorDefaults from '../app/editor/defaults';
import preferenceStore from '../app/editor/preferenceStore';

import { isNumber } from '../utilities/numbers';
import {
  ElementProps,
  ImageElementProps,
  RectElementProps,
  TextElementProps,
  PathElementProps,
  GroupElementProps,
} from '../ui/editor/types/ElementProps';

const randomBetween = (start: number, end: number): number =>
  Math.floor(Math.random() * end) + start;

const element = (args = {}): ElementProps =>
  new SvgElement({
    ...args,
  }) as ElementProps;

/**
 * Create an image element
 *
 * @param {Object} [args={}] - arguments to pass to the SvgElement
 * @param {Object} [options={}] - options for instantiation, e.g. initial animations
 * @returns
 */
const image = (
  args: any, // FIXME:
  options: {
    animStart?: number;
    animDuration?: number;
  } = {}
): ImageElementProps => {
  const { animStart = 0, animDuration = 1000 } = options;

  return new ImageElement({
    src: args.src,
    ...args,
    props: {
      ...args.props,
    },
    keyframes: {
      ...(isNumber(animStart)
        ? {
            opacity: [
              {
                time: animStart,
                opacity: 0,
                id: uuid(),
              },
              {
                time: animStart + animDuration,
                opacity: 1,
                id: uuid(),
              },
            ],
          }
        : {}),
    },
  });
};

const dogs = [
  'https://osmosify.s3.amazonaws.com/mP9MXv8gSAiGesv9KwFr9UEZRAibsoNh.png',
  'https://osmosify.s3.amazonaws.com/VYxjfyrJTeaqG5nH029j_X2CTWOGrZt4.png',
  'https://osmosify.s3.amazonaws.com/VYxjfyrJTeaqG5nH029j_X2CTWOGrZt4.png',
  'https://osmosify.s3.amazonaws.com/A7kotWMETymwdgZQWVLMaw54TuKX25AZ.png',
  'https://osmosify.s3.amazonaws.com/cUUkELZvTa_JBwrz0n76IeG9TeGwiNaI.png',
  'https://osmosify.s3.amazonaws.com/x0Oott4FRsmxseUvMn6ucfRERHmVajUF.png',
];

const testImage = () =>
  new ImageElement({
    props: {
      style: {
        fontSize: 64,
      },
    },
    src: dogs[randomBetween(0, dogs.length)],
  });

const testText = () =>
  new TextElement({
    props: {
      x: 0,
      y: 0,
      style: {
        fontSize: 64,
      },
    },
    text: 'Hello world',
    keyframes: {
      text: [
        {
          time: 3000,
          progress: 0,
          id: uuid(),
        },
        {
          time: 5000,
          progress: 1,
          id: uuid(),
        },
      ],
    },
  });

/**
 * Create an text element
 *
 * @param {Object} [args={}] - arguments to pass to the SvgElement
 * @param {Object} [options={}] - options for instantiation, e.g. initial animations
 * @returns
 */
const createText = (
  args: any,
  options: { animStart?: number } = {}
): TextElementProps => {
  const { animStart = 0 } = options;
  const { text = '' } = args;

  return new TextElement({
    ...args,
    props: {
      x: 0,
      y: 0,
      ...args.props,
      style: {
        fontSize: preferenceStore.style.fontSize,
        fontWeight: preferenceStore.style.fontWeight,
        color: preferenceStore.style.color,
        ...(args.props ? args.props.style : {}),
      },
    },
    text,
    keyframes: {
      text: isNumber(animStart)
        ? [
            {
              time: animStart,
              progress: 0,
              id: uuid(),
            },
            {
              time: animStart + text.length * editorDefaults.durationPerLetter,
              progress: 1,
              id: uuid(),
            },
          ]
        : [],
    },
  });
};

/**
 * Create a rect element
 *
 * @param {Object} [args={}] - arguments to pass to the SvgElement
 * @param {Object} [options={}] - options for instantiation, e.g. initial animations
 * @returns
 */
const rect = (
  args: any = {},
  options: { usePreferenceStore?: boolean } = {}
): RectElementProps => {
  const { usePreferenceStore = true } = options;
  return new RectElement({
    ...args,
    props: {
      width: 100,
      height: 100,
      ...args.props,
      style: {
        ...(usePreferenceStore
          ? {
              strokeWidth: preferenceStore.style.strokeWidth,
              stroke: preferenceStore.style.stroke,
              fill: preferenceStore.style.fill,
              rx: preferenceStore.style.rx,
            }
          : {}),
        ...(args.props ? args.props.style : {}),
      },
    },
  });
};

const testRect = () =>
  new RectElement({
    props: {
      style: { fill: 'grey' },
      width: randomBetween(100, 300),
      height: randomBetween(100, 300),
    },
    keyframes: {
      opacity: [
        {
          time: 2500,
          opacity: 1,
          id: uuid(),
        },
        {
          time: 3500,
          opacity: 0.5,
          id: uuid(),
        },
      ],
      position: [
        {
          time: 2000,
          translateX: 10,
          translateY: 10,
          id: uuid(),
        },
        {
          time: 4000,
          translateX: 50,
          translateY: 50,
          id: uuid(),
        },
      ],
      scale: [
        {
          scaleX: 1,
          scaleY: 1,
          time: 3000,
          id: uuid(),
        },
        {
          scaleX: 0.5,
          scaleY: 0.5,
          time: 6000,
          id: uuid(),
        },
      ],
    },
  });

/**
 * Create a path element
 *
 * @param {Object} [args={}] - arguments to pass to the SvgElement
 * @param {Object} [options={}] - options for instantiation, e.g. initial animations
 * @returns
 */
const path = (
  args: any = {},
  options: {
    usePreferenceStore?: boolean;
    animStart?: number;
    animDuration?: number;
  } = {}
): PathElementProps => {
  const {
    usePreferenceStore = true,
    animStart = 0,
    animDuration = 1000,
  } = options;
  return new PathElement({
    ...args,
    props: {
      ...(usePreferenceStore
        ? {
            pressure: preferenceStore.path.pressure,
          }
        : {}),
      ...args.props,
      style: {
        ...(usePreferenceStore
          ? {
              stroke: preferenceStore.style.stroke,
              strokeWidth: preferenceStore.style.strokeWidth,
            }
          : {}),
        ...(args.props ? args.props.style : {}),
      },
    },
    keyframes: {
      stroke: isNumber(animStart)
        ? [
            {
              dashoffset: 0,
              time: animStart,
              id: uuid(),
            },
            {
              dashoffset: 1,
              time: animStart + (animDuration || 1000),
              id: uuid(),
            },
          ]
        : [],
    },
  });
};

const testPath = () =>
  new PathElement({
    props: {
      d: 'M200,200 C200,300 500,100 500,200',
    },
    keyframes: {
      stroke: [
        {
          dashoffset: 0,
          time: 3020,
          id: uuid(),
        },
        {
          dashoffset: 1,
          time: 4000,
          id: uuid(),
        },
      ],
    },
  });

/**
 * Create a group element
 *
 * @param {Object} [args={}] - arguments to pass to the SvgElement
 * @param {Object} [options={}] - options for instantiation, e.g. initial animations
 * @returns
 */
const group = (args: any): GroupElementProps =>
  new GroupElement(args) as GroupElementProps;

const testGroup = (args: any) =>
  group({ ...args, elements: [testRect(), testRect()] });

// Map element names to their create functions
const nodeMap = {
  image,
  rect,
  path,
  text: createText,
  g: group,
};

export default {
  image,
  testImage,
  text: createText,
  testText,
  rect,
  testRect,
  path,
  testPath,
  group,
  element,
  testGroup,
  nodeMap,
};
