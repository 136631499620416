import anime from 'animejs/lib/anime.es.js';
import $ from 'jquery';

export default function (animeOb) {
  const anim = {
    animejs: {},
    play(cb) {
      this.animejs.play();
      if ($.isFunction(cb)) {
        cb();
      }
      return this;
    },
    reset(cb) {
      this.animejs.seek(0);
      if ($.isFunction(cb)) {
        cb();
      }
      return this;
    },
    replay(cb) {
      this.animejs.restart();
      if ($.isFunction(cb)) {
        cb();
      }
      return this;
    },
    stop(cb) {
      this.animejs.pause();
      if ($.isFunction(cb)) {
        cb();
      }
      return this;
    },
    finish(cb) {
      this.animejs.seek(this.animejs.duration);
      if ($.isFunction(cb)) {
        cb();
      }
      return this;
    },
  };

  if (animeOb) {
    anim.animejs = anime(animeOb);
  }

  return anim;
}
