import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';

import { Link, Route, useRouteMatch } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import { Store } from '../../state/store';

import { IconContainer } from './NavigationStyles';
import {
  HomeButton,
  StyledAvatar,
  AvatarButton,
  Container,
  UpperWrapper,
  LowerWrapper,
  ProjectName,
  ProjectButton,
  StyledNavLink,
  ButtonText,
} from './HeaderBarStyles';

import ProjectNavigation from './ProjectNavigation';
import AssetNavigationHeader from './AssetNavigationHeader';
import Notifications from '../notifications/Notifications';
import Tasks from './Tasks';
import Feedback from './Feedback';

function HeaderBar() {
  const {
    state: { user, activeView, activeFile, activeProject },
  } = useContext(Store);

  const showNav = !useRouteMatch(`/project/:projectID/editor/scene/:sceneID`);

  const hasActiveComments = !!activeProject.comments?.find(
    (comment) =>
      comment.fileID === activeFile.fileID && // Is part of the active file
      !comment.data.status && // Is active, i.e. 0 or null
      !comment.data.parentCommentID
  ); // Is not a reply to a parent comment

  return (
    <Container>
      {showNav && (
        <>
          <UpperWrapper>
            <IconContainer>
              <StyledNavLink to="/home" activeClassName="active">
                <HomeButton>
                  <FontAwesomeIcon icon={['fad', 'home']} />
                  <ButtonText>Dashboard</ButtonText>
                </HomeButton>
              </StyledNavLink>
              <StyledNavLink to="/assets" activeClassName="active">
                <HomeButton>
                  <FontAwesomeIcon icon={['fad', 'image']} />
                  <ButtonText>Assets</ButtonText>
                </HomeButton>
              </StyledNavLink>
              <StyledNavLink to="/pronounce" activeClassName="active">
                <Tippy content="Pronunciation guide">
                  <HomeButton>
                    <FontAwesomeIcon icon={['fad', 'microphone-alt']} />
                    {/* <ButtonText>Pronounce</ButtonText> */}
                  </HomeButton>
                </Tippy>
              </StyledNavLink>

              {activeProject.name && (
                <StyledNavLink
                  to={`/project/${activeProject.projectID}`}
                  activeClassName="active"
                >
                  <ProjectButton>
                    <ProjectName>{activeProject.name}</ProjectName>
                  </ProjectButton>
                </StyledNavLink>
              )}
            </IconContainer>
            <IconContainer>
              <Tasks />

              <Notifications />

              <Feedback />

              <Link to="/profile">
                <AvatarButton>
                  <StyledAvatar size={40} src={user.avatar} />
                </AvatarButton>
              </Link>
            </IconContainer>
          </UpperWrapper>

          <Route path="/(project|projectContents)/:projectID">
            <LowerWrapper>
              <ProjectNavigation
                activeView={activeView}
                hasActiveFile={!!activeFile.name}
                hasActiveComments={hasActiveComments}
              />
            </LowerWrapper>
          </Route>
          <Route path="/assets/:kit(\d+)" exact>
            <LowerWrapper>
              <AssetNavigationHeader />
            </LowerWrapper>
          </Route>
        </>
      )}
    </Container>
  );
}

export default HeaderBar;
