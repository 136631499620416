import { lighten, darken } from 'polished';

const primary = '#2079DF';
const accent = '#D33162';
const dark = '#2f3137';
const light = '#F8F8F8';
const negative = '#D53939';
const positive = '#00ab35';
const colors: { [index: string]: string } = {
  primary,
  'primary-1': lighten(0.3, primary),
  'primary-2': lighten(0.15, primary),
  'primary-3': lighten(0.05, primary),
  'primary-4': primary,
  'primary-5': darken(0.05, primary),
  'primary-6': darken(0.1, primary),
  'primary-7': darken(0.15, primary),

  accent,
  'accent-1': lighten(0.15, accent),
  'accent-2': lighten(0.1, accent),
  'accent-3': lighten(0.05, accent),
  'accent-4': accent,
  'accent-5': darken(0.05, accent),
  'accent-6': darken(0.1, accent),
  'accent-7': darken(0.15, accent),

  negative,
  'negative-1': lighten(0.15, negative),
  'negative-2': lighten(0.1, negative),
  'negative-4': negative,
  'negative-3': darken(0.05, negative),
  'negative-5': darken(0.15, negative),

  positive,
  'positive-1': lighten(0.15, positive),
  'positive-2': lighten(0.1, positive),
  'positive-4': positive,
  'positive-3': darken(0.05, positive),
  'positive-5': darken(0.15, positive),

  'light-1': light,
  'light-2': darken(0.05, light),
  'light-3': darken(0.1, light),
  'light-4': darken(0.15, light),
  'light-5': darken(0.2, light),
  'light-6': darken(0.25, light),
  'light-7': darken(0.35, light),
  'dark-1': dark,
  'dark-2': lighten(0.05, dark),
  'dark-3': lighten(0.1, dark),
  'dark-4': lighten(0.15, dark),
  'dark-5': lighten(0.2, dark),
  'dark-6': lighten(0.25, dark),
  'dark-7': lighten(0.35, dark),
};

export default colors;
