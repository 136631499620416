import React, { useContext, useEffect, useRef, useState } from 'react';
import useAnimationFrame from '../../app/hooks/useAnimationFrame';
import layersPanelController from '../../app/layersPanelController';
import browserNotification from '../../app/notifications/browserNotification';
import playback from '../../app/playback';
import playSound from '../../app/playSound';
import { Store } from '../../state/store';
import { post } from '../../util/fetchUtil';
import Box from '../Box';
import TimelinePortalControls from '../editor/timeline/TimelinePortalControls';
import TimelinePortalSceneLoader from '../editor/timeline/TimelinePortalSceneLoader';
import WindowPortal from '../editor/timeline/WindowPortal';
import { colors } from '../styles/colors';
import playbackRates from '../../app/playbackRates';
import wait from '../../app/utilities/wait';
import { mapOrder } from '../../shared/sort';
import useHotkeys from '../../app/hooks/useHotkeys';

import SceneSvgProps from '../project/SceneSvgProps';

const ExporterPortal: React.FC = () => {
  const context = useContext(Store);

  const [scenes, setScenes] = useState<SceneSvgProps[]>([]);
  const [activeScene, setActiveScene] = useState<SceneSvgProps | null>();
  const [playBell, setPlayBell] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(1000);
  const [rate, setRate] = useState(playbackRates[0]);
  const [screenCaptureModeActive, setScreenCaptureModeActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const playOnLoadRef = useRef(false);

  const handleUpdateProgress = (delta: number) => {
    setCurrentTime((prevValue) => {
      const newCurrentTime = prevValue + delta * rate;
      // Clamp progress at 1
      if (newCurrentTime > playback.duration) return playback.duration;

      return newCurrentTime;
    });
  };

  const { controls, isPlaying } = useAnimationFrame(handleUpdateProgress);

  const handlePlay = () => {
    console.trace('Toggling play');
    if (!isPlaying) {
      controls.start();
    } else {
      controls.stop();
    }
  };

  const handleSetProgress = (progress: number) => {
    setCurrentTime(progress * duration);
  };

  const init = async () => {
    // Get scenes from localStorage if they're there
    const sceneIDsString = localStorage.getItem('screenRecordV1');
    const settingsString = localStorage.getItem('exporterSettings');

    if (sceneIDsString) {
      const fileIDs = JSON.parse(sceneIDsString);
      const res = await post('/files/getByIDs', {
        fileIDs,
      });

      if (res.files) {
        // Make sure order of files is the same as what was saved to localStorage
        const sortedFiles = mapOrder(res.files, fileIDs, 'fileID');

        setScenes(sortedFiles);
        setActiveScene(sortedFiles[0]);
        setScreenCaptureModeActive(true);
      }

      if (settingsString) {
        const settings = JSON.parse(settingsString);
        console.log(settings);
        setPlayBell(!!settings.playBell);
      }

      localStorage.removeItem('screenRecordV1');
    }
  };

  useEffect(() => {
    init();
  }, []);

  // Seek animejs objects on current time change
  useEffect(() => {
    playback.seek(currentTime);
  }, [currentTime]);

  const loadScene = async () => {
    if (activeScene) {
      setCurrentTime(0);
      setIsLoading(true);

      await layersPanelController.loadSvgFromUrl(activeScene.data.url, context);

      playback.seek(0);

      await wait(0);

      setIsLoading(false);
      if (playOnLoadRef.current) controls.start();
      playOnLoadRef.current = false;
    }
  };

  useEffect(() => {
    if (activeScene) {
      loadScene();
    }
    // eslint-disable-next-line
  }, [activeScene]);

  useEffect(() => {
    setDuration(playback.duration);
    // eslint-disable-next-line
  }, [playback.duration]);

  const handleSetActiveScene = (newSceneID: number, playOnLoad = false) => {
    const newScene = scenes.find((s) => s.fileID === newSceneID);
    if (newScene) {
      if (playOnLoad) playOnLoadRef.current = playOnLoad;
      setActiveScene(newScene);
    }
  };

  const handleSkip = (delta: number) => {
    const newCurrentTime = currentTime + delta;
    if (newCurrentTime < 0) setCurrentTime(0);
    else if (newCurrentTime > duration) setCurrentTime(duration);
    else setCurrentTime(newCurrentTime);
  };

  const handleSetRate = (newRate: number) => {
    setRate(newRate);

    // Restart
    if (isPlaying) {
      controls.stop();
      controls.start();
    }
  };

  const handleFinish = () => {
    browserNotification('👋 Scenes have finished recording.');
    if (playBell) {
      playSound(
        'https://osmosify.s3.amazonaws.com/Jg9oyLe0TrOUHNJH0wM7oeTcTDyjagsu.mp3'
      );
    }
  };

  useHotkeys([
    {
      // Handle normal play/pause
      names: ['k', 'space'],
      modifiers: [],
      onPress: () => {
        handlePlay();
      },
    },
    {
      // Skip forward
      names: ['l', 'arrowright'],
      modifiers: [],
      onPress: () => {
        handleSkip(1000);
      },
    },
    {
      // Skip backward
      names: ['j', 'arrowleft'],
      modifiers: [],
      onPress: () => {
        handleSkip(-1000);
      },
    },
  ]);

  return (
    <>
      {screenCaptureModeActive && activeScene && (
        <WindowPortal
          width={550}
          height={300}
          styleDeps={[activeScene]}
          onExternalWindowClose={() => window.close()}
        >
          <Box backgroundColor={colors['light-night-10']} p={20}>
            <Box mb={20}>
              <TimelinePortalSceneLoader
                scenes={scenes.map((scene) => ({
                  id: scene.fileID,
                  name: scene.name,
                }))}
                activeScene={{
                  id: activeScene.fileID,
                  name: activeScene.name,
                }}
                handleSetActiveScene={handleSetActiveScene}
                isPlaying={isPlaying}
              />
            </Box>
            <TimelinePortalControls
              sceneIDs={scenes.map((s) => s.fileID)}
              activeSceneID={activeScene.fileID}
              currentTime={currentTime}
              duration={duration}
              isPlaying={isPlaying}
              rate={rate}
              handleSetRate={handleSetRate}
              handleSetActiveScene={handleSetActiveScene}
              handleSetProgress={handleSetProgress}
              handleTogglePlay={handlePlay}
              handleSkip={handleSkip}
              onFinish={handleFinish}
              isLoading={isLoading}
            />
          </Box>
        </WindowPortal>
      )}
    </>
  );
};

export default ExporterPortal;
