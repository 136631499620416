import React, { useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { post } from '../../../util/fetchUtil';
import FileProps from '../types/FileProps';
import Button from '../../RebassButton';
import KitProps from '../../../types/KitProps';
import OptionSelector from '../../OptionSelector';
import { KitFileProps } from './MediaImages';
import ImageFileInfo from '../../assets/ImageFileInfo';
import { H3 } from '../../styles/typography';
import useModal from '../../../app/hooks/useModal';
import Dialog from '../../Dialog';
import AddToDirectoryDialog from '../../assets/AddToDirectoryDialog';
import FileTagger from '../../assets/controls/FileTagger';
import useFileTagger from '../../../app/hooks/useFileTagger';

type Props = {
  file: FileProps;
  allKits: KitProps[];
  kits: KitFileProps[];
  update: () => void;
};

const AddToKitDialogRow: React.FC<Props> = ({
  file,
  allKits,
  kits,
  update,
}: Props) => {
  const kitOptions = allKits.map((kit) => ({ ...kit, id: kit.kitID }));

  const [selectedKit, setSelectedKit] = useState<KitProps>();
  const [tagsVisible, setTagsVisible] = useState(false);
  const [userInput, setUserInput] = useState('');

  const updateTags = () => {
    update();
    setUserInput('');
  };

  const { addTag, createTag, removeTag } = useFileTagger({
    onCreateTag: updateTags,
    onAddTag: updateTags,
    onRemoveTag: updateTags,
  });

  const addToDirectoryDialog = useModal();

  const handleSelectKit = async (kit: KitFileProps) => {
    await post('/assets/linkFilesToKit', {
      fileIDs: [file.fileID],
      kitID: kit.kitID,
    });

    update();
  };

  const handleRemoveKit = async (kit: KitFileProps) => {
    await post('/assets/removeFileKitLinks', {
      linkIDs: [kit.linkID],
    });

    update();
  };

  const handleAddToDirectory = (kit: KitProps) => {
    setSelectedKit(kit);
    addToDirectoryDialog.show();
  };

  return (
    <Box>
      <Dialog
        handleHideDialog={() => addToDirectoryDialog.hide()}
        isVisible={addToDirectoryDialog.isVisible}
      >
        {selectedKit && (
          <AddToDirectoryDialog
            kit={selectedKit}
            file={file}
            handleHideDialog={() => addToDirectoryDialog.hide()}
          />
        )}
      </Dialog>

      <Box mb={20}>
        <ImageFileInfo file={file} />

        <Flex mt={2}>
          <Button
            variant="transparent"
            color="light-6"
            endIcon={
              <FontAwesomeIcon
                icon={['fas', tagsVisible ? 'chevron-up' : 'chevron-down']}
              />
            }
            size="s"
            onClick={() => setTagsVisible(!tagsVisible)}
          >
            Tags
          </Button>
        </Flex>

        {tagsVisible && (
          <FileTagger
            files={[file]}
            onTagClick={() => {}}
            canEdit={true}
            labelVisible={false}
            userInput={userInput}
            setUserInput={setUserInput}
            addTag={addTag}
            createTag={createTag}
            removeTag={removeTag}
          />
        )}
      </Box>

      <Box>
        {!!kits.length && <H3>Kits</H3>}
        <ul>
          {kits.map((k) => (
            <li key={k.kitID}>
              <Flex
                alignItems="center"
                sx={{
                  ':hover': {
                    '.hover-target': {
                      opacity: 1,
                    },
                  },
                }}
              >
                <Box>{k.name}</Box>
                <Flex ml={10} opacity={0} className="hover-target">
                  <Button
                    size="s"
                    variant="transparent"
                    color="dark-6"
                    onClick={() => handleAddToDirectory(k)}
                  >
                    <FontAwesomeIcon icon={['fad', 'folder']} />
                  </Button>
                  <Button
                    size="s"
                    variant="transparent"
                    color="negative"
                    onClick={() => handleRemoveKit(k)}
                  >
                    <FontAwesomeIcon icon={['fas', 'times']} />
                  </Button>
                </Flex>
              </Flex>
            </li>
          ))}
        </ul>
      </Box>
      <Box ml="20px">
        <OptionSelector options={kitOptions} onSelectOption={handleSelectKit}>
          <Button
            variant="outline"
            color="dark-6"
            size="s"
            startIcon={<FontAwesomeIcon icon={['fas', 'plus']} />}
          >
            {kits.length ? 'Add to another kit' : 'Add to kit'}
          </Button>
        </OptionSelector>
      </Box>
    </Box>
  );
};

export default AddToKitDialogRow;
