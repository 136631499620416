import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';
import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import tippy from 'tippy.js';

import { Flex } from 'rebass/styled-components';
import { post } from '../../util/fetchUtil';

import SceneCard from './dashboard/SceneCard';

import { save } from '../../app/editor/save';
import { loadFromS3 } from '../../app/editor/load';
import {
  FileImportProps,
  importSvgFiles,
} from '../../app/editor/helpers/EditorDashboard';
import { Store } from '../../state/store';
import { chooseFromFiles, getFilesFromEvent } from '../../app/fileManager';
import { getExtension } from '../../utilities/files';
import sortFiles from '../../utilities/sortFiles';
import host from '../../app/host';

import Box from '../Box';
import Button from '../Button';
import Warning from '../dialogs/WarningDialog';
import Dialog from '../Dialog';
import DropZone from '../DropZone';
import { colors, fade, darken, lighten } from '../styles/colors';
import { P } from '../styles/typography';
import ReplaceScenesDialog from './dashboard/ReplaceScenesDialog';
import { SceneProps } from './types/SceneProps';
import SceneExportProps from './types/SceneExportProps';
import { FileObjectProps } from '../../types/Browser';
import { CommentProps } from './types/CommentProps';
import useSnackbar from '../../app/hooks/useSnackbar';
import SnackbarContainer from './SnackbarContainer';
import LoadingSpinner from '../LoadingSpinner';
import useModal from '../../app/hooks/useModal';
import Snackbar from '../Snackbar';
import LoadingProgressBar from '../LoadingProgressBar';
import Text from '../Text';

const EmptyContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0.5;
  color: white;

  font-size: 24px;
`;

const Header = styled(Box)`
  margin-bottom: 10px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
`;

const Container = styled(Box)`
  height: 100%;
  background-color: ${colors.night};
`;

const Overlay = styled(motion.div)`
  position: fixed;
  pointer-events: none;
  width: 100%;
  height: 100%;

  background-color: ${fade(colors.blue, 50)};

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const OverlayText = styled(motion.div)`
  padding: 15px;
  background-color: ${lighten(colors.blue, 30)};
  font-size: 24px;
  font-weight: bold;
  color: ${colors.blue};

  border-radius: 5px;
`;

const ButtonContainer = styled(Box)`
  > * {
    margin-right: 10px;
  }
`;

/**
 * Displays all the scenes for a given project
 */
const EditorDashboard: React.FC = () => {
  const {
    state: { activeProject },
  } = useContext(Store);

  const [isLoading, setIsLoading] = useState(true);
  const [selectedScenes, setSelectedScenes] = useState<SceneProps[]>([]);
  const [pendingFiles, setPendingFiles] = useState<FileObjectProps[]>([]);
  const [importSnackbarMessage, setImportSnackbarMessage] = useState('');
  const [importSnackbarProgress, setImportSnackbarProgress] = useState(0);

  const [labels, setLabels] = useState([]);

  const [scenes, setScenes] = useState<SceneProps[]>([]);
  const [sceneExports, setSceneExports] = useState<
    { exports: SceneExportProps[]; sceneID: number }[]
  >([]);

  const [warningDialogVisible, setWarningDialogVisible] = useState(false);
  const [scenesToDelete, setScenesToDelete] = useState<SceneProps[]>([]);

  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const history = useHistory();
  const snackbar = useSnackbar({ stayOpen: true });

  const getScenes = async () => {
    if (activeProject.projectID) {
      setIsLoading(true);
      const res = await post('/scene/getByProjectID', {
        projectID: activeProject.projectID,
      });

      const newScenes = res.scenes;

      if (res.scenes.length) {
        const commentsRes = await post('/scene/getAllSceneComments', {
          sceneIDs: res.scenes.map((s: SceneProps) => s.sceneID),
        });

        // Populate scene comments
        commentsRes.scenes.forEach(
          (sceneComments: { sceneID: number; comments: CommentProps[] }) => {
            const newScene = newScenes.find(
              (s: SceneProps) => s.sceneID === sceneComments.sceneID
            );
            if (newScene) newScene.comments = sceneComments.comments;
          }
        );
      }

      setScenes(res.scenes);
      setSelectedScenes([]);
      setSceneExports(res.exports);
      setIsLoading(false);
    }
  };

  const handleImportFiles = async (files: FileImportProps[]) => {
    snackbar.show();

    await importSvgFiles(
      files,
      activeProject.projectID,
      setImportSnackbarMessage,
      setImportSnackbarProgress
    );

    await getScenes();

    snackbar.hide();
  };

  const handleReplaceScenes = (scenesToUpdate: SceneProps[]) => {
    const mappedFiles = pendingFiles
      .filter((f) => {
        const sceneID = scenesToUpdate.find((s) => s.data.name === f.name)
          ?.sceneID;

        // If the scene isn't supposed to be updated and its name already
        // exists
        if (!sceneID && scenes.find((s) => s.data.name === f.name)) {
          return false;
        }

        return true;
      })
      .map((f) => {
        const sceneID = scenesToUpdate.find((s) => s.data.name === f.name)
          ?.sceneID;

        return {
          file: f,
          name: f.name,
          sceneID,
        };
      })
      .filter((f) => f);

    handleImportFiles(mappedFiles);
    setPendingFiles([]);
  };

  const fileDuplicates = scenes.filter((s) =>
    pendingFiles.find((f) => f.name === s.data.name)
  );

  const replaceScenesDialog = useModal();

  const handleSelectAll = () => {
    setSelectedScenes(scenes);
  };

  const handleDeselectAll = () => {
    setSelectedScenes([]);
  };

  const handleSelectScene = (scene: SceneProps) => {
    const newSelectedScenes = [...selectedScenes];
    const index = newSelectedScenes.findIndex(
      (s) => s.sceneID === scene.sceneID
    );

    if (index > -1) {
      newSelectedScenes.splice(index, 1);
    } else {
      newSelectedScenes.push(scene);
    }

    setSelectedScenes(newSelectedScenes);
  };

  const checkFileUpdates = (files: FileObjectProps[]) => {
    // Check to see if there are any file duplicates by name
    const duplicates = scenes.filter((s) =>
      files.find((f) => f.name === s.data.name)
    );

    if (duplicates.length) {
      setPendingFiles(files);
      replaceScenesDialog.show();
      return;
    }

    // Otherwise all are new
    handleImportFiles(
      files.map((f) => ({
        file: f,
        name: f.name,
      }))
    );
  };

  const createScene = async () => {
    const res = await post('/scene/createProjectScene', {
      projectID: activeProject.projectID,
      duration: 30000,
    });

    history.push(
      `/project/${activeProject.projectID}/editor/scene/${res.scene.sceneID}`
    );
  };

  const handleReviewScenes = async () => {
    let { reviewUrl } = activeProject.data;
    if (!reviewUrl) {
      ({ reviewUrl } = await post('/scene/generateProjectReviewUrl', {
        projectID: activeProject.projectID,
      }));
    }

    window.open(
      `${host}/review/project/${activeProject.name}-${reviewUrl}`,
      '_blank'
    );
  };

  const handleShowDeleteDialog = (scene: SceneProps) => {
    setScenesToDelete([scene]);
    setWarningDialogVisible(true);
  };

  const handleDeleteScenes = () => {
    setScenesToDelete(selectedScenes);
    setWarningDialogVisible(true);
  };

  const handleExportScenes = async () => {
    const res = await post('/export/addSceneExport', {
      sceneIDs: selectedScenes.map((s) => s.sceneID),
    });

    await post('/exportVideoV2', {
      sceneExportIDs: res.sceneExportIDs,
      projectID: activeProject.projectID,
      notify: true,
    });

    await getScenes();
  };

  const handleCancelDelete = () => {
    setWarningDialogVisible(false);
    setScenesToDelete([]);
  };

  const deleteScene = async () => {
    await post('/scene/deleteProjectScenes', {
      projectID: activeProject.projectID,
      sceneIDs: scenesToDelete.map((s) => s.sceneID),
    });

    setWarningDialogVisible(false);
    getScenes();
  };

  const handleCloneScene = async (scene: SceneProps) => {
    // Create a new scene
    const res = await post('/scene/createProjectScene', {
      projectID: activeProject.projectID,
      name: `${scene.data.name} Copy`,
    });

    if (scene.data.s3Key) {
      // Get the current scene elements
      const data = await loadFromS3(scene.data.s3Key);
      const { elements } = data;

      // Save the elements to the new scene
      await save('', res.scene, elements);
    }

    getScenes();
  };

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDraggingOver(false);
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    setIsDraggingOver(false);

    const files = getFilesFromEvent(e).filter(
      (f) => getExtension(f.name) === '.svg'
    );

    if (files.length) checkFileUpdates(files);
  };

  const handleRecordScreen = () => {
    window.localStorage.setItem(
      'screenRecordV2',
      JSON.stringify(
        selectedScenes
          .map((scene) => ({ ...scene, name: scene.data.name }))
          .sort(sortFiles.find((s) => s.id === 'number')?.sort)
          .map((s) => s.sceneID)
      )
    );

    window.open('/exporter?v=2');
  };

  useEffect(() => {
    getScenes();
    // eslint-disable-next-line
  }, [activeProject.projectID]);

  useEffect(() => {
    post('/scene/getSceneLabels').then((res) => {
      setLabels(res.labels);
    });
  }, []);

  React.useEffect(() => {
    tippy('[data-tippy-content]');
  }, [scenes]);

  const sceneCards = scenes
    .map((scene) => ({ ...scene, name: scene.data.name }))
    .sort(sortFiles.find((s) => s.id === 'number')?.sort)
    .map((scene) => {
      // Attach any exports to this card
      const sceneExportsObject = sceneExports.find(
        (ex) => ex.sceneID === scene.sceneID
      );
      const sExports = sceneExportsObject ? sceneExportsObject.exports : [];
      return (
        <motion.div
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 1, opacity: 1 }}
          key={scene.sceneID}
        >
          <SceneCard
            projectID={activeProject.projectID}
            sceneExports={sExports}
            scene={scene}
            link={`/project/${activeProject.projectID}/editor/scene/${scene.sceneID}`}
            handleDelete={handleShowDeleteDialog}
            handleCloneScene={handleCloneScene}
            getScenes={getScenes}
            isSelected={
              !!selectedScenes.find((s) => s.sceneID === scene.sceneID)
            }
            canSelect={!!selectedScenes.length}
            handleSelectScene={handleSelectScene}
            comments={scene.comments}
            labels={labels}
          />
        </motion.div>
      );
    });

  return (
    <Container style={{ height: '100%' }}>
      <Dialog
        isVisible={replaceScenesDialog.isVisible}
        handleHideDialog={() => replaceScenesDialog.hide()}
      >
        <ReplaceScenesDialog
          scenes={fileDuplicates}
          onConfirm={handleReplaceScenes}
          handleHideDialog={() => replaceScenesDialog.hide()}
        />
      </Dialog>
      <Snackbar isVisible={snackbar.isVisible}>
        <SnackbarContainer bg="light-6">
          <Flex alignItems="center">
            <Box mr={10}>
              <LoadingSpinner size={24} />
            </Box>
            <P style={{ color: 'white' }}>Importing</P>
          </Flex>
          <Text color="white" opacity={0.65} mt={2}>
            {importSnackbarMessage}
          </Text>
          <LoadingProgressBar progress={importSnackbarProgress} />
        </SnackbarContainer>
      </Snackbar>

      <Dialog
        hasHeader={false}
        isVisible={warningDialogVisible}
        handleHideDialog={handleCancelDelete}
      >
        <Warning
          onConfirm={deleteScene}
          onCancel={handleCancelDelete}
          header={`Are you sure you want to delete ${
            scenesToDelete.length > 1 ? 'these scenes?' : 'this scene'
          }?`}
          color={colors.red}
        />
      </Dialog>
      <Box
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Header>
          {!selectedScenes.length && (
            <>
              <ButtonContainer display="flex">
                <Button
                  box
                  leftIcon
                  backgroundColor={colors.blue}
                  color="white"
                  onClick={createScene}
                >
                  <FontAwesomeIcon icon={['fas', 'plus']} />
                  New scene
                </Button>
                <Button
                  box
                  leftIcon
                  backgroundColor={lighten(colors.night, 10)}
                  color={lighten(colors.night, 50)}
                  onClick={() =>
                    chooseFromFiles(
                      (files: FileObjectProps[]) =>
                        checkFileUpdates([...files]),
                      {
                        multiple: true,
                        accept: '.svg',
                      }
                    )
                  }
                >
                  <FontAwesomeIcon icon={['fas', 'file-import']} />
                  Import
                </Button>
                <Button
                  box
                  leftIcon
                  backgroundColor={lighten(colors.night, 10)}
                  color={lighten(colors.night, 50)}
                  onClick={handleReviewScenes}
                >
                  <FontAwesomeIcon icon={['fas', 'comment-edit']} />
                  Review
                </Button>
              </ButtonContainer>
              <Box>
                <Button
                  box
                  backgroundColor={lighten(colors.night, 10)}
                  color={lighten(colors.night, 50)}
                  onClick={handleSelectAll}
                >
                  Select all
                </Button>
              </Box>
            </>
          )}
          {!!selectedScenes.length && (
            <>
              <ButtonContainer display="flex">
                <Button leftIcon box onClick={handleExportScenes}>
                  <FontAwesomeIcon icon={['fad', 'file-export']} />
                  Export
                </Button>
                <Button leftIcon box onClick={handleRecordScreen}>
                  <FontAwesomeIcon icon={['fad', 'camera-movie']} />
                  Record screen
                </Button>
                <Button
                  leftIcon
                  box
                  backgroundColor={lighten(colors.red, 30)}
                  color={darken(colors.red, 20)}
                  onClick={handleDeleteScenes}
                >
                  <FontAwesomeIcon icon={['fad', 'trash-alt']} />
                  Delete
                </Button>
              </ButtonContainer>
              <Box>
                <Button
                  box
                  backgroundColor={lighten(colors.night, 10)}
                  color={lighten(colors.night, 50)}
                  onClick={handleDeselectAll}
                >
                  Deselect all
                </Button>
              </Box>
            </>
          )}
        </Header>
        <Box
          style={{
            position: 'relative',
            height: '100%',
            flex: 1,
            overflow: 'auto',
            padding: '0 20px 20px 20px',
          }}
        >
          <AnimatePresence>
            {isDraggingOver && (
              <Overlay
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.25 }}
              >
                <OverlayText
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.5 }}
                >
                  Drop file
                </OverlayText>
              </Overlay>
            )}
          </AnimatePresence>
          <DropZone
            onDragLeave={handleDragLeave}
            onDragEnter={handleDragEnter}
            onDrop={handleDrop}
          >
            <AnimatePresence>
              {!sceneCards.length && !isLoading && (
                <EmptyContainer key="empty-message">
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.25 }}
                    style={{ textAlign: 'center' }}
                  >
                    <Box style={{ fontSize: 64 }}>
                      <span role="img" aria-label="Sad face">
                        😢
                      </span>
                    </Box>
                    No scenes for this project
                  </motion.div>
                </EmptyContainer>
              )}
              <Flex style={{ flexWrap: 'wrap' }}>{sceneCards}</Flex>
            </AnimatePresence>
          </DropZone>
        </Box>
      </Box>
    </Container>
  );
};

export default EditorDashboard;
