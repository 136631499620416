import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import OptionSelector from '../OptionSelector';
import { Flex } from '../Box';
import Button from '../Button';

import sortOptions from '../../utilities/sortFiles';

import { colors } from '../styles/colors';
import { getHash } from '../../shared/links';

import {
  downloadFile,
  deleteFile,
  fileInfo,
  sort,
  exportComments,
} from './FileRowOptions';

import {
  Container,
  Files,
  FilesContainer,
  FileTypeContainer,
  FileRow,
  CommentIcon,
  LeftContainer,
  RightContainer,
  Name,
  StyledUploadBox,
  MoreButton,
  ExportDownloadIcon,
} from './LayersPanelStyles';

import fileManager from '../../app/fileManager';
import { getBasename, getExtension } from '../../utilities/files';

import { H4 } from '../styles/typography';

function VideoFiles(props) {
  const {
    files,
    activeFile,
    activeProject,
    fileHasComments,
    handleRowClick,
    setSelectedDelete,
    onProgress,
    onDownloadError,
  } = props;

  const [currentSort, setCurrentSort] = useState(
    sortOptions.find((o) => o.id === 'number').id
  );
  const [reverseSort, setReverseSort] = useState(false);

  const handleDownloadAllVideos = () => {
    fileManager.downloadZipFile(files, onProgress);
  };

  const handleSelectSort = (newSort) => {
    const currentSortOption = sortOptions.find((o) => o.id === currentSort);
    if (currentSortOption.id === newSort && currentSortOption.reverse) {
      setReverseSort(!reverseSort);
    } else {
      setReverseSort(false);
      setCurrentSort(newSort);
    }
  };

  const downloadCaptions = async (e, file) => {
    e.stopPropagation();

    const trackSrc = file.data.tracks[file.data.tracks.length - 1].src;
    const blob = await fileManager.fetchFile(trackSrc);
    fileManager.downloadFile(
      blob,
      `${getBasename(file.name)}${getExtension(trackSrc)}`
    );
  };

  const currentSortOption = sortOptions.find((o) => o.id === currentSort);

  const videoFiles = files
    .sort(
      reverseSort && currentSortOption.reverse
        ? currentSortOption.reverse
        : currentSortOption.sort
    )
    .map((file) => {
      const commentStatus = fileHasComments(file);
      return (
        <FileRow
          darkMode
          key={file.fileID}
          onClick={() =>
            handleRowClick(
              getHash({
                projectID: activeProject.projectID,
                fileID: file.fileID,
              })
            )
          }
          isActive={activeFile.fileID === file.fileID}
        >
          <LeftContainer>
            <Name darkMode>{file.name}</Name>
          </LeftContainer>
          <RightContainer>
            {file.data.tracks && !!file.data.tracks.length && (
              <ExportDownloadIcon
                icon={['fad', 'closed-captioning']}
                data-tippy-content="Download closed captions"
                onClick={(e) => downloadCaptions(e, file)}
              />
            )}
            {!!commentStatus && (
              <CommentIcon status={commentStatus}>
                <FontAwesomeIcon
                  style={{ width: 'unset' }}
                  icon={[
                    'fad',
                    commentStatus === 'unresolved'
                      ? 'comment-exclamation'
                      : 'comment-check',
                  ]}
                />
              </CommentIcon>
            )}
            <OptionSelector
              onClick={(e) => e.stopPropagation()}
              options={[
                fileInfo(file),
                downloadFile(file, onProgress, onDownloadError),
                deleteFile(file, setSelectedDelete),
              ]}
            >
              <MoreButton icon transparent color={colors['light-grey-20']}>
                <FontAwesomeIcon
                  style={{ width: 'unset' }}
                  icon={['fas', 'ellipsis-v']}
                />
              </MoreButton>
            </OptionSelector>
          </RightContainer>
        </FileRow>
      );
    });

  const options = [];
  if (files.length) {
    options.push({
      id: 'download-all',
      name: 'Download Videos',
      icon: 'file-download',
      onClick: handleDownloadAllVideos,
    });

    const comments = activeProject.comments.filter((c) =>
      files.some((file) => file.fileID === c.fileID)
    );

    if (comments.length) {
      options.push(exportComments(activeProject.projectID, 'video'));
    }

    options.push(
      sort(
        sortOptions,
        currentSort,
        handleSelectSort,
        currentSortOption.reverse && reverseSort
      )
    );
  }

  return (
    <Container>
      <FileTypeContainer darkMode>
        <Flex style={{ justifyContent: 'space-between' }}>
          <H4 color="white">Videos</H4>
          {!!options.length && (
            <OptionSelector
              onClick={(e) => e.stopPropagation()}
              options={options}
            >
              <Button
                icon
                transparent
                style={{ fontSize: 14 }}
                color={colors['light-grey-20']}
              >
                <FontAwesomeIcon icon={['fas', 'ellipsis-v']} />
              </Button>
            </OptionSelector>
          )}
        </Flex>
        {!!files.length && (
          <FilesContainer darkMode>
            <Files>{videoFiles}</Files>
          </FilesContainer>
        )}
        <StyledUploadBox
          types={['mov', 'avi', 'mp4']}
          allowMultiple={true}
          darkMode
        />
      </FileTypeContainer>
    </Container>
  );
}

VideoFiles.propTypes = {
  files: PropTypes.array,
  activeFile: PropTypes.object,
  activeProject: PropTypes.object,
  fileHasComments: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  setSelectedDelete: PropTypes.func.isRequired,
  onProgress: PropTypes.func.isRequired,
  onDownloadError: PropTypes.func.isRequired,
};

VideoFiles.defaultProps = {
  files: [],
  activeFile: {},
  activeProject: {},
};

export default VideoFiles;
