import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

import React, { useContext, useState } from 'react';

import { motion, AnimatePresence } from 'framer-motion';

import { post } from '../../util/fetchUtil';

import Checkbox from '../Checkbox';
import Button from '../RebassButton';

import { goToUrl } from '../../shared/links';
import { TextInput } from '../Input';
import Box from '../Box';
import ProjectGroupProps from '../../types/ProjectGroupProps';
import { Store } from '../../state/store';

type Props = {
  groups: any[];
  handleHideDialog: () => void;
};

type PendingProjectProps = {
  projectID: number;
};

function NewProject(props: Props) {
  const { groups, handleHideDialog } = props;

  const groupOptions = groups.map((group) => ({ ...group, checked: false }));

  const {
    state: { user },
  } = useContext(Store);

  const [newProjectName, setNewProjectName] = useState('');
  const [pendingProject, setPendingProject] = useState<PendingProjectProps>();
  const [options, setOptions] = useState(groupOptions);
  const [errorStatus, setErrorStatus] = useState<number | null>(null);

  const handleNameInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    setNewProjectName(value);

    // Reset error status
    setErrorStatus(null);
  };

  const handleCheckboxChange = (option: ProjectGroupProps) => {
    const newOptions = Array.from(options);
    const index = newOptions.findIndex(
      (op) => op.projectGroupID === option.projectGroupID
    );
    newOptions[index].checked = !newOptions[index].checked;

    setOptions(newOptions);
  };

  const handleCreateProject = () => {
    post('/project/create', {
      name: newProjectName,
      groups: options
        .filter((group) => group.checked)
        .map((group) => group.projectGroupID),
    }).then((res) => {
      if (res.project) {
        goToUrl({ projectID: res.project.projectID, view: 'project' });
        handleHideDialog();
      }

      // Get project if it already exists
      return post('/project/getProjectByName', {
        projectName: newProjectName,
      }).then((projectRes) => {
        setPendingProject(projectRes.project);
        if (projectRes.project.status === 0) {
          setErrorStatus(0);
        } else {
          setErrorStatus(1);
        }
      });
    });
  };

  const handleJoinProject = () => {
    // Get project
    if (pendingProject) {
      post('/project/subscribeToProject', {
        projectIDs: [pendingProject.projectID],
        userIDs: [user.userID],
        subscribed: true,
      }).then(() => {
        goToUrl({ projectID: pendingProject.projectID, view: 'project' });

        handleHideDialog();
      });
    }
  };

  const handleReviveProject = () => {
    if (pendingProject) {
      post('/project/updateStatus', {
        projectID: pendingProject?.projectID,
        status: 1,
      }).then(() => {
        goToUrl({ projectID: pendingProject.projectID, view: 'project' });

        handleHideDialog();
      });
    }
  };

  const checkboxes = options.map((option) => (
    <Box display="flex" key={option.name} mb="10px">
      <Checkbox
        checked={option.checked}
        onChange={() => handleCheckboxChange(option)}
        label={option.name}
      ></Checkbox>
    </Box>
  ));

  return (
    <Container>
      <Header>New project</Header>
      <TextInput
        style={{ width: '100%' }}
        value={newProjectName}
        onChange={handleNameInputChange}
        placeholder="New project name..."
      />
      <Subheader>Project folders</Subheader>
      <Box mb="20px">{checkboxes}</Box>
      <ButtonsContainer>
        <Button
          bg="positive"
          onClick={handleCreateProject}
          enabled={!!newProjectName}
        >
          Create project
        </Button>
        <Button variant="outline" color="dark-6" onClick={handleHideDialog}>
          Cancel
        </Button>
      </ButtonsContainer>
      <AnimatePresence>
        {errorStatus === 0 && (
          <motion.div
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 10, opacity: 0 }}
          >
            <Message className="negative message">
              <Text>
                Uh oh, looks like that project already exists, but has been
                deleted.
              </Text>
              <Button
                onClick={handleReviveProject}
                className="left-icon thin red basic box"
              >
                <FontAwesomeIcon icon={['fad', 'feather']} />
                Revive
              </Button>
            </Message>
          </motion.div>
        )}
        {errorStatus === 1 && (
          <motion.div
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 10, opacity: 0 }}
          >
            <Message className="negative message">
              <Text>Uh oh, looks like that project already exists.</Text>
              <Button
                onClick={handleJoinProject}
                className="basic box thin red"
              >
                Join project
              </Button>
            </Message>
          </motion.div>
        )}
      </AnimatePresence>
    </Container>
  );
}

const Container = styled.div``;
const Message = styled.div``;
const Text = styled.p`
  margin-top: 0;

  max-width: 400px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  > * {
    margin-right: 10px;
  }
`;

const Header = styled.h2`
  margin: 0;
  margin-bottom: 20px;
`;

const Subheader = styled.h3``;

NewProject.defaultProps = {
  groups: [],
};

export default NewProject;
