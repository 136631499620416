import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useState, useContext, useEffect } from 'react';

import { AnimatePresence } from 'framer-motion';

import { post } from '../../util/fetchUtil';

import { Store } from '../../state/store';

import { fetchNotifications } from '../../state/notifications/actions';

import NotificationsList from './NotificationsList';

import {
  BellNotification,
  BellIcon,
  MotionContainer,
} from '../navigation/HeaderBarStyles';

const Notifications = () => {
  const {
    state: { notifications },
    dispatch,
  } = useContext(Store);

  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const [notificationsData, setNotificationsData] = useState([]);

  const activeNotifications = notifications.filter(
    (notification) => notification.status === 0
  );
  const hasActiveNotifications = !!activeNotifications.length;

  /**
   * Updates the status for an array of notifications
   *
   * @param {Array} notificationIDs - array of IDs
   * @param {Number} status - status to update to
   * @memberof HeaderBar
   */
  const handleUpdateStatus = async (notificationIDs, status) => {
    await post('/notifications/updateStatus', {
      notificationIDs,
      status,
    });

    fetchNotifications(dispatch);
  };

  const toggleNotifications = () =>
    setNotificationsVisible(!notificationsVisible);

  const fetchNotificationData = async (ids) => {
    const res = await post('/notifications/populateNotifications', {
      ids,
    });

    setNotificationsData(res.notifications);
  };

  useEffect(() => {
    (async () => {
      if (notificationsVisible) {
        await fetchNotificationData(
          notifications
            .slice(0, notificationsData.length || 5)
            .map((n) => n.notificationID)
        );
      }
    })();
  }, [notificationsVisible, notifications]);

  return (
    <BellIcon
      hasActiveNotifications={hasActiveNotifications}
      onClick={toggleNotifications}
    >
      <FontAwesomeIcon icon={['fad', 'bell']} />
      {hasActiveNotifications && (
        <BellNotification>{activeNotifications.length}</BellNotification>
      )}
      <AnimatePresence>
        {notificationsVisible && (
          <MotionContainer
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0, transition: { ease: 'easeOut' } }}
          >
            <NotificationsList
              notifications={notificationsData}
              allNotifications={notifications}
              handleUpdateStatus={handleUpdateStatus}
              toggleVisible={toggleNotifications}
              fetchNotificationData={fetchNotificationData}
            />
          </MotionContainer>
        )}
      </AnimatePresence>
    </BellIcon>
  );
};

export default Notifications;
