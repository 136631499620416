import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

const Container = styled.div`
  height: 100%;

  ${(props) =>
    props.size &&
    css`
      height: ${props.size}px;
      width: ${props.size}px;
    `}

  img {
    border-radius: 100%;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
`;

function Avatar(props) {
  const { src, className, size, style } = props;

  return (
    <Container className={className} style={style} size={size}>
      <img alt="Avatar" src={src} />
    </Container>
  );
}

Avatar.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
};

Avatar.defaultProps = {
  src: '',
  className: '',
  size: 30,
};

export default Avatar;
