import { createGlobalStyle } from 'styled-components/macro';
import { colors, darken } from '../ui/styles/colors';

// Font weights
export const regular = 400;
export const bold = 400;

// Base Font
export const baseFontFamily = 'Nunito';
export const baseFontWeight = regular;
export const baseFontSize = 16;

const Typography = createGlobalStyle`
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background: #fff;
  font-family: ${baseFontFamily}, 'Open Sans', sans-serif;
  font-weight: ${baseFontWeight};
  margin: 0;
}

strong { font-weight: 700; }

a, a:hover, a:focus {
  color: ${colors.blue};
  text-decoration: none;
    -o-transition: all .3s;
    -moz-transition: all .3s;
    -webkit-transition: all .3s;
    -ms-transition: all .3s;
    transition: all .3s;
}

h1, h2, h3, h4, h5,
p, li, ul, label {
  font-size: ${baseFontSize};
}

pre code * {
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
}

h1, h2, h3, h4, h5 {
  margin-top: 20px;
  margin-bottom: 20px;

  line-height: 1.4;

  &.span {
    display: inline-block;
  }
}

h1 {
  color: ${darken(colors.grey, 15)};
  
  font-weight: 700;
  font-size: 32px;

  &.inverted {
    color: white;
  }
}

h2 {
  color: ${darken(colors.grey, 15)};
  font-weight: 700;
  font-size: 24px;

  &.inverted {
    color: white;
    opacity: .95;
  }
}

h3 {
  color: ${darken(colors.grey, 10)};
  font-weight: 700;
  font-size: 18px;

  &.inverted {
    color: white;
    opacity: .9;
  }
}

h4 {
  color: ${colors.grey};
  font-weight: 700;
  font-size: 16px;

  &.inverted {
    color: white;
    opacity: .9;
  }
}

p, li {
  color: ${colors.grey};
  font-size: 16px;

  &.inverted {
    color: white;
    opacity: .85;
  }
}
`;

export default Typography;
