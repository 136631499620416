import React from 'react';
import moment from 'moment';
import Box, { Flex } from '../Box';
import FileProps from '../editor/types/FileProps';
import { colors } from '../styles/colors';
import { H4, P } from '../styles/typography';

interface Props {
  file: FileProps;
}

const ImageFileInfo: React.FC<Props> = (props: Props) => {
  const { file } = props;
  return (
    <Box>
      <Flex>
        <Flex
          c
          bg={colors['light-grey-50']}
          sx={{
            borderRadius: 5,
            overflow: 'hidden',
            maxWidth: '25%',
            maxHeight: 100,
          }}
        >
          <img
            alt="File to add to directory"
            src={file?.data.url}
            style={{ objectFit: 'contain', maxWidth: '100%', height: '100%' }}
          />
        </Flex>
        <Box ml={20}>
          <Box mb={10}>
            <H4>Name</H4>
            <P>{file?.name}</P>
          </Box>
          <H4>Created on</H4>
          <P>{moment(file?.createdOn).format('DD-MM-YYYY')}</P>
        </Box>
      </Flex>
    </Box>
  );
};

export default ImageFileInfo;
