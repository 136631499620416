import fetchUtil from '../../util/fetchUtil';

export const fetchNotifications = async (dispatch) => {
  const res = await fetchUtil.post('/notifications/getNotifications');

  return dispatch({
    type: 'FETCH_NOTIFICATIONS',
    payload: res.notifications,
  });
};
