import styled from 'styled-components/macro';

import React, { useState, useRef } from 'react';

import { lighten, colors } from '../../styles/colors';
import useClickOutside from '../../../app/hooks/useClickOutside';

import { Flex } from '../../Box';

import { P } from '../../styles/typography';

import {
  StyledTextInput,
  NewFolderButton,
  StyledIcon,
} from './FileExplorerStyles';

const Name = styled(P)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
  display: inline-block;
`;

/**
 * Small component that handles editing a directory name
 * @returns
 */

type Props = {
  onSave: (newName: string) => void;
  name?: string;
  active?: boolean;
  editable?: boolean;
};

const FolderName: React.FC<Props> = (props: Props) => {
  const { name, onSave, active, editable } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(name || '');
  const boxRef = useRef();

  const handleSave = () => {
    setIsEditing(false);
    onSave(value);
  };

  const handleToggleEdit = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (editable) setIsEditing(!isEditing);
  };

  const handleClickOutside = () => {
    setIsEditing(false);
  };

  useClickOutside(boxRef, handleClickOutside);

  return (
    <Flex style={{ display: 'inline-block' }} ref={boxRef}>
      <Flex onClick={handleToggleEdit}>
        <Name
          title={name}
          style={{
            display: isEditing ? 'none' : 'inline-block',
            ...(active ? { color: colors.blue } : {}),
          }}
        >
          {name}
        </Name>
      </Flex>

      {isEditing && (
        <StyledTextInput
          value={value}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setValue(e.currentTarget.value)
          }
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
          handleEnterPress={handleSave}
          placeholder="Enter folder name..."
        />
      )}
      <NewFolderButton
        style={{ display: name || isEditing ? 'none' : 'inline-block' }}
        onClick={handleToggleEdit}
      >
        <StyledIcon
          style={{ color: lighten(colors.grey, 35) }}
          icon="plus"
          prefix="far"
        />
        New folder
      </NewFolderButton>
    </Flex>
  );
};

FolderName.defaultProps = {
  active: false,
  name: '',
  editable: true,
};

export default FolderName;
