import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React from 'react';

import { useState } from 'react';

import fetchUtil from '../../../util/fetchUtil';

import Dialog from '../../Dialog';

import Button from '../../Button';
import { TextInput } from '../../Input';

import { H4 } from '../../styles/typography';
import { colors } from '../../styles/colors';

const Container = styled.div``;
const Section = styled.div`
  margin-bottom: 15px;
`;
const SectionHeader = styled(H4)`
  color: ${colors['light-grey-30']};
  margin-bottom: 10px;
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
`;

function AddUserDialog(props) {
  const { handleHideDialog, isVisible, updateUsers } = props;

  const [email, setEmail] = useState('');
  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [userID, setUserID] = useState('');

  const handleSubmit = async () => {
    handleHideDialog();
    await fetchUtil.post('/admin/addUser', {
      userID: parseInt(userID, 10),
      email,
      givenName,
      familyName,
    });

    updateUsers();
  };

  return (
    <Dialog handleHideDialog={handleHideDialog} isVisible={isVisible}>
      <Container>
        <Section>
          <SectionHeader>UserID</SectionHeader>
          <StyledTextInput
            value={userID}
            onChange={(e) => setUserID(e.target.value)}
            placeholder="Enter a number..."
          />
        </Section>
        <Section>
          <SectionHeader>Email</SectionHeader>
          <StyledTextInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="bobafett@osmosis.org"
          />
        </Section>
        <Section>
          <SectionHeader>Given name</SectionHeader>
          <StyledTextInput
            value={givenName}
            onChange={(e) => setGivenName(e.target.value)}
            placeholder="Boba"
          />
        </Section>
        <Section>
          <SectionHeader>Family name</SectionHeader>
          <StyledTextInput
            value={familyName}
            onChange={(e) => setFamilyName(e.target.value)}
            placeholder="Fett"
          />
        </Section>
        <Button
          backgroundColor={colors.green}
          color="white"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Container>
    </Dialog>
  );
}

const { func, bool } = PropTypes;

AddUserDialog.propTypes = {
  handleHideDialog: func.isRequired,
  isVisible: bool.isRequired,
};

AddUserDialog.defaultProps = {};

export default AddUserDialog;
