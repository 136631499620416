import styled, { css } from 'styled-components/macro';

import { lighten, colors, fade } from '../../styles/colors';

import Icon from '../../Icon';
import Box from '../../Box';
import Button from '../../Button';
import { TextInput } from '../../Input';

export const StyledTextInput = styled(TextInput)`
  padding: 5px 8px;
`;

export const Item = styled.div`
  color: ${colors.grey};
  padding: 3px 5px;
  margin: 3px;
  border-radius: 3px;

  cursor: pointer;

  :hover,
  :focus {
    background-color: ${fade(colors.grey, 85)};
  }
`;

export const NewFolderButton = styled(Item)`
  color: ${lighten(colors.grey, 35)};
  display: inline-block;

  :hover,
  :focus {
    color: ${colors['light-grey-20']};
  }
`;

export const FilesContainer = styled(Box)`
  border-radius: 3px;
  ${(props: { active?: boolean }) =>
    props.active &&
    css`
      background-color: ${lighten(colors.blue, 40)};
    `}
`;

export const FileContainer = styled(Item)`
  color: ${colors['light-grey-20']};

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${(props: { active?: boolean }) =>
    props.active &&
    css`
      color: ${colors['light-blue-10']};
    `}
`;
export const DirnameContainer = styled(Item)`
  display: flex;
  justify-content: space-between;

  ${(props: { active?: boolean }) =>
    props.active &&
    css`
      background-color: ${lighten(colors.blue, 40)};
    `}
`;
export const FolderButton = styled(Button)`
  font-size: 12px;
  opacity: 0;

  ${DirnameContainer}:hover & {
    opacity: 1;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-right: 5px;
`;

export const FolderContainer = styled.div``;

export const FolderContents = styled.div`
  border-left: 1px solid ${colors['light-grey-50']};
  margin-left: 17px;
  padding: 0 0 0 6px;

  overflow: hidden;

  ${(props: { active?: boolean }) =>
    props.active &&
    css`
      border-left: 1px solid ${colors['light-blue-30']};
    `}
`;
export const RemoveFileButton = styled(Button)`
  opacity: 0;
  ${FileContainer}:hover & {
    opacity: 1;
  }
`;
export const ActionsBox = styled(Box)`
  padding: 10px;
  border-top: 1px solid ${colors['light-grey-50']};
  border-bottom: 1px solid ${colors['light-grey-50']};
  background-color: ${lighten(colors.grey, 55)};
`;
