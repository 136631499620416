import SVG from 'svgjs';

import Anim from '../anim';
import setDashoffset from '../setDashoffset';

export default function (scene, animation, id, dimensions, onError) {
  let name;

  const elementId = id.toString();

  const draw = SVG(elementId)
    .style({
      'stroke-linecap': 'round',
      'stroke-miterlimit': '1.5',
      'stroke-linejoin': 'round',
    })
    .addClass('scene-component')
    .viewbox(0, 0, dimensions.w, dimensions.h);

  name = 'Animation';
  if (scene.id !== '') {
    name = `Animation-${scene.id}`;
  }
  const type = 'pathSvg';

  // Duration factor - lower = slower, higher = faster
  const durationFactor = 1;
  const offsetDelay = durationFactor * 200;

  // Delay between subsequent animations
  let dataDelay = 0;

  // Create groups to hold paths, as defined in Affinity Designer
  const sceneGroup = draw.group().attr({
    name: scene.id,
  });

  // Function to set the timing and path data for the animation
  const setPathData = function (sceneChild, animationChild) {
    // Check to make sure sceneChild has corresponding animationChild
    if (!sceneChild || !animationChild) {
      onError(
        'Uh oh, something went wrong with importing the scene and corresponding animation' +
          'Double-check that there are no strokes will a fill!',
        'Whoops!'
      );
    } else {
      const pathDataDuration = Math.ceil(
        sceneChild.getTotalLength() / durationFactor
      );
      // Create separate path objects for both the scene and animation paths
      const scenePath = draw
        .path(sceneChild.getAttribute('d'))
        .style(sceneChild.getAttribute('style'))
        .attr({
          'data-ignore': 'true',
        });
      const animationPath = draw
        .path(animationChild.getAttribute('d'))
        .style(animationChild.getAttribute('style'))
        .attr({
          'data-duration': pathDataDuration,
          'data-delay': dataDelay,
        })
        .addClass(`${elementId}-anim-path`);

      // Create group container to hold scenePath
      const pathGroup = draw.group();
      pathGroup.add(animationPath);

      // Add group containing scene and animation paths to larger group as defined in Affinity Designer
      sceneGroup.add(pathGroup);

      // If the Scene path has no fill, then it must have a constant pressure profile and be the same as the animation path.
      if (sceneChild.style.fill === 'none') {
        scenePath.remove();
      } else {
        // Otherwise use the scene path to mask the animation path
        const clip = draw.clip().add(scenePath);
        animationPath.clipWith(clip);
      }

      // Add a delay (in ms) between subsequent paths (only for paths after the first path)
      dataDelay += offsetDelay + pathDataDuration;
    }
  };

  // Variable to abort loading group if toggled true
  let stopLoad = false;

  // Recursive function to look for all Paths within sceneGroup and animationGroup
  const addPathsToGroup = function (sceneParentGroup, animationParentGroup) {
    for (let j = 0; j < animationParentGroup.children.length; j += 1) {
      // If stopLoad toggled true, break from loop
      if (stopLoad === true) {
        break;
      }

      const sceneChild = sceneParentGroup.children[j];
      const animationChild = animationParentGroup.children[j];

      // The animation curve should never have a fill.
      if (
        animationChild.style.fill !== 'none' &&
        animationChild.style.fill !== ''
      ) {
        onError(
          "There's a path element that has a fill to one of your groups!" +
            'Make sure to remove any fill colors from the animatable curves.',
          'Whoops!'
        );

        stopLoad = true;
      } else {
        // Load path or group
        // eslint-disable-next-line no-lonely-if
        if (animationChild.nodeName === 'path') {
          setPathData(sceneChild, animationChild);
        } else if (animationChild.nodeName === 'g') {
          // If there's a nested group inside the sceneGroup/animationGroup, calls itself with the children as args
          addPathsToGroup(sceneChild, animationChild);
        } else {
          onError(
            `Found a problem layer in ${name}` +
              "If it's an animated group, make sure there are no rasterized layers within the group.",
            'Whoops!'
          );
        }
      }
    }
  };

  if (scene.nodeName === 'path') {
    setPathData(scene, animation);
  } else {
    // Run recursive search for paths within the sceneGroup/animationGroup
    addPathsToGroup(scene, animation);
  }

  const bbox = sceneGroup.bbox();

  const anim = Anim({
    targets: `.${elementId}-anim-path`,
    // BUG: artifact sometimes appears depending on curve length
    strokeDashoffset: [setDashoffset, 0],
    autoplay: false,
    duration(target) {
      // Duration based on every 'data-duration' attribute
      return target.getAttribute('data-duration');
    },
    delay(target) {
      // 100ms delay multiplied by every div index, in ascending order
      return target.getAttribute('data-delay');
    },
    easing: 'easeInOutSine',
  });

  // Set initial value to end of animation
  anim.finish();

  return {
    draw,
    anim,
    name,
    type,
    bbox,
  };
}
