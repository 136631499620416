import PropTypes from 'prop-types';

const { shape, number, string, oneOf } = PropTypes;

export default shape({
  commentID: number,
  createdOn: string,
  sceneID: number,
  userID: number,
  data: shape({
    annotations: string,
    status: oneOf([1, 2]),
    text: string,
    timestamp: number,
    resolved: shape({
      givenName: string,
      familyName: string,
      userID: number,
      timestamp: string,
    }),
    user: shape({
      userID: number,
      data: shape({}),
      familyName: string,
      givenName: string,
    }),
  }),
});

// :
// commentID: 32
// createdOn: "2020-10-07T14:54:27.000Z"
// data:
// annotations: "https://osmosify-dev.s3.amazonaws.com/F95sqSQFSTicGjBaFcEblYnVTM6OBmKR.json"
// resolved: {givenName: "Tanner", familyName: "Marshall", userID: 10000075, timestamp: "2020-10-07T12:07:01-04:00"}
// status: 2
// text: "<p>fdsff</p>"
// timestamp: 52350
// user: {userID: 10000075, password: "", data: {…}, familyName: "Marshall", givenName: "Tanner", …}
// __proto__: Object
// sceneID: 90
// status: 1
// userID: 10000075
