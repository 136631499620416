import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React from 'react';

import { motion, AnimatePresence } from 'framer-motion';

import moment from 'moment';

import { post } from '../../util/fetchUtil';

import OptionSelector from '../OptionSelector';
import RowInfoDialog from './analytics/RowInfoDialog';

import Table from '../Table';
import Dialog from '../Dialog';

import AnalyticsChart from './analytics/AnalyticsChart';
import AnalyticsTotals from './analytics/AnalyticsTotals';
import Button from '../Button';

import getTasks from '../../app/analytics/getTasks';

import { colors } from '../styles/colors';

function RoleStat(props) {
  const { name, date, hours } = props;

  return (
    <div>
      <Name>
        <Icon icon={['fad', 'check-circle']} />
        {name}
      </Name>
      <Date>
        {hours}
        &nbsp;hrs,&nbsp;
        {date}
      </Date>
    </div>
  );
}

RoleStat.propTypes = {
  name: PropTypes.string,
  date: PropTypes.string,
  hours: PropTypes.number,
};

const rangeOptions = [
  {
    text: 'Last month',
    value: 'lastMonth',
    date: moment().subtract(1, 'month'),
  },
  { text: 'This month', value: 'thisMonth', date: moment() },
];

const metricOptions = ['Projects', 'Words'];

class AdminAnalytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      groups: [],
      team: [],
      filters: [],
      rowInfoDialogVisible: false,
      rowInfo: {},
      selectedUser: { name: 'All users', id: 'all' },
      selectedGroup: { name: 'All folders', id: 'all' },
      activeMonth: rangeOptions[1].value,
      selectedMetric: { name: metricOptions[0], id: metricOptions[0] },
      groupBy: { id: 'week', name: 'Week' },
    };

    this.onRowClick = this.onRowClick.bind(this);
    this.getAdminAnalytics = this.getAdminAnalytics.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
  }

  componentDidMount() {
    this.getAdminAnalytics();
    post('/project/getProjectGroups').then((res) =>
      this.setState({ groups: res.groups })
    );
    post('/user/getTeam').then((res) => this.setState({ team: res.team }));
  }

  handleMonthChange(e) {
    const { value } = e.target;
    this.setState({ activeMonth: value });
  }

  getAdminAnalytics() {
    post('/admin/getAdminAnalytics').then((res) =>
      this.setState({ projects: res.tracking })
    );
  }

  onRowClick(row) {
    this.setState({ rowInfoDialogVisible: true, rowInfo: row });
  }

  render() {
    const {
      projects,
      rowInfoDialogVisible,
      rowInfo,
      team,
      groups,
      activeMonth,
      selectedUser,
      selectedGroup,
      selectedMetric,
      groupBy,
    } = this.state;

    const headers = [
      {
        id: 'project',
        value: 'Project',
      },
      {
        id: 'wordCount',
        value: 'Words',
        width: 100,
        align: 'center',
        default: '-',
      },
      {
        id: 'illustrator',
        value: 'Illustrator',
        default: '-',
      },
      {
        id: 'voiceover',
        value: 'Voiceover',
        default: '-',
      },
      {
        id: 'videoEditor',
        value: 'Video Editor',
        default: '-',
      },
    ];

    // Get the selected month as a moment object
    let month;
    const activeRangeOption = rangeOptions.find(
      (op) => op.value === activeMonth
    );
    if (activeRangeOption) month = activeRangeOption.date;
    else if (activeMonth) month = moment(activeMonth);

    const groupFilter = (project) =>
      selectedGroup.id === 'all' ||
      project.groups.find((gId) => gId === selectedGroup.id);

    let userFilter = (project) => project;
    if (selectedUser.id !== 'all')
      userFilter = (task) => task.userID === selectedUser.userID;

    // Apply any filters to the data
    const filteredProjects = projects
      // Filter projects by the group chosen
      .filter(groupFilter);

    // Get object of tasks organized by task type
    const tasks = getTasks(filteredProjects)
      // Filter tasks by the userID chosen
      .map((task) => ({ ...task, list: task.list.filter(userFilter) }))
      .sort((a, b) => (a.taskID > b.taskID ? 1 : -1));

    const rows = projects
      // Filter by project group
      .filter(groupFilter)
      // Filter by date - only allow projects with tasks in the specified date range
      .filter(
        (project) =>
          project.data.tasks &&
          project.data.tasks.find(
            (task) =>
              moment(task.date).format('MM/YY') === month.format('MM/YY')
          )
      )
      // Filter by user
      .filter((project) =>
        selectedUser.id !== 'all'
          ? project.data.tasks &&
            project.data.tasks.find(
              (task) => task.userID === selectedUser.userID
            )
          : project
      )
      .map((project) => {
        const illustrator =
          project.data.tasks &&
          project.data.tasks.find((task) => task.taskID === 1);
        const voiceover =
          project.data.tasks &&
          project.data.tasks.find((task) => task.taskID === 2);
        const videoEditor =
          project.data.tasks &&
          project.data.tasks.find((task) => task.taskID === 4);

        return {
          project: (
            <Link
              href={`/#/projectContents/${project.name}`}
              target="_blank"
              projectID={project.projectID}
            >
              {project.name}
            </Link>
          ),
          wordCount: project.wordCount,
          illustrator: illustrator && (
            <RoleStat
              name={illustrator.user.givenName}
              userID={illustrator.user.userID}
              date={moment(illustrator.date).format('MM-DD-YY')}
              hours={illustrator.hours}
              taskID={1}
            />
          ),
          voiceover: voiceover && (
            <RoleStat
              name={voiceover.user.givenName}
              userID={voiceover.user.userID}
              date={moment(voiceover.date).format('MM-DD-YY')}
              hours={voiceover.hours}
              taskID={2}
            />
          ),
          videoEditor: videoEditor && (
            <RoleStat
              name={videoEditor.user.givenName}
              userID={videoEditor.user.userID}
              date={moment(videoEditor.date).format('MM-DD-YY')}
              hours={videoEditor.hours}
              taskID={4}
            />
          ),
        };
      });

    // Date range button components
    const rangeButtons = rangeOptions.map((option) => (
      <Button
        className={`box thin ${
          option.value === activeMonth ? 'blue' : 'basic'
        }`}
        key={option.value}
        onClick={() => this.setState({ activeMonth: option.value })}
      >
        {option.text}
      </Button>
    ));

    return (
      <Container>
        <Dialog
          handleHideDialog={() =>
            this.setState({ rowInfoDialogVisible: false })
          }
          isVisible={rowInfoDialogVisible}
        >
          <RowInfoDialog
            getAdminAnalytics={this.getAdminAnalytics}
            row={rowInfo}
          />
        </Dialog>
        <Actions>
          <OptionSelector
            options={metricOptions.map((op) => ({ name: op, id: op }))}
            selectedOption={selectedMetric}
            onSelectOption={(option) =>
              this.setState({ selectedMetric: option })
            }
            buttonClasses="thin box basic"
            placeholder="Choose metric..."
          />
          <OptionSelector
            options={[
              { name: 'All users', id: 'all' },
              ...team.map((teammate) => ({
                ...teammate,
                name: teammate.fullName,
                id: teammate.userID,
              })),
            ]}
            selectedOption={selectedUser}
            onSelectOption={(option) => this.setState({ selectedUser: option })}
            buttonClasses="thin box basic"
            placeholder="Choose user..."
            hasAutocomplete={true}
            autocompletePlaceholder="Filter users..."
          />
          <OptionSelector
            options={[
              { name: 'All folders', id: 'all' },
              ...groups.map((group) => ({
                ...group,
                name: group.name,
                id: group.projectGroupID,
              })),
            ]}
            selectedOption={selectedGroup}
            onSelectOption={(option) =>
              this.setState({ selectedGroup: option })
            }
            buttonClasses="thin box basic"
            placeholder="Choose folder..."
          />
          {/* <OptionSelector FIXME: NOT DISPLAYING BY DAY PROPERLY
            options={[{ name: 'Day', id: 'day' }, { name: 'Week', id: 'week' }]}
            onSelectOption={option => this.setState({ groupBy: option })}
            selectedOption={groupBy}
            buttonClasses="thin box basic"
          /> */}
          {rangeButtons}
          <MonthInput className="text-input">
            <input type="month" onChange={this.handleMonthChange} />
          </MonthInput>
        </Actions>
        <AnalyticsChart
          team={team}
          tasks={tasks}
          activeMonth={activeMonth}
          rangeOptions={rangeOptions}
          selectedMetric={selectedMetric.name}
          groupBy={groupBy.id}
        />
        <TotalsContainer>
          <AnalyticsTotals tasks={tasks} month={month} />
        </TotalsContainer>
        <AnimatePresence initial={false}>
          {!rows.length && (
            <MessageContainer
              initial={{ y: 10, opacity: 0, height: 0 }}
              animate={{ y: 0, opacity: 1, height: 'auto' }}
              exit={{
                y: 10,
                opacity: 0,
                height: 0,
                transition: { easing: 'easeOut' },
              }}
            >
              <Message className="message">
                <FontAwesomeIcon icon={['fad', 'exclamation-circle']} />
                No projects found with the given filters
              </Message>
            </MessageContainer>
          )}
          {!!rows.length && (
            <TableContainer
              initial={{ y: 10, opacity: 0, height: 0 }}
              animate={{ y: 0, opacity: 1, height: 'auto' }}
              exit={{
                y: 10,
                opacity: 0,
                height: 0,
                transition: { easing: 'easeOut' },
              }}
            >
              <Table
                headers={headers}
                rows={rows}
                onRowClick={this.onRowClick}
              />
            </TableContainer>
          )}
        </AnimatePresence>
      </Container>
    );
  }
}

const Container = styled.div``;

const Date = styled.p`
  margin: 0;
  font-size: 14px;
  color: ${colors['light-grey-20']};
`;

const Name = styled.p`
  margin: 0;
  margin-bottom: 2px;
`;

const TotalsContainer = styled.div``;

const MessageContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
`;

const Message = styled.div`
  display: inline-block;
  padding: 15px 25px;

  font-size: 18px;

  i,
  svg {
    margin-right: 10px;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${colors.green};
  margin-right: 5px;
`;

const Link = styled.a`
  color: ${colors.grey};
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin: 15px;

  > * {
    margin-right: 10px;
  }
`;

const MonthInput = styled.div`
  input {
    padding: 2px 7px;
  }
`;

const TableContainer = styled(motion.div)``;

AdminAnalytics.propTypes = {
  propName: PropTypes.string,
};

AdminAnalytics.defaultProps = {
  propName: '',
};

export default AdminAnalytics;
