import { motion } from 'framer-motion';
import React from 'react';
import scale from './animations/scale';
import slide from './animations/slide';
import { Flex, Box } from './Box';
import Text from './Text';
import { colors } from './styles/colors';

interface Props {
  grant: string;
}

const AccessMessage = (props: Props) => {
  const { grant } = props;
  return (
    <Flex c width="100%" height="100%">
      <Box>
        <motion.div {...scale} transition={{ delay: 0.5 }}>
          <Box width="400px">
            <img
              style={{ transform: 'scaleX(-1)', filter: 'hue-rotate(304deg)' }}
              src="https://osmosify.s3.amazonaws.com/NJioJU5hQOaWH23Mus6rae2nSeWWGgH9.png"
              width="100%"
              alt="cell wizard"
            />
          </Box>
        </motion.div>
        <motion.div {...slide.down} transition={{ delay: 0.6 }}>
          <Text as="h1" color={colors.red} mb={10}>
            You shall not pass!
          </Text>
          <Box fontSize={18} maxWidth={500}>
            <Text mb="5px">
              Sorry, it looks like you don&apos;t have access to <b>{grant}</b>.
            </Text>
            <Text>
              Not to worry, contact your friendly neighborhood admin to get
              access.
            </Text>
          </Box>
        </motion.div>
      </Box>
    </Flex>
  );
};

export default AccessMessage;
