import PropTypes from 'prop-types';

const { string, arrayOf, number, shape } = PropTypes;

export const UserProps = shape({
  avatar: string,
  degrees: arrayOf(string),
  familyName: string,
  fullName: string,
  givenName: string,
  roles: arrayOf(string),
  userID: number,
  grants: arrayOf(string),
});
