import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import fetchUtil from '../../util/fetchUtil';

import { getFullUrl } from '../../shared/links';

import { TextInput } from '../Input';
import ListItemSelector from '../ListItemSelector';
import Button from '../Button';
import Box, { Flex } from '../Box';
import Message from '../Message';
import LoadingSpinner from '../LoadingSpinner';
import TrelloCardWrapper from '../TrelloCardWrapper';
import { P, H2 } from '../styles/typography';
import { colors } from '../styles/colors';

function LinkToTrello(props) {
  const { project, updateProject, handleHideDialog } = props;

  const { groups } = project;
  const [errorMessage, setErrorMessage] = useState(false);
  const [existingProject, setExistingProject] = useState(null);
  const [cards, setCards] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const cardItems = cards.filter((card) =>
    card.name.toLowerCase().includes(filterValue.toLowerCase())
  );

  const selectedCard = cardItems.find((card) => card.checked);

  const linkTrelloCardToProject = async () => {
    const res = await fetchUtil.post('/project/linkTrelloCard', {
      projectID: project.projectID,
      trelloCardId: selectedCard.id,
    });

    if (res.existingProject) {
      setExistingProject(res.existingProject);
      setErrorMessage(true);
    } else {
      updateProject();
      handleHideDialog();
    }
  };

  const getGroupTrelloBoards = async () => {
    if (groups && groups.length) {
      setIsLoading(true);

      const res = await fetchUtil.post('/project/getGroupTrelloBoards', {
        projectGroupIDs: groups,
      });

      setIsLoading(false);

      if (res.cards) {
        setCards(
          res.cards.map((card) => ({
            name: card.name,
            id: card.id,
            checked: false,
          }))
        );
      }
    }
  };

  const handleSelectItem = (item) => {
    const newCards = Array.from(cards).map((card) => ({
      ...card,
      checked: false,
    }));
    const card = newCards.find((c) => item.id === c.id);
    card.checked = !card.checked;

    setCards(newCards);
  };

  useEffect(() => {
    getGroupTrelloBoards();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <H2 style={{ marginBottom: 10 }}>Choose card to link</H2>
      <TextInput
        value={filterValue}
        onChange={(e) => setFilterValue(e.target.value)}
        placeholder="Filter results by name..."
        style={{ width: '100%' }}
      />
      <Flex c style={{ padding: 20 }}>
        {isLoading && <LoadingSpinner />}
        {!isLoading && !cardItems.length && (
          <P>No Trello cards found for this project&apos;s folders.</P>
        )}
      </Flex>
      {(!groups || !groups.length) && (
        <Message negative>
          This project isn&apos;t in any folders, please add it to at least one
          folder to retrieve Trello cards.
        </Message>
      )}
      <ListItemSelector onItemClick={handleSelectItem} items={cardItems} />
      <Box style={{ margin: 20 }}>
        {selectedCard && (
          <TrelloCardWrapper
            style={{ width: '100%' }}
            cardId={selectedCard.id}
          />
        )}
      </Box>
      <Flex c>
        <Button
          enable={!!selectedCard}
          backgroundColor={colors.blue}
          color="white"
          onClick={linkTrelloCardToProject}
        >
          Link Trello card to project
        </Button>
      </Flex>
      {errorMessage && (
        <Message style={{ display: 'block', marginTop: 10 }} negative>
          <P>
            Sorry, that Trello card is already linked to an existing project:
          </P>
          <Flex c>
            <a
              href={getFullUrl({
                view: 'projectContents',
                projectID: existingProject.projectID,
              })}
              target="_blank"
              rel="noreferrer noopener"
            >
              {existingProject.name}
            </a>
          </Flex>
          <P style={{ marginTop: 10 }}>
            Please either choose a different Trello card to link or remove the
            link from <b>{existingProject.name}</b>
          </P>
        </Message>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 400px;
`;

LinkToTrello.propTypes = {
  project: PropTypes.shape({
    groups: PropTypes.array,
    projectID: PropTypes.number,
  }),
  updateProject: PropTypes.func.isRequired,
  handleHideDialog: PropTypes.func.isRequired,
};

LinkToTrello.defaultProps = {};

export default LinkToTrello;
