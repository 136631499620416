import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

import React, { useState, useEffect } from 'react';

import fetchUtil from '../../util/fetchUtil';

import Table from '../Table';
import Button from '../Button';
import Box from '../Box';
import Dialog from '../Dialog';
import { colors } from '../styles/colors';
import UserInfoDialog from './users/UserInfoDialog';
import AddUserDialog from './users/AddUserDialog';

function AdminUsers() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [addUserDialogVisible, setAddUserDialogVisible] = useState(false);
  const [userDialogVisible, setUserDialogVisible] = useState(false);

  const updateUsers = () =>
    fetchUtil.post('/admin/getAllUsers').then((res) => setUsers(res.users));

  useEffect(() => {
    updateUsers();
  }, []);

  const handleSelectUser = (user) => {
    setSelectedUser(user);
    setUserDialogVisible(true);
  };

  const headers = [
    {
      id: 'name',
      value: 'Name',
    },
    {
      id: 'userID',
      value: 'User ID',
    },
    {
      id: 'trello',
      value: 'Trello ID',
    },
    {
      id: 'slack',
      value: 'Slack ID',
    },
    {
      id: 'active',
      value: 'Active',
    },
  ];

  const rows = users.map((user) => ({
    name: `${user.givenName} ${user.familyName}`,
    userID: user.userID,
    trello: user.data.trello ? user.data.trello.id : 'None',
    slack: user.data.slackId || '',
    active: user.data.deactivated ? (
      <FontAwesomeIcon icon={['fas', 'times']} style={{ color: colors.red }} />
    ) : (
      <FontAwesomeIcon
        icon={['fad', 'check']}
        style={{ color: colors.green }}
      />
    ),
  }));

  return (
    <Container>
      <Dialog
        handleHideDialog={() => setUserDialogVisible(false)}
        isVisible={userDialogVisible}
      >
        {!!selectedUser.userID && (
          <UserInfoDialog
            userID={selectedUser.userID}
            updateUsers={updateUsers}
          />
        )}
      </Dialog>
      <AddUserDialog
        isVisible={addUserDialogVisible}
        handleHideDialog={() => setAddUserDialogVisible(false)}
      />
      <Box
        style={{
          padding: 15,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box></Box>
        <Button
          box
          thin
          backgroundColor={colors.blue}
          leftIcon
          color="white"
          onClick={() => setAddUserDialogVisible(true)}
        >
          <FontAwesomeIcon icon={['fas', 'plus']} />
          Add user
        </Button>
      </Box>
      <Table headers={headers} rows={rows} onRowClick={handleSelectUser} />
    </Container>
  );
}

const Container = styled.div``;

AdminUsers.propTypes = {};

AdminUsers.defaultProps = {};

export default AdminUsers;
