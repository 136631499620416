import React, { useContext, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { setActiveProjectByNameOrID } from '../../state/projects/actions';

import { Store } from '../../state/store';

import Editor from './Editor';

const EditorWrapper: React.FC = () => {
  const {
    state: { activeProject, projects },
    dispatch,
  } = useContext(Store);

  const params = useParams<{
    projectID: string;
  }>();

  useEffect(() => {
    if (params.projectID) {
      // Set the project
      setActiveProjectByNameOrID(
        parseInt(params.projectID, 10),
        projects,
        activeProject,
        dispatch
      );
    }
    // eslint-disable-next-line
  }, [params.projectID]);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Editor />
    </div>
  );
};

EditorWrapper.propTypes = {};

export default EditorWrapper;
