import styled, { css } from 'styled-components/macro';
import Box from '../Box';

import { colors, fade, lighten } from '../styles/colors';

export const Container = styled.div``;
export const ListContainer = styled.div`
  padding: 10px;
`;
export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 15px 15px 0 15px;
`;
export const Header = styled.h4`
  margin: 0;

  color: ${colors['light-grey-20']};
`;
export const SettingsContainer = styled.div``;
export const SpellingError = styled.div`
  background: ${colors['light-red-40']};

  ${(props: { darkMode?: boolean }) =>
    props.darkMode &&
    css`
      background-color: ${fade(lighten(colors.red, 10), 30)};

      p {
        color: white;
      }
    `}

  border-radius: 3px;
  padding: 5px 5px 5px 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 5px;
`;

export const SpellingErrorText = styled.p`
  margin: 0;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  align-items: center;
  opacity: 0;

  transition: 0.1s all ease-in-out;

  button {
    margin-left: 5px;
  }

  ${SpellingError}:hover & {
    opacity: 1;
  }
`;
