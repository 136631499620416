import PropTypes from 'prop-types';

const { string, shape, bool, number } = PropTypes;

export default shape({
  type: string,
  id: string,
  name: string,
  duration: number,
  start: number,
  props: shape({}),
  currentProps: shape({}),
  keyframesOpen: bool,
  keyframes: shape({}),
  cache: shape({}),
  easing: string,
  animationPathD: string,
});
