import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Flex } from '../../Box';
import Lightbox from '../../Lightbox';
import { H3 } from '../../styles/typography';
import { colors } from '../../styles/colors';

const Heading = styled(H3)`
  color: ${colors['light-grey-20']};
`;

const ObjectLightbox = styled.object`
  border-radius: 3px;
  overflow: hidden;

  width: 100%;
  height: 50vh;

  background: white;
`;

function SceneSvgAsset(props) {
  const { file, showPreview, setShowPreview } = props;

  return (
    <Flex c style={{ height: '100%' }}>
      <Heading>Scene SVG</Heading>
      <Lightbox
        isVisible={showPreview}
        handleHideLightbox={() => setShowPreview(false)}
      >
        <ObjectLightbox data={file.data.url} type="image/svg+xml" />
      </Lightbox>
    </Flex>
  );
}

SceneSvgAsset.propTypes = {
  file: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  showPreview: PropTypes.bool,
  setShowPreview: PropTypes.func.isRequired,
};

SceneSvgAsset.defaultProps = {
  file: {},
  showPreview: false,
};

export default SceneSvgAsset;
