import React, { useState } from 'react';
import { Box } from 'rebass/styled-components';
import FileProps from '../editor/types/FileProps';
import { ProjectProps } from '../editor/types/ProjectProps';
import { colors } from '../styles/colors';
import { P, H1, H3 } from '../styles/typography';
import CommentList from './CommentList';

type Props = {
  file: FileProps;
  project: ProjectProps;
};

const File: React.FC<Props> = (props: Props) => {
  const { file, project } = props;

  const [showResolved, setShowResolved] = useState(false);

  const comments = project.comments.filter((c) => c.fileID === file.fileID);

  const unresolvedComments = comments.filter((c) => c.data.status === 0);
  const resolvedComments = comments.filter((c) => c.data.status === 1);

  return (
    <Box style={{ marginBottom: 30 }}>
      <H1>{file.name}</H1>
      <CommentList comments={unresolvedComments} />
      {showResolved && (
        <Box style={{ marginTop: 20 }}>
          <H3>Resolved</H3>
          <CommentList comments={resolvedComments} />
        </Box>
      )}
      {!showResolved && !!resolvedComments.length && (
        <Box style={{ marginTop: 20 }} onClick={() => setShowResolved(true)}>
          <P style={{ color: colors['light-grey-30'] }}>
            {resolvedComments.length} resolved comments
          </P>
        </Box>
      )}
    </Box>
  );
};

export default File;
