const tagReplacements = [
  {
    tag: '<br>',
    text: '\n',
  },
];

const replaceTags = (text) => {
  let newText = text;

  tagReplacements.forEach((tag) => {
    newText = newText.replaceAll(tag.tag, tag.text);
  });

  return newText;
};

export default replaceTags;
