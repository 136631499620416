/* eslint-disable complexity */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useContext } from 'react';

import { faEye, faEyeSlash } from '@fortawesome/pro-duotone-svg-icons';
import Tippy from '@tippyjs/react';
import { post } from '../../util/fetchUtil';

import { Store } from '../../state/store';
import { fetchNotifications } from '../../state/notifications/actions';
import { fetchActiveProject } from '../../state/projects/actions';
import { colors } from '../styles/colors';

import {
  setAnnotationIsActive,
  setAnnotationMode,
  setCurrentAnnotationSvg,
} from '../../state/annotations/actions';

import { getArrayString } from '../../utilities/array';

import playback from '../../app/playback';
import players from '../../app/videoPlayer';
import { send } from '../../app/notifications/notifications';

import {
  Container,
  TextAreaContainer,
  StyledButton,
  ButtonContainer,
} from './CommentEditorStyles';

import CommentTextArea from './CommentTextArea';
import timelineRefs from '../project/timelineRefs';
import { CommentData } from '../editor/types/CommentProps';
import Button from '../RebassButton';
import Box from '../Box';

type UserProps = {
  fullName: string;
  userID: number;
  roles?: string[];
};

type Player = {
  currentTime: () => number;
};

type Props = {
  isAnonymous: boolean;
  setIsAnonymous: (isAnonymous: boolean) => void;
};
/**
 * Component that writes comments and toggle annotation modes
 *
 * @class CommentEditor
 * @extends {Component}
 */
function CommentEditor(props: Props) {
  const { isAnonymous, setIsAnonymous } = props;
  const {
    dispatch,
    state: {
      annotationIsActive,
      annotationMode,
      currentAnnotationSvg,
      team,
      activeFile,
      sceneMode,
      activeProject,
      user,
    },
  } = useContext(Store);

  const [commentInput, setCommentInput] = useState('');
  const [mentionedUsers, setMentionedUsers] = useState<UserProps[]>([]);

  /**
   * Handle
   *
   * @param {*} value
   * @param {*} mentionedUser
   * @memberof CommentEditor
   */
  const handleSelectMention = (value: string, mentionedUser: UserProps) => {
    // Add new mentioned user if it doesn't already exist in list
    const newMentionedUsers = mentionedUsers;
    if (!mentionedUsers.find((user) => user.userID === mentionedUser.userID)) {
      newMentionedUsers.push(mentionedUser);
    }

    setCommentInput(value);
    setMentionedUsers(newMentionedUsers);
  };

  /**
   * Clears all current annotations
   *
   * @memberof CommentEditor
   */
  const clearAnnotations = () => {
    setAnnotationMode('', dispatch);
    setCurrentAnnotationSvg('', dispatch);
    setAnnotationIsActive(false, dispatch);
  };

  /**
   * Save the comment to the DB
   *
   * @memberof CommentEditor
   */
  const handleSaveComment = async () => {
    const { projectID } = activeProject;
    const { fileID } = activeFile;
    const { userID } = user;

    if (commentInput === '' && currentAnnotationSvg === '') return;

    const data: CommentData = {
      svg: currentAnnotationSvg || '',
      text: commentInput,
      status: 0,
      anonymous: isAnonymous,
    };

    if (sceneMode === 'video' && players.reviewPlayer) {
      data.timestamp =
        (players.reviewPlayer as unknown as Player).currentTime() * 1000;
    } else {
      data.timestamp =
        timelineRefs.currentTime === 0
          ? playback.duration
          : timelineRefs.currentTime;
    }

    const res = await post('/comments/saveComment', {
      projectID,
      fileID: activeFile.fileID,
      data,
      mentionedUsers,
    });

    // Update projects
    await fetchActiveProject(projectID, dispatch);

    // Reset after save
    setCommentInput('');
    clearAnnotations();

    // Send notifcications asynchronously after saving comment
    const promises = [];
    for (let i = 0; i < mentionedUsers.length; i += 1) {
      promises.push(
        send({
          recipientUserID: mentionedUsers[i].userID,
          action: 'commentMention',
          actionData: {
            anonymous: isAnonymous,
            commentingUserID: userID,
            projectID,
            fileID,
            parentCommentID: res.comment.commentID,
            replyCommentID: res.comment.commentID,
            replyCommentText: res.comment.data.text,
          },
        })
      );
    }

    await Promise.all(promises);

    fetchNotifications(dispatch);
    setMentionedUsers([]);
  };

  /**
   * Toggle the annotation mode - options: 'pencil', 'box'
   *
   * @param {String} mode
   * @memberof CommentEditor
   */
  const handleToggleMode = (mode: 'pencil' | 'box') => {
    // If we're currently annotating and we clicked the same mode
    if (annotationIsActive && mode === annotationMode) {
      clearAnnotations();
    } else if (annotationIsActive) {
      // If we're currently annotating and we clicked a different mode
      setAnnotationMode(mode, dispatch); // Switch the current mode
    } else {
      // Otherwise create a new annotation
      setAnnotationMode(mode, dispatch); // Switch the current mode
      setAnnotationIsActive(true, dispatch);
    }
  };

  const options = team.map((u: UserProps) => ({
    ...u,
    id: u.userID,
    name: u.fullName,
    text: `@${u.fullName}`,
    subheader: u.roles ? getArrayString(u.roles, ' ,') : null,
  }));

  return (
    <Container>
      <TextAreaContainer className="text-input">
        <CommentTextArea
          value={commentInput}
          onSelect={handleSelectMention}
          onChange={setCommentInput}
          onEnterPress={handleSaveComment}
          placeholder="New comment..."
          options={options}
          darkMode
        />
      </TextAreaContainer>
      <ButtonContainer>
        {!!currentAnnotationSvg && (
          <StyledButton thin box leftIcon onClick={clearAnnotations}>
            <FontAwesomeIcon icon={['fas', 'times']} />
            <span>Clear</span>
          </StyledButton>
        )}
        <Tippy
          content={`Anonymous commenting is ${isAnonymous ? 'ON' : 'OFF'}`}
        >
          <Box>
            <Button
              variant={isAnonymous ? undefined : 'transparent'}
              color="white"
              onClick={() => setIsAnonymous(!isAnonymous)}
              bg={isAnonymous ? colors.blue : undefined}
            >
              <FontAwesomeIcon icon={isAnonymous ? faEyeSlash : faEye} />
            </Button>
          </Box>
        </Tippy>
        <Button
          variant={
            annotationIsActive && annotationMode === 'pencil'
              ? undefined
              : 'transparent'
          }
          color="white"
          onClick={() => handleToggleMode('pencil')}
          bg={
            annotationIsActive && annotationMode === 'pencil'
              ? colors.blue
              : undefined
          }
        >
          <FontAwesomeIcon icon={['fad', 'highlighter']} />
        </Button>
        <Button
          variant={
            annotationIsActive && annotationMode === 'box'
              ? undefined
              : 'transparent'
          }
          color="white"
          onClick={() => handleToggleMode('box')}
          bg={
            annotationIsActive && annotationMode === 'box'
              ? colors.blue
              : undefined
          }
        >
          <FontAwesomeIcon icon={['fad', 'draw-square']} />
        </Button>
        <StyledButton
          enable={commentInput !== '' || !!currentAnnotationSvg}
          box
          thin
          color={(commentInput !== '' || !!currentAnnotationSvg) && 'white'}
          backgroundColor={
            (commentInput !== '' || !!currentAnnotationSvg) && colors.green
          }
          onClick={handleSaveComment}
        >
          Save
        </StyledButton>
      </ButtonContainer>
    </Container>
  );
}

export default CommentEditor;
