import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import AddKeyframeIcon from '../../icons/AddKeyframeIcon';
import Message from '../../Message';

const Screenshot = styled.img`
  max-width: 75%;
  max-height: 500px;
`;

export default {
  header: 'Scene Builder',
  content: (
    <>
      <p>The Scene Builder can be used to construct or edit an SVG scene.</p>
      <p>
        To get to the Scene Builder, select a project and then click the pencil
        icon. Then click &quot;New scene&quot;.
      </p>
    </>
  ),
  img:
    'https://d16qt3wv6xm098.cloudfront.net/L1ch6SF8QK6Vy9pao_FXDEbSSEmvSUHb/_.png',
  subSections: [
    {
      header: 'Adding SVGs',
      content: (
        <>
          <p>
            SVGs can built in Affinity Designer and imported into the scene
            builder much in the same way they can normally. To import an SVG,
            select &quot;Import&quot; or drag and drop an SVG into the
            dashboard. Alternatively, you can drag and drop SVG's directly into
            the scene once it's been opened.
          </p>
          <p>
            If you upload an SVG with the same name as one of your scenes,
            you'll be prompted to overwrite the existing scene with the same
            name. If you choose to update it, the comments remain the same but
            the scene will be updated.
          </p>
        </>
      ),
      subSections: [
        {
          header: 'Affinity Designer (legacy)',
          content: (
            <p>
              You can import SVGs that have the legacy SVG formatting, i.e. with
              an &quot;Animation&quot; group and a &quot;Scene&quot; group.
            </p>
          ),
          img:
            'https://d16qt3wv6xm098.cloudfront.net/4k4quJnQQJS89evWeThFWxxbRRa6BOfi/_.png',
        },
        {
          header: 'Affinity Designer (new)',
          content: (
            <>
              <p>
                With the scene builder, you can omit the &quot;Animation&quot;
                and &quot;Scene&quot; grouping, and instead just lay out the
                scene as if it's just the &quot;Animation&quot; group.
              </p>
              <Message icon="info-circle">
                Note that all curves' stroke pressure should be set to 0
                (default), the scene builder will add a default stroke pressure.
              </Message>
              <p></p>
            </>
          ),
          img:
            'https://d16qt3wv6xm098.cloudfront.net/LxvmMDxPQciuF72bhmBrkNpoTSewARmy/_.png',
        },
      ],
    },
    {
      header: 'Timeline',
      content: (
        <>
          <p>
            Each layer in the left layers panel has a corresponding track in the
            timeline. Each track can have multiple keyframe tracks as well,
            which control animations. If you're importing from Affinity
            Designer, these are automatically added on import, but can be
            edited.
          </p>
        </>
      ),
      subSections: [
        {
          header: 'Tracks',
          content: (
            <p>
              Each track corresponds to a layer in the scene. Each track may
              have several keyframe tracks as well. Dragging the start and end
              of a track on the timeline will control when it starts and ends.
            </p>
          ),
          img:
            'https://d16qt3wv6xm098.cloudfront.net/TqB2g2k_SQitxxRDhWhh4f4cTnuiqrmM/_.png',
        },
        {
          header: 'Keyframes',
          content: (
            <>
              <p>
                Keyframes control a specific animation for the layer. E.g. a{' '}
                <code>position</code> keyframe type will control the elements x
                and y position, and can be used for moving, and{' '}
                <code>opacity</code> controls its opacity, which can be used for
                fades.
              </p>
              <p>
                To add a keyframe group, first select the layer to add it to,
                then click the <AddKeyframeIcon sx={{ width: 30 }} />
                button, then select the type of animation to apply.
              </p>
              <Screenshot src="https://d16qt3wv6xm098.cloudfront.net/qhk6LWiqQE_Qywn6kt837q4iRqG1b-Er/_.png" />
              <p>
                To add individual keyframes, click the{' '}
                <FontAwesomeIcon icon={['fad', 'map-marker']} /> button, and the
                properties corresponding to that keyframe type will be applied
                at that point.
              </p>
              <Screenshot src="https://d16qt3wv6xm098.cloudfront.net/MDIeXfp9Tr_2Xw7hq0SZimihT7SXOX55/_.png" />
            </>
          ),
        },
      ],
    },
    {
      header: 'Review',
      content: (
        <>
          <p>
            Scenes can be reviewed by clicking &quot;Review&quot; in the
            dashboard, this will open a review panel and allow you to review all
            the scenes at once. Comments and annotations can then be added.
          </p>
          <Screenshot src="https://d16qt3wv6xm098.cloudfront.net/hCja4UXxQXuRWQ6wer0uO-OqSBaoIx-t/_.png" />
        </>
      ),
    },
    {
      header: 'Exporting',
      content: (
        <>
          <p>
            Scenes can be exported in the same way that regular scenes can be
            exported, either by clicking &quot;Export&quot;, which will produce
            an MP4 after ~5-7 minutes, or by clicking Screen capture.
          </p>
        </>
      ),
      subSections: [
        {
          header: 'MP4 Export',
          content: (
            <>
              <p>
                To export scenes as MP4s, click &quot;Select all&quot;, or
                select individual scenes by clicking the checkmark(s) in the
                upper-left corner.
              </p>
              <p>
                Once you have your scenes selected, click &quot;Export&quot;.
                The selected scenes for export will now display a spinning
                circle below their title, indicating they're currently
                exporting. The export process takes 5-7 minutes (regardless of
                how many are exporting).
              </p>
            </>
          ),
          img:
            'https://d16qt3wv6xm098.cloudfront.net/kHResET7TXm6NCydxF5YnGbtTVOVR_hv/_.png',
        },
        {
          header: 'Screen capture',
          content: (
            <>
              <p>
                To screen capture the scenes, click &quot;Select all&quot;, or
                select individual scenes by clicking the checkmark(s) in the
                upper-left corner.
              </p>
              <p>
                Once you have your scenes selected, click &quot;Record
                screen&quot;, and another window will open with a controller,
                and you can play through your scenes using that window.
              </p>
            </>
          ),
          img:
            'https://d16qt3wv6xm098.cloudfront.net/qnsVwrTaT_ipKE49giMCmpbPRzywg_i6/_.png',
        },
      ],
    },
  ],
};
