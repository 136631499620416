import $ from 'jquery';

class SceneObject {
  constructor(args) {
    // Attach data to the object
    const keys = Object.keys(args);
    for (let i = 0; i < keys.length; i += 1) {
      this[keys[i]] = args[keys[i]];
    }

    // Create the dom element
    this.dom = $(
      `<div id="${this.id}" class="svg-container" style="z-index: ${this.zIndex}">`
    ).appendTo(this.container);
  }

  play() {
    this.anim.reset().play();
  }

  remove() {
    this.dom.remove();
    this.parent.objects.remove(this);
  }

  reset() {
    this.anim.reset();
  }
}

export default SceneObject;
