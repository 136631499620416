import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Point from './Point';
import ControlPoint from './ControlPoint';

import { mirrorCp } from '../../../app/editor/path';
import { colors } from '../../styles/colors';
import ParsedPathProps from '../props/ParsedPathProps';
import { RefProps } from '../../../props/general';

const StyledPath = styled.path`
  stroke: ${colors.blue};
  stroke-width: 1px;
  fill: none;
`;

/**
 * Add new points to a path with a bezier pen tool
 */
function PenPathEditor(props) {
  const { d, pathData, svgRef, onMouseMove, onMouseDown, isNew } = props;

  const [isDragging, setIsDragging] = useState(isNew);

  const handleMouseMove = (e) => {
    if (isDragging) {
      onMouseMove(e);
    }
  };

  const handleMouseDown = (e) => {
    onMouseDown(e);
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const svg = svgRef.current;
    if (svg) {
      svg.addEventListener('mousedown', handleMouseDown);
      svg.addEventListener('mouseup', handleMouseUp);
      svg.addEventListener('mousemove', handleMouseMove);
    }
    return () => {
      if (svg) {
        svg.removeEventListener('mousedown', handleMouseDown);
        svg.removeEventListener('mouseup', handleMouseUp);
        svg.removeEventListener('mousemove', handleMouseMove);
      }
    };
    // eslint-disable-next-line
  }, [svgRef.current, isDragging]);

  const controlPoints = pathData.map((command, i) => {
    // Draw control points for last two points
    if (command.code === 'C' && i > pathData.length - 2) {
      const mirroredCp2 = mirrorCp(
        command.cp2.x,
        command.cp2.y,
        command.end.x,
        command.end.y
      );
      return (
        <g key={i}>
          <ControlPoint
            cpx={command.cp1.x}
            cpy={command.cp1.y}
            x={pathData[i - 1].end.x}
            y={pathData[i - 1].end.y}
          />
          <ControlPoint
            cpx={command.cp2.x}
            cpy={command.cp2.y}
            x={command.end.x}
            y={command.end.y}
          />
          {i === pathData.length - 1 && (
            <ControlPoint
              cpx={mirroredCp2.x}
              cpy={mirroredCp2.y}
              x={command.end.x}
              y={command.end.y}
            />
          )}
        </g>
      );
    }

    return null;
  });

  const points = pathData.map((command, i) => {
    return (
      <Point
        key={i}
        isActive={pathData.length - 1 === i}
        x={command.end.x}
        y={command.end.y}
      />
    );
  });

  return (
    <g>
      <g>
        <StyledPath d={d} />
      </g>
      <g>{points}</g>
      <g>{controlPoints}</g>
    </g>
  );
}

const { bool, func, string } = PropTypes;

PenPathEditor.propTypes = {
  svgRef: RefProps.isRequired,
  d: string.isRequired,
  pathData: ParsedPathProps.isRequired,
  onMouseMove: func.isRequired,
  onMouseDown: func.isRequired,
  isNew: bool,
};

PenPathEditor.defaultProps = {
  isNew: false,
};

export default PenPathEditor;
