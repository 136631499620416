import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fetchUtil from '../../util/fetchUtil';

import Table from '../Table';

import Dialog from '../Dialog';
import { TextInput } from '../Input';
import ProjectInfoDialog from './projects/ProjectInfoDialog';

import Pagination from '../home/Pagination';
import { colors, lighten, darken } from '../styles/colors';
import useDebounce from '../../app/hooks/useDebounce';

const sortBy = [
  { id: 'alphabetical', name: 'Alphabetical' },
  { id: 'newest', name: 'Recent' },
];

function AdminFiles() {
  const [files, setFiles] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [activeSortBy] = useState(sortBy[0]);
  const [page, setPage] = useState(0);
  const [count] = useState(20);
  const [totalProjects, setTotalProjects] = useState(0);
  const [statusFilter] = useState(null);
  const [projectDialogVisible, setProjectDialogVisible] = useState(false);
  const [selectedProject] = useState({});

  const filters = [{ id: 'search', filter: () => searchFiles(searchValue) }];

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const [activeFilter, setActiveFilter] = useState(filters[0]);

  useEffect(() => {
    getFiles();
    // eslint-disable-next-line
  }, [page, statusFilter]);

  const getFiles = () =>
    filters.find((filter) => filter.id === activeFilter.id).filter();

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const searchFiles = (text) =>
    fetchUtil
      .post('/files/searchFiles', {
        text,
        start: page,
        count,
        sortBy: activeSortBy.id,
        status: statusFilter,
      })
      .then((res) => {
        setFiles(res.files);
        setTotalProjects(res.total);
        setActiveFilter(filters.find((filter) => filter.id === 'search'));
      });

  useEffect(() => {
    searchFiles(debouncedSearchValue);
    // eslint-disable-next-line
  }, [debouncedSearchValue]);

  const handlePageChange = (newPage) => {
    if (newPage > -1) setPage(newPage);
  };

  const handleDeleteFile = () => {};

  const handleReviveFile = () => {};

  const headers = [
    {
      id: 'id',
      value: 'ID',
      width: 75,
    },
    {
      id: 'name',
      value: 'Name',
    },
    {
      id: 'type',
      value: 'Type',
    },
    {
      id: 'status',
      value: 'Status',
    },
    {
      id: 'url',
      value: 'URL',
    },
  ];

  const rows = files.map((file) => {
    let statusComponent = <DeletedTag>Deleted</DeletedTag>;
    if (file.status === 1) statusComponent = <ActiveTag>Active</ActiveTag>;
    else if (file.status === 0)
      statusComponent = <ArchivedTag>Archived</ArchivedTag>;

    return {
      id: file.fileID.toString(),
      file: { data: file },
      name: file.name,
      type: file.type,
      status: statusComponent,
      url: file.data.url,
      actions: (
        <div>
          {file.status === 0 && (
            <Icon
              icon={['fad', 'feather']}
              onClick={() => handleReviveFile(file.fileID)}
            />
          )}
          {file.status > 0 && (
            <Icon
              icon={['fad', 'trash-alt']}
              onClick={() => handleDeleteFile(file.fileID)}
            />
          )}
        </div>
      ),
    };
  });

  const pages = Math.ceil(totalProjects / count);

  return (
    <Container>
      <HeaderContainer>
        <FlexContainer>
          <TextInput
            onChange={handleSearchChange}
            value={searchValue}
            placeholder="Filter files..."
          />
          {/* <OptionSelector
              zindex={10}
              options={sortBy}
              selectedOption={activeSortBy}
              onSelectOption={option => handleSelectSortBy(option)}
            /> */}
        </FlexContainer>
        {/* <StatusContainer>
            {checkboxes}
          </StatusContainer> */}
      </HeaderContainer>
      <Table
        headers={headers}
        rows={rows}
        // onRowClick={handleToggleDialog}
      />
      <FooterContainer>
        {pages > 1 && (
          <Pagination
            pages={pages}
            page={page}
            handlePageChange={handlePageChange}
          />
        )}
      </FooterContainer>
      <Dialog
        handleHideDialog={() => setProjectDialogVisible(false)}
        isVisible={projectDialogVisible}
      >
        <ProjectInfoDialog project={selectedProject} />
      </Dialog>
    </Container>
  );
}

const Container = styled.div``;

const FooterContainer = styled.div`
  padding: 15px;
`;

const HeaderContainer = styled.div`
  padding: 15px;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 10px;
  &.button.icon {
    font-size: 14px;
  }
`;

const ActiveTag = styled.div`
  display: inline-flex;
  padding: 3px 6px;
  border-radius: 3px;
  background: ${lighten(colors.green, 40)};
  color: ${darken(colors.green, 10)};
`;

const DeletedTag = styled(ActiveTag)`
  background: ${lighten(colors.red, 40)};
  color: ${darken(colors.red, 10)};
`;

const ArchivedTag = styled(ActiveTag)`
  background: ${lighten(colors.grey, 50)};
  color: ${lighten(colors.grey, 20)};
`;

const FlexContainer = styled.div`
  display: flex;
  > * {
    margin-right: 10px;
  }
`;

AdminFiles.propTypes = {
  propName: PropTypes.string,
};

AdminFiles.defaultProps = {
  propName: '',
};

export default AdminFiles;
