import React from 'react';
import styled, { css } from 'styled-components/macro';
import {
  addAnimation,
  AnimationProps,
  animations,
} from '../../../app/editor/animations';

import Box, { Flex } from '../../Box';
import Message from '../../Message';
import { fade, lighten, colors } from '../../styles/colors';
import { P } from '../../styles/typography';
import { ElementsArrayProps } from '../types/ElementProps';

const Name = styled(P)`
  text-align: center;
`;

const AnimationContainer = styled(Box)`
  padding: 20px;
  margin: 10px;
  width: 40%;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${lighten(colors.night, 5)};
  p {
    color: ${fade('white', 75)};
  }

  ${(props: { isActive: boolean }) =>
    props.isActive &&
    css`
      cursor: pointer;

      background-color: ${lighten(colors.night, 10)};

      p {
        color: ${fade('white', 30)};
      }

      :hover,
      :focus {
        background-color: ${lighten(colors.night, 8)};
      }

      :active {
        background-color: ${lighten(colors.night, 5)};
      }
    `}
`;

type Props = {
  selectedElements: ElementsArrayProps;
  currentTime: number;
  handleUpdateElement: (elementIds: string[]) => void;
};

/**
 * List of animations that can be applied to elements in the scene
 */
const AnimationsList: React.FC<Props> = (props: Props) => {
  const { selectedElements, currentTime, handleUpdateElement } = props;

  const handleClick = (animation: AnimationProps) => {
    addAnimation(animation, selectedElements, currentTime);

    handleUpdateElement(selectedElements.map((el) => el.id));
  };

  return (
    <Flex style={{ flexWrap: 'wrap' }}>
      {!selectedElements.length && (
        <Box style={{ padding: 10, width: '100%' }}>
          <Message
            style={{ width: '100%' }}
            color={fade('white', 60)}
            backgroundColor={lighten(colors.night, 10)}
          >
            No elements selected
          </Message>
        </Box>
      )}
      {animations.map((animation: AnimationProps) => {
        const isActive =
          // Must have elements selected
          selectedElements.length > 0 &&
          // Check if animation is restricted to certain types of elements
          (!animation.types ||
            selectedElements.every((el) =>
              animation.types?.some((t) => t === el.type)
            ));

        return (
          <AnimationContainer
            key={animation.id}
            onClick={isActive ? () => handleClick(animation) : () => {}}
            isActive={isActive}
          >
            <Name>{animation.name}</Name>
          </AnimationContainer>
        );
      })}
    </Flex>
  );
};

export default AnimationsList;
