import React from 'react';
import moment from 'moment';
import Box, { Flex } from '../Box';
import Text from '../Text';

const Footer = () => {
  return (
    <Flex p={[4, 5]} bg="light-1" justifyContent="center">
      <Box maxWidth="500px">
        <Text mb={3} textAlign="center" color="light-6">
          © {moment().format('YYYY')} Osmosis. All rights reserved.
        </Text>
        <Text textAlign="center" color="light-6">
          All content on and from Osmosis is intended for educational and
          informational purposes only.
        </Text>
      </Box>
    </Flex>
  );
};

export default Footer;
