import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useRef, useEffect } from 'react';

import { motion, AnimatePresence } from 'framer-motion';

import moment from 'moment';

import { post } from '../../../util/fetchUtil';

import { goToUrl } from '../../../shared/links';
import { getCardData } from '../../../app/utilities/trello';

import InputConfirm from '../../InputConfirm';
import Button from '../../Button';
import Message from '../../Message';
import TrelloCardWrapper from '../../TrelloCardWrapper';

import { H4, H3 } from '../../styles/typography';
import { colors } from '../../styles/colors';
import { ProjectProps } from '../../../props/projects';

const Container = styled.div``;
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Section = styled.div`
  margin-bottom: 15px;
`;

const SectionValue = styled(H3)`
  max-width: 300px;
`;

const NameValue = styled(SectionValue)`
  cursor: pointer;
`;

const SectionHeader = styled(H4)`
  color: ${colors['light-grey-30']};
  margin-bottom: 10px;
`;

const TrelloMessage = styled(Message)`
  max-width: 300px;
  margin-top: 15px;
`;

const EditButton = styled(Button)`
  width: 1.5em;
  height: 1.5em;

  font-size: 14px;

  margin-left: 10px;
`;

function ProjectInfoDialog(props) {
  const { project, getAllProjects } = props;

  const [projectStore, setProjectStore] = useState(project);
  const [, setCard] = useState({});

  const [newName, setNewName] = useState(project.name);
  const [isEditing, setIsEditing] = useState(false);
  const originalName = useRef(project.name);

  useEffect(() => {
    if (project.data.trello) {
      getCardData(project.data.trello.id).then((res) => {
        setCard(res);
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    const { value } = e.target;
    setNewName(value);
  };

  const handleSave = () => {
    post('/project/updateName', {
      projectID: project.projectID,
      name: newName,
    }).then(() => {
      setIsEditing(false);
      // Update projects array
      getAllProjects();

      // Update local project object
      setProjectStore({ ...project, name: newName });
    });
  };

  return (
    <Container>
      <Section>
        <SectionHeader>Name</SectionHeader>
        {!isEditing && (
          <FlexContainer>
            <NameValue
              onClick={() =>
                goToUrl({
                  projectID: project.projectID,
                  view: 'projectContents',
                })
              }
            >
              {projectStore.name}
            </NameValue>
            <EditButton
              className="icon transparent"
              onClick={() => setIsEditing(!isEditing)}
            >
              <FontAwesomeIcon icon={['fad', 'edit']} />
            </EditButton>
          </FlexContainer>
        )}
        {isEditing && (
          <InputConfirm
            handleInputChange={handleChange}
            handleSaveClick={() => handleSave()}
            handleCancelClick={() => setIsEditing(!isEditing)}
            enableSave={newName !== originalName.current}
            cancelButtonVisible={!!newName}
            placeholder={project.name}
            inputValue={newName}
          />
        )}
        <AnimatePresence>
          {isEditing && project.data.trello && (
            <motion.div
              key="trello"
              initial={{ opacity: 0, height: 0, scale: 0 }}
              animate={{
                opacity: 1,
                height: 'auto',
                scale: 1,
                transition: { easing: 'easeIn' },
              }}
              exit={{
                opacity: 0,
                height: 0,
                scale: 0,
                transition: { easing: 'easeOut' },
              }}
            >
              <TrelloMessage color={colors.teal} icon="exclamation-circle">
                This project is linked to a Trello card. Updating it&apos;s name
                will also update the Trello card&apos;s name.
              </TrelloMessage>
            </motion.div>
          )}
        </AnimatePresence>
      </Section>
      <Section>
        <SectionHeader>Created</SectionHeader>
        <SectionValue>
          {moment(project.createdOn).format('MM-DD-YYYY')}
        </SectionValue>
      </Section>
      {project.data.trello && (
        <Section>
          <SectionHeader>Trello</SectionHeader>
          <SectionValue>
            {/* <TrelloCard trello={project.data.trello} /> */}
          </SectionValue>
          <TrelloCardWrapper cardId={project.data.trello.id} />
        </Section>
      )}
    </Container>
  );
}

ProjectInfoDialog.propTypes = {
  getAllProjects: PropTypes.func.isRequired,
  project: ProjectProps,
};

ProjectInfoDialog.defaultPropss = {
  project: {},
};

export default ProjectInfoDialog;
