import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';

import { motion } from 'framer-motion';

import sortFiles from '../../utilities/sortFiles';
import Box, { Flex } from '../Box';
import Button from '../Button';

import Circle from '../Circle';

import { lighten, fade, darken, colors } from '../styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SceneProps } from '../editor/types/SceneProps';
import { CommentProps } from '../editor/types/CommentProps';

const Icon = styled(FontAwesomeIcon)`
  transition: 0.1s all ease-in-out;
`;

type RowProps = {
  active?: boolean;
};

const SceneRow = styled(Box)`
  padding: 10px 15px;
  background-color: ${lighten(colors.night, 10)};
  color: ${fade('white', 50)};

  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;

  :hover,
  :focus {
    background-color: ${lighten(colors.night, 5)};
  }

  :active {
    background-color: ${lighten(colors.night, 2)};
  }

  ${(props: RowProps) =>
    props.active &&
    css`
      background-color: ${lighten(colors.blue, 30)};
      color: ${darken(colors.blue, 10)};
      :hover,
      :focus,
      :active {
        background-color: ${lighten(colors.blue, 30)};
        color: ${darken(colors.blue, 10)};
      }
    `}
`;

const sortByNumber = sortFiles.find((s) => s.id === 'number');

type Props = {
  scenes: SceneProps[];
  comments: {
    comments: CommentProps[];
    sceneID: number;
  }[];
  activeScene: SceneProps;
  handleSelectScene: (scene: SceneProps) => void;
};

function ScenePicker(props: Props) {
  const { scenes, comments, activeScene, handleSelectScene } = props;
  const [isMinimized, setIsMinimized] = useState(false);

  return (
    <motion.div
      animate={{ width: isMinimized ? 40 : 200 }}
      style={{ overflow: 'auto', height: '100%' }}
    >
      <Box>
        <Button
          onClick={() => setIsMinimized(!isMinimized)}
          icon
          color={fade('white', 40)}
        >
          <Icon
            style={{
              transform: isMinimized ? `rotate(180deg)` : `rotate(0deg)`,
            }}
            icon={['fas', 'chevron-left']}
          />
        </Button>
      </Box>
      {isMinimized && (
        <Button icon onClick={() => setIsMinimized(false)}>
          <Circle
            data-tippy-content={`${scenes.length} scenes`}
            style={{
              backgroundColor: fade('white', 80),
              color: fade('white', 30),
            }}
            size={30}
          >
            {scenes.length}
          </Circle>
        </Button>
      )}
      {!isMinimized &&
        scenes
          .map((s) => ({ ...s, name: s.data.name }))
          .sort(sortByNumber?.sort)
          .map((scene) => {
            const isSelected = activeScene.sceneID === scene.sceneID;
            const sceneComments = comments.find(
              (c) => c.sceneID === scene.sceneID
            )?.comments;

            const activeSceneComments = sceneComments?.filter(
              (c) => c.data.status !== 2 && !c.data.parentCommentID
            );

            return (
              <SceneRow
                onClick={() => handleSelectScene(scene)}
                active={isSelected}
                key={scene.sceneID}
              >
                <Box display="flex">
                  {scene.data.name}
                  {!!activeSceneComments?.length && (
                    <Circle
                      size={20}
                      style={{
                        backgroundColor: fade(colors.red, 50),
                        color: 'white',
                        marginLeft: 10,
                        fontSize: 12,
                      }}
                    >
                      {activeSceneComments.length}
                    </Circle>
                  )}
                  {sceneComments?.length && !activeSceneComments?.length && (
                    <Flex
                      color={colors.green}
                      display="flex"
                      alignItems="center"
                      ml="10px"
                    >
                      <FontAwesomeIcon
                        style={{ fontSize: 20 }}
                        icon={['fad', 'comment-check']}
                      />
                    </Flex>
                  )}
                  {/* TODO: ADD IN GREEN COMMENT CHECK BOX */}
                </Box>
                {isSelected && (
                  <Box>
                    <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                  </Box>
                )}
              </SceneRow>
            );
          })}
    </motion.div>
  );
}

export default ScenePicker;
