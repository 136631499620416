import PropTypes from 'prop-types';

const { string, shape, number } = PropTypes;

export default shape({
  sceneExportID: number,
  sceneID: number,
  status: number,
  data: shape({
    url: string,
    completedOn: string,
  }),
});
