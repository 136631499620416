import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Flex } from '../../Box';
import Button from '../../Button';
import { fade } from '../../styles/colors';
import { P, H2 } from '../../styles/typography';
// import { SceneProps } from '../types/SceneProps';

interface ExporterSceneProps {
  id: number;
  name: string;
}

type Props = {
  scenes: ExporterSceneProps[];
  activeScene: ExporterSceneProps;
  isPlaying: boolean;
  handleSetActiveScene: (newSceneID: number) => void;
};

export default function TimelinePortalSceneLoader(props: Props) {
  const { scenes, activeScene, handleSetActiveScene } = props;

  const activeSceneIndex = scenes.findIndex((s) => s.id === activeScene.id);

  const previousScene = scenes[activeSceneIndex - 1];
  const nextScene = scenes[activeSceneIndex + 1];

  return (
    <Flex justifyContent="space-between" width="100%">
      <Flex justifyContent="flex-start" style={{ flex: 1 }}>
        <Button
          leftIcon
          transparent
          box
          color={fade('white', 30)}
          enable={!!previousScene}
          onClick={() => handleSetActiveScene(previousScene.id)}
        >
          <FontAwesomeIcon icon={['fas', 'arrow-left']} />
          Previous
        </Button>
      </Flex>
      <Flex flexDirection="column" alignItems="center" style={{ flex: 1 }}>
        <P color={fade('white', 40)}>
          Current scene ({activeSceneIndex + 1} / {scenes.length})
        </P>
        <H2 color="white">{activeScene.name}</H2>
      </Flex>
      <Flex justifyContent="flex-end" style={{ flex: 1 }}>
        <Button
          rightIcon
          transparent
          box
          color={fade('white', 30)}
          onClick={() => handleSetActiveScene(nextScene.id)}
          enable={!!nextScene}
        >
          Next
          <FontAwesomeIcon icon={['fas', 'arrow-right']} />
        </Button>
      </Flex>
    </Flex>
  );
}
