import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { BoxProps, TextProps } from 'rebass';
import { lighten } from 'polished';
import Box, { Flex } from '../../Box';
import FileProps from '../../editor/types/FileProps';
import { H4, P } from '../../styles/typography';
import {
  FileSize,
  HeaderBar,
  FileName,
  InfoContainer,
} from '../ControlsStyles';
import FileDimensions from './FileDimensions';
import colors from '../../../styles/themeColors';
import InputConfirm from '../../InputConfirm';
import Button from '../../RebassButton';
import Icon from '../../Icon';

interface Props {
  files: FileProps[];
  canEdit?: boolean;
  onSaveName?: (name: string, file: FileProps) => void;
}

const sizeTypes = ['png', 'jpeg', 'jpg', 'svg'];

const FlexBetween = (props: BoxProps) => (
  <Flex justifyContent="space-between" mb={2} {...props} />
);

const Heading = (props: TextProps) => <H4 sx={{ opacity: 0.5 }} {...props} />;

const FileInfo = (props: Props) => {
  const { files = [], onSaveName, canEdit = true } = props;
  const [newName, setNewName] = useState(files[0]?.name || '');
  const [editingName, setEditingName] = useState(false);

  const handleSaveClick = () => {
    onSaveName?.(newName, files[0]);
    setEditingName(false);
  };

  const handleCancelClick = () => {
    setNewName(files[0]?.name || '');
    setEditingName(false);
  };

  useEffect(() => {
    setNewName(files[0]?.name || '');
  }, [files]);

  const fileNames =
    files.length > 3
      ? `${files.length} files selected`
      : files.reduce(
          (previous, current, i) =>
            `${previous}${
              i < files.length - 1 ? `${current.name}, ` : current.name
            }`,
          ''
        );

  const fileSize =
    Math.round(
      files.reduce((total, file) => total + file.data.size, 0) / 1000
    ) / 100;

  const [file] = files;

  return (
    <Box>
      {files.length === 1 && (
        <HeaderBar>
          <P>{file.type}</P>
        </HeaderBar>
      )}
      <InfoContainer>
        {files.length === 1 && !!onSaveName && (
          <>
            {editingName && (
              <InputConfirm
                inputValue={newName}
                handleInputChange={(e) => setNewName(e.currentTarget.value)}
                handleCancelClick={handleCancelClick}
                handleSaveClick={handleSaveClick}
              />
            )}
            {!editingName && (
              <Flex alignItems="center">
                <P mr={2}>{newName}</P>
                {canEdit && (
                  <Button
                    variant="transparent"
                    color="dark-5"
                    onClick={() => setEditingName(true)}
                  >
                    <Icon icon="edit" prefix="fad" />
                  </Button>
                )}
              </Flex>
            )}
          </>
        )}
        {(files.length > 1 || !onSaveName) && <FileName>{fileNames}</FileName>}
        <FileSize>
          {fileSize}
          &nbsp;MB
        </FileSize>
        {files.length === 1 && (
          <Box style={{ marginTop: 10 }}>
            {sizeTypes.find((type) => files.find((f) => f.type === type)) && (
              <FileDimensions file={file} />
            )}
          </Box>
        )}

        {files.length === 1 && (
          <Box mt={3}>
            <FlexBetween>
              <Heading>Created</Heading>
              <P>{moment(file.createdOn).format('MM / DD / YYYY')}</P>
            </FlexBetween>
            <FlexBetween>
              <Heading>Last modified</Heading>
              <P>{moment(file.modified).format('MM / DD / YYYY')}</P>
            </FlexBetween>
            {file.data && file.data.user && file.data.user.name && (
              <Box>
                <Heading mb={2}>Owner</Heading>
                <Box
                  p={2}
                  bg={lighten(0.4, colors.primary)}
                  sx={{
                    borderRadius: 5,
                    border: `1px solid ${colors['primary-3']}`,
                  }}
                >
                  <P color="primary-6">{file.data.user.name}</P>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </InfoContainer>
    </Box>
  );
};

export default FileInfo;
