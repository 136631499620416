import { transparentize } from 'polished';
import React from 'react';
import { SxStyleProp } from 'rebass/styled-components';
import { Input, InputProps } from '@rebass/forms';
import colors from '../styles/themeColors';

type Props = InputProps & {
  inputSize?: 's' | 'm' | 'l';
  sx?: SxStyleProp;
  label?: string;
  darkMode?: boolean;
};

export default function CustomInput(props: Props) {
  const { sx = {}, darkMode } = props;

  return (
    <Input
      {...props}
      sx={{
        borderRadius: 3,
        bg: transparentize(0.8, 'white'),
        borderColor: colors['light-5'],
        color: darkMode ? 'white' : 'dark-3',
        '::placeholder': {
          color: darkMode
            ? transparentize(0.6, 'white')
            : transparentize(0.7, 'black'),
        },
        ...sx,
      }}
    />
  );
}
