import React from 'react';
import PropTypes from 'prop-types';
import { H2, P } from '../styles/typography';
import Box, { Flex } from '../Box';
import Dialog from '../Dialog';
import ToggleInput from '../ToggleInput';

const ViewerSettingsDialog = (props) => {
  const { isVisible, gridVisible, setGridVisible, handleHideDialog } = props;

  const handleToggleGrid = () => {
    setGridVisible(!gridVisible);
  };

  return (
    <Dialog isVisible={isVisible} handleHideDialog={handleHideDialog}>
      <Box style={{ marginBottom: 20 }}>
        <H2>Canvas settings</H2>
      </Box>
      <Flex style={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <P>Show grid</P>
        <Box>
          <ToggleInput
            size={15}
            isOn={gridVisible}
            handleToggle={handleToggleGrid}
          />
        </Box>
      </Flex>
    </Dialog>
  );
};

const { bool, func } = PropTypes;

ViewerSettingsDialog.propTypes = {
  gridVisible: bool.isRequired,
  isVisible: bool.isRequired,
  setGridVisible: func.isRequired,
  handleHideDialog: func.isRequired,
};

export default ViewerSettingsDialog;
