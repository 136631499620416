import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import fetchUtil from '../../../util/fetchUtil';

import Box, { Flex } from '../../Box';
import Button from '../../Button';
import { colors, fade, lighten } from '../../styles/colors';
import { H3 } from '../../styles/typography';
import QuillEditor from '../../QuillEditor';

const Container = styled(Box)`
  color: ${lighten(colors.night, 45)};

  p,
  li {
    color: ${lighten(colors.night, 45)};
  }
`;

/**
 * A basic note editor for adding notes about a scene
 */
const NotesPanel = (props) => {
  const { notes, sceneID, style } = props;

  const [displayValue, setDisplayValue] = useState(notes || '');
  const [value, setValue] = useState(notes || '');
  const [isEditing, setIsEditing] = useState(!notes);

  const handleChange = (val) => {
    setValue(val);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setValue(displayValue);
  };

  const handleSave = async () => {
    await fetchUtil.post('/scene/updateNotes', {
      sceneID,
      text: value,
    });

    setDisplayValue(value);
    setIsEditing(false);
  };

  return (
    <Container style={style}>
      {!!displayValue && !isEditing && (
        <>
          <Flex style={{ alignItems: 'center' }}>
            <H3 style={{ color: fade('white', 40) }}>Notes</H3>
            <Button
              icon
              style={{ fontSize: 14, marginLeft: 10 }}
              onClick={() => setIsEditing(true)}
            >
              <FontAwesomeIcon icon={['fad', 'edit']} />
            </Button>
          </Flex>
          <Box
            dangerouslySetInnerHTML={{
              __html: displayValue,
            }}
          />
        </>
      )}
      {isEditing && (
        <>
          {notes && (
            <Flex style={{ marginBottom: 10 }}>
              <Button
                box
                thin
                color={'white'}
                backgroundColor={colors.green}
                style={{ color: 'white', marginRight: 10 }}
                onClick={handleSave}
              >
                Save
              </Button>
              <Button
                box
                thin
                transparent
                backgroundColor={lighten(colors.night, 10)}
                style={{ color: 'white' }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Flex>
          )}
          <QuillEditor
            onChange={handleChange}
            placeholder="Add notes here..."
            darkMode={true}
            toolbar={[
              'bold',
              'italic',
              'link',
              { list: 'bullet' },
              { list: 'ordered' },
              'image',
            ]}
            value={value}
            onEnterPress={handleSave}
          />
        </>
      )}
    </Container>
  );
};

const { shape, string, number } = PropTypes;

NotesPanel.propTypes = {
  notes: string,
  sceneID: number,
  style: shape({}),
};

export default NotesPanel;
