import { post } from '../../util/fetchUtil';

export const getCardData = async (idCard: string) => {
  const res = await post('/trello/fetchCardData', {
    cardId: idCard,
  });

  return res.cardData;
};

export const updateCardDue = async (
  idCard: string,
  dueDate?: string,
  dueComplete?: boolean
) => {
  const res = await post('/trello/updateDueDate', {
    cardId: idCard,
    dueDate,
    dueComplete,
  });

  return res.data;
};
