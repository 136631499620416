exports.video = ['mp4', 'avi', 'mov'];
exports.image = ['png', 'jpeg', 'jpg', 'tiff', 'tif', 'gif'];
exports.audio = ['mp3', 'wav'];
exports.svg = ['svg'];
exports.text = ['txt', 'md'];
exports.pdf = ['pdf'];
exports.exportTypes = {
  image: [['png'], ['jpg', 'jpeg'], ['tiff', 'tif'], ['webp']],
  video: [['mp4']],
};

const mediaTypes = {
  video: exports.video,
  image: exports.image,
  audio: exports.audio,
  svg: exports.svg,
  text: exports.text,
  pdf: exports.pdf,
};
exports.isMediaType = (fileType, mediaType) => {
  if (
    mediaTypes[mediaType] &&
    mediaTypes[mediaType].find((type) => type === fileType)
  )
    return true;
  return false;
};
exports.getMediaType = (extension) => {
  const keys = Object.keys(mediaTypes);
  for (let i = 0; i < keys.length; i += 1) {
    if (mediaTypes[keys[i]].find((type) => type === extension)) return keys[i];
  }

  return 'unknown';
};
exports.videoMimeTypeMap = {
  // mov: 'quicktime',
  mov: 'mp4', // NOTE: For some reason this works in place of 'quicktime' (the actual MIME type)
  avi: 'x-msvideo',
};
