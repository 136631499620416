import paper from 'paper';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useRef, useEffect } from 'react';

import { loadFromS3 } from '../../app/editor/load';
import Viewer from '../editor/Viewer';

import font from '../../app/font';
import WindowPortal from '../editor/timeline/WindowPortal';
import TimelinePortalControls from '../editor/timeline/TimelinePortalControls';
import useAnimationFrame from '../../app/hooks/useAnimationFrame';
import { post } from '../../util/fetchUtil';
import TimelinePortalSceneLoader from '../editor/timeline/TimelinePortalSceneLoader';
import Box from '../Box';
import { colors } from '../styles/colors';
import wait from '../../app/utilities/wait';
import { mapOrder } from '../../shared/sort';

// Setup paper canvas
paper.setup([1000, 1000]);

// Used by puppeteer to load the scene
window.loadSceneFromUrl = async (url) => {
  const s3Key = url.slice(url.lastIndexOf('/') + 1, url.length);

  const data = await loadFromS3(s3Key);
  const { elements } = data;

  window.setElements(elements);
  window.setProgress(0);
  window.setStageDimensions({
    width: (data.dimensions && data.dimensions.width) || 1920,
    height: (data.dimensions && data.dimensions.height) || 1080,
  });

  window.sceneLoaded = true;
};

const StyledViewer = styled(Viewer)`
  height: 100%;
  margin: 0;
`;

/**
 * Exporter to be used by puppeteer only!
 */
function Exporter() {
  const [scenes, setScenes] = useState([]);
  const [activeScene, setActiveScene] = useState();
  const [screenCaptureModeActive, setScreenCaptureModeActive] = useState(false);

  const [elements, setElements] = useState([]);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [stageDimensions, setStageDimensions] = useState({
    width: 1920,
    height: 1080,
  });
  const [selectedElements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const svgRef = useRef();
  const playOnLoadRef = useRef(false);

  const gridVisible = false;

  const handleUpdateElement = () => {
    setElements([...elements]);
  };

  const currentTime = progress * duration;

  // Export data to window to be used by puppeteer
  // Update duration and progress
  window.duration = duration;
  window.progress = progress;
  window.stageDimensions = stageDimensions;

  // Load SVG load function into window for puppeteer
  window.setElements = setElements;
  window.setDuration = setDuration;
  window.setProgress = setProgress;
  window.setStageDimensions = setStageDimensions;

  window.seekFrame = (frame) => {
    const newProgress = ((frame / 30) * 1000) / window.duration;
    window.setProgress(newProgress);
  };

  window.seekTime = (time) => {
    const newProgress = time / window.duration;
    window.setProgress(newProgress);
  };

  const init = async () => {
    // Create array of promises
    const promises = [font.init()];

    // After loading's done, set global window variable
    await Promise.all(promises).then(() => {
      window.assetsLoaded = true;
    });

    // Get scenes from localStorage if they're there
    const sceneIDsString = localStorage.getItem('screenRecordV2');
    if (sceneIDsString) {
      const sceneIDs = JSON.parse(sceneIDsString);
      const res = await post('/editor/getScenes', {
        sceneIDs,
      });
      if (res.scenes) {
        // Make sure order of files is the same as what was saved to localStorage
        const sortedFiles = mapOrder(res.scenes, sceneIDs, 'sceneID');

        setScenes(sortedFiles);
        setActiveScene(sortedFiles[0]);
        setScreenCaptureModeActive(true);
      }

      localStorage.removeItem('screenRecordV2');
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  const loadScene = async () => {
    setProgress(0);
    setDuration(activeScene.data.duration);
    setIsLoading(true);

    await window.loadSceneFromUrl(activeScene.data.s3Key);

    await wait(0);

    setIsLoading(false);
    if (playOnLoadRef.current) controls.start();
    playOnLoadRef.current = false;
  };

  useEffect(() => {
    if (activeScene) {
      loadScene();
    }
    // eslint-disable-next-line
  }, [activeScene]);

  const handleSkip = (time) => {
    const skipProgress = time / duration;
    const newProgress = skipProgress + progress;
    if (newProgress >= 1) setProgress(1);
    else if (newProgress <= 0) setProgress(0);
    else setProgress(newProgress);
  };

  const handleUpdateProgress = (delta) => {
    setProgress((prevProgress) => {
      const newProgress = prevProgress + delta / duration;
      // Clamp progress at 1
      if (newProgress > 1) return 1;

      return newProgress;
    });
  };

  const { controls, isPlaying } = useAnimationFrame(handleUpdateProgress);

  const handlePlay = () => {
    if (!isPlaying) {
      controls.start();
    } else {
      controls.stop();
    }
  };

  const handleSetActiveScene = (sceneID, playOnLoad = false) => {
    const newScene = scenes.find((s) => s.sceneID === sceneID);
    if (newScene) {
      if (playOnLoad) playOnLoadRef.current = true;
      setActiveScene(newScene);
    }
  };

  return (
    <Container>
      {screenCaptureModeActive && (
        <WindowPortal
          width={550}
          height={300}
          styleDeps={[activeScene]}
          onExternalWindowClose={() => window.close()}
        >
          <Box backgroundColor={colors['light-night-10']} p={20}>
            <Box mb={20}>
              <TimelinePortalSceneLoader
                scenes={scenes.map((scene) => ({
                  id: scene.sceneID,
                  name: scene.data.name,
                }))}
                activeScene={{
                  id: activeScene.sceneID,
                  name: activeScene.data.name,
                }}
                handleSetActiveScene={handleSetActiveScene}
                isPlaying={isPlaying}
              />
            </Box>
            <TimelinePortalControls
              sceneIDs={scenes.map((s) => s.sceneID)}
              activeSceneID={activeScene.sceneID}
              currentTime={currentTime}
              duration={duration}
              isPlaying={isPlaying}
              isLoading={isLoading}
              handleSetActiveScene={handleSetActiveScene}
              handleSetProgress={(newProgress) => setProgress(newProgress)}
              handleTogglePlay={handlePlay}
              handleSkip={handleSkip}
            />
          </Box>
        </WindowPortal>
      )}
      <StyledViewer
        elements={elements}
        svgRef={svgRef}
        currentTime={currentTime}
        handleUpdateElement={handleUpdateElement}
        selectedElements={selectedElements}
        gridVisible={gridVisible}
        stageDimensions={stageDimensions}
        isPlaying={true}
      />
    </Container>
  );
}

const Container = styled.div`
  height: 100vh;
`;

Exporter.propTypes = {
  propName: PropTypes.string,
};

Exporter.defaultProps = {
  propName: '',
};

export default Exporter;
