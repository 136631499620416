export const addKeyframeGroup = (elementId, key, elements) => {
  // Recursively look for and remove target elements
  const addRecursive = (els) => {
    // eslint-disable-next-line no-param-reassign
    const element = els.find((el) => el.id === elementId);

    if (element) {
      element.addKeyframeGroup(key);
    } else {
      els.forEach((el) => {
        if (el.elements) addRecursive(el.elements);
      });
    }
  };

  addRecursive(elements);
};

export default {
  addKeyframeGroup,
};
