import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState } from 'react';

import { motion, AnimatePresence } from 'framer-motion';

import { post } from '../../util/fetchUtil';

import FetchAutocomplete from '../FetchAutocomplete';
import Label from '../Label';
import { colors } from '../styles/colors';
import { P } from '../styles/typography';

import { LabelContainer, LabelsContainer } from '../assets/AssetStyles';

const Container = styled.div``;

const StyledLabel = styled(Label)`
  font-size: 16px;
`;

const LabelClose = styled(FontAwesomeIcon)`
  margin-right: 5px;
  opacity: 0.5;

  cursor: pointer;
`;

const CreateTag = styled.div`
  color: ${colors['light-grey-10']};
  display: flex;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const Text = styled(P)`
  color: ${colors['light-grey-10']};
`;

function ProjectTagger(props) {
  const { project, tags, getTagData } = props;

  const [userInput, setUserInput] = useState('');

  const handleCreateTag = async () => {
    const res = await post('/tags/createTag', {
      text: userInput,
    });

    await post('/tags/createProjectTagLink', {
      projectID: project.projectID,
      tagID: res.tag.tagID,
    });

    getTagData();
    setUserInput('');

    return res.tag.tagID;
  };

  const handleSelectTagOption = async (tag) => {
    let tagID = tag && tag.tagID;

    // If no matches were returned, add userInput as new tag
    if (!tagID) {
      tagID = await handleCreateTag();
    } else {
      await post('/tags/createProjectTagLink', {
        tagID,
        projectID: project.projectID,
      });
    }

    post('/tags/addTagsToProjectFiles', {
      tagID,
      projectID: project.projectID,
    });

    getTagData();
    setUserInput('');
  };

  const handleRemoveTag = (tag) => {
    post('/tags/removeProjectTagLink', {
      tagID: tag.tagID,
      projectID: project.projectID,
    }).then(() => {
      getTagData();
    });
  };

  const tagComponents = tags.map((tag) => (
    <LabelContainer
      initial={{ opacity: 0, x: -10 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -10, transition: { easing: 'easeOut' } }}
      key={tag.tagID}
    >
      <StyledLabel color="teal">
        <LabelClose
          icon={['fas', 'times']}
          onClick={() => handleRemoveTag(tag)}
        />
        <span>{tag.name}</span>
      </StyledLabel>
    </LabelContainer>
  ));

  const notFoundComponent = (
    <CreateTag onClick={handleCreateTag}>
      <Icon icon={['fas', 'plus']} />
      <Text>Create new tag &apos;{userInput}&apos;</Text>
    </CreateTag>
  );

  return (
    <Container>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, x: -10, height: 0 }}
          animate={{ opacity: 1, x: 0, height: 'auto' }}
          exit={{
            opacity: 0,
            x: -10,
            height: 0,
            transition: { easing: 'easeOut' },
          }}
        >
          <FetchAutocomplete
            route="tags/searchTags"
            onOptionClick={handleSelectTagOption}
            userInput={userInput}
            setUserInput={setUserInput}
            notFoundComponent={notFoundComponent}
            placeholder="Create or search for tags..."
          />
        </motion.div>
      </AnimatePresence>
      <LabelsContainer>
        <AnimatePresence>{tagComponents}</AnimatePresence>
      </LabelsContainer>
    </Container>
  );
}

ProjectTagger.propTypes = {
  file: PropTypes.shape({}),
  project: PropTypes.shape({
    projectID: PropTypes.number,
  }),
  tags: PropTypes.array,
  getTagData: PropTypes.func.isRequired,
};

ProjectTagger.defaultProps = {
  project: {},
  file: {},
  tags: [],
};

export default ProjectTagger;
