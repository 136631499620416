import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { useState } from 'react';

import { motion } from 'framer-motion';

import Box from '../../Box';
import { colors, lighten } from '../../styles/colors';
import Avatar from '../../Avatar';

import CommentProps from '../props/CommentProps';

const StyledAvatar = styled(Avatar)`
  img {
    border: 1px solid ${lighten(colors.teal, 30)};
  }
`;

const AnimationContainer = styled(motion.div)`
  position: absolute;
  height: 100%;
  left: ${(props) => props.left};
  display: flex;
  align-items: center;
`;

const CommentPoint = styled(Box)`
  opacity: 0.5;

  transition: 0.1s all ease-in-out;

  cursor: pointer;

  :hover,
  :focus {
    opacity: 1;
  }
`;

const TimelineCommentPoint = (props) => {
  const { comment, handleClickComment, duration } = props;
  const [, setMouseIsOver] = useState(false);

  const handleMouseEnter = () => {
    setMouseIsOver(true);
  };

  const handleMouseLeave = () => {
    setMouseIsOver(false);
  };

  const p = comment.data.timestamp / duration;
  return (
    <AnimationContainer
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ scale: 1 }}
      left={`calc(${p * 100}% - 8px)`}
    >
      <CommentPoint
        onClick={(e) => handleClickComment(e, p, comment)}
        onMouseDown={(e) => e.stopPropagation()}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {comment.data.user && (
          <Box style={{ position: 'relative' }}>
            <StyledAvatar src={comment.data.user.avatar} size={16} />
          </Box>
        )}
      </CommentPoint>
    </AnimationContainer>
  );
};

const { func, number } = PropTypes;

TimelineCommentPoint.propTypes = {
  comment: CommentProps.isRequired,
  handleClickComment: func,
  duration: number,
};

TimelineCommentPoint.defaultProps = {
  handleClickComment: () => {},
  duration: 0,
};

export default TimelineCommentPoint;
