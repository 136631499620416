import React, { ReactNode } from 'react';
import {
  Checkbox as RebassCheckbox,
  CheckboxProps,
  Label,
} from '@rebass/forms';
import { SxStyleProp } from 'rebass';
import styled from 'styled-components';
import colors from '../styles/themeColors';
import Text from './Text';
import Box from './Box';

const Container = styled(Box)`
  input {
    position: fixed; // Hack to get around weird input bug with rebass checkbox (absolute positioning affecting scroll)
  }
`;

type Props = CheckboxProps & {
  value?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  placeholder?: string;
  inputSize?: 's' | 'm' | 'l';
  sx?: SxStyleProp;
  labelSx?: SxStyleProp;
  label?: ReactNode;
};

export default function Checkbox(props: Props) {
  const { value, onChange, checked, placeholder, sx, labelSx, label } = props;

  return (
    <Container>
      <Label sx={{ ...labelSx }}>
        <RebassCheckbox
          {...props}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          sx={{
            color: checked ? colors['accent-6'] : colors['dark-6'],
            ':focus': {
              background: colors['accent-1'],
            },
            // ...sizes[inputSize],
            ...sx,
          }}
        />
        <Text>{label}</Text>
      </Label>
    </Container>
  );
}
