import React, { useEffect } from 'react';

import { RectElementProps } from '../types/ElementProps';

type Props = {
  element: RectElementProps;
  gRef: any; // FIXME:
  handleGetTransform: () => void;
  className?: string;
};

const parseProps = (props: any) => {
  const { handleGetTransform, gRef, ...newProps } = props;
  return newProps;
};

/**
 * A <rect> element
 */
function Rect(props: Props) {
  const { element, gRef, handleGetTransform, className } = props;

  const style = {
    // Override with initial props
    ...(element.props.style ? element.props.style : {}),
    // Override with current props
    ...(element.currentProps.style ? element.currentProps.style : {}),
  };

  // Hacky fix for behavior where rect element transform is set after
  // rendering by subjx, causing the resize to be off.
  // Makes sure the transform matrix matches the element's after render
  useEffect(() => {
    if (
      gRef.current &&
      !element.currentProps.transform &&
      gRef.current.attributes.transform.value.match(/matrix/g)
    ) {
      gRef.current.attributes.transform.value = handleGetTransform();
    }
  });

  const newProps = parseProps(props);

  return (
    <rect
      {...newProps}
      height={element.currentProps.height}
      width={element.currentProps.width}
      style={style}
      className={className}
      x={0}
      y={0}
    />
  );
}

export default Rect;
