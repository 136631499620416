import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useContext, useEffect } from 'react';

import { post } from '../../util/fetchUtil';

import { Store } from '../../state/store';
import { fetchUserData, fetchTeamData } from '../../state/user/actions';
import { setActiveProjectByProjectID } from '../../state/projects/actions';

import uuid from '../../app/utilities/uuid';

import CreditsPanel from './CreditsPanel';
import CreditsPreview from './CreditsPreview';

const initialCredits = {
  items: [],
  partnerLogos: [],
};

function Credits() {
  const [credits, setCredits] = useState(initialCredits);
  const [projectID, setProjectID] = useState();
  const {
    dispatch,
    state: { activeProject },
  } = useContext(Store);

  const location = useLocation();

  useEffect(() => {
    const url = new URL(window.location.href);
    const projectIDParam = +url.searchParams.get('projectID');
    if (projectIDParam && !projectID) setProjectID(projectIDParam);
    // eslint-disable-next-line
  }, [location]);

  const initData = async () => {
    if (projectID) {
      fetchUserData(dispatch);
      fetchTeamData(dispatch);
      const res = await post('/project/getCreditsData', {
        projectID,
      });

      const { creditsData } = res;

      if (projectID && !activeProject.projectID)
        setActiveProjectByProjectID(projectID, [], dispatch);

      if (projectID) {
        let items = [];
        let partnerLogos = [];
        let partnerLogo = {};

        if (creditsData) {
          partnerLogo = creditsData.data.partnerLogo || {};
          partnerLogos = creditsData.data.partnerLogos || [];

          items = creditsData.data.items || [];

          // If there's no 'section' type, then create initial template
          if (!items.find((item) => item.type === 'section')) {
            items = [
              {
                id: uuid(),
                type: 'section',
                title: 'Osmosis',
              },
              ...creditsData.data.items,
              {
                id: uuid(),
                type: 'section',
                title: 'Other',
              },
              {
                id: uuid(),
                type: 'entry',
                name: 'Mike Koenig',
                role: 'Sound effect',
                attribution: 1,
                shareAlike: 0,
              },
            ];

            // Save
            post('/project/updateCredits', {
              projectID: parseInt(projectID, 10),
              data: {
                ...creditsData.data,
                items,
              },
            });
          }

          // Transfer over deprecated single logo
          if (partnerLogo && partnerLogo.src && !partnerLogos.length) {
            partnerLogos.push({ ...partnerLogo, id: uuid() });
          }
        } else {
          items = [
            {
              id: uuid(),
              type: 'section',
              title: 'Osmosis',
            },
            {
              id: uuid(),
              type: 'section',
              title: 'Other',
            },
            {
              id: uuid(),
              type: 'entry',
              name: 'Mike Koenig',
              role: 'Sound effect',
              attribution: true,
              shareAlike: false,
            },
          ];

          // Save new entry
          post('/project/newCredits', {
            projectID: parseInt(projectID, 10),
            data: {
              items,
            },
          });
        }

        setCredits({
          ...(creditsData ? creditsData.data : {}),
          items,
          partnerLogos,
        });
      }
    }
  };

  useEffect(() => {
    initData();
    // eslint-disable-next-line
  }, [projectID]);

  const updateItems = (newItems) => {
    if (projectID) {
      post('/project/updateCredits', {
        projectID: parseInt(projectID, 10),
        data: {
          items: newItems,
        },
      });
    }
  };

  const addPartnerLogo = (newPartnerLogo) => {
    if (projectID) {
      post('/project/updateCredits', {
        projectID: parseInt(projectID, 10),
        data: {
          partnerLogos: [...credits.partnerLogos, newPartnerLogo],
        },
      });
    }
  };

  const updatePartnerLogos = (newPartnerLogos) => {
    if (projectID) {
      if (newPartnerLogos.length) {
        post('/project/updateCredits', {
          projectID: parseInt(projectID, 10),
          data: {
            partnerLogos: newPartnerLogos,
          },
        });
      } else {
        post('/project/removePartnerLogos', {
          projectID: parseInt(projectID, 10),
        });
      }
    }

    // Update UI
    setCredits({
      ...credits,
      partnerLogos: newPartnerLogos,
    });
  };

  /**
   *
   *
   * @param {Object} credit
   * @param {String} type - takes "attribution" or "shareAlike"
   * @memberof Credits
   */
  const handleToggleCC = (credit, type) => {
    const { items } = credits;
    const newItems = Array.from(items);

    const index = newItems.findIndex((c) => c.id === credit.id);
    newItems[index][type] = newItems[index][type] === 'true' ? 'false' : 'true';

    setCredits({
      ...credits,
      items: newItems,
    });

    updateItems(newItems);
  };

  const handleAddSection = (title) => {
    const { items } = credits;
    const newItems = Array.from(items);

    newItems.push({
      id: uuid(),
      type: 'section',
      title,
    });

    setCredits({
      ...credits,
      items: newItems,
    });

    updateItems(newItems);
  };

  const handleAddEntry = (name, role) => {
    const { items } = credits;
    const newItems = Array.from(items);

    newItems.push({
      id: uuid(),
      type: 'entry',
      name,
      role,
    });

    setCredits({
      ...credits,
      items: newItems,
    });

    updateItems(newItems);
  };

  const handleRemoveCredit = (id) => {
    const { items } = credits;
    const newItems = Array.from(items);
    const index = newItems.findIndex((item) => item.id === id);
    newItems.splice(index, 1);

    setCredits({
      ...credits,
      items: newItems,
    });

    updateItems(newItems);
  };

  const handleChangeOrder = (oldIndex, newIndex) => {
    const { items } = credits;
    const newItems = Array.from(items);

    const [item] = newItems.splice(oldIndex, 1);
    newItems.splice(newIndex, 0, item);

    setCredits({
      ...credits,
      items: newItems,
    });

    updateItems(newItems);
  };

  const handleUpdateSpacer = (spacer) => {
    // Save
    if (projectID) {
      post('/project/updateCredits', {
        projectID: parseInt(projectID, 10),
        data: {
          spacer,
        },
      });
    }
  };

  const handleRemovePartnerLogo = (partnerLogo) => {
    const newPartnerLogos = Array.from(credits.partnerLogos);

    const index = newPartnerLogos.findIndex(
      (logo) => logo.id === partnerLogo.id
    );

    newPartnerLogos.splice(index, 1);

    updatePartnerLogos(newPartnerLogos);
  };

  const handleAddPartnerLogo = ({ src }) => {
    const newPartnerLogo = {
      src,
      id: uuid(),
      style: {
        width: '300',
        transform: {
          x: 0,
          y: 0,
        },
      },
    };

    setCredits({
      ...credits,
      partnerLogos: [
        ...(credits.partnerLogos ? credits.partnerLogos : []),
        newPartnerLogo,
      ],
    });

    addPartnerLogo(newPartnerLogo);
  };

  const handleUpdatePartnerLogo = (id, { transform, width }) => {
    const newPartnerLogos = Array.from(credits.partnerLogos || []);
    const partnerLogo = newPartnerLogos.find((l) => l.id === id);

    partnerLogo.style = {
      transform,
      width,
    };

    setCredits({
      ...credits,
      partnerLogos: newPartnerLogos,
    });

    updatePartnerLogos(newPartnerLogos);
  };

  return (
    <Container>
      <CreditsPanel
        credits={credits}
        projectID={projectID}
        activeProject={activeProject}
        handleToggleCC={handleToggleCC}
        handleAddSection={handleAddSection}
        handleAddEntry={handleAddEntry}
        handleRemoveCredit={handleRemoveCredit}
        handleChangeOrder={handleChangeOrder}
        handleUpdatePartnerLogo={handleUpdatePartnerLogo}
        handleAddPartnerLogo={handleAddPartnerLogo}
      />
      <CreditsPreview
        handleUpdateSpacer={handleUpdateSpacer}
        handleUpdatePartnerLogo={handleUpdatePartnerLogo}
        handleRemovePartnerLogo={handleRemovePartnerLogo}
        credits={credits}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;

  height: 100vh;
`;

Credits.propTypes = {
  propName: PropTypes.string,
};

Credits.defaultProps = {
  propName: '',
};

export default Credits;
