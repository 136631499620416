import React from 'react';
import { post } from '../../util/fetchUtil';
import Box from '../Box';
import { H1, P } from '../styles/typography';
import Button from '../Button';

export default function AdminSearch() {
  const rebuildIndices = async () => {
    const res = await post('/v0/search/rebuild');
    console.log('rebuild', res);
  };

  return (
    <Box p={3}>
      <H1>Admin Search</H1>
      <P>🛠 under construction 🛠</P>
      {/* <Button onClick={rebuildIndices}>🛠 Rebuild Search Indices</Button> */}
    </Box>
  );
}
