import React, { useState, memo } from 'react';
import styled, { css } from 'styled-components/macro';

import Box, { Flex } from '../Box';
import ElementsPanel from './elementsPanel/ElementsPanel';
import { colors, lighten, fade } from '../styles/colors';
import AnimationsList from './sidePanel/AnimationsList';
import Library from './sidePanel/Library';
import Media from './sidePanel/Media';
import {
  ElementsArrayProps,
  GroupElementProps,
  MixedElementProps,
} from './types/ElementProps';
import {
  getPreference,
  updatePreference,
} from '../../app/editor/preferenceStore';

type ViewProps = {
  name: string;
  id: string;
  icon: string;
};

const options: ViewProps[] = [
  {
    name: 'Layers',
    id: 'layers',
    icon: 'fad fa-layer-group',
  },
  {
    name: 'Media',
    id: 'media',
    icon: 'fad fa-file-image',
  },
  {
    name: 'Library',
    id: 'library',
    icon: 'fad fa-books',
  },
  {
    name: 'Animations',
    id: 'animations',
    icon: 'fad fa-sparkles',
  },
];

const SelectionPanel = styled(Box)`
  flex: 1;

  background-color: ${lighten(colors.night, 7)};
`;

const Option = styled(Box)`
  color: ${fade('white', 60)};
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  :hover,
  :focus {
    background-color: ${fade('black', 95)};
  }

  :active {
    background-color: ${fade('black', 90)};
  }

  ${(props: { isSelected: boolean }) =>
    props.isSelected &&
    css`
      color: ${lighten(colors.blue, 35)};
    `}
`;

const Container = styled(Flex)`
  margin: 5px 0 5px 5px;
  border-radius: 5px;
  background-color: ${lighten(colors.night, 3)};
`;

type Props = {
  elements: ElementsArrayProps;
  selectedElements: ElementsArrayProps;
  elementsToUpdate: React.RefObject<string[]>;
  style?: React.CSSProperties;
  isLoading: boolean;
  currentTime: number;
  duration: number;
  isPlaying: boolean;
  setSelectedElements: (elements: ElementsArrayProps) => void;
  handleAddElement: (element: MixedElementProps) => void;
  handleReorderElement: (
    droppedElementId: string,
    targetElementId: string,
    newIndex: 0 | 1
  ) => void;
  handleAddToGroup: (
    elementsToAdd: ElementsArrayProps,
    groupElement: GroupElementProps
  ) => void;
  handleGroupElements: (elementsToGroup: ElementsArrayProps) => void;
  handleUpdateElement: (elementIds: string[]) => void;
  handleMaskElement: (
    maskingElement: MixedElementProps,
    elementToMask: MixedElementProps
  ) => void;
  handleCloneElements: (elements: ElementsArrayProps) => void;
  handleRemoveElements: (elements: ElementsArrayProps) => void;
};

/**
 * Left panel that contains the element layers, animations, assets, and other things
 */
function LeftPanel(props: Props) {
  const {
    elements,
    selectedElements,
    elementsToUpdate,
    style,
    isLoading,
    currentTime,
    duration,
    setSelectedElements,
    handleAddElement,
    handleReorderElement,
    handleAddToGroup,
    handleGroupElements,
    handleUpdateElement,
    handleMaskElement,
    handleCloneElements,
    handleRemoveElements,
  } = props;

  const [selectedView, setSelectedView] = useState(
    options.find((o) => o.id === getPreference('leftPanelView')) ||
      ({} as ViewProps)
  );

  const handleSelectView = (newView: ViewProps) => {
    if (newView === selectedView) {
      setSelectedView({} as ViewProps);

      updatePreference('leftPanelView', '');
    } else {
      setSelectedView(newView);
      updatePreference('leftPanelView', newView.id);
    }
  };

  return (
    <Container style={style}>
      <SelectionPanel>
        {options.map((o) => (
          <Option
            isSelected={selectedView.id === o.id}
            key={o.id}
            onClick={() => handleSelectView(o)}
          >
            <i style={{ fontSize: 24 }} className={o.icon} />
            {o.name}
          </Option>
        ))}
      </SelectionPanel>
      {selectedView.id && (
        <Box style={{ width: '300px', overflow: 'auto' }}>
          {selectedView.id === 'layers' && (
            <ElementsPanel
              elements={elements}
              elementsToUpdate={elementsToUpdate}
              handleReorderElement={handleReorderElement}
              handleGroupElements={handleGroupElements}
              handleAddToGroup={handleAddToGroup}
              selectedElements={selectedElements}
              setSelectedElements={setSelectedElements}
              isLoading={isLoading}
              handleUpdateElement={handleUpdateElement}
              handleCloneElements={handleCloneElements}
              handleRemoveElements={handleRemoveElements}
              handleMaskElement={handleMaskElement}
            />
          )}
          {selectedView.id === 'animations' && (
            <AnimationsList
              currentTime={currentTime}
              selectedElements={selectedElements}
              handleUpdateElement={handleUpdateElement}
            />
          )}
          {selectedView.id === 'media' && (
            <Media
              elements={elements}
              handleUpdateElement={handleUpdateElement}
            />
          )}
          <Box
            style={{
              display: selectedView.id === 'library' ? 'block' : 'none',
              height: '100%',
            }}
          >
            <Library duration={duration} handleAddElement={handleAddElement} />
          </Box>
        </Box>
      )}
    </Container>
  );
}

export default memo(LeftPanel, (prevProps, nextProps) => {
  if (nextProps.isPlaying) return true;
  return false;
});
