import React, { useCallback, useContext, useEffect, useState } from 'react';

import { HashRouter, useHistory, useParams } from 'react-router-dom';

import ScenesReview from './ScenesReview';
import { Store, StoreProvider } from '../../state/store';
import { post } from '../../util/fetchUtil';
import { fetchSavedWords } from '../../state/misspelledWords/actions';

function SceneReviewWrapper() {
  const { dispatch } = useContext(Store);
  const [scenes, setScenes] = useState([]);
  const [comments, setComments] = useState([]);

  const params = useParams();
  const history = useHistory();

  const getScenes = async () => {
    const { slug, type } = params;
    if (slug) {
      let scenes = [];
      if (type === 'scene') {
        const res = await post('/scene/getReviewScene', {
          slug,
        });

        scenes = [res.scene];
      } else if (type === 'project') {
        const res = await post('/scene/getProjectReviewScenes', {
          slug,
        });

        scenes = res.scenes;
      }

      setScenes(scenes);
      return;
    }

    return history.push('/');
  };

  const handleGetComments = useCallback(async () => {
    const commentsRes = await post('/scene/getAllSceneComments', {
      sceneIDs: scenes.map((s) => s.sceneID),
    });

    setComments(commentsRes.scenes);
  }, [scenes]);

  useEffect(() => {
    if (scenes.length && !comments.length) {
      handleGetComments();
    }
  }, [comments.length, scenes.length, handleGetComments]);

  useEffect(() => {
    getScenes();
    fetchSavedWords(dispatch);
    // eslint-disable-next-line
  }, []);

  return (
    <HashRouter>
      <StoreProvider>
        {scenes.length && (
          <ScenesReview
            scenes={scenes}
            comments={comments}
            handleGetComments={handleGetComments}
          />
        )}
      </StoreProvider>
    </HashRouter>
  );
}

SceneReviewWrapper.propTypes = {};

export default SceneReviewWrapper;
