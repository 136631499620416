import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useContext } from 'react';

import { Store } from '../../state/store';
import Uploader from '../../app/Uploader';

import { colors } from '../styles/colors';
import WarningDialog from '../dialogs/WarningDialog';
import useModal from '../../app/hooks/useModal';
import Dialog from '../Dialog';

function PartnerLogoUI(props) {
  const { handleAddPartnerLogo } = props;

  const errorDialog = useModal();

  const {
    state: { activeProject },
  } = useContext(Store);

  const uploadFile = (file) => {
    // Initialize uploader
    const uploader = new Uploader();
    uploader
      .init()
      .then(() => {
        // Add file and upload
        uploader.addFile(file);

        // Set loading state
        const uploadString = activeProject.hash
          ? `projects/${activeProject.hash}/credits/${file.name}`
          : `images/${file.name}`;

        return uploader.upload(uploadString);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        errorDialog.show();
        return false;
      })
      // Add to DB
      .then((res) => {
        handleAddPartnerLogo({ src: res.url });
      });
  };

  const handleInputChange = (e) => {
    e.persist();
    const [file] = e.target.files;

    uploadFile(file);
  };

  return (
    <Wrapper>
      <Dialog
        isVisible={errorDialog.isVisible}
        handleHideDialog={() => errorDialog.hide()}
      >
        <WarningDialog>
          Uh oh, something went wrong with that upload.
        </WarningDialog>
      </Dialog>
      <Container>
        <Header>Partner Logo:</Header>
        <FileInputContainer>
          <input type="file" onChange={handleInputChange} />
        </FileInputContainer>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 15px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 2px solid ${colors['light-grey-50']};
  border-radius: 5px;

  padding: 15px;
`;

const Header = styled.h3`
  margin: 0;
  margin-right: 15px;
`;

const FileInputContainer = styled.div``;

PartnerLogoUI.propTypes = {
  handleAddPartnerLogo: PropTypes.func.isRequired,
};

PartnerLogoUI.defaultProps = {};

export default PartnerLogoUI;
