import styled from 'styled-components/macro';

import { colors, lighten } from '../styles/colors';

export const FlexContainer = styled.div`
  display: flex;

  img {
    width: 100%;
  }
`;
export const CommentTimestamp = styled.p`
  margin: 0;
  margin-left: 5px;

  color: ${colors['light-grey-30']};
`;
export const CommentRow = styled.div`
  padding-top: 15px;
`;
export const CommentText = styled.div`
  margin: 0;
  margin-top: 5px;
  padding: 8px 13px;

  background: white;
  border-radius: 3px;
  border-bottom: 2px solid ${lighten(colors.grey, 53)};

  white-space: pre-line;

  display: inline-block;
`;
export const CommentActions = styled.div`
  display: flex;
  padding: 5px;
`;
export const CommentAction = styled.div`
  font-size: 12px;
  color: ${colors['light-grey-30']};
  margin-right: 10px;

  cursor: pointer;

  :hover,
  :focus {
    color: ${colors['light-grey-20']};
  }

  :active {
    color: ${colors['light-grey-10']};
  }
`;
export const EditingInputContainer = styled.div`
  margin-top: 15px;
  textarea {
    width: 100%;
  }
`;
export const RightContainer = styled.div`
  flex: 1;
`;
