import { useState, useRef, useEffect } from 'react';

import Debouncer from '../utilities/debouncer';

/**
 * useScroll React custom hook
 */
function useScroll(containerRef, options = {}) {
  const { debounce = 0 } = options;

  const debouncerRef = useRef(new Debouncer(0, debounce));

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);

  const [offsetHeight, setOffsetHeight] = useState(0);
  const [scrollX, setScrollX] = useState(0);
  const [scrollY, setScrollY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState();

  const updateScrollPositions = (containerEl) => {
    const currentScrollTop = -containerEl.scrollTop;
    setScrollY(currentScrollTop);
    setOffsetHeight(containerEl.offsetHeight);
    setScrollWidth(containerEl.scrollWidth);
    setScrollHeight(containerEl.scrollHeight);

    setScrollX(containerEl.scrollLeft);

    setScrollDirection(lastScrollTop > currentScrollTop ? 'down' : 'up');
    setLastScrollTop(currentScrollTop);
  };

  const listener = () => {
    const containerEl = containerRef.current;
    if (containerEl) {
      const currentScrollTop = -containerEl.scrollTop;
      const debouncer = debouncerRef.current;

      debouncer.setValue(currentScrollTop, () =>
        updateScrollPositions(containerEl)
      );
    }
  };

  useEffect(() => {
    const containerEl = containerRef.current;
    if (containerEl) {
      containerEl.addEventListener('scroll', listener);
    }
    return () => {
      if (containerEl) {
        containerEl.removeEventListener('scroll', listener);
      }
    };
  });

  return {
    scrollY,
    scrollX,
    scrollDirection,
    scrollHeight,
    scrollWidth,
    offsetHeight,
  };
}

export default useScroll;
