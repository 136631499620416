import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { useState, useRef, useEffect } from 'react';

import Box from '../../Box';
import ContentEditable from '../../ContentEditable';

import useClickOutside from '../../../app/hooks/useClickOutside';

import { fade  } from '../../styles/colors';

import ElementProps from '../props/ElementProps';

const TextItem = styled(Box)`
  padding: 10px;
  background: ${fade('white', 90)};
  border-radius: 3px;
  margin: 5px;
`;

const MediaTextItem = (props) => {
  const { element, handleUpdateElement } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [newText, setNewText] = useState(element.text);

  const containerRef = useRef();
  const editableRef = useRef();

  useClickOutside(containerRef, () => {
    if (isEditing) {
      element.update('text', newText);
      setIsEditing(false);
      handleUpdateElement([element.id]);
    }
  });

  const handleClickText = () => {
    setIsEditing(true);
  };

  const textStyles = {
    color: fade('white', 30),
  };

  useEffect(() => {
    if (isEditing) {
      editableRef.current.focus();
    }
  }, [isEditing]);

  return (
    <TextItem ref={containerRef}>
      {isEditing && (
        <ContentEditable
          html={newText}
          domRef={editableRef}
          style={textStyles}
          onChange={(e) => setNewText(e.target.value)}
        />
      )}
      {!isEditing && (
        <div style={textStyles} onClick={handleClickText}>
          {element.text}
        </div>
      )}
    </TextItem>
  );
};

const { func } = PropTypes;

MediaTextItem.propTypes = {
  element: ElementProps,
  handleUpdateElement: func,
};

export default MediaTextItem;
