import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Store } from '../../state/store';
import NotesPanel from './notes/NotesPanel';
import ErrorsPanel from './errors/ErrorsPanel';
import ElementProperties from './properties/ElementProperties';
import EditorCommentPanel from './comments/EditorCommentPanel';
import Box, { Flex } from '../Box';
import { colors, fade } from '../styles/colors';
import { PanelContainer, PanelButton, PanelHeader } from './EditorStyles';

import tools from '../../app/editor/editorCommentTools';

import CommentProps from './props/CommentProps';
import ElementProps from './props/ElementProps';
import ErrorProps from './props/SceneErrorProps';

const StyledPanelContainer = styled(PanelContainer)`
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
`;

const PanelBody = styled(Box)`
  overflow: auto;
`;

const CommentNumber = styled(Box)`
  background-color: ${fade(colors.red, 20)};
  padding: 5px;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 5px;
`;

/**
 * Panel that contains element properties and notes
 */
const RightPanel = (props) => {
  const {
    scene,
    errors,
    selectedElements,
    handleUpdateElement,
    addKeyframe,
    currentTime,
    comments,
    handleGetComments,
    isPlaying,
    handleClickComment,
    selectedCommentID,
    setSelectedCommentID,
  } = props;

  const activeComments = comments.filter((c) => c.data.status === 1);

  const options = [
    {
      name: 'Properties',
      id: 'properties',
      icon: 'fad fa-layer-group',
    },
    {
      name: 'Notes',
      id: 'notes',
      icon: 'fad fa-file',
    },
    {
      name: 'Comments',
      id: 'comments',
      icon: 'fad fa-comment',
    },
    ...(errors.length ? [{ name: 'Errors', id: 'errors' }] : []),
  ];

  const {
    state: { team },
  } = useContext(Store);
  const [selectedView, setSelectedView] = useState(options[0]);

  const handleSelectView = (option) => {
    setSelectedView(option);
  };

  const getPanelButton = (option) => {
    if (option.id === 'comments') {
      return (
        <Flex>
          {option.name}
          {!!activeComments.length && (
            <CommentNumber>{activeComments.length}</CommentNumber>
          )}
        </Flex>
      );
    }
    if (option.id === 'errors') {
      return (
        <Flex>
          <span style={{ color: colors['light-red-20'] }}>{option.name}</span>
          {errors.length && <CommentNumber>{errors.length}</CommentNumber>}
        </Flex>
      );
    }
    return option.name;
  };

  return (
    <Box style={{ flex: 1, margin: 5 }}>
      <StyledPanelContainer>
        <PanelHeader display="flex">
          {options.map((option) => (
            <PanelButton
              key={option.id}
              isActive={selectedView.id === option.id}
              onClick={() => handleSelectView(option)}
            >
              {getPanelButton(option)}
            </PanelButton>
          ))}
        </PanelHeader>
        {scene.data && (
          <PanelBody>
            {selectedView.id === 'notes' && (
              <NotesPanel notes={scene.data.notes} sceneID={scene.sceneID} />
            )}
            {selectedView.id === 'properties' && (
              <ElementProperties
                selectedElements={selectedElements}
                handleUpdateElement={handleUpdateElement}
                addKeyframe={addKeyframe}
              />
            )}
            {selectedView.id === 'comments' && (
              <EditorCommentPanel
                scene={scene}
                currentTime={currentTime}
                comments={comments}
                handleGetComments={handleGetComments}
                onClickComment={handleClickComment}
                isPlaying={isPlaying}
                team={team}
                showTools={false}
                tools={tools}
                selectedCommentID={selectedCommentID}
                setSelectedCommentID={setSelectedCommentID}
              />
            )}
            {selectedView.id === 'errors' && <ErrorsPanel errors={errors} />}
          </PanelBody>
        )}
      </StyledPanelContainer>
    </Box>
  );
};

const { arrayOf, func, number, bool } = PropTypes;

RightPanel.propTypes = {
  scene: PropTypes.any,
  elements: arrayOf(ElementProps).isRequired,
  selectedElements: arrayOf(ElementProps).isRequired,
  handleUpdateElement: func.isRequired,
  duration: number.isRequired,
  addKeyframe: func.isRequired,

  errors: arrayOf(ErrorProps),
  progress: number,
  currentTime: number,
  comments: arrayOf(CommentProps),
  isPlaying: bool,
  handleGetComments: func,
  handleClickComment: func,
  setSelectedCommentID: func,
  selectedCommentID: number,
};

RightPanel.defaultProps = {
  errors: [],
  progress: 0,
  currentTime: 0,
  comments: [],
  isPlaying: false,
  handleGetComments: () => {},
  handleClickComment: () => {},
  setSelectedCommentID: () => {},
};

export default RightPanel;
