import React from 'react';
import PropTypes from 'prop-types';

// Register
require('../app/videojs/CommentBar');

function VideoPlayer(props) {
  const { videoRef, tracks, className, style } = props;

  return (
    <div data-vjs-player className={className} style={style}>
      <video ref={videoRef} className="video-js">
        {tracks.length &&
          tracks.map((track) => (
            <track
              key={track.src}
              src={track.src}
              kind={track.kind}
              srcLang={track.srcLang}
              label={track.label}
            />
          ))}
      </video>
    </div>
  );
}

VideoPlayer.propTypes = {
  videoRef: PropTypes.shape({}),
  className: PropTypes.string,
  style: PropTypes.shape({}),
  tracks: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      kind: PropTypes.string,
      srcLang: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

VideoPlayer.defaultProps = {
  videoRef: null,
  className: '',
  tracks: [],
};

export default VideoPlayer;
