import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import tools from './tools';

import useModifier from '../../app/hooks/useModifier';
import useHotkeys from '../../app/hooks/useHotkeys';

import coordsUtil from '../../app/editor/coords';

import { colors, lighten } from '../styles/colors';

import InitialPath from './path/InitialPath';
import SvgGrid from '../SvgGrid';

import { ChildrenProps } from '../../props/general';
import ElementProps from './props/ElementProps';

// Allow click events to fall through to <svg>
const Stage = styled.rect`
  pointer-events: none;
`;

const StyledSvg = styled.svg`
  ${(props) =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}

  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}

  background: ${lighten(colors.grey, 50)};
  border: 1px solid ${colors['light-grey-40']};
`;

/**
 * Parses the kit param and gets the appropriate kit information
 *
 */
function SvgStage(props) {
  const {
    children,
    className,
    handleStageClick = () => {},
    handleStageDrag,
    handleStageMouseDown,
    handleStageMouseUp,
    handleStageDrop = () => {},
    handleAddPath = () => {},
    svgRef,
    backgroundColor,
    selectedTool,
    gridVisible,
    selectedElements,
    stageHeight,
    stageWidth,
    viewBox,
    setViewBox,
    style,
  } = props;
  const [initialPoint, setInitialPoint] = useState(null);
  const [isDragging, setIsDragging] = useState(false);

  let cursor = tools[selectedTool] && tools[selectedTool].cursor;
  if (isDragging && tools[selectedTool] && tools[selectedTool].cursorDrag)
    cursor = tools[selectedTool].cursorDrag;

  const altDown = useModifier('alt').isDown;
  useHotkeys([
    {
      // Undo
      name: '0',
      modifiers: ['cmd'],
      onPress: () => {
        setViewBox({
          x: 0,
          y: 0,
          w: stageWidth,
          h: stageHeight,
        });
      },
    },
  ]);

  const handleMouseMove = (e) => {
    if (isDragging) {
      handleStageDrag(svgRef.current, e);

      if (selectedTool === 'moveStage') {
        // Get svg point scale from bounding client rect for svg
        const { height } = svgRef.current.getBoundingClientRect();
        const scale = viewBox.h / height;

        const dx = e.movementX * scale;
        const dy = e.movementY * scale;

        const newViewBox = { ...viewBox };
        const { x, y } = newViewBox;

        setViewBox({
          ...newViewBox,
          x: x - dx,
          y: y - dy,
        });
      }

      if (selectedTool === 'pen' && initialPoint) {
        const coords = coordsUtil.getSvgCoords(svgRef.current, e);
        // Have to initialize a point before creating an element
        setInitialPoint({
          ...initialPoint,
          cpx: coords.x,
          cpy: coords.y,
        });
      }
    }
  };

  const handleMouseDown = (e) => {
    handleStageMouseDown(svgRef.current, e);
    setIsDragging(true);

    if (selectedTool === 'pen' && !selectedElements.length) {
      const coords = coordsUtil.getSvgCoords(svgRef.current, e);

      if (initialPoint) {
        handleAddPath(e, initialPoint);
        setInitialPoint(null);
      } else {
        setInitialPoint({
          x: coords.x,
          y: coords.y,
          cpx: coords.x,
          cpy: coords.y,
        });
      }
    }
  };

  const handleMouseUp = (e) => {
    handleStageMouseUp(svgRef.current, e);
    setIsDragging(false);
  };

  const handleZoom = (e) => {
    e.preventDefault();
    const coords = coordsUtil.getSvgCoords(svgRef.current, e);

    let multiplier = 1;
    if (altDown) multiplier = 2;

    let dx = e.deltaX * multiplier;
    let dy = e.deltaY * multiplier;

    // Use a fixed scroll delta if deltaMode isn't 0 (in pixels—only really an issue for Firefox)
    if (e.deltaMode !== 0) {
      const scrollDelta = 100;

      if (e.deltaX > 0) dx = scrollDelta;
      else if (e.deltaX < 0) dx = -scrollDelta;

      if (e.deltaY > 0) dy = scrollDelta;
      else if (e.deltaY < 0) dy = -scrollDelta;
    }

    let newViewBox = { ...viewBox };
    const { x, y, w, h } = newViewBox;

    if (altDown) {
      const scaleFactor = (newViewBox.h + dy) / newViewBox.h;
      const newHeight = h + dy;
      const newWidth = w + (stageWidth / stageHeight) * dy;

      if (newHeight > 0) {
        newViewBox = {
          x: x - (scaleFactor - 1) * (coords.x - x),
          y: y - (scaleFactor - 1) * (coords.y - y),
          w: newWidth,
          h: newHeight,
        };
      }
    } else {
      newViewBox = {
        ...viewBox,
        x: x + dx,
        y: y + dy,
      };
    }

    setViewBox(newViewBox);
  };

  useEffect(() => {
    setInitialPoint(null);
  }, [selectedTool]);

  return (
    <StyledSvg
      ref={svgRef}
      style={style}
      viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`}
      backgroundColor={backgroundColor}
      cursor={cursor}
      width="100%"
      height="100%"
      className={className}
      onClick={(e) => handleStageClick(svgRef.current, e)}
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onDrop={handleStageDrop}
      onWheel={handleZoom}
      onDragOver={(e) => e.preventDefault()}
      onDragLeave={(e) => e.preventDefault()}
      onDragEnter={(e) => e.preventDefault()}
      onScroll={(e) => e.stopPropagation()}
    >
      <Stage
        id="stage"
        x={0}
        y={0}
        height={stageHeight}
        width={stageWidth}
        fill="white"
      />

      {gridVisible && <SvgGrid width={stageWidth} height={stageHeight} />}

      {children}

      {initialPoint && (
        <InitialPath
          x={initialPoint.x}
          y={initialPoint.y}
          cpx={initialPoint.cpx}
          cpy={initialPoint.cpy}
        />
      )}
    </StyledSvg>
  );
}

const { bool, func, shape, string, instanceOf, arrayOf, number } = PropTypes;

SvgStage.propTypes = {
  handleAddPath: func,
  selectedTool: string.isRequired,
  selectedElements: arrayOf(ElementProps),
  svgRef: shape({
    current: instanceOf(Element),
  }).isRequired,
  viewBox: shape({
    width: number,
    height: number,
  }).isRequired,
  setViewBox: func.isRequired,
  stageHeight: number.isRequired,
  stageWidth: number.isRequired,

  className: string,
  children: ChildrenProps,
  backgroundColor: string,
  gridVisible: bool,
  handleStageClick: func,
  handleStageDrag: func,
  handleStageDrop: func,
  handleStageMouseDown: func,
  handleStageMouseUp: func,
  style: shape({}),
  isAnnotating: bool,
};

SvgStage.defaultProps = {
  className: '',
  backgroundColor: 'white',
  gridVisible: true,
  isAnnotating: false,
  style: {},
  handleStageClick: () => {},
  handleStageDrag: () => {},
  handleStageDrop: () => {},
  handleStageMouseDown: () => {},
  handleStageMouseUp: () => {},
};

export default SvgStage;
