import React from 'react';
import PropTypes from 'prop-types';
import { UserRow, Container, Subheader, UserName } from './MentionPickerStyles';

/**
 * A list of users that pops up when typing a name with the '@' symbol
 *
 * @param {Object} props
 * @returns
 */
function MentionPicker({ matchingOptions, onSelect, focusedIndex }) {
  const handleSelectOption = (e, user) => {
    e.stopPropagation();
    onSelect(user);
  };

  const matchingUserComponents = matchingOptions.map((user, index) => (
    <UserRow
      key={user.id}
      onClick={(e) => handleSelectOption(e, user)}
      hasFocus={focusedIndex === index}
    >
      <UserName>{user.name}</UserName>
      <Subheader>{user.subheader}</Subheader>
    </UserRow>
  ));

  return <Container>{matchingUserComponents}</Container>;
}

MentionPicker.propTypes = {
  focusedIndex: PropTypes.number.isRequired,
  matchingOptions: PropTypes.array,
  onSelect: PropTypes.func,
};

MentionPicker.defaultProps = {
  matchingOptions: [],
  onSelect: () => {},
};

export default MentionPicker;
