import React, { useState, useRef, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components/macro';

import undoStackUtil from '../../../app/editor/undoStackUtil';
import pathUtil from '../../../app/editor/path';

import PenPathEditor from '../path/PenPathEditor';
import NodePathEditor from '../path/NodePathEditor';
import { PathElementProps } from '../types/ElementProps';
import { ToolOptionProps } from '../tools';

const StyledPath = styled.path`
  ${(props: { strokeDasharray: number; strokeDashoffset: number }) =>
    props.strokeDasharray &&
    css`
      stroke-dasharray: ${props.strokeDasharray};
      stroke-dashoffset: ${props.strokeDashoffset};
    `}
`;

const PressurePath = styled.path`
  fill: blue;
  stroke: black;
  stroke-width: 1px;
  stroke-linecap: round;
`;

type Props = {
  element: PathElementProps;
  isSelected: boolean;
  selectedTool: ToolOptionProps;
  svgRef: any; // FIXME:
};

/**
 * A <path> element
 */
function Path(props: Props): JSX.Element {
  const { element, isSelected, selectedTool, svgRef } = props;

  // Manually update component
  const [, update] = useState({});
  const [pathLength, setPathLength] = useState<number>(0);

  const pathRef = useRef<SVGPathElement>(null);

  const hasFill =
    element.currentProps.style.fill &&
    element.currentProps.style.fill !== 'none';

  const clipPathD = useMemo(() => {
    // Do not use clipping path if the element has all pressure points >= 0.95 (~1)
    const hasPressure =
      element.currentProps.pressure &&
      !element.currentProps.pressure.every(
        (point: [number, number]) => Math.round(point[1] * 10) / 10 === 1
      );

    if (pathRef.current && !hasFill && hasPressure) {
      const { path } = pathUtil.getPressurePath(
        element.currentProps.d,
        element.currentProps.pressure,
        element.currentProps.style.strokeWidth
      );
      return path.getPathData();
    }

    return '';
    // eslint-disable-next-line
  }, [
    element.currentProps.d,
    element.currentProps.style.strokeWidth,
    element.currentProps.pressure,
    pathRef.current,
    hasFill,
    element.currentProps.style.fill,
  ]);

  const style = {
    // Override with initial props
    ...(element.props.style ? element.props.style : {}),
    // Override with current props
    ...(element.currentProps.style ? element.currentProps.style : {}),
  };

  const handlePenPathDown = (e: MouseEvent) => {
    if (isSelected && selectedTool === 'pen' && svgRef.current) {
      element.addPoint(svgRef.current, e);

      // Add to undo stack manually whenever new node is added (since we're only updating the Path)
      // eslint-disable-next-line import/no-named-as-default-member
      undoStackUtil.addElement(element);
    }
  };

  const handlePenPathMove = (e: MouseEvent) => {
    element.translateControlPoint(
      svgRef.current,
      e,
      element.parsedPath.length - 1,
      true
    );

    update({});
  };

  useEffect(() => {
    if (pathRef.current) setPathLength(pathRef.current.getTotalLength());
  }, [element.currentProps.d]);

  const strokeDashoffset = pathLength * (1 - element.currentProps.dashoffset);

  const clipPathId = `path-clip-${element.id}`;

  // Get clipping path if using pressure stroke
  let ClipPath = null;
  if (!hasFill) {
    if (element.clipPath) {
      // If the element has a clip path, use that
      const ClipPathTag = element.clipPath.type;
      ClipPath = (
        <clipPath id={clipPathId}>
          <ClipPathTag {...element.clipPath.attributes} />
        </clipPath>
      );
    } else if (clipPathD) {
      // Otherwise use built-in stroke pressure
      ClipPath = (
        <clipPath id={clipPathId}>
          <PressurePath d={clipPathD} />
        </clipPath>
      );
    }
  }

  return (
    <g>
      {ClipPath}
      <StyledPath
        strokeDasharray={pathLength}
        strokeDashoffset={strokeDashoffset}
        ref={pathRef}
        style={style}
        d={element.currentProps.d}
        clipPath={`url(#${clipPathId})`}
      />
      {selectedTool === 'pen' && isSelected && (
        <PenPathEditor
          d={element.currentProps.d}
          svgRef={svgRef}
          pathData={element.parsedPath}
          onMouseMove={handlePenPathMove}
          onMouseDown={handlePenPathDown}
          isNew={element.parsedPath.length === 2}
        />
      )}
      {selectedTool === 'node' && isSelected && (
        <NodePathEditor svgRef={svgRef} element={element} update={update} />
      )}
    </g>
  );
}

export default Path;
