import create from '../../../editor/create';
import getElementArgs from './getElementArgs';

export default (
  node,
  transform,
  startTime,
  duration,
  currentTime,
  hasAnimation
) => {
  const args = getElementArgs(
    node,
    {
      props: {
        ...transform,
      },
      duration,
      src: node.attributes.href,
    },
    hasAnimation
      ? {
          startTime,
          currentTime,
        }
      : {}
  );

  const newElement = create.image(...args);

  return newElement;
};
