import SvgElement from './SvgElement';
import textUtil from '../app/editor/text';

class TextElement extends SvgElement {
  constructor(args = {}) {
    super({
      ...args,
      type: 'text',
    });

    const { text = '' } = args;

    this.text = text;

    this.currentProps = {
      progress: 1, // Animation progress
      ...this.props,
      ...this.currentProps,
      style: {
        fontWeight: 400, // Default font weight
        lineHeight: 1.5,
        ...this.currentProps.style,
      },
    };

    this.easing = 'linear';
  }

  /**
   * Render the element's text as an animation
   *
   * @param {HTMLElement} el - a <text> element
   * @returns
   * @memberof TextElement
   */
  renderText() {
    return textUtil.render(this.text, {
      ...this.currentProps,
    });
  }
}

export default TextElement;
