import styled from 'styled-components/macro';

import React, { useEffect, useMemo, useState } from 'react';

import { Flex } from 'rebass';
import Box from '../../Box';
import Message from '../../Message';
import MediaItem from './MediaItem';

import { fade, lighten, colors } from '../../styles/colors';
import flattenElements from '../../../app/editor/util/flattenElements';
import { ImageElementProps } from '../types/ElementProps';
import { post } from '../../../util/fetchUtil';
import KitProps from '../../../types/KitProps';
import Button from '../../RebassButton';
import AddToKitDialog from './AddToKitDialog';
import FileProps from '../types/FileProps';
import Dialog from '../../Dialog';
import useModal from '../../../app/hooks/useModal';

export type KitFileProps = KitProps & {
  fileID: number;
  linkID: number;
};

const PreviewsContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`;

type Props = {
  elements: ImageElementProps[];
  handleUpdateElement: (elementIds: string[]) => void;
};

/**
 * List out the media items that can be added to the scene
 */
function MediaList(props: Props) {
  const { elements, handleUpdateElement } = props;

  const [kitFiles, setKitFiles] = useState<KitFileProps[]>([]);
  const [files, setFiles] = useState<FileProps[]>([]);
  const [allKits, setAllKits] = useState<KitProps[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const flattenedEls = useMemo(() => flattenElements(elements), [elements]);
  const imageElements = flattenedEls.filter(
    (el) => el.type === 'image'
  ) as ImageElementProps[];

  const addToKitDialog = useModal();

  const handleSelectItem = (elementId: string) => {
    const newSelectedItems = [...selectedItems];
    const index = newSelectedItems.findIndex((id) => id === elementId);
    if (index > -1) {
      newSelectedItems.splice(index, 1);
    } else {
      newSelectedItems.push(elementId);
    }

    setSelectedItems(newSelectedItems);
  };

  const getKitLinks = async () => {
    const res = await post('/assets/getAllFileKitLinks', {
      fileIDs: imageElements.map((image) => image.fileID),
    });

    setKitFiles(res.files);
  };

  const getKits = async () => {
    const res = await post('/assets/getKits');
    setAllKits(res.kits);
  };

  const getFiles = async () => {
    const res = await post('/files/getByIDs', {
      fileIDs: imageElements.map((e) => e.fileID),
    });

    setFiles(res.files);
  };

  const update = () => {
    getFiles();
    getKitLinks();
  };

  const init = async () => {
    getKits();
    getKitLinks();
  };

  useEffect(() => {
    getFiles();
    update();
    // eslint-disable-next-line
  }, [elements]);

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  const selectedFiles = files.filter((f) => {
    const element = imageElements.find((el) => el.fileID === f.fileID);
    return !!selectedItems.find((elId) => elId === element?.id);
  });

  return (
    <Box>
      <Dialog
        isVisible={addToKitDialog.isVisible}
        handleHideDialog={() => addToKitDialog.hide()}
      >
        <AddToKitDialog
          update={update}
          kitFiles={kitFiles}
          allKits={allKits}
          files={selectedFiles}
        />
      </Dialog>
      <Flex p="10px" justifyContent="space-between">
        <Box>
          {!!selectedItems.length && (
            <Button size="s" onClick={() => addToKitDialog.show()}>
              Manage kits
            </Button>
          )}
        </Box>
        <Box>
          {!selectedItems.length && (
            <Button
              size="s"
              variant="outline"
              color="light-3"
              onClick={() => setSelectedItems(elements.map((e) => e.id))}
            >
              Select all
            </Button>
          )}
          {!!selectedItems.length && (
            <Button
              size="s"
              variant="outline"
              color="light-3"
              onClick={() => setSelectedItems([])}
            >
              Deselect all
            </Button>
          )}
        </Box>
      </Flex>
      {!imageElements.length && (
        <Box style={{ padding: 15 }}>
          <Message
            style={{ width: '100%' }}
            color={fade('white', 60)}
            backgroundColor={lighten(colors.night, 10)}
          >
            No media files in scene
          </Message>
        </Box>
      )}
      <PreviewsContainer>
        {imageElements.map((image) => (
          <MediaItem
            key={image.id}
            image={image}
            handleSelectItem={handleSelectItem}
            selected={!!selectedItems.find((id) => id === image.id)}
            kits={kitFiles.filter((kit) => kit.fileID === image.fileID)}
            handleUpdateElement={handleUpdateElement}
          />
        ))}
      </PreviewsContainer>
    </Box>
  );
}

export default MediaList;
