import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useMemo } from 'react';

import Box from '../../Box';
import Message from '../../Message';
import MediaTextItem from './MediaTextItem';

import { fade, lighten, colors } from '../../styles/colors';
import ElementProps from '../props/ElementProps';
import flattenElements from '../../../app/editor/util/flattenElements';

const { arrayOf, func } = PropTypes;

const PreviewsContainer = styled(Box)``;

/**
 * List out the media items that can be added to the scene
 */
function MediaText(props) {
  const { elements, handleUpdateElement } = props;

  const flattenedEls = useMemo(() => flattenElements(elements), [elements]);
  const textEls = flattenedEls.filter((el) => el.type === 'text');

  return (
    <Box>
      {!textEls.length && (
        <Box style={{ padding: 15 }}>
          <Message
            style={{ width: '100%' }}
            color={fade('white', 60)}
            backgroundColor={lighten(colors.night, 10)}
          >
            No text in scene
          </Message>
        </Box>
      )}
      <PreviewsContainer>
        {textEls.map((textEl) => (
          <MediaTextItem
            key={textEl.id}
            element={textEl}
            handleUpdateElement={handleUpdateElement}
          />
        ))}
      </PreviewsContainer>
    </Box>
  );
}

MediaText.propTypes = {
  elements: arrayOf(ElementProps),
  handleUpdateElement: func,
};

export default MediaText;
