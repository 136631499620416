import React, { ReactNode } from 'react';

import { useState, useRef, useEffect } from 'react';

import { AnimatePresence } from 'framer-motion';

import { TextInput } from './Input';
import {
  Option,
  Container,
  StyledButton,
  OptionsText,
  OptionsContainer,
  OptionsIcon,
  Wrapper,
  Icon,
  Placeholder,
  Text,
  Header,
  CheckIcon,
  Options,
} from './OptionSelectorStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/pro-light-svg-icons';

type OptionProps = {
  name: string;
  id: string | number;
  component?: ReactNode;
  icon?: IconName;
  onClick?: (option: OptionProps, e: React.MouseEvent) => void;
};

type Props = {
  options: OptionProps[];
  onSelectOption?: (option: any) => void;
  buttonClasses?: string;
  header?: ReactNode;
  selectedOption?: OptionProps;
  hasAutocomplete?: boolean;
  autocompletePlaceholder?: string;
  placeholder?: string;
  error?: boolean;
  zindex?: number;
  children?: ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  enable?: boolean;
};

/**
 * Simple dropdown UI component
 *
 * @class OptionSelector
 * @extends {Component}
 * @prop {[Object]} options
 * [{
 *    id: {String}, // A string identifier for the option
 *    name: {String}, // A display name to show for the option
 * }]
 * @prop {React} children (optional) - if children are passed, dropdown will use it as the open / close button
 * @prop {String} placeholder (optional) - text to display when nothing is selected
 * @prop {String} header (optional) - text to display at the top of the popup when opened
 * @prop {String} buttonClasses (optional) - space-separated string of class names to add to the button
 * @prop {Boolean} hasAutocomplete [false] (optional) - when true, has an autocomplete component to search options
 * @prop {String} autocompletePlaceholder (optional) - placeholder to add to the autocomplete if hasAutocomplete
 */
function OptionSelector(props: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const [dropdownPosition, setDropdownPosition] = useState({
    left: '0',
    top: '0',
  });

  const {
    header,
    buttonClasses,
    options,
    selectedOption,
    hasAutocomplete,
    autocompletePlaceholder,
    placeholder,
    error,
    children,
    className,
    onSelectOption,
    onClick,
    zindex = 1,
    enable = true,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const originalDropdownPosition = useRef<DOMRect>();

  /**
   * Closes whenever user clicks outside
   *
   * @param {Event Object} e
   * @memberof Notifications
   */
  const handleClickOutside = (e: any) => {
    // FIX TYPE
    if (
      containerRef.current &&
      !containerRef?.current.contains(e.target as Node) // NOT SURE IF THIS IS LEGIT
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Register global click handler
    document.addEventListener('click', handleClickOutside);

    return () => {
      // Unregister global click handler
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Close the dropdown
  const handleScrollClose = (e: any) => {
    // FIX TYPE
    // If we're not scrolling in some child of the dropdown, close it
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Register global event listener to close the dropdown on scroll
    window.addEventListener('scroll', handleScrollClose, true);

    if (isOpen) {
      // Save original computed dropdown position
      if (!originalDropdownPosition.current)
        originalDropdownPosition.current = dropdownRef.current?.getBoundingClientRect();

      // Get dropdown measurements
      const dropdownRect = originalDropdownPosition.current;

      // Get container measurements
      const containerEl = containerRef.current;
      const containerRect = containerEl?.getBoundingClientRect();

      if (containerRect && dropdownRect) {
        const dropdownWidth = dropdownRect?.width;
        const dropdownLeft = dropdownRect?.left;
        const dropdownHeight = dropdownRect?.height;

        const containerX = containerRect?.x;
        const containerY = containerRect?.y;
        const containerHeight = containerRect?.height;

        // Margin to edge of window
        const margin = 30;

        // Set the initial left to align with the left edge of the option container
        let left = containerX;
        // Set the initial top as the bottom of the option container
        let top = containerY + containerHeight;

        // Off right side of window
        if (left + dropdownWidth > window.innerWidth) {
          left = window.innerWidth - dropdownWidth - dropdownLeft - margin;
        }

        // Off the bottom of the window
        if (top + dropdownHeight > window.innerHeight) {
          top = window.innerHeight - dropdownHeight - margin;
        }

        setDropdownPosition({ left: `${left}px`, top: `${top}px` });
      }
    }

    return () => {
      window.removeEventListener('scroll', handleScrollClose, true);
    };
  }, [isOpen]);

  // Pass option back to parent
  const handleSelectOption = (option: OptionProps) => {
    if (onSelectOption) onSelectOption(option);
    setIsOpen(!isOpen);
  };

  // Open the dropdown
  const handleClick = (e: React.MouseEvent) => {
    if (onClick) onClick(e);

    if (enable) {
      setIsOpen(!isOpen);
    }
  };

  const visibleOptions = options.filter((option) =>
    option.name.toLowerCase().includes(inputValue.toLowerCase())
  );

  const optionComponents = visibleOptions.map((option) => {
    if (option.component) {
      return option.component;
    }

    return (
      <Option
        key={option.id}
        onClick={(e) =>
          option.onClick
            ? option.onClick(option, e)
            : handleSelectOption(option)
        }
        title={option.name}
      >
        {option.icon && <OptionsIcon icon={['fad', option.icon]} />}
        <OptionsText>{option.name}</OptionsText>
        {option.id === (selectedOption && selectedOption.id) && (
          <CheckIcon icon={['fad', 'check']} />
        )}
      </Option>
    );
  });

  return (
    <Container
      className={className}
      ref={containerRef}
      onClick={(e) => e.stopPropagation()}
    >
      {children && <div onClick={handleClick}>{children}</div>}

      {!children && (
        <StyledButton
          box
          error={error}
          className={`box ${buttonClasses}`}
          onClick={handleClick}
        >
          <Wrapper>
            {selectedOption && selectedOption.name && (
              <Text error={error} title={selectedOption.name}>
                {selectedOption.name}
              </Text>
            )}
            {(!selectedOption || !selectedOption.id) && (
              <Placeholder error={error}>{placeholder}</Placeholder>
            )}
            <Icon error={error} isOpen={isOpen}>
              <FontAwesomeIcon icon={['far', 'chevron-down']} />
            </Icon>
          </Wrapper>
        </StyledButton>
      )}
      <AnimatePresence>
        {isOpen && (
          <OptionsContainer
            ref={dropdownRef}
            position={dropdownPosition}
            key="options"
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10, transition: { easing: 'easeOut' } }}
            zindex={zindex}
          >
            {header && <Header>{header}</Header>}
            {hasAutocomplete && (
              <TextInput
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => setInputValue(e.target.value)}
                value={inputValue}
                placeholder={autocompletePlaceholder || 'Filter options...'}
              />
            )}
            <Options>{optionComponents}</Options>
          </OptionsContainer>
        )}
      </AnimatePresence>
    </Container>
  );
}

export default OptionSelector;
