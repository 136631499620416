import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import moment from 'moment';

import { colors } from '../styles/colors';
import { H4, P } from '../styles/typography';

const Container = styled.div`
  padding: 10px;
`;

const InfoSection = styled.div`
  margin-bottom: 15px;
`;

const InfoHeader = styled(H4)`
  color: ${colors['light-grey-30']};
`;

const Info = styled(P)``;

function FileItemInfo(props) {
  const { file } = props;

  const filename = file.name;
  const createdOn = moment(file.createdOn).format('hh:mma MM/DD/YYYY');
  const uploader = file.data.user && file.data.user.name;

  return (
    <Container>
      <InfoSection>
        <InfoHeader>Filename</InfoHeader>
        <Info>{filename}</Info>
      </InfoSection>
      <InfoSection>
        <InfoHeader>Uploaded</InfoHeader>
        <Info>{createdOn}</Info>
      </InfoSection>
      <InfoSection>
        <InfoHeader>Uploader</InfoHeader>
        <Info>{uploader}</Info>
      </InfoSection>
    </Container>
  );
}

FileItemInfo.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string,
    createdOn: PropTypes.string,
    data: PropTypes.shape({
      user: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  }),
};

export default FileItemInfo;
