import React from 'react';
import { isGroupElement, ElementProps } from '../types/ElementProps';

type Props = {
  childElements: ElementProps[];
  Child: any; // FIXME
};

/**
 * A <g> component representing a group of elements
 */
const Group: React.FC<Props> = (props: Props) => {
  // NOTE: the Child.jsx component is passed through props because
  // otherwise it isn't included if required is used since both Group.jsx and Child.jsx require each other
  const { childElements, Child } = props;

  const childrenComponents = childElements.map((el) => {
    const ref = el.ref || el;
    // Recursively add children
    return (
      <Child
        {...props}
        element={ref}
        childElements={isGroupElement(el) ? el.elements : []}
        key={ref.id}
      />
    );
  });

  return <g>{childrenComponents}</g>;
};

export default Group;
