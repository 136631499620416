import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import editorColors from '../../../app/data/editorColors';
import { style as styleStore } from '../../../app/editor/preferenceStore';

import Box, { Flex } from '../../Box';
import NumberInput from '../../NumberInput';
import { P } from '../../styles/typography';
import ColorPicker from '../../ColorPicker';

import { PropertyContainer, Unit } from './PropertyStyles';

import ElementProps from '../props/ElementProps';

/**
 * Properties specific to rect elements
 */
function RectProperties(props) {
  const { element, handleUpdateElement } = props;

  const { style } = element.currentProps;

  const updateCurrentProps = (key, value) => {
    element.update('currentProps', {
      ...element.currentProps,
      [key]: value,
    });

    handleUpdateElement([element.id]);
  };

  const handleUpdateStyle = (key, newVal) => {
    updateCurrentProps('style', {
      ...element.currentProps.style,
      [key]: newVal,
    });

    // Save pref to store
    styleStore[key] = newVal;
  };

  const handleColorSave = (colorObj, key) => {
    handleUpdateStyle(key, colorObj.toHEXA().toString());
  };

  return (
    <div style={{ height: '100%' }}>
      <Box>
        <PropertyContainer display="flex">
          <Label style={{ marginRight: 10 }}>Stroke width</Label>
          <NumberInput
            min={0}
            step={1}
            value={style.strokeWidth}
            onChange={(newVal) => handleUpdateStyle('strokeWidth', newVal)}
            style={{ width: '50px', fontSize: 14, padding: '.5em' }}
          />
          <Unit>px</Unit>
        </PropertyContainer>
        <PropertyContainer display="flex">
          <Label style={{ marginRight: 10 }}>Corner radius</Label>
          <NumberInput
            min={0}
            step={1}
            value={style.rx}
            onChange={(newVal) => handleUpdateStyle('rx', newVal)}
            style={{ width: '50px', fontSize: 14, padding: '.5em' }}
          />
          <Unit>px</Unit>
        </PropertyContainer>
        <Flex style={{ justifyContent: 'space-between' }}>
          <PropertyContainer display="flex">
            <Label style={{ marginRight: 10 }}>Fill</Label>
            <ColorPicker
              swatches={editorColors}
              initialColor={element.currentProps.style.fill}
              onColorSelect={(colorObj) => handleColorSave(colorObj, 'fill')}
              saveOnSelect={true}
            />
          </PropertyContainer>
          <PropertyContainer display="flex">
            <Label style={{ marginRight: 10 }}>Stroke</Label>
            <ColorPicker
              swatches={editorColors}
              initialColor={element.currentProps.style.stroke}
              onColorSelect={(colorObj) => handleColorSave(colorObj, 'stroke')}
              saveOnSelect={true}
            />
          </PropertyContainer>
        </Flex>
      </Box>
    </div>
  );
}

const Label = styled(P)``;

const { func } = PropTypes;

RectProperties.propTypes = {
  element: ElementProps.isRequired,
  handleUpdateElement: func.isRequired,
  addKeyframe: func.isRequired,
};

export default RectProperties;
