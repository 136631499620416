import React from 'react';
import { Container, SchemaHighlight, SchemaHeader, PSpan  } from './FileNamingStyles';

function SvgNaming() {
  return (
    <Container>
      <div>
        <p>All other files should be formatted with the project name, followed by the type ("o", "r", or "u"), followed by the timestamp (YYYYMMDD)</p>
        <SchemaHighlight>[NAME]_[TYPE]_[TIMESTAMP].[EXTENSION]</SchemaHighlight>
        <SchemaHeader>Examples</SchemaHeader>
        <div><SchemaHighlight>Renal_Clearance_o_20191029.mp3</SchemaHighlight><PSpan> - An original voiceover file for Renal Clearance, uploaded on 10-29-19</PSpan></div>
        <div><SchemaHighlight>Renal_Clearance_r_20191030.afdesign</SchemaHighlight><PSpan> - A revised Affinity Designer file for Renal Clearance, uploaded on 10-30-19</PSpan></div>
        <div><SchemaHighlight>Renal_Clearance_u_20191031.zip</SchemaHighlight><PSpan> - An updated Camtasia file (zipped) uploaded on 10-31-19</PSpan></div>
      </div>
    </Container>
  );
}

export default SvgNaming;

