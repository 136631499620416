import { useState, useRef, useEffect } from 'react';
import videojs from 'video.js';

const findChild = (player, id, parentId = 'name_') => {
  let targetChild = null;
  const searchChild = (parent) => {
    if (typeof parent.children === 'function') {
      const target = parent.children().find((c) => c[parentId] === id);
      if (!target) {
        parent.children().forEach((c) => searchChild(c));
      } else {
        targetChild = target;
      }
    }
  };

  searchChild(player);

  return targetChild;
};

/**
 *
 *
 * @param {Object} {
 *   src: {String}, // Path to video file
 *   controls: {Boolean}, // Show controls or not
 *   autoplay: {Boolean}, // Autoplay video or not
 *   events: {
 *     eventname: {Function} // Object of event handlers with event name keys,
 *                              e.g. 'dataloaded: () => {}'
 *   },
 *   options = {Object}, // Additional Videojs options
 *   components = [{
 *      name: {String}, // Name of the custom component (e.g. 'CommentBar')
 *      parent: {String}, // Name of the parent component to append to (e.g. 'SeekBar')
 *      id: {String}, // Unique identifier (different from the native vjs component id)
 *      args: {Object}, // Arguments to supply the React component
 *   }],
 * }
 */
const usePlayer = ({
  src,
  type = 'mp4',
  controls = true,
  autoplay = true,
  events = {},
  options = {},
  components = [],
}) => {
  const videoRef = useRef(null);
  const [player, setPlayer] = useState(null);

  /**
   * Searches for the passed components and
   * appends any custom components
   *
   * @param {Object} vjsPlayer - video.js player object
   */
  const addComponents = (vjsPlayer) => {
    // Add custom components
    components.forEach((component) => {
      const target = findChild(vjsPlayer, component.parent, 'name_');

      if (target) {
        const newComponent = target.addChild(component.name, component.args);
        // Set custom componentId
        newComponent.componentId = component.id;
      }
    });
  };

  /**
   * Initializes the video js object
   */
  useEffect(() => {
    const vjsPlayer = videojs(videoRef.current, {
      ...options,
      controls,
      autoplay,
      sources: [{ src, type: `video/${type}` }],
    });

    // Add any passed event listeners
    Object.keys(events).forEach((eventName) => {
      vjsPlayer.on(eventName, events[eventName]);
    });

    // Add custom components via `components` prop
    addComponents(vjsPlayer);

    // Save the player to state
    setPlayer(vjsPlayer);

    // Dispose of player on unmount
    return () => {
      if (player !== null) {
        player.dispose();
      }
    };
    // eslint-disable-next-line
  }, []);

  // Update the player source when it changes
  useEffect(() => {
    if (player !== null) {
      player.src({ src });
    }
    // eslint-disable-next-line
  }, [src]);

  // Update the custom components when they change
  useEffect(() => {
    // if (player) updateComponents(player);
    if (player) {
      components.forEach((component) => {
        const comp = findChild(player, component.id, 'componentId');
        if (comp) {
          comp.render(component.args);
        }
      });
    }
    // eslint-disable-next-line
  }, [components]);

  return [videoRef, player];
};

export default usePlayer;
