import SceneObject from './SceneObject';
import drawCurves from '../app/draw/curves';

class PathSvg extends SceneObject {
  constructor(args) {
    super(args);
    // Create the svg element
    this.svg = drawCurves(
      this.scene,
      this.animation,
      this.id,
      this.dimensions,
      args.onError
    );
    this.anim = this.svg.anim;
    this.name = this.svg.name;
    this.type = this.svg.type;
    this.bbox = this.svg.bbox;
  }
}

export default PathSvg;
