import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useContext } from 'react';

import fetchUtil from '../../util/fetchUtil';

import { Store } from '../../state/store';
import { fetchActiveProject } from '../../state/projects/actions';

import UploadBox from '../UploadBox';

import Button from '../Button';

import { HeaderSection, SectionTitle } from '../views/ProjectContentsStyles';

import { colors } from '../styles/colors';

function ThumbnailSection(props) {
  const {
    downloadFile,
    activeProject: { files, projectID },
  } = props;

  const { dispatch } = useContext(Store);

  const thumbnail =
    files && files.find((file) => file.data.projectAssetType === 'thumbnail');

  const handleOnBeforeUpload = () =>
    new Promise((resolve) => {
      if (thumbnail) {
        fetchUtil
          .post('/project/removeFiles', {
            projectID,
            fileIDs: [thumbnail.fileID],
          })
          .then(() => resolve());
      } else {
        resolve();
      }
    });

  const handleOnAfterUpload = () =>
    new Promise((resolve) => {
      resolve();
    });

  const handleDownloadThumbnail = () => {
    downloadFile(thumbnail);
  };

  /**
   * Removes the thumbnail
   *
   * @memberof ProjectContents
   */
  const removeThumbnail = () => {
    if (thumbnail) {
      fetchUtil
        .post('/project/removeFiles', {
          fileIDs: [thumbnail.fileID],
          projectID,
        })
        .then(() => fetchActiveProject(projectID, dispatch));
    }
  };

  return (
    <Container>
      <HeaderSection>
        <SectionTitle>Thumbnail</SectionTitle>
        {thumbnail && (
          <ThumbnailButtons>
            <Button onClick={handleDownloadThumbnail} thin icon box>
              <FontAwesomeIcon icon={['fad', 'file-download']} />
            </Button>
            <Button thin icon box onClick={removeThumbnail}>
              <TrashIcon icon={['fad', 'trash-alt']} />
            </Button>
          </ThumbnailButtons>
        )}
      </HeaderSection>
      {thumbnail && (
        <ThumbnailContainer>
          <ThumbnailImage>
            <img src={thumbnail.data.url} alt="Thumbnail" />
          </ThumbnailImage>
        </ThumbnailContainer>
      )}
      <UploadBox
        projectID={projectID}
        types={['jpg', 'jpeg']}
        fileData={{ projectAssetType: 'thumbnail' }}
        onBeforeUpload={handleOnBeforeUpload}
        onAfterUpload={handleOnAfterUpload}
      />
    </Container>
  );
}

const Container = styled.div`
  max-width: 300px;
`;

const ThumbnailButtons = styled.div`
  display: flex;

  button.button:not(:last-of-type) {
    margin-right: 5px;
  }
`;

const ThumbnailContainer = styled.div`
  overflow: hidden;
  border-radius: 4px;
  position: relative;

  margin-bottom: 10px;

  /* cursor: pointer; */
`;

const ThumbnailImage = styled.div`
  img {
    width: 100%;
  }
`;

const TrashIcon = styled(FontAwesomeIcon)`
  color: ${colors.red};
`;

ThumbnailSection.propTypes = {
  downloadFile: PropTypes.func.isRequired,
  activeProject: PropTypes.shape({
    files: PropTypes.array,
    projectID: PropTypes.number,
  }),
};

ThumbnailSection.defaultProps = {
  activeProject: {},
};

export default ThumbnailSection;
