import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import React, { useState, useContext, useRef } from 'react';

import { post } from '../../util/fetchUtil';

import { Store } from '../../state/store';
import { fetchActiveProject } from '../../state/projects/actions';

import fileManager from '../../app/fileManager';

import UploadBox from '../UploadBox';
import Button from '../Button';
import OptionSelector from '../OptionSelector';
import DownloadSnackbar from '../DownloadSnackbar';

import { Option, OptionsText } from '../OptionSelectorStyles';
import { P } from '../styles/typography';

import FileRow from './FileRow';

import { colors, lighten, getColorCombination } from '../styles/colors';

import { HeaderSection, SectionTitle } from '../views/ProjectContentsStyles';
import WarningDialog from '../dialogs/WarningDialog';
import useModal from '../../app/hooks/useModal';
import Dialog from '../Dialog';

function FilesList(props) {
  const {
    files,
    sectionTitle,
    uploader,
    downloadFile,
    projectID,
    color,
    selectFiles,
    message,
    downloadError,
  } = props;

  const { dispatch } = useContext(Store);
  const [downloadingFiles, setDownloadingFiles] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const filesToDelete = useRef();

  const onProgress = (progress) => {
    const { received, total } = progress;
    const amount = received / total;

    if (amount > 0) setDownloadingFiles(true);
    if (amount === 1) setDownloadingFiles(false);
    setDownloadProgress(amount);
  };

  const handleSelectFiles = (file) => {
    selectFiles([file]);
  };

  const handleDownloadAll = () => {
    setDownloadingFiles('Downloading files...');

    fileManager.downloadZipFile(files).then(() => {
      setDownloadingFiles(false);
    });
  };

  const handleDeleteAll = () => {
    const fileIDs = files.map((file) => file.fileID);
    post('/project/removeFiles', {
      projectID,
      fileIDs,
    }).then(() => fetchActiveProject(projectID, dispatch));
  };

  const confirmDeleteAllDialog = useModal();

  const handleDeleteFile = async () => {
    if (filesToDelete.current) {
      await post('/project/removeFiles', {
        projectID,
        fileIDs: [filesToDelete.current[0].fileID],
      }).then(() => fetchActiveProject(projectID, dispatch));
    }

    filesToDelete.current = null;
  };

  const confirmDeleteOneDialog = useModal();

  const handleConfirmDelete = (file) => {
    filesToDelete.current = [file];
    confirmDeleteOneDialog.show();
  };

  const allFileOptions = [
    {
      key: 'download',
      id: 'downloadAll',
      name: 'Download All',
      component: (
        <Option onClick={handleDownloadAll} key="download">
          <OptionsText>
            <FontAwesomeIcon icon={['fad', 'download']} />
            &nbsp;&nbsp; Download all
          </OptionsText>
        </Option>
      ),
    },
    {
      key: 'delete',
      id: 'deleteAll',
      name: 'Delete All',
      component: (
        <Option onClick={() => confirmDeleteAllDialog.show()} key="delete">
          <OptionsText>
            <FontAwesomeIcon
              icon={['fad', 'trash-alt']}
              style={{ color: colors.red }}
            />
            &nbsp;&nbsp;
            <span style={{ color: colors.red }}>Delete all</span>
          </OptionsText>
        </Option>
      ),
    },
  ];

  const rows = files.map((file) => (
    <FileRow
      key={file.fileID}
      file={file}
      onProgress={onProgress}
      handleDeleteFile={handleConfirmDelete}
      downloadFile={downloadFile}
      onClick={() => handleSelectFiles(file)}
    />
  ));

  return (
    <Container>
      <Dialog
        isVisible={confirmDeleteAllDialog.isVisible}
        handleHideDialog={() => confirmDeleteAllDialog.hide()}
      >
        <WarningDialog
          color={colors.red}
          header={`Are you sure you want to delete ${files.length} files?`}
          onConfirm={handleDeleteAll}
        />
      </Dialog>
      <Dialog
        isVisible={confirmDeleteOneDialog.isVisible}
        handleHideDialog={() => confirmDeleteOneDialog.hide()}
      >
        <WarningDialog
          color={colors.red}
          header={`Are you sure you want to delete this file?`}
          onConfirm={handleDeleteFile}
          onCancel={() => {
            filesToDelete.current = null;
          }}
        />
      </Dialog>
      <DownloadSnackbar
        downloadError={downloadError}
        isVisible={!!downloadingFiles}
        progress={downloadProgress}
      />
      <FilesHeader>
        <div>
          <StyledSectionTitle color={color}>{sectionTitle}</StyledSectionTitle>
          <FileTypes>
            {uploader &&
              uploader.types &&
              uploader.types.map((type, i) => (
                <FileType key={type}>
                  {type}
                  {i !== uploader.types.length - 1 && ','}
                </FileType>
              ))}
          </FileTypes>
        </div>
        {!!files.length && (
          <OptionSelector options={allFileOptions}>
            <Button className="icon transparent">
              <FontAwesomeIcon icon={['fas', 'ellipsis-v']} />
            </Button>
          </OptionSelector>
        )}
      </FilesHeader>

      <FilesListContainer>{rows}</FilesListContainer>

      {files.length === 0 && <EmptyMessage>No files uploaded yet</EmptyMessage>}

      {message}

      {uploader && (
        <UploadBoxContainer>
          <UploadBox
            projectID={projectID}
            types={uploader.types}
            fileData={uploader.fileData}
            allowMultiple={uploader.allowMultiple}
          />
        </UploadBoxContainer>
      )}
    </Container>
  );
}

const Container = styled.div``;
const UploadBoxContainer = styled.div`
  margin-top: 10px;
`;

const EmptyMessage = styled.div`
  padding: 10px 15px;
  background: ${lighten(colors.grey, 55)};
  border-radius: 5px;
  color: ${lighten(colors.grey, 30)};

  margin-bottom: 10px;
`;

const StyledSectionTitle = styled(SectionTitle)`
  font-size: 16px;
  display: inline-block;
  margin-bottom: 5px;

  ${(props) =>
    props.color &&
    css`
      padding: 3px 6px;
      border-radius: 3px;
      color: ${getColorCombination(props.color).color};
      background: ${getColorCombination(props.color).background};
    `}
`;

const FilesListContainer = styled.div`
  display: flex;
  flex-direction: column;

  max-height: 300px;
  overflow: auto;
  border-radius: 3px;
  background: ${lighten(colors.grey, 55)};
`;

const FileTypes = styled.div`
  display: flex;
`;

const FileType = styled(P)`
  color: ${colors['light-grey-30']};
  margin-right: 5px;
  font-style: italic;
`;

const FilesHeader = styled(HeaderSection)`
  margin-bottom: 10px;

  display: flex;
  align-items: flex-start;
`;

FilesList.propTypes = {
  files: PropTypes.array,
  sectionTitle: PropTypes.string,
  uploader: PropTypes.shape({
    fileData: PropTypes.shape({}),
    allowMultiple: PropTypes.bool,
    types: PropTypes.array,
  }),
  projectID: PropTypes.number,
  downloadFile: PropTypes.func.isRequired,
  color: PropTypes.string,
  message: PropTypes.element,
  selectFiles: PropTypes.func.isRequired,
  downloadError: PropTypes.bool,
};

FilesList.defaultProps = {
  projectID: null,
  files: [],
  sectionTitle: '',
  uploader: null,
  color: '',
  message: null,
  downloadError: false,
};

export default FilesList;
