import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

function PlayAllIcon() {
  const styles = {
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    strokeLinejoin: 'round',
    strokeMiterlimit: 2,
  };

  return (
    <Container>
      <svg width="100%" height="100%" viewBox="0 0 339 294" version="1.1" xmlns="http://www.w3.org/2000/svg" style={styles}><path d="M202.701,145.28c2.314,0.187 4.539,0.801 6.626,1.833l120.664,63.79c0.679,0.383 0.857,0.465 1.503,0.903c7.217,4.895 9.166,16.095 3.566,23.171c-1.089,1.376 -2.404,2.569 -3.879,3.52c-0.737,0.475 -1.522,0.867 -2.318,1.237l-120.664,52.169c-0.706,0.283 -0.878,0.369 -1.605,0.594c-8.865,2.754 -19.609,-3.845 -20.653,-13.571c-0.082,-0.757 -0.072,-0.949 -0.092,-1.709l0,-115.959c0.021,-0.77 0.011,-0.964 0.094,-1.729c0.775,-7.133 6.85,-13.375 14.163,-14.176c1.668,-0.183 2.061,-0.087 2.595,-0.073Zm-61.967,56.813l-120.736,0c-10.34,-0.254 -19.483,-8.97 -19.976,-19.019c-0.512,-10.406 8.097,-20.422 18.994,-20.957c0.327,-0.016 0.654,-0.016 0.982,-0.024l120.736,0c0.327,0.008 0.654,0.016 0.981,0.024c7.934,0.585 15.224,5.899 17.85,13.238c4.137,11.563 -4.773,26.071 -17.85,26.714c-0.326,0.016 -0.653,0.016 -0.981,0.024Zm80.38,-80.405l-201.116,0c-10.196,-0.321 -19.319,-8.547 -19.961,-18.744c-0.628,-9.975 7.315,-19.818 17.454,-21.099c1.109,-0.14 1.391,-0.122 2.507,-0.157l201.116,0c0.418,0.013 0.837,0.026 1.255,0.039c11,1.04 19.976,11.47 18.587,22.467c-1.101,8.717 -8.566,16.228 -17.336,17.336c-1.108,0.14 -1.391,0.123 -2.506,0.158Zm0.593,-81.688l-201.71,0c-8.08,-0.254 -15.658,-5.218 -18.595,-12.638c-4.446,-11.229 3.643,-25.632 16.089,-27.204c1.109,-0.14 1.391,-0.123 2.506,-0.158l201.71,0c0.419,0.013 0.838,0.026 1.256,0.039c11.041,1.044 19.969,11.529 18.587,22.468c-1.102,8.717 -8.566,16.227 -17.336,17.335c-1.109,0.14 -1.391,0.123 -2.507,0.158Z"/></svg>
    </Container>
  );
}

const Container = styled.div`
  width: 22px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

PlayAllIcon.propTypes = {
  propName: PropTypes.string,
};

PlayAllIcon.defaultProps = {
  propName: '',
};

export default PlayAllIcon;
