import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import fetchUtil from '../../util/fetchUtil';

import KitSettings from './KitSettings';

/**
 * Parses the kit param and gets the appropriate kit information
 *
 */
function KitSettingsWrapper() {
  const [kit, setKit] = useState();

  const history = useHistory();
  const kitIDParam = useParams().kit;

  const getKit = (kitID) =>
    fetchUtil
      .post('/assets/getKit', {
        kitID,
      })
      .then((res) => {
        if (res.kit) {
          setKit(res.kit);
        } else {
          // Route back to assets page
          history.push('/assets');
        }
      });

  // On start, check param and if it's an number, check if the kit exists,
  // If it's "all", return all items
  // Otherwise route back to /assets
  useEffect(() => {
    if (!Number.isNaN(parseInt(kitIDParam, 10))) {
      getKit(kitIDParam);
    } else {
      // Route back to assets page
      history.push('/assets');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ height: '100%' }}>
      {kit && <KitSettings kit={kit} getSettings={getKit} />}
    </div>
  );
}

KitSettingsWrapper.propTypes = {};

export default KitSettingsWrapper;
