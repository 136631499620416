import $ from 'jquery';

import nodeSearch from './nodeSearch';

import PathSvg from '../object/PathSvg';
import SvgImage from '../object/SvgImage';
import EraseSvg from '../object/EraseSvg';
import TextSvg from '../object/TextSvg';

/**
 * Controls all logic for scene, including loading and creating scene
 * objects.
 *
 * @class sceneController
 */
class SceneLoader {
  constructor() {
    this.objects = [];

    this.loaded = false;

    this.isLoading = false;

    this.textCount = 0;

    const config = {
      zIndexCount: 0,
      animIndexCount: 0,
      svgIdCount: 0,
      textSvgIdCount: 0,
      svgImageIdCount: 0,
      eraseIdCount: 0,
    };

    const keys = Object.keys(config);

    for (let i = 0; i < keys.length; i += 1) {
      this[keys[i]] = config[keys[i]];
    }
  }
}

/**
 * Loads the path elements and images from an external SVG
 *
 * @param {*} file [object] - native file object
 * @return promise that resolves after loading is complete
 */
SceneLoader.prototype.load = async function (file, options = {}) {
  const { onError = () => {}, statsOnly = false } = options;

  const errors = [];

  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;

  // Data to accumulate during load
  this.sceneData = {
    misspelledWords: [],
    charactersLoaded: 0,
  };

  return new Promise((resolve) => {
    if (this.isLoading) {
      console.log('Already loading');
      return;
    }

    // Set isLoading
    this.isLoading = true;

    // Clear all items from variables and DOM
    if (!statsOnly) this.clearAll();

    if (!file) {
      alert('No files loaded');
      return;
    }

    // Check if file's been loaded yet
    if (file) {
      // Create new FileReader object for reading selected file
      const reader = new FileReader();
      // Create new Parser object for parsing selected file
      const parser = new DOMParser();

      // Load scene components once file's been read
      reader.addEventListener('load', () => {
        // Parse file from string to SVG document object
        const doc = parser.parseFromString(reader.result, 'image/svg+xml');

        // Set dimensions of svg
        const $svg = $(doc).find('svg');
        const [, , w, h] = $svg.attr('viewBox').split(' ');
        this.sceneDimensions = {
          w: parseInt(w, 10),
          h: parseInt(h, 10),
        };

        // Save the scene dimensions to the data
        this.sceneData.dimensions = this.sceneDimensions;

        // Find Scene & Animation groups
        const $scene = $(doc).find('#Scene');
        const animation = $(doc).find('#Animation');

        // Check for amount of text to render
        const maxCharacters = 1500;
        const totalCharacters = $('text', $scene)
          .toArray()
          .reduce((total, text) => total + text.textContent.length, 0);

        // Throw error message if too much text
        console.log(`Total characters: ${totalCharacters}`);
        if (totalCharacters > maxCharacters) {
          errors.push(
            `There's too much text to render (${totalCharacters} characters) smoothly! The maximum is ${maxCharacters}, consider cutting the scene into two parts.`
          );

          onError(errors);

          this.isLoading = false;

          return resolve({
            file,
            ...this.sceneData,
            objects: this.objects,
            errors,
          });
        }

        // Continue only if 'Scene' and 'Animation' exist and they have an equal number of children
        if (
          $scene.length !== 0 &&
          animation.length !== 0 &&
          animation.children().length === $scene.children().length
        ) {
          // Function to parse children
          const parseLayers = function (sceneChild, animationChild) {
            // -------- ERASE ELEMENT -------- //
            if (sceneChild.id.substring(0, 5).toLowerCase() === 'erase') {
              self.createEraseSvg(sceneChild, doc, options);
            } else if (sceneChild.id.substring(0, 4).toLowerCase() === 'sync') {
              // -------- SYNC ELEMENT -------- //
              // Save current animIndex
              const syncedAnimIndex = self.animIndexCount;
              for (let i = 0; i < sceneChild.children.length; i += 1) {
                parseLayers(sceneChild.children[i], animationChild.children[i]);
                // If this was the last child, increment animIndexCount
                // Else retain the same animIndex for the remaining children
                if (i === sceneChild.children.length - 1) {
                  self.animIndexCount += 1;
                } else {
                  self.animIndexCount = syncedAnimIndex;
                }
              }
            } else if (sceneChild.id.substring(0, 4).toLowerCase() === 'move') {
              // -------- MOVE ELEMENT -------- //
              // Save copy of motionPath
              const motionPath = nodeSearch.getChildById(sceneChild, 'motion');

              if (motionPath) {
                // Remove motionPath from DOM
                sceneChild.removeChild(motionPath);

                self.createSvgImage(
                  {
                    sceneChild,
                    motionPath,
                  },
                  doc,
                  options
                );
              } else {
                errors.push(
                  'Couldn\'t find a path tagged with "motion" to follow. Double-check your "move" groups.'
                );
              }
            } else if (
              sceneChild.id.substring(0, 5).toLowerCase() === 'image'
            ) {
              // -------- IMAGE ELEMENT -------- //
              self.createSvgImage({ sceneChild }, doc, options);
            } else if (sceneChild.nodeName === 'text') {
              // -------- TEXT ELEMENT -------- //
              self.createTextSvg(sceneChild, errors, options);
            } else if (sceneChild.nodeName === 'g') {
              // -------- GROUP ELEMENT -------- //
              if (sceneChild.getAttribute('clip-path')) {
                // If it's a <g> with a clipping path attribute
                self.createSvgImage({ sceneChild }, doc, options);
              } else if (nodeSearch.hasChild(sceneChild, 'text')) {
                // Check if it's a group of text to write
                self.createTextSvg(sceneChild, errors, options);
              } else {
                // Otherwise it's a group of paths to draw
                self.createPathSvg(sceneChild, animationChild, errors, options);
              }
            } else if (sceneChild.nodeName === 'path') {
              // -------- CURVE ELEMENT -------- //
              // Create an animation path group
              self.createPathSvg(sceneChild, animationChild, errors, options);
            } else if (sceneChild.nodeName === 'use') {
              // -------- PIXEL ELEMENT -------- //
              self.createSvgImage({ sceneChild }, doc, options);
            }
          };

          for (let i = 0; i < $scene.children().length; i += 1) {
            parseLayers($scene.children()[i], animation.children()[i]);
          }

          // Enable 'Play All' and 'Reset All' buttons
          self.loaded = true;

          // Toggle loading
          self.isLoading = false;

          // Resolve promise and return the file that was loaded
          resolve({ file, ...this.sceneData, objects: this.objects, errors });
        } else if ($scene.length === 0 || animation.length === 0) {
          // ------ Error handling ------ //
          errors.push(
            `Couldn't find Scene and/or Animation group(s), Make sure to create a "Scene" group and an "Animation" group.`
          );
        } else if (animation.children().length !== $scene.children().length) {
          let fill = false;
          let fillWithoutStroke = false;
          let layer = null;
          for (let j = 0; j < animation.children().length; j += 1) {
            const child = animation.children()[j];
            // Check to see if any of the children had a "fill" attribute
            if (
              child.style.fill.substring(0, 3) === 'rgb' &&
              child.nodeName !== 'text'
            ) {
              fill = true;
              layer = j - 1;
              break;
            }
          }

          for (let j = 0; j < $scene.children().length; j += 1) {
            const child = $scene.children()[j];
            // Handle instances where a curve has a fill (should only have a stroke)
            if (child.style.fill === 'none' && !child.style['stroke-width']) {
              fillWithoutStroke = true;
              layer = j - 1;
              break;
            }
          }

          if (fill) {
            errors.push(
              `There's a problem around layer ${layer}. If it's a curve layer with the "image" tag, make sure it's grouped.`
            );
          } else if (fillWithoutStroke) {
            errors.push(
              `There's a problem around layer ${layer}. It might be a curve that has both a stroke and a fill, curves should not have a fill.`
            );
          } else {
            errors.push(
              'The number of scene elements was different from the number of animation elements!'
            );
          }
        } else {
          errors.push('Something went wrong with importing.');
        }

        if (errors.length) {
          onError(errors);
        }

        self.isLoading = false;

        return resolve({
          file,
          ...this.sceneData,
          objects: this.objects,
          errors,
        });
      });

      // Read file
      if (file) {
        reader.readAsText(file);
      }
    }
  });
};

/* Increments the total SVG count and adds a new entry (without) the SVG object */
SceneLoader.prototype.createEraseSvg = function (
  sceneGroup,
  doc,
  options = {}
) {
  const { statsOnly = false } = options;

  this.eraseIdCount += 1;

  if (!statsOnly) {
    const newEraseSvg = new EraseSvg({
      parent: this,
      id: `eraseSvg-${this.eraseIdCount.toString()}`,
      uiId: `ui-eraseSvg-${this.eraseIdCount.toString()}`,
      type: '',
      zIndex: this.zIndexCount,
      animIndex: this.animIndexCount,
      scene: sceneGroup,
      doc,
      container: $('#scene'),
      dimensions: this.sceneDimensions,
      sceneData: this.sceneData,
    });
    this.objects.push(newEraseSvg);
  }

  this.zIndexCount += 1;
  this.animIndexCount += 1;
};

/* Increments the total SVG count and adds a new entry (without) the SVG object */
SceneLoader.prototype.createTextSvg = function (
  textElement,
  errors = [],
  options = {}
) {
  const { statsOnly = false } = options;

  this.textSvgIdCount += 1;

  if (!statsOnly) {
    const newTextSvg = new TextSvg({
      parent: this,
      id: `textSvg-${this.textSvgIdCount.toString()}`,
      uiId: `ui-textSvg-${this.textSvgIdCount.toString()}`,
      type: '',
      zIndex: this.zIndexCount,
      animIndex: this.animIndexCount,
      scene: textElement,
      container: $('#scene'),
      sceneData: this.sceneData,
      dimensions: this.sceneDimensions,
      onError: (error) => errors.push(error),
    });
    this.objects.push(newTextSvg);
  }

  this.zIndexCount += 1;
  this.animIndexCount += 1;
};

/* Increments the total SVG count and adds a new entry (without) the SVG object */
SceneLoader.prototype.createPathSvg = function (
  sceneGroup,
  animationGroup,
  errors = [],
  options = {}
) {
  const { statsOnly = false } = options;

  this.svgIdCount += 1;

  if (!statsOnly) {
    const newPathSvg = new PathSvg({
      parent: this,
      id: `pathSvg-${this.svgIdCount.toString()}`,
      uiId: `ui-pathSvg-${this.svgIdCount.toString()}`,
      type: '',
      zIndex: this.zIndexCount,
      animIndex: this.animIndexCount,
      scene: sceneGroup,
      animation: animationGroup,
      container: $('#scene'),
      dimensions: this.sceneDimensions,
      onError: (error) => errors.push(error),
    });
    this.objects.push(newPathSvg);
  }

  this.zIndexCount += 1;
  this.animIndexCount += 1;
};

/* Increments the total SVG count and adds a new entry (without) the SVG object */
SceneLoader.prototype.createSvgImage = function (args, doc, options = {}) {
  const { statsOnly = false } = options;

  this.svgImageIdCount += 1;

  let type = '';
  if (nodeSearch.hasChild(args.sceneChild, 'text')) {
    type = 'text';
  }

  if (!statsOnly) {
    const newSvgImage = new SvgImage({
      parent: this,
      id: `svgImage-${this.svgImageIdCount.toString()}`,
      uiId: `ui-svgImage-${this.svgImageIdCount.toString()}`,
      type,
      motionPath: args.motionPath,
      zIndex: this.zIndexCount,
      animIndex: this.animIndexCount,
      scene: args.sceneChild,
      container: $('#scene'),
      sceneData: this.sceneData,
      dimensions: this.sceneDimensions,
      doc,
    });
    this.objects.push(newSvgImage);
  }

  this.zIndexCount += 1;
  this.animIndexCount += 1;
};

SceneLoader.prototype.clearAll = function () {
  // Remove objects from objects array
  this.objects = [];
  // Remove svgs from DOM
  $('.svg-container').remove();
  $('.svg-test-container').remove();

  // Reset all counters
  this.zIndexCount = 0;
  this.animIndexCount = 0;
  this.svgIdCount = 0;
  this.textSvgIdCount = 0;
  this.svgImageIdCount = 0;
  this.eraseIdCount = 0;
};

export default SceneLoader;
