import styled from 'styled-components/macro';

import { colors, lighten } from '../styles/colors';

export const Container = styled.div``;
export const HeaderContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
export const BodyContainer = styled.div``;
export const Text = styled.h4`
  flex: 1;
  margin: 0;
  color: ${colors['light-grey-10']};

  white-space: nowrap;
  &:not(.autocomplete) {
    overflow: hidden;
  }
  text-overflow: ellipsis;
`;
export const HeaderText = styled(Text)`
  color: ${colors['light-grey-20']};
`;
export const ButtonContainer = styled.div`
  width: 100px;
  display: flex;
  justify-content: flex-end;
`;
export const CreditsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 3px 5px;
  border-radius: 2px;

  margin-bottom: 5px;

  > * {
    margin-right: 10px;
  }

  &:nth-child(odd) {
    background: ${lighten(colors.grey, 55)};
  }

  i.button {
    font-size: 12px;
  }
`;
export const FooterRow = styled(CreditsRow)`
  &:nth-child(odd) {
    background: unset;
  }
`;
