import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useContext } from 'react';

import { post } from '../../util/fetchUtil';

import { Store } from '../../state/store';
import { fetchActiveProject } from '../../state/projects/actions';

import OptionSelector from '../OptionSelector';

import {
  HeaderSection,
  SectionTitle,
  Icon,
} from '../views/ProjectContentsStyles';

import TaskDialog from './TaskDialog';
import Button from '../RebassButton';
import Avatar from '../Avatar';
import Dialog from '../Dialog';

import { colors } from '../styles/colors';

/**
 * A single contributor row
 *
 * @param {*} props
 * @returns
 */
const Member = (props) => {
  const { member, getTasks, handleSubscribeTeammate } = props;

  const {
    state: { activeProject },
  } = useContext(Store);
  const [taskDialogVisible, setTaskDialogVisible] = useState(false);

  return (
    <MemberContainer key={member.userID}>
      <Avatar src={member.avatar} size={30} />
      <MemberName>
        {member.givenName} {member.familyName}
      </MemberName>
      <MemberOwner>{member.owner}</MemberOwner>
      <MemberActions>
        <Button
          onClick={() => setTaskDialogVisible(true)}
          variant="transparent"
          color="dark-6"
          mx={10}
        >
          <FontAwesomeIcon icon={['fad', 'tasks']} />
        </Button>
        <Button
          onClick={() => handleSubscribeTeammate(member, false)}
          variant="transparent"
          color="dark-6"
        >
          <FontAwesomeIcon icon={['fas', 'times']} />
        </Button>
      </MemberActions>
      <Dialog
        isVisible={taskDialogVisible}
        handleHideDialog={() => setTaskDialogVisible(false)}
      >
        <TaskDialog
          task={{
            project: activeProject,
            userID: member.userID,
            data: {},
          }}
          handleHideDialog={() => setTaskDialogVisible(false)}
          onAfterSave={getTasks}
        />
      </Dialog>
    </MemberContainer>
  );
};

Member.propTypes = {
  member: PropTypes.shape({
    userID: PropTypes.number,
    avatar: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
    owner: PropTypes.bool,
  }),
  getTasks: PropTypes.func.isRequired,
  handleSubscribeTeammate: PropTypes.func.isRequired,
};

function ContributorsSection(props) {
  const {
    activeProject: { members, projectID },
    team,
    getTasks,
  } = props;

  const { dispatch } = useContext(Store);

  const isSubscribed =
    members &&
    members.find((user) => user.userID === window.userID && user.subscribed);

  const subscribedMembers = members
    ? members.filter((member) => member.subscribed)
    : [];

  const handleSubscribeTeammate = (teammate, subscribed) => {
    post('/project/subscribeToProject', {
      userIDs: [teammate.userID],
      projectIDs: [projectID],
      subscribed,
    }).then(() => {
      fetchActiveProject(projectID, dispatch);
    });
  };

  const handleSelectTeammate = (teammate) => {
    handleSubscribeTeammate(teammate);
  };

  const handleSubscribe = () => {
    post('/project/subscribeToProject', {
      userIDs: [window.userID],
      projectIDs: [projectID],
      subscribed: true,
    }).then(() => {
      fetchActiveProject(projectID, dispatch);
    });
  };

  const memberComponents = subscribedMembers.map((member) => (
    <Member
      key={member.userID}
      member={member}
      getTasks={getTasks}
      handleSubscribeTeammate={handleSubscribeTeammate}
    />
  ));

  return (
    <Container>
      <HeaderSection>
        <SectionTitle>
          <Icon icon={['fad', 'users']} />
          Contributors
        </SectionTitle>
      </HeaderSection>
      <MembersContainer>{memberComponents}</MembersContainer>
      <Actions>
        {!isSubscribed && (
          <Button bg="positive" onClick={handleSubscribe}>
            Join
          </Button>
        )}
        <OptionSelector
          options={team.map((teammate) => ({
            ...teammate,
            name: teammate.fullName,
            id: teammate.userID,
          }))}
          onSelectOption={handleSelectTeammate}
          hasAutocomplete={true}
          autocompletePlaceholder="Filter teammates..."
          saveSelection={false}
        >
          <Button
            variant="outline"
            color="dark-6"
            startIcon={<FontAwesomeIcon icon={['fas', 'plus']} />}
          >
            Add teammate
          </Button>
        </OptionSelector>
      </Actions>
    </Container>
  );
}

const Container = styled.div``;
const MembersContainer = styled.div``;

const MemberContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 5px;
`;

const MemberName = styled.div`
  color: ${colors.grey};
  margin-left: 10px;
`;
const MemberOwner = styled.div`
  color: ${colors['light-grey-30']};

  margin-left: 5px;
`;

const Actions = styled.div`
  margin-top: 10px;

  display: flex;

  > * {
    margin-right: 10px;
  }
`;

const MemberActions = styled.div`
  display: flex;
`;

ContributorsSection.propTypes = {
  activeProject: PropTypes.shape({
    members: PropTypes.array,
    projectID: PropTypes.number,
  }),
  team: PropTypes.array,
  getTasks: PropTypes.func.isRequired,
};

ContributorsSection.defaultProps = {
  activeProject: {},
  team: [],
};

export default ContributorsSection;
