import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import React from 'react';

import { useState, useRef, memo } from 'react';

import ElementRow from './ElementRow';
import Box from '../../Box';
import { colors, fade } from '../../styles/colors';

import ElementProps from '../props/ElementProps';

const StyledElementRow = styled(ElementRow)`
  ${(props) =>
    props.isDraggingOver &&
    css`
      background-color: ${fade(colors.teal, 50)};
    `}
`;

const DropareaAbove = styled(Box)`
  position: absolute;
  width: 100%;
  height: 12px;
  top: -7px;
  background-color: ${fade(colors.teal, 50)};
  border-radius: 3px;
  z-index: 1;

  pointer-events: none;
`;

const DropareaBelow = styled(DropareaAbove)`
  height: 12px;
  bottom: -7px;
  top: unset;
`;

const shouldMemo = (prevProps, nextProps) => {
  if (
    nextProps.element.type === 'group' ||
    nextProps.isSelected !== prevProps.isSelected ||
    nextProps.elementsToUpdate.current.find(
      (id) => id === nextProps.element.id
    ) ||
    nextProps.cmdKeyDown !== prevProps.cmdKeyDown ||
    nextProps.shiftKeyDown !== prevProps.shiftKeyDown
  ) {
    return false;
  }
  return true;
};

/**
 * A wrapper over the ElementRow component that allows drag and drop behavior
 */
const ElementRowWrapper = memo((props) => {
  const {
    element,
    elements,
    handleReorderElement,
    handleAddToGroup,
    handleGroupElements,
  } = props;
  const [isDraggingOverTop, setIsDraggingOverTop] = useState(false);
  const [isDraggingOverBottom, setIsDraggingOverBottom] = useState(false);
  const [isDraggingOverMiddle, setIsDraggingOverMiddle] = useState(false);

  const elementRef = useRef();

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { top, height } = elementRef.current.getBoundingClientRect();
    const padding = 10;
    const { clientY } = e;

    if (clientY < top + padding) {
      if (!isDraggingOverTop) setIsDraggingOverTop(true);
      if (isDraggingOverBottom) setIsDraggingOverBottom(false);
      if (isDraggingOverMiddle) setIsDraggingOverMiddle(false);
      // Drag above to set in front of another el
    } else if (clientY > top + height - padding) {
      // Drag below to set behind another el
      if (!isDraggingOverBottom) setIsDraggingOverBottom(true);
      if (isDraggingOverTop) setIsDraggingOverTop(false);
      if (isDraggingOverMiddle) setIsDraggingOverMiddle(false);
    } else if (clientY > top + padding && clientY < top + height - padding) {
      // Drag over to group
      if (!isDraggingOverMiddle) setIsDraggingOverMiddle(true);
      if (isDraggingOverTop) setIsDraggingOverTop(false);
      if (isDraggingOverBottom) setIsDraggingOverBottom(false);
    } else if (
      isDraggingOverBottom ||
      isDraggingOverTop ||
      isDraggingOverMiddle
    ) {
      setIsDraggingOverBottom(false);
      setIsDraggingOverTop(false);
      setIsDraggingOverMiddle(false);
    }
  };

  const handleDragLeave = (e) => {
    e.stopPropagation();

    setIsDraggingOverBottom(false);
    setIsDraggingOverTop(false);
    setIsDraggingOverMiddle(false);
  };

  const handleDragStart = (e) => {
    e.stopPropagation();
    // Transfer the projectIDs
    e.dataTransfer.setData('elements', JSON.stringify([element.id]));
  };

  const handleDrop = (e) => {
    e.stopPropagation();

    if (
      e.dataTransfer.items &&
      (isDraggingOverBottom || isDraggingOverTop || isDraggingOverMiddle)
    ) {
      // Use DataTransferItemList interface to access the objects
      const droppedElements = Object.values(e.dataTransfer.items).find(
        (item) => item.type === 'elements'
      );

      droppedElements.getAsString((s) => {
        // Get dropped data
        const elementIds = JSON.parse(s);

        // Disallow dropping on self
        if (elementIds.some((id) => element.id === id)) return;

        if (isDraggingOverBottom || isDraggingOverTop) {
          // Reorder element(s)
          // TODO: ALLOW FOR MULTIPLE REORDER
          const newIndex = isDraggingOverTop ? 0 : 1;

          handleReorderElement(elementIds[0], element.id, newIndex);
        } else if (element.type === 'group') {
          // If this element is already a group, add dropped elements to group
          const elementsToAdd = elementIds.map((id) =>
            elements.find((el) => el.id === id)
          );
          handleAddToGroup(elementsToAdd, element);
        } else {
          // Create a new group out of the elements
          const elementsToGroup = [
            ...elementIds.map((id) => elements.find((el) => el.id === id)),
            element,
          ];
          handleGroupElements(elementsToGroup);
        }
      });
    }

    setIsDraggingOverBottom(false);
    setIsDraggingOverMiddle(false);
    setIsDraggingOverTop(false);
  };

  return (
    <Box
      ref={elementRef}
      style={{ position: 'relative' }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {isDraggingOverTop && <DropareaAbove />}
      <Box draggable={true} onDragStart={handleDragStart}>
        <StyledElementRow
          ElementRowWrapper={ElementRowWrapper}
          isDraggingOver={isDraggingOverMiddle}
          {...props}
        />
      </Box>
      {isDraggingOverBottom && <DropareaBelow />}
    </Box>
  );
}, shouldMemo);

ElementRowWrapper.displayName = 'ElementRowWrapper';

const { arrayOf, func } = PropTypes;

ElementRowWrapper.propTypes = {
  element: ElementProps.isRequired,
  elements: arrayOf(ElementProps).isRequired,
  handleReorderElement: func.isRequired,
  handleAddToGroup: func.isRequired,
  handleGroupElements: func.isRequired,
};

export default ElementRowWrapper;
