import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';

import { AnimatePresence } from 'framer-motion';

import { post } from '../../util/fetchUtil';

import { Store } from '../../state/store';
import { fetchUserData } from '../../state/user/actions';

import UserAvatar from '../profile/UserAvatar';
import Button from '../Button';
import OptionSelector from '../OptionSelector';

import AnalyticsSection from '../profile/AnalyticsSection';
import TaskSection from '../profile/TaskSection';

import rolesData from '../../shared/roles';

import {
  Header,
  Container,
  Column,
  Section,
  HeaderContainer,
  Name,
  RolesWrapper,
  SectionContainer,
  TagContainer,
  TagText,
  Subheader,
  AddingContainer,
  ContentContainer,
} from './ProfileStyles';

const Profile = () => {
  const roles = rolesData.map((role) => role.taskName);
  const [editingDegree, setEditingDegree] = useState(false);
  const [degreeValue, setDegreeValue] = useState('');

  const {
    dispatch,
    state: { user },
  } = useContext(Store);
  /**
   * Fetch most up-to-date user information and update global state
   *
   * @memberof Profile
   */
  const updateUser = () => {
    fetchUserData(dispatch);
  };

  const handleSelectRole = (role) => {
    post('/user/addRole', {
      role: role.name,
    }).then(() => updateUser());
  };

  /**
   * Remove the passed role from the user's roles array
   *
   * @param {String} role
   * @returns
   * @memberof Profile
   */
  const handleRemoveRole = (role) => {
    post('/user/removeRole', {
      role,
    }).then(() => updateUser());
  };

  const handleSignoutClick = () => {
    post('/logout').then(() => {
      window.location.reload();
    });
  };

  const handleDegreeChange = (e) => {
    const { value } = e.target;
    setDegreeValue(value);
  };

  const handleAddDegree = () => {
    const degrees = user.data.degrees || [];
    degrees.push(degreeValue);

    post('/user/updateData', {
      data: { degrees },
    }).then(() => updateUser());

    setEditingDegree(false);
    setDegreeValue('');
  };

  const handleRemoveDegree = (degree) => {
    post('/user/removeDegree', {
      degree,
    }).then(() => updateUser());
  };

  const handleEditClick = () => {
    setEditingDegree(true);
    setDegreeValue(user.data.degree);
  };

  const degrees = user.data && user.data.degrees;

  const userRolesArray =
    (user.data && user.data.roles && user.data.roles.sort()) || [];
  const userRoles = userRolesArray.map((role) => (
    <TagContainer
      initial={{ opacity: 0, x: -20 }}
      key={role}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, scale: 0.5 }}
    >
      <TagText>{role}</TagText>
      <Button
        className="icon transparent thin"
        onClick={() => handleRemoveRole(role)}
      >
        <FontAwesomeIcon icon={['fas', 'times']} />
      </Button>
    </TagContainer>
  ));

  const availableRoles = roles
    .filter((role) => !userRolesArray.find((r) => r === role))
    .map((role) => ({ name: role, id: role }));
  return (
    <Container>
      <Column>
        <Section>
          <HeaderContainer>
            <Name>{`${user.givenName} ${user.familyName}`}</Name>
            <Button basic box rightIcon onClick={handleSignoutClick}>
              <span>Sign out</span>
              <FontAwesomeIcon icon={['fad', 'sign-out']} />
            </Button>
          </HeaderContainer>
        </Section>

        <Section>
          <UserAvatar user={user} onComplete={() => fetchUserData(dispatch)} />
        </Section>

        <Section>
          <Header>Profile</Header>
          <SectionContainer>
            <Subheader>Degree</Subheader>

            <ContentContainer>
              <AnimatePresence initial={false}>
                {degrees &&
                  degrees.map((degree) => (
                    <TagContainer
                      key={degree}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, scale: 0.5 }}
                    >
                      <TagText>{degree}</TagText>
                      <Button
                        className="icon transparent thin"
                        onClick={() => handleRemoveDegree(degree)}
                      >
                        <FontAwesomeIcon icon={['fas', 'times']} />
                      </Button>
                    </TagContainer>
                  ))}
              </AnimatePresence>
            </ContentContainer>
            {editingDegree && (
              <AddingContainer>
                <div className="text-input">
                  <input
                    type="text"
                    placeholder="E.g. PhD, MS, etc."
                    onChange={handleDegreeChange}
                    value={degreeValue}
                  />
                </div>

                <Button className="box green" onClick={handleAddDegree}>
                  <FontAwesomeIcon icon={['fad', 'check']} />
                </Button>

                <Button
                  className="box basic"
                  onClick={() => setEditingDegree(false)}
                >
                  <FontAwesomeIcon icon={['fas', 'times']} />
                </Button>
              </AddingContainer>
            )}
            {!editingDegree && (
              <Button box basic leftIcon onClick={handleEditClick}>
                <FontAwesomeIcon icon={['far', 'plus']} />
                Add degree
              </Button>
            )}
          </SectionContainer>
        </Section>

        <RolesWrapper>
          <Header>Roles</Header>
          <SectionContainer>
            <AnimatePresence initial={false}>{userRoles}</AnimatePresence>
          </SectionContainer>
          <OptionSelector
            options={availableRoles}
            onSelectOption={handleSelectRole}
            buttonClasses="box basic"
            saveSelection={false}
            placeholder="Add role"
          />
        </RolesWrapper>
        <AnalyticsSection />
      </Column>
      <Column>
        <Header>Tasks</Header>
        <Section>
          <TaskSection />
        </Section>
      </Column>
    </Container>
  );
};

Profile.propTypes = {};

Profile.defaultProps = {};

export default Profile;
