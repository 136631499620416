import PropTypes from 'prop-types';
const { number, string, shape, arrayOf } = PropTypes;

export const FileProps = shape({
  createdOn: string,
  data: shape({
    url: string,
    size: number,
    user: shape({
      id: number,
      name: string,
    }),
    fileExports: arrayOf(),
    fileID: number,
    modified: string,
    name: string,
    projectID: number,
    status: number,
    type: string,
  }),
});
