import PropTypes from 'prop-types';

const { any, instanceOf, oneOfType, arrayOf, node, shape, array } = PropTypes;

export const ChildrenProps = oneOfType([arrayOf(PropTypes.node), node, array]);

export const DomRefProps = shape({
  current: instanceOf(Element),
});

export const RefProps = shape({
  current: any,
});

export default {
  ChildrenProps,
  DomRefProps,
  RefProps,
};
