import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import { motion, AnimatePresence } from 'framer-motion';

import Button from './Button';
import Box, { Flex } from './Box';
import { P } from './styles/typography';
import { colors, lighten } from './styles/colors';

const ButtonContainer = styled(motion.div)``;
const ButtonWrapper = styled(motion.div)`
  margin-right: 10px;
`;

const Content = styled.div`
  margin-bottom: 20px;
  max-height: 350px;
  overflow: auto;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 5px 5px 5px 10px;
  border-radius: 3px;

  margin-bottom: 5px;

  cursor: pointer;
  border: 1px solid transparent;

  :hover,
  :focus {
    background: ${lighten(colors.grey, 54)};
  }

  :active {
    background: ${lighten(colors.grey, 50)};
  }

  ${(props) =>
    props.checked &&
    css`
      border: 1px solid ${lighten(colors.blue, 30)};
      background: ${lighten(colors.blue, 40)};
      :hover,
      :focus {
        background: ${lighten(colors.blue, 35)};
      }

      :active {
        background: ${lighten(colors.blue, 30)};
      }
    `}
`;

const Number = styled(P)`
  margin-right: 15px;
`;

const Check = styled(FontAwesomeIcon)`
  margin-right: 10px;
  color: ${colors.blue};
`;

/**
 * Allows user to select which items to play
 *
 * @prop {object} project - a project object (e.g. activeProject)
 * @returns
 */
const ListItemSelector = (props) => {
  const { items, onItemClick, handleDeselectAll, handleSelectAll } = props;

  const checkedItems = items.filter((item) => item.checked);

  const itemComponents = items.map((item, i) => (
    <Box key={item.id}>
      <CheckboxContainer
        onClick={() => onItemClick(item)}
        checked={item.checked}
      >
        <Flex c>
          <Number>{i + 1}</Number>
          {item.name}
        </Flex>
        <Flex c>{item.checked && <Check icon={['fas', 'check']} />}</Flex>
      </CheckboxContainer>
    </Box>
  ));

  return (
    <Box>
      <Flex style={{ marginBottom: 10 }}>
        <AnimatePresence intial={false}>
          {items.length > 1 &&
            handleSelectAll &&
            checkedItems.length !== items.length && (
              <ButtonContainer
                key="select"
                initial={{ opacity: 0, scale: 0, width: 0 }}
                animate={{ opacity: 1, scale: 1, width: 'auto' }}
                exit={{ opacity: 0, scale: 0, width: 0 }}
              >
                <ButtonWrapper>
                  <Button onClick={() => handleSelectAll(true)} thin box basic>
                    Select all
                  </Button>
                </ButtonWrapper>
              </ButtonContainer>
            )}
          {items.length > 1 && handleDeselectAll && checkedItems.length !== 0 && (
            <ButtonContainer
              key="deselect"
              initial={{ opacity: 0, scale: 0, width: 0 }}
              animate={{ opacity: 1, scale: 1, width: 'auto' }}
              exit={{ opacity: 0, scale: 0, width: 0 }}
            >
              <ButtonWrapper onClick={() => handleDeselectAll(false)}>
                <Button thin box basic>
                  Deselect all
                </Button>
              </ButtonWrapper>
            </ButtonContainer>
          )}
        </AnimatePresence>
      </Flex>
      <Content>{itemComponents}</Content>
    </Box>
  );
};

ListItemSelector.propTypes = {
  handleDeselectAll: PropTypes.func,
  handleSelectAll: PropTypes.func,
  onItemClick: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      checked: PropTypes.bool,
    })
  ),
};

ListItemSelector.defaultProps = {
  handleDeselectAll: null,
  handleSelectAll: null,
};

export default ListItemSelector;
