import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

import React, { useState, useEffect } from 'react';

import { Link, useParams } from 'react-router-dom';

import fetchUtil from '../../util/fetchUtil';

import { colors, fade } from '../styles/colors';
import Box, { Flex } from '../Box';
import { P } from '../styles/typography';

import { ViewContainer, IconContainer } from './NavigationStyles';
import { SettingsButton } from './ProjectNavigationStyles';

const Breadcrumbs = styled.div`
  color: ${colors['light-grey-10']};
`;

const CrumbText = styled(P)`
  color: ${fade('white', 30)};
  display: inline-block;
`;

const Divider = styled.span`
  color: ${colors['light-grey-30']};
`;

const CrumbLink = styled(Link)`
  cursor: pointer;
  color: ${fade('white', 70)};

  :hover {
    color: ${fade('white', 60)};
  }
`;

function AssetNavigationHeader() {
  const params = useParams();

  const [kit, setKit] = useState();

  const getKitData = async () => {
    const kitID = Number.parseInt(params.kit, 10);
    if (!Number.isNaN(kitID)) {
      const res = await fetchUtil.post('/assets/getKit', {
        kitID,
      });

      if (res.kit) {
        setKit(res.kit);
      }
    }
  };

  useEffect(() => {
    getKitData();
    // eslint-disable-next-line
  }, [params.kit]);

  return (
    <ViewContainer style={{ alignItems: 'center' }}>
      <IconContainer />

      <Box>
        <Flex style={{ padding: 10 }}>
          <Breadcrumbs>
            <CrumbLink to="/assets">Kits</CrumbLink>
            <Divider>&nbsp;&gt;&nbsp;</Divider>
            <CrumbText>{kit && kit.name}</CrumbText>
          </Breadcrumbs>
        </Flex>
      </Box>

      <Link to={`/assets/${params.kit}/settings`}>
        <SettingsButton icon>
          <FontAwesomeIcon icon={['fad', 'cog']} />
        </SettingsButton>
      </Link>
    </ViewContainer>
  );
}

AssetNavigationHeader.propTypes = {};

AssetNavigationHeader.defaultProps = {};

export default AssetNavigationHeader;
