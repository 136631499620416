import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button';
import { P } from '../../styles/typography';

import { PropertyContainer, Header, Group, Section } from './PropertyStyles';

import ElementProps from '../props/ElementProps';

/**
 * Properties specific to rect elements
 */
function AnimationPathProperties(props) {
  const { element, handleUpdateElement } = props;

  const handleClearAnimationPath = () => {
    const newAnimationPath = '';
    element.update('animationPathD', newAnimationPath);
    handleUpdateElement([element.id]);
  };

  return (
    <div style={{ height: '100%' }}>
      <Header>Animation path</Header>
      <Group>
        <PropertyContainer display="flex">
          <Section>
            <P>Length: {Math.round(element.cache.animationPath.length)}px</P>
          </Section>
          <Section>
            <Button
              leftIcon
              box
              thin
              transparent
              onClick={handleClearAnimationPath}
            >
              <FontAwesomeIcon icon={['fad', 'trash-alt']} />
              Clear
            </Button>
          </Section>
        </PropertyContainer>
      </Group>
    </div>
  );
}

const { func } = PropTypes;

AnimationPathProperties.propTypes = {
  element: ElementProps.isRequired,
  handleUpdateElement: func.isRequired,
};

export default AnimationPathProperties;
