import styled from 'styled-components/macro';

import { colors, lighten } from '../styles/colors';

export const Container = styled.div``;
export const PSpan = styled.p`
  display: inline;
`;
export const Note = styled.p`
  font-style: italic;
  padding-left: 15px;
  border-left: 1px solid ${colors['light-grey-50']};
`;
export const SchemaHighlight = styled.p`
  display: inline-block;
  font-weight: 700;

  background: ${lighten(colors.grey, 55)};
  color: ${colors.grey};
  padding: 3px 6px;
  border-radius: 2px;
`;
export const SchemaHeader = styled.h4`
  margin-bottom: 10px;
  margin-top: 10px;
`;
