import styled, { css } from 'styled-components/macro';

import Box from '../Box';
import { colors, fade, lighten } from '../styles/colors';

export const PanelContainer = styled(Box)`
  padding: 10px 15px;
  margin: 5px;

  background: ${colors.night};
  border-radius: 5px;
`;

type PanelButtonProps = {
  isActive: boolean;
};

export const PanelButton = styled(Box)`
  margin-right: 10px;
  padding: 5px;
  color: ${fade('white', 50)};
  border-radius: 3px;

  cursor: pointer;

  ${(props: PanelButtonProps) =>
    props.isActive &&
    css`
      color: ${fade('white', 10)};
      background-color: ${lighten(colors.night, 20)};
    `}
`;

export const PanelHeader = styled(Box)`
  background-color: ${lighten(colors.night, 5)};
  padding: 5px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
`;
