import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import uuid from '../app/utilities/uuid';

import { colors, lighten } from './styles/colors';

/**
 *
 *
 * @prop {Array} headers
 * [{
 *    id: {String}, - the key id for the rows prop
 *    value: {String}, - the header display name
 * }]
 * @prop {Object} rows
 * {
 *    [id]: {String|ReactChild|Object},
 * }
 *
 * If passing object, should be of the shape:
 * Object: { component: ReactChild, data: String }
 * This makes it easier to pass data back to the parent if react components are used
 * @returns
 */
function Table(props) {
  const { headers, rows, fixedHeader, onRowClick } = props;

  const handleRowClick = (row) => {
    const rowData = {};
    Object.keys(row).forEach((key) => {
      rowData[key] = (row[key] && row[key].data) || row[key];
    });

    onRowClick(rowData);
  };

  return (
    <Container>
      <Header fixedHeader={fixedHeader}>
        <HeaderRow>
          {headers.map((header) => (
            <HeaderColumn
              key={header.id}
              width={header.width}
              align={header.align}
              expand={header.expand}
            >
              {header.value}
            </HeaderColumn>
          ))}
        </HeaderRow>
      </Header>
      <Content fixedHeader={fixedHeader}>
        {rows.map((row) => (
          <Row
            key={uuid()}
            onClick={onRowClick ? () => handleRowClick(row) : null}
            clickable={!!onRowClick}
          >
            {headers.map((header) => (
              <Column
                key={header.id}
                width={header.width}
                align={header.align}
                expand={header.expand}
                title={row[header.id] || header.default}
              >
                {(row[header.id] && row[header.id].component) ||
                  row[header.id] ||
                  header.default}
              </Column>
            ))}
          </Row>
        ))}
      </Content>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const Header = styled.div`
  ${(props) =>
    props.fixedHeader &&
    css`
      position: absolute;
      width: 100%;
      z-index: 1;
    `}
`;

const Content = styled.div`
  ${(props) =>
    props.fixedHeader &&
    css`
      overflow: auto;
      flex: 1;
      padding-top: 42px;
      max-height: 100vh;
    `}
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  :nth-child(even) {
    background: ${lighten(colors.grey, 58)};
  }

  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
      :hover,
      :active {
        background: ${lighten(colors.grey, 55)};
      }

      :active {
        background: ${lighten(colors.grey, 50)};
      }
    `}
`;

const HeaderRow = styled(Row)`
  background: ${lighten(colors.grey, 53)};
`;

const Column = styled.div`
  width: 200px;
  color: ${colors.grey};

  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 30px;

  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `}

  ${(props) =>
    props.align &&
    css`
      display: flex;
      justify-content: ${props.align};
    `}

  ${(props) =>
    props.expand &&
    css`
      flex: 1;
      width: 'unset';
    `}
`;

const HeaderColumn = styled(Column)`
  color: ${lighten(colors.grey, 20)};
`;

Table.propTypes = {
  fixedHeader: PropTypes.bool, // Fixes header to top of container
  onRowClick: PropTypes.func, // Fixes header to top of container
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string, // The identifier of the column
      value: PropTypes.string, // The text value to display for the header
      width: PropTypes.number, // The width of the column (default: 200px)
      align: PropTypes.string, // A css attribute for 'justify-content'
      default: PropTypes.string, // A default string to display if falsey
    })
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string, // the header identifier
      value: PropTypes.oneOfType([
        PropTypes.string, // A string to display in the table
        PropTypes.element, // A React component
      ]),
    })
  ),
};

Table.defaultProps = {
  fixedHeader: true, // Fixes header to top of container
};

export default Table;
