// Resources
// https://www.w3.org/TR/2011/WD-css3-2d-transforms-20111215/#matrix-decomposition

/**
 * Convert a matrix string to an array of numbers
 *
 * @param {String} matrixString - e.g. 'matrix(1,0,0,1,0,0)'
 */
export const stringToArray = (matrixString) =>
  matrixString
    .replace(/^\w*\(/, '')
    .replace(')', '')
    .split(/\s*,\s*/)
    .map((stringNum) => parseFloat(stringNum));

/**
 * Convert a matrix string to a DOMMatrix object
 *
 * @param {String} matrixString - e.g. 'matrix(1,0,0,1,0,0)'
 */
export const stringToObject = (matrixString = 'matrix(1, 0, 0, 1, 0, 0)') =>
  new DOMMatrix(stringToArray(matrixString));

/**
 * Get the delta between the matrix and a point
 *
 * @param {DOMMatrix} matrix
 * @param {Object} point
 * @returns
 */
export const deltaTransformPoint = (matrix, point) => {
  const dx = point.x * matrix.a + point.y * matrix.c + 0;
  const dy = point.x * matrix.b + point.y * matrix.d + 0;
  return { x: dx, y: dy };
};

/**
 * Decompose a matrix transform
 *
 * @param {DOMMatrix} matrix
 * @returns
 */
// @see https://gist.github.com/2052247
export const decomposeMatrix = (matrix) => {
  // calculate delta transform point
  const px = deltaTransformPoint(matrix, { x: 0, y: 1 });
  const py = deltaTransformPoint(matrix, { x: 1, y: 0 });

  // calculate skew
  const skewX = (180 / Math.PI) * Math.atan2(px.y, px.x) - 90;
  const skewY = (180 / Math.PI) * Math.atan2(py.y, py.x);

  return {
    translateX: parseFloat(matrix.e),
    translateY: parseFloat(matrix.f),
    scaleX: Math.sqrt(matrix.a * matrix.a + matrix.b * matrix.b),
    scaleY: Math.sqrt(matrix.c * matrix.c + matrix.d * matrix.d),
    skewX,
    skewY,
    rotate: skewX, // rotation is the same as skew x
  };
};

/**
 * Decompose a matrix transform from matrix string
 *
 * @param {String} matrixString - e.g. matrix(1,0,0,1,0,0)
 * @returns
 */
export const decomposeMatrixFromString = (matrixString) => {
  const matrixVars = ['a', 'b', 'c', 'd', 'e', 'f'];
  const matrix = matrixString
    .slice(7, matrixString.length - 1)
    .split(',')
    .reduce(
      (t, curr, i) => ({
        ...t,
        [matrixVars[i]]: curr,
      }),
      {}
    );

  return decomposeMatrix(matrix);
};

export const isFlipped = (transform) => {
  // Calculate inversion based on skew
  const diffSkew = transform.skewY - transform.skewX;
  if (Math.abs(Math.round(diffSkew)) === 180) {
    return true;
  }

  return false;
};

export default {
  isFlipped,
  stringToArray,
  stringToObject,
  decomposeMatrix,
  decomposeMatrixFromString,
  deltaTransformPoint,
};
