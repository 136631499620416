import PropTypes from 'prop-types';
import React, { useState } from 'react';

import useAbortController from '../app/hooks/useAbortController';
import Autocomplete from './Autocomplete';

/**
 * Component that takes a route and fetches data from that route
 * dynamically as the user types, useful for database searches.
 */
function FetchAutocomplete(props) {
  const {
    route,
    userInput,
    setUserInput,
    onOptionClick,
    notFoundComponent,
    placeholder,
  } = props;

  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const body = {
    text: userInput,
  };

  useAbortController(
    route,
    body,
    [userInput],
    (res) => {
      const { data } = res;
      if (data.length) {
        setOptions(
          data.map((item) => ({
            ...item,
            id: item.id,
            name: item.name,
          }))
        );
      } else {
        setOptions([]);
      }

      setIsLoading(false);
    },
    {
      onBeforeFetch() {
        // Set loading
        if (!isLoading) setIsLoading(true);
      },
    }
  );

  const onUserInputChange = (e) => {
    const { value } = e.target;

    setUserInput(value);
  };

  let activeOptions = [];

  const notFoundOption = {
    noFilter: true,
    component: notFoundComponent || null,
    name: 'No results found 😭',
    id: 'notFound',
  };

  if (isLoading) {
    activeOptions.push({ noFilter: true, name: 'Loading', id: 'loading' });
  } else if (options.length === 0) {
    activeOptions.push(notFoundOption);
  } else {
    activeOptions = options;
    // If the user input doesn't match, add the notFoundOption
    if (
      !activeOptions.find(
        (o) => o.name.toLowerCase() === userInput.toLowerCase()
      )
    ) {
      activeOptions.unshift(notFoundOption);
    }
  }

  return (
    <Autocomplete
      options={activeOptions}
      value={userInput}
      onItemSelect={onOptionClick}
      placeholder={placeholder}
      onChange={onUserInputChange}
    />
  );
}

FetchAutocomplete.propTypes = {
  onOptionClick: PropTypes.func.isRequired,
  route: PropTypes.string.isRequired,
  userInput: PropTypes.string.isRequired,
  setUserInput: PropTypes.func.isRequired,
  notFoundComponent: PropTypes.element,
  placeholder: PropTypes.string,
};

FetchAutocomplete.defaultProps = {
  notFoundComponent: null,
  placeholder: '',
};

export default FetchAutocomplete;
