import { setSavedAnnotationSvg } from '../state/annotations/actions';
import {
  loadAssets,
  setActiveFile,
  setSceneDimensions,
  setSceneMode,
} from '../state/scene/actions';
import { fetchActiveProject } from '../state/projects/actions';

import fileManager from './fileManager';
import sceneLoader from './sceneLoader';
import playback from './playback';
import { setMisspelledWords } from '../state/misspelledWords/actions';

/**
 * Singleton class that controls functions related to the layers panel
 *
 * @class LayersPanelController
 */
function LayersPanelController() {
  /**
   * Loads a file from a url
   *
   * @param {Project File Object} file
   * @memberof LayersPanelController
   */
  this.loadSvgFromFile = (file, context, options = {}) =>
    new Promise((resolve) => {
      const {
        state: { sceneMode },
        dispatch,
      } = context;
      if (sceneMode !== 'svg') setSceneMode('svg', dispatch);

      // Clear saved SVGs
      setSavedAnnotationSvg('', dispatch);

      this.loadSvgFromUrl(file.data.url, context, options)
        // Set the active file
        .then(() => resolve());
    });

  /**
   * Load the from a URL source
   *
   * @param {*} url
   * @memberof LayersPanelController
   */
  this.loadSvgFromUrl = async (url, context, options = {}) => {
    const blob = await fileManager.getSvgFileObject(url);

    // Make sure assets are loaded into pageß
    if (!context.state.assetsLoaded) {
      await loadAssets(context.dispatch);
      window.assetsLoaded = true;
    }

    const res = await sceneLoader.load(blob, options);

    if (res) {
      // Update playback timeline
      playback.updateTimeline(res.objects);
      // Load the file blob returned
      setMisspelledWords(res.misspelledWords, context.dispatch);
      setSceneDimensions(res.dimensions, context.dispatch);

      // Set scene as loaded
      window.sceneLoaded = true;
    }

    return res;
  };

  this.loadImageFromFile = (file, context) =>
    new Promise((resolve) => {
      const {
        state: { sceneMode },
        dispatch,
      } = context;
      // Change mode
      if (sceneMode !== 'image') setSceneMode('image', dispatch);
      // Clear saved SVGs
      setSavedAnnotationSvg('', dispatch);

      resolve();
    });

  this.loadVideoFromFile = (file, context) =>
    new Promise((resolve) => {
      const {
        state: { sceneMode },
        dispatch,
      } = context;
      // Change mode
      if (sceneMode !== 'video') setSceneMode('video', dispatch);
      // Clear saved SVGs
      setSavedAnnotationSvg('', dispatch);

      resolve();
    });

  this.uploadSvgs = (files, context) => {
    const { projectID } = this.context.state.activeProject;

    // Upload passed files
    const uploadPromises = [];
    for (let i = 0; i < files.length; i += 1) {
      const fileToUpload = files[i];
      uploadPromises.push(
        fileManager.uploadProjectFile(
          fileToUpload,
          'svg',
          context.state.activeProject,
          {}
        )
      );
    }

    // Return promise that resolves when all uploads are complete
    // TODO: FIGURE OUT HOW TO HAVE THEM UPLOAD ONE BY ONE
    let fileUploads = [];
    Promise.all(uploadPromises)
      .then((res) => {
        fileUploads = res; // Retrieve response array from uploads
        return fetchActiveProject(projectID, this.context.dispatch);
      })
      .then(() => {
        // Set the active file object for the project
        setActiveFile(
          this.context.state.activeProject.files.find(
            (f) => f.name === fileUploads[0].fileName
          ),
          context.dispatch
        );
      });
  };
}

const layersPanelController = new LayersPanelController();

export default layersPanelController;
