import React, { useContext } from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';
import { Store } from '../../state/store';
import { Box } from '../Box';
import ProjectGroupProps from '../../types/ProjectGroupProps';
import SidebarGroup from './SidebarGroup';

type ItemProps = {
  count: number;
  isRefined: boolean;
  label: string;
  value: string[];
};

interface Props {
  items: any;
  refine: (value: string[]) => void;
}

const RefinementList = ({ items, refine }: Props) => {
  const {
    state: { projectGroups = [] },
  } = useContext(Store);

  return (
    <Box>
      {items.map((item: ItemProps) => {
        const projectGroup = projectGroups.find(
          (group: ProjectGroupProps) =>
            group.projectGroupID.toString() === item.label
        );
        if (projectGroup) {
          return (
            <SidebarGroup
              key={item.label}
              count={item.count}
              projectGroupID={projectGroup.projectGroupID}
              isChecked={item.isRefined}
              label={projectGroup.name}
              handleChange={() => refine(item.value)}
            />
          );
        }

        return null;
      })}
    </Box>
  );
};

const GroupRefinementList = connectRefinementList(RefinementList);
export default GroupRefinementList;
