export default function (imageGroup, bbox, dimensions) {
  return {
    // Expand from nothing
    expand: {
      name: 'Expand',
      targets: `#${imageGroup.id()}`,
      opacity: [1, 1],
      duration: 1000,
      easing: 'easeInOutSine',
      autoplay: false,
      translateY: {
        value: [bbox ? imageGroup.bbox().cy : 0, 0],
      },
      translateX: {
        value: [bbox ? imageGroup.bbox().cx : 0, 0],
      },
      scale: {
        value: [0, 1],
      },
    },
    // Fade in
    fadeIn: {
      defaultAnim: true,
      name: 'Fade In',
      targets: `#${imageGroup.id()}`,
      opacity: [0, 1],
      duration: 1000,
      easing: 'easeInOutSine',
      autoplay: false,
    },
    // Enter from right
    fromRight: {
      name: 'From Right',
      targets: `#${imageGroup.id()}`,
      opacity: [1, 1],
      translateX: {
        value: [bbox ? `=+${dimensions.w - imageGroup.bbox().x}` : 0, 0],
        duration: (dimensions.w - imageGroup.bbox().x) * 1.5,
        easing: 'easeInOutSine',
      },
      autoplay: false,
    },
    // Enter from left
    fromLeft: {
      name: 'From Left',
      targets: `#${imageGroup.id()}`,
      opacity: [1, 1],
      translateX: {
        value: [bbox ? `=+${-imageGroup.bbox().x2}` : 0, 0],
        duration: imageGroup.bbox().x2 * 1.5,
        easing: 'easeInOutSine',
      },
      autoplay: false,
    },
    // Enter from the Top
    fromTop: {
      name: 'From Top',
      targets: `#${imageGroup.id()}`,
      opacity: [1, 1],
      translateY: {
        value: [bbox ? `=+${-imageGroup.bbox().y2}` : 0, 0],
        duration: imageGroup.bbox().y2 * 1.5,
        easing: 'easeInOutSine',
      },
      autoplay: false,
    },
    // Enter from the Bottom
    fromBottom: {
      name: 'From Bottom',
      targets: `#${imageGroup.id()}`,
      opacity: [1, 1],
      translateY: {
        value: [bbox ? `=+${dimensions.h - imageGroup.bbox().y}` : 0, 0],
        duration: (dimensions.h - imageGroup.bbox().y) * 1.5,
        easing: 'easeInOutSine',
      },
      autoplay: false,
    },
    // Shrink to nothing
    shrink: {
      name: 'Shrink',
      targets: `#${imageGroup.id()}`,
      duration: 1000,
      opacity: [1, 1],
      translateY: {
        value: [0, bbox ? imageGroup.bbox().cy : 0],
      },
      translateX: {
        value: [0, bbox ? imageGroup.bbox().cx : 0],
      },
      scale: {
        value: [1, 0],
      },
      easing: 'easeInOutSine',
      autoplay: false,
    },
  };
}
