import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import useModal from '../../app/hooks/useModal';
import Box from '../Box';
import Button from '../RebassButton';
import Dialog from '../Dialog';
import Text from '../Text';
import { HomeButton } from './HeaderBarStyles';
import slide from '../animations/slide';

const Feedback = () => {
  const [showConfirmation] = useState(false);
  const dialog = useModal();

  return (
    <Box>
      <Dialog isVisible={dialog.isVisible} handleHideDialog={dialog.hide}>
        {!showConfirmation && (
          <>
            <Box width="100%" maxWidth={500} maxHeight="60vh" overflow="auto">
              <Text as="h2">Have feedback for Osmosify?</Text>
              <Text mt={2}>
                Send us your thoughts by filling out our{' '}
                <a href="https://docs.google.com/forms/d/e/1FAIpQLSdh2c-69OBE2Z4smLgJpQxHm3UovSuxDwGWx7EDrNm8u9BUEw/viewform">
                  Feature Request Form
                </a>{' '}
                or report a bug via our{' '}
                <a href="https://docs.google.com/forms/d/e/1FAIpQLScG9kwEmEXB9aHSSCIVRX-zGNb_hJbheKtZOeQbqw83wILmsA/viewform">
                  Bug Report Form
                </a>
                . The Product and Engineering Team will review all requests and
                will get back to you in about a week.
              </Text>
              <Box mt={3} pl={3}>
                <Button
                  mb={2}
                  as="a"
                  href="https://docs.google.com/forms/d/e/1FAIpQLSdh2c-69OBE2Z4smLgJpQxHm3UovSuxDwGWx7EDrNm8u9BUEw/viewform"
                  target="_blank"
                  color="dark-6"
                  variant="outline"
                >
                  Request a feature
                </Button>
                <Button
                  as="a"
                  href="https://docs.google.com/forms/d/e/1FAIpQLScG9kwEmEXB9aHSSCIVRX-zGNb_hJbheKtZOeQbqw83wILmsA/viewform"
                  target="_blank"
                  color="dark-6"
                  variant="outline"
                >
                  Report a bug
                </Button>
              </Box>
            </Box>
          </>
        )}
        {showConfirmation && (
          <Box>
            <motion.div {...slide.up}>
              <Text fontSize={60} textAlign="center">
                <span role="img" aria-label="thumbs up">
                  👍
                </span>
              </Text>
              <Text as="h2">Sweet! Thanks for the feedback!</Text>
            </motion.div>
          </Box>
        )}
      </Dialog>
      <HomeButton onClick={dialog.show}>
        <Box mr={2}>
          <FontAwesomeIcon icon={['fad', 'lightbulb']} />
        </Box>
        <Text color="light-5" fontSize={16}>
          Feedback
        </Text>
      </HomeButton>
    </Box>
  );
};

export default Feedback;
