import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useContext } from 'react';

import fetchUtil from '../../../util/fetchUtil';

import { Store } from '../../../state/store';
import InputConfirm from '../../InputConfirm';
import OptionSelector from '../../OptionSelector';
import Button from '../../Button';

import { colors, lighten } from '../../styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function InfoEdit(props) {
  const {
    type,
    projectID,
    getAdminAnalytics,
    data: { name, userID, date, hours, taskID },
  } = props;

  const {
    state: { team },
  } = useContext(Store);

  const initialUser = team.find((teammate) => teammate.userID === userID);
  const [selectedUser, setSelectedUser] = useState({
    ...initialUser,
    name: initialUser.fullName,
    id: initialUser.userID,
  });

  const [nameValue, setNameValue] = useState(name);
  const [isEditingName, setIsEditingName] = useState(false);

  const [hoursValue, setHoursValue] = useState(hours);
  const [isEditingHours, setIsEditingHours] = useState(false);

  const [dateValue, setDateValue] = useState(date);
  const [isEditingDate, setIsEditingDate] = useState(false);

  const handleParseHoursValue = (e) => {
    const { value } = e.target;
    if (value.length === 1 && value.indexOf('.') === 0) setHoursValue('0.');
    // If the starting value is '.'
    else if (value.indexOf('.') === value.length - 1) setHoursValue(value);
    // '.' is the last character
    else if (value.match(/^-?\d*(\.\d+)?$/g)) setHoursValue(value); // Matches floating point
  };

  const handleSelectUser = (user) => {
    setSelectedUser(user);
    setNameValue(user.fullName.split(' ')[0]);
    setIsEditingName(false);

    // Pass user to save handler due to asynchronous nature of useState (i.e. new selectedUser has not been set yet)
    handleSave(user);
  };

  const handleSave = (user) => {
    fetchUtil
      .post('/tracking/updateTask', {
        projectID,
        taskID,
        userID: user ? user.userID : selectedUser.userID,
        hours: parseFloat(hoursValue),
        date: dateValue,
      })
      .then(() => getAdminAnalytics());

    setIsEditingDate(false);
    setIsEditingHours(false);
    setIsEditingName(false);
  };

  return (
    <SectionContainer>
      <Header>{type}:&nbsp;</Header>
      <TypeContainer>
        <InputContainer>
          {!isEditingName && (
            <WordsContainer>
              <TextContainer>{nameValue}</TextContainer>
              <Button
                onClick={() => setIsEditingName(true)}
                className="icon transparent thin"
              >
                <StyledIcon icon={['fad', 'edit']} />
              </Button>
            </WordsContainer>
          )}
          {isEditingName && (
            <WordsContainer>
              <OptionSelector
                options={team.map((teammate) => ({
                  ...teammate,
                  name: teammate.fullName,
                  id: teammate.userID,
                }))}
                onSelectOption={handleSelectUser}
                selectedOption={selectedUser}
                hasAutocomplete={true}
                autocompletePlaceholder="Filter teammates..."
                buttonClasses="thin box basic"
                saveSelection={false}
                placeholder={nameValue}
              />
              <Button
                onClick={() => setIsEditingName(false)}
                className="thin icon transparent"
              >
                <StyledIcon icon={['fad', 'edit']} />
              </Button>
            </WordsContainer>
          )}
        </InputContainer>
        <InputContainer>
          {!isEditingHours && (
            <WordsContainer>
              <TextContainer>
                {hoursValue}
                &nbsp;hrs
              </TextContainer>
              <Button
                onClick={() => setIsEditingHours(true)}
                className="thin icon transparent"
              >
                <StyledIcon icon={['fad', 'edit']} />
              </Button>
            </WordsContainer>
          )}
          {isEditingHours && (
            <SmallInputConfirm
              theme="thin"
              handleInputChange={handleParseHoursValue}
              handleSaveClick={() => handleSave()}
              handleCancelClick={() => setIsEditingHours(!isEditingHours)}
              inputValue={hoursValue}
              placeholder="Enter word count"
              enableSave={!!hoursValue}
              cancelButtonVisible={!!hoursValue}
            />
          )}
        </InputContainer>
        <InputContainer>
          {!isEditingDate && (
            <WordsContainer>
              <TextContainer>{dateValue}</TextContainer>
              <Button
                onClick={() => setIsEditingDate(true)}
                className="thin icon transparent"
              >
                <StyledIcon icon={['fad', 'edit']} />
              </Button>
            </WordsContainer>
          )}
          {isEditingDate && (
            <InputConfirm
              theme="thin"
              inputType="date"
              handleInputChange={(e) => setDateValue(e.target.value)}
              handleSaveClick={() => handleSave()}
              handleCancelClick={() => setIsEditingDate(!isEditingDate)}
              inputValue={dateValue}
              placeholder="Enter word count"
              enableSave={!!dateValue}
              cancelButtonVisible={!!dateValue}
            />
          )}
        </InputContainer>
      </TypeContainer>
    </SectionContainer>
  );
}

InfoEdit.propTypes = {
  type: PropTypes.string.isRequired,
  projectID: PropTypes.number.isRequired,
  getAdminAnalytics: PropTypes.func.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    hours: PropTypes.number.isRequired,
    taskID: PropTypes.number.isRequired,
  }),
};

function RowInfoDialog(props) {
  const { row, getAdminAnalytics } = props;

  const [wordCount, setWordCount] = useState(row.wordCount);
  const [isEditingWordCount, setIsEditingWordCount] = useState(false);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setWordCount(parseInt(value, 10) || '');
  };

  const handleSaveWordCount = () => {
    fetchUtil
      .post('/tracking/updateWordCount', {
        projectID: row.project.props.projectID,
        wordCount,
      })
      .then(() => getAdminAnalytics());

    setIsEditingWordCount(false);
  };

  return (
    <Container>
      <SectionHeader>Word count</SectionHeader>
      {!isEditingWordCount && (
        <WordsContainer>
          <Header>Words:&nbsp;</Header>
          <TextContainer>
            <WordCount>{wordCount}</WordCount>
          </TextContainer>
          <Button
            onClick={() => setIsEditingWordCount(true)}
            className="thin icon transparent"
          >
            <StyledIcon icon={['fad', 'edit']} />
          </Button>
        </WordsContainer>
      )}
      {isEditingWordCount && (
        <InputConfirm
          handleInputChange={handleInputChange}
          handleSaveClick={handleSaveWordCount}
          handleCancelClick={() => setIsEditingWordCount(!isEditingWordCount)}
          inputValue={wordCount}
          placeholder="Enter word count"
          enableSave={!!wordCount}
          cancelButtonVisible={!!wordCount}
        />
      )}
      {row.illustrator && (
        <InfoEdit
          type="Illustrator"
          projectID={row.project.props.projectID}
          data={row.illustrator.props}
          getAdminAnalytics={getAdminAnalytics}
        />
      )}
      {row.voiceover && (
        <InfoEdit
          type="Voiceover"
          projectID={row.project.props.projectID}
          data={row.voiceover.props}
          getAdminAnalytics={getAdminAnalytics}
        />
      )}
      {row.videoEditor && (
        <InfoEdit
          type="Video Editor"
          projectID={row.project.props.projectID}
          data={row.videoEditor.props}
          getAdminAnalytics={getAdminAnalytics}
        />
      )}
    </Container>
  );
}

const Container = styled.div``;
const InputContainer = styled.div`
  margin-right: 5px;
`;
const TypeContainer = styled.div`
  display: flex;
`;

const SectionContainer = styled.div`
  margin-top: 15px;
`;

const SectionHeader = styled.h4`
  margin: 0;
  margin-bottom: 10px;
`;

const WordsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SmallInputConfirm = styled(InputConfirm)`
  input {
    width: 50px;
  }
`;

const StyledIcon = styled(FontAwesomeIcon)``;

const Header = styled.p`
  margin: 0;
  color: ${colors['light-grey-20']};
`;

const TextContainer = styled.div`
  padding: 2px 5px;
  background: ${lighten(colors.grey, 50)};
  border-radius: 3px;
  color: ${colors['light-grey-10']};

  display: flex;
  align-items: center;
`;

const WordCount = styled.h4`
  margin: 0;
`;

RowInfoDialog.propTypes = {
  getAdminAnalytics: PropTypes.func.isRequired,
  row: PropTypes.shape({
    illustrator: PropTypes.object,
    voiceover: PropTypes.object,
    videoEditor: PropTypes.object,
    project: PropTypes.object,
  }),
};

RowInfoDialog.defaultProps = {
  propName: '',
};

export default RowInfoDialog;
