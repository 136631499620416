import React, { useRef, useState } from 'react';

import { post } from '../../util/fetchUtil';

import Uploader from '../../app/Uploader';

import Avatar from '../Avatar';
import LoadingSpinner from '../LoadingSpinner';

import {
  avatarSize,
  Container,
  Input,
  AvatarWrapper,
  AvatarOverlay,
  OverlayBackground,
  Message,
} from './UserAvatarStyles';
import WarningDialog from '../dialogs/WarningDialog';
import useModal from '../../app/hooks/useModal';
import Dialog from '../Dialog';
import UserProps from '../../types/UserProps';
import { FileObjectProps } from '../../types/Browser';

type Props = {
  user: UserProps;
  onComplete: () => Promise<void>;
};

/**
 * Component that allows user to change avatar
 *
 * @class UserAvatar
 * @extends {React.Component}
 */
export default function UserAvatar(props: Props) {
  const { user, onComplete } = props;
  const [isLoading, setIsLoading] = useState(false);
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const errorDialog = useModal();

  /**
   * Upload to S3 and then save to database
   *
   * @param {Native File Object} file
   * @memberof UserAvatar
   */
  const saveAvatar = (file: FileObjectProps) => {
    // Initialize uploader
    const uploader = new Uploader();
    uploader
      .init()
      .then(() => {
        // Add file and upload
        uploader.addFile(file);

        // Set loading state
        setIsLoading(true);

        return uploader.upload(`users/${user.userID}/${file.name}`);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        errorDialog.show();
        return false;
      })
      // Add new avatar to DB
      .then((res) =>
        post('/user/updateAvatar', {
          src: res.url,
        })
      )
      // Update the user store
      .then(() => onComplete())
      .then(() => {
        // Remove loading state
        setIsLoading(false);
      });
  };

  const handleUploadClick = () => {
    if (hiddenInputRef.current) hiddenInputRef.current.click();
  };

  const handleFileUpload = (e: React.FormEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;

    if (files?.length) saveAvatar(files[0]);
  };

  return (
    <Container>
      <Dialog
        isVisible={errorDialog.isVisible}
        handleHideDialog={() => errorDialog.hide()}
      >
        <WarningDialog>
          Uh oh, something went wrong with that upload.
        </WarningDialog>
      </Dialog>
      <AvatarWrapper onClick={handleUploadClick}>
        <AvatarOverlay>
          <OverlayBackground />
          <Message>Upload a new image</Message>
        </AvatarOverlay>
        {!isLoading && <Avatar size={avatarSize} src={user.avatar} />}
        {isLoading && <LoadingSpinner />}
      </AvatarWrapper>
      <Input
        ref={hiddenInputRef}
        accept=".png,.jpeg,.jpg"
        type="file"
        onChange={handleFileUpload}
      />
    </Container>
  );
}
