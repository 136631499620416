import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import React, { useState, useEffect, useContext } from 'react';
import tippy from 'tippy.js';
import Tippy from '@tippyjs/react';
import moment from 'moment';
import SceneLoader from '../../app/SceneLoaderClass';

import {
  exportComments,
  downloadFile as downloadFileRow,
  deleteFile,
  exportSvgFile,
  fileInfo,
  sort,
} from './FileRowOptions';

import ExportVideo from '../dialogs/ExportVideo';

import OptionSelector from '../OptionSelector';
import Dialog from '../Dialog';
import Box, { Flex } from '../Box';
import Button from '../Button';
import Label from '../Label';

import { H4 } from '../styles/typography';
import { colors } from '../styles/colors';

import {
  fetchFile,
  downloadZipFile,
  downloadFile,
} from '../../app/fileManager';
import { getBasename, getExtension } from '../../utilities/files';

import {
  Container,
  Files,
  FilesContainer,
  FileTypeContainer,
  FileRow,
  MoreButton,
  CommentIcon,
  LeftContainer,
  RightContainer,
  ExportDownloadIcon,
  ExportPendingIcon,
  Name,
  StyledUploadBox,
} from './LayersPanelStyles';

import { Option, OptionsText } from '../OptionSelectorStyles';
import sortOptions from '../../utilities/sortFiles';
import { getHash } from '../../shared/links';
import SvgFilePreview from './SvgFilePreview';
import { post } from '../../util/fetchUtil';
import { fetchActiveProject } from '../../state/projects/actions';
import { Store } from '../../state/store';
import { getErrorString } from '../views/Project';

const EXPORT_TIMEOUT_HOURS = 4;

const SETTING_NAME = 'setting:svg-sort';

const saveSortSetting = (value) => {
  window.localStorage.setItem(SETTING_NAME, value);
};

const getSortSetting = () => {
  const res = window.localStorage.getItem(SETTING_NAME);
  return res;
};

function SvgFiles(props) {
  const {
    files,
    activeFile,
    activeProject,
    fileHasComments,
    setSelectedDelete,
    handleRowClick,
    onProgress,
    onDownloadError,
  } = props;

  const { dispatch } = useContext(Store);
  const [exportVideoDialogVisible, setExportVideoDialogVisible] =
    useState(false);

  const [selectedExportFiles, setSelectedExportFiles] = useState([]);
  const [currentSort, setCurrentSort] = useState(
    sortOptions.find((o) => o.id === getSortSetting())?.id || 'number'
  );
  const [reverseSort, setReverseSort] = useState(false);

  const handleSelectSort = (newSort) => {
    const currentSortOption = sortOptions.find((o) => o.id === currentSort);
    if (currentSortOption.id === newSort && currentSortOption.reverse) {
      setReverseSort(!reverseSort);
    } else {
      setReverseSort(false);
      setCurrentSort(newSort);
      saveSortSetting(newSort);
    }
  };

  useEffect(() => {
    tippy('[data-tippy-content]');
  }, [files]);

  // Loads a scene without loading to the dom to check for errors
  const checkStats = async (blob) => {
    const sceneLoader = new SceneLoader();
    const res = await sceneLoader.load(blob, { statsOnly: true });

    return res;
  };

  const handleAfterUpload = async (uploads) => {
    const stats = [];
    uploads.forEach((upload) => {
      stats.push(checkStats(upload.file));
    });

    const statsRes = await Promise.all(stats);

    // Add metadata to file
    const metaUpdates = [];
    statsRes.forEach((uploadStats, i) => {
      metaUpdates.push(
        post('/project/addSvgMeta', {
          fileID: uploads[i].fileID,
          ...uploadStats,
        })
      );
    });

    await Promise.all(statsRes);

    await fetchActiveProject(activeProject.projectID, dispatch);
  };

  useEffect(() => {
    if (selectedExportFiles.length) {
      setExportVideoDialogVisible(true);
    }
  }, [selectedExportFiles]);

  const downloadExportedVideo = async (e, file, fileExport) => {
    e.stopPropagation();
    const blob = await fetchFile(fileExport.data.url.replace('http', 'https'));
    await downloadFile(
      blob,
      `${getBasename(file.name)}${getExtension(fileExport.data.url)}`
    );
  };

  const handleDownloadAllSvgs = () => {
    downloadZipFile(files, onProgress);
  };

  const handleDownloadAllExports = () => {
    const exportedFiles = [];
    files.forEach((file) => {
      if (file.fileExports && file.fileExports.length) {
        // Get most recent export
        const sortedFiles = file.fileExports.sort(
          (a, b) => new Date(b) - new Date(a)
        );

        if (sortedFiles[0].data.url) {
          const type = getExtension(sortedFiles[0].data.url).slice(1);

          exportedFiles.push({
            data: {
              url: sortedFiles[0].data.url.replace('http', 'https'),
            },
            name: `${getBasename(file.name)}.${type}`,
            type,
          });
        }
      }
    });

    if (exportedFiles.length) {
      downloadZipFile(exportedFiles, onProgress);
    }
  };

  const currentSortOption = sortOptions.find((o) => o.id === currentSort);
  const comments = activeProject.comments.filter((c) =>
    files.some((file) => file.fileID === c.fileID)
  );

  const svgFiles = files
    .sort(
      reverseSort && currentSortOption.reverse
        ? currentSortOption.reverse
        : currentSortOption.sort
    )
    // eslint-disable-next-line complexity
    .map((file) => {
      const commentStatus = fileHasComments(file);

      const latestExport =
        file.fileExports && file.fileExports.length
          ? file.fileExports.sort(
              (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
            )[0]
          : null;

      // If it's been more than an hour, something's wrong with the export
      const hoursSinceExport =
        moment().diff(moment(latestExport?.createdOn)) / 1000 / 60 / 60;
      return (
        <FileRow
          darkMode
          key={file.fileID}
          onClick={() =>
            handleRowClick(
              getHash({
                projectID: activeProject.projectID,
                fileID: file.fileID,
              })
            )
          }
          isActive={activeFile.fileID === file.fileID}
        >
          <LeftContainer>
            <Tippy content={<SvgFilePreview src={file.data.url} />}>
              <Name darkMode>{file.name}</Name>
            </Tippy>
          </LeftContainer>
          <RightContainer>
            {latestExport && latestExport.status === 2 && (
              <ExportDownloadIcon
                style={{ width: 'unset' }}
                icon={['fad', 'file-video']}
                data-tippy-content="Download MP4 file"
                onClick={(e) => downloadExportedVideo(e, file, latestExport)}
              />
            )}
            {latestExport &&
              latestExport.status === 1 &&
              hoursSinceExport <= EXPORT_TIMEOUT_HOURS && (
                <Box data-tippy-content="Export in progress">
                  <ExportPendingIcon
                    style={{ width: 'unset' }}
                    icon={['fad', 'compact-disc']}
                  />
                </Box>
              )}
            {latestExport && latestExport.status === 0 && (
              <Box data-tippy-content="Error exporting">
                <FontAwesomeIcon
                  style={{ color: colors.red, width: 'unset' }}
                  icon={['fad', 'exclamation-circle']}
                />
              </Box>
            )}
            {latestExport &&
              latestExport.status === 1 &&
              hoursSinceExport > EXPORT_TIMEOUT_HOURS && (
                <Box data-tippy-content="Something went wrong, try exporting again.">
                  <FontAwesomeIcon
                    style={{ color: colors.red, width: 'unset' }}
                    icon={['fad', 'exclamation-circle']}
                  />
                </Box>
              )}
            {!!file.data.meta?.errors?.length && (
              <Tippy content={getErrorString(file.data.meta.errors)}>
                <CommentIcon status={commentStatus}>
                  <FontAwesomeIcon
                    style={{ width: 'unset', color: colors['light-red-20'] }}
                    icon={['fad', 'exclamation-circle']}
                  />
                </CommentIcon>
              </Tippy>
            )}
            {!!commentStatus && (
              <CommentIcon status={commentStatus}>
                <FontAwesomeIcon
                  style={{ width: 'unset' }}
                  icon={[
                    'fad',
                    commentStatus === 'unresolved'
                      ? 'comment-exclamation'
                      : 'comment-check',
                  ]}
                />
              </CommentIcon>
            )}
            <OptionSelector
              onClick={(e) => e.stopPropagation()}
              options={[
                fileInfo(file),
                exportSvgFile(file, setSelectedExportFiles),
                downloadFileRow(file, onProgress, onDownloadError),
                deleteFile(file, setSelectedDelete),
              ]}
            >
              <MoreButton icon transparent color={colors['light-grey-20']}>
                <FontAwesomeIcon
                  style={{ width: 'unset' }}
                  icon={['fas', 'ellipsis-v']}
                />
              </MoreButton>
            </OptionSelector>
          </RightContainer>
        </FileRow>
      );
    });

  const options = [];
  if (files.some((file) => file.fileExports && file.fileExports.length)) {
    options.push({
      id: 'download-all-exports',
      name: 'Download exports',
      icon: 'file-download',
      onClick: handleDownloadAllExports,
    });
  }

  if (files.length) {
    options.push({
      id: 'download-all',
      name: 'Download SVGs',
      icon: 'file-download',
      onClick: handleDownloadAllSvgs,
    });

    // options.push({
    //   id: 'export-all',
    //   name: 'Export all',
    //   component: (
    //     <Option onClick={() => setSelectedExportFiles(files)}>
    //       <OptionsText>
    //         <FontAwesomeIcon icon={['fad', 'file-export']} />
    //         &nbsp;&nbsp;Export all&nbsp;&nbsp;
    //         <Label color="teal">Beta</Label>
    //       </OptionsText>
    //     </Option>
    //   ),
    // });

    if (comments.length) {
      options.push(exportComments(activeProject.projectID, 'svg'));
    }

    options.push(
      sort(
        sortOptions,
        currentSort,
        handleSelectSort,
        currentSortOption.reverse && reverseSort
      )
    );
  }

  return (
    <Container>
      <Dialog
        isVisible={exportVideoDialogVisible}
        handleHideDialog={() => setExportVideoDialogVisible(false)}
      >
        <ExportVideo
          files={selectedExportFiles}
          projectID={activeProject.projectID}
          handleHideDialog={() => setExportVideoDialogVisible(false)}
        />
      </Dialog>
      <FileTypeContainer>
        <Flex style={{ justifyContent: 'space-between' }}>
          <H4 color="white">SVGs</H4>
          {!!options.length && (
            <OptionSelector
              onClick={(e) => e.stopPropagation()}
              options={options}
            >
              <Button
                icon
                transparent
                style={{ fontSize: 14 }}
                color={colors['light-grey-20']}
              >
                <FontAwesomeIcon icon={['fas', 'ellipsis-v']} />
              </Button>
            </OptionSelector>
          )}
        </Flex>
        {!!files.length && (
          <FilesContainer darkMode>
            <Files>{svgFiles}</Files>
          </FilesContainer>
        )}
        <FileActions>
          <StyledUploadBox
            types={['svg']}
            allowMultiple={true}
            onAfterUpload={handleAfterUpload}
            darkMode
          />
        </FileActions>
      </FileTypeContainer>
    </Container>
  );
}

const FileActions = styled.div`
  display: flex;

  input {
    display: none;
  }
`;

SvgFiles.propTypes = {
  files: PropTypes.array,
  activeFile: PropTypes.object,
  activeProject: PropTypes.object,
  fileHasComments: PropTypes.func.isRequired,
  setSelectedDelete: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  onProgress: PropTypes.func.isRequired,
  onDownloadError: PropTypes.func.isRequired,
};

SvgFiles.defaultProps = {
  files: [],
  activeFile: {},
  activeProject: {},
};

export default SvgFiles;
