import styled, { css } from 'styled-components/macro';

export const Container = styled.div``;
export const AppContainer = styled.div`
  height: 100vh;

  display: flex;
  flex-direction: column;
`;

export const HeaderBarContainer = styled.div`
  ${(props: ContainerProps) =>
    !props.visible &&
    css`
      display: none;
    `}
`;

export const Content = styled.div`
  height: 100%;
  overflow: auto;
`;

const ComponentContainer = styled.div`
  height: 100%;
`;

type ContainerProps = {
  visible?: boolean;
};

export const ProjectContainer = styled(ComponentContainer)`
  ${(props: ContainerProps) =>
    !props.visible &&
    css`
      display: none;
    `}
`;

export const HomeContainer = styled(ComponentContainer)`
  ${(props: ContainerProps) =>
    !props.visible &&
    css`
      display: none;
    `}
`;
