import React, { useEffect, useState } from 'react';

import { HashRouter, useParams } from 'react-router-dom';

import KitPublic from './KitPublic';
import { StoreProvider } from '../../state/store';
import { post } from '../../util/fetchUtil';

function KitPublicWrapper() {
  const [kit, setKit] = useState();
  const { slug } = useParams();

  const getKit = async () => {
    if (slug) {
      const res = await post('/assets/getPublicKit', {
        slug,
      });

      if (res.kit) {
        setKit(res.kit);
      }
    }
  };

  useEffect(() => {
    getKit();
    // eslint-disable-next-line
  }, [slug]);

  return (
    <HashRouter>
      <StoreProvider>
        <KitPublic kit={kit} />
      </StoreProvider>
    </HashRouter>
  );
}

KitPublicWrapper.propTypes = {};

export default KitPublicWrapper;
