import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useEffect } from 'react';

import Box, { Flex } from '../../Box';

import { P } from '../../styles/typography';
import { colors } from '../../styles/colors';

const DimensionLabel = styled(P)`
  color: ${colors['light-grey-30']};
  margin-right: 5px;
`;

function FileDimensions(props) {
  const { file } = props;

  const [image, setImage] = useState({});

  useEffect(() => {
    const newImage = new Image();
    newImage.onload = function () {
      setImage(newImage);
    };
    newImage.src = file.data.url;
  }, [file]);

  return (
    <Flex>
      <Box style={{ flex: 1 }}>
        <DimensionLabel>W</DimensionLabel>
        <P>{image.naturalWidth}px</P>
      </Box>
      <Box style={{ flex: 1 }}>
        <DimensionLabel>H</DimensionLabel>
        <P>{image.naturalHeight}</P>
      </Box>
    </Flex>
  );
}

FileDimensions.propTypes = {
  file: PropTypes.shape({
    data: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
};

FileDimensions.defaultProps = {
  file: {},
};

export default FileDimensions;
