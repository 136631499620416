import styled from 'styled-components/macro';

import React, { useState, useEffect } from 'react';

import { post } from '../../../util/fetchUtil';

import AssetControls from './AssetControls';

import { colors, lighten } from '../../styles/colors';
import useFileTagger from '../../../app/hooks/useFileTagger';
import FileTagger from './FileTagger';
import TagProps from '../../../types/TagProps';
import KitProps, { FileKitLinkProps } from '../../../types/KitProps';
import FileProps from '../../editor/types/FileProps';
import { FilterProps } from '../Kit';
import FileProjects from './FileProjects';
import fileProjects from '../../../app/assets/fileProjects';
import FileInfo from './FileInfo';
import FileDownload from './FileDownload';
import FileVersions from './FileVersions';
import KitActions from './KitActions';
import FileExporter from './FileExporter';
import FileDelete from './FileDelete';

const Container = styled.div`
  background: ${lighten(colors.grey, 57)};
  border-left: 1px solid ${colors['light-grey-50']};
  min-width: 300px;

  height: 100%;
`;

type Props = {
  handleUpdate: () => void;
  handleDeselectFiles: () => void;
  setFilters: (newFilters: FilterProps[]) => void;
  kit: KitProps;
  selectedFiles: FileProps[];
  canEdit: boolean;
};

function AssetControlsWrapper(props: Props) {
  const {
    selectedFiles,
    handleDeselectFiles,
    handleUpdate,
    setFilters,
    canEdit,
    kit,
  } = props;

  const [files, setFiles] = useState<FileProps[]>([]);
  const [fileTaggerUserInput, setFileTaggerUserInput] = useState('');

  const handleAfterUpload = async (res: any) => {
    const file = files[0];
    if (file) {
      const [fileData] = res;
      await post('/assets/updateVersion', {
        fileID: file.fileID,
        url: fileData.url,
        size: fileData.file.size,
      });

      await handleUpdate();
    }
  };

  const handleDelete = async () => {
    await post('/assets/deleteFile', {
      fileIDs: files.map((f) => f.fileID),
    });

    await handleUpdate();

    handleDeselectFiles();
  };

  const getFileKitLinks = async (
    fileIDs: number[]
  ): Promise<{ files: FileKitLinkProps[] }> =>
    post('/assets/getFileKitLinks', {
      fileIDs,
      kitID: kit.kitID || -1,
    });

  const getFileData = async () => {
    const res = await post('/files/getFileData', {
      fileUUIDs: selectedFiles.map((f) => f.uuid),
      kitID: kit.kitID || -1, // Need to pass a kitID
    });

    const fileKitLinksRes = await getFileKitLinks(
      res.files.map((f: FileProps) => f.fileID)
    );

    const {
      directories,
    }: { directories: { directoryID: number; fileID: number }[] } = await post(
      '/assets/getFileDirectories',
      {
        fileIDs: selectedFiles.map((f) => parseInt(f.fileID.toString(), 10)),
        kitID: kit.kitID || -1,
      }
    );

    const newFiles = res.files.map((f: FileProps) => ({
      ...f,
      kits: fileKitLinksRes.files
        .filter((linkFile) => linkFile.fileID === f.fileID)
        .map((kitFile) => ({
          ...kitFile,
          directoryID: directories.find((d) => d.fileID === kitFile.fileID)
            ?.directoryID,
        })),
    }));

    setFiles(newFiles);
  };

  const handleAddToKit = async (k: KitProps) => {
    await post('/assets/linkFilesToKit', {
      fileIDs: files.map((f) => f.fileID),
      kitID: k.kitID,
    });

    await getFileData();
  };

  // const handleAddToKitDirectory = async () => {};

  const handleRemoveFromKit = async (kitID: number) => {
    const { files: links } = await getFileKitLinks(files.map((f) => f.fileID));

    const linkIDs = links.filter((l) => l.kitID === kitID).map((l) => l.linkID);
    if (linkIDs.length) {
      await post('/assets/removeFileKitLinks', {
        linkIDs,
      });

      await getFileData();

      if (kit.kitID === kitID) {
        await handleUpdate();
      }
    }
  };

  const handleUpdateName = async (newName: string, file: FileProps) => {
    await post('/assets/updateName', {
      name: newName,
      fileID: file.fileID,
    });

    await handleUpdate();
  };

  const updateTags = () => {
    getFileData();
    setFileTaggerUserInput('');
  };

  const { addTag, createTag, removeTag } = useFileTagger({
    onCreateTag: updateTags,
    onAddTag: updateTags,
    onRemoveTag: updateTags,
  });

  const { addProject, removeProject } = fileProjects({
    onRemove: getFileData,
    onAdd: getFileData,
  });

  /**
   * Adds the tag as a search filter
   *
   * @param {Object} tag
   */
  const handleSearchTag = (tag: TagProps) => {
    setFilters([{ attr: 'tags', value: tag.name }]);
  };

  useEffect(() => {
    if (selectedFiles.length > 0) {
      getFileData();
    } else {
      setFiles([]);
    }
    // eslint-disable-next-line
  }, [selectedFiles]);

  const fileTaggerComponent = (
    <FileTagger
      files={files}
      addTag={addTag}
      createTag={createTag}
      removeTag={removeTag}
      userInput={fileTaggerUserInput}
      setUserInput={setFileTaggerUserInput}
      onTagClick={handleSearchTag}
      canEdit={canEdit}
    />
  );

  const fileProjectComponent = (
    <FileProjects
      files={files}
      addProject={addProject}
      removeProject={removeProject}
      canEdit={canEdit}
    />
  );

  const fileInfoComponent = (
    <FileInfo files={files} onSaveName={handleUpdateName} canEdit={canEdit} />
  );

  const fileDownloadComponent = <FileDownload files={files} />;
  const fileDeleteComponent = canEdit ? (
    <FileDelete files={files} onDelete={handleDelete} />
  ) : null;

  const components = [
    fileInfoComponent,
    fileTaggerComponent,
    canEdit && fileProjectComponent,
    fileDownloadComponent,
    canEdit && (
      <KitActions
        files={files}
        addToKit={handleAddToKit}
        removeFromKit={handleRemoveFromKit}
      />
    ),
    files.length === 1 ? (
      <FileVersions
        file={files[0]}
        s3Dir={`kits/${kit.kitID}`}
        handleAfterUpload={handleAfterUpload}
        canEdit={canEdit}
      />
    ) : undefined,
    files.length === 1 && canEdit && (
      <FileExporter file={files[0]} kitID={kit.kitID} />
    ),
    fileDeleteComponent,
  ].filter((el) => el) as React.ReactChild[];

  let controlsComponent = null;
  if (files.length > 0) {
    controlsComponent = (
      <AssetControls
        handleDeselectFiles={handleDeselectFiles}
        handleUpdate={handleUpdate}
        files={files}
        getFileData={getFileData}
        canEdit={canEdit}
        components={components}
      />
    );
  }

  return (
    <Container style={{ overflow: 'auto' }}>{controlsComponent}</Container>
  );
}

AssetControlsWrapper.defaultProps = {
  selectedFiles: [],
  kit: {},
};

export default AssetControlsWrapper;
