import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { transparentize } from 'polished';
import Tippy from '@tippyjs/react';
import Dialog from '../../Dialog';
import Box, { Flex } from '../../Box';
import Button from '../../RebassButton';
import { colors, fade } from '../../styles/colors';

import { getTimeStringFromMs } from '../../../app/utilities/durations';
import TimelineSettingsDialog from './TimelineSettingsDialog';

const CurrentTime = styled(Box)`
  padding: 5px 10px;

  font-size: 14px;

  color: ${fade('white', 30)};
  border-radius: 100px;

  background-color: ${colors.night};
`;

const ControlButton = styled(Button)`
  font-size: 13px;
  color: white;
  :hover,
  :focus {
    color: ${fade('white', 10)};
  }
`;

const Container = styled(Box)`
  padding: 5px;
  justify-content: space-between;
  align-items: center;

  background-color: ${colors['light-night-10']};
  border-bottom: 1px solid ${colors['light-night-30']};
`;

/**
 * Controls above the timeline for play / pause, skip, and settings
 */
function TimelineControls(props) {
  const {
    handlePlay,
    isPlaying,
    handleSkip,
    currentTime,
    duration,
    handleUpdateDuration,
    setPixelsPerMs,
    pixelsPerMs,
    canEdit,
    layersVisible,
    setLayersVisible,
  } = props;

  const [settingsDialogVisible, setSettingsDialogVisible] = useState(false);

  const handleSliderChange = (e) => {
    const { value } = e.target;

    setPixelsPerMs(value);
  };

  return (
    <Container display="flex">
      <Dialog
        isVisible={settingsDialogVisible}
        handleHideDialog={() => setSettingsDialogVisible(false)}
        darkMode={true}
      >
        <TimelineSettingsDialog
          duration={duration}
          handleUpdateDuration={handleUpdateDuration}
        />
      </Dialog>
      <Box>
        {canEdit && (
          <Tippy content="Toggle timeline layers">
            <Button
              variant={!layersVisible}
              color="white"
              bg={layersVisible ? transparentize(0.75, 'white') : 'transparent'}
              onClick={() => setLayersVisible(!layersVisible)}
            >
              <FontAwesomeIcon icon={['fad', 'stream']} />
            </Button>
          </Tippy>
        )}
      </Box>
      <Flex>
        <Flex style={{ marginRight: 20 }}>
          <ControlButton
            variant="transparent"
            color="white"
            onClick={() => handleSkip(-currentTime)}
          >
            <FontAwesomeIcon icon={['fad', 'fast-backward']} />
          </ControlButton>
          <ControlButton
            variant="transparent"
            color="white"
            onClick={() => handleSkip(-1000)}
          >
            <FontAwesomeIcon icon={['fad', 'backward']} />
          </ControlButton>
          <ControlButton
            variant="transparent"
            color="white"
            onClick={handlePlay}
          >
            {!isPlaying && <FontAwesomeIcon icon={['fas', 'play']} />}
            {isPlaying && <FontAwesomeIcon icon={['fas', 'pause']} />}
          </ControlButton>
          <ControlButton
            variant="transparent"
            color="white"
            onClick={() => handleSkip(1000)}
          >
            <FontAwesomeIcon icon={['fad', 'forward']} />
          </ControlButton>
          <ControlButton
            variant="transparent"
            color="white"
            onClick={() => handleSkip(duration - currentTime)}
          >
            <FontAwesomeIcon icon={['fad', 'fast-forward']} />
          </ControlButton>
        </Flex>
        <CurrentTime>{getTimeStringFromMs(currentTime)}</CurrentTime>
      </Flex>
      <Flex c>
        <Button
          variant="transparent"
          color="white"
          onClick={() => setPixelsPerMs(pixelsPerMs - 0.01)}
        >
          <FontAwesomeIcon icon={['fad', 'minus-square']} />
        </Button>
        <input
          type="range"
          min="0.01"
          max="0.20"
          step="0.01"
          value={pixelsPerMs}
          onChange={handleSliderChange}
        />
        <Button
          onClick={() => setPixelsPerMs(pixelsPerMs + 0.01)}
          variant="transparent"
          color="white"
        >
          <FontAwesomeIcon icon={['fad', 'plus-square']} />
        </Button>
        {canEdit && (
          <Button
            variant="transparent"
            onClick={() => setSettingsDialogVisible(true)}
            color={fade('white', 30)}
          >
            <FontAwesomeIcon icon={['fad', 'cog']} />
          </Button>
        )}
      </Flex>
    </Container>
  );
}

const { number, func, bool } = PropTypes;

TimelineControls.propTypes = {
  handlePlay: func.isRequired,
  isPlaying: bool.isRequired,
  handleSkip: func.isRequired,
  currentTime: number.isRequired,
  duration: number.isRequired,
  handleUpdateDuration: func.isRequired,
  setPixelsPerMs: func.isRequired,
  pixelsPerMs: number.isRequired,
  canEdit: bool,
  layersVisible: bool,
  setLayersVisible: func.isRequired,
};

TimelineControls.defaultProps = {
  canEdit: true,
};

export default TimelineControls;
