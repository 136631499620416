import { useState, useEffect, MutableRefObject } from 'react';

/**
 * A hook that returns whether or not the referenced element is dragging
 *
 * @param {ReactRef} ref
 * @param {[Any]} [deps=[]] - array of dependencies
 * @param {Object} options
 * {
 *   onMouseDown: func,
 *   onMouseUp: func,
 *   onMouseMove: func,
 *   onDrag: func,
 * }
 * @returns
 */

type OptionProps = {
  onMouseDown?: (e: MouseEvent) => void;
  onMouseUp?: (e: MouseEvent) => void;
  onMouseMove?: (e: MouseEvent) => void;
  onDrag: (e: DragEvent) => void;
};

export default (
  ref: MutableRefObject<Element | null>,
  deps: any[],
  options: OptionProps
): { isDragging: boolean } => {
  const {
    onMouseDown = () => {},
    onMouseUp = () => {},
    onMouseMove = () => {},
    onDrag = () => {},
  } = options;

  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = (e: any) => {
    setIsDragging(true);

    onMouseDown(e);
  };

  const handleMouseUp = (e: any) => {
    setIsDragging(false);

    onMouseUp(e);
  };

  const handleMouseMove = (e: any) => {
    e.preventDefault();

    onMouseMove(e);

    if (isDragging) {
      onDrag(e);
    }
  };

  useEffect(() => {
    const element = ref.current;
    if (element) {
      element.addEventListener('mousedown', handleMouseDown);
      element.addEventListener('mouseup', handleMouseUp);
      element.addEventListener('mousemove', handleMouseMove);

      return () => {
        element.removeEventListener('mousedown', handleMouseDown);
        element.removeEventListener('mouseup', handleMouseUp);
        element.removeEventListener('mousemove', handleMouseMove);
      };
    }

    return () => {};
    // eslint-disable-next-line
  }, [...deps, isDragging]);

  return { isDragging };
};
