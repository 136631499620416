import { post } from '../../../util/fetchUtil';

import { uploadFile, getFilesFromEvent } from '../../fileManager';
import { isMediaType } from '../../../shared/utilities/fileTypes';
import { getBasename, loadImage, getExtension } from '../../../utilities/files';
import { importSvgElements } from '../svgParser';

import uuid from '../../utilities/uuid';
import { getSvgCoords } from '../coords';
import create from '../../../editor/create';
import { getMaxKeyframe } from '../keyframes';
import flattenElements from '../util/flattenElements';
import { ElementProps, ImageElementProps } from '../../../ui/editor/types/ElementProps';
import FileProps from '../../../ui/editor/types/FileProps';
import { FileObjectProps } from '../../../types/Browser';

export const formatDuration = (newElements: ElementProps[]) => {
  const minDuration = 10000;
  let totalDuration = minDuration;

  const flattenedEls = flattenElements(newElements);
  const maxKeyframe = getMaxKeyframe(flattenedEls);

  // Update duration for each element
  if (maxKeyframe && maxKeyframe > minDuration) {
    flattenedEls.forEach((el) => {
      // eslint-disable-next-line no-param-reassign
      el.duration = maxKeyframe;
    });

    // Get the new duration from the imports
    totalDuration = maxKeyframe;
  }

  return totalDuration;
};

export /**
 * Adds images to the files table
 *
 * @param {ElementProps[]} newElements
 */
const formatImages = async (newElements: ElementProps[]): Promise<void> => {
  const flattenedEls = flattenElements(newElements);

  const imageElements = flattenedEls.filter((el) => el.type === 'image') as ImageElementProps[];
  const filesRes = await post('/files/addFiles', {
    files: imageElements.map((el) => ({
      name: el.name,
      type: getExtension(el.src).slice(1),
      size: 0,
      url: el.src,
    })),
  });

  // Add fileID to element data
  filesRes.files.forEach((file: FileProps) => {
    const element = imageElements.find((el) => el.src === file.data.url);
    if (element) element.fileID = file.fileID;
  });
};

/**
 * Helper function used in the Editor.jsx component for
 * handling file drops into canvas
 *
 * @param {Event} e
 * @param {SVGElement} svgElement
 * @param {Object} state
 * @returns {Object}
 * {
 *  progress: {Number}, - the new progress that the scenes should use
 *  elements: {[SvgElement]}
 * }
 */ const handleDropFile = async (
  e: React.DragEvent,
  svgElement: SVGElement,
  state: {
    progress: number;
    duration: number;
    currentTime: number;
    projectID: number;
  }
): Promise<{
  progress: number;
  duration: number;
  elements: ElementProps[];
}> => {
  const { progress, duration, currentTime, projectID } = state;

  let totalDuration = 0;

  // Get coordinates to drop file into correct spot
  const { x, y } = getSvgCoords(svgElement, e);

  const uploads: Promise<{ file: FileObjectProps; url: string }>[] = [];
  const files = getFilesFromEvent(e);
  const images = Object.values(files).filter((file) =>
    isMediaType(getExtension(file.name).slice(1), 'image')
  );

  const svgs = Object.values(files).filter((file) =>
    isMediaType(getExtension(file.name).slice(1), 'svg')
  );

  const newElements = [];

  if (images.length) {
    images.forEach((file) => {
      uploads.push(uploadFile(file, `${uuid()}${getExtension(file.name)}`));
    });

    const resArray = await Promise.all(uploads);

    // Add to files and associate with project
    const filesDbRes = await Promise.all(
      resArray.map((uploadRes, i) => {
        const { size, name } = images[i];
        const type = getExtension(name);
        return post('/project/addFile', {
          projectID,
          name,
          type: type.slice(1),
          size,
          data: {
            url: uploadRes.url,
            // projectAssetType: 'image-asset',
          },
        });
      })
    );

    // Load all images to get dimensions
    const imageArray = await Promise.all(
      resArray.map((res) => loadImage(res.url))
    );

    // Combine the res array and images
    const imagesArray = resArray.map((res, i) => ({
      url: res.url,
      img: imageArray[i],
      name: getBasename(files[i].name),
      fileID: filesDbRes[i].fileID,
    }));

    // Add in default animations for the images
    imagesArray.forEach((image) => {
      const animDuration = 1000;
      totalDuration += animDuration;

      newElements.push(
        create.image(
          {
            src: image.url,
            fileID: image.fileID,
            name: image.name,
            duration,
            currentProps: {
              translateX: x - image.img.width / 2,
              translateY: y - image.img.height / 2,
            },
          },
          { animStart: currentTime, animDuration }
        )
      );
    });
  }

  // Handle dropped SVGs
  if (svgs && svgs.length) {
    const svgTextArray: string[] = [];
    // Get svgs as string
    svgs.forEach((svg) => {
      svgTextArray.push(svg.text());
    });

    const res = await Promise.all(svgTextArray);

    // Import each svg
    const svgImports = await Promise.all(
      res.map((svgText) => importSvgElements(svgText, duration, currentTime))
    );

    // Flatten svg file imports into elements
    newElements.push(...svgImports.reduce((arr, svg) => [...arr, ...svg], []));

    // Add elements and format image elements
    totalDuration = formatDuration(newElements);

    // Add images to files database and attach fileID
    await formatImages(newElements);
  }

  return {
    progress: progress + totalDuration / duration,
    duration: totalDuration,
    elements: newElements,
  };
};

export default handleDropFile;
