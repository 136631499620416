import styled, { css } from 'styled-components/macro';

import { colors, darken, fade, lighten } from '../styles/colors';
import Button from '../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const AuthorContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
`;
export const Container = styled.div`
  padding: 10px 15px;

  ${(props) =>
    props.isReply &&
    css`
      margin-bottom: 15px;
      padding-bottom: 0;
      border-left: 1px solid
        ${props.darkMode ? fade('white', 80) : lighten(colors.grey, 45)};

      :not(:last-child) {
        padding-bottom: 15px;
        margin-bottom: 0;
      }
    `}

  cursor: pointer;

  img {
    width: 100%;
  }
`;
export const UpperContainer = styled.div`
  margin-bottom: 10px;
`;
export const LowerContainer = styled.div``;
export const CommentTextContainer = styled.div`
  p {
    margin: 0;
    white-space: pre-line;
  }
`;
export const AnnotationIcon = styled(FontAwesomeIcon)`
  color: ${colors.teal};
  background: ${colors['light-teal-40']};
  padding: 5px;
  font-size: 22px;
  border-radius: 100%;
  margin-right: 10px;
`;
export const AuthorName = styled.h4`
  font-size: 16px;
  margin: 0;
  margin-right: 5px;
  color: ${(props) => (props.darkMode ? fade('white', 35) : colors.grey)};
`;
export const AuthorText = styled.p`
  font-size: 16px;
  margin: 0;
  color: ${colors['light-grey-20']};
`;
export const AuthorDate = styled(AuthorText)`
  color: ${colors['light-grey-30']};
`;
export const ActionButton = styled(Button)`
  display: inline-block;
  padding: 3px 6px;
  border-radius: 3px;

  cursor: pointer;

  color: ${(props) =>
    props.darkMode ? fade('white', 50) : colors['light-grey-20']};

  ${(props) =>
    props.isResolved &&
    css`
      color: ${colors.green};
    `}
`;
export const DeleteButton = styled(ActionButton)`
  color: ${colors.red};
`;
export const SaveButton = styled(ActionButton)`
  color: ${colors.green};
  &:hover,
  &:focus {
    background-color: ${colors['light-green-40']};
  }

  &:active {
    background-color: ${colors['light-green-30']};
  }
`;
export const EditingInputContainer = styled.div`
  margin-top: 15px;
  textarea {
    width: 100%;
  }
`;
export const ResolvedInfoContainer = styled.div`
  background-color: ${colors['light-grey-50']};
  margin-top: 5px;

  padding: 3px 6px;
  border-radius: 3px;

  color: ${colors['light-grey-20']};
  font-size: 14px;

  ${(props) =>
    props.darkMode &&
    css`
      background-color: ${fade('white', 75)};
      color: ${colors.night};
    `}
`;
export const CommentTimestamp = styled.span`
  color: ${(props) =>
    props.darkMode ? lighten(colors.teal, 20) : colors.teal};
  opacity: 0.75;

  margin-right: 5px;
`;
export const ReplyContainer = styled.div`
  textarea,
  input {
    width: 100%;
  }
`;
export const ReplyButton = styled.p`
  color: ${(props) =>
    props.darkMode ? fade('white', 50) : colors['light-grey-20']};
  font-size: 14px;
  font-weight: 400;

  margin: 0;
  padding: 5px 0;

  &:hover,
  &:focus {
    color: ${(props) =>
      props.darkMode ? lighten(colors.blue, 20) : colors.blue};
  }

  &:active {
    color: ${(props) =>
      props.darkMode ? lighten(colors.blue, 15) : darken('blue', 5)};
  }
`;
export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
