import SvgElement from './SvgElement';
import transformUtil from '../app/editor/transform';

class GroupElement extends SvgElement {
  constructor(args = {}) {
    super({
      ...args,
    });

    this.type = 'group';

    const { elements = [] } = args;

    this.elements = elements;
  }

  /**
   * Before ungrouping, we apply this group's transformations to its direct children
   *
   * @memberof GroupElement
   */
  onUngroup() {
    // Apply transformations to child elements before ungrouping
    this.elements.forEach((element) => {
      const newTransform = transformUtil.merge(
        element.currentProps,
        this.currentProps
      );

      element.update('currentProps', {
        ...element.currentProps,
        ...newTransform,
      });

      // element.update('keyframes', {
      //   ...newKeyframes,
      // });
    });
  }

  // transform(matrix) {
  //   super.transform(matrix);
  // }
}

export default GroupElement;
