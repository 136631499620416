import {
  ElementsArrayProps,
  MixedElementProps,
} from '../../ui/editor/types/ElementProps';

type KeyframeProps = {
  value: (el: MixedElementProps) => number; // FIXME:
  duration: number;
};

export type AnimationProps = {
  id: string;
  name: string;
  types?: string[];
  keyframes: {
    [index: string]: KeyframeProps[];
  };
};

export const animations: AnimationProps[] = [
  {
    id: 'fade-in',
    name: 'Fade in',
    keyframes: {
      opacity: [
        {
          value: () => 0,
          duration: 0,
        },
        {
          value: () => 1,
          duration: 1000,
        },
      ],
    },
  },
  {
    id: 'write-text',
    name: 'Write Text',
    types: ['text'],
    keyframes: {
      text: [
        {
          value: () => 0,
          duration: 0,
        },
        {
          value: () => 1,
          duration: 1000,
        },
      ],
    },
  },
  {
    id: 'path-stroke',
    name: 'Draw Path',
    types: ['path'],
    keyframes: {
      stroke: [
        {
          value: () => 0,
          duration: 0,
        },
        {
          value: () => 1,
          duration: 1000,
        },
      ],
    },
  },
  {
    id: 'scale-in',
    name: 'Scale in',
    keyframes: {
      scale: [
        {
          value: () => 0,
          duration: 0,
        },
        {
          value: () => 1,
          duration: 1000,
        },
      ],
      position: [
        {
          // Calculate offset so it expands from the center
          value: (el: MixedElementProps) => {
            // FIXME: ONLY WORKS IF AT ORIGIN
            const domEl = document.getElementById(el.id) as any; // FIXME:
            if (domEl) {
              return el.currentProps.translateX + domEl.getBBox().width / 2;
            }
          },
          duration: 0,
        },
        {
          value: (el: MixedElementProps) => el.currentProps.translateX,
          duration: 1000,
        },
      ],
    },
  },
  {
    id: 'follow-path',
    name: 'Follow Path',
    keyframes: {
      path: [
        {
          value: () => 0,
          duration: 0,
        },
        {
          value: () => 1,
          duration: 1000,
        },
      ],
    },
  },
];

export const addAnimation = (
  animation: AnimationProps,
  elements: ElementsArrayProps,
  time: number
): void => {
  elements.forEach((element) => {
    Object.keys(animation.keyframes).forEach((key) => {
      animation.keyframes[key].forEach((keyframe: KeyframeProps) => {
        // Add new keyframes
        element.addKeyframe(
          key,
          keyframe.value(element),
          keyframe.duration + time
        );
      });
    });
  });
};
