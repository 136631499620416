import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { Link, useRouteMatch } from 'react-router-dom';

import Tippy from '@tippyjs/react';
import { Store } from '../../state/store';
import { colors } from '../styles/colors';
import { Flex } from '../Box';

import {
  NotificationDot,
  ViewContainer,
  IconContainer,
  IconButton,
} from './NavigationStyles';

import { BellNotification } from './HeaderBarStyles';
import OsmosisIcon from '../icons/OsmosisIcon';
import Text from '../Text';

type Props = {
  hasActiveFile: boolean;
  hasActiveComments: boolean;
};

function ProjectNavigation(props: Props) {
  const { hasActiveFile, hasActiveComments } = props;

  const context = useContext(Store);
  const {
    state: { misspelledWords, savedWords, activeProject, activeFile },
  } = context;

  // Determine which panel is active based on route
  let panelVisible = '';

  if (useRouteMatch('/project/:projectID/files')) panelVisible = 'files';

  if (useRouteMatch('/project/:projectID/files/:fileID/comments'))
    panelVisible = 'comments';

  if (useRouteMatch('/project/:projectID/editor')) panelVisible = 'editor';
  if (useRouteMatch('/projectContents/:projectID')) panelVisible = 'contents';
  if (useRouteMatch('/project/:projectID/osmosis')) panelVisible = 'osmosis';

  const filteredWords = misspelledWords.filter(
    (word: string) => !savedWords.includes(word)
  );

  const hasMisspelledWords = filteredWords.length > 0;

  return (
    <ViewContainer>
      <IconContainer>
        <Tippy content="Files">
          <Link
            to={`/project/${activeProject.projectID}/files${
              hasActiveFile ? `/${activeFile.fileID}` : ''
            }`}
          >
            <IconButton isActive={panelVisible === 'files'}>
              <FontAwesomeIcon icon={['fad', 'layer-group']} />
            </IconButton>
          </Link>
        </Tippy>
        {hasActiveFile && (
          <Link
            to={`/project/${activeProject.projectID}/files/${activeFile.fileID}/comments`}
          >
            <IconButton isActive={panelVisible === 'comments'}>
              {(hasActiveComments || hasMisspelledWords) && (
                <NotificationDot>
                  <FontAwesomeIcon icon={['fad', 'circle']} />
                </NotificationDot>
              )}
              <FontAwesomeIcon icon={['fad', 'comment']} />
            </IconButton>
          </Link>
        )}
        <Tippy content="Scene editor">
          <Link to={`/project/${activeProject.projectID}/editor/dashboard`}>
            <IconButton isActive={panelVisible === 'editor'}>
              <BellNotification
                style={{ right: -10, backgroundColor: colors.teal }}
              >
                Beta
              </BellNotification>
              <FontAwesomeIcon icon={['fad', 'pencil']} />
            </IconButton>
          </Link>
        </Tippy>
        <Tippy content="Osmosis Learn Page">
          <Link to={`/project/${activeProject.projectID}/osmosis`}>
            <IconButton isActive={panelVisible === 'osmosis'}>
              <Flex c>
                <OsmosisIcon color="white" />
              </Flex>
            </IconButton>
          </Link>
        </Tippy>
      </IconContainer>

      <Flex alignItems="center">
        <Text color="light-6">{activeFile.name}</Text>
      </Flex>

      <IconContainer>
        <Tippy content="Settings">
          <Link to={`/projectContents/${activeProject.projectID}`}>
            <IconButton isActive={panelVisible === 'contents'}>
              <FontAwesomeIcon icon={['fad', 'cog']} />
            </IconButton>
          </Link>
        </Tippy>
      </IconContainer>
    </ViewContainer>
  );
}

ProjectNavigation.propTypes = {
  activeView: PropTypes.string.isRequired,
  hasActiveFile: PropTypes.bool.isRequired,
  hasActiveComments: PropTypes.bool.isRequired,
};

export default ProjectNavigation;
