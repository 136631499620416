import { useEffect, useState } from 'react';

type LocalType = 'string' | 'object' | 'boolean';

type StateType = string | Record<string, any> | boolean;

const parseToString = (value: StateType) => {
  let newValue = '';
  if (typeof value === 'string') {
    newValue = value;
  } else if (typeof value === 'boolean') {
    newValue = value.toString();
  } else if (typeof value === 'object') {
    newValue = JSON.stringify(value);
  }

  return newValue;
};

const parseFromString = (value: string, type: LocalType): StateType => {
  let newValue: StateType = value;
  switch (type) {
    case 'string':
      newValue = value;
      break;
    case 'object':
      try {
        newValue = JSON.parse(value);
      } catch (e) {
        // Silent
      }
      break;
    case 'boolean':
      newValue = value === 'true';
      break;

    default:
  }

  return newValue;
};

type Options = {
  type?: LocalType;
};

export default function useLocalStorage(
  key: string,
  defaultValue = '',
  options: Options = {}
): [StateType, (newValue: string) => void] {
  const { type = 'string' } = options;
  const [value, setValue] = useState<StateType>(defaultValue);

  const setLocal = (newValue: string) => {
    if (window.localStorage) window.localStorage.setItem(key, newValue);
  };

  const set = (newValue: StateType) => {
    const stringValue = parseToString(newValue);

    setValue(newValue);
    setLocal(stringValue);
  };

  useEffect(() => {
    const newValue = window.localStorage.getItem(key) || defaultValue;
    const parsedValue = parseFromString(newValue, type);
    set(parsedValue);
    // eslint-disable-next-line
  }, []);

  return [value, set];
}
