import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components/macro';

import characters from '../../app/data/characters';

import SvgNaming from './SvgNaming';
import FileNaming from './FileNaming';
import BrandColors from './BrandColors';
import sceneBuilder from './data/sceneBuilder';

const Screenshot = styled.img`
  max-width: 75%;
  max-height: 500px;
`;

const getCharacterElements = (array) =>
  array.map((element) => (
    <div key={element.key} className="character">
      {element.key}
    </div>
  ));

const getCharactersWithShortcuts = (array) =>
  array.map((element) => (
    <div key={element.key}>
      <div className="character">
        <div className="key">{element.key}</div>
        <div className="shortcut">
          {element.keystroke &&
            element.keystroke.map((key) => (
              <div key={key}>
                <div className="shortcut-key">{key}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  ));

export default [
  {
    header: 'Asset Manager',
    content: (
      <>
        <p>
          The asset manager contains all the image files created by our content
          team and can be used to download and edit those image assets.
        </p>
        <a
          href="https://osmosify.osmosis.org/#/assets"
          target="_blank"
          rel="noopener noreferrer"
        >
          osmosify.osmosis.org/#/assets
        </a>
      </>
    ),
    subSections: [
      {
        header: 'Kits',
        content: (
          <p>
            The asset manager is split into different &quot;Kits&quot;, e.g. we
            have a Marketing Kit, a Video Assets Kit, etc.
          </p>
        ),
        subSections: [
          {
            header: 'Visibility',
            content: (
              <>
                <h4>Osmosis</h4>
                <p>
                  By default, kits are only accessible to users with an
                  @osmosis.org email address, i.e. Osmosis teammates.
                </p>

                <h4>Anyone can view</h4>
                <p>
                  This makes the kit publicly available! This should only be
                  done for certain kits that users outside of Osmosis need to
                  access, since all images placed into them will be accessible
                  to anybody with the link.
                </p>

                <p>
                  To set the visibility for a kit, click on the settings icon on
                  the kit, or navigate to{' '}
                  <code>
                    osmosify.osmosis.org/#/assets/[KIT_NUMBER]/settings
                  </code>
                </p>
                <Screenshot src="https://osmosify.s3.amazonaws.com/FIYGwJ0vRbWWFZDx_i5HmgekQxm1qSGS.png" />
              </>
            ),
          },
          {
            header: 'Search',
            content: (
              <>
                <p>
                  To search all assets in the asset manager, click{' '}
                  <b>Browse all kits</b> at the top of the assets homepage, or
                  go to&nbsp;
                  <a
                    href="https://osmosify.osmosis.org/#/assets/all"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    https://osmosify.osmosis.org/#/assets/all
                  </a>
                  .
                </p>

                <p>
                  You can also search within kits by first clicking on the kit
                  you&apos;d like to search through via the asset manager
                  homepage.
                </p>

                <p>
                  Using the search bar will look for files that match the search
                  term&apos;s name or tags, in that order. You can also filter
                  items by additional criteria by selecting &quot;Filters&quot;
                  and adding a filter, or by selecting a &quot;folder&quot;.
                  Each search criteria will appear at the top. Search results
                  are returned as if every criteria was an &quot;AND&quot;.
                </p>

                <p>
                  E.g. below the conceptual folder is selected, so you&apos;ll
                  see all the items in that folder. Then if you type a search
                  term in the search bar (like &apos;fin&apos;) and press enter,
                  now only items that are in the selected folder AND match the
                  search term will appear.
                </p>
                <Screenshot src="https://osmosify.s3.amazonaws.com/M8NJRqpOSjGdE9uez_60TZYpQZ2v9TJ4.png" />
              </>
            ),
          },
          {
            header: 'Tags',
            content: (
              <>
                <p>
                  Any number of tags can be applied to files by clicking on the
                  file and then adding the tag in the right panel. Tags add
                  additional keywords to files for search purposes.
                </p>

                <Screenshot src="https://d16qt3wv6xm098.cloudfront.net/oXTEmdJgSMWduwHVRzcsAc6gSF_S_fme/_.png" />
              </>
            ),
          },
        ],
      },
    ],
  },
  {
    header: 'Brand',
    subSections: [
      {
        header: 'Colors',
        content: <BrandColors />,
      },
    ],
  },
  sceneBuilder,
  {
    header: 'Projects',
    content: (
      <p>
        Projects are shown on the main page and are automatically pulled and
        populated from the Trello board.
      </p>
    ),
    subSections: [
      {
        header: 'Project permissions',
        content: (
          <div>
            <p>
              Project permissions determine who can take certain actions on
              projects, and are marked in this guide with the following special
              tags:
            </p>
            <ul className="project-actions">
              <li>
                <span className="action admin">Admin</span>
                <span> - Only an admin user has can perform the action.</span>
              </li>
              <li>
                <span className="action owner">Owner</span>
                <span>
                  {' '}
                  - Project owners can perform the action (in addition to admin
                  users)
                </span>
              </li>
            </ul>
          </div>
        ),
      },
      {
        header: 'Project layers',
        content: (
          <p>
            Clicking the project card will take you to the project layers view.
            The project layers view is where you can upload and record scenes
            for the specified project.
          </p>
        ),
        img: '/assets/images/manual/projects-view.gif',
        subSections: [
          {
            header: 'Trello projects',
            content: (
              <p>
                Projects are automatically imported from the Trello board. Users
                attached to a particular Trello project will automatically be
                subscribed to the project as well. Unsubscribing from a project,
                however, <em>will not</em> affect the Trello board.
              </p>
            ),
          },
          {
            header: 'Custom projects',
            content: (
              <p>
                You can also create your own projects by clicking &quot;New
                project.&quot; You can add files, subscribe to, and do anything
                else you could do with a Trello-based project.
              </p>
            ),
          },
          {
            header: 'Adding SVGs',
            content: (
              <div>
                <p>
                  When you add a scene file to Osmosify, it will{' '}
                  <b>automatically be uploaded and saved</b> to the project. You
                  can drag and drop multiple files at once. The green check-mark
                  icon indicates the file has saved. You\&apos;ll be able to
                  access these files later.
                </p>
                <p>
                  Adding a file with the same name will automatically overwrite
                  the previous file. Previous versions, however, <em>are</em>{' '}
                  saved on the server.
                </p>
              </div>
            ),
            img: '/assets/images/manual/projects-upload-svg.gif',
          },
          {
            header: 'Editing layers',
            content: (
              <div className="negative message">
                <span role="img" aria-label="alarm">
                  🚨
                </span>{' '}
                Edits to the SVG layer properties (e.g. the transition)
                currently do not save{' '}
                <span role="img" aria-label="alarm">
                  🚨
                </span>
              </div>
            ),
          },
          {
            header: 'Exporting video',
            content: (
              <div className="warning message">
                <span role="img" aria-label="construction">
                  👨‍🔧
                </span>{' '}
                Under construction. Check back soon.{' '}
                <span role="img" aria-label="construction">
                  👨‍🔧
                </span>
              </div>
            ),
          },
        ],
      },
      {
        header: 'Project settings',
        content: (
          <div>
            <p>
              All information related to a particular project is kept here. The
              projects settings can be accessed through the{' '}
              <FontAwesomeIcon icon={['fad', 'cog']} /> icon in the projects
              view.
            </p>
            <p>The following actions can be performed:</p>
            <ul>
              <li>View and change project user subscriptions</li>
              <li>Archive or delete the project</li>
              <li>Change project status</li>
              <li>Add, remove, and download files</li>
              <li>Add credits information (coming soon)</li>
            </ul>
          </div>
        ),
        img: '/assets/images/manual/project-settings-view.gif',
        subSections: [
          {
            header: 'Subscribing',
            content: (
              <p>
                Subscribing to a project causes it to show up in &quot;My
                projects&quot; on the home page. This allows you to keep track
                of any projects you&apos;re currently working on.
              </p>
            ),
            img: '/assets/images/manual/project-subscription.gif',
          },
          {
            header: <span>Renaming</span>,
            content: (
              <p>
                Rename a project. Projects cannot share the same name, so the
                new name must be unique. If the project is linked to a Trello
                card, the Trello card&apos;s name will also be updated.
              </p>
            ),
          },
          {
            header: (
              <span>
                Archiving <span className="action owner">Owner</span>
              </span>
            ),
            content: (
              <p>
                Archiving a project will essentially hide it from the primary
                view. You can see archived projects by clicking &quot;Show
                archived projects&quot; at the bottom of the home page section
                you&apos;re on.
              </p>
            ),
          },
          {
            header: (
              <span>
                Deleting <span className="action owner">Owner</span>
              </span>
            ),
            content: (
              <p>Deleted projects will no longer be loaded and accessible.</p>
            ),
          },
          {
            header: 'Trello',
            content: (
              <p>
                Information for many of the projects is automatically pulled
                from Trello.
              </p>
            ),
            subSections: [
              {
                header: 'Auto-import from a board',
                content: (
                  <div>
                    <p>
                      A project folder can be set to import new projects from
                      the cards on a specified Trello board. To accomplish this,
                      head over to{' '}
                      <a
                        href="https://osmosify.osmosis.org/admin#/folders"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        the admin panel
                      </a>{' '}
                      (If you need permissions to access, ping Tanner or
                      somebody who can grant permissions), and follow these
                      steps:
                    </p>
                    <ol>
                      <li>
                        <p>
                          Click the folder you want to link to a Trello board,
                          you&apos;ll see a prompt to enter a &quot;Board
                          Id&quot;
                        </p>
                        <Screenshot src="/assets/images/manual/enter-board-id.png" />
                      </li>
                      <li>
                        <p>Head over to your Trello board</p>
                      </li>
                      <li>
                        <p>
                          Click on a card in a list that you want Osmosify to
                          watch for new projects
                        </p>
                      </li>
                      <li>
                        <p>
                          Click on &quot;Share&quot;, then &quot;Export
                          JSON&quot;
                        </p>
                        <Screenshot src="/assets/images/manual/export-json.png" />
                      </li>
                      <li>
                        <p>
                          Search the text for <code>idBoard</code> and enter the
                          hash into the input on Osmosify
                        </p>
                        <Screenshot src="/assets/images/manual/text-search.png" />
                      </li>
                      <li>
                        <p>
                          Now, to add lists, search the same text for{' '}
                          <code>idList</code> and enter the hash into the input
                          on Osmosify
                        </p>
                      </li>
                      <li>
                        <p>
                          Repeat steps 3-6 for any other lists you want Osmosify
                          to watch
                        </p>
                      </li>
                    </ol>
                  </div>
                ),
              },
              {
                header: 'Tracking tasks',
                content: (
                  <div>
                    <p>
                      You can set up automatic tracking of Trello cards by
                      linking a list to a specific task. Once set up, Osmosify
                      will automatically mark a task (Illustration, Editing,
                      etc.) as complete when the Trello card <em>leaves</em> the
                      specified list.
                    </p>
                    <ol>
                      <li>
                        <p>
                          Add any lists that you want to watch on the Trello
                          board.
                        </p>
                      </li>
                      <li>
                        <p>
                          Use the dropdown next to the list you want to watch,
                          then select the task to associate with the list.
                        </p>
                        <Screenshot src="/assets/images/manual/track-card.png" />
                      </li>
                      <li>
                        <p>
                          And that&apos;s it! Now whenever somebody moves a card
                          from that list, Osmosify will mark the corresponding
                          task as complete.
                        </p>
                      </li>
                    </ol>
                  </div>
                ),
              },
            ],
          },
          {
            header: 'Files',
            content: (
              <p>
                All files related to a particular project are stored here. You
                can upload or download any of these files.
              </p>
            ),
            subSections: [
              {
                header: 'Naming',
                content: (
                  <p>
                    Use these guidelines to name your files, this will help us
                    stay organized and ascertain which files are the most
                    up-to-date. You can also refer to{' '}
                    <a
                      href="https://docs.google.com/document/d/1Rmsa4iz4AzPntL6fG21Ns9B-schnPtkgtK4HavpfzvE/edit"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      this document.
                    </a>
                  </p>
                ),
                subSections: [
                  {
                    header: 'Definitions',
                    content: (
                      <div>
                        <h4>Original (o)</h4>
                        <p>
                          First draft of a file, whether it be an illustrator’s
                          SVG, a voiceover artist’s, .mp3, or an editors, .mov
                          file. This also includes ‘living’ files like Affinity
                          Design and Camtasia Project files.
                        </p>
                        <h4>Revised (r)</h4>
                        <p>
                          This may be a second or third draft of a file that has
                          not been incorporated into and published with a
                          finished video yet. So, these files will likely be the
                          result corrections asked for during an internal or
                          external review step.
                        </p>
                        <h4>Updated (u)</h4>
                        <p>
                          These are files that have created to correct or update
                          an already published video.
                        </p>
                      </div>
                    ),
                  },
                  {
                    header: 'SVGs',
                    content: <SvgNaming />,
                  },
                  {
                    header: 'Other files',
                    content: <FileNaming />,
                  },
                ],
              },
              {
                header: 'Downloading',
                content: (
                  <p>
                    You can download any of the files individually, or download
                    all as a zip file by clicking{' '}
                    <FontAwesomeIcon icon={['fad', 'ellipsis-v']} /> and then{' '}
                    <span className="highlight">Download all</span>.
                  </p>
                ),
              },
              {
                header: 'Uploading',
                content: (
                  <p>
                    You can upload files to most of the sections. Files that you
                    upload will automatically overwrite any files that share the
                    same name. A version history is kept for any files that are
                    overwritten. Access to the version history is a feature that
                    we can add in the future.
                  </p>
                ),
              },
              {
                header: 'Deleting',
                content: (
                  <p>
                    You can delete files by clicking{' '}
                    <FontAwesomeIcon icon={['fas', 'times']} /> on a file.
                    Alternatively you can click{' '}
                    <FontAwesomeIcon icon={['fad', 'ellipsis-v']} /> and then{' '}
                    <span className="highlight">Delete all</span>. Deleting
                    files will remove them from the project. Even deleted files
                    are still kept in long-term storage, so if absolutely
                    necessary, they can be retrieved.
                  </p>
                ),
              },
            ],
          },
          {
            header: 'External resources',
            content: (
              <p>
                Resources that generally have a copyright or were created by an
                outside source.
              </p>
            ),
            subSections: [
              {
                header: 'Adding resources',
                img: (
                  <Screenshot src="/assets/images/manual/add-resource.png" />
                ),
                content: (
                  <p>
                    Resources can be added via the project contents page for a
                    given project, both existing resources can be added or new
                    resources created and added. Additionally, resources can
                    also be added via the <a href="/admin/#/resources">Admin</a>{' '}
                    page.
                  </p>
                ),
              },
              {
                header: 'Deleting resources',
                content: (
                  <p>
                    Resources can be deleted via the{' '}
                    <a href="/admin/#/resources">Admin</a> page, however
                    you&apos;ll need <code>resource</code> permissions to delete
                    resources.
                  </p>
                ),
              },
            ],
          },
          {
            header: 'Credits',
            content: (
              <p>You can add or remove credit entries to the project.</p>
            ),
            img: '/assets/images/manual/project-credits.jpg',
            subSections: [
              {
                header: 'Credits Builder',
                content: (
                  <p>
                    Create a PNG image of the credits to be included in the
                    video. Credits added via the credits builder as well as the
                    exported credits image will also save to the project itself.
                  </p>
                ),
                img: '/assets/images/manual/project-credits.gif',
              },
            ],
          },
          {
            header: 'Activity',
            content: <p>A log of activity in the project</p>,
            subSections: [
              {
                header: 'Comments',
                content: (
                  <p>
                    You can leave comments that will stay attached to this
                    project, you can also use &apos;@&apos; to mention teammates
                    in comments, this will send them a notification in Osmosify
                    and Slack.
                  </p>
                ),
                img: '/assets/images/manual/project-contents-comments.gif',
              },
              {
                header: 'Replying',
                content: (
                  <p>
                    Replying is the same as mentioning, and will simply append
                    &apos;@[NAME]&apos; to the comment box.
                  </p>
                ),
              },
              {
                header: 'Editing',
                content: (
                  <p>Only the person who created the comment may edit.</p>
                ),
              },
              {
                header: 'Deleting',
                content: (
                  <p>Only the person who created the comment may delete.</p>
                ),
              },
            ],
          },
        ],
      },
      {
        header: 'Project review',
        content: (
          <p>
            Leave comments and add annotations to scene files by clicking the{' '}
            <FontAwesomeIcon icon={['fad', 'comment']} /> icon in the project
            view. Annotations and comments can be left for both SVG and video
            files.
          </p>
        ),
        subSections: [
          {
            header: 'Annotations',
            content: (
              <div>
                <p>
                  To add an annotation, scrub to the timepoint you&apos;d like
                  to add to and click either the{' '}
                  <FontAwesomeIcon icon={['fad', 'highlighter']} /> button or
                  the <FontAwesomeIcon icon={['fad', 'draw-square']} /> button.
                </p>
                <p>
                  To remove an annotation without saving it, click the same
                  active annotation button again, this will deactivate
                  annotations and remove the current annotation.
                </p>
              </div>
            ),
          },
          {
            header: 'Comments',
            content: (
              <p>
                To add a comment, scrub to the timepoint you&apos;d like to add
                to, add your comment into the text box, and click Save.
              </p>
            ),
            subSections: [
              {
                header: 'Deleting comments',
                content: (
                  <p>
                    Only the user that created the comment is able to delete the
                    comment by clicking{' '}
                    <FontAwesomeIcon icon={['fad', 'trash-alt']} />.
                  </p>
                ),
              },
              {
                header: 'Resolving comments',
                content: (
                  <p>
                    Users that did not create the comment can
                    &quot;resolve&quot; comments by clicking{' '}
                    <FontAwesomeIcon icon={['far', 'check-circle']} />. This
                    simply marks it as read. You can always unresolve it by
                    clicking the{' '}
                    <FontAwesomeIcon icon={['fad', 'check-circle']} /> again.
                  </p>
                ),
              },
              {
                header: 'Replying to comments',
                content: (
                  <p>
                    You can reply to comments by clicking &quot;reply&quot; in
                    the lower left corner of the comment. If replying to a
                    comment other than your own, the original comment owner will
                    receive a notification in <b>Osmosify</b> and in{' '}
                    <b>Slack.</b>
                  </p>
                ),
                img: '/assets/images/manual/reply.gif',
              },
              {
                header: 'Mentioning teammates in comments',
                content: (
                  <p>
                    You can mention other teammates by using the <b>@</b> symbol
                    and choosing their name from the list that appears. Users
                    mentioned in this way will receive a notification in{' '}
                    <b>Osmosify</b> and in <b>Slack.</b>
                  </p>
                ),
                img: '/assets/images/manual/mention.gif',
              },
              {
                header: 'Example review of an SVG',
                img: '/assets/images/manual/project-annotations.gif',
              },
              {
                header: 'Example review of a video',
                img: '/assets/images/manual/project-annotations-2.gif',
              },
            ],
          },
          {
            header: 'Spelling',
            content: (
              <p>
                Osmosify automatically scans through the text on the page and
                checks spelling against a medical dictionary. It&apos;ll list
                any words it thinks are misspelled.
              </p>
            ),
            img: '',
            subSections: [
              {
                header: 'Saving',
                content: (
                  <p>
                    Clicking save on a misspelled word means that word will be
                    remembered on future spell-checks. This is a global action,
                    so <em>all</em> other SVGs will be affected.
                  </p>
                ),
              },
              {
                header: 'Ignoring',
                content: (
                  <p>
                    Clicking the <FontAwesomeIcon icon={['fas', 'times']} />{' '}
                    button will ignore the word. Ignored items are not saved,
                    and will reappear on page reload.
                  </p>
                ),
              },
              {
                header: 'Editing',
                content: (
                  <p>
                    If you&apos;ve accidentally saved a word, or you&apos;d like
                    to see which words Osmosify remembers, click{' '}
                    <FontAwesomeIcon icon={['fad', 'ellipsis-v']} /> and then{' '}
                    <span className="highlight">Edit saved spellings</span>.
                  </p>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    header: 'SVGs',
    content: '',
    subSections: [
      {
        header: 'Text',
        content: (
          <p>
            Osmosify can write out text created with projects. It randomly
            chooses from several options for each character to help mimic
            handwriting. That said, not all characters can be used. Below is a
            list of all available characters.
          </p>
        ),
        subSections: [
          {
            header: 'Letters',
            content: (
              <div>
                <p>
                  All uppercase and lowercase letters of the alphabet are
                  supported in Regular, Bold, and Light fonts.
                </p>
                <div className="characters-container lowercase">
                  {getCharacterElements(characters.letters_lowercase)}
                </div>
                <div className="characters-container uppercase">
                  {getCharacterElements(characters.letters_uppercase)}
                </div>
              </div>
            ),
          },
          {
            header: 'Numbers',
            content: (
              <div>
                <p>All numbers are supported in Regular, Bold, and Light.</p>
                <div className="characters-container lowercase">
                  {getCharacterElements(characters.numbers)}
                </div>
              </div>
            ),
          },
          {
            header: 'Punctuation',
            content: (
              <div>
                <p>The following punctuation characters are supported.</p>
                <div className="characters-container">
                  {getCharacterElements(characters.punctuation)}
                </div>
              </div>
            ),
          },
          {
            header: 'Symbols',
            content: (
              <div>
                <p>The following math characters are supported.</p>
                <div className="characters-container">
                  {getCharacterElements(characters.math)}
                  {getCharacterElements(characters.symbols)}
                </div>
              </div>
            ),
          },
          {
            header: 'Custom Symbols',
            content: (
              <div>
                <p>
                  The following custom symbol characters are supported. These
                  require special custom keystrokes to use.
                </p>
                <div className="characters-container custom-characters">
                  {getCharactersWithShortcuts(characters.symbols_custom)}
                </div>
              </div>
            ),
          },
          {
            header: 'Spanish',
            content: (
              <div>
                <p>The following spanish symbol characters are supported.</p>
                <div className="characters-container">
                  {getCharacterElements(characters.spanish)}
                </div>
              </div>
            ),
          },
          {
            header: 'Greek',
            content: (
              <div>
                <p>The following greek symbol characters are supported.</p>
                <div className="characters-container">
                  {getCharacterElements(characters.greek)}
                </div>
              </div>
            ),
          },
        ],
      },
      {
        header: 'Tags',
        content: (
          <div>
            <p>
              “Tags” are a way to tell Omsosify you want to do something to a
              particular layer or group of layers. To “tag” a layer, simply
              rename the layer with the appropriate tag below. Tags are
              case-insensitive.
            </p>
            <p>
              Additional information can be found in the{' '}
              <a
                href="https://docs.google.com/document/d/1ul86ErEaepN5z0t4MfMG_GpOPuZ8G5QWJLb4KX0w6DI/edit#heading=h.wigku0svgk8r"
                target="_blank"
                rel="noopener noreferrer"
              >
                Illustrator&apos;s Guide to the Galaxy
              </a>
            </p>
          </div>
        ),
        subSections: [
          {
            header: 'Image',
            content: (
              <p>
                Tells Osmosify you want to treat this group as a static image
                that will not have its paths animated and will instead have the
                whole group treated as a single image. The “image” group can
                contain all types of layers, including text, image/pixel,
                shapes, and curves.
              </p>
            ),
            subSections: [
              {
                img: '/assets/images/manual/tags/image-layers.png',
              },
              {
                img: '/assets/images/manual/tags/image-gif.gif',
              },
            ],
          },
          {
            header: 'Erase',
            content: (
              <p>
                Applies an erase-like animation to the video. To apply an erase,
                create a (Group) layer named “erase” (again—this is
                case-insensitive and what comes after “erase” is irrelevant).
                Anything added to the “erase” group will be masked and revealed
                with the erase (see example below). By default, the “erase”
                group applies a white background behind everything you place
                inside the group (if nothing’s the group, it’ll simply appear to
                erase the screen).
              </p>
            ),
            subSections: [
              {
                img: '/assets/images/manual/tags/erase-layers.png',
              },
              {
                img: '/assets/images/manual/tags/erase-gif.gif',
              },
              {
                content:
                  '<p>Optionally, you can add a shape layer (rectangle, polygon, circle, curve, etc.) named “area”, and this defines a custom area as well as custom background color to apply the erase. This allows you to only “erase” a portion of the screen at a time,  as well as change the color of the “erase”. In the example below, the red rectangle delineates the area to be “erased”. Note that the only element inside the “erase” group is “area.”</p>',
                img: '/assets/images/manual/tags/erase-layers-2.png',
              },
              {
                img: '/assets/images/manual/tags/erase-gif-2.gif',
              },
            ],
          },
          {
            header: 'Move',
            content: (
              <p>
                Allows you to “move” or animate an element along whatever path
                you specify. To start, create a (Group) layer and name it
                “move”. Inside the “move” group should be everything that you
                want to apply the move animation to, as well as an additional
                (Curve) layer named “motion”. All layer types are acceptable to
                place inside the “move” group. The “motion” layer specifies the
                path you’d like everything in the “move” group to follow (see
                structure below). The “motion” (Curve) layer will not be
                rendered, so you can have it be any color you like as well as
                any stroke-width, so long as it has a constant stroke-width.
              </p>
            ),
            subSections: [
              {
                img: '/assets/images/manual/tags/move-layers.png',
              },
              {
                img: '/assets/images/manual/tags/move-gif.gif',
              },
            ],
          },
          {
            header: 'Sync',
            content: (
              <p>
                This allows you to synchronize the animations of 2 or more
                components in your scene. To start, create a (Group) layer and
                name it “sync” (non-case-sensitive). Then simply place any
                element in the “sync” group you’d like to have synchronously
                played when run through Osmosify. All element types work in the
                “sync” group, including other tagged groups like “move”,
                “image”, etc. In the example below, there are two (Curve)
                layers, a (Text) layer, and a tagged “move” group. When played
                in Osmosify they all start simultaneously. One caveat to be
                aware of, is that while they all start at the same time, they
                may have different durations, and therefore may end at different
                times.
              </p>
            ),
            subSections: [
              {
                img: '/assets/images/manual/tags/sync-layers.png',
              },
              {
                img: '/assets/images/manual/tags/sync-gif.gif',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    header: 'Preferences',
    subSections: [
      {
        header: 'Profile',
        content: <p>Contains miscelleous information.</p>,
        subSections: [
          {
            header: 'Roles',
            content: (
              <p>
                Roles can be added or removed to your profile via the &quot;Add
                role&quot; input or the{' '}
                <FontAwesomeIcon icon={['fas', 'times']} /> buttons,
                respectively.
              </p>
            ),
            img: '/assets/images/manual/roles-image.png',
          },
        ],
      },
      {
        header: 'Shortcuts',
        content: <p>Useful shortcuts.</p>,
        subSections: [
          {
            header: 'SVG Timeline',
            content: <p>Shortcuts when viewing SVGs.</p>,
            subSections: [
              {
                header: 'Full Screen',
                content: (
                  <div>
                    <div className="shortcut-container">
                      <div className="shortcut-key">F</div>
                    </div>
                    <p>
                      Toggles the UI components on and off, shows only the
                      scene.
                    </p>
                  </div>
                ),
              },
              {
                header: 'Play All',
                content: (
                  <div>
                    <div className="shortcut-container">
                      <div className="shortcut-key">Shift</div> +{' '}
                      <div className="shortcut-key">K</div>
                    </div>
                    <p>
                      Plays through all layers and, if there are multiple SVGs
                      loaded, all loaded SVGs.
                    </p>
                  </div>
                ),
              },
              {
                header: 'Play / Pause',
                content: (
                  <div>
                    <div className="shortcut-container">
                      <div className="shortcut-key">K</div>
                    </div>
                    <p>Plays from the current playhead position.</p>
                  </div>
                ),
              },
              {
                header: 'Skip Backward',
                content: (
                  <div>
                    <div className="shortcut-container">
                      <div className="shortcut-key">J</div>
                    </div>
                    <p>Skips back 5 seconds.</p>
                  </div>
                ),
              },
              {
                header: 'Skip Forward',
                content: (
                  <div>
                    <div className="shortcut-container">
                      <div className="shortcut-key">L</div>
                    </div>
                    <p>Skips forward 5 seconds.</p>
                  </div>
                ),
              },
              {
                header: 'Export Scene as Image',
                content: (
                  <div>
                    <div className="shortcut-container">
                      <div className="shortcut-key">Option</div> +{' '}
                      <div className="shortcut-key">F</div>
                    </div>
                    <p>Generates an image file for the current scene.</p>
                  </div>
                ),
              },
            ],
          },
          {
            header: 'Video Timeline',
            content: <p>When viewing video files (mp4, avi, mov)</p>,
            subSections: [
              {
                header: 'Play / Pause',
                content: (
                  <div>
                    <div className="shortcut-container">
                      <div className="shortcut-key">K</div>
                    </div>
                    <p>Plays from the current playhead position.</p>
                  </div>
                ),
              },
              {
                header: 'Skip ahead (1 frame)',
                content: (
                  <div>
                    <div className="shortcut-container">
                      <div className="shortcut-key">&gt;</div>
                    </div>
                    <p>Skips forward 1 frame.</p>
                  </div>
                ),
              },
              {
                header: 'Skip forward (10 frames)',
                content: (
                  <div>
                    <div className="shortcut-container">
                      <div className="shortcut-key">Shift</div> +{' '}
                      <div className="shortcut-key">&gt;</div>
                    </div>
                    <p>Skips forward 10 frames.</p>
                  </div>
                ),
              },
              {
                header: 'Skip forward (10 seconds)',
                content: (
                  <div>
                    <div className="shortcut-container">
                      <div className="shortcut-key">→</div>
                    </div>
                    <p>Skips forward 10 seconds.</p>
                  </div>
                ),
              },
              {
                header: 'Skip back (1 frame)',
                content: (
                  <div>
                    <div className="shortcut-container">
                      <div className="shortcut-key">&lt;</div>
                    </div>
                    <p>Skips back 1 frame.</p>
                  </div>
                ),
              },
              {
                header: 'Skip back (10 frames)',
                content: (
                  <div>
                    <div className="shortcut-container">
                      <div className="shortcut-key">Shift</div> +{' '}
                      <div className="shortcut-key">&lt;</div>
                    </div>
                    <p>Skips back 10 frames.</p>
                  </div>
                ),
              },
              {
                header: 'Skip back (10 seconds)',
                content: (
                  <div>
                    <div className="shortcut-container">
                      <div className="shortcut-key">←</div>
                    </div>
                    <p>Skips back 10 seconds.</p>
                  </div>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    header: 'Resources',
    content: <p>Helpful links and resources.</p>,
    subSections: [
      {
        header: 'Links',
        content: (
          <ul>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.google.com/document/d/1ul86ErEaepN5z0t4MfMG_GpOPuZ8G5QWJLb4KX0w6DI/edit?usp=sharing"
              >
                Illustrator&apos;s Guide to the Galaxy
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.google.com/document/u/1/d/1Rmsa4iz4AzPntL6fG21Ns9B-schnPtkgtK4HavpfzvE/edit"
              >
                File naming convention
              </a>
            </li>
          </ul>
        ),
      },
    ],
  },
];
