import React from 'react';
import Box, { Flex } from '../Box';
import AssetPreview from './asset-previews/AssetPreview';
import FileProps from '../editor/types/FileProps';
import { TextInput } from '../Input';
import Button from '../RebassButton';
import Icon from '../Icon';

interface Props {
  file: FileProps;
  index: number; // id
  selectedFiles: FileProps[];
  handleUpdateFile: (file: FileProps) => void;
  handleUploadVersion: (file: FileProps) => void;
  handleRemoveFile: (idx: number) => void;
  handleSelectFiles: (files: FileProps[]) => void;
}

const AssetPreviewWrapper = (props: Props) => {
  const {
    file,
    selectedFiles,
    index,
    handleUpdateFile,
    handleSelectFiles,
    handleRemoveFile,
    handleUploadVersion,
  } = props;

  const handleUpdateName = (e: React.FormEvent<HTMLInputElement>) => {
    const newFileAsset = { ...file, name: e.currentTarget.value };
    handleUpdateFile(newFileAsset);
  };

  const isSelected = !!selectedFiles.find((f) => f.fileID === file.fileID);

  return (
    <Box>
      {file && (
        <AssetPreview
          file={file}
          selectFiles={handleSelectFiles}
          isSelected={isSelected}
          style={{ margin: 0, marginBottom: 8, width: 'auto' }}
        />
      )}
      <Box mb={2}>
        <TextInput
          style={{ width: '100%' }}
          value={file.name}
          onChange={handleUpdateName}
        />
      </Box>
      <Flex>
        <Button
          variant="outline"
          color="gray"
          onClick={() => handleUploadVersion(file)}
        >
          Upload new version
        </Button>
        <Button
          variant="transparent"
          color="red"
          onClick={() => handleRemoveFile(index)}
        >
          <Icon icon="trash-alt" />
        </Button>
      </Flex>
    </Box>
  );
};

export default AssetPreviewWrapper;
