import styled, { css, keyframes } from 'styled-components/macro';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UploadBox from '../UploadBox';
import { colors, fade, lighten } from '../styles/colors';
import Button from '../Button';

export const Container = styled.div``;
export const Files = styled.div`
  max-height: 273px;
  overflow: auto;
`;
export const FilesContainer = styled.div`
  border-left: 1px solid
    ${(props) => (props.darkMode ? fade('white', 75) : colors['light-grey-40'])};
`;
export const FileTypeContainer = styled.div`
  padding: 15px;
`;
export const FileRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;

  border-radius: 3px;

  cursor: pointer;

  :hover,
  :focus {
    background: ${lighten(colors.night, 11)};
  }

  :active {
    background: ${lighten(colors.night, 9)};
  }

  i,
  svg {
    padding-right: 5px;
    padding-left: 5px;
  }

  ${(props) =>
    props.isActive &&
    css`
      background: ${props.darkMode
        ? fade(colors.blue, 30)
        : lighten(colors.blue, 40)};
      :hover,
      :focus,
      :active {
        background: ${props.darkMode
          ? fade(colors.blue, 30)
          : lighten(colors.blue, 40)};
      }
    `}
`;
export const CommentIcon = styled.div`
  font-size: 18px;
  color: ${colors['light-red-20']};

  svg {
    width: inherit;
  }

  ${(props) =>
    props.status === 'resolved' &&
    css`
      color: ${colors['light-green-20']};
    `}
`;
export const CheckIcon = styled(FontAwesomeIcon)`
  color: ${colors.green};
  font-size: 18px;

  margin-right: 10px;
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const SyncingIcon = styled(CheckIcon)`
  color: ${colors['light-grey-20']};
  animation: ${spin} 0.5s linear infinite;
`;
export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const RightContainer = styled(LeftContainer)`
  display: flex;
  align-items: center;
`;
export const Name = styled.p`
  font-size: 14px;
  margin: 0;
  padding: 4px 8px;
  border-radius: 3px;

  :hover {
    background-color: ${(props) =>
      props.darkMode ? fade('white', 95) : fade('black', 95)};
  }

  ${(props) =>
    props.darkMode &&
    css`
      color: white;
    `}
`;
export const ExportIcon = styled(FontAwesomeIcon)`
  color: ${lighten(colors.blue, 5)};

  :hover,
  :focus {
    color: ${lighten(colors.blue, 10)};
  }

  :active {
    color: ${lighten(colors.blue, 15)};
  }
`;
export const ExportDownloadIcon = styled(FontAwesomeIcon)`
  color: ${lighten(colors.grey, 20)};

  :hover,
  :focus {
    color: ${lighten(colors.grey, 10)};
  }

  :active {
    color: ${lighten(colors.grey, 5)};
  }
`;
export const ExportPendingIcon = styled(FontAwesomeIcon)`
  color: ${colors['light-grey-40']};
  animation: ${spin} 0.5s linear infinite;
`;
export const EmptyMessage = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
`;
export const Emoji = styled.div`
  font-size: 32px;
  margin-right: 10px;
`;
export const MessageText = styled.h4`
  margin: 0;
  color: ${colors['light-grey-30']};
`;
export const StyledUploadBox = styled(UploadBox)`
  flex: 1;

  margin-top: 10px;
`;
export const MoreButton = styled(Button)`
  opacity: 0;
  font-size: 12px;

  ${FileRow}:hover & {
    opacity: 1;
  }
`;
