export default [
  '#157CC9',
  '#006E85',
  '#006C54',
  '#0B6220',
  '#A87D00',
  '#A74D0B',
  '#DA2733',
  '#D82262',
  '#9447B5',
  '#00000000',
];
