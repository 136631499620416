import React, { useContext, useEffect, useState } from 'react';
import { Store } from '../../state/store';
import { loadFiles } from '../../utilities/files';
import { Box } from '../Box';
import FileProps from '../editor/types/FileProps';
import UploadStaging from './UploadStaging';

const saveLocalProgress = (files: FileProps[]) => {
  window.localStorage.setItem('importStagingFiles', JSON.stringify(files));
};

const loadLocalProgress = (): FileProps[] => {
  const res = window.localStorage.getItem('importStagingFiles');
  return JSON.parse(res ?? '[]');
};

const MyUploads = (props: any) => {
  const [files, setFiles] = useState<FileProps[]>([]);
  const {
    state: { user },
  } = useContext(Store);

  const handleUploadFiles = async (fileList: { [index: string]: File }) => {
    const filesArray = Object.values(fileList);

    const loadedFiles = await loadFiles(filesArray, {
      user: {
        id: user.userID,
        name: `${user.givenName} ${user.familyName}`,
      },
    });

    const newFiles = [...files, ...loadedFiles];
    setFiles(newFiles);
    saveLocalProgress(newFiles);
  };

  const handleSetFiles = (newFiles: FileProps[]) => {
    setFiles(newFiles);
    saveLocalProgress(newFiles);
  };

  useEffect(() => {
    // Load progress from local storage
    const loadedFiles = loadLocalProgress();
    if (loadedFiles.length) setFiles(loadedFiles);
  }, []);

  return (
    <Box height="100%">
      <UploadStaging
        files={files}
        setFiles={handleSetFiles}
        handleUploadFiles={handleUploadFiles}
      />
    </Box>
  );
};

export default MyUploads;
