import styled, { css } from 'styled-components/macro';

export const Container = styled.div`
  position: absolute;
  top: 0;

  width: 100%;
  height: 100%;

  z-index: 1000;

  pointer-events: none;

  ${(props: { visible?: boolean }) =>
    !props.visible &&
    css`
      display: none;
    `}

  svg {
    position: absolute;
  }

  path {
    fill: none;
    stroke: #ff0000;
    stroke-width: 6px;
    stroke-linejoin: round;
    stroke-linecap: round;
  }
`;
