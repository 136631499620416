import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState } from 'react';

import { Column } from '../AdminStyles';

import { colors } from '../../styles/colors';

function StackColumn(props) {
  const { error } = props;

  const [showStack, setShowStack] = useState(false);

  return (
    <FlexColumn>
      {typeof error.info.msg === 'string'
        ? error.info.msg
        : JSON.stringify(error.info.msg)}
      <StackButton
        className="thin basic red box"
        onClick={() => setShowStack(!showStack)}
      >
        {showStack ? 'Hide' : 'Show'} stack
      </StackButton>
      {showStack && (
        <StackContainer>
          {JSON.stringify(error.info.stack || error.info.e)}
        </StackContainer>
      )}
    </FlexColumn>
  );
}

const StackButton = styled.h4`
  margin: 0;
  margin-top: 5px;

  color: ${colors.red};

  cursor: pointer;

  opacity: 0.5;

  :hover,
  :focus {
    opacity: 1;
  }
`;

const StackContainer = styled.div``;

const FlexColumn = styled(Column)`
  flex: 1;
`;

StackColumn.propTypes = {
  error: PropTypes.shape({
    info: PropTypes.shape({
      stack: PropTypes.string,
      e: PropTypes.string,
      msg: PropTypes.string,
    }),
  }),
};

StackColumn.defaultProps = {
  propName: '',
};

export default StackColumn;
