import React from 'react';
import { Box } from 'rebass/styled-components';
import KitProps from '../../../types/KitProps';
import { KitFileProps } from './MediaImages';
import FileProps from '../types/FileProps';

import AddToKitDialogRow from './AddToKitDialogRow';
import { colors } from '../../styles/colors';

type Props = {
  files: FileProps[];
  kitFiles: KitFileProps[];
  allKits: KitProps[];
  update: () => void;
};

export default function AddToKitDialog(props: Props) {
  const { kitFiles, allKits, update, files } = props;

  return (
    <Box maxHeight="50vh" overflow="auto" maxWidth={500}>
      {files.map((f) => (
        <Box
          key={f.fileID}
          mb={20}
          pb={20}
          sx={{ borderBottom: `1px solid ${colors['light-grey-50']}` }}
        >
          <AddToKitDialogRow
            file={f}
            allKits={allKits}
            kits={kitFiles.filter((kit) => kit.fileID === f.fileID)}
            update={update}
          />
        </Box>
      ))}
    </Box>
  );
}
