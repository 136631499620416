import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import Box from './Box';
import sortFiles from '../utilities/sortFiles';
import { post } from '../util/fetchUtil';
import File from './printcomments/File';
import { ProjectProps } from './editor/types/ProjectProps';
import fileTypes from '../shared/utilities/fileTypes';

const Wrapper = styled(Box)`
  padding: 30px;
`;

const mediaTypes = ['svg', 'video', 'image', 'all'] as const;
type MediaType = typeof mediaTypes[number];

function isMedia(media: string | null): media is MediaType {
  return typeof (media as MediaType) === 'string'
    ? mediaTypes.includes(media as MediaType)
    : false;
}

const PrintComments: React.FC = () => {
  const [project, setProject] = useState<ProjectProps>();
  const [fileType, setFileType] = useState<MediaType>('all');

  const sortOption = sortFiles.find((s) => s.id === 'alphabetical');

  const url = new URL(window.location.href);
  const fileTypeParam = url.searchParams.get('type');

  useEffect(() => {
    const projectID = url.searchParams.get('projectID');

    if (projectID) {
      post('/project/getProjectByProjectID', {
        projectID,
      }).then((res) => {
        setProject(res.project);
      });
    }

    if (isMedia(fileTypeParam)) setFileType(fileTypeParam);
    // eslint-disable-next-line
  }, [fileTypeParam]);

  return (
    <Wrapper>
      {project?.files
        .filter((f) => project.comments.some((c) => c.fileID === f.fileID))
        .filter((f) =>
          fileType !== 'all' && fileTypes[fileType]
            ? fileTypes[fileType].some((t) => t === f.type)
            : true
        )
        .sort(sortOption?.sort)
        .map((file) => (
          <File key={file.fileID} project={project} file={file} />
        ))}
    </Wrapper>
  );
};

PrintComments.propTypes = {};

PrintComments.defaultProps = {};

export default PrintComments;
