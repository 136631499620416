import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { post } from '../../util/fetchUtil';
import Box from '../Box';
import Button from '../Button';
import { colors, lighten } from '../styles/colors';
import { H3, P } from '../styles/typography';

const Word = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 5px 10px;

  &:nth-child(2n) {
    background: ${lighten(colors.grey, 57)};
  }

  i,
  svg {
    font-size: 12px;
  }
`;

type Props = {
  savedWords: string[];
};

export default function SavedSpellingDialog(props: Props) {
  const { savedWords } = props;
  const [localSavedWords, setLocalSavedWords] = useState(savedWords);

  const handleRemoveWord = async (word: string) => {
    await post('/dictionary/removeWord', {
      word,
    });

    const newWords = [...localSavedWords];
    // Update the saved words list observable
    const index = newWords.findIndex((w: string) => w === word);
    newWords.splice(index, 1);

    setLocalSavedWords(newWords);
  };

  const words = localSavedWords.map((word, i) => (
    <Word key={i}>
      {word}
      <Button
        thin
        icon
        box
        color={colors.red}
        onClick={() => handleRemoveWord(word)}
        style={{ fontSize: 13 }}
      >
        <FontAwesomeIcon icon={['fad', 'trash-alt']} />
      </Button>
    </Word>
  ));

  return (
    <Box>
      <Box mb={10}>
        <H3 mb={10}>Saved spellings</H3>
        <P mb={10}>The spellings for these words are ignored.</P>
      </Box>
      <Box maxHeight={400} overflow="auto">
        {words}
      </Box>
    </Box>
  );
}
