import React from 'react';
import Box from '../Box';

interface Props {
  src?: string;
  youtube?: string;
}

const Video = (props: Props) => {
  const { src, youtube } = props;

  return (
    <Box
      flex={1}
      sx={{
        'video,iframe': {
          width: '100%',
          borderRadius: '5px',
          boxShadow:
            '0 3px 5px -1px rgb(0 0 0 / 13%), 0 5px 8px 0 rgb(0 0 0 / 8%), 0 1px 14px 0 rgb(0 0 0 / 5%)',
        },
      }}
    >
      {src && <video src={src} autoPlay muted loop />}
      {youtube && (
        <iframe
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${youtube}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
    </Box>
  );
};

export default Video;
