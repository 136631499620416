import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import PressureCurvePicker from '../PressureCurvePicker';
import Box, { Flex } from '../../Box';
import Button from '../../Button';
import NumberInput from '../../NumberInput';
import ColorPicker from '../../ColorPicker';
import { P } from '../../styles/typography';
import { colors, darken } from '../../styles/colors';

import { PropertyContainer, Unit, Section } from './PropertyStyles';

import editorColors from '../../../app/data/editorColors';
import {
  style as styleStore,
  path as pathStore,
} from '../../../app/editor/preferenceStore';

import ElementProps from '../props/ElementProps';

const properties = [
  {
    key: 'strokeWidth',
    name: 'Stroke width',
    unit: 'px',
    min: 0,
    step: 1,
  },
];

const sectionStyle = {
  width: '100%',
};

const PressureWarning = styled(Box)`
  padding: 20px;
  background-color: ${darken(colors.night, 5)};
  border-radius: 5px;
`;

/**
 * Properties specific to path elements
 */
function StrokeProperties(props) {
  const { element, handleUpdateElement, addKeyframe } = props;

  const [pressurePoints, setPressurePoints] = useState(
    element.currentProps.pressure
  );

  const { style } = element.currentProps;

  const handleUnsetClipPath = () => {
    element.update('clipPath', null);
    handleUpdateElement([element.id]);
  };

  const handleUpdatePressure = (points) => {
    pathStore.pressure = points;
    setPressurePoints(points);

    element.currentProps.pressure = points;
    handleUpdateElement([element.id]);
  };

  const updateCurrentProps = (key, value) => {
    element.update('currentProps', {
      ...element.currentProps,
      [key]: value,
    });

    handleUpdateElement([element.id]);
  };

  const handleUpdateStyle = (key, newVal) => {
    styleStore[key] = newVal;

    updateCurrentProps('style', {
      ...element.currentProps.style,
      [key]: newVal,
    });
  };

  const handleChangeDashOffset = (newVal) => {
    updateCurrentProps('dashoffset', newVal / 100);

    addKeyframe('stroke', newVal / 100);

    handleUpdateElement([element.id]);
  };

  const handleColorSave = (colorObj) => {
    handleUpdateStyle('stroke', colorObj.toHEXA().toString());
  };

  const propertyComponents = properties.map((property) => (
    <Box key={property.key}>
      <Section style={sectionStyle}>
        <Label>{property.name}</Label>
        <NumberInput
          min={property.min || 1}
          step={property.step || 1}
          value={style[property.key]}
          onChange={(newVal) => handleUpdateStyle(property.key, newVal)}
          style={{ width: '50px', fontSize: 14, padding: '.5em' }}
        />
        <Unit>{property.unit}</Unit>
      </Section>
    </Box>
  ));

  const hasFill =
    element.currentProps.style.fill &&
    element.currentProps.style.fill !== 'none';

  const hasPressureCurvePicker = !element.clipPath && !hasFill;

  return (
    <Box style={{ height: '100%' }}>
      {style && propertyComponents}
      <Section style={sectionStyle}>
        <Label>Stroke progress</Label>
        <NumberInput
          min={0}
          max={100}
          value={element.currentProps.dashoffset * 100}
          onChange={(newVal) => handleChangeDashOffset(newVal)}
          style={{ width: '50px', fontSize: 14, padding: '.5em' }}
        />
        <Unit>%</Unit>
      </Section>
      <PropertyContainer display="flex">
        <Section style={sectionStyle}>
          <P style={{ marginRight: 10 }}>Stroke</P>
          <ColorPicker
            swatches={editorColors}
            initialColor={
              element.currentProps.style.stroke || styleStore.stroke
            }
            onColorSelect={handleColorSave}
            saveOnSelect={true}
          />
        </Section>
      </PropertyContainer>
      <Section style={{ ...sectionStyle, display: 'block' }}>
        <P style={{ marginBottom: 10 }}>Pressure</P>
        {element.clipPath && (
          <PressureWarning>
            <P style={{ marginBottom: 10 }}>
              This path is using a pressure profile from Affinity Designer
            </P>
            <Flex c>
              <Button
                onClick={handleUnsetClipPath}
                box
                thin
                backgroundColor={colors.teal}
                color="white"
              >
                Overwrite
              </Button>
            </Flex>
          </PressureWarning>
        )}
        {hasFill && (
          <PressureWarning>
            <P style={{ marginBottom: 10 }}>This path has a fill color</P>
            <Flex c>
              <Button
                onClick={handleUnsetClipPath}
                box
                thin
                backgroundColor={colors.teal}
                color="white"
              >
                Remove fill
              </Button>
            </Flex>
          </PressureWarning>
        )}
        {hasPressureCurvePicker && (
          <PressureCurvePicker
            points={pressurePoints}
            setPoints={handleUpdatePressure}
            style={{
              backgroundColor: darken(colors.night, 5),
              borderRadius: 5,
              width: '75%',
            }}
          />
        )}
      </Section>
    </Box>
  );
}

const Label = styled(P)``;

const { func } = PropTypes;

StrokeProperties.propTypes = {
  element: ElementProps.isRequired,
  handleUpdateElement: func.isRequired,
  addKeyframe: func.isRequired,
};

export default StrokeProperties;
