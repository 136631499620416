import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useEffect } from 'react';

import fetchUtil from '../../util/fetchUtil';

import fileManager from '../../app/fileManager';
import { getExtension } from '../../utilities/files';

import Dialog from '../Dialog';
import AddResourceDialog from './AddResourceDialog';

import {
  HeaderSection,
  SectionTitle,
  Icon,
} from '../views/ProjectContentsStyles';

import { colors, lighten } from '../styles/colors';
import { P, A } from '../styles/typography';

import Button from '../Button';

const StyledButton = styled(Button)`
  width: 100%;
`;

const Container = styled.div``;
const Actions = styled.div`
  display: flex;
`;

const ResourceInfo = styled.div`
  display: flex;
  justify-content: space-between;

  flex: 1;
`;

const Image = styled.div`
  max-height: 75px;
  width: 100px;
  margin-right: 10px;
  border-radius: 3px;
  overflow: hidden;

  background: ${colors.grey};

  img {
    width: 100%;
  }
`;

const Info = styled(P)`
  color: ${colors['light-grey-10']};
`;

const Notes = styled(P)`
  color: ${colors['light-grey-20']};
`;

const ResourceRow = styled.div`
  display: flex;

  padding: 15px;
  margin-bottom: 10px;
  border-radius: 3px;

  background: ${lighten(colors.grey, 53)};
`;

function ResourcesSection(props) {
  const {
    activeProject,
    activeProject: { projectID },
  } = props;

  const [resources, setResources] = useState([]);
  const [resourceDialogVisible, setResourceDialogVisible] = useState(false);

  const getResources = () => {
    fetchUtil
      .post('/resources/getProjectResources', {
        projectID,
      })
      .then((res) => setResources(res.resources));
  };

  useEffect(() => {
    if (projectID) {
      getResources();
    }
    // eslint-disable-next-line
  }, [projectID]);

  const handleRemoveResource = (resource) => {
    fetchUtil
      .post('/resources/removeProjectResource', {
        resourceID: resource.resourceID,
        projectID,
      })
      .then(() => {
        getResources();
      });
  };

  const handleDownload = (resource) => {
    fileManager
      .fetchFile(resource.data.image.replace('https', 'http'))
      .then((blob) =>
        fileManager.downloadFile(
          blob,
          `${resource.name}${getExtension(resource.data.image)}`
        )
      );
  };

  const resourceRows = resources.map((resource) => (
    <ResourceRow key={resource.resourceID}>
      <Image>
        <img src={resource.data.image} alt="Resource" />
      </Image>
      <ResourceInfo>
        <div>
          <P>
            <A href={resource.data.link} target="_blank">
              {resource.name}
            </A>
          </P>
          <Info>
            {resource.data.attribution} • {resource.data.copyright}
          </Info>
          {resource.data.notes && <Notes>{resource.data.notes}</Notes>}
        </div>
        <Actions>
          <Button
            onClick={() => handleDownload(resource)}
            className="icon transparent"
          >
            <FontAwesomeIcon icon={['fad', 'file-download']} />
          </Button>
          <Button
            onClick={() => handleRemoveResource(resource)}
            className="icon transparent"
          >
            <FontAwesomeIcon icon={['fas', 'times']} />
          </Button>
        </Actions>
      </ResourceInfo>
    </ResourceRow>
  ));

  return (
    <Container>
      <Dialog
        handleHideDialog={() => setResourceDialogVisible(false)}
        isVisible={resourceDialogVisible}
      >
        <AddResourceDialog
          activeProject={activeProject}
          getResources={getResources}
          handleHideDialog={() => setResourceDialogVisible(false)}
        />
      </Dialog>
      <HeaderSection>
        <SectionTitle>
          <Icon icon={['fad', 'x-ray']} />
          Resources
        </SectionTitle>
      </HeaderSection>
      {resourceRows}
      <StyledButton box leftIcon onClick={() => setResourceDialogVisible(true)}>
        <FontAwesomeIcon icon={['fas', 'plus']} />
        Add
      </StyledButton>
    </Container>
  );
}

ResourcesSection.propTypes = {
  activeProject: PropTypes.shape({
    data: PropTypes.object,
    projectID: PropTypes.number,
    name: PropTypes.string,
  }),
};

ResourcesSection.defaultProps = {
  activeProject: {},
};

export default ResourcesSection;
