import dictionary from './dictionary';

/**
 * Accepts an array of strings
 *
 * @param {Array} words - array of strings to check spelling
 */ export const check = function (words) {
  const misspelledWords = [];
  // Create filtered array of words
  const newWords = Array.from(words)
    .filter((word) => word.length > 1) // Filter out single characters
    .filter((word) => Number.isNaN(parseInt(word, 10))) // Filter out numbers
    .map((word) => word.replace(/"|!|¡|:|<|>|\(|\)|\/|√|,|◊|=|\?/g, '')); // Filter out certain characters

  for (let i = 0; i < newWords.length; i += 1) {
    const word = newWords[i];

    // Push to misspelled words if fails dictionary check
    if (!dictionary.check(word)) misspelledWords.push(word);
  }

  return misspelledWords;
};

export default {
  check,
};
