import { ElementsArrayProps } from '../../ui/editor/types/ElementProps';

/**
 * Gets the max time that a keyframe exists among all the elements in the array
 * // FIXME: MAY NEED TO RECURSIVELY SEARCH GROUPS
 *
 * @param {[SvgElement]} [elements=[]]
 * @returns
 */
export const getMaxTime = (elements: ElementsArrayProps = []): number => {
  let time = 0;

  elements.forEach((element) => {
    if (element.keyframes) {
      Object.keys(element.keyframes).forEach((key) => {
        element.keyframes[key].forEach((keyframe) => {
          if (keyframe.time > time) ({ time } = keyframe);
        });
      });
    }
  });

  return time;
};

// MIGHT BE SAME AS ABOVE...
export const getMaxKeyframe = (flatElements: ElementsArrayProps): number => {
  // Find the max time keyframe
  const maxKeyframe = flatElements.reduce((m, el) => {
    const { max } = el.getMinMaxKeyframes();
    return max && max > m ? max : m;
  }, 0);

  return maxKeyframe;
};

export default { getMaxTime, getMaxKeyframe };
