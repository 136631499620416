import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, useRef } from 'react';
import styled from 'styled-components/macro';

import { InputProps } from '@rebass/forms';
import useClickOutside from '../app/hooks/useClickOutside';
import useKeypress from '../app/hooks/useKeypress';

import Button from './Button';
import { TextInput } from './Input';
import { colors } from './styles/colors';

type Props = {
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSaveClick: () => void;
  handleCancelClick: () => void;
  inputValue: string | number;
  placeholder?: string;
  inputType?: string;
  enableSave?: boolean;
  cancelButtonVisible?: boolean;
  saveButtonVisible?: boolean;
  theme?: string;
  className?: string;
  clickOutsideToCancel?: boolean;
  enterToSave?: boolean;
  inputStyle?: InputProps;
  saveButtonProps?: Record<string, any>;
};

function InputConfirm(props: Props) {
  const {
    handleInputChange,
    handleSaveClick,
    handleCancelClick,
    inputValue,
    placeholder,
    enableSave,
    saveButtonVisible,
    cancelButtonVisible,
    theme,
    inputType,
    className,
    clickOutsideToCancel,
    enterToSave,
    inputStyle,
    saveButtonProps = {},
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);

  useClickOutside(containerRef, () => {
    if (clickOutsideToCancel) {
      handleCancelClick();
    }
  });

  useKeypress('Enter', () => {
    if (enterToSave) {
      handleSaveClick();
    }
  });

  return (
    <Container ref={containerRef} className={className}>
      <TextInput
        type={inputType || 'text'}
        onChange={handleInputChange}
        value={inputValue}
        style={inputStyle}
        placeholder={placeholder}
      />
      {saveButtonVisible && (
        <StyledButton
          theme={theme}
          enable={enableSave}
          box
          color="white"
          backgroundColor={colors.green}
          onClick={handleSaveClick}
          {...saveButtonProps}
        >
          <FontAwesomeIcon icon={['fad', 'check']} />
        </StyledButton>
      )}
      {cancelButtonVisible && (
        <StyledButton theme={theme} box onClick={handleCancelClick}>
          <FontAwesomeIcon icon={['fas', 'times']} />
        </StyledButton>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-left: 5px;
`;

InputConfirm.defaultProps = {
  enableSave: true,
  cancelButtonVisible: true,
  clickOutsideToCancel: false,
  enterToSave: false,
  saveButtonVisible: true,
  theme: '',
  className: '',
  placeholder: '',
  inputType: 'text',
  inputStyle: {},
};

export default InputConfirm;
