import SvgElement from '../../editor/SvgElement';
import { ElementProps } from '../../ui/editor/types/ElementProps';
import { fetchFile } from '../fileManager';
import elementTypeMap from './elementTypes';

/**
 * Instantiates an SvgElement from an object
 *
 * @param {Object} elementObj - the object representation of an SvgElement
 * @returns {SvgElement}
 */
export const loadElement = (elementObj: ElementProps): ElementProps => {
  // Load element or use generic element
  const ElementType = elementTypeMap[elementObj.type] || SvgElement;

  const newElement = new ElementType(elementObj);
  if (newElement.type === 'group') {
    newElement.elements = newElement.elements.map((el: ElementProps) =>
      loadElement(el)
    );
  }

  if (newElement.maskElements.length) {
    newElement.maskElements = newElement.maskElements.map((el: ElementProps) =>
      loadElement(el)
    );
  }

  return newElement;
};

declare global {
  interface Window {
    AWSMediaBucket: string;
  }
}

/**
 * Gets a scene data file from S3 and loads its elements
 *
 * @param {String} s3Key
 * @returns {Object}
 * {
 *  elements: {[SvgElement]}, - elements
 *  duration: {Number}, - scene duration
 * }
 */
export const loadFromS3 = async (
  s3Key: string
): Promise<{
  elements: ElementProps[];
  duration: number;
}> => {
  const url = `https://${window.AWSMediaBucket}.s3.amazonaws.com/${s3Key}`;

  const file = await fetchFile(url);
  const text = await file.text();

  let data;
  try {
    data = JSON.parse(text);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  if (data && data.elements) {
    const newElements = data.elements
      .map((element: ElementProps) => loadElement(element))
      .filter((element: ElementProps) => !!element);

    return { elements: newElements, duration: data.duration };
  }

  return { elements: [], duration: 0 };
};

export default { loadElement, loadFromS3 };
