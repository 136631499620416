import React, { useState, useEffect } from 'react';

import { Link, useHistory } from 'react-router-dom';

import tippy from 'tippy.js';

import { post } from '../../util/fetchUtil';

import KitCard from './KitCard';
import AddKitDialog from './AddKitDialog';

import Button from '../Button';
import Box, { Flex } from '../Box';
import Icon from '../Icon';
import Dialog from '../Dialog';

import KitProps from '../../types/KitProps';
import { colors } from '../styles/colors';

const Kits: React.FC = () => {
  const [kits, setKits] = useState<KitProps[]>([]);
  const [addKitDialogVisible, setAddKitVisibleDialog] = useState(false);
  const history = useHistory();

  const getKits = async () => {
    const res = await post('/assets/getKits');
    if (res.kits) {
      setKits(res.kits);
    }
  };

  useEffect(() => {
    getKits();
  }, []);

  useEffect(() => {
    tippy('[data-tippy-content]');
  }, [kits]);

  const kitCards = kits
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((kit) => <KitCard key={kit.kitID} kit={kit} />);

  return (
    <Box style={{ height: '100%', padding: 30, overflow: 'auto' }}>
      <Dialog
        isVisible={addKitDialogVisible}
        handleHideDialog={() => setAddKitVisibleDialog(false)}
      >
        <AddKitDialog getKits={getKits} />
      </Dialog>

      <Flex>
        <Box mr={2}>
          <Button
            box
            leftIcon
            backgroundColor={colors.blue}
            color="white"
            onClick={() => history.push('/assets/uploads')}
          >
            <Icon color="white" icon="cloud-upload-alt" />
            Upload
          </Button>
        </Box>
        <Box mr={2}>
          <Link to="/assets/all">
            <Button box basic color="grey">
              Browse all kits
            </Button>
          </Link>
        </Box>
        <Button
          box
          basic
          color="grey"
          leftIcon
          onClick={() => setAddKitVisibleDialog(true)}
        >
          <Icon color="white" icon="plus" prefix="far" />
          New kit
        </Button>
      </Flex>

      <Flex style={{ flexWrap: 'wrap', padding: 30 }}>{kitCards}</Flex>
    </Box>
  );
};

export default Kits;
