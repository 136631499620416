import { calcAdjacent, calcOpposite, cos, tan, sin } from '../trig';

export const swapChildParent = (childTransform = {}, parentTransform = {}) => {
  const x1 = parentTransform.translateX || 0;
  const y1 = parentTransform.translateY || 0;
  const sx1 = parentTransform.scaleX || 1;
  const sy1 = parentTransform.scaleY || 1;
  const r1 = parentTransform.rotate || 0;
  const x2 = childTransform.translateX || 0;
  const y2 = childTransform.translateY || 0;
  const sx2 = childTransform.scaleX || 1;
  const sy2 = childTransform.scaleY || 1;
  const r2 = childTransform.rotate || 0;

  const y3 =
    (y1 - y2 + sin(r1) * x2 * sy1 + cos(r1) * y2 * sy1) /
      (cos(r2) * sy2 * (1 + tan(r2) ** 2)) +
    (tan(r2) * (x1 - x2 + cos(r1) * x2 * sx1 - sin(r1) * y2 * sx1)) /
      (cos(r2) * sx2 * (1 + tan(r2) ** 2));

  const x3 =
    (x1 - x2 + cos(r1) * x2 * sx1 - sin(r1) * y2 * sx1) / (cos(r2) * sx2) +
    tan(r2) * y3;

  return {
    translateX: x3,
    translateY: y3,
    scaleX: sx1,
    scaleY: sy1,
    rotate: r1,
  };
};

/**
 * Calculates the resultant transform when applied to a child (that has a transform)
 * Must be in the order: translate, rotate, scale
 *
 * @param {Object} childTransform
 * @param {Object} parentTransform
 * @returns {Object} { translateX: number, translateY: number, scaleX: number, scaleY: number, rotate: number }
 */
export const merge = (childTransform, parentTransform) => {
  const translateX =
    parentTransform.translateX +
    calcAdjacent(parentTransform.rotate, childTransform.translateX) *
      parentTransform.scaleX -
    calcOpposite(parentTransform.rotate, childTransform.translateY) *
      parentTransform.scaleX;
  const translateY =
    parentTransform.translateY +
    calcOpposite(parentTransform.rotate, childTransform.translateX) *
      parentTransform.scaleY +
    calcAdjacent(parentTransform.rotate, childTransform.translateY) *
      parentTransform.scaleY;

  const scaleX = childTransform.scaleX * parentTransform.scaleY;
  const scaleY = childTransform.scaleY * parentTransform.scaleY;

  const rotate = childTransform.rotate + parentTransform.rotate;

  return { translateX, translateY, scaleX, scaleY, rotate };
};

/**
 * Gets the composite transform being applied to a child element
 * e.g. if an element is deeply nested in 3 groups all with separate transforms, this
 * calculates the composite transform to apply to the child if the child were completely ungrouped
 *
 * @param {SvgElement} element - the target child element
 * @param {SvgElement[]} elements - array of elements the child lives in (nested or not)
 * @returns {Object} { translateX: number, translateY: number, scaleX: number, scaleY: number, rotate: number }
 */
export const mergeParents = (element, elements) => {
  let parentTransforms = null;
  const getTransformRecursive = (el, parentTransform) => {
    // Combine the parent transform with this transform
    if (el.id === element.id) {
      // Stop the loop if this is the element
      parentTransforms = parentTransform;
    } else if (!parentTransforms && el.elements) {
      const newTransform = merge(el.currentProps, parentTransform);
      // Calculate recursively until we find the element
      el.elements.forEach((childEl) =>
        getTransformRecursive(childEl, newTransform)
      );
    }
  };

  elements.forEach((el) =>
    getTransformRecursive(el, {
      translateX: 0,
      translateY: 0,
      scaleX: 1,
      scaleY: 1,
      rotate: 0,
    })
  );

  return parentTransforms;
};

export default { merge, mergeParents, swapChildParent };
