import uuid from '../utilities/uuid';
import elementTypeMap from './elementTypes';
import deepClone from '../deepClone';

const clone = (element, options = {}) => {
  const { newIds = true } = options;
  const cloneRecursive = (el) => {
    const Element = elementTypeMap[el.type];
    // eslint-disable-next-line no-console
    if (!Element) return console.error(`Element of type ${el.type} not found!`);

    const newElement = new Element(deepClone(el));

    if (newIds) {
      // Must assign a new id to the new element
      newElement.id = uuid();

      // All keyframe ids must be unique
      Object.keys(newElement.keyframes).forEach((key) => {
        newElement.keyframes[key].forEach((keyframe) => {
          // eslint-disable-next-line no-param-reassign
          keyframe.id = uuid();
        });
      });
    }

    if (newElement.elements) {
      newElement.elements.forEach((e, i) => {
        newElement.elements[i] = cloneRecursive(e);
      });
    }

    return newElement;
  };

  // Make sure all cloned elements have unique ids with new refs
  return cloneRecursive(element);
};

export default clone;
