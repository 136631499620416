export const setIsFullScreen = (isFullScreen, dispatch) => dispatch({
  type: 'SET_IS_FULL_SCREEN',
  payload: isFullScreen,
});

export const setLeftPanelContainerIsVisible = (isVisible, dispatch) => dispatch({
  type: 'SET_LEFT_PANEL_CONTAINER_IS_VISIBLE',
  payload: isVisible,
});

export const setHeaderBarIsVisible = (isVisible, dispatch) => dispatch({
  type: 'SET_HEADER_BAR_IS_VISIBLE',
  payload: isVisible,
});

export const setTimelinePanelIsVisible = (isVisible, dispatch) => dispatch({
  type: 'SET_TIMELINE_PANEL_IS_VISIBLE',
  payload: isVisible,
});

export const setTimelinePanelIsFixed = (isFixed, dispatch) => dispatch({
  type: 'SET_TIMELINE_PANEL_IS_FIXED',
  payload: isFixed,
});

export const setDebugBorderIsVisible = (isVisible, dispatch) => dispatch({
  type: 'SET_DEBUG_BORDER_IS_VISIBLE',
  payload: isVisible,
});
