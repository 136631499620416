import { CommandProps } from '../../../types/DPathParse';
import KeyframeProps from './KeyframeProps';

export type ElementTypes =
  | 'rect'
  | 'text'
  | 'image'
  | 'path'
  | 'group'
  | 'circle'
  | 'ellipse'
  | 'line'
  | 'polyline'
  | 'polygon';

export interface ElementProps {
  type: ElementTypes;
  id: string;
  name: string;
  duration: number;
  start: number;
  maskElements?: ElementProps[];
  props: any; // TODO
  currentProps: any; // TODO:
  keyframesOpen: boolean;
  keyframes: { [index: string]: KeyframeProps[] }; // TODO:
  cache: any; // TODO:
  easing: string;
  animationPathD: string;
  locked?: boolean; // Layer is locked from selection
  text?: string;
  maintainAspect?: boolean;
  transform: (matrix: DOMMatrix) => void;
  setCurrentProps: (time: number) => void;
  update: (key: string, value: any) => void;
  getMinMaxKeyframes: () => { min: number | null; max: number | null };
  removeKeyframeGroup: (type: string) => void;
  moveAllKeyframes: (time: number) => void;
  addKeyframeGroup: (key: string) => void;
  setKeyframeCache: (time: number) => void;
  cacheTransform: () => void;
  clearTransformCache: () => void;
  getKeyframeCache: (
    time: number
  ) => { shouldUpdate: boolean; isAboveMax: boolean; isBelowMin: boolean };
  addKeyframe: (
    key: string,
    value: any, // FIXME:
    absoluteTime: number,
    callback?: () => void
  ) => void;

  // GroupElement
  ref?: ElementProps; // Sometimes added for temporary grouping
}

export type ImageElementProps = ElementProps & {
  type: 'image';
  src: string;
  fileID: number;
};

export type TextElementProps = ElementProps & {
  text: string;
  type: 'text';
  // renderText: () => RenderTextData;
  renderText: () => any; // FIXME:
};

export type GroupElementProps = ElementProps & {
  type: 'group';
  elements: ElementsArrayProps;
  onUngroup: () => void;
};

export function isGroupElement(
  element: MixedElementProps
): element is GroupElementProps {
  return (element as GroupElementProps).type === 'group';
}

export type RectElementProps = ElementProps & {};

export type PathElementProps = ElementProps & {
  // PathElement
  parsedPath: CommandProps[];
  addPoint: (
    svg: SVGElement,
    e: MouseEvent,
    initialCp?: any // { cpx: number; cpy: number } FIXME:
  ) => void;
  translateControlPoint: (
    svg: any, // FIXME:
    e: MouseEvent,
    commandIndex: number,
    mirror?: boolean
  ) => void;
  clipPath: {
    type: string;
    attributes: {};
  };
};

export type MixedElementProps =
  | ElementProps
  | ImageElementProps
  | TextElementProps
  | GroupElementProps
  | RectElementProps
  | PathElementProps;

export type ElementsArrayProps = MixedElementProps[];
