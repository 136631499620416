import create from '../../../editor/create';
import getElementArgs from './getElementArgs';

export default (
  node,
  transform,
  startTime,
  duration,
  currentTime,
  hasAnimation,
  legacyScene,
  legacySceneLocation,
  map // The index map to use to navigate and find a nested child
) => {
  let legacyScenePath = null;

  // If this is a legacy scene, find the corresponding scene path in the legacy "Scene" group
  if (legacyScene) {
    const legacyMap = [...map];
    // Shift off the first value since the "Animation" group should be a sibling to "Scene"
    legacyMap.shift();
    // Account for current position in tree
    legacyMap.splice(0, legacySceneLocation.length);

    const findScenePath = (n, m) => {
      const child = n.children[m[0]];
      m.shift();

      if (m.length > 0 && child) {
        findScenePath(child, m);
      } else if (child && child.type === 'path') {
        legacyScenePath = child;
      }
    };

    findScenePath(legacyScene, [...legacyMap]);

    if (!legacyScenePath) {
      console.error('CORRESPONDING SCENE PATH NOT FOUND');
    }
  }

  const pathHasFill = !!(
    node.attributes.style.fill && node.attributes.style.fill !== 'none'
  );

  // Make sure the legacy path has an associated pressure path
  // (true when it doesn't have a stroke)
  const legacyScenePathHasPressure =
    legacyScenePath &&
    !legacyScenePath.attributes.style.stroke &&
    !legacyScenePath.attributes.style.strokeWidth;

  const args = getElementArgs(
    node,
    {
      props: {
        ...transform,
        ...(!legacyScenePathHasPressure
          ? {
              pressure: [
                [0, 1],
                [1, 1],
              ],
            }
          : {}),
      },
      duration,
      // Add in clipPath property if legacy scene path exists
      ...(legacyScenePath && legacyScenePathHasPressure
        ? { clipPath: legacyScenePath }
        : {}),
    },
    // Don't add stroke animations if it has a fill
    !pathHasFill && hasAnimation
      ? {
          startTime,
          currentTime,
        }
      : {}
  );

  const newElement = create.path(...args);

  return newElement;
};
