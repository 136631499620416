import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import Button from '../Button';

import { colors, lighten } from '../styles/colors';

function Pagination(props) {
  const { page, pages, handlePageChange, style } = props;

  const maxPages = pages < 9 ? pages : 9;

  // Determine starting page
  let startPage = 0;
  if (page - maxPages / 2 > 0) startPage = Math.ceil(page - maxPages / 2);
  if (page + maxPages / 2 > pages) startPage = Math.ceil(pages - maxPages);

  // Add page link elements
  const pageElements = [];
  for (let i = startPage; i < startPage + maxPages; i += 1) {
    const pageElement = (
      <PageText
        key={i}
        selected={page === i}
        onClick={() => handlePageChange(i)}
      >
        {i + 1}
      </PageText>
    );

    pageElements.push(pageElement);
  }

  return (
    <Container style={style}>
      <Button
        className="icon transparent"
        onClick={() => page > 0 && handlePageChange(page - 1)}
      >
        <FontAwesomeIcon icon={['fas', 'chevron-left']} />
      </Button>

      {startPage > 0 && (
        <FlexContainer>
          <PageText onClick={() => handlePageChange(0)}>{1}</PageText>
          <Ellipsis>...</Ellipsis>
        </FlexContainer>
      )}

      {pageElements}

      {pages > maxPages && startPage + maxPages < pages && (
        <FlexContainer>
          <Ellipsis>...</Ellipsis>
          <PageText
            selected={page === pages}
            onClick={() => handlePageChange(pages - 1)}
          >
            {pages}
          </PageText>
        </FlexContainer>
      )}

      <Button
        className="icon transparent"
        onClick={() => {
          if (page < pages - 1) handlePageChange(page + 1);
        }}
      >
        <FontAwesomeIcon icon={['fas', 'chevron-right']} />
      </Button>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const PageText = styled.h4`
  margin: 0;
  margin-left: 5px;
  margin-right: 5px;
  padding: 10px;
  border-radius: 3px;
  color: ${colors['light-grey-30']};

  cursor: pointer;
  :hover,
  :focus {
    color: ${colors['light-grey-10']};
    background: ${lighten(colors.grey, 55)};
  }

  :active {
    background: ${lighten(colors.grey, 45)};
  }

  ${(props) =>
    props.selected &&
    css`
      color: ${colors.grey};
      background: ${colors['light-grey-50']};

      :hover,
      :focus {
        background: ${colors['light-grey-50']};
      }
    `}
`;

const Ellipsis = styled.h4`
  margin: 0;
  margin-left: 5px;
  margin-right: 5px;

  color: ${colors['light-grey-30']};
`;

Pagination.propTypes = {
  page: PropTypes.number,
  pages: PropTypes.number,
  handlePageChange: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
};

Pagination.defaultProps = {
  page: 0,
  pages: 10,
  style: {},
};

export default Pagination;
