import React from 'react';
import { Box, SxStyleProp } from 'rebass/styled-components';

/**
 * A custom icon for the add keyframe button
 */
function AddKeyframeIcon(props: { sx?: SxStyleProp }) {
  const { sx } = props;

  return (
    <Box
      sx={{
        width: '22px',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <svg width="100%" height="100%" viewBox="0 0 831 766">
        <path d="M424.837,146.619c-5.272,-5.272 -13.82,-5.272 -19.092,0l-226.397,226.398c-5.272,5.272 -5.272,13.819 -0,19.091l226.397,226.398c5.272,5.272 13.82,5.272 19.092,-0l226.398,-226.398c5.272,-5.272 5.272,-13.819 -0,-19.091l-226.398,-226.398Zm-35.396,210.093l-0,-64.928c-0,-7.456 6.044,-13.5 13.5,-13.5l24.701,0c7.456,0 13.5,6.044 13.5,13.5l-0,64.928l64.928,0c7.456,0 13.5,6.044 13.5,13.5l-0,24.701c-0,7.456 -6.044,13.5 -13.5,13.5l-64.928,-0l-0,64.928c-0,7.456 -6.044,13.5 -13.5,13.5l-24.701,-0c-7.456,-0 -13.5,-6.044 -13.5,-13.5l-0,-64.928l-64.928,-0c-7.456,-0 -13.5,-6.044 -13.5,-13.5l0,-24.701c0,-7.456 6.044,-13.5 13.5,-13.5l64.928,0Z" />
      </svg>
    </Box>
  );
}

export default AddKeyframeIcon;
