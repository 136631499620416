import React from 'react';

import { memo } from 'react';

import TimelineRowInfoWrapper from './TimelineRowInfoWrapper';

const TimelineRowInfoContainer = (props) => {
  const {
    elements,
    elementsToUpdate,
    selectedElements,
    handleUpdateElement,
    handleAddKeyframe,
    handleSelectElement,
    rowHeight,
    selectedKeyframeGroups,
    handleSelectKeyframeGroup,
    handleDeselectKeyframeGroup,
    handleRemoveKeyframeGroup,
  } = props;

  return elements.map((element) => (
    <TimelineRowInfoWrapper
      key={element.id}
      element={element}
      elementsToUpdate={elementsToUpdate}
      handleUpdateElement={handleUpdateElement}
      handleAddKeyframe={handleAddKeyframe}
      selectedElements={selectedElements}
      isSelected={selectedElements.find((el) => el.id === element.id)}
      handleSelectElement={handleSelectElement}
      height={rowHeight}
      selectedKeyframeGroups={selectedKeyframeGroups}
      handleSelectKeyframeGroup={handleSelectKeyframeGroup}
      handleDeselectKeyframeGroup={handleDeselectKeyframeGroup}
      handleRemoveKeyframeGroup={handleRemoveKeyframeGroup}
    />
  ));
};

// Memoize this container when we're playing or scrubbing an animation
export default memo(TimelineRowInfoContainer, (prevProps, nextProps) => {
  if (nextProps.isPlaying) return true;
  return false;
});
