module.exports = [
  {
    taskID: 0,
    taskName: 'Script Writer',
    icon: 'scroll',
    color: 'lavender',
  },
  {
    taskID: 1,
    taskName: 'Illustrator',
    icon: 'pencil-paintbrush',
    color: 'blue',
  },
  {
    taskID: 2,
    taskName: 'Voiceover Artist',
    icon: 'microphone-alt',
    color: 'green',
  },
  {
    taskID: 3,
    taskName: 'Audio Editor',
    icon: 'microphone-alt-slash',
    color: 'yellow',
  },
  {
    taskID: 4,
    taskName: 'Video Editor',
    icon: 'photo-video',
    color: 'orange',
  },
  {
    taskID: 5,
    taskName: 'Reviewer',
    icon: 'comment-edit',
    color: 'red',
  },
];
