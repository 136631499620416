import React from 'react';
import Box from '../Box';
import OsmosisSection from '../projectContents/OsmosisSection';

const OsmosisDashboard = ({ projectID }: { projectID: number }) => {
  return (
    <Box p={5} maxWidth={1200} margin="auto">
      <OsmosisSection projectID={projectID} />
    </Box>
  );
};

export default OsmosisDashboard;
