import React from 'react';
import styled from 'styled-components/macro';

/**
 * A custom icon for the node tool
 */
function NodeToolIcon() {
  const styles = {
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    strokeLinejoin: 'round',
    strokeMiterlimit: 2,
  };

  return (
    <Container>
      <svg width="100%" height="100%" viewBox="0 0 831 766" style={styles}>
        <path d="M472.165,488.894l-117.456,135.999c-3.433,3.975 -8.86,5.598 -13.911,4.16c-5.052,-1.437 -8.812,-5.674 -9.638,-10.861l-73.388,-460.719c-0.859,-5.392 1.615,-10.771 6.268,-13.628c4.653,-2.857 10.569,-2.63 14.99,0.576l372.477,270.118c4.178,3.03 6.263,8.176 5.372,13.259c-0.891,5.083 -4.601,9.214 -9.56,10.642l-175.154,50.454Z" />
      </svg>
    </Container>
  );
}

const Container = styled.div`
  width: 22px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

NodeToolIcon.propTypes = {};

NodeToolIcon.defaultProps = {};

export default NodeToolIcon;
