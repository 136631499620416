import styled, { css } from 'styled-components/macro';
import {
  space,
  layout,
  color,
  SpaceProps,
  ColorProps,
  FontSizeProps,
  FontWeightProps,
  LineHeightProps,
  LayoutProps,
} from 'styled-system';

import { colors, darken, fade, lighten } from './colors';

type TypographyProps = SpaceProps &
  ColorProps &
  LayoutProps &
  FontSizeProps &
  FontWeightProps &
  LineHeightProps & {
    inverted?: boolean;
    darkMode?: boolean;
  };

const fontFamily = "'Nunito','Helvetica Neue',Arial,Helvetica,sans-serif";
export const H1 = styled.h1<TypographyProps>`
  font-size: 32px;
  font-family: ${fontFamily};
  color: ${(props) => props.color || darken(colors.grey, 30)};

  margin: 0;

  ${(props) =>
    props.inverted &&
    css`
      color: ${fade('white', 0)};
    `}

  ${space}
  ${layout}
  ${color}
`;
export const H2 = styled.h2<TypographyProps>`
  font-size: 24px;
  font-family: ${fontFamily};
  color: ${(props) => props.color || darken(colors.grey, 20)};

  margin: 0;

  ${(props) =>
    props.inverted &&
    css`
      color: ${fade('white', 5)};
    `}

  ${space}
  ${layout}
  ${color}
`;
export const H3 = styled.h3<TypographyProps>`
  font-size: 18px;
  font-family: ${fontFamily};
  color: ${(props) => props.color || darken(colors.grey, 10)};

  margin: 0;

  ${(props) =>
    props.inverted &&
    css`
      color: ${fade('white', 10)};
    `}

  ${space}
  ${layout}
  ${color}
`;

export const H4 = styled.h4<TypographyProps>`
  font-size: 16px;
  font-family: ${fontFamily};
  color: ${(props) => props.color || colors.grey};

  margin: 0;

  ${(props) =>
    props.inverted &&
    css`
      color: ${fade('white', 15)};
    `}

  ${space}
  ${layout}
  ${color}
`;
export const P = styled.p<TypographyProps>`
  font-size: 16px;
  font-family: ${fontFamily};
  line-height: 1.5em;
  color: ${(props) => props.color || lighten(colors.grey, 5)};

  margin: 0;

  ${(props) =>
    props.inverted &&
    css`
      color: ${fade('white', 20)};
    `}

  ${(props) =>
    props.darkMode &&
    css`
      color: ${fade('white', 30)};
    `}

  ${space}
  ${layout}
  ${color}
`;

export const A = styled.a<TypographyProps>`
  font-family: ${fontFamily};
  cursor: pointer;

  ${space}
  ${layout}
  ${color}
`;
