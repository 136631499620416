import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { motion, AnimatePresence } from 'framer-motion';

import Button from './Button';

const PreviewImageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const PreviewBackground = styled(motion.div)`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
`;

const Preview = styled(motion.div)`
  position: relative;
  z-index: 1;
  width: 75%;
  height: 75%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * Component that displays the children in a popout with a faded background
 */
function Lightbox(props) {
  const { isVisible, handleHideLightbox, children } = props;

  return (
    <AnimatePresence>
      {isVisible && (
        <PreviewImageContainer>
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
          >
            <Button
              style={{ position: 'absolute', top: 10, right: 10, zIndex: 2 }}
              icon
              transparent
              color="white"
              onClick={handleHideLightbox}
            >
              <FontAwesomeIcon icon={['fas', 'times']} />
            </Button>
          </motion.div>
          <PreviewBackground
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={handleHideLightbox}
          />
          <Preview
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5, transition: { easing: 'easeOut' } }}
          >
            {children}
          </Preview>
        </PreviewImageContainer>
      )}
    </AnimatePresence>
  );
}

Lightbox.propTypes = {
  isVisible: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  handleHideLightbox: PropTypes.func,
};

Lightbox.defaultProps = {
  isVisible: false,
  children: null,
  handleHideLightbox: () => {},
};

export default Lightbox;
