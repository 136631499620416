import React from 'react';
import PropTypes from 'prop-types';
import Point from './Point';
import ControlPoint from './ControlPoint';
import { mirrorCp } from '../../../app/editor/path';

/**
 * A Ghost path that's created before we actually create
 * a new path, used to initialize the first control point
 */
const InitialPath = (props) => {
  const { x, y, cpx, cpy } = props;

  const mirroredCp = mirrorCp(cpx, cpy, x, y);

  return (
    <g>
      <Point x={x} y={y} isActive={true} />
      <ControlPoint x={x} y={y} cpx={cpx} cpy={cpy} />
      <ControlPoint x={x} y={y} cpx={mirroredCp.x} cpy={mirroredCp.y} />
    </g>
  );
};

const { number } = PropTypes;

InitialPath.propTypes = {
  cpx: number,
  cpy: number,
  x: number,
  y: number,
};

export default InitialPath;
