import SvgElement from './SvgElement';

/**
 * Class representing a <rect> element
 *
 * @class Rect
 * @extends {SvgElement}
 */
class Rect extends SvgElement {
  constructor(args = {}) {
    super({
      ...args,
      type: 'rect',
    });

    // Allow free aspect resizing by default for rects
    this.maintainAspect = false;

    this.currentProps = {
      ...this.currentProps,
      style: {
        strokeWidth: 1,
        strokeLinejoin: 'round',
        stroke: 'transparent',
        rx: 0, // Rounded corner radius
        ...this.currentProps.style,
      },
    };
  }

  /**
   * Updates the stroke of the rect to maintain it's width (and not transform)
   *
   * @memberof Rect
   */
  updateStroke() {
    const newWidth = this.currentProps.width * this.currentProps.scaleX;
    const newHeight = this.currentProps.height * this.currentProps.scaleY;

    this.update('currentProps', {
      ...this.currentProps,
      scaleX: 1,
      scaleY: 1,
      height: newHeight,
      width: newWidth,
    });
  }

  /**
   * Hook to recalculate rect width and height whenever
   * it gets transformed
   *
   * @param {DOMMatrix} matrix
   * @memberof Rect
   */
  transform(matrix) {
    super.transform(matrix);

    // Updates the width of a stroke
    this.updateStroke();
  }
}

export default Rect;
