import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import React, { useState } from 'react';

import Box from '../Box';
import Button from '../Button';
import Draggable from '../Draggable';

import { colors } from '../styles/colors';

function CreditsSpacer(props) {
  const { spacer, handleUpdateSpacer } = props;

  const [height, setHeight] = useState(spacer.height || 300);
  const [isEditing, setIsEditing] = useState(false);

  const handleResize = (move) => {
    setHeight(+height + move.dy);
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveEdits = () => {
    handleUpdateSpacer({ ...spacer, height });
  };

  const handleCancelEdits = () => {
    setHeight(spacer.height);
  };

  return (
    <Box style={{ position: 'relative' }}>
      <SpacerContainer
        onClick={toggleEditing}
        height={height}
        isEditing={isEditing}
      >
        {isEditing && (
          <ButtonContainer>
            <Button
              className="green thin box left-icon"
              onClick={handleSaveEdits}
            >
              <FontAwesomeIcon icon={['fad', 'check']} />
              Save
            </Button>
            <Button className="box thin" onClick={handleCancelEdits}>
              Cancel
            </Button>
          </ButtonContainer>
        )}
      </SpacerContainer>
      {isEditing && (
        <Draggable onMouseMove={handleResize}>
          <SpacerHandle />
        </Draggable>
      )}
    </Box>
  );
}

const SpacerContainer = styled.div`
  width: 100%;
  height: ${(props) => props.height}px;

  ${(props) =>
    props.isEditing &&
    css`
      border: 1px solid ${colors.blue};
    `}
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 10px;

  display: flex;

  button {
    margin-left: 10px;
  }
`;

const SpacerHandle = styled.div`
  position: absolute;
  height: 4px;
  bottom: 0;
  width: 100%;
  background: ${colors.blue};
  cursor: ns-resize;
`;

CreditsSpacer.propTypes = {
  handleUpdateSpacer: PropTypes.func.isRequired,
  spacer: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
};

CreditsSpacer.defaultProps = {
  spacer: {
    height: 300,
  },
};

export default CreditsSpacer;
