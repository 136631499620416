import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Box from '../../Box';
import Dialog from '../../Dialog';
import Warning from '../../dialogs/WarningDialog';
import { colors } from '../../styles/colors';

import Button from '../../Button';
import useSnackbar from '../../../app/hooks/useSnackbar';
import Snackbar from '../../Snackbar';
import SnackbarContainer from '../../editor/SnackbarContainer';
import LoadingSpinner from '../../LoadingSpinner';
import { P } from '../../styles/typography';

function FileDelete(props) {
  const { files, onDelete } = props;

  const snackbar = useSnackbar();
  const [warningDialogVisible, setWarningDialogVisible] = useState(false);

  const handleDelete = (e) => {
    snackbar.show();

    onDelete(e);

    setWarningDialogVisible(false);
  };

  return (
    <Box style={{ padding: '10px' }}>
      <Snackbar isVisible={snackbar.isVisible}>
        <SnackbarContainer sx={{ display: 'flex', alignItems: 'center' }}>
          <Box mr={3}>
            <LoadingSpinner />
          </Box>
          <P color="light-1">Deleting {files.length} files</P>
        </SnackbarContainer>
      </Snackbar>

      <Button
        basic
        box
        color="red"
        leftIcon
        style={{ width: '100%' }}
        onClick={() => setWarningDialogVisible(true)}
      >
        <FontAwesomeIcon icon={['fad', 'trash-alt']} />
        Delete{files.length > 1 ? ` (${files.length} files)` : ''}
      </Button>
      <Dialog
        isVisible={warningDialogVisible}
        handleHideDialog={() => setWarningDialogVisible(false)}
        hasHeader={true}
        headerColor="white"
        headerBackground={colors.red}
        headerContent={
          <FontAwesomeIcon
            style={{ fontSize: 20 }}
            icon={['fad', 'exclamation-circle']}
          />
        }
      >
        <Warning
          onConfirm={handleDelete}
          color={colors.red}
          onCancel={() => setWarningDialogVisible(false)}
          header={
            files.length > 1
              ? `Are you sure you want to delete these ${files.length} files?`
              : 'Are you sure you want to delete this file?'
          }
          error
        />
      </Dialog>
    </Box>
  );
}

FileDelete.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      fileID: PropTypes.number,
    })
  ),
  canEdit: PropTypes.bool,
};

FileDelete.defaultProps = {
  file: {},
  onDelete: () => {},
};

export default FileDelete;
