import React from 'react';
import PropTypes from 'prop-types';
import { goToUrl } from '../../shared/links';

import Avatar from '../Avatar';

import {
  Container,
  NotificationContent,
  NotificationAction,
  UserName,
  ProjectName,
  FileName,
} from './CommentReplyStyles';

function CommentReply(props) {
  const {
    actionText,

    sendingUserName,
    sendingUserAvatar,
    projectID,
    projectName,
    file,
    notificationID,
    commentID,
    isActive,

    handleUpdateStatus,
  } = props;

  const handleClick = () => {
    handleUpdateStatus([notificationID], 1);

    goToUrl({
      projectID,
      view: 'project',
      fileID: file.fileID,
      commentID,
    });
  };

  return (
    <Container isActive={isActive} onClick={handleClick}>
      <Avatar size={30} src={sendingUserAvatar} />
      <NotificationContent>
        <UserName isActive={isActive}>{sendingUserName}</UserName>
        <NotificationAction>&nbsp;{actionText}</NotificationAction>
        <ProjectName isActive={isActive}>&nbsp;{projectName}</ProjectName>
        {file && file.name && <FileName>{file.name}</FileName>}
      </NotificationContent>
    </Container>
  );
}

CommentReply.propTypes = {
  actionText: PropTypes.string.isRequired,
  handleUpdateStatus: PropTypes.func.isRequired,
  notificationID: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  commentID: PropTypes.number.isRequired,

  sendingUserName: PropTypes.string,
  sendingUserAvatar: PropTypes.string,
  projectName: PropTypes.string,
  projectID: PropTypes.number,
  timestamp: PropTypes.string,
  file: PropTypes.object,
};

CommentReply.defaultProps = {
  file: {},
  sendingUserName: '',
  sendingUserAvatar: '',
  projectName: '',
  projectID: null,
  timestamp: '',
};

export default CommentReply;
