import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Box from '../../Box';
import NumberInput from '../../NumberInput';
import { P } from '../../styles/typography';
import ColorPicker from '../../ColorPicker';
import OptionSelector from '../../OptionSelector';

import { PropertyContainer, Unit, Section } from './PropertyStyles';

import editorColors from '../../../app/data/editorColors';
import { style as styleStore } from '../../../app/editor/preferenceStore';

import ElementProps from '../props/ElementProps';

const properties = [
  {
    key: 'fontSize',
    name: 'Font Size',
  },
  {
    key: 'lineHeight',
    name: 'Line Height',
    step: 0.1,
    fixed: 1,
  },
];

/**
 * Properties specific to text elements
 */
function TextProperties(props) {
  const { element, handleUpdateElement, addKeyframe } = props;

  const { style } = element.currentProps;

  const updateCurrentProps = (key, value) => {
    element.update('currentProps', {
      ...element.currentProps,
      [key]: value,
    });

    handleUpdateElement([element.id]);
  };

  const handleUpdateStyle = (key, newVal) => {
    updateCurrentProps('style', {
      ...element.currentProps.style,
      [key]: newVal,
    });

    styleStore[key] = newVal;
  };

  const handleColorSave = (colorObj) => {
    handleUpdateStyle('color', colorObj.toHEXA().toString());
  };

  const handleChangeProgress = (newVal) => {
    updateCurrentProps('progress', newVal / 100);

    addKeyframe('text', newVal / 100);
  };

  const propertyComponents = properties.map((property) => (
    <PropertyContainer key={property.key}>
      <Section>
        <Label>{property.name}</Label>
        <NumberInput
          min={1}
          value={style[property.key]}
          step={property.step || 1}
          fixed={property.fixed || 0}
          onChange={(newVal) => handleUpdateStyle(property.key, newVal)}
          style={{ width: '50px', fontSize: 14, padding: '.5em' }}
        />
        <Unit>px</Unit>
      </Section>
    </PropertyContainer>
  ));

  const fontWeightOptions = [
    { id: 400, name: 'Regular' },
    { id: 700, name: 'Bold' },
    { id: 200, name: 'Light' },
  ];

  return (
    <div style={{ height: '100%' }}>
      {style && (
        <Box>
          {propertyComponents}
          <PropertyContainer display="flex">
            <OptionSelector
              options={fontWeightOptions}
              selectedOption={fontWeightOptions.find(
                (op) => op.id === element.currentProps.style.fontWeight
              )}
              onSelectOption={(option) =>
                handleUpdateStyle('fontWeight', option.id)
              }
              buttonClasses="thin box basic"
              placeholder="Font weight..."
            />
          </PropertyContainer>
          <PropertyContainer display="flex">
            <P style={{ marginRight: 10 }}>Stroke</P>
            <ColorPicker
              swatches={editorColors}
              initialColor={element.currentProps.style.color}
              onColorSelect={handleColorSave}
              saveOnSelect={true}
            />
          </PropertyContainer>
          {/* <PropertyContainer>
            <P>Text</P>
            <TextArea
              value={element.text}
              onChange={handleChangeText}
              onFocus={handleFocus}
              onBlur={handleBlur}
              style={{
                fontFamily: 'Tannerscript',
                fontWeight: element.currentProps.style.fontWeight,
                width: '100%',
                fontSize: 20,
              }}
            />
          </PropertyContainer> */}
          <PropertyContainer>
            <P>Progress</P>
            <Section>
              <NumberInput
                max={100}
                min={0}
                value={element.currentProps.progress * 100}
                onChange={(newVal) => handleChangeProgress(newVal)}
                style={{ width: '50px', fontSize: 14, padding: '.5em' }}
              />
              <Unit>%</Unit>
            </Section>
          </PropertyContainer>
        </Box>
      )}
    </div>
  );
}

const Label = styled(P)``;

const { func } = PropTypes;

TextProperties.propTypes = {
  element: ElementProps.isRequired,
  handleUpdateElement: func.isRequired,
  addKeyframe: func.isRequired,
};

export default TextProperties;
