import styled from 'styled-components';
import Box from '../Box';

export const RouteItem = styled(Box)`
  padding: 10px 15px;

  i,
  svg {
    margin-right: 10px;
  }

  cursor: pointer;
`;

export default {
  RouteItem,
};
