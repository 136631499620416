import JSZip from 'jszip';

import { post } from '../util/fetchUtil';

import Uploader from './Uploader';
import uuid from './utilities/uuid';
import { getExtension } from '../utilities/files';

import { to } from '../shared/utilities/promise';

// Initialize fileManager Uploader

/**
 * Get the selected file from S3 and decode to be read as SVG text by Osmosify
 *
 * @param {Object} url - the url to get the file from
 * @returns {Promise}
 * @memberof LayersPanel
 */ export const getSvgFileObject = (url) =>
  new Promise((resolve) => {
    fetch(url, { cache: 'no-store' }) // Do not cache the SVG, in the file is updated with the same name
      .then((res) => res.arrayBuffer())
      .then((res) => {
        // Decode bytes to an <svg> string
        const decoder = new TextDecoder();
        const svgString = decoder.decode(res);

        // Create blob
        const blob = new Blob([svgString], { type: 'text/plain' });

        resolve(blob);
      });
  });

/**
 * Download a file
 *
 * @param {Project File Object} file - a project file
 * @param {Function} onProgress - a callback function that's provided
 * with the current download progress:
 * {
 *  received: {Number},
 *  total: {Number},
 * }
 * @returns {Promise}
 * @memberof LayersPanel
 */ export const fetchFile = async (url, onProgress = () => {}) => {
  const id = uuid();

  // Add a query parameter timestamp to force the browser to not use the HTML cached
  // version of the file This happens in Chrome for images files (png, jpeg, etc.)
  // Reason -> https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
  const timestampURL = `${url}?ts=${new Date()}`;

  const [err, res] = await to(fetch(timestampURL));
  if (err) throw new Error(err);

  const reader = res.body.getReader();
  const contentLength = +res.headers.get('Content-Length');

  let receivedLength = 0; // received that many bytes at the moment
  const chunks = []; // array of received binary chunks (comprises the body)
  // FIXME: SHOULDN'T USE AWAIT IN A LOOP
  // eslint-disable-next-line no-constant-condition
  while (true) {
    // eslint-disable-next-line no-await-in-loop
    const { done, value } = await reader.read();

    if (done) break;

    chunks.push(value);
    receivedLength += value.length;

    onProgress({
      received: receivedLength,
      total: contentLength,
      id,
    });
  }

  const blob = new Blob(chunks);

  return blob;
};

export const downloadZipFile = async (files, onProgress = () => {}) => {
  // Record download to rate limiter
  const fileTypes = files.map((file) => getExtension(file.name).slice(1));
  const res = await post('/files/recordDownload', {
    fileTypes,
  });

  if (res.limitReached) window.location.reload();

  const failed = [];
  const zip = new JSZip();

  const progress = {};

  const handleFetchProgress = ({ total, received, id }) => {
    if (!progress[id]) {
      progress[id] = {
        total,
        received,
      };
    } else {
      progress[id].received = received;
    }

    const totalTotal = Object.values(progress).reduce((t, p) => t + p.total, 0);
    const totalReceived = Object.values(progress).reduce(
      (t, p) => t + p.received,
      0
    );

    onProgress({ total: totalTotal, received: totalReceived });
  };

  // Fetch all files
  const fetchPromises = [];
  for (let i = 0; i < files.length; i += 1) {
    // Add a query parameter timestamp to force the browser to not use the HTML cached
    // version of the file This happens in Chrome for images files (png, jpeg, etc.)
    // Reason -> https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
    fetchPromises.push(fetchFile(files[i].data.url, handleFetchProgress));
  }

  const fetchRes = await Promise.allSettled(fetchPromises);

  fetchRes.forEach((r, i) => {
    if (r.value) {
      let { name } = files[i];
      if (!getExtension(name)) name = `${name}.${files[i].type}`;

      zip.file(`${files[i].type}/${name}`, r.value);
    } else {
      failed.push(files[i]);
    }
  });

  let zipFile = null;
  if (Object.values(zip.files).filter((item) => !item.dir).length) {
    zipFile = await zip.generateAsync({ type: 'blob' });
  }

  if (zipFile) {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);

    a.href = window.URL.createObjectURL(zipFile);
    // Use download attribute to set desired file name
    a.download = 'files.zip';

    // Trigger the download by simulating click
    a.click();

    // Cleanup
    document.body.removeChild(a);
    window.URL.revokeObjectURL(a.href);
  }

  return { failed };
};

export const downloadFile = async (blob, name = 'untitled') => {
  // Record download to rate limiter
  const fileType = getExtension(name).slice(1);
  const res = await post('/files/recordDownload', {
    fileTypes: [fileType],
  });

  if (res.limitReached) window.location.reload();

  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);

  // Set the HREF to a Blob representation of the data to be downloaded
  a.href = window.URL.createObjectURL(blob);
  // Use download attribute to set desired file name
  a.download = name;

  // Trigger the download by simulating click
  a.click();

  // Cleanup
  document.body.removeChild(a);
  window.URL.revokeObjectURL(a.href);
};

/**
 * Upload the given file to S3 and associate with a project
 *
 * @memberof LayersPanel
 * @param {Object} file - a File object
 * @param {String} type - the file type, e.g. 'svg'
 * @param {Object} options - additional options that may be passed
 * {
 *    onProgress: function (loaded, total), - onProgress callback
 *    onAfterUpload: function (), - called after upload has completed
 *    data: {Object}, - extra data to attach to uploaded file
 * }
 */ export const uploadProjectFile = async (
  file,
  type,
  context,
  options = {}
) => {
  const { projectID, hash } = context.state.activeProject;
  const {
    onProgress,
    onBeforeUpload = null, // Should return true or a Promise
    onAfterUpload = null, // Should return true or a Promise
    setIsFinished,
    data = {},
  } = options;

  const uploader = new Uploader();

  // Set on progress function only if it exists (otherwise use default Uploader onProgress)
  if (onProgress) uploader.setOnProgress(onProgress);

  const fileName = file.name;
  const fileType = type;

  const key = `projects/${hash}/${fileType}/${uuid()}${fileType}`;

  let err = null;

  // Initialize uploader
  [err] = await to(uploader.init());

  // Run any optional functions before upload
  if (onBeforeUpload) [err] = await to(onBeforeUpload());

  // Add file and upload
  uploader.addFile(file);
  const [uploadErr, uploadRes] = await to(uploader.upload(key));
  if (uploadErr) {
    alert('Uh oh, something went wrong with that upload.');
    setIsFinished(true);
    // eslint-disable-next-line no-console
    return console.error('Something went wrong with that upload');
  }

  let res = {};
  if (uploadRes.url) {
    const [addFileErr, addFileRes] = await to(
      post('/project/addFile', {
        projectID,
        name: fileName,
        type: fileType,
        size: file.size,
        data: {
          ...data,
          url: uploadRes.url,
        },
      })
    );

    if (addFileErr) {
      // eslint-disable-next-line no-console
      alert('Uh oh, something went wrong with that upload.');
      return console.error('Something went wrong with that upload');
    }

    res = addFileRes;
    setIsFinished(true);
  } else {
    alert('Uh oh, no URL returned for the upload.');
    setIsFinished(true);
    // eslint-disable-next-line no-console
    return console.error('Uh oh, no URL returned for the upload.');
  }

  // If error exists
  if (onAfterUpload) [err] = await to(onAfterUpload());
  if (err) {
    alert('Uh oh, something went wrong with that upload.');
    // eslint-disable-next-line no-console
    return console.error('Uh oh, something went wrong with that upload');
  }

  return { fileName, ...res, file };
};

/**
 * uploadFile - Generic file upload
 *
 * @param {Object} file - a file object
 * @param {String} key - the S3 key string
 * @param {Object} [options={}] - additional options
 * {
 *  onProgress: {Function}, - callback to call on uploader progress
 *  onBeforeUpload: {Function}, - callback to initiate before the upload begins
 * }
 */ export const uploadFile = (file, key, options = {}) =>
  new Promise((resolve) => {
    const {
      onProgress,
      onBeforeUpload = () => true, // Should return true or a Promise
    } = options;

    const uploader = new Uploader();

    // Set on progress function only if it exists (otherwise use default Uploader onProgress)
    if (onProgress) uploader.setOnProgress(onProgress);

    // Initialize uploader
    uploader
      .init()
      // Then upload the file to S3
      .then(() => onBeforeUpload())
      .then(() => {
        // Add file and upload
        uploader.addFile(file);
        return uploader.upload(key);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        alert('Uh oh, something went wrong with that upload.');
        return false;
      })
      .then((res) => resolve({ ...res, file })); // Then finally resolve the promise
  });

export const getFilesFromEvent = (event) => {
  const files = [];
  if (event.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (let i = 0; i < event.dataTransfer.items.length; i += 1) {
      // If dropped items aren't files, reject them
      if (event.dataTransfer.items[i].kind === 'file') {
        const file = event.dataTransfer.items[i].getAsFile();
        files.push(file);
      }
    }
  } else {
    // Use DataTransfer interface to access the file(s)
    for (let i = 0; i < event.dataTransfer.files.length; i += 1) {
      files.push(event.dataTransfer.files[i]);
    }
  }

  return files;
};

/**
 * chooseFromFiles - helper function that appends a new file input element
 * and has the user choose items from files.
 *
 * @param {Function} callback - function that gets passed the selected files
 * @param {Object} [options={ multiple: String }]
 */ export const chooseFromFiles = (callback, options = {}) => {
  const { multiple = false, accept = null } = options;

  const input = document.createElement('input');
  input.style.display = 'none';
  input.type = 'file';

  input.multiple = multiple;
  if (accept) input.accept = accept;

  document.body.appendChild(input);

  // Trigger the download by simulating click
  input.click();

  const cleanup = () => document.body.removeChild(input);

  input.addEventListener('change', (e) => {
    if (e.target.files && e.target.files.length) {
      callback(e.target.files);
    }

    cleanup();
  });
};

export default {
  getFilesFromEvent,
  downloadFile,
  downloadZipFile,
  chooseFromFiles,
  uploadFile,
  uploadProjectFile,
  fetchFile,
  getSvgFileObject,
};
