import UTIF from 'utif';
import LazyLoad from 'react-lazy-load';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useRef, useEffect } from 'react';

import { Flex } from '../../Box';
import Lightbox from '../../Lightbox';

const Image = styled.img`
  border-radius: 3px;
  overflow: hidden;

  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const ImageContainer = styled.div`
  max-width: 75%;
  max-height: 75%;
  object-fit: contain;
  display: flex;
  justify-content: center;

  margin: auto;
`;

const LazyLoadContainer = styled(LazyLoad)`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

function ImageAsset(props) {
  const { file, showPreview, setShowPreview } = props;

  const imageRef = useRef();
  const lightboxImageRef = useRef();

  // Handle tiffs
  // https://github.com/photopea/UTIF.js/
  // Decode tiff or tif files
  const replaceTiff = (imgArray) => {
    if (file.type === 'tiff' || file.type === 'tif') {
      UTIF.replaceIMG(imgArray);
    }
  };

  // Convert tiffs when it appears in lightbox
  useEffect(() => {
    if (showPreview) {
      replaceTiff([lightboxImageRef.current]);
    }
    // eslint-disable-next-line
  }, [showPreview]);

  return (
    <Flex c style={{ height: '100%' }}>
      <LazyLoadContainer
        onContentVisible={() => replaceTiff([imageRef.current])}
        debounce={false}
      >
        <Image ref={imageRef} src={file.data.url} />
      </LazyLoadContainer>
      <Lightbox
        handleHideLightbox={() => setShowPreview(false)}
        isVisible={showPreview}
      >
        <ImageContainer>
          <Image ref={lightboxImageRef} src={file.data.url} />
        </ImageContainer>
      </Lightbox>
    </Flex>
  );
}

ImageAsset.propTypes = {
  file: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  showPreview: PropTypes.bool,
  setShowPreview: PropTypes.func.isRequired,
};

ImageAsset.defaultProps = {
  file: {},
  showPreview: false,
};

export default ImageAsset;
