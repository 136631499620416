export const loadDirSettings = (dirs = [], settings = {}) => {
  const newDirs = dirs.map((d) => ({ ...d }));

  const loadSettings = (child) => {
    if (settings[child.directoryID]) {
      // Assigning to child here because we've created new refs above
      // eslint-disable-next-line no-param-reassign
      child.open = settings[child.directoryID].open;
    }

    if (child.dirs) child.dirs.forEach((d) => loadSettings(d));
  };

  newDirs.forEach((d) => loadSettings(d));

  return newDirs;
};

export const getDirSettings = (dirs) => {
  const settings = {};

  const getSettings = (child) => {
    const childSettings = {};

    // Save child settings
    childSettings.open = child.open;

    // Save to settings object
    settings[child.directoryID] = childSettings;

    if (child.dirs) child.dirs.forEach((d) => getSettings(d));
  };

  dirs.forEach((d) => getSettings(d));

  return settings;
};

export const getDirByID = (dirs, dirId) => {
  let dir = null;
  const getDir = (child) => {
    if (child.directoryID === dirId) {
      dir = child;
      return;
    }

    if (child.dirs) child.dirs.forEach((childDir) => getDir(childDir));
  };

  dirs.forEach((d) => getDir(d));

  return dir;
};

export const getTotalFiles = (dirs = [], dirId) => {
  const files = [];
  const dir = getDirByID(dirs, dirId);

  const getFiles = (child) => {
    if (child.files) files.push(...child.files);
    if (child.dirs) child.dirs.forEach((childDir) => getFiles(childDir));
  };

  getFiles(dir);

  return files;
};

export const getParentDir = (dirs, id) => {
  let parent = null;

  const findDir = (child) => {
    if (!parent && child.dirs) {
      const hasDir = !!child.dirs.find((d) => d.directoryID === id);
      if (hasDir) {
        parent = child;
      } else {
        child.dirs.forEach((d) => findDir(d));
      }
    }
  };

  dirs.forEach((dir) => findDir(dir));

  return parent;
};

export const getBreadcrumbs = (dirs, dir) => {
  const breadcrumbs = [];
  breadcrumbs.push(dir.name);

  const addCrumb = (child) => {
    const parent = getParentDir(dirs, child.directoryID);

    if (parent) {
      breadcrumbs.unshift(parent.name);
      addCrumb(parent);
    }
  };

  addCrumb(dir);

  return breadcrumbs.reduce(
    (prev, curr, i) =>
      `${prev}${curr}${i < breadcrumbs.length - 1 ? ' > ' : ''}`,
    ''
  );
};

export const getUnorganizedFiles = (dirs, files) => {
  const unorganizedFiles = [...files];

  const search = (child) => {
    child.files.forEach((file) => {
      // Handle file as string ID or file object
      const index = unorganizedFiles.findIndex(
        (f) =>
          f.fileID.toString() ===
          (file.fileID ? file.fileID.toString() : file.toString())
      );
      if (index > -1) {
        unorganizedFiles.splice(index, 1);
      }
    });

    if (child.dirs) {
      child.dirs.forEach((dir) => search(dir));
    }
  };

  dirs.forEach((d) => search(d));

  return unorganizedFiles;
};

export const removeExistingFiles = (dirs, filesToRemove) => {
  const newDirs = [...dirs];

  const search = (child) => {
    if (child.files) {
      // If files exist in folder, splice them out
      child.files
        .filter((file) => filesToRemove.find((f) => f.fileID === file.fileID))
        .forEach((file) => {
          const index = child.files.findIndex((f) => f.fileID === file.fileID);
          if (index > -1) {
            child.files.splice(index, 1);
          }
        });
    }

    if (child.dirs) {
      child.dirs.forEach((d) => search(d));
    }
  };

  newDirs.forEach((d) => search(d));

  return newDirs;
};

export const stripData = (directories) => {
  const stripFileData = (dirs, files) =>
    dirs.map((dir) => ({
      name: dir.name,
      id: dir.directoryID,
      files: dir.files
        ? dir.files.map((file) => (file.fileID ? file.fileID : file))
        : [],
      dirs: dir.dirs ? stripFileData(dir.dirs, files) : [],
    }));

  // Recursively add file data
  return stripFileData(directories);
};
