import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

import React, { CSSProperties, ReactNode } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import FileItemInfo from './FileItemInfo';
import Button from '../Button';
import Box from '../Box';
import OptionSelector from '../OptionSelector';
import { Option, OptionsText } from '../OptionSelectorStyles';

import { formatSize } from '../../utilities/files';
import { colors } from '../styles/colors';

import { desktopMin } from '../styles/breakpoints';
import FileProps from '../editor/types/FileProps';

const Container = styled.div`
  display: inline-flex;
  justify-content: space-between;
  padding: 0 0 0 10px;
  border-radius: 3px;

  cursor: pointer;

  @media ${desktopMin} {
    :hover {
      background: ${colors['light-grey-50']};
    }
  }
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }
`;

const LeftContainer = styled(RightContainer)`
  overflow: hidden;

  p {
    margin: 0;
  }
`;

const Actions = styled.div`
  margin-left: 15px;
  display: flex;
  align-items: center;
`;

const FileName = styled.p`
  color: ${colors['light-grey-10']};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
`;

const FileSize = styled.p`
  white-space: nowrap;
  color: ${colors['light-grey-40']};
`;

const StyledButton = styled(Button)`
  opacity: 0;
  ${Container}:hover & {
    opacity: 1;
  }
`;

type Props = {
  file: FileProps;
  style?: CSSProperties;
  actions?: {
    id: string;
    icon: IconProp;
    onClick: (e: React.MouseEvent) => void;
  }[];
  options?: {
    key: string;
    id: string;
    name: string;
    component?: ReactNode;
  }[];
  onProgress: () => void;
  handleDeleteFile: (file: FileProps) => void;
  downloadFile: (file: FileProps, onProgress: () => void) => void;
  onClick: () => void;
};

function FileRow(props: Props) {
  const {
    file,
    onProgress,
    handleDeleteFile,
    downloadFile,
    onClick,
    style,
    actions,
    options,
  } = props;

  const handleDownload = (e: MouseEvent) => {
    e.stopPropagation();
    downloadFile(file, onProgress);
  };

  return (
    <Container style={style} key={file.fileID} onClick={onClick}>
      <LeftContainer>
        <FileName>{file.name}</FileName>
      </LeftContainer>
      <RightContainer>
        <FileSize>{formatSize(file.data.size)}</FileSize>
        <Actions>
          {actions?.map((action) => (
            <StyledButton icon onClick={action.onClick} key={action.id}>
              <FontAwesomeIcon icon={action.icon} />
            </StyledButton>
          ))}
          <StyledButton icon onClick={handleDownload}>
            <FontAwesomeIcon icon={['fad', 'file-download']} />
          </StyledButton>
          <Box>
            <OptionSelector
              options={[
                {
                  key: 'info',
                  id: 'fileItemInfo',
                  name: 'File Item Info',
                  component: <FileItemInfo file={file} />,
                },
                ...(options || []),
                {
                  key: 'delete',
                  id: 'deleteFile',
                  name: 'Delete',
                  component: (
                    <Option onClick={() => handleDeleteFile(file)}>
                      <OptionsText style={{ color: colors.red }}>
                        <FontAwesomeIcon icon={['fad', 'trash-alt']} />
                        &nbsp;&nbsp;Delete
                      </OptionsText>
                    </Option>
                  ),
                },
              ]}
            >
              <StyledButton icon>
                <FontAwesomeIcon icon={['fas', 'ellipsis-v']} />
              </StyledButton>
            </OptionSelector>
          </Box>
        </Actions>
      </RightContainer>
    </Container>
  );
}

FileRow.defaultProps = {
  file: {},
  onProgress: () => {},
  handleDeleteFile: () => {},
  downloadFile: () => {},
  onClick: () => {},
  actions: [],
  options: [],
};

export default FileRow;
