export const getArrayString = (arr: any[], separator: string): string =>
  arr.reduce(
    (previous, current, index) =>
      `${previous}${current}${index < arr.length - 1 ? separator : ''}`,
    ''
  );

export const partition = (
  array: any[],
  isValid: (item: any) => boolean
): [any[], any[]] => {
  return array.reduce(
    ([pass, fail], elem) => {
      return isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
    },
    [[], []]
  );
};

export default { partition };
