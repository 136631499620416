import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useRef, useContext, useEffect, useState } from 'react';

import { Store } from '../../state/store';

import layersPanelController from '../../app/layersPanelController';
import sceneLoader from '../../app/sceneLoader';

import font from '../../app/font';

import { colors } from '../styles/colors';
import { SceneContainer } from '../project/SceneStyles';
import ExporterPortal from './ExporterPortal';

function Exporter() {
  const context = useContext(Store);
  const {
    state: { sceneDimensions },
  } = context;

  const [hasInitialized, setHasInitialized] = useState(false);

  const hiddenInputRef = useRef();

  const init = async () => {
    // Create array of promises
    const promises = [font.init()];

    // After loading's done, set global window variable
    await Promise.all(promises).then(() => {
      window.assetsLoaded = true;
    });

    // Load SVG load function into window for puppeteer
    window.loadSceneFromUrl = layersPanelController.loadSvgFromUrl;

    window.context = context;
    window.sceneLoader = sceneLoader;

    setHasInitialized(true);
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <HiddenInput ref={hiddenInputRef} type="file" />
      {hasInitialized && <ExporterPortal />}
      <ProjectContainer>
        <SceneContainer>
          <InnerSceneContainer id="scene">
            <SceneBackground>
              <svg
                viewBox={`0 0 ${sceneDimensions.w} ${sceneDimensions.h}`}
                width="100%"
                height="100%"
              >
                <rect width={sceneDimensions.w} height={sceneDimensions.h} />
              </svg>
            </SceneBackground>
          </InnerSceneContainer>
        </SceneContainer>
      </ProjectContainer>
    </Container>
  );
}

const Container = styled.div`
  height: 100vh;
`;

const ProjectContainer = styled.div`
  height: 100%;
`;

const InnerSceneContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 0;
  top: 0;
  overflow: hidden;

  background: ${colors['light-grey-50']};
`;

const SceneBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  rect {
    fill: white;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

Exporter.propTypes = {
  propName: PropTypes.string,
};

Exporter.defaultProps = {
  propName: '',
};

export default Exporter;
