import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import useModifier from '../../../app/hooks/useModifier';

import Box from '../../Box';
import { P } from '../../styles/typography';

import ElementRowWrapper from './ElementRowWrapper';

import ElementProps from '../props/ElementProps';

/**
 * Shows items as layers
 */
function ElementsPanel(props) {
  const {
    elements,
    selectedElements,
    setSelectedElements,
    handleReorderElement,
    handleGroupElements,
    handleAddToGroup,
    handleMaskElement,
    style,
    className,
    isLoading,
    handleUpdateElement,
    handleCloneElements,
    handleRemoveElements,
    elementsToUpdate,
  } = props;

  const shiftKeyDown = useModifier('shift').isDown;
  const cmdKeyDown = useModifier('command').isDown;

  const rowContainerRef = useRef();

  const elementRows = elements.map((element) => (
    <ElementRowWrapper
      key={element.id}
      element={element}
      elements={elements}
      parent={elements}
      selectedElements={selectedElements}
      isSelected={!!selectedElements.find((e) => e.id === element.id)}
      shiftKeyDown={shiftKeyDown}
      cmdKeyDown={cmdKeyDown}
      setSelectedElements={setSelectedElements}
      handleReorderElement={handleReorderElement}
      handleAddToGroup={handleAddToGroup}
      handleGroupElements={handleGroupElements}
      handleUpdateElement={handleUpdateElement}
      handleCloneElements={handleCloneElements}
      handleRemoveElements={handleRemoveElements}
      handleMaskElement={handleMaskElement}
      elementsToUpdate={elementsToUpdate}
    />
  ));

  return (
    <Box style={style} className={className}>
      {isLoading && <P>Loading...</P>}
      <Box ref={rowContainerRef}>{elementRows}</Box>
    </Box>
  );
}

const { arrayOf, shape, bool, string, func } = PropTypes;

ElementsPanel.propTypes = {
  elements: arrayOf(ElementProps).isRequired,
  selectedElements: arrayOf(ElementProps).isRequired,
  setSelectedElements: func.isRequired,
  handleReorderElement: func.isRequired,
  handleAddToGroup: func.isRequired,
  handleGroupElements: func.isRequired,
  handleUpdateElement: func.isRequired,
  handleCloneElements: func.isRequired,
  handleRemoveElements: func.isRequired,
  handleMaskElement: func.isRequired,
  isLoading: bool.isRequired,
  elementsToUpdate: shape({
    current: arrayOf(string),
  }).isRequired,
  style: shape({}),
  className: string,
};

export default ElementsPanel;
