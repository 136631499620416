import SceneObject from './SceneObject';
import drawGroup from '../app/draw/group';

class SvgImage extends SceneObject {
  constructor(args) {
    // Call the super constructor
    super(args);
    // Create the svg element
    this.svg = drawGroup(this, true, this.sceneData, this.doc);
    this.anim = this.svg.anim;
    this.name = this.svg.name;
    this.type = 'svgImage';
    this.bbox = this.svg.bbox;
    this.dropdownValues = this.svg.dropdownValues;
  }
}

export default SvgImage;
