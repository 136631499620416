import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import { colors, lighten, fade } from '../ui/styles/colors';

export const TextInput = (props) => {
  const {
    onChange,
    value,
    placeholder,
    className,
    style,
    thin,
    error,
    type,
    onKeyDown,
    onClick,
    onBlur,
    onFocus,
    handleEnterPress,
    darkMode,
  } = props;

  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      case 13: // Enter
        e.preventDefault();
        handleEnterPress(e);
        break;
      default:
        break;
    }

    onKeyDown(e);
  };

  return (
    <StyledInput
      error={error}
      className={className}
      type={type}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      onClick={onClick}
      value={value}
      placeholder={placeholder}
      thin={thin}
      style={style}
      onBlur={onBlur}
      onFocus={onFocus}
      darkMode={darkMode}
    />
  );
};

const StyledInput = styled.input`
  font-size: 16px;
  font-weight: 400;
  color: ${colors['light-grey-20']};

  display: block;

  padding: 10px;

  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: none;
  border-bottom: 2px solid ${colors['light-grey-50']};

  background: ${fade('black', 95)};
  box-shadow: none;

  transition: all 0.2s ease-in-out;

  :after {
    display: absolute;
    content: '';
    border-bottom: solid 3px #019fb6;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  :hover:after {
    transform: scaleX(1);
  }
  /* h1.fromRight:after{ transform-origin:100% 50%; }
  h1.fromLeft:after{  transform-origin:  0% 50%; } */

  ::placeholder {
    /* Chrome/Opera/Safari */
    color: ${colors['light-grey-30']};
  }

  :focus {
    outline: none;
    border-bottom: 2px solid ${colors.blue};
    /* background: ${lighten(colors.blue, 47)} */

    color: ${colors.grey};
  }

  ${(props) =>
    props.thin &&
    css`
      padding: 5px;
    `}

  ${(props) =>
    props.error &&
    css`
      background: ${colors['light-red-40']};
      border-color: ${colors['light-red-20']};
      color: ${colors['light-red-10']};

      :focus {
        color: ${colors.red};
        border-color: ${colors.red};
      }

      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: ${colors['light-red-20']};
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        color: ${colors['light-red-20']};
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        color: ${colors['light-red-20']};
      }
      :-moz-placeholder {
        /* Firefox 18- */
        color: ${colors['light-red-20']};
      }
    `}

    ${(props) =>
    props.darkMode &&
    css`
      background-color: ${fade('white', 95)};
      border-bottom-color: ${fade('white', 75)};

      :focus {
        color: ${fade('white', 50)};
      }
    `}
`;

TextInput.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  thin: PropTypes.bool,
  error: PropTypes.bool,
  className: PropTypes.string,
  onKeyDown: PropTypes.func,
  handleEnterPress: PropTypes.func,
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
  darkMode: PropTypes.bool,
};

TextInput.defaultProps = {
  onChange: null,
  onFocus: null,
  onBlur: null,
  value: '',
  placeholder: '',
  thin: false,
  error: false,
  type: 'text',
  className: '',
  darkMode: false,
  onKeyDown: () => {},
  handleEnterPress: () => {},
};

export const TextArea = (props) => {
  const {
    value,
    placeholder,
    className,
    error,
    rows,
    onKeyDown,
    style,
    updateOn,
    onChange,
    onFocus,
    onBlur,
  } = props;

  const [localValue, setLocalValue] = useState(value);

  const handleChange = (e) => {
    setLocalValue(e.target.value);
    if (updateOn === 'input') {
      onChange(e);
    }
  };

  const handleOnFocus = (e) => {
    onFocus(e);
  };

  const handleOnBlur = (e) => {
    if (updateOn === 'blur') {
      onChange(e);
    }

    onBlur(e);
  };

  return (
    <StyledTextArea
      style={style}
      rows={rows}
      className={className}
      onChange={handleChange}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      value={localValue}
      placeholder={placeholder}
      error={error}
      onKeyDown={onKeyDown}
    />
  );
};

const StyledTextArea = styled.textarea`
  font-size: 16px;
  font-weight: 400;
  color: ${colors['light-grey-20']};

  display: block;

  padding: 10px;

  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: none;
  border-bottom: 2px solid ${colors['light-grey-50']};

  background: ${fade('black', 95)};
  box-shadow: none;

  transition: all 0.2s ease-in-out;

  :after {
    display: absolute;
    content: '';
    border-bottom: solid 3px #019fb6;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  :hover:after {
    transform: scaleX(1);
  }
  /* h1.fromRight:after{ transform-origin:100% 50%; }
  h1.fromLeft:after{  transform-origin:  0% 50%; } */

  ::placeholder {
    /* Chrome/Opera/Safari */
    color: ${colors['light-grey-30']};
  }

  :focus {
    outline: none;
    border-bottom: 2px solid ${colors.blue};
    /* background: ${lighten(colors.blue, 47)} */

    color: ${colors.grey};
  }

  ${(props) =>
    props.thin &&
    css`
      padding: 5px;
    `}

  ${(props) =>
    props.error &&
    css`
      background: ${colors['light-red-40']};
      border-color: ${colors['light-red-20']};
      color: ${colors['light-red-10']};

      :focus {
        color: ${colors.red};
        border-color: ${colors.red};
      }

      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: ${colors['light-red-20']};
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        color: ${colors['light-red-20']};
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        color: ${colors['light-red-20']};
      }
      :-moz-placeholder {
        /* Firefox 18- */
        color: ${colors['light-red-20']};
      }
    `}
`;

TextArea.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  className: PropTypes.string,
  onKeyDown: PropTypes.func,
  rows: PropTypes.number,
  style: PropTypes.shape({}),
  updateOn: PropTypes.string,
};

TextArea.defaultProps = {
  onBlur: () => {},
  onFocus: () => {},
  onChange: null,
  value: '',
  placeholder: '',
  error: false,
  className: '',
  onKeyDown: null,
  rows: null,
  style: {},
  updateOn: 'input',
};
