import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useContext } from 'react';

import fetchUtil from '../../util/fetchUtil';

import { Store } from '../../state/store';
import { fetchActiveProject } from '../../state/projects/actions';

import rolesData from '../../shared/roles';

import TrackingTask from './TrackingTask';

import {
  HeaderSection,
  SectionTitle,
  Icon,
} from '../views/ProjectContentsStyles';

import InputConfirm from '../InputConfirm';
import Button from '../Button';

import { colors, lighten } from '../styles/colors';

function TrackingSection() {
  const {
    state: { activeProject, team, user },
    dispatch,
  } = useContext(Store);
  const { tracking } = activeProject;

  const [wordCountValue, setWordCountValue] = useState('');
  const [editingWordCount, setEditingWordCount] = useState(false);

  let wordCount;
  if (tracking && tracking.wordCount) {
    ({ wordCount } = tracking);
  }

  const handleInputChange = (e) => {
    const { value } = e.target;
    setWordCountValue(parseInt(value, 10) || '');
  };

  const handleUpdateWordCount = () => {
    fetchUtil
      .post('/tracking/updateWordCount', {
        projectID: activeProject.projectID,
        wordCount: parseInt(wordCountValue, 10),
      })
      .then(() => fetchActiveProject(activeProject.projectID, dispatch))
      .then(() => setEditingWordCount(false));
  };

  const taskComponents = rolesData.map((task) => (
    <TrackingTask
      key={task.taskID}
      task={task}
      team={team}
      user={user}
      projectID={activeProject.projectID}
      tracking={tracking}
    />
  ));

  return (
    <Container>
      <HeaderSection>
        <SectionTitle>
          <Icon icon={['fad', 'analytics']} />
          Tracking
        </SectionTitle>
      </HeaderSection>
      {(editingWordCount || !wordCount) && (
        <Subsection>
          <InputConfirm
            handleInputChange={handleInputChange}
            handleSaveClick={handleUpdateWordCount}
            handleCancelClick={() => setEditingWordCount(!editingWordCount)}
            inputValue={wordCountValue}
            placeholder="Enter word count"
            enableSave={!!wordCountValue}
            cancelButtonVisible={!!wordCount}
          />
        </Subsection>
      )}
      {!editingWordCount && wordCount && (
        <Subsection>
          <WordsContainer>
            <Header>Words:&nbsp;</Header>
            <TextContainer>
              <WordCount>{wordCount}</WordCount>
            </TextContainer>
            <StyledButton
              transparent
              thin
              icon
              onClick={() => setEditingWordCount(true)}
            >
              <FontAwesomeIcon icon={['fad', 'edit']} />
            </StyledButton>
          </WordsContainer>
        </Subsection>
      )}
      <TasksContainer>{taskComponents}</TasksContainer>
    </Container>
  );
}

const Container = styled.div``;
const Subsection = styled.div`
  margin-top: 5px;
`;

const Header = styled.p`
  margin: 0;
  color: ${colors['light-grey-20']};
`;

const TextContainer = styled.div`
  padding: 2px 5px;
  background: ${lighten(colors.grey, 50)};
  border-radius: 3px;
  color: ${colors['light-grey-10']};

  display: flex;
`;

const WordCount = styled.h4`
  margin: 0;
`;

const TasksContainer = styled.div``;
const WordsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

TrackingSection.propTypes = {
  activeProject: PropTypes.shape({
    members: PropTypes.array,
    projectID: PropTypes.number,
  }),
  team: PropTypes.array,
};

TrackingSection.defaultProps = {
  activeProject: {},
  team: [],
};

export default TrackingSection;
