import React, { useContext, useEffect, useState } from 'react';

import { Route } from 'react-router';
import { post } from '../../util/fetchUtil';

import { Store } from '../../state/store';
import { fetchTeamData, fetchUserData } from '../../state/user/actions';
import { fetchProjectGroups } from '../../state/projects/actions';

import AdminNavigation from './AdminNavigation';
import AdminContent from './AdminContent';
import AdminProjectGroups from './AdminProjectGroups';
import AdminPermissions from './AdminPermissions';
import AdminErrors from './AdminErrors';
import AdminProjects from './AdminProjects';
import AdminAnalytics from './AdminAnalytics';
import AdminUsers from './AdminUsers';
import AdminResources from './AdminResources';
import AdminTasks from './AdminTasks';
import AdminReports from './AdminReports';
import AdminFiles from './AdminFiles';
import AdminExports from './AdminExports';
import AdminStats from './AdminStats';
import AdminLabels from './AdminLabels';
import AdminFeedbackForms from './AdminFeedbackForms';
import AdminSearch from './AdminSearch';

import { Container } from './AdminStyles';
import { isAllowed } from '../../shared/grants';

// const incompleteComponent = (
//   <ConstructionMessage>👷 Under construction 👷</ConstructionMessage>
// );

const routes = [
  {
    name: 'Permissions',
    value: 'admin.permissions',
    id: 'permissions',
    icon: 'fad fa-lock',
    component: <AdminPermissions />,
  },
  {
    name: 'Stats',
    value: 'admin.stats',
    id: 'stats',
    icon: 'fad fa-chart-bar',
    component: <AdminStats />,
  },
  {
    name: 'Projects',
    value: 'admin.projects',
    id: 'projects',
    icon: 'fad fa-image',
    component: <AdminProjects />,
  },
  {
    name: 'Labels',
    value: 'admin.labels',
    id: 'labels',
    icon: 'fad fa-tag',
    component: <AdminLabels />,
  },
  {
    name: 'Tasks',
    value: 'admin.tasks',
    id: 'tasks',
    icon: 'fad fa-tasks',
    component: <AdminTasks />,
  },
  {
    name: 'Folders',
    value: 'admin.folders',
    id: 'folders',
    icon: 'fad fa-folders',
    component: <AdminProjectGroups />,
  },
  {
    name: 'Files',
    value: 'admin.assets',
    id: 'assets',
    icon: 'fad fa-file',
    component: <AdminFiles />,
  },
  {
    name: 'File Exports',
    value: 'admin.exports',
    id: 'exports',
    icon: 'fad fa-file-export',
    component: <AdminExports />,
  },
  {
    name: 'Analytics',
    value: 'admin.analytics',
    id: 'analytics',
    icon: 'fad fa-chart-bar',
    component: <AdminAnalytics />,
  },
  {
    name: 'Users',
    value: 'admin.users',
    id: 'users',
    icon: 'fad fa-users',
    component: <AdminUsers />,
  },
  {
    name: 'Search',
    value: 'admin.search',
    id: 'search',
    icon: 'fad fa-search',
    component: <AdminSearch />,
  },
  {
    name: 'Resources',
    value: 'admin.resources',
    id: 'resources',
    icon: 'fad fa-x-ray',
    component: <AdminResources />,
  },
  {
    name: 'Reports',
    value: 'admin.reports',
    id: 'reports',
    icon: 'fad fa-file-chart-line',
    component: <AdminReports />,
  },
  {
    name: 'Feedback',
    value: 'admin.feedback',
    id: 'feedback',
    icon: 'fad fa-comment',
    component: <AdminFeedbackForms />,
  },
  {
    name: 'Errors',
    value: 'admin.errors',
    id: 'errors',
    icon: 'fad fa-bug',
    component: <AdminErrors />,
  },
];

const Admin = () => {
  const { dispatch } = useContext(Store);
  const [availablePermissions, setAvailablePermissions] = useState([]);

  const {
    state: { user },
  } = useContext(Store);

  const getGrants = async () => {
    const res = await post('/admin/getUserGrants');

    setAvailablePermissions(res.grants);
    window.grants = res.grants;
  };

  useEffect(() => {
    getGrants();
    // Update active route on back / forward navigation

    fetchTeamData(dispatch);
    fetchUserData(dispatch);
    fetchProjectGroups(dispatch);
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      {user && (
        <>
          <AdminNavigation routes={routes} grants={user.grants} />
          {routes.map((r) => (
            <Route key={r.id} path={`/admin/${r.id}`}>
              <AdminContent
                availablePermissions={availablePermissions}
                route={r}
                isAllowed={isAllowed(r.value, availablePermissions)}
              />
            </Route>
          ))}
        </>
      )}
    </Container>
  );
};

Admin.propTypes = {};

Admin.defaultProps = {};

export default Admin;
