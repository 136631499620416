import { useEffect } from 'react';

import { isInputActive } from '../keydown';

import modifiersMap from '../keyModifierMap';

const modifiers = ['shiftKey', 'ctrlKey', 'altKey', 'metaKey'];

const keyPressed = (name: string, e: KeyboardEvent) =>
  name.toLowerCase() === e.key.toLowerCase() ||
  name.toLowerCase() === e.code.toLowerCase();

type Key = {
  name?: string;
  names?: string[];
  modifiers: string[];
  onPress: (e: KeyboardEvent) => void;
};

type Options = {
  allowInputsActive?: boolean;
};

/**
 *
 * @param {[Object]} keys
 * {
 *  name: {String}, // Name of the key, from `event.key`
 *  onPress: {Func}, // Function to call when the key is pressed
 *  modifier: {[String]}, // Optional modifier - must match one of the keys in the `modiferMap`.
 * }
 */
export default (keys: Key[], deps = [], options: Options = {}) => {
  const { allowInputsActive = false } = options;
  const handleKeydown = (e: KeyboardEvent) => {
    // Don't activate hotkeys when inputs are active
    if (!isInputActive() || allowInputsActive) {
      // Find the keys that were pressed (can have multiple of the same with different modifiers)
      const keysPressed = keys.filter((k) =>
        k.names // Handle an array of names
          ? k.names.some((name) => keyPressed(name, e))
          : keyPressed(k.name || '', e)
      );

      keysPressed.forEach((key: Key) => {
        if (key) {
          if (key.modifiers) {
            // Make sure all the passed modifiers are the same as the event's modifiers
            const modifiersMet = modifiers.every(
              (modifier): boolean =>
                // @ts-ignore // TODO: FIX TYPING
                e[modifier] ===
                !!key.modifiers.find(
                  // @ts-ignore // TODO: FIX TYPING
                  (m) => modifiersMap[m.toLowerCase()] === modifier
                )
            );
            if (!modifiersMet) return;
          }

          key.onPress(e);
        }
      });
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
    // eslint-disable-next-line
  }, [keys, ...deps]);
};
