import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from './styles/colors';

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: ${(props) => `${props.fontSize}px`};
  color: ${(props) => props.color};
`;

const Icon = (props) => {
  const { className, style, fontSize, icon, prefix } = props;

  return (
    <StyledIcon
      icon={[prefix, icon]}
      className={className}
      fontSize={fontSize}
      style={style}
    />
  );
};

Icon.propTypes = {
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  prefix: PropTypes.string,
};

Icon.defaultProps = {
  fontSize: 16,
  icon: null,
  color: colors.grey,
  className: '',
  style: {},
  prefix: 'fad',
};

export default Icon;
