import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Link } from 'react-router-dom';

import { motion } from 'framer-motion';

import fetchUtil from '../../util/fetchUtil';

import { colors, fade } from '../styles/colors';
import Box, { Flex } from '../Box';
import InputConfirm from '../InputConfirm';
import { P } from '../styles/typography';

import SceneProps from '../editor/props/SceneProps';

const CrumbLink = styled(Link)`
  cursor: pointer;
  color: ${fade('white', 70)};

  :hover {
    color: ${fade('white', 60)};
  }
`;

/**
 * Nav header displayed when working on a scene
 */
function EditorNavigationSceneHeader(props) {
  const {
    scene,
    setActiveScene,
    fetchActiveScene,
    isSaving,
    projectID,
  } = props;

  const [newSceneName, setNewSceneName] = useState(scene.data.name);
  const [isEditingName, setIsEditingName] = useState(false);

  const handleSaveNewName = async () => {
    setIsEditingName(false);

    if (newSceneName) {
      const res = await fetchUtil.post('/scene/updateName', {
        sceneID: scene.sceneID,
        name: newSceneName,
      });

      setNewSceneName(res.scene.data.name);

      const sceneRes = await fetchActiveScene();
      setActiveScene(sceneRes.scene);
    }
  };

  return (
    <ViewContainer
      style={{
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <CrumbLink to={`/project/${projectID}/editor/dashboard`}>
        <FontAwesomeIcon icon={['fas', 'arrow-left']} />
        &nbsp;Scenes
      </CrumbLink>
      <Flex>
        {!isEditingName && (
          <P
            style={{ color: 'white', opacity: 0.5 }}
            onClick={() => setIsEditingName(true)}
          >
            {newSceneName}
          </P>
        )}
        {isEditingName && (
          <InputConfirm
            theme="thin"
            handleInputChange={(e) => setNewSceneName(e.target.value)}
            handleSaveClick={handleSaveNewName}
            handleCancelClick={() => setIsEditingName(!isEditingName)}
            inputValue={newSceneName}
            placeholder="Enter scene name..."
            enableSave={!!newSceneName}
            cancelButtonVisible={false}
            saveButtonVisible={false}
            clickOutsideToCancel={true}
            enterToSave={true}
            inputStyle={{ fontSize: 14, padding: '0.25em' }}
          />
        )}
        <motion.div animate={{ opacity: isSaving ? 1 : 0 }}>
          <Box style={{ marginLeft: 10 }}>
            <P>Saving...</P>
          </Box>
        </motion.div>
      </Flex>
      <Box></Box>
    </ViewContainer>
  );
}

const ViewContainer = styled.div`
  height: 30px;
  width: 100%;

  background: ${colors.night};

  display: flex;
  justify-content: space-between;
`;

const { func, bool, string } = PropTypes;

EditorNavigationSceneHeader.propTypes = {
  scene: SceneProps,
  isSaving: bool,
  projectID: string.isRequired,
  setActiveScene: func.isRequired,
  fetchActiveScene: func.isRequired,
};

EditorNavigationSceneHeader.defaultProps = {
  isSaving: false,
};

export default EditorNavigationSceneHeader;
