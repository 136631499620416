import React from 'react';
import styled from 'styled-components/macro';

import fetchUtil from '../../util/fetchUtil';

import fileManager from '../../app/fileManager';

import Button from '../Button';
import { H3, P } from '../styles/typography';

const ReportContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 20px;
`;

const Description = styled(P)`
  margin-top: 10px;
`;

const reports = [
  {
    name: 'Files data',
    description:
      'Downloads all the files data as a csv file to be imported into a local SQL',
    filename: 'files.csv',
    type: 'csv',
    id: 'download-files-data',
    route: 'admin/reports/downloadFilesData',
  },
  {
    name: 'Projects data',
    description:
      'Downloads all the project data as a csv file to be imported into a local SQL',
    filename: 'projects.csv',
    type: 'csv',
    id: 'download-projects-data',
    route: 'admin/reports/downloadProjectsData',
  },
  {
    name: 'Credits data',
    description:
      'Downloads all the project credits data as a csv file to be imported into a local SQL',
    filename: 'credits.csv',
    type: 'csv',
    id: 'download-credits-data',
    route: 'admin/reports/downloadCreditsData',
  },
  {
    name: 'Kit data',
    description:
      'Downloads all the kit data as a csv file to be imported into a local SQL',
    filename: 'kits.csv',
    type: 'csv',
    id: 'download-kits-data',
    route: 'admin/reports/downloadKitsData',
  },
  {
    name: 'Thumbnails',
    description: 'Downloads all video thumbnails data as a zip file',
    filename: 'files.zip',
    type: 'zip',
    id: 'download-thumbnails',
    route: 'admin/reports/downloadThumbnails',
  },
  {
    name: 'Audit',
    description: 'Run an audit on projects and project files.',
    filename: 'audit.csv',
    type: 'csv',
    id: 'download-audit',
    route: 'admin/reports/downloadAudit',
  },
];

function AdminReports() {
  const runReport = async (report) => {
    const res = await fetchUtil.post(`/${report.route}`);

    if (res && report.type === 'csv') {
      const csvs = [];
      if (Array.isArray(res.csv)) {
        csvs.push(...res.csv);
      } else {
        csvs.push(res.csv);
      }

      csvs.forEach((csv) => {
        const blob = new Blob([csv], {
          type: 'text/plain',
        });

        fileManager.downloadFile(blob, report.filename);
      });
    }

    if (res && report.type === 'zip') {
      await fileManager.downloadZipFile(res.files);
    }
  };

  const reportComponents = reports.map((report) => (
    <ReportContainer key={report.id}>
      <div>
        <H3>{report.name}</H3>
        <Description>{report.description}</Description>
      </div>
      <div>
        <Button onClick={() => runReport(report)}>Download</Button>
      </div>
    </ReportContainer>
  ));

  return <Container>{reportComponents}</Container>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
`;

AdminReports.propTypes = {};

AdminReports.defaultProps = {};

export default AdminReports;
