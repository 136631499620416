import React from 'react';
import { memo } from 'react';

import TimelineRow from './TimelineRow';

import ElementProps from '../props/ElementProps';

const shouldMemo = (prevProps, nextProps) => {
  if (
    nextProps.element.type === 'group' ||
    nextProps.isSelected !== prevProps.isSelected ||
    nextProps.elementsToUpdate.current.find(
      (id) => id === nextProps.element.id
    ) ||
    nextProps.duration !== prevProps.duration ||
    nextProps.selectedKeyframes !== prevProps.selectedKeyframes ||
    nextProps.pixelsPerMs !== prevProps.pixelsPerMs
  )
    return false;
  return true;
};

/**
 * Wrapper for timeline rows, allows for recursive grouping
 */
const TimelineRowWrapper = memo((props) => {
  const { element } = props;

  const isGroup = element.type === 'group';
  const hasMask = !!element.maskElements.length;

  return (
    <>
      <TimelineRow {...props} />
      {hasMask &&
        element.maskElements.map((el) => (
          <TimelineRowWrapper {...props} key={el.id} element={el} />
        ))}
      {isGroup &&
        element.elements.map((el) => (
          <TimelineRowWrapper {...props} key={el.id} element={el} />
        ))}
    </>
  );
}, shouldMemo);

TimelineRowWrapper.displayName = 'TimelineRowWrapper';

TimelineRowWrapper.propTypes = {
  element: ElementProps,
};

export default TimelineRowWrapper;
