import Searchkit, { SearchSettingsConfig } from 'searchkit';
import Client from '@searchkit/instantsearch-client';
import { SearchIndex } from '../../types/search';
import searchConfig from '../data/searchConfig';

/**
 * Get the search client
 * @param indexName
 * @returns
 */
export default function useSearchClient(indexName: SearchIndex) {
  const sk = new Searchkit({
    connection: {
      host: '/search',
      auth: {
        username: process.env.REACT_APP_ES_USERNAME ?? 'elastic',
        password: process.env.REACT_APP_ES_PASSWORD ?? '',
      },
    },
    search_settings: {
      // Typecasting here because the types from searchkit seem wrong
      ...(searchConfig[indexName] as SearchSettingsConfig),
    },
  });

  const searchClient = Client(sk, {
    getQuery: (query: string, searchAttributes) => {
      return [
        {
          multi_match: {
            query,
            fields: searchAttributes,
            operator: 'and',
            type: 'cross_fields',
          },
        },
      ];
    },
  });
  return { searchClient, indexName };
}
