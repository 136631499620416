import SvgElement from './SvgElement';

class ImageElement extends SvgElement {
  constructor(args = {}) {
    const { src, fileID } = args;

    super({
      ...args,
      type: 'image',
    });

    this.src = src;
    this.fileID = fileID; // The database file ID
  }
}

export default ImageElement;
