import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import hotkeyManager from '../../../app/editor/editorHotkeys';
import { mirrorCp } from '../../../app/editor/path';

import Point from './Point';
import DraggableSvg from '../../DraggableSvg';
import DraggableControlPoint from './DraggableControlPoint';

import { colors } from '../../styles/colors';

import { DomRefProps } from '../../../props/general';

const StyledPath = styled.path`
  stroke: ${colors.blue};
  stroke-width: 1px;
  fill: none;
`;

/**
 * Renders the path nodes and allows the user to interact with the nodes as
 * well as the node's control points.
 */
function NodePathEditor(props) {
  const { element, svgRef, update } = props;
  const { parsedPath } = element;

  // Array of selected nodes
  // TODO: Allow for selecting multiple at once (with shift + select)
  const [selectedNodes, setSelectedNodes] = useState([]);

  const [isDraggingNode, setIsDraggingNode] = useState(false);

  hotkeyManager.register({
    DELETE_PATH_NODE: {
      names: ['backspace', 'delete'],
      modifiers: [],
      priority: 1,
      onPress(e) {
        if (selectedNodes.length) {
          e.preventDefault();
          e.stopPropagation();

          element.removeNodes(selectedNodes);

          update({});

          return true;
        }

        return false;
      },
    },
  });

  const handleClickNode = (e, commandIndex) => {
    e.stopPropagation();

    setSelectedNodes([commandIndex]);
  };

  const handleDragNode = (move, nodeIndex) => {
    if (selectedNodes.some((index) => index === nodeIndex)) {
      element.translateNode(nodeIndex, move.dx, move.dy);
      update({});
    }
  };

  /**
   * Drag a control point of an active node
   *
   * @param {Event} e
   * @param {Number} nodeIndex - the index of the active node
   * @param {String} handleName - Name of the handle, options are:
   * cp1 - first control point of the selected node
   * cp2 - second control point of the selected node
   * ncp1 - first control point of the next node
   * ncp2 - second control point of the next node
   */
  const handleDragControlPoint = (e, nodeIndex, handleName) => {
    e.stopPropagation();

    let newNodeIndex = nodeIndex;
    let mirror = true;

    if (handleName === 'cp1') newNodeIndex = nodeIndex - 1;
    if (handleName === 'ncp2') newNodeIndex = nodeIndex + 1;
    if (handleName === 'cp2' || handleName === 'ncp2') mirror = false;

    element.translateControlPoint(svgRef.current, e, newNodeIndex, mirror);

    update({});
  };

  const handleClickCanvas = () => {
    setSelectedNodes([]);
  };

  const handleMouseUp = (e) => {
    if (isDraggingNode) e.stopPropagation();
    setIsDraggingNode(false);
  };

  useEffect(() => {
    const svg = svgRef.current;
    if (svg) {
      svg.addEventListener('click', handleClickCanvas);
      svg.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      if (svg) {
        svg.removeEventListener('click', handleClickCanvas);
        svg.removeEventListener('mouseup', handleMouseUp);
      }
    };
    // eslint-disable-next-line
  }, [svgRef.current, isDraggingNode]);

  const controlPoints = selectedNodes.map((nodeIndex) => {
    const command = parsedPath[nodeIndex];
    // Draw control points for last two points
    if (command.code === 'C') {
      const mirroredCp2 = mirrorCp(
        command.cp2.x,
        command.cp2.y,
        command.end.x,
        command.end.y
      );
      return (
        <g key={nodeIndex}>
          <DraggableControlPoint
            onDrag={(move, e) => handleDragControlPoint(e, nodeIndex, 'cp1')}
            svgRef={svgRef}
            cpx={command.cp1.x}
            cpy={command.cp1.y}
            x={parsedPath[nodeIndex - 1].end.x}
            y={parsedPath[nodeIndex - 1].end.y}
          />
          <DraggableControlPoint
            onDrag={(move, e) => handleDragControlPoint(e, nodeIndex, 'cp2')}
            svgRef={svgRef}
            cpx={command.cp2.x}
            cpy={command.cp2.y}
            x={command.end.x}
            y={command.end.y}
          />
          <DraggableControlPoint
            onDrag={(move, e) => handleDragControlPoint(e, nodeIndex, 'ncp1')}
            svgRef={svgRef}
            cpx={mirroredCp2.x}
            cpy={mirroredCp2.y}
            x={command.end.x}
            y={command.end.y}
          />
          {parsedPath[nodeIndex + 1] && (
            <DraggableControlPoint
              onDrag={(move, e) => handleDragControlPoint(e, nodeIndex, 'ncp2')}
              svgRef={svgRef}
              cpx={parsedPath[nodeIndex + 1].cp2.x}
              cpy={parsedPath[nodeIndex + 1].cp2.y}
              x={parsedPath[nodeIndex + 1].end.x}
              y={parsedPath[nodeIndex + 1].end.y}
            />
          )}
        </g>
      );
    }
    if (command.code === 'M' && parsedPath.length > 1) {
      return (
        <DraggableControlPoint
          onDrag={(move, e) => handleDragControlPoint(e, nodeIndex + 1, 'cp1')}
          svgRef={svgRef}
          cpx={parsedPath[nodeIndex + 1].cp1.x}
          cpy={parsedPath[nodeIndex + 1].cp1.y}
          x={command.end.x}
          y={command.end.y}
        />
      );
    }

    return null;
  });

  const points = parsedPath
    .filter((command) => command.end)
    .map((command, i) => {
      return (
        <DraggableSvg
          key={i}
          onClick={(e) => handleClickNode(e, i)}
          onMouseMove={(move) => handleDragNode(move, i)}
          svgRef={svgRef}
        >
          <Point
            isActive={selectedNodes.some((index) => index === i)}
            x={command.end.x}
            y={command.end.y}
          />
        </DraggableSvg>
      );
    });

  return (
    <g>
      <g>
        <StyledPath d={element.currentProps.d} />
      </g>
      <g>{controlPoints}</g>
      <g>{points}</g>
    </g>
  );
}

NodePathEditor.propTypes = {
  element: PropTypes.any,
  update: PropTypes.func,
  svgRef: DomRefProps,
};

export default NodePathEditor;
