import styled from 'styled-components/macro';

import React, { useState } from 'react';
import Text from '../Text';

import { post } from '../../util/fetchUtil';

import Button from '../RebassButton';
import { H2 } from '../styles/typography';
import Box, { Flex } from '../Box';
import Grant, { GrantProps } from '../admin/Grant';

import { grants, parse } from '../../shared/grants';

type Props = {
  userName: string;
  userID: number;
  permissions: string[];
  handleToggleDialog: () => void;
  onSave: () => void;
};

const levels = ['edit', 'view', undefined] as const;
export type LevelProps = typeof levels[number];

const isLevel = (level: string | undefined): level is LevelProps =>
  levels.includes(level as LevelProps);

function Permissions(props: Props) {
  const { userName, userID, handleToggleDialog, onSave, permissions } = props;

  const availableOptions = grants.map(
    (grant) =>
      ({
        name: grant.name,
        description: grant.description,
        value: grant.name,
        checked: false,
        level: undefined,
      } as GrantProps)
  );

  permissions.forEach((savedGrant) => {
    const parsedGrant = parse(savedGrant);
    const op = availableOptions.find(
      (option: GrantProps) => option.name === parsedGrant.name
    );
    if (op) {
      op.checked = true;
      if (isLevel(parsedGrant.level)) op.level = parsedGrant.level;
    }
  });

  const [options, setOptions] = useState<GrantProps[]>(availableOptions);

  const handleCheckboxChange = (
    grant: GrantProps,
    isChecked: boolean,
    level: LevelProps
  ) => {
    const newOptions = Array.from(options);
    const index = newOptions.findIndex((g) => g.name === grant.name);

    newOptions[index].checked = isChecked;
    newOptions[index].level = level;

    setOptions(newOptions);
  };

  const handleSavePermissions = () => {
    const promises = [];

    // Gather any permissions that need to be revoked
    const toRevoke: string[] = [];
    permissions.forEach((permission) => {
      const grant = parse(permission);
      if (!options.find((option) => option.name === grant.name)?.checked) {
        toRevoke.push(grant.full);
      }
    });

    // Gather any permissions that need to be added
    const toAdd = options
      .filter((option) => option.checked)
      .map((option) =>
        option.level ? `${option.name}-${option.level}` : option.name
      );

    // Add grants
    if (toAdd.length) {
      promises.push(
        post('/admin/addGrants', {
          userID,
          grants: toAdd,
        })
      );
    }

    // Revoke grants
    if (toRevoke.length) {
      promises.push(
        post('/admin/revokeGrants', {
          userID,
          grants: toRevoke,
        })
      );
    }

    // Call onSave after all have finished
    Promise.all(promises).then(() => onSave());
  };

  const checkboxes = options
    .filter((o) => o.name.split('.')[0] !== 'admin')
    .map((option) => (
      <Grant
        key={option.value}
        option={option}
        handleCheckboxChange={handleCheckboxChange}
      />
    ));

  const adminCheckboxes = options
    .filter((o) => o.name.split('.')[0] === 'admin')
    .map((o) => (
      <Grant
        key={o.value}
        option={o}
        handleCheckboxChange={handleCheckboxChange}
      />
    ));

  return (
    <Flex flexDirection="column">
      <Header>Permissions for {userName}</Header>
      <Box mb={20}>
        <Text as="h3" mb={10}>
          Content
        </Text>
        <Box maxHeight="30vh" overflow="auto" p={10} bg="light-1">
          {checkboxes}
        </Box>
      </Box>
      <Text as="h3" mb={10}>
        Admin
      </Text>
      <Box maxHeight="40vh" overflow="auto" p={10} bg="light-1">
        {adminCheckboxes}
      </Box>
      <ButtonsContainer>
        <Button bg="positive" onClick={handleSavePermissions}>
          Save
        </Button>
        <Button variant="outline" color="dark-6" onClick={handleToggleDialog}>
          Cancel
        </Button>
      </ButtonsContainer>
    </Flex>
  );
}

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 15px;
  > * {
    margin-right: 10px;
  }
`;

const Header = styled(H2)`
  margin-bottom: 15px;
`;

Permissions.defaultProps = {
  permissions: [],
  userName: '',
};

export default Permissions;
