const UNDO_HISTORY_MAX = 20;

/**
 * A class to keep track of the undo/redo state
 *
 * @class UndoStack
 */
class UndoStack {
  constructor() {
    this.index = -1;
    this.stack = [];
  }

  static getUndoHistoryMax() {
    return UNDO_HISTORY_MAX;
  }

  /**
   * Add the state to the stack
   *
   * @param {Object} state
   * @memberof UndoStack
   */
  add(state) {
    if (this.index < this.stack.length - 1) {
      // Remove all states after the current index
      this.stack.length = this.index + 1;
    }

    // Remove front of array if we're at the max
    if (this.stack.length === UNDO_HISTORY_MAX) {
      this.stack.shift();
      this.index -= 1;
    }

    this.stack.push(state);
    this.index += 1;
  }

  get() {
    return this.stack;
  }

  undo() {
    if (this.index > 0) {
      this.index -= 1;
      const previousState = this.stack[this.index];

      return previousState;
    }

    return null;
  }

  redo() {
    if (this.stack[this.index + 1]) {
      this.index += 1;
      const futureState = this.stack[this.index];

      return futureState;
    }

    return null;
  }
}

// Make it a singleton
export default UndoStack;
