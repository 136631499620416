import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components/macro';

import React, { useState, useRef, CSSProperties } from 'react';

import { colors } from '../../styles/colors';
import { getFilesFromEvent } from '../../../app/fileManager';

import FolderName from './FolderName';
import { DirectorySettingsProps } from './FileExplorer';

import { getTotalFiles } from '../../../app/assetDirs';

import Box from '../../Box';
import Icon from '../../Icon';

import {
  DirnameContainer,
  FolderButton,
  FolderContainer,
  FolderContents,
  RemoveFileButton,
  FileContainer,
  FilesContainer,
} from './FileExplorerStyles';
import sortFiles from '../../../utilities/sortFiles';
import { KitDirectoryProps } from '../../../types/KitDirectoryProps';
import FileProps from '../../editor/types/FileProps';
import { FileObjectProps } from '../../../types/Browser';

const ChevronIcon = styled(FontAwesomeIcon)`
  ${(props: { open: boolean }) =>
    props.open &&
    css`
      transform: rotate(180deg);
    `}
`;

type Props = {
  dir: KitDirectoryProps;
  directories: KitDirectoryProps[];
  directorySettings: DirectorySettingsProps[];
  selectedDir: KitDirectoryProps;
  selectedFiles: FileProps[];
  setDirectories: (dirs: KitDirectoryProps[]) => void;
  selectDir: (dir?: KitDirectoryProps) => void;
  selectFile: (
    e: React.MouseEvent,
    file: FileProps,
    dir: KitDirectoryProps
  ) => void;
  uploadToKit: (files: FileObjectProps[]) => Promise<FileProps[]>;
  handleUpdate: () => Promise<void>;
  onToggleDir: (dir: KitDirectoryProps) => void;
  handleCreateFolder: (name: string, dirID: number) => void;
  handleChangeFolderName: (dir: KitDirectoryProps, newName: string) => void;
  handleRemoveFile: (dir: KitDirectoryProps, file: FileProps) => void;
  handleAddFiles: (dir: KitDirectoryProps, files: FileProps[]) => void;
  handleDeleteDirectory: (e: React.MouseEvent, dir: KitDirectoryProps) => void;
  editable?: boolean;
  style?: CSSProperties;
  className?: string;
};

/**
 * A directory component that recursively adds itself depending on how many dirs there are
 */
function Folder(props: Props) {
  const {
    dir,
    directories,
    directorySettings,
    setDirectories,
    selectDir,
    selectedDir,
    selectFile,
    selectedFiles,
    uploadToKit,
    editable,
    handleUpdate,
    onToggleDir,

    handleCreateFolder,
    handleChangeFolderName,
    handleRemoveFile,
    handleAddFiles,
    handleDeleteDirectory,

    style,
    className,
  } = props;

  const [draggingOver, setDraggingOver] = useState(false);
  const dragCounter = useRef(0);

  const currentFiles = dir.files || [];

  const handleToggleOpen = () => {
    // If no directory is selected or this is not the current selected directory,
    // make this directory active
    if (
      !selectedDir ||
      (selectedDir && selectedDir.directoryID !== dir.directoryID)
    ) {
      selectDir(dir);
    } else {
      // Otherwise we're clicking the same directory - close it and make it inactive
      selectDir(undefined);
    }

    onToggleDir(dir);
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();

    setDraggingOver(false);

    let newFiles = [];

    // Handle dropped files that need to be uploaded
    const localFiles = getFilesFromEvent(e);

    if (localFiles && localFiles.length) {
      // Upload files
      newFiles = await uploadToKit(localFiles);
    } else {
      newFiles = selectedFiles;
    }

    // Add the files
    handleAddFiles(dir, newFiles);

    // Reload assets view
    handleUpdate();
  };

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    dragCounter.current += 1;
    if (!draggingOver) setDraggingOver(true);
  };

  const handleDragOver = (e: React.DragEvent) => e.preventDefault();

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    dragCounter.current -= 1;
    if (dragCounter.current === 0) setDraggingOver(false);
  };

  const handleToggleFolder = (e: React.MouseEvent) => {
    e.stopPropagation();

    onToggleDir(dir);
  };

  const handleDragStart = (e: React.DragEvent, file: FileProps) => {
    e.stopPropagation();
    const isSelected = !!selectedFiles.find((f) => f.fileID === file.fileID);
    if (!isSelected) {
      selectFile(e, file, dir);
    }
  };

  const totalFiles = getTotalFiles([dir], dir.directoryID);

  const isActive = selectedDir && selectedDir.directoryID === dir.directoryID;

  const settings = directorySettings.find(
    (d) => d.directoryID === dir.directoryID
  );

  const isOpen = settings && settings.open;

  const sortAlphabetical = sortFiles.find((s) => s.id === 'alphabetical');

  return (
    <FolderContainer className={className} style={style}>
      <DirnameContainer
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={handleToggleOpen}
        active={draggingOver}
      >
        <Box>
          <Icon
            icon={isOpen ? 'folder-open' : 'folder'}
            style={{ marginRight: 10, color: isActive ? colors.blue : '' }}
          />
          <FolderName
            name={dir.name}
            onSave={(newName) => handleChangeFolderName(dir, newName)}
            active={isActive}
            editable={editable}
          />
          <span style={{ marginLeft: 5, color: colors['light-grey-30'] }}>
            ({totalFiles.length})
          </span>
          <FolderButton
            onClick={handleToggleFolder}
            box
            thin
            icon
            color={colors['light-grey-20']}
            style={{ marginLeft: 10 }}
          >
            <ChevronIcon icon={['fas', 'chevron-down']} open={dir.open} />
          </FolderButton>
        </Box>
        {editable && (
          <Box>
            <FolderButton
              box
              thin
              icon
              color={colors['light-grey-20']}
              onClick={(e: React.MouseEvent) => handleDeleteDirectory(e, dir)}
            >
              <FontAwesomeIcon icon={['fas', 'times']} />
            </FolderButton>
          </Box>
        )}
      </DirnameContainer>
      {isOpen && (
        <FolderContents active={isActive}>
          <Box>
            {dir &&
              dir.dirs &&
              dir.dirs.map((item) => (
                <Folder
                  key={item.directoryID}
                  dir={item}
                  directories={directories}
                  setDirectories={setDirectories}
                  directorySettings={directorySettings}
                  handleCreateFolder={handleCreateFolder}
                  handleAddFiles={handleAddFiles}
                  handleRemoveFile={handleRemoveFile}
                  handleChangeFolderName={handleChangeFolderName}
                  selectDir={selectDir}
                  selectedDir={selectedDir}
                  selectedFiles={selectedFiles}
                  selectFile={selectFile}
                  handleDeleteDirectory={handleDeleteDirectory}
                  onToggleDir={onToggleDir}
                  uploadToKit={uploadToKit}
                  style={style}
                  className={className}
                />
              ))}
            {editable && (
              <FolderName
                onSave={(name) => handleCreateFolder(name, dir.directoryID)}
              />
            )}
            <FilesContainer
              active={draggingOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              {currentFiles.sort(sortAlphabetical?.sort).map((file) => (
                <FileContainer
                  key={file.fileID}
                  active={!!selectedFiles.find((f) => f.fileID === file.fileID)}
                  onClick={(e) => selectFile(e, file, dir)}
                  onDragStart={(e) => handleDragStart(e, file)}
                  draggable
                >
                  <Box>
                    <Icon
                      style={{ color: 'inherit', marginRight: 5 }}
                      icon="file"
                    />
                    {file.name}
                  </Box>
                  {editable && (
                    <Box>
                      <RemoveFileButton
                        icon
                        transparent
                        style={{ fontSize: 12 }}
                        color={colors['light-grey-20']}
                        onClick={() => handleRemoveFile(dir, file)}
                      >
                        <Icon
                          style={{ color: 'inherit' }}
                          icon="times"
                          prefix="far"
                        />
                      </RemoveFileButton>
                    </Box>
                  )}
                </FileContainer>
              ))}
            </FilesContainer>
          </Box>
        </FolderContents>
      )}
    </FolderContainer>
  );
}

Folder.defaultProps = {
  handleCreateFolder: () => {},
  handleUpdate: () => {},
  dir: {},
  selectedFiles: [],
  selectedDir: {},
  directories: [],
  editable: true,
  onToggleDir: () => {},
  handleDeleteDirectory: () => {},
  handleRemoveFile: () => {},
  handleAddFiles: () => {},
  handleChangeFolderName: () => {},
  uploadToKit: () => {},
  directorySettings: [],
};

export default Folder;
