import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Store } from '../../state/store';
import { P } from '../styles/typography';
import { colors } from '../styles/colors';

import { getDurationSinceNow } from '../../app/utilities/durations';

import {
  FlexContainer,
  CommentTimestamp,
  CommentRow,
  RightContainer,
} from './ActivityBoxCommentStyles';

import {
  StyledAvatar,
  ActivityText,
  FileText,
  FileName,
  UserName,
} from './ActivityBoxStyles';

const StyledFlexContainer = styled(FlexContainer)`
  align-items: center;
`;

const Timestamp = styled(CommentTimestamp)`
  margin-left: 0;
`;

const ShowAll = styled(P)`
  display: inline-block;
  text-decoration: underline;
  color: ${colors['light-grey-30']};
  cursor: pointer;
  margin: 5px 0;

  :hover,
  :focus {
    color: ${colors['light-grey-20']};
  }
`;

/**
 * Functional component that controls individual comment items in the projectContents view
 */
function ActivityBoxFile(props) {
  const { files, userID } = props;

  const {
    state: { team },
  } = useContext(Store);
  const [showAllFiles, setShowAllFiles] = useState(false);

  const fileNumber = files.length;
  let user = team.find((u) => u.userID === userID);

  // Add Osmosify as automated user
  if (!user) {
    user = {
      fullName: 'Osmosify',
      avatar:
        'https://d16qt3wv6xm098.cloudfront.net/xkla8xRfRGGMsGPE0d2w92aAQnSZyAIz/_.png',
    };
  }

  return (
    <CommentRow>
      <FlexContainer>
        <StyledAvatar src={user.avatar} size={35} />

        <RightContainer>
          <StyledFlexContainer>
            <ActivityText>
              <UserName>{user.fullName.split(' ')[0]}&nbsp;</UserName>
              {fileNumber > 1 && (
                <FileText>uploaded {fileNumber} files&nbsp;</FileText>
              )}
              {fileNumber === 1 && (
                <FileText>
                  uploaded <FileName>{files[0].name}</FileName>
                </FileText>
              )}
            </ActivityText>
          </StyledFlexContainer>
          {fileNumber > 1 && (
            <div>
              <ShowAll onClick={() => setShowAllFiles(!showAllFiles)}>
                {showAllFiles ? 'Hide files' : 'Show files'}
              </ShowAll>
            </div>
          )}
          {showAllFiles && (
            <ul style={{ marginTop: 0 }}>
              {files.map((file) => (
                <li style={{ marginBottom: 5 }} key={file.fileID}>
                  <FileName>{file.name}</FileName>
                </li>
              ))}
            </ul>
          )}
          <Timestamp>{getDurationSinceNow(files[0].createdOn)}</Timestamp>
        </RightContainer>
      </FlexContainer>
    </CommentRow>
  );
}

ActivityBoxFile.propTypes = {
  files: PropTypes.array,
  userID: PropTypes.number,
};

ActivityBoxFile.defaultProps = {
  files: [],
  userID: -1,
};

export default ActivityBoxFile;
