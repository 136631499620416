import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useRef, useEffect } from 'react';

import { post } from '../../util/fetchUtil';

import FileInfoDialog from './FileInfoDialog';
import Dialog from '../Dialog';

import Button from '../RebassButton';
import OptionSelector from '../OptionSelector';
import KitSection from './KitSection';

import {
  Section,
  SectionTitle,
  HeaderSection,
  Icon,
} from '../views/ProjectContentsStyles';

function KitsSection(props) {
  const { activeProject, fetchProject, downloadFile } = props;

  const { projectID } = activeProject;

  const [fileInfoVisible, setFileInfoVisible] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [kits, setKits] = useState([]);
  const [activeKits, setActiveKits] = useState([]);
  const currentProject = useRef();

  const getKitData = async () => {
    const res = await post('/assets/getProjectKits', {
      projectID,
    });

    setActiveKits(res.kits);
  };

  const handleAfterDelete = async () => {
    getKitData();
    fetchProject();
  };

  const getKits = async () => {
    const res = await post('/assets/getKits');

    setKits(res.kits);
  };

  const handleDeleteFile = (fileID, kitID) => {
    post('/assets/removeProjectKitFile', {
      fileID,
      kitID,
      projectID,
    }).then(() => {
      getKitData();
    });
  };

  const handleLinkToKit = (kitID, fileIDs) => {
    post('/assets/linkFilesToKit', {
      fileIDs,
      kitID,
    }).then(() => {
      // Update view
      getKitData();
    });
  };

  const handleSelectKit = (kit) => {
    setActiveKits([
      ...activeKits,
      {
        ...kit,
        files: [],
      },
    ]);
  };

  useEffect(() => {
    // Prevent multiple requests for the same resources
    if (projectID && projectID !== currentProject.current) {
      getKitData();
      currentProject.current = projectID;
    }
    // eslint-disable-next-line
  }, [activeProject]);

  useEffect(() => {
    getKits();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!fileInfoVisible && selectedFiles.length) setFileInfoVisible(true);
    // eslint-disable-next-line
  }, [selectedFiles]);

  const kitComponents = activeKits.map((kit) => (
    <KitSection
      key={kit.kitID}
      kit={kit}
      handleDeleteFile={handleDeleteFile}
      downloadFile={downloadFile}
      handleLinkToKit={handleLinkToKit}
      setSelectedFiles={setSelectedFiles}
      getKitData={getKitData}
    />
  ));

  const kitOptions = kits
    .filter((kit) => !activeKits.find((k) => k.kitID === kit.kitID))
    .map((kit) => ({ ...kit, id: kit.kitID }));

  return (
    <Container>
      <Dialog
        handleHideDialog={() => setFileInfoVisible(false)}
        isVisible={fileInfoVisible}
      >
        {projectID && (
          <FileInfoDialog
            files={selectedFiles}
            projectID={projectID}
            handleHideDialog={() => setFileInfoVisible(false)}
            onDelete={handleAfterDelete}
          />
        )}
      </Dialog>

      <Section>
        <HeaderSection>
          <SectionTitle>
            <Icon icon={['fad', 'images']} />
            Kits
          </SectionTitle>
        </HeaderSection>
        <OptionSelector options={kitOptions} onSelectOption={handleSelectKit}>
          <Button
            startIcon={<FontAwesomeIcon icon={['fas', 'plus']} />}
            variant="outline"
            color="dark-6"
          >
            Link kit
          </Button>
        </OptionSelector>
        {kitComponents}
      </Section>
    </Container>
  );
}

const Container = styled.div``;

const { shape, func, array, number } = PropTypes;

KitsSection.propTypes = {
  activeProject: shape({
    files: array,
    projectID: number,
  }),

  downloadFile: func.isRequired,
  fetchProject: func.isRequired,
};

KitsSection.defaultProps = {};

export default KitsSection;
