import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components/macro';

import { AnimatePresence } from 'framer-motion';

import fetchUtil from '../../util/fetchUtil';

import useClickOutside from '../../app/hooks/useClickOutside';

import { colors } from '../styles/colors';

import TaskSection from '../profile/TaskSection';

import { BellIcon, MotionContainer, BellNotification } from './HeaderBarStyles';

const TaskSectionWrapper = styled.div`
  right: 0;
  top: 30px;

  border-radius: 3px;

  z-index: 1001;

  position: absolute;

  padding: 10px;

  min-width: 450px;

  background-color: ${colors.night};
`;

const Tasks = () => {
  const [tasksVisible, setTasksVisible] = useState(false);
  const [userTasks, setUserTasks] = useState([]);

  const wrapperRef = useRef();

  const getUserTasks = () => {
    fetchUtil.post('/tasks/getUserTasks').then((res) => {
      setUserTasks(res.tasks);
    });
  };

  const toggleTasks = () => setTasksVisible(!tasksVisible);

  useClickOutside(wrapperRef, toggleTasks);

  useEffect(() => {
    getUserTasks();
  }, []);

  const incompleteTasks = userTasks.filter((t) => t.status === 1);

  return (
    <BellIcon
      hasActiveNotifications={!!incompleteTasks.length}
      onClick={toggleTasks}
    >
      <FontAwesomeIcon icon={['fad', 'list']} />
      {!!incompleteTasks.length && (
        <BellNotification style={{ right: 7 }}>
          {incompleteTasks.length}
        </BellNotification>
      )}
      <AnimatePresence>
        {tasksVisible && (
          <MotionContainer
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0, transition: { ease: 'easeOut' } }}
          >
            <TaskSectionWrapper
              ref={wrapperRef}
              onClick={(e) => e.stopPropagation()}
            >
              <TaskSection />
            </TaskSectionWrapper>
          </MotionContainer>
        )}
      </AnimatePresence>
    </BellIcon>
  );
};

export default Tasks;
