import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { colors, lighten } from '../../styles/colors';
import Box from '../../Box';

import Timepoints from './Timepoints';
import TimelineComments from './TimelineComments';

import CommentProps from '../props/CommentProps';

const Playhead = styled.div`
  position: absolute;
  left: ${(props) => props.left};
  width: 2px;
  height: 100%;
  z-index: 1;
  bottom: 0;

  pointer-events: none;

  background-color: ${colors['light-blue-30']};
`;

const Container = styled(Box)`
  width: ${(props) => props.width}px;
  position: relative;
  background-color: ${lighten(colors.night, 5)};
`;

const PlayContainer = styled(Box)`
  position: relative;
  height: ${(props) => (props.layersVisible ? '30px' : '60px')};
`;

/**
 * The playbar displaying the timepoints and the playhead
 */
function TimelinePlayBar(props) {
  const {
    progress,
    setProgress,
    pixelsPerMs,
    duration,
    width,
    onClickComment,
    comments,
    commentsVisible,
    isPlaying,
    layersVisible,
  } = props;

  const containerRef = useRef();

  const handleClick = (e) => {
    setProgress(e.nativeEvent.offsetX / containerRef.current.offsetWidth);
  };

  const handleClickComment = (e, p, comment) => {
    e.stopPropagation();
    setProgress(p);

    onClickComment(comment);
  };

  return (
    <Container width={width} ref={containerRef} onMouseDown={handleClick}>
      {commentsVisible && (
        <TimelineComments
          duration={duration}
          comments={comments}
          handleClickComment={handleClickComment}
          isPlaying={isPlaying}
        />
      )}
      <PlayContainer layersVisible={layersVisible}>
        <Timepoints
          duration={duration}
          pixelsPerMs={pixelsPerMs}
          layersVisible={layersVisible}
        />
        <Playhead left={`${progress * 100}%`} />
      </PlayContainer>
    </Container>
  );
}
const { bool, arrayOf, number, func } = PropTypes;

TimelinePlayBar.propTypes = {
  setProgress: func.isRequired,
  pixelsPerMs: number.isRequired,
  duration: number.isRequired,
  width: number.isRequired,
  progress: number,
  onClickComment: func,
  comments: arrayOf(CommentProps),
  commentsVisible: bool,
  isPlaying: bool,
  layersVisible: bool,
};

TimelinePlayBar.defaultProps = {
  progress: 0,
  onClickComment: () => {},
  commentsVisible: true,
  isPlaying: false,
};

export default TimelinePlayBar;
