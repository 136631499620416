import React, { useContext } from 'react';

import { Store } from '../../state/store';

import AnnotationArea from '../comments/AnnotationArea';
import SceneBorder from './SceneBorder';

import { Container, SceneContainer, SceneBackground } from './SceneStyles';

type Props = {
  isLoading: boolean;
};

const Scene: React.FC<Props> = (props: Props) => {
  const { isLoading } = props;
  const {
    state: { debugBorderIsVisible, sceneDimensions },
  } = useContext(Store);

  return (
    <Container>
      <SceneContainer id="scene">
        <SceneBackground style={{ zIndex: isLoading ? 1 : -1 }}>
          <svg
            viewBox={`0 0 ${sceneDimensions.w} ${sceneDimensions.h}`}
            width="100%"
            height="100%"
          >
            <rect width={sceneDimensions.w} height={sceneDimensions.h} />
          </svg>
        </SceneBackground>
        {debugBorderIsVisible && <SceneBorder />}
        <AnnotationArea />
      </SceneContainer>
    </Container>
  );
};

export default Scene;
