import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import ElementMasks from './ElementMasks';

import Box, { Flex } from '../../Box';
import Button from '../../Button';
import { TextInput } from '../../Input';
import OptionSelector from '../../OptionSelector';
import { P } from '../../styles/typography';

import useClickOutside from '../../../app/hooks/useClickOutside';
import iconMap from '../../../app/editor/elementIconMap';
import group from '../../../app/editor/group';

import { Icon, IconContainer } from './ElementPanelStyles';
import { colors, fade, lighten } from '../../styles/colors';

import ElementProps from '../props/ElementProps';
import { Option, OptionsText } from '../../OptionSelectorStyles';
import editorHotkeys from '../../../app/editor/editorHotkeys';
// const maskTypes = require('app/editor/maskTypes');
// const mask = require('app/editor/elementIconMap');

const ElementText = styled(Box)`
  display: flex;
  align-items: center;
  color: ${fade('white', 75)};
`;

const Name = styled(P)`
  color: ${fade('white', 40)};
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 125px;
  overflow: hidden;
  margin-right: 5px;
`;

const StyledTextInput = styled(TextInput)`
  height: 10px;
  font-size: 14px;
  max-width: 120px;
  margin-right: 10px;
`;

const ElementRowContainer = styled.div`
  /* padding: 5px 10px; */
  background-color: ${colors['light-night-10']};
  margin: 2px;
  border-radius: 3px;
  border: 1px solid transparent;

  cursor: pointer;

  :hover,
  :focus {
    background-color: ${lighten(colors.night, 8)};
  }

  :active {
    background-color: ${lighten(colors.night, 6)};
  }

  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${fade(colors.blue, 65)};
      border: 1px solid ${lighten(colors.blue, 25)};

      :hover,
      :focus,
      :active {
        background-color: ${fade(colors.blue, 65)};
        border: 1px solid ${lighten(colors.blue, 25)};
      }
    `}
`;

const getHotkey = (hotkeyName) => {
  const hotkey = editorHotkeys.getKeyString(hotkeyName);
  return hotkey.toUpperCase();
};

/**
 * A layer row for an element
 */
const ElementRow = (props) => {
  const {
    element,
    // parent,
    isSelected,
    shiftKeyDown,
    cmdKeyDown,
    selectedElements,
    setSelectedElements,
    elements,
    // handleMaskElement,
    handleUpdateElement,
    handleCloneElements,
    handleRemoveElements,
    style,
    className,
    icon,
    ElementRowWrapper, // NOTE: HAVE TO PASS REACT COMPONENT THRU PARENT, OTHERWISE requires DOESN'T WORK
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [newName, setNewName] = useState(element.name || '');
  const inputRef = useRef();

  const isGroup = element.type === 'group';

  const isLocked = !!element.locked;

  const rowRef = useRef();

  // let elementBelow = null;
  // if (parent) {
  //   const index = parent.findIndex((el) => el.id === element.id);
  //   if (parent[index + 1]) elementBelow = parent[index + 1];
  // }

  const handleSaveNewName = () => {
    element.update('name', newName);

    setIsEditingName(false);

    handleUpdateElement([element.id]);
  };

  useClickOutside(inputRef, () => {
    if (isEditingName) handleSaveNewName();
  });

  // FIXME: FIGURE THIS OUT
  // useClickOutside(rowRef, handleDeselectElement);

  const handleToggleIsOpen = (e) => {
    e.stopPropagation();

    setIsOpen(!isOpen);
  };

  const handleClick = (event) => {
    event.stopPropagation();

    if (cmdKeyDown && !shiftKeyDown) {
      setSelectedElements([...selectedElements, element]);
    } else if (!cmdKeyDown && shiftKeyDown) {
      const newElements = [...elements];
      const index = elements.findIndex((el) => el.id === element.id);

      let nearestIndex = null;
      selectedElements.forEach((el) => {
        const selIndex = elements.findIndex((e) => e.id === el.id);
        if (!nearestIndex) nearestIndex = selIndex;
        else if (Math.abs(selIndex - index) < nearestIndex)
          nearestIndex = selIndex;
      });

      if (nearestIndex > index) {
        const elementsToAdd = newElements.splice(index, nearestIndex - index);
        setSelectedElements([...selectedElements, ...elementsToAdd]);
      }

      if (index > nearestIndex) {
        const elementsToAdd = newElements.splice(
          nearestIndex,
          index - nearestIndex + 1
        );
        setSelectedElements([...selectedElements, ...elementsToAdd]);
      }
    } else {
      setSelectedElements([element]);
    }
  };

  const handleClickText = () => {
    if (isSelected) {
      setIsEditingName(true);
    }
  };

  const handleToggleLocked = () => {
    element.locked = !element.locked;
    handleUpdateElement([element.id]);
  };

  const elementOptions = [
    // ...(maskTypes.has(element.type) && elementBelow
    //   ? [
    //       {
    //         id: 'mask',
    //         name: 'Mask to below',
    //         icon: 'fad fa-mask',
    //         onClick: () => handleMaskElement(element, elementBelow),
    //       },
    //     ]
    //   : []),
    {
      id: 'lock',
      name: isLocked ? 'Unlock' : 'Lock',
      component: (
        <Option onClick={handleToggleLocked}>
          <OptionsText
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box>{isLocked ? 'Unlock' : 'Lock'}</Box>
            <Box style={{ opacity: 0.75 }}>
              {getHotkey(isLocked ? 'UNLOCK_ELEMENT' : 'LOCK_ELEMENT')}
            </Box>
          </OptionsText>
        </Option>
      ),
    },
    {
      id: 'duplicate',
      name: 'Duplicate',
      icon: 'fad fa-copy',
      onClick: () => handleCloneElements([element]),
    },
    {
      id: 'delete',
      name: 'Delete',
      icon: 'fad fa-trash',
      onClick: () => handleRemoveElements([element]),
    },
  ];

  // Open group if it's not open and we select a child
  useEffect(() => {
    if (
      isGroup &&
      !isOpen &&
      selectedElements.some(
        (el) =>
          el.id !== element.id && group.findElement(el.id, element.elements)
      )
    ) {
      setIsOpen(true);
    }
    // eslint-disable-next-line
  }, [selectedElements]);

  return (
    <ElementRowContainer
      className={className}
      style={style}
      ref={rowRef}
      onClick={handleClick}
      isSelected={isSelected}
    >
      <Flex style={{ justifyContent: 'space-between' }}>
        <Flex c>
          {isGroup && (
            <Flex c>
              <Button
                onClick={handleToggleIsOpen}
                style={{
                  fontSize: 16,
                  color: 'white',
                  marginRight: 10,
                }}
                icon
                transparent
              >
                <FontAwesomeIcon
                  icon={['fad', isOpen ? 'folder-open' : 'folder']}
                />
              </Button>
            </Flex>
          )}
          {!isGroup && (icon || iconMap[element.type]) && (
            <IconContainer>
              <Icon
                style={{ fontSize: 18 }}
                icon={['fad', icon || iconMap[element.type]]}
              />
            </IconContainer>
          )}
          <ElementText onClick={handleClickText}>
            {isEditingName && (
              <Box ref={inputRef}>
                <StyledTextInput
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                  handleEnterPress={handleSaveNewName}
                  placeholder={element.name || ''}
                  darkMode={true}
                />
              </Box>
            )}
            {!isEditingName && <Name>{element.name}</Name>}
            <span>({element.type})</span>
          </ElementText>
        </Flex>
        <Flex alignItems="center">
          {isLocked && (
            <Button
              style={{ fontSize: 14 }}
              icon
              color={fade('white', 50)}
              onClick={handleToggleLocked}
            >
              <FontAwesomeIcon icon={['fad', 'lock']} />
            </Button>
          )}
          <OptionSelector
            options={elementOptions}
            onClick={(e) => e.stopPropagation()}
          >
            <Button style={{ fontSize: 14 }} icon color={fade('white', 50)}>
              <FontAwesomeIcon icon={['fas', 'ellipsis-v']} />
            </Button>
          </OptionSelector>
        </Flex>
      </Flex>
      {!!element.maskElements.length && (
        <ElementMasks
          {...props}
          elements={element.maskElements}
          ElementRowWrapper={ElementRowWrapper}
        />
      )}
      {isOpen &&
        isGroup &&
        element.elements.map((e) => (
          <Box key={e.id} style={{ marginLeft: 10 }}>
            <ElementRowWrapper
              {...props}
              isSelected={!!selectedElements.find((el) => el.id === e.id)}
              element={e}
              parent={element.elements}
            />
          </Box>
        ))}
    </ElementRowContainer>
  );
};

const { arrayOf, shape, bool, string, func } = PropTypes;

ElementRow.propTypes = {
  element: ElementProps.isRequired,
  ElementRowWrapper: shape({}).isRequired,
  selectedElements: arrayOf(ElementProps).isRequired,
  setSelectedElements: func.isRequired,
  elements: arrayOf(ElementProps).isRequired,
  handleReorderElement: func.isRequired,
  handleAddToGroup: func.isRequired,
  handleGroupElements: func.isRequired,
  shiftKeyDown: bool.isRequired,
  cmdKeyDown: bool.isRequired,
  handleUpdateElement: func.isRequired,
  handleCloneElements: func.isRequired,
  handleRemoveElements: func.isRequired,
  isSelected: bool.isRequired,
  parent: arrayOf(ElementProps),
  icon: string,
  style: shape({}),
  className: string,
};

export default ElementRow;
