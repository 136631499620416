import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

import React from 'react';

import { post } from '../../util/fetchUtil';

import AddPermissions from './users/AddPermissions';

import Table from '../Table';

import { colors, lighten } from '../styles/colors';

import { parse } from '../../shared/grants';

class AdminPermissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      dialogVisible: true,
    };

    post('/admin/getAllUserGrants').then((res) =>
      this.setState({ users: res.users })
    );

    this.handleRemovePermission = this.handleRemovePermission.bind(this);
    this.handleSavePermissions = this.handleSavePermissions.bind(this);
  }

  handleRemovePermission(userID, permission) {
    post('/admin/revokeGrants', {
      userID,
      grants: [permission],
    })
      .then(() => post('/admin/getAllUserGrants'))
      .then((res) => this.setState({ users: res.users }));
  }

  /**
   * Get updated user grants after saving permissions
   *
   * @returns {Promise}
   * @memberof AdminPermissions
   */
  handleSavePermissions() {
    return post('/admin/getAllUserGrants').then((res) => {
      this.setState({ users: res.users });
    });
  }

  render() {
    const { users } = this.state;

    const headers = [
      {
        id: 'name',
        value: 'Name',
      },
      {
        id: 'userID',
        value: 'User ID',
      },
      {
        id: 'permissions',
        value: 'Permissions',
        expand: true,
      },
    ];

    const rows = users.map((user) => ({
      name: user.fullName,
      userID: user.userID,
      permissions: (
        <div>
          <AddPermissions
            handleSavePermissions={this.handleSavePermissions}
            user={user}
          />
          {user.permissions.map((permission) => {
            const grant = parse(permission);
            return (
              <PermissionTag key={permission}>
                {grant.type} {'>'} {grant.subtype}
                {':'} {grant.level}
                <FontAwesomeIcon
                  icon={['fas', 'times']}
                  onClick={() =>
                    this.handleRemovePermission(user.userID, permission)
                  }
                />
              </PermissionTag>
            );
          })}
        </div>
      ),
    }));

    return (
      <Container>
        <Table headers={headers} rows={rows} />
      </Container>
    );
  }
}

const Container = styled.div``;

const PermissionTag = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  margin-right: 5px;

  padding: 3px 6px;
  border-radius: 3px;
  background: ${lighten(colors.teal, 45)};
  color: ${colors['dark-teal-10']};

  i,
  svg {
    margin-left: 10px;
    color: ${colors['light-teal-20']};
    cursor: pointer;

    :hover,
    :focus {
      color: ${colors['light-teal-10']};
    }
  }
`;

AdminPermissions.propTypes = {};

AdminPermissions.defaultProps = {};

export default AdminPermissions;
