import $ from 'jquery';
import anime from 'animejs/lib/anime.es.js';

import Anim from './anim';

import animOptions from './animOptions';

// Easing functions
// https://gist.github.com/gre/1650294
// https://github.com/danro/jquery-easing/blob/master/jquery.easing.js
import easings from './easings';

export default function (sceneObject) {
  const id = `#${sceneObject.id}`;
  const { value, bbox, motionPath } = sceneObject;

  const imageGroup = sceneObject.draw.select('g.image-group').members[0];

  // Reset the transform style property (interferes with SVG transform)
  if (imageGroup) {
    imageGroup.node.style.transform = '';
  }

  // Get image options
  const anims = animOptions(imageGroup, bbox, sceneObject.dimensions);

  // Add to dropdown list if motionPath option exists
  if (motionPath) {
    // Parent group that holds the "imageGroup"
    let motionImageGroup;
    const motionPathId = `${sceneObject.id}-motion-path`;

    // Check if alongPath animation is selected
    if (typeof value === 'undefined' || value === 'alongPath') {
      // If motionImageGroup does not already exist, create it and make it the parent of imageGroup
      if (
        sceneObject.draw.select('g.motion-image-group').members.length === 0
      ) {
        // Append basic div container with path data and remove SVG.js object
        $(id).append(`
          <div 
            class="motion-path"
            id="${motionPathId}"
          >
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 ${sceneObject.dimensions.w} ${
          sceneObject.dimensions.h
        }"
            >
              <path 
                d="${motionPath.getAttribute('d')}">
              </path>
            </svg>
          </div>`);

        motionImageGroup = sceneObject.draw.group();
        motionImageGroup.add(imageGroup);
        motionImageGroup.addClass('motion-image-group');
      } else {
        // Otherwise if motionImageGroup does already exist, move imageGroup into.
        motionImageGroup = sceneObject.draw.select('g.motion-image-group')
          .members[0];
        imageGroup.putIn(motionImageGroup);
      }
    } else {
      // If 'Along path' is not selected, move imageGroup out of motionImageGroup
      motionImageGroup = sceneObject.draw.select('g.motion-image-group')
        .members[0];
      imageGroup.putIn(sceneObject.draw);
    }

    // Use anime.js path Object
    const path = anime.path(`#${motionPathId} path`);
    const startPosition = path().el.getPointAtLength(0);

    // Move to starting position
    motionImageGroup.node.setAttribute(
      'transform',
      `translate(${startPosition.x - bbox.cx} ${startPosition.y - bbox.cy})`
    );

    anims.alongPath = anime.timeline({
      targets: `#${motionImageGroup.id()}`,
      autoplay: false,
    });

    anims.alongPath
      .add({
        opacity: [0, 1],
        duration: 2000,
        begin(anim) {
          // Adjust <g> transform attribute at each update
          const { progress } = anim;
          const currentLength = (progress / 100) * path().totalLength;
          const currentPosition = path().el.getPointAtLength(currentLength);
          motionImageGroup.node.setAttribute(
            'transform',
            `translate(${currentPosition.x - bbox.cx} ${
              currentPosition.y - bbox.cy
            })`
          );
        },
      })
      .add({
        duration: Math.ceil(path().totalLength * 2),
        update(anim) {
          // Adjust <g> transform attribute at each update
          const { progress } = anim;
          // Have to add custom easing since we're updating in update
          const currentLength =
            easings.easeInOutCubic(progress / 100) * path().totalLength;
          const currentPosition = path().el.getPointAtLength(currentLength);

          // Use the translate attribute to preserve position in the SVG during resizing
          // (css transform will not preserve position)
          motionImageGroup.node.setAttribute(
            'transform',
            `translate(${currentPosition.x - bbox.cx} ${
              currentPosition.y - bbox.cy
            })`
          );
        },
      });

    anims.alongPath.name = 'Along path';

    // Turn current default off
    const animValues = Object.values(anims);
    for (let i = 0; i < animValues.length; i += 1) {
      animValues[i].defaultAnim = false;
    }

    // Make alongPath default
    anims.alongPath.defaultAnim = true;
  }

  // Create empty array to hold dropdown options
  const dropdownValues = [];

  // Read animation options from anims object
  const animKeys = Object.keys(anims);
  for (let i = 0; i < animKeys.length; i += 1) {
    dropdownValues.push({
      name: anims[animKeys[i]].name,
      value: animKeys[i],
      selected: !!anims[animKeys[i]].defaultAnim,
    });
  }

  // Initially set to null in case no id is passed
  let anim = null;

  // If id was passed, create and set new animation
  if (id) {
    anim = new Anim();
    if (anims[value]) {
      anim.animejs = anime(anims[value]);
      anim.finish();
    } else {
      for (let i = 0; i < animKeys.length; i += 1) {
        const animValue = anims[animKeys[i]];
        if (animValue.defaultAnim === true) {
          // Along path already instantiated (because of anime.timeline usage)
          if (animValue.name === 'Along path') {
            anim.animejs = animValue;
          } else {
            // Instantiate animation
            anim.animejs = anime(animValue);
          }
          anim.finish();
        }
      }
    }
  }

  return { anim, dropdownValues };
}
