import React from 'react';
import styled from 'styled-components/macro';

import { useHistory } from 'react-router-dom';

import Box, { Flex } from '../Box';
import Icon from '../Icon';
import Button from '../Button';

import { KitProps } from './assetsProps';

import { H3 } from '../styles/typography';
import elevations from '../styles/elevations';
import { colors, lighten } from '../styles/colors';

const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  cursor: pointer;

  margin: 15px;

  height: 200px;
  width: 300px;

  border-radius: 5px;

  background-color: white;
  border: 1px solid ${colors['light-grey-50']};

  transition: 0.1s all ease-in-out;

  overflow: hidden;

  :hover,
  :focus {
    box-shadow: ${elevations[2]};
    transform: translateY(-5px);
  }

  :active {
    background-color: ${lighten(colors.grey, 59)};
  }
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

function KitCard(props) {
  const { kit } = props;

  const history = useHistory();

  const handleSettingsClick = (e) => {
    e.stopPropagation();
    history.push(`/assets/${kit.kitID}/settings`);
  };

  const handleCardClick = (e) => {
    e.stopPropagation();
    history.push(`/assets/${kit.kitID}`);
  };

  return (
    <Container onClick={handleCardClick}>
      <Box
        style={{
          width: '100%',
          flex: 1,
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Image src={kit.data.thumbnail} />
      </Box>
      <Flex
        cy
        style={{
          padding: 15,
          justifyContent: 'space-between',
          borderTop: `1px solid ${colors['light-grey-50']}`,
        }}
      >
        <H3>{kit.name}</H3>
        <Flex c>
          {kit.data.visibility === 'public-read' && (
            <Box data-tippy-content="Public kit" style={{ marginRight: 5 }}>
              <Icon
                icon="globe-africa"
                style={{ color: colors['light-grey-20'] }}
              />
            </Box>
          )}
          <Button icon onClick={handleSettingsClick}>
            <Icon
              style={{ color: colors['light-grey-40'] }}
              icon="cog"
              prefix="fas"
            />
          </Button>
        </Flex>
      </Flex>
    </Container>
  );
}

KitCard.propTypes = {
  kit: KitProps,
};

KitCard.defaultProps = {
  kit: {},
};

export default KitCard;
