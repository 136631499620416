import dPathParse from 'd-path-parser';

import { getSvgCoords } from '../app/editor/coords';
import path from '../app/editor/path';
import SvgElement from './SvgElement';

// dPathParse data schema
// [
//   { end: { x: 200, y: 200 }, code: 'M', relative: false },
//   {
//     cp1: { x: 200, y: 300 },
//     code: 'C',
//     relative: false,
//     cp2: { x: 500, y: 100 },
//     end: { x: 500, y: 200 },
//   },
//   {
//     cp1: { x: 341, y: 639 },
//     code: 'C',
//     relative: false,
//     cp2: { x: 370, y: 421 },
//     end: { x: 370, y: 421 },
//   },
// ];

/**
 * Object representation of a <path>
 *
 * @class PathElement
 * @extends {SvgElement}
 */
class PathElement extends SvgElement {
  constructor(args = {}) {
    super({
      ...args,
      type: 'path',
    });

    const { props = {}, clipPath } = args;

    this.props = {
      ...props,
      ...(props.d ? { d: path.toAbsolute(props.d) } : {}), // Make sure d path is absolute
      style: {
        stroke: 'none',
        strokeWidth: 1,
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        fill: 'none',
        ...props.style,
      },
    };

    this.currentProps = {
      pressure: [
        [0, 1],
        [1, 1],
      ],
      ...this.props,
      ...this.currentProps,
      ...(this.currentProps.d
        ? { d: path.toAbsolute(this.currentProps.d) } // Make sure d path is absolute
        : {}),
      dashoffset: props.dashoffset || 1,
    };

    // Optional clipping path
    this.clipPath = clipPath;

    this.parsedPath = [];
    if (this.currentProps.d) this.setParsedPath();
  }

  /**
   * Translate a control point for one of the nodes
   *
   * @param {HTMLElement} svg
   * @param {MouseEvent} e
   * @param {Number} commandIndex - command index from parsedPath
   * @param {boolean} [mirror=false] - whether this should be mirrored or not
   * @memberof PathElement
   */
  translateControlPoint(svg, e, commandIndex, mirror = false) {
    const {
      parsedPath,
      currentProps: { scaleX, scaleY, translateX, translateY },
    } = this;
    const newCoords = getSvgCoords(svg, e);
    const newParsedPath = path.translateControlPoints(
      newCoords,
      parsedPath,
      commandIndex,
      { scaleX, scaleY, translateX, translateY },
      mirror
    );

    // Update
    const newD = path.getDString(newParsedPath);
    this.update('currentProps', {
      ...this.currentProps,
      d: newD,
    });
  }

  /**
   * Translates a path node by the given distance
   *
   * @param {Number} nodeIndex - the node index in the node array for the path
   * @param {Number} dx - the delta x
   * @param {Number} dy - the delta y
   * @memberof PathElement
   */
  translateNode(nodeIndex, dx, dy) {
    const {
      parsedPath,
      currentProps: { scaleX, scaleY },
    } = this;

    path.translateNode(parsedPath, nodeIndex, dx, dy, { scaleX, scaleY });

    // Update
    const newD = path.getDString(parsedPath);
    this.update('currentProps', {
      ...this.currentProps,
      d: newD,
    });
  }

  /**
   *
   *
   * @param {SVGElement} svg
   * @param {MouseEvent} e
   * @param {Object} initialCp
   * {
   *  cpx: {Number},
   *  cpy: {Number}
   * }
   * @memberof PathElement
   */
  addPoint(svg, e, initialCp) {
    const { parsedPath } = this;
    const coords = getSvgCoords(svg, e);

    const newCommand = path.addPoint(coords, parsedPath, initialCp);

    // Update
    const newD = this.currentProps.d + newCommand;
    this.update('currentProps', {
      ...this.currentProps,
      d: newD,
    });
  }

  /**
   * Removes nodes from the path
   *
   * @param {[Number]} nodeIndexes - array of number indexes to remove
   * @memberof PathElement
   */
  removeNodes(nodeIndexes) {
    const { parsedPath } = this;

    const newParsedPath = [...parsedPath];

    const nodesToRemove = nodeIndexes.sort(function (a, b) {
      return b - a;
    });

    for (let i = nodesToRemove.length - 1; i >= 0; i -= 1) {
      newParsedPath.splice(nodesToRemove[i], 1);
    }

    const newD = path.getDString(newParsedPath);
    this.update('currentProps', {
      ...this.currentProps,
      d: newD,
    });
  }

  /**
   * Cache the parsed path
   * // TODO: SHOULD THIS BE IN CACHE?
   *
   * @memberof PathElement
   */
  setParsedPath() {
    this.parsedPath = dPathParse(this.currentProps.d);
  }

  /**
   * Update hook - caches the parsed path on every update
   *
   * @param {String} key
   * @param {String} value
   * @memberof PathElement
   */
  update(key, value) {
    super.update(key, value);

    // Cache the new parsed path
    this.setParsedPath();
  }
}

export default PathElement;
