import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import colors from '../../../styles/themeColors';
import { KitDirectoryProps } from '../../../types/KitDirectoryProps';
import { post } from '../../../util/fetchUtil';
import Autocomplete from '../../Autocomplete';
import Box, { Flex } from '../../Box';
import { FileKitProps } from '../../editor/types/FileProps';
import Button from '../../RebassButton';
import { H3, P } from '../../styles/typography';

interface Props {
  kit: FileKitProps;
  handleRemoveKit: (kitID: number) => void;
  addToKitDirectory?: (kitID: number, directoryID: number) => Promise<void>;
}

const getParents = (
  dir: KitDirectoryProps,
  dirs: KitDirectoryProps[] = []
): KitDirectoryProps[] => {
  const tree: KitDirectoryProps[] = [];

  const addParent = (id: number) => {
    const parent = dirs.find((d) => d.directoryID === id);
    if (parent) {
      tree.unshift(parent);
      if (parent.parentID) {
        addParent(parent.parentID);
      }
    }
  };

  addParent(dir.directoryID);

  return tree;
};

const FileKitDirectory = (props: Props) => {
  const { kit, addToKitDirectory, handleRemoveKit } = props;

  const { name, kitID, directoryID } = kit;

  const [dirs, setDirs] = useState<KitDirectoryProps[]>([]);
  const [searchValue, setSearchValue] = useState('');

  // Find directory for this kit
  const filesDir = dirs.find((dir) => directoryID === dir.directoryID);

  // Child options for dropdown
  const options = dirs.filter((d) =>
    filesDir ? d.parentID === filesDir.directoryID : true
  );

  // Tree to display based on directory
  const tree = filesDir ? getParents(filesDir, dirs) : [];

  const handleAddToKitDirectory = (directory: KitDirectoryProps) => {
    if (addToKitDirectory) {
      addToKitDirectory(kitID, directory.directoryID);
      setSearchValue('');
    }
  };

  const handleRemoveDirectory = (dirID: number) => {
    const index = tree.findIndex((d) => d.directoryID === dirID);
    if (index > -1) {
      // Add parent if it exists
      if (addToKitDirectory) {
        addToKitDirectory(kitID, tree[index - 1]?.directoryID);
      }
    }
  };

  useEffect(() => {
    const init = async () => {
      const res = await post('/assets/getKitDirectories', { kitID });
      setDirs(res.flattenedDirectories);
    };

    init();
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      p={2}
      sx={{ borderRadius: 5, border: `1px solid ${colors['light-3']}` }}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <H3>{name}</H3>
        <Button
          variant="transparent"
          color="dark-5"
          onClick={() => handleRemoveKit(kitID)}
        >
          <FontAwesomeIcon icon={['fas', 'times']} />
        </Button>
      </Flex>
      <Box>
        {tree.map((d, i) => (
          <Flex key={d.directoryID} mt={i === 0 ? 2 : 0}>
            <P p={1} pl={`${i * 20}px`}>
              └─ <FontAwesomeIcon icon={['fad', 'folder']} /> {d.name}
            </P>
            <Button
              variant="transparent"
              color="dark-5"
              onClick={() => handleRemoveDirectory(d.directoryID)}
            >
              <FontAwesomeIcon icon={['fas', 'times']} />
            </Button>
          </Flex>
        ))}
      </Box>
      {!!options.length && addToKitDirectory && (
        <Autocomplete
          value={searchValue}
          options={options}
          placeholder={`Add to folder${
            tree.length ? ` in ${tree[tree.length - 1].name}` : ''
          }`}
          onItemSelect={handleAddToKitDirectory}
          onChange={(e) => setSearchValue(e.target.value)}
          showListWhenEmpty={true}
        />
      )}
    </Box>
  );
};

export default FileKitDirectory;
