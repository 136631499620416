import styled from 'styled-components/macro';

import React, { useState } from 'react';

import { motion, AnimatePresence } from 'framer-motion';

import { getFilesFromEvent } from '../../app/fileManager';
import DropZone from '../DropZone';
import { Flex } from '../Box';
import LoadingSpinner from '../LoadingSpinner';
import Icon from '../Icon';

import { H1 } from '../styles/typography';

import { colors, fade } from '../styles/colors';

const DropMessage = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  pointer-events: none;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  background-color: ${fade(colors.blue, 40)};
`;

const Message = styled(H1)`
  position: relative;
  color: white;
`;

type Props = {
  handleUploadFiles: (fileList: { [index: string]: File }) => Promise<void>;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
};

function KitUploader(props: Props) {
  const { handleUploadFiles, children, className, style, disabled } = props;

  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const canUpload = !disabled;

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    console.log('ENTER');

    if (!canUpload) {
      return;
    }

    const files = getFilesFromEvent(e);

    // Show dropzone if dragging files over
    if (files.length && !isDraggingOver) setIsDraggingOver(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    console.log('LEAVE');

    if (isDraggingOver) setIsDraggingOver(false);
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();

    if (!canUpload) {
      return;
    }

    const files = getFilesFromEvent(e);

    if (!files.length) return;

    setIsUploading(true);
    await handleUploadFiles(files as any);
    setIsUploading(false);

    setIsDraggingOver(false);
  };

  const dropMessage = (
    <Flex c style={{ flexDirection: 'column' }}>
      <Icon
        icon="cloud-upload"
        style={{ fontSize: 84, color: 'white', margin: 20 }}
      />
      <Message>Drop file to upload</Message>
    </Flex>
  );

  const uploadMessage = (
    <Flex c style={{ flexDirection: 'column' }}>
      <LoadingSpinner style={{ fontSize: 84, color: 'white', margin: 20 }} />
      <Message>Uploading...</Message>
    </Flex>
  );

  const components = canUpload ? (
    <DropZone
      onDragLeave={handleDragLeave}
      onDragEnter={handleDragEnter}
      onDrop={handleDrop}
      className={className}
      style={style}
    >
      {children}
      <AnimatePresence>
        {(isDraggingOver || isUploading) && (
          <DropMessage
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1 }}
          >
            <Background />
            {isUploading ? uploadMessage : dropMessage}
          </DropMessage>
        )}
      </AnimatePresence>
    </DropZone>
  ) : (
    <>{children}</>
  );

  return components;
}

export default KitUploader;
