import styled from 'styled-components/macro';

import { colors } from '../styles/colors';

export const Container = styled.div`
  border-right: 1px solid ${colors['light-grey-50']};

  overflow: auto;
`;
export const PanelHeader = styled.div`
  background: ${colors.teal};

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 15px;
`;
export const Header = styled.h3`
  margin: 0;
  color: white;
`;
export const PanelBody = styled.div``;
export const EntriesContainer = styled.div`
  padding: 10px;
`;
export const AddNewContentContainer = styled.div`
  padding: 15px 30px;
  background: #f8f8f8;
`;
export const FieldContainer = styled.div`
  display: flex;
  > * {
    margin-right: 5px;
  }

  .text-input {
    margin-right: 10px;
  }
`;
export const InputHeader = styled.h4`
  margin: 0;
  margin-bottom: 10px;

  color: ${colors['light-grey-20']};
`;
export const InputSubheader = styled.p`
  margin: 0;
  margin-top: 5px;
  font-size: 14px;
  color: ${colors['light-grey-30']};
`;
