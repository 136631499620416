import styled from 'styled-components/macro';

import NumberInput from '../../NumberInput';
import Box from '../../Box';
import { P } from '../../styles/typography';
import { colors, fade, darken } from '../../styles/colors';

export const PropertyContainer = styled(Box)`
  margin-bottom: 15px;
`;
export const Header = styled(Box)`
  background-color: ${darken(colors.night, 5)};
  padding: 5px;
  font-size: 16px;
  color: ${fade('white', 30)};
`;
export const Label = styled(P)`
  color: white;
  opacity: 0.5;
`;
export const Unit = styled(P)`
  color: white;
  opacity: 0.25;
`;
export const Value = styled(P)`
  color: white;
  opacity: 0.8;
`;
export const Section = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 45%;
`;
export const Group = styled(Box)`
  padding: 10px;
`;
export const StyledNumberInput = styled(NumberInput)`
  font-size: 14px;
  padding: 0.5em;
  width: 50px;

  margin-right: 3px;

  text-align: center;
`;
