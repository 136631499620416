import React, { useState, useRef, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import paper, { Size } from 'paper';

import {
  handleStageDragPen,
  handleStageDragBox,
} from '../../app/editor/helpers/SceneReview';
import font from '../../app/font';
import { loadFromS3 } from '../../app/editor/load';
import useAnimationFrame from '../../app/hooks/useAnimationFrame';

import { Store } from '../../state/store';
import { fetchTeamData, fetchUserData } from '../../state/user/actions';
import { colors } from '../styles/colors';

import Timeline from '../editor/timeline/Timeline';

import Box, { Flex } from '../Box';
import Viewer from '../editor/Viewer';

import { SceneProps } from '../editor/types/SceneProps';
import { CommentProps } from '../editor/types/CommentProps';
import ReviewView from './ReviewView';
import { ActiveViewProps, Tools, AnnotationProps } from './ReviewTypes';

import { getAllOfType } from '../../app/editor/elements';
import { ElementProps } from '../editor/types/ElementProps';
import { check } from '../../utilities/spelling';
import {
  fetchSavedWords,
  setMisspelledWords,
} from '../../state/misspelledWords/actions';
import { ToolOptionProps } from '../editor/tools';

const toolsMap: { [index: string]: ToolOptionProps } = {
  pen: 'annotate',
  box: 'annotate',
};

// Setup paper canvas
paper.setup(new Size(1000, 1000));

const StyledViewer = styled(Viewer)`
  flex: 5;
`;

type Props = {
  scene: SceneProps;
  comments: CommentProps[];
  handleGetComments: () => void;
};

type PaperPathProps = {
  simplify: () => void; // NOT IDEAL
  getPathData: () => string;
};

const getAllWords = (elements: ElementProps[]) => {
  const textElements: ElementProps[] = getAllOfType('text', elements);
  const words: string[] = [];
  textElements.forEach((el: ElementProps) => {
    words.push(...(el.text ? el.text.split(' ').map((t) => t.trim()) : []));
  });

  return check(words);
};

const SceneReview: React.FC<Props> = (props: Props) => {
  const { scene, comments, handleGetComments } = props;

  const {
    dispatch,
    state: { team, savedWords, misspelledWords },
  } = useContext(Store);

  const [elements, setElements] = useState<ElementProps[]>([]);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [selectedElements] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);

  const [newAnnotations, setNewAnnotations] = useState<AnnotationProps[]>([]);
  const [savedAnnotations, setSavedAnnotations] = useState<AnnotationProps[]>(
    []
  );
  const [currentAnnotation, setCurrentAnnotation] = useState<AnnotationProps>();
  const [isAnnotating, setIsAnnotating] = useState(false);

  const [activeView, setActiveView] = useState<ActiveViewProps>('comments');

  const [stageDimensions] = useState({
    width: (scene.data.dimensions && scene.data.dimensions.width) || 1920,
    height: (scene.data.dimensions && scene.data.dimensions.height) || 1080,
  });

  const [selectedCommentID, setSelectedCommentID] = useState<number>();
  const [activeTool, setActiveTool] = useState<Tools>('');

  const paperPath = useRef<PaperPathProps>();

  const svgRef = useRef<SVGElement>(null);
  const elementsToUpdate = useRef([]);

  const gridVisible = false;

  const init = async () => {
    const promises = [
      fetchTeamData(dispatch),
      fetchUserData(dispatch),
      fetchSavedWords(dispatch),
      font.init(),
    ];
    return Promise.all(promises);
  };

  const handleSetActiveTool = (toolId: Tools) => {
    if (toolId === activeTool) {
      setActiveTool('');
      setNewAnnotations([]);
    } else setActiveTool(toolId);
  };

  const handleStageDrag = (svg: SVGElement, e: React.DragEvent) => {
    if (activeTool === 'pen') {
      handleStageDragPen({
        e,
        svg,
        paperPath,
        annotations: newAnnotations,
        currentAnnotation,
        setIsAnnotating,
        setCurrentAnnotation,
        setAnnotations: setNewAnnotations,
      });
    } else if (activeTool === 'box') {
      handleStageDragBox({
        e,
        svg,
        annotations: newAnnotations,
        currentAnnotation,
        setIsAnnotating,
        setCurrentAnnotation,
        setAnnotations: setNewAnnotations,
      });
    }
  };

  const handleStageMouseDown = () => {
    if (activeTool === 'pen') {
      // setAnnotations([]);
    }
  };

  const handleStageMouseUp = () => {
    if (activeTool === 'pen') {
      if (paperPath.current) {
        if (paperPath.current?.simplify) paperPath.current.simplify();

        const newNewAnnotations = [...newAnnotations];
        const index = newNewAnnotations.findIndex(
          (a) => a.id === currentAnnotation?.id
        );
        newNewAnnotations[index].attributes.d = paperPath.current.getPathData();
      }

      paperPath.current = undefined;
    } else if (activeTool === 'box') {
      // Handle box mouse up
    }

    setCurrentAnnotation(undefined);

    setIsAnnotating(false);
  };

  const handleUpdateProgress = (delta: number) => {
    setProgress((prevProgress) => {
      const newProgress = prevProgress + delta / duration;
      // Clamp progress at 1
      if (newProgress > 1) return 1;

      return newProgress;
    });
  };

  const { controls } = useAnimationFrame(handleUpdateProgress);

  const handlePlay = () => {
    if (!isPlaying) {
      controls.start();
    } else {
      controls.stop();
    }
    setIsPlaying(!isPlaying);
  };

  const handleSkip = (time: number) => {
    const skipProgress = time / duration;
    const newProgress = skipProgress + progress;
    if (newProgress >= 1) setProgress(1);
    else if (newProgress <= 0) setProgress(0);
    else setProgress(newProgress);
  };

  const handleUpdateElement = () => {
    setElements([...elements]);
  };

  const loadScene = async () => {
    if (scene.data) {
      const sceneDuration = scene.data.duration;

      if (scene.data.s3Key) {
        const data = await loadFromS3(scene.data.s3Key);
        const newElements = data.elements;

        setElements(newElements);
      }

      setDuration(sceneDuration);
    }
  };

  const handleClickComment = async (comment: CommentProps) => {
    if (comment.data.annotations) {
      const commentAnnotations = await fetch(
        comment.data.annotations
      ).then((res) => res.json());

      setSavedAnnotations(commentAnnotations);
    } else {
      setSavedAnnotations([]);
    }

    if (comment.data.timestamp) {
      setProgress(comment.data.timestamp / duration);
    }

    setSelectedCommentID(comment.commentID);
  };

  const currentTime = progress * duration;

  const loadEditor = async () => {
    if (!font.hasLoaded) {
      await init();
    }

    loadScene();
    handleGetComments();
  };

  const handleUpdateList = () => {
    fetchSavedWords(dispatch);
  };

  const handleSetMisspelledWords = (updatedMisspelledWords: string[]) => {
    setMisspelledWords(updatedMisspelledWords, dispatch);
  };

  useEffect(() => {
    loadEditor();

    setProgress(0);

    setSavedAnnotations([]);
    // eslint-disable-next-line
  }, [scene]);

  useEffect(() => {
    setMisspelledWords(getAllWords(elements), dispatch);
    // eslint-disable-next-line
  }, [elements]);

  // Stop animation when we're at 1
  useEffect(() => {
    if (progress === 1 && isPlaying) {
      setIsPlaying(false);
      controls.stop();
    }
    // eslint-disable-next-line
  }, [progress]);

  useEffect(() => {
    // If we just deselected a comment, remove any annotations
    if (!selectedCommentID) {
      setSavedAnnotations([]);
    }
  }, [selectedCommentID]);

  useEffect(() => {
    // If savedAnnotations are selected and newAnnotations are currently active, clear newAnnotations
    if (savedAnnotations.length && newAnnotations.length) setNewAnnotations([]);
    // eslint-disable-next-line
  }, [savedAnnotations]);

  let activeAnnotations: AnnotationProps[] = [];
  if (newAnnotations.length) activeAnnotations = newAnnotations;
  else if (savedAnnotations.length) activeAnnotations = savedAnnotations;

  // Filter saved words out of misspelled words
  const filteredWords = misspelledWords.filter(
    (word: string) => !savedWords.includes(word)
  );

  const tool = toolsMap[activeTool];

  return (
    <Box style={{ backgroundColor: colors.night }}>
      {elements.length && (
        <Flex style={{ flexDirection: 'column', height: '100vh' }}>
          <Flex style={{ height: '85%', flex: 1 }}>
            <Box style={{ flex: 1, overflow: 'auto' }}>
              <ReviewView
                scene={scene}
                currentTime={currentTime}
                comments={comments}
                activeTool={activeTool}
                selectedCommentID={selectedCommentID}
                team={team}
                isAnnotating={isAnnotating}
                isPlaying={isPlaying}
                activeView={activeView}
                newAnnotations={newAnnotations}
                misspelledWords={filteredWords}
                savedWords={savedWords}
                setActiveView={setActiveView}
                handleGetComments={handleGetComments}
                handleClickComment={handleClickComment}
                setSelectedCommentID={setSelectedCommentID}
                handleSetActiveTool={handleSetActiveTool}
                handleUpdateList={handleUpdateList}
                handleSetMisspelledWords={handleSetMisspelledWords}
              />
            </Box>
            <StyledViewer
              elements={elements}
              svgRef={svgRef}
              currentTime={currentTime}
              handleUpdateElement={handleUpdateElement}
              selectedElements={selectedElements}
              gridVisible={gridVisible}
              stageDimensions={stageDimensions}
              isPlaying={isPlaying}
              handleStageDrag={handleStageDrag}
              handleStageMouseUp={handleStageMouseUp}
              handleStageMouseDown={handleStageMouseDown}
              selectedTool={tool}
              annotations={activeAnnotations}
              isAnnotating={isAnnotating}
            />
          </Flex>
          <Box>
            <Timeline
              elements={elements}
              scene={scene}
              comments={comments}
              elementsToUpdate={elementsToUpdate}
              selectedElements={selectedElements}
              progress={progress}
              currentTime={currentTime}
              setProgress={setProgress}
              duration={duration}
              handleUpdateElement={handleUpdateElement}
              handlePlay={handlePlay}
              handleSkip={handleSkip}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              layersVisible={false}
              setLayersVisible={() => {}}
              canEdit={false}
              onClickComment={handleClickComment}
              handleSelectElements={() => {}}
              handleUpdateDuration={() => {}}
              isAnnotating={isAnnotating}
            />
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default SceneReview;
