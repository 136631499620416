import React from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazy-load';
import Box, { Flex } from '../Box';
import Text from '../Text';
import Video from './Video';

interface Props {
  heading?: string;
  subheading?: string;
  video?: string;
}

const LazyLoadContainer = styled(LazyLoad)`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const CenterBlock = (props: Props) => {
  const { heading, subheading, video } = props;
  return (
    <Flex flexDirection="column" maxWidth="1200px" margin="auto" p={[4, 5]}>
      <Flex flexDirection="column" alignItems="center" mb={4}>
        <Text textAlign="center" as="h1" mb={3}>
          {heading}
        </Text>
        <Text textAlign="center" as="p" fontSize="20px" maxWidth="600px">
          {subheading}
        </Text>
      </Flex>
      {video && (
        <LazyLoadContainer
          offsetBottom={300}
          onContentVisible={() => console.log('visible')}
        >
          <Box>
            <Video src={video} />
          </Box>
        </LazyLoadContainer>
      )}
    </Flex>
  );
};

export default CenterBlock;
