import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { colors, lighten } from '../styles/colors';

function EntryPreview(props) {
  const {
    credit: { role, name, attribution, shareAlike },
  } = props;

  return (
    <Container>
      <LeftCreditsContainer>
        <RoleText>{role}</RoleText>
      </LeftCreditsContainer>
      <Spacer />
      <RightCreditsContainer>
        <CreditsNameContainer>
          <NameText>{name}</NameText>
          {attribution === 'true' && (
            <Image>
              <img alt="Attribution" src="https://i.imgur.com/T09iIzS.png" />
            </Image>
          )}
          {shareAlike === 'true' && (
            <Image>
              <img alt="Share alike" src="https://i.imgur.com/qWlqmY4.png" />
            </Image>
          )}
        </CreditsNameContainer>
      </RightCreditsContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  margin-bottom: 18px;
`;

const LeftCreditsContainer = styled.div`
  display: flex;
  direction: rtl;

  width: 40%;

  text-align: right;
`;

const RightCreditsContainer = styled.div`
  width: 40%;
`;

const Text = styled.div`
  font-size: 32px;
  font-weight: 400;

  text-transform: uppercase;

  color: ${lighten(colors.blue, 10)};

  white-space: nowrap;
`;

const RoleText = styled(Text)`
  max-width: 50vw;
  line-height: 1.25em;
  white-space: unset;
`;

const NameText = styled(Text)`
  color: ${lighten(colors.red, 5)};

  max-width: 50vw;
  line-height: 1.25em;
  white-space: unset;
`;

const Spacer = styled.div`
  width: 50px;
`;

const CreditsNameContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

const Image = styled.div`
  width: 35px;

  display: flex;
  align-items: center;

  margin-left: 15px;

  :first-of-type {
    margin-left: 30px;
  }

  img {
    width: 100%;
  }
`;

EntryPreview.propTypes = {
  credit: PropTypes.shape({
    role: PropTypes.string,
    name: PropTypes.string,
    attribution: PropTypes.string, // Backend converted from bool to string
    shareAlike: PropTypes.string, // Backend converted from bool to string
  }),
};

EntryPreview.defaultProps = {};

export default EntryPreview;
