import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useContext } from 'react';

import { post } from '../../util/fetchUtil';

import { Store } from '../../state/store';

import { fetchActiveProject } from '../../state/projects/actions';

import Warning from '../dialogs/WarningDialog';
import TrelloCardWrapper from '../TrelloCardWrapper';
import Dialog from '../Dialog';
import Box from '../Box';
import LinkToTrello from '../dialogs/LinkToTrello';

import { colors } from '../styles/colors';

import {
  HeaderSection,
  SectionTitle,
  Icon,
} from '../views/ProjectContentsStyles';

import Button from '../RebassButton';
import { H4 } from '../styles/typography';

function TrelloSection(props) {
  const { cardData } = props;

  const {
    state: { activeProject },
    dispatch,
  } = useContext(Store);

  const [trelloDialogVisible, setTrelloDialogVisible] = useState(false);
  const [warningDialogVisible, setWarningDialogVisible] = useState(false);
  const trelloData = (activeProject.data && activeProject.data.trello) || {};

  const handleRemoveTrelloLink = async () => {
    await post('/project/unlinkTrelloCard', {
      projectID: activeProject.projectID,
    });

    fetchActiveProject(activeProject.projectID, dispatch);
    setWarningDialogVisible(false);
  };

  return (
    <Container>
      <Dialog
        isVisible={warningDialogVisible}
        handleHideDialog={() => setWarningDialogVisible(false)}
      >
        <Warning
          onConfirm={handleRemoveTrelloLink}
          onCancel={() => setWarningDialogVisible(false)}
          header="Removing the link to Trello will cause this project to stop syncing with Trello, are you sure you want to remove it?"
          color={colors.red}
        />
      </Dialog>
      <Dialog
        isVisible={trelloDialogVisible}
        handleHideDialog={() => setTrelloDialogVisible(false)}
      >
        <LinkToTrello
          project={activeProject}
          handleHideDialog={() => setTrelloDialogVisible(false)}
          updateProject={() =>
            fetchActiveProject(activeProject.projectID, dispatch)
          }
        />
      </Dialog>
      <HeaderSection>
        <SectionTitle>
          <Icon icon={['fab', 'trello']} />
          Trello
        </SectionTitle>
      </HeaderSection>
      <TrelloContainer>
        {!trelloData.id && (
          <Button box onClick={() => setTrelloDialogVisible(true)}>
            Sync with Trello
          </Button>
        )}
      </TrelloContainer>
      {cardData && (
        <List>
          <ListName>{cardData.list.name}</ListName>
          <TrelloCardWrapper cardId={trelloData.id} />
        </List>
      )}
      <Box style={{ marginTop: 10 }}>
        {trelloData.id && (
          <Button
            onClick={() => setWarningDialogVisible(true)}
            color="negative"
            variant="outline"
            size="s"
          >
            Remove Trello sync
          </Button>
        )}
      </Box>
    </Container>
  );
}

const Container = styled.div``;
const TrelloContainer = styled.div``;

const List = styled.div`
  padding: 10px 15px;

  display: inline-block;
  background: ${colors['light-grey-50']};
  border-radius: 3px;
`;

const ListName = styled(H4)`
  margin-bottom: 10px;
`;

TrelloSection.propTypes = {
  activeProject: PropTypes.object,
  cardData: PropTypes.shape({
    list: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
};

TrelloSection.defaultProps = {
  activeProject: {},
  cardData: null,
};

export default TrelloSection;
