import React from 'react';
import Box from '../Box';

type Props = {
  src: string;
};

export default function SvgFilePreview(props: Props) {
  const { src } = props;
  return (
    <Box maxHeight={200}>
      <embed src={src} height="100%" style={{ backgroundColor: 'white' }} />
    </Box>
  );
}
