// Pull directly from assets folder if in development mode (localhost), otherwise pull from S3
const s3Url = 'https://osmosify-src.s3.amazonaws.com/assets/';
const metricsKey = window.LOCAL
  ? '/assets/json/fontMetrics.json'
  : `${s3Url}SBu7pzZHTf_yigghx9uD8Q6RRViX5Oho.json`;
const tannerscriptKey = window.LOCAL
  ? '/assets/json/tannerscript.json'
  : `${s3Url}O3VCSHHMQueMcSZ3FOwKWOsFQgqOENao.json`;

// This script pulls the fonts from S3, runs them through opentype,
// and stores everything in FontMetrics.font
class Font {
  constructor() {
    this.metrics = {};
    this.tannerscript = {};

    this.hasLoaded = false;
  }

  init() {
    return new Promise((resolve, reject) => {
      const promises = [];

      const metricsPromise = new Promise((res) => {
        fetch(metricsKey)
          .then((response) => response.json())
          .then((json) => {
            this.metrics = json;
            res();
          });
      });

      const tannerscriptPromise = new Promise((res) => {
        fetch(tannerscriptKey)
          .then((response) => response.json())
          .then((json) => {
            this.tannerscript = json;
            res();
          });
      });

      promises.push(metricsPromise, tannerscriptPromise);

      const timeout = setTimeout(() => {
        // eslint-disable-next-line no-alert
        alert(
          "It's taking extra-long to load fonts, check your internet connection",
          'Ruh roh'
        );
        reject();
      }, 60000);

      // Resolve when all fetches have completed
      Promise.all(promises).then(() => {
        clearTimeout(timeout);

        this.hasLoaded = true;

        resolve();
      });
    });
  }
}

export default new Font();
