import { useState } from 'react';

type Props = {
  open?: boolean;
  timeout?: number;
  stayOpen?: boolean;
};

export default function useSnackbar(props: Props = {}) {
  const { open = false, timeout = 5000, stayOpen = false } = props;
  const [isVisible, setIsOpen] = useState(!!open);

  const hide = () => {
    setIsOpen(false);
  };

  const show = () => {
    setIsOpen(true);

    if (!stayOpen) {
      setTimeout(() => {
        hide();
      }, timeout);
    }
  };

  const toggle = () => {
    setIsOpen(!isVisible);
  };

  return { show, hide, toggle, isVisible };
}
