import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React from 'react';

const Container = styled.div`
  stroke: #d53939;
  stroke-width: 6;
  fill-opacity: 0;
  stroke-opacity: 0.75;
`;

/**
 * Inserts a saved SVG string into the DOM
 *
 * @class Annotation
 * @extends {React.Component}
 */
class Annotation extends React.Component {
  createMarkup(svg) {
    return { __html: svg };
  }

  render() {
    const { svg } = this.props;
    return (
      <Container dangerouslySetInnerHTML={this.createMarkup(svg)}></Container>
    );
  }
}

Annotation.propTypes = {
  svg: PropTypes.string,
};

Annotation.defaultProps = {
  svg: '',
};

export default Annotation;
