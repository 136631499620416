import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Box from '../../Box';
import iconMap from '../../../app/editor/elementIconMap';

import ElementProps from '../props/ElementProps';

const Container = styled(Box)`
  width: 100%;
  padding-left: 30px;
`;

/**
 * A wrapper over the ElementRow component that allows drag and drop behavior
 */
const ElementMasks = (props) => {
  const { elements, selectedElements, ElementRowWrapper } = props;

  return (
    <Container display="flex">
      {elements.map((el) => (
        <ElementRowWrapper
          {...props}
          key={el.id}
          isSelected={!!selectedElements.find((e) => e.id === el.id)}
          icon={iconMap.mask}
          element={el}
          style={{ width: '100%' }}
        />
      ))}
    </Container>
  );
};

ElementMasks.displayName = 'ElementMaskRow';

const { arrayOf, element } = PropTypes;

ElementMasks.propTypes = {
  elements: arrayOf(ElementProps).isRequired,
  selectedElements: arrayOf(ElementProps).isRequired,
  ElementRowWrapper: element,
};

export default ElementMasks;
