import React from 'react';
import PropTypes from 'prop-types';
import fetchUtil from '../../util/fetchUtil';

import Box from '../Box';
import Message from '../Message';
import ImageAsset from '../assets/asset-previews/ImageAsset';
import SvgAsset from '../assets/asset-previews/SvgAsset';
import FileDelete from '../assets/controls/FileDelete';
import UploadBox from '../UploadBox';
import FileInfo from '../assets/controls/FileInfo';
import FileVersions from '../assets/controls/FileVersions';

import { getMediaType } from '../../shared/utilities/fileTypes';

function FileInfoDialog(props) {
  const { files, projectID, handleHideDialog, onDelete } = props;

  const handleDelete = async () => {
    await fetchUtil.post('/project/removeFiles', {
      projectID,
      fileIDs: files.map((f) => f.fileID),
    });

    onDelete();
    handleHideDialog();
  };

  const projectFileUploader = (
    <UploadBox
      projectID={projectID}
      types={[files[0].type]}
      allowMultiple={false}
      uploadText="Upload new version"
    />
  );

  let mediaType = '';
  if (files.length === 1) {
    mediaType = getMediaType(files[0].type);
  }

  let preview;
  switch (mediaType) {
    case 'image':
      preview = <ImageAsset file={files[0]} showPreview={false} />;
      break;
    case 'svg':
      preview = <SvgAsset showPreview={false} file={files[0]} />;
      break;
    default:
      preview = (
        <Message style={{ width: '100%' }}>Preview not available</Message>
      );
      break;
  }

  return (
    <div>
      {files.length === 1 && <Box style={{ maxWidth: 300 }}>{preview}</Box>}
      {files.length === 1 && <FileInfo file={files[0]} />}
      {files.length === 1 && (
        <FileVersions file={files[0]} uploader={projectFileUploader} />
      )}
      <FileDelete files={files[0]} onDelete={handleDelete} />
    </div>
  );
}

FileInfoDialog.propTypes = {
  projectID: PropTypes.number.isRequired,
  handleHideDialog: PropTypes.func,
  onDelete: PropTypes.func,
  files: PropTypes.array,
};

FileInfoDialog.defaultProps = {
  handleHideDialog: () => {},
  onDelete: () => {},
  files: [],
};

export default FileInfoDialog;
