import React, { useRef } from 'react';
import Navbar from '../landing/Navbar';
import Hero from '../landing/Hero';
import Box, { Flex } from '../Box';
import CenterBlock from '../landing/CenterBlock';
import Footer from '../landing/Footer';

interface Props {}

const Landing = (props: Props) => {
  const scrollContainerRef = useRef(null);

  return (
    <Flex
      height="100vh"
      overflow="auto"
      flexDirection="column"
      ref={scrollContainerRef}
    >
      <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100%' }}>
        <Navbar containerRef={scrollContainerRef} />
      </Box>
      <Box>
        <Hero />
        <CenterBlock
          heading="Fully featured scene editor"
          subheading="Create and edit text, add images, and draw animatable curves, or import an SVG from your favorite vector software."
          video="https://d3cdo0emj8d2qc.cloudfront.net/assets/zHv33-cWTdy1UloDdC0A3aF_Q5CanexV.mp4"
        />
        <CenterBlock
          heading="Review for each step of video production"
          subheading="Annotate, leave comments, and add tasks for each videos and other
          assets to improve accuracy and efficiency."
          video="https://d3cdo0emj8d2qc.cloudfront.net/assets/yFqimfWUTa_9_DgoQyqWC0HtR4GoqI1I.mp4"
        />
        <CenterBlock
          heading="Browse & Re-Use 25,000+ Digital Assets"
          subheading="Search, tag, and organize your assets to boost productivity and quickly create new content."
          video="https://d3cdo0emj8d2qc.cloudfront.net/assets/nkBZyWizSG63fKYG6LJkeMINRN2RBTl_.mp4"
        />
        <CenterBlock
          heading="Manage all your projects in one place"
          subheading="Store assets, add tasks and due dates, tag users in comments, and automate with Trello."
          video="https://d3cdo0emj8d2qc.cloudfront.net/assets/0DaxsakBQDGH5kZNiTwgZR45Tz6CA1RO.mp4"
        />
        <CenterBlock
          heading="Localize Content through Multi-Language Support,"
          subheading="Store assets, add tasks and due dates, tag users in comments, and automate with Trello."
          video="https://d3cdo0emj8d2qc.cloudfront.net/assets/0DaxsakBQDGH5kZNiTwgZR45Tz6CA1RO.mp4"
        />
        <Footer />
      </Box>
    </Flex>
  );
};

export default Landing;
