import React, { createContext, useReducer } from 'react';

import initialState from './initialState';
import reducer from './reducer';
import { ChildrenProps } from '../props/general';

export const Store = createContext();

export const StoreProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <Store.Provider value={value}>{props.children}</Store.Provider>;
};

StoreProvider.propTypes = {
  children: ChildrenProps,
};
