import { post } from '../../../util/fetchUtil';

import font from '../../font';
import sceneScreenshots from '../sceneScreenshots';

import { save } from '../save';
import { stringToJson, importSvgElements, parseViewBox } from '../svgParser';
import { formatDuration, formatImages } from './Editor';
import { FileObjectProps } from '../../../types/Browser';
import { ElementProps } from '../../../ui/editor/types/ElementProps';
import { SceneProps } from '../../../ui/editor/types/SceneProps';

export type FileImportProps = {
  file: FileObjectProps;
  name: string;
  sceneID?: number;
};

/**
 * Imports an affinity designer SVG file and initializes a scene
 *
 * @param {Object} files - svg files
 * @param {Number} projectID
 * @returns
 */
export const importSvgFiles = async (
  fileImports: FileImportProps[],
  projectID: number,
  setMessage: (newMessage: string) => void,
  setProgress: (newProgress: number) => void
) => {
  const svgs: Promise<string>[] = [];
  const elementArrays: Promise<ElementProps[]>[] = [];
  const json: any[] = [];
  let currentStep = 0;
  const totalSteps = 3 + fileImports.length;

  setMessage('Uploading scene images');

  if (!font.hasLoaded) {
    await font.init();
  }

  fileImports.forEach((fileImport) => {
    // Get svg text and upload the svg
    svgs.push(fileImport.file.text());
  });

  const svgsRes = await Promise.all(svgs);

  currentStep += 1;
  setProgress(currentStep / totalSteps);

  // Parse json and import svg elements
  svgsRes.forEach((str) => {
    json.push(stringToJson(str));
    elementArrays.push(importSvgElements(str));
  });

  let count = 0;
  elementArrays.forEach((promise) => {
    promise.then(() => {
      count += 1;
      currentStep += 1;
      setMessage(`Importing SVGs (${count} / ${fileImports.length})`);
      setProgress(currentStep / totalSteps);
    });
  });

  const jsonRes = await Promise.all(json);
  const elementArraysRes = await Promise.all(elementArrays);

  // Format images for each set
  const formattedImages: Promise<void>[] = [];
  elementArraysRes.forEach((elements) => {
    formattedImages.push(formatImages(elements));
  });

  await Promise.all(formattedImages);

  // Create new scenes
  const newScenes: Promise<{ scene: SceneProps }>[] = [];
  jsonRes.forEach((svg, i) => {
    const duration = formatDuration(elementArraysRes[i]);

    const { width, height } = parseViewBox(svg.attributes.viewBox);

    newScenes.push(
      post('/scene/createProjectScene', {
        projectID,
        sceneID: fileImports[i].sceneID, // Pass sceneID if it exists
        duration,
        name: fileImports[i].name,
        width,
        height,
      })
    );
  });

  const newScenesRes = await Promise.all(newScenes);

  // Save new scene elements
  const sceneSaves: Promise<string>[] = [];
  newScenesRes.forEach((res, i) => {
    // Save new new scene elements
    sceneSaves.push(save('', res.scene, elementArraysRes[i]));
  });

  const sceneSavesRes = await Promise.all(sceneSaves);

  setMessage('Getting thumbnails...');
  // Get screenshots for thumbnails
  const screenshotsRes = await sceneScreenshots(
    sceneSavesRes.map((s3Key, i) => {
      let time = newScenesRes[i].scene.data.duration / 2;
      if (!time || time < 0) time = 0;

      return {
        s3Key,
        duration: newScenesRes[i].scene.data.duration,
        time,
        temp: false,
      };
    })
  );

  currentStep += 1;
  setProgress(currentStep / totalSteps);

  const thumbnailUpdates: Promise<void>[] = [];
  screenshotsRes
    .filter((screenshot: { url: string }) => screenshot)
    .forEach((screenshot: { url: string }, i: number) => {
      thumbnailUpdates.push(
        post('/scene/updateThumbnail', {
          sceneID: newScenesRes[i].scene.sceneID,
          thumbnail: screenshot.url,
        })
      );
    });

  await Promise.all(thumbnailUpdates);

  setProgress(1);

  return fileImports.map((fileImport, i) => ({
    file: fileImport.file,
    svg: svgsRes[i],
    svgJson: jsonRes[i],
  }));
};

export default { importSvgFiles };
