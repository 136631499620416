import React from 'react';

import Box, { Flex } from '../Box';

import { SceneProps } from '../editor/types/SceneProps';
import { CommentProps } from '../editor/types/CommentProps';
import { ActiveViewProps, AnnotationProps, Tools } from './ReviewTypes';
import { TeamProps } from '../editor/types/TeamProps';

import EditorCommentPanel from '../editor/comments/EditorCommentPanel';
import { PanelButton } from '../editor/EditorStyles';
import Spelling from '../comments/Spelling';
import Circle from '../Circle';
import { colors } from '../styles/colors';

type Props = {
  activeView: ActiveViewProps;
  scene: SceneProps;
  currentTime: number;
  comments: CommentProps[];
  activeTool: string;
  newAnnotations: AnnotationProps[];
  team: TeamProps;
  isAnnotating: boolean;
  isPlaying: boolean;
  selectedCommentID?: number;
  misspelledWords: string[];
  savedWords: string[];
  handleSetActiveTool: (tool: Tools) => void;
  handleGetComments: () => void;
  handleClickComment: (comment: CommentProps) => void;
  setSelectedCommentID: (commentID: number) => void;
  setActiveView: (view: ActiveViewProps) => void;
  handleUpdateList: () => void;
  handleSetMisspelledWords: (words: string[]) => void;
};

const options: {
  name: string;
  id: ActiveViewProps;
  icon: string;
}[] = [
  {
    name: 'Comments',
    id: 'comments',
    icon: 'fad fa-comment',
  },
  {
    name: 'Spelling',
    id: 'spelling',
    icon: 'fad fa-spelling',
  },
];

const ReviewView: React.FC<Props> = (props: Props) => {
  const {
    activeView,
    scene,
    currentTime,
    comments,
    activeTool,
    newAnnotations,
    selectedCommentID,
    team,
    isAnnotating,
    isPlaying,
    misspelledWords,
    savedWords,
    handleUpdateList,
    handleGetComments,
    handleClickComment,
    setSelectedCommentID,
    handleSetActiveTool,
    setActiveView,
    handleSetMisspelledWords,
  } = props;

  const handleSelectView = (view: ActiveViewProps) => {
    setActiveView(view);
  };

  return (
    <Box>
      <Flex display="flex">
        {options.map((option) => (
          <PanelButton
            key={option.id}
            onClick={() => handleSelectView(option.id)}
            isActive={option.id === activeView}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {option.name}
            {option.id === 'spelling' && !!misspelledWords.length && (
              <Box ml={10}>
                <Circle
                  size={16}
                  style={{ fontSize: 12, backgroundColor: colors.red }}
                >
                  {misspelledWords.length}
                </Circle>
              </Box>
            )}
          </PanelButton>
        ))}
      </Flex>
      {activeView === 'spelling' && (
        <Spelling
          misspelledWords={misspelledWords}
          savedWords={savedWords}
          handleUpdateList={handleUpdateList}
          handleSetMisspelledWords={handleSetMisspelledWords}
          darkMode={true}
        />
      )}
      {activeView === 'comments' && (
        <EditorCommentPanel
          scene={scene}
          currentTime={currentTime}
          comments={comments}
          activeTool={activeTool}
          annotations={newAnnotations}
          selectedCommentID={selectedCommentID}
          team={team}
          isAnnotating={isAnnotating}
          isPlaying={isPlaying}
          handleGetComments={handleGetComments}
          onClickComment={handleClickComment}
          setSelectedCommentID={setSelectedCommentID}
          setActiveTool={handleSetActiveTool}
        />
      )}
    </Box>
  );
};

export default ReviewView;
