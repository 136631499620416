import styled from 'styled-components/macro';

import React, { useState, useEffect } from 'react';

import { motion, AnimatePresence } from 'framer-motion';

import { post } from '../../util/fetchUtil';

import Message from '../Message';
import { StyledUserTask, CompletedAnimation } from '../views/ProfileStyles';
import { A } from '../styles/typography';
import { colors, lighten } from '../styles/colors';
import Box from '../Box';

const StyledA = styled(A)`
  color: ${colors['light-grey-30']};
  text-decoration: underline;
  font-size: 16px;

  :hover,
  :focus {
    color: ${colors['light-grey-20']};
    text-decoration: underline;
  }
`;

const CompletedTextContainer = styled.div`
  margin-bottom: 10px;
  margin-top: 15px;
`;

const OverflowContainer = styled(motion.div)`
  max-height: ${(props) => props.height || 300}px;
  overflow: auto;
  padding: 10px;
  border-radius: 3px;
  background: ${lighten(colors.grey, 54)};
  border: 1px solid ${lighten(colors.grey, 50)};
`;

function TaskSection() {
  const [userTasks, setUserTasks] = useState([]);
  const [completedVisible, setCompletedVisible] = useState(false);

  const incompleteTasks = userTasks.filter((task) => task.status === 1);

  const getUserTasks = () => {
    post('/tasks/getUserTasks').then((res) => {
      setUserTasks(res.tasks);
    });
  };

  const handleCompleteAll = async () => {
    await post('/tasks/userToggleTasks', {
      status: 2,
      taskIDs: incompleteTasks.map((t) => t.taskID),
    });

    getUserTasks();
  };

  useEffect(() => {
    getUserTasks();
  }, []);

  const incompleteTaskComponents = incompleteTasks.map((task) => (
    <motion.div
      key={task.taskID}
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, height: 0 }}
    >
      <StyledUserTask
        key={task.taskID}
        task={task}
        onAfterSave={getUserTasks}
        showProject
      />
    </motion.div>
  ));

  const completedTasks = userTasks.filter((task) => task.status === 2);

  const completedTaskComponents = completedTasks.map((task) => (
    <CompletedAnimation
      key={task.taskID}
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
    >
      <StyledUserTask task={task} completed onAfterSave={getUserTasks} />
    </CompletedAnimation>
  ));

  return (
    <Box>
      {!!incompleteTaskComponents.length && (
        <Box
          onClick={handleCompleteAll}
          flex
          style={{ marginBottom: 10, justifyContent: 'flex-end' }}
        >
          <StyledA>Mark all as complete</StyledA>
        </Box>
      )}
      <OverflowContainer height={400}>
        <AnimatePresence initial={false}>
          {incompleteTaskComponents}
        </AnimatePresence>
        {incompleteTaskComponents.length === 0 && (
          <Message>
            <span>
              <span role="img" aria-label="Strong">
                💪
              </span>{' '}
              No open tasks
            </span>
          </Message>
        )}
      </OverflowContainer>
      <CompletedTextContainer>
        <StyledA onClick={() => setCompletedVisible(!completedVisible)}>
          {completedVisible ? 'Hide' : 'Show'} completed
        </StyledA>
      </CompletedTextContainer>
      <AnimatePresence initial={false}>
        {completedVisible && (
          <OverflowContainer
            height={175}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
          >
            {completedTaskComponents}
          </OverflowContainer>
        )}
      </AnimatePresence>
    </Box>
  );
}

TaskSection.propTypes = {};

export default TaskSection;
