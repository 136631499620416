import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React from 'react';

import Button from '../Button';
import { colors } from '../styles/colors';
import { H3 } from '../styles/typography';
import Box from '../Box';

const Container = styled.div`
  max-width: 400px;
`;
const Footer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  :not(:last-child) {
    margin-right: 10px;
  }
`;

function WarningDialog(props) {
  const {
    header,
    children,
    confirmText,
    cancelText,
    onConfirm,
    onCancel,
    color,
  } = props;

  return (
    <Container>
      {header && <H3 mb={2}>{header}</H3>}
      <Box mb={2}>{children}</Box>
      <Footer>
        {onCancel && (
          <StyledButton onClick={onCancel}>{cancelText}</StyledButton>
        )}

        {onConfirm && (
          <StyledButton
            onClick={onConfirm}
            color="white"
            backgroundColor={color}
          >
            {confirmText}
          </StyledButton>
        )}
      </Footer>
    </Container>
  );
}

WarningDialog.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  error: PropTypes.bool,
  color: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

WarningDialog.defaultProps = {
  header: '',
  onConfirm: null,
  onCancel: null,
  confirmText: 'Yes',
  cancelText: 'No, cancel',
  error: false,
  color: colors.orange,
};

export default WarningDialog;
