import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '../../Box';

import MediaImages from './MediaImages';
import MediaText from './MediaText';

import ElementProps from '../props/ElementProps';

import { PanelButton, PanelHeader } from '../EditorStyles';

const { arrayOf, func } = PropTypes;

const views = [
  {
    name: 'Images',
    id: 'images',
  },
  {
    name: 'Text',
    id: 'text',
  },
];

/**
 * List out the media items that can be added to the scene
 */
function Media(props) {
  const { elements, handleUpdateElement } = props;

  const [selectedView, setSelectedView] = useState(views[0]);

  return (
    <Box>
      <PanelHeader display="flex">
        {views.map((view) => (
          <PanelButton
            isActive={selectedView.id === view.id}
            onClick={() => setSelectedView(view)}
            key={view.id}
          >
            {view.name}
          </PanelButton>
        ))}
      </PanelHeader>
      {selectedView.id === 'images' && (
        <MediaImages
          elements={elements}
          handleUpdateElement={handleUpdateElement}
        />
      )}
      {selectedView.id === 'text' && (
        <MediaText
          elements={elements}
          handleUpdateElement={handleUpdateElement}
        />
      )}
    </Box>
  );
}

Media.propTypes = {
  elements: arrayOf(ElementProps),
  handleUpdateElement: func,
};

export default Media;
