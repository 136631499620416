import PropTypes from 'prop-types';

const { string, shape, number } = PropTypes;

export default shape({
  sceneID: number,
  modified: string,
  createdOn: string,
  data: shape({
    s3Key: string,
    name: string,
    duration: number,
    notes: string,
  }),
});
