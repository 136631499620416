import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { post } from '../../util/fetchUtil';

import Button from '../Button';
import OptionSelector from '../OptionSelector';

import {
  Container,
  ListContainer,
  HeaderContainer,
  Header,
  SettingsContainer,
  SpellingError,
  SpellingErrorText,
  ButtonContainer,
} from './SpellingStyles';

import SavedSpellingDialog from '../dialogs/SavedSpellingsDialog';
import { colors } from '../styles/colors';
import Message from '../Message';
import useModal from '../../app/hooks/useModal';
import Dialog from '../Dialog';

type Props = {
  misspelledWords: string[];
  savedWords: string[];
  darkMode?: boolean;
  handleUpdateList: () => void;
  handleSetMisspelledWords: (words: string[]) => void;
};

export default function Spelling(props: Props) {
  const {
    misspelledWords,
    savedWords,
    handleUpdateList,
    handleSetMisspelledWords,
    darkMode,
  } = props;

  const savedSpellingsDialog = useModal();

  /**
   * Removes the misspelled words from the list
   *
   * @param {String} word - the word to remove
   * @returns
   * @memberof CommentPanel
   */
  const handleDismissSpelling = (word: string) => {
    // Remove the word from the list and update the misspelled words array
    const updatedMisspelledWords = misspelledWords.filter((w) => w !== word);
    handleSetMisspelledWords(updatedMisspelledWords);
  };

  /**
   * Save the spelling to the database to be ignored later
   *
   * @param {String} word - the word to ignore
   * @returns
   * @memberof CommentPanel
   */
  const handleSaveSpelling = (word: string) => {
    // Add to database
    post('/dictionary/addWord', {
      word,
    }).then(() => {
      // Remove the word(s) from the list
      handleDismissSpelling(word);

      // Update the observable list
      handleUpdateList();
    });
  };

  const filteredWords = misspelledWords.filter(
    (word) => !savedWords.includes(word)
  );

  const spellingErrors = filteredWords.map((word, i) => (
    <SpellingError key={i} darkMode={darkMode}>
      <SpellingErrorText>{word}</SpellingErrorText>
      <ButtonContainer>
        <Button
          onClick={() => handleSaveSpelling(word)}
          thin
          box
          transparent
          color="white"
          style={{ fontSize: 14 }}
        >
          Save
        </Button>
        <Button
          onClick={() => handleDismissSpelling(word)}
          thin
          box
          icon
          style={{ fontSize: 14 }}
          color={colors['light-red-30']}
        >
          <FontAwesomeIcon icon={['fas', 'times']} />
        </Button>
      </ButtonContainer>
    </SpellingError>
  ));

  return (
    <Container>
      <Dialog
        isVisible={savedSpellingsDialog.isVisible}
        handleHideDialog={() => savedSpellingsDialog.hide()}
      >
        <SavedSpellingDialog savedWords={savedWords} />
      </Dialog>

      <HeaderContainer>
        <Header>Spelling Errors</Header>
        <SettingsContainer>
          <OptionSelector
            options={[
              {
                name: 'Edit spelling',
                id: 'edit-spelling',
                onClick: () => savedSpellingsDialog.show(),
              },
            ]}
          >
            <Button icon box color={colors['light-grey-20']}>
              <FontAwesomeIcon icon={['fad', 'cog']} />
            </Button>
          </OptionSelector>
        </SettingsContainer>
      </HeaderContainer>
      <ListContainer>
        {!misspelledWords.length && (
          <Message style={{ width: '100%' }} darkMode>
            <span role="img" aria-label="Hooray">
              🥳
            </span>
            &nbsp;&nbsp;No spelling errors found
          </Message>
        )}
        {spellingErrors}
      </ListContainer>
    </Container>
  );
}
