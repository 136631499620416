import PropTypes from 'prop-types';

export const KitProps = PropTypes.shape({
  data: PropTypes.shape({
    thumbnail: PropTypes.string,
  }),
  kitID: PropTypes.number,
  name: PropTypes.string,
});

export const ActiveFilterProps = PropTypes.shape({
  type: PropTypes.oneOf([
    'projectAssetType',
    'fileType',
    'userInput',
    'folder',
    'fileFilters',
  ]),
  operator: PropTypes.oneOf(['or', 'and']),
  color: PropTypes.string,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
});

export const ActiveFiltersProps = PropTypes.arrayOf(ActiveFilterProps);
