import PropTypes from 'prop-types';
import { CommentProps } from './comments';
import { FileProps } from './files';

const { number, string, shape, arrayOf, bool } = PropTypes;

export const TrelloMemberProps = shape({
  userID: number,
  subscribed: bool,
});

export const TrackingProps = shape({
  data: shape({}),
  projectID: number,
  wordCount: number,
});

export const MemberProps = shape({
  avatar: string,
  familyName: string,
  givenName: string,
  subscribed: bool,
  userID: number,
});

export const WebhookProps = shape({
  name: string,
  webhookId: string,
});

export const ProjectProps = shape({
  comments: arrayOf(CommentProps),
  createdOn: string,
  // credits:
  data: shape({
    members: arrayOf(TrelloMemberProps),
    trello: shape({
      url: string,
      id: string,
      dateLastActivity: string,
      webhooks: arrayOf(WebhookProps),
    }),
  }),
  files: arrayOf(FileProps),
  groups: arrayOf(number),
  hash: string,
  members: arrayOf(MemberProps),
  modified: string,
  name: string,
  projectID: number,
  status: number,
  tracking: TrackingProps,
});
