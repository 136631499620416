import SVG from 'svgjs';
import $ from 'jquery';

import anime from 'animejs/lib/anime.es.js';

import Anim from '../anim';
import nodeSearch from '../nodeSearch';
import drawGroup from './group';

export default function (sceneChild, doc, id, sceneData, dimensions) {
  const type = 'erase';
  const name = 'Erase';

  // Erase path to use
  const path =
    'M123.423,2115.81c0,0 -52.983,-1811.02 49.49,-1958.38c102.473,-147.363 59.755,2024.65 147.98,2035.58c88.225,10.924 57.301,-2053.36 152.43,-2049.28c95.129,4.08 107.988,2024.68 186.749,2027.58c78.761,2.903 40.915,-2053.45 119.289,-2045.58c78.375,7.869 123.469,2064.33 183.655,2067.28c60.185,2.949 40.877,-2103.05 103.179,-2083.14c62.302,19.908 90.235,2092.03 152.787,2083.14c62.551,-8.894 67.369,-2076.53 117.177,-2081.01c91.931,-8.272 51,2064.56 134.42,2081.01c87.884,17.336 57.301,-2053.36 152.43,-2049.28c95.129,4.08 107.988,2024.68 186.749,2027.58c78.761,2.903 40.915,-2053.45 119.29,-2045.58c78.374,7.869 123.468,2064.33 183.654,2067.28c60.185,2.949 40.877,-2103.05 103.179,-2083.14c62.302,19.908 90.235,2092.03 152.787,2083.14c62.552,-8.894 33.914,-2075.01 83.669,-2081.01';

  const elementId = id.toString();

  const draw = SVG(elementId).fill('none');
  draw.viewbox(0, 0, dimensions.w, dimensions.h);

  const erasePath = draw
    .path(path)
    .stroke({
      width: 300,
      color: 'white',
    })
    .attr({
      'stroke-linecap': 'round',
      id: `${elementId}-erase-path`,
    });

  // Center erasePath
  erasePath.transform({
    x: -(erasePath.bbox().w / 2) + dimensions.w / 2,
    y: -(erasePath.bbox().h / 2) + dimensions.h / 2,
  });

  // Default white background to erase
  const eraseBackground = draw.rect(dimensions.w, dimensions.h).fill('white');

  // Create and get contents of "erase" group
  const contentsToErase = drawGroup(
    {
      scene: sceneChild,
      id: elementId,
      type: '',
      dimensions,
    },
    false,
    sceneData,
    doc
  );

  // Extract imageGroup and remove the contentsToErase DOM element
  const imageGroup = contentsToErase.draw.select('g.image-group').members[0];
  contentsToErase.draw.remove();

  // Look for custom mask area
  const eraseArea = nodeSearch.getChildById(imageGroup.node, 'area');
  if (eraseArea) {
    eraseBackground.remove();

    // Remove stroke if it exists
    eraseArea.style.strokeWidth = 0;
    // If the eraseArea does not have a fill, add a white fill
    if (eraseArea.style.fill === 'none') {
      eraseArea.style.fill = 'white';
    }
    // Move area behind all other elements in the group
    $(imageGroup.node).prepend(eraseArea);
  }

  // Create mask and mask eraseBackground
  const eraseMask = draw.mask();
  eraseMask.add(erasePath);
  eraseBackground.maskWith(eraseMask);

  // Add the extracted imageGroup and mask it
  draw.add(imageGroup);
  imageGroup.maskWith(eraseMask);

  // Create animation for erase path
  const animOb = {
    targets: `#${elementId}-erase-path`,
    strokeDashoffset: [anime.setDashoffset, 0],
    autoplay: false,
    duration: 6000,
    easing: 'linear',
  };

  const anim = Anim(animOb);

  return {
    draw,
    anim,
    type,
    name,
  };
}
