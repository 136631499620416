import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

import React from 'react';

import { AnimatePresence } from 'framer-motion';

import FetchAutocomplete from '../../FetchAutocomplete';
import Label from '../../Label';
import Message from '../../Message';
import { colors } from '../../styles/colors';
import { P } from '../../styles/typography';

import { LabelContainer, LabelsContainer, Heading } from '../AssetStyles';
import FileProps from '../../editor/types/FileProps';
import TagProps from '../../../types/TagProps';
import Box from '../../Box';
import {
  AddTagProps,
  CreateTagProps,
  RemoveTagProps,
} from '../../../app/hooks/useFileTagger';

const Container = styled.div`
  padding: 10px;
`;

const StyledLabel = styled(Label)``;

const LabelClose = styled(FontAwesomeIcon)`
  margin-right: 5px;
  opacity: 0.5;

  cursor: pointer;
`;

const CreateTag = styled.div`
  color: ${colors['light-grey-10']};
  display: flex;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const Text = styled(P)`
  color: ${colors['light-grey-10']};
`;

type Props = {
  files: FileProps[];
  userInput: string;
  canEdit?: boolean;
  labelVisible?: boolean;
  setUserInput: (newText: string) => void;
  createTag?: CreateTagProps;
  addTag?: AddTagProps;
  removeTag?: RemoveTagProps;
  onTagClick?: (tag: TagProps) => void;
};

function FileTagger(props: Props) {
  const {
    files,
    canEdit,
    userInput,
    labelVisible = true,
    addTag,
    createTag,
    removeTag,
    setUserInput,
    onTagClick,
  } = props;

  const handleRemoveTagClick = (e: React.MouseEvent, tag: TagProps) => {
    e.stopPropagation();

    removeTag?.(
      tag.tagID,
      files.map((f) => f.fileID)
    );
  };

  const handleCreateTag = async () => {
    const tagID = await createTag?.(userInput);

    if (tagID) {
      addTag?.(
        tagID,
        files.map((f) => f.fileID)
      );
    }
  };

  // Only include tags that every file shares
  const tags: TagProps[] = [];
  const file = files[0];
  if (file?.tags) {
    file.tags.forEach((tag) => {
      if (
        files.every((f) => f.tags && f.tags.find((t) => t.tagID === tag.tagID))
      ) {
        tags.push(tag);
      }
    });
  }

  const tagComponents = tags.map((tag) => (
    <LabelContainer
      initial={{ opacity: 0, x: -10 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -10, transition: { easing: 'easeOut' } }}
      key={tag.tagID}
    >
      <StyledLabel color="teal" onClick={() => onTagClick?.(tag)}>
        {canEdit && (
          <LabelClose
            icon={['fas', 'times']}
            onClick={(e) => handleRemoveTagClick(e, tag)}
          />
        )}
        <span>{tag.name}</span>
      </StyledLabel>
    </LabelContainer>
  ));

  const notFoundComponent = (
    <CreateTag onClick={handleCreateTag}>
      <Icon icon={['fas', 'plus']} />
      <Text>Create new tag &apos;{userInput}&apos;</Text>
    </CreateTag>
  );

  return (
    <Container>
      {labelVisible && (
        <>
          {files.length === 1 && <Heading>Tags</Heading>}
          {files.length > 1 && <Heading>Tags ({files.length} files)</Heading>}
        </>
      )}
      {canEdit && (
        <FetchAutocomplete
          route="tags/searchTags"
          onOptionClick={(option) => {
            if (option.tagID) {
              addTag?.(
                option.tagID,
                files.map((f) => f.fileID)
              );
            }
          }}
          userInput={userInput}
          setUserInput={setUserInput}
          notFoundComponent={notFoundComponent}
          placeholder="Search or add tags..."
        />
      )}
      <LabelsContainer>
        <AnimatePresence>{tagComponents}</AnimatePresence>
      </LabelsContainer>
      {!tags.length && (
        <Box mt={2}>
          <Message>No tags for this asset.</Message>
        </Box>
      )}
    </Container>
  );
}

FileTagger.defaultProps = {
  tags: [],
  files: [],
};

export default FileTagger;
