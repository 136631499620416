import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { MouseEvent } from 'react';
import styled from 'styled-components/macro';

import { AnimatePresence } from 'framer-motion';

import { goToUrl } from '../../../shared/links';

import Label from '../../Label';
import ProjectSearch from '../../ProjectSearch';
import Message from '../../Message';

import { LabelContainer, LabelsContainer, Heading } from '../AssetStyles';
import { ProjectProps } from '../../editor/types/ProjectProps';
import FileProps from '../../editor/types/FileProps';
import {
  AddProjectProps,
  RemoveProjectProps,
} from '../../../app/assets/fileProjects';

const Container = styled.div`
  padding: 10px;
`;

const StyledLabel = styled(Label)``;

const LabelClose = styled(FontAwesomeIcon)`
  margin-right: 5px;
  opacity: 0.5;

  cursor: pointer;
`;

type Props = {
  files: FileProps[];
  addProject: AddProjectProps;
  removeProject: RemoveProjectProps;
  canEdit?: boolean;
};

function FileProjects(props: Props) {
  const { files, addProject, removeProject, canEdit } = props;

  const handleRemoveProject = (e: MouseEvent, project: ProjectProps) => {
    e.stopPropagation();

    removeProject(
      project.projectID,
      files.map((f) => f.fileID)
    );
  };

  // Only display projects shared by every selected files
  const projects: ProjectProps[] = [];
  const fileData = files[0];
  if (fileData) {
    fileData.projects.forEach((project) => {
      if (
        files.every((file) =>
          file.projects.find((p) => p.projectID === project.projectID)
        )
      ) {
        projects.push(project);
      }
    });
  }

  const projectLabels = projects.map((project) => (
    <LabelContainer
      key={project.projectID}
      initial={{ opacity: 0, x: -10 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -10, transition: { easing: 'easeOut' } }}
    >
      <StyledLabel
        color="olive"
        onClick={() =>
          goToUrl({
            view: 'projectContents',
            projectID: project.projectID,
            newWindow: true,
          })
        }
      >
        <LabelClose
          icon={['fas', 'times']}
          onClick={(e) => handleRemoveProject(e, project)}
        />
        <span>{project.name}</span>
      </StyledLabel>
    </LabelContainer>
  ));

  return (
    <Container>
      {files.length === 1 && <Heading>Projects</Heading>}
      {files.length > 1 && <Heading>Projects ({files.length} files)</Heading>}

      {canEdit && (
        <ProjectSearch
          onItemSelect={(project) =>
            addProject(
              project.projectID,
              files.map((f) => f.fileID)
            )
          }
          placeholder="Search projects to add this file to..."
        />
      )}

      <LabelsContainer>
        <AnimatePresence>{projectLabels}</AnimatePresence>
      </LabelsContainer>

      {projects.length === 0 && (
        <Message style={{ marginTop: 10 }}>
          No projects associated with this asset.
        </Message>
      )}
    </Container>
  );
}

FileProjects.defaultProps = {
  files: [],
  canEdit: true,
};

export default FileProjects;
