import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import { colors, lighten } from './styles/colors';
import elevations from './styles/elevations';

const Container = styled.div``;

const Checkbox = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;

const labelPadding = 6;

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: ${(props) => props.size * 2.5}px;
  height: ${(props) => props.size}px;

  background: ${colors['light-grey-50']};
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;

  :hover,
  :focus {
    background: ${lighten(colors.grey, 45)};
  }

  ${(props) =>
    props.isChecked &&
    css`
      background: ${props.color};
      :hover,
      :focus {
        background: ${props.color};
      }
    `}
`;

const stroke = 2;
const LabelButton = styled.span`
  content: '';
  position: absolute;
  top: ${(props) => -0.25 * props.size - stroke}px;
  right: ${-labelPadding}px;
  width: ${(props) => props.size * 1.5}px;
  height: ${(props) => props.size * 1.5}px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: ${elevations[3]};
  border: ${stroke}px solid transparent;

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${colors['light-grey-10']};

  ${(props) =>
    props.isChecked &&
    css`
    color: ${props.color}
    left: calc(100% + ${labelPadding}px);
    transform: translateX(-100%);
    border: ${stroke}px solid ${props.color};
  `}
`;

const ToggleInput = ({
  className,
  isOn,
  handleToggle,
  size,
  onIcon,
  offIcon,
  color,
}) => (
  <Container className={className}>
    <Label size={size} isChecked={isOn} color={color}>
      <Checkbox type="checkbox" onChange={handleToggle} />
      <LabelButton size={size} isChecked={isOn} color={color}>
        {isOn ? onIcon : offIcon}
      </LabelButton>
    </Label>
  </Container>
);

ToggleInput.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  isOn: PropTypes.bool.isRequired,
  size: PropTypes.number,
  onIcon: PropTypes.element,
  offIcon: PropTypes.element,
  color: PropTypes.string,
  className: PropTypes.string,
};

ToggleInput.defaultProps = {
  size: 20,
  onIcon: null,
  offIcon: null,
  color: colors.blue,
  className: '',
};

export default ToggleInput;
