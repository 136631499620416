import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components/macro';

import fetchUtil from '../../util/fetchUtil';

import uuid from '../../app/utilities/uuid';

import useDebounce from '../../app/hooks/useDebounce';
import { TextArea } from '../Input';
import { colors, lighten, darken } from '../styles/colors';
import Box, { Flex } from '../Box';

const LeftContainer = styled(Box)`
  flex: 1;
  padding: 20px;
  border-right: 1px solid ${colors['light-grey-50']};
  overflow: auto;

  background-color: ${lighten(colors.grey, 60)};
`;

const pop = keyframes`
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
`;

const RuleText = styled.span`
  color: ${colors.grey};
`;

const NegativeHighlight = styled.div`
  display: inline-block;
  padding: 1px 3px;
  background-color: ${lighten(colors.red, 42)};
  color: ${colors['dark-red-10']};
  border-radius: 3px;
  margin: 1px 2px;

  ${(props) =>
    props.severity === 0 &&
    css`
      background-color: ${lighten(colors.yellow, 35)};
      color: ${colors['dark-yellow-30']};
    `}

  ${(props) =>
    props.severity === 1 &&
    css`
      background-color: ${lighten(colors.orange, 40)};
      color: ${colors['dark-orange-20']};
    `}


  ${(props) =>
    props.isHighlighted &&
    css`
      animation: ${pop} 0.2s ease-in-out;
      background-color: ${colors.red};
      color: white;

      ${props.severity === 0 &&
      css`
        background-color: ${colors.yellow};
        color: ${darken(colors.yellow, 50)};
      `}

      ${props.severity === 1 &&
      css`
        background-color: ${colors.orange};
      `}
    `}
`;

const NegativeWord = styled.div`
  display: inline-block;
  padding: 1px 3px;
  background-color: ${lighten(colors.red, 35)};
  color: ${colors['dark-red-20']};
  border-radius: 3px;
  margin: 1px 2px;

  ${(props) =>
    props.severity === 0 &&
    css`
      background-color: ${lighten(colors.yellow, 35)};
      color: ${colors['dark-yellow-30']};
    `}

  ${(props) =>
    props.severity === 1 &&
    css`
      background-color: ${lighten(colors.orange, 40)};
      color: ${colors['dark-orange-20']};
    `}
`;

const PositiveWord = styled(NegativeWord)`
  background-color: ${lighten(colors.green, 40)};
  color: ${colors['dark-green-10']};
`;

const RuleContainer = styled(Box)`
  padding: 10px;
  background-color: ${lighten(colors.grey, 55)};
  border-left: 2px solid ${lighten(colors.grey, 40)};
  border-radius: 2px;

  cursor: pointer;

  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${lighten(colors.blue, 45)};
      border-left: 2px solid ${lighten(colors.blue, 20)};
    `}

  ${(props) =>
    !props.isSelected &&
    css`
      &:hover,
      &:focus {
        background-color: ${lighten(colors.grey, 53)};
      }
      &:active {
        background-color: ${lighten(colors.grey, 50)};
      }
    `}
`;

const Rule = (props) => {
  const { rule, selectedRule, setSelectedRule } = props;

  const message = [];
  const negativeMatches = [];
  let { actual } = rule;
  if (typeof actual === 'string') {
    actual = [rule.actual];
  }

  if (Array.isArray(actual)) {
    actual.forEach((a) => {
      negativeMatches.push(
        ...[...rule.message.matchAll(new RegExp(`\`${a}\``, 'gi'))].map(
          (m) => ({
            ...m,
            type: 'actual',
          })
        )
      );
    });
  }

  const positiveMatches = [];

  let { expected } = rule;
  if (typeof expected === 'string') {
    expected = [rule.expected];
  }

  if (Array.isArray(expected)) {
    expected.forEach((ex) => {
      positiveMatches.push(
        ...[
          ...rule.message.matchAll(new RegExp(`\`${ex}\``, 'gi')),
        ].map((m) => ({ ...m, type: 'expected' }))
      );
    });
  }

  const matches = [...negativeMatches, ...positiveMatches].sort(
    (a, b) => a.index - b.index
  );

  let currentIndex = 0;
  matches.forEach((match, i) => {
    message.push(
      <RuleText>{rule.message.slice(currentIndex, match.index)}</RuleText>
    );
    if (match.type === 'actual')
      message.push(
        <NegativeWord severity={rule.profanitySeverity}>
          {match[0]}
        </NegativeWord>
      );
    else if (match.type === 'expected')
      message.push(<PositiveWord>{match[0]}</PositiveWord>);

    currentIndex = match.index + match[0].length;
    if (i + 1 === matches.length) {
      message.push(
        <RuleText>
          {rule.message.slice(currentIndex, rule.message.length)}
        </RuleText>
      );
    }
  });

  return (
    <RuleContainer
      onClick={() => setSelectedRule(rule)}
      style={{ marginBottom: 15 }}
      isSelected={selectedRule.id === rule.id}
    >
      {message}
    </RuleContainer>
  );
};

const RuleProps = PropTypes.shape({
  actual: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  expected: PropTypes.arrayOf(PropTypes.string),
  message: PropTypes.string,
  id: PropTypes.string,
});

Rule.propTypes = {
  selectedRule: RuleProps,
  rule: RuleProps.isRequired,
  setSelectedRule: PropTypes.func.isRequired,
};

Rule.defaultProps = {
  selectedRule: {},
};

const Word = (props) => {
  const { word, selectedRule = {}, severity } = props;

  let isHighlighted = false;
  let actual = selectedRule.actual || [];
  if (actual && typeof actual === 'string') {
    actual = [actual];
  }

  isHighlighted = actual.some((a) => a === word);

  return (
    <NegativeHighlight severity={severity} isHighlighted={isHighlighted}>
      {word}
    </NegativeHighlight>
  );
};

Word.propTypes = {
  word: PropTypes.string,
  severity: PropTypes.number,
  selectedRule: RuleProps,
};

const TextChecker = () => {
  const [text, setText] = useState('');
  const debouncedText = useDebounce(text, 250);
  const [selectedRule, setSelectedRule] = useState();
  const [rules, setRules] = useState([]);

  const handleCheckText = async () => {
    const res = await fetchUtil.post('/checkTextAlex', {
      text: debouncedText,
    });

    setRules(
      res.rules.map((rule) => ({
        ...rule,
        id: uuid(),
      }))
    );
  };

  useEffect(() => {
    handleCheckText();
    // eslint-disable-next-line
  }, [debouncedText]);

  let currentIndex = 0;
  const textComponents = [];
  rules.forEach((rule, i) => {
    textComponents.push(
      <RuleText>
        {debouncedText.slice(currentIndex, rule.location.start.offset)}
      </RuleText>
    );

    const word = debouncedText.slice(
      rule.location.start.offset,
      rule.location.end.offset
    );
    textComponents.push(
      <Word
        selectedRule={selectedRule}
        severity={rule.profanitySeverity}
        word={word}
      />
    );

    currentIndex = rule.location.start.offset + word.length;
    if (i + 1 === rules.length) {
      textComponents.push(
        <RuleText>
          {debouncedText.slice(currentIndex, debouncedText.length)}
        </RuleText>
      );
    }
  });

  if (rules.length === 0) {
    textComponents.push(<RuleText>{debouncedText}</RuleText>);
  }

  const ruleComponents = rules
    .reduce((unique, item) => {
      if (!unique.find((u) => u.message === item.message))
        return [...unique, item];
      return unique;
    }, [])
    .map((rule, i) => (
      <Rule
        selectedRule={selectedRule}
        setSelectedRule={setSelectedRule}
        rule={rule}
        key={i}
      />
    ));

  return (
    <Box>
      <Flex style={{ height: '100vh' }}>
        <LeftContainer>
          <TextArea
            placeholder="Check text for non-inclusive or offensive language..."
            onChange={(e) => setText(e.target.value)}
            value={text}
            style={{ width: '100%', marginBottom: 10 }}
          />
          <Box style={{ marginTop: 20 }}>{ruleComponents}</Box>
        </LeftContainer>
        <Box
          style={{
            flex: 4,
            padding: 20,
            whiteSpace: 'pre-wrap',
            overflow: 'auto',
          }}
        >
          {textComponents}
        </Box>
      </Flex>
    </Box>
  );
};

export default TextChecker;
