import styled from 'styled-components/macro';

import React, { useRef, useEffect, useState } from 'react';

import { motion, AnimatePresence } from 'framer-motion';

import { useLocation } from 'react-router-dom';
import { post } from '../../util/fetchUtil';
import useModal from '../../app/hooks/useModal';
import Button from '../Button';

import themeColors from '../../styles/themeColors';
import { P, H2 } from '../styles/typography';
import Dialog from '../Dialog';
import WarningDialog from '../dialogs/WarningDialog';
import { Flex } from '../Box';

const AnimationContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;

  opacity: 0.25;
`;

const messages = [
  {
    header: 'Greetings',
    text: "Indeed, you'll have to sign in.",
    image:
      'https://osmosify-src.s3.amazonaws.com/assets/Swthhmj6RtGffDmDMw4Qbh8BSeSKj6Up.png',
  },
  {
    header: 'Yeeeeeeehaa!',
    text: "You're gonna have to sign in, partner.",
    image:
      'https://osmosify-src.s3.amazonaws.com/assets/s-kQh-x9TyCsymmLwkznb9DQSmy8yz2L.png',
  },
  {
    header: 'Heyo!',
    text: "Sorry, you'll have to sign in for legal porpoises.",
    image:
      'https://osmosify-src.s3.amazonaws.com/assets/9jVs8Yz3QgywPD5jaGlHO8sWQjaihqZt.png',
  },
  {
    header: 'Laugh it up, fuzzball!',
    text: 'But seriously, you gotta sign in.',
    image:
      'https://osmosify-src.s3.amazonaws.com/assets/UUOQEbQrR0y6rM5stXa94eZQTPeRjGgU.png',
  },
  {
    header: 'Great scott!',
    text: "Sign in...because where you're going, you won't need passwords.",
    image:
      'https://osmosify-src.s3.amazonaws.com/assets/gZltCzXkSyS-wdWDeI_jM354RpyAyrko.png',
  },
  {
    header: 'This is fine.',
    text: "Everything's fine, but maybe just sign in first.",
    image:
      'https://osmosify-src.s3.amazonaws.com/assets/il2jS8_TQR2oMMctc2FX_EDdTxiNLboL.png',
  },
  {
    header: 'Party time!',
    text: "It's cool to sign in.",
    image:
      'https://osmosify-src.s3.amazonaws.com/assets/Cmto1evnT0izsf6XcSuE6QFbSGyY9xEE.png',
  },
];

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
`;

const Container = styled(motion.div)`
  padding: 30px;
  border-radius: 6px;

  border: 1px solid ${themeColors['light-2']};

  width: 350px;

  position: relative;
  z-index: 1;
`;

const Message = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Image = styled.img`
  max-width: 250px;
`;

const MessageContainer = styled.div``;
const MessageHeading = styled(H2)`
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
`;

const MessageText = styled(P)`
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
`;

const SignInButton = styled(Button)`
  padding: 5px 20px;

  display: flex;
  align-items: center;
  width: 100%;
`;

const SignInImage = styled.div`
  width: 20px;
  margin-right: 10px;
  img {
    width: 100%;
  }
`;

const Login = () => {
  const googleAuth = useRef();

  const [errorMessage, setErrorMessage] = useState('');
  const message = messages[Math.floor(Math.random() * messages.length)];

  const location = useLocation();
  const modal = useModal();

  const loadGoogleApi = (clientId) =>
    new Promise((resolve, reject) => {
      window.gapi.load('auth2', () => {
        googleAuth.current = window.gapi.auth2.init({
          client_id: clientId,
          onInit() {
            resolve();
          },
          onError(err) {
            // setIsLoading(false);
            console.error(err);
            reject();
          },
        });
      });
    });

  const init = async () => {
    const res = await post('/getGoogleClientID');
    await loadGoogleApi(res.googleClientID);
  };

  useEffect(() => {
    if (window.gapi && !googleAuth.current) {
      init();
    }
    // eslint-disable-next-line
  }, [window.gapi]);

  const verifySignIn = (googleUser) => {
    // The ID token you need to pass to your backend:
    const idToken = googleUser.getAuthResponse().id_token;

    post(
      '/auth/google',
      {
        idToken,
      },
      {
        suppressError: true,
      }
    ).then((res) => {
      if (res.error) {
        console.error(res.error);
        setErrorMessage(res.error);
        modal.show();
      } else {
        // Check for redirect query parameter and redirect if it exists
        const query = new URLSearchParams(location.search);
        const redirect = query.get('redirect');
        window.location.href = redirect || '/';
      }
    });
  };

  /**
   * Use google authorization to get the signed in user on the front end
   *
   * @memberof login
   */
  const authorizeGoogleClient = () => {
    googleAuth.current.signIn().then((googleUser) => verifySignIn(googleUser));
  };

  /**
   * Use Elsevier Auth0 to sign in the user
   */
  const handleSSOSignIn = async () => {
    window.location.href = '/auth/initialize?connection=ElsevierWAAD';
  };

  return (
    <Wrapper>
      <Dialog isVisible={modal.isVisible} handleHideDialog={modal.hide}>
        <WarningDialog header="Uh oh, something went wrong">
          {errorMessage}
        </WarningDialog>
      </Dialog>
      <AnimationContainer></AnimationContainer>
      <AnimatePresence>
        <Container
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 20, opacity: 0 }}
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            maxWidth="300px"
            width="100%"
            sx={{ img: { width: '100%', p: 2 } }}
          >
            <img alt="logo" src="/images/Logo.png" />
          </Flex>
          <Message>
            <Image src={message.image} />
            <MessageContainer>
              <MessageHeading>{message.header}</MessageHeading>
              <MessageText>{message.text}</MessageText>
            </MessageContainer>
          </Message>
          <ButtonContainer>
            <SignInButton onClick={handleSSOSignIn} box basic>
              <SignInImage>
                <img
                  alt="Elsevier"
                  src="https://www.osmosis.org/_next/static/media/nonsolus-elsevier.8314496d.svg"
                />
              </SignInImage>
              <span style={{ fontSize: 20, fontWeight: 'bold' }}>Sign in</span>
            </SignInButton>
            <SignInButton onClick={authorizeGoogleClient} box basic>
              <SignInImage>
                <img
                  alt="Google"
                  src="https://d3cdo0emj8d2qc.cloudfront.net/assets/22d292b7e779addb6e8832edfe68998d27d85746.png"
                />
              </SignInImage>
              <span style={{ fontSize: 20, fontWeight: 'bold' }}>Sign in</span>
            </SignInButton>
          </ButtonContainer>
        </Container>
      </AnimatePresence>
    </Wrapper>
  );
};

export default Login;
