import React from 'react';
import { connectHits } from 'react-instantsearch-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';

import { Box, Flex } from '../../ui/Box';
import ProjectCard from './ProjectCard';
import { IconContainer } from './ProjectCardStyles';

export type ProjectHitProps = {
  name: string;
  id: string;
  createdOn: string;
  thumbnail: string;
  types: ['audio' | 'video' | 'image'];
  comments: number;
  status: 0 | 1 | 2;
  members: {
    avatar: string;
    familyName: string;
    givenName: string;
    owner: boolean;
    subscribed: boolean;
    userID: number;
  }[];
};

interface Props {
  hits: ProjectHitProps[];
  handleProjectDragStart: () => void;
}

const Hits = (props: Props) => {
  const { hits, handleProjectDragStart } = props;
  const projectHits = hits;

  return (
    <Flex flexWrap="wrap">
      {projectHits.map((project: ProjectHitProps) => (
        <Box key={project.id}>
          <ProjectCard
            projectID={parseInt(project.id, 10)}
            project={project}
            onProjectSelectClick={() => {}}
            handleProjectDragStart={handleProjectDragStart}
            icons={[
              ...project.types.map((type) => (
                <IconContainer key={type}>
                  <FontAwesomeIcon icon={['fad', `file-${type}` as IconName]} />
                </IconContainer>
              )),
              ...(project.comments > 0
                ? [
                    <IconContainer key="comments">
                      <FontAwesomeIcon icon={['fad', 'comment-exclamation']} />
                    </IconContainer>,
                  ]
                : []),
            ]}
            thumbnail={project.thumbnail}
          />
        </Box>
      ))}
    </Flex>
  );
};

const RecipeHits = connectHits(Hits as any); // NOTE: issue with custom props in Hit component

export default RecipeHits;
