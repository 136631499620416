import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import setClipboard from '../../app/copyToClipboard';
import Box, { Flex } from '../Box';
import Snackbar from '../Snackbar';
import { colors } from '../styles/colors';
import { P } from '../styles/typography';

const brandColors = [
  {
    name: 'Wisteria',
    hex: '#a373c1',
  },
  {
    name: 'Studio',
    hex: '#8b51b0',
  },
  {
    name: 'Japonica',
    hex: '#d56b6c',
  },
  {
    name: 'Mojo',
    hex: '#c94546',
  },
  {
    name: 'Charm',
    hex: '#d36987',
  },
  {
    name: 'Hibiscus',
    hex: '#c64268',
  },
  {
    name: 'Tuscany',
    hex: '#ce692f',
  },
  {
    name: 'Desert',
    hex: '#a85221',
  },
  {
    name: 'Mandalay',
    hex: '#a87f1c',
  },
  {
    name: 'Apple',
    hex: '#409e40',
  },
  {
    name: 'Goblin',
    hex: '#327932',
  },
  {
    name: 'Jungle Green',
    hex: '#269d7b',
  },
  {
    name: 'Eucalyptus',
    hex: '#1e735a',
  },
  {
    name: 'Eastern Blue',
    hex: '#2a94b8',
  },
  {
    name: 'Jelly Bean',
    hex: '#22728c',
  },
  {
    name: 'Havelock Blue',
    hex: '#508cd7',
  },
  {
    name: 'Mariner',
    hex: '#2b72cd',
  },
  {
    name: 'Dove gray',
    hex: '#727272',
  },
  {
    name: 'Emperor',
    hex: '#555555',
  },
];

const Color = styled(Box)`
  border-radius: 5px;
  height: 150px;
  width: 150px;
  margin: 15px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;

  padding: 5px;

  cursor: pointer;
`;

export default function BrandColors() {
  const [snackbarVisible, setSnackbarVisible] = useState('');

  useEffect(() => {
    if (snackbarVisible) {
      setTimeout(() => {
        setSnackbarVisible('');
      }, 3000);
    }
  }, [snackbarVisible]);

  const handleClickColor = (hex: string) => {
    setClipboard(hex);
    setSnackbarVisible(`Copied ${hex} to clipboard`);
  };

  return (
    <Flex flexWrap="wrap">
      <Snackbar
        isVisible={!!snackbarVisible}
        style={{ background: colors.night }}
      >
        <Box p="20px 25px">
          <P color="white">{snackbarVisible}</P>
        </Box>
      </Snackbar>
      {brandColors.map((color) => (
        <Color
          key={color.hex}
          style={{ backgroundColor: color.hex }}
          onClick={() => handleClickColor(color.hex)}
        >
          <P color="white">{color.name}</P>
          <P color="white">{color.hex}</P>
        </Color>
      ))}
    </Flex>
  );
}
