import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useRef } from 'react';

import { colors, fade } from '../styles/colors';
import NotificationRow from './NotificationRow';
import useOutsideClick from '../../app/hooks/useClickOutside';
import Button from '../RebassButton';

export default function Notifications(props) {
  const {
    notifications,
    allNotifications,
    fetchNotificationData,
    handleUpdateStatus,
    toggleVisible,
  } = props;

  const containerRef = useRef();

  useOutsideClick(containerRef, () => {
    toggleVisible();
  });

  const handleMarkAllAsRead = () => {
    handleUpdateStatus(
      notifications.map((notification) => notification.notificationID),
      1
    );
  };

  const remainingNotifications = allNotifications.filter(
    (notif) =>
      !notifications.find((n) => n.notificationID === notif.notificationID)
  );

  const handleLoadMore = () => {
    fetchNotificationData(
      allNotifications
        .slice(0, notifications.length + 5)
        .map((n) => n.notificationID)
    );
  };

  const notificationComponents = notifications
    .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
    .map((notification) => {
      const { data } = notification;

      return (
        <NotificationRow
          key={notification.notificationID}
          notificationID={notification.notificationID}
          status={notification.status}
          sendingUserName={data.user && data.user.givenName}
          sendingUserAvatar={data.user && data.user.avatar}
          projectName={data.project.name}
          projectID={data.project.projectID}
          action={data.action}
          actionText={data.actionText}
          timestamp={notification.createdOn}
          data={data}
          handleUpdateStatus={handleUpdateStatus}
        />
      );
    });

  return (
    // Stop notifications container from closing on click
    <Container ref={containerRef} onClick={(e) => e.stopPropagation()}>
      {!!notifications.length && (
        <NotificationsHeader>
          <div />
          <FlexContainer>
            <Action onClick={handleMarkAllAsRead}>Mark all as read</Action>
          </FlexContainer>
        </NotificationsHeader>
      )}
      <NotificationsContent>
        {notificationComponents}
        {!!remainingNotifications.length && (
          <Button
            width={'100%'}
            variant="transparent"
            color="light-6"
            onClick={handleLoadMore}
          >
            Load more ({remainingNotifications.length})
          </Button>
        )}
        {!notifications.length && (
          <EmptyMessage>You don&apos;t have any notifications.</EmptyMessage>
        )}
      </NotificationsContent>
    </Container>
  );
}

const EmptyMessage = styled.p`
  text-align: center;
  margin: 0;

  color: white;
  opacity: 0.35;
`;

const Container = styled.div`
  right: 0;
  top: 30px;

  border-radius: 3px;

  background-color: ${colors.night};
  z-index: 1001;

  position: absolute;

  padding: 10px;

  min-width: 200px;
`;

const NotificationsContent = styled.div`
  max-height: 400px;
  overflow: auto;
`;

const NotificationsHeader = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 10px;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const Action = styled.p`
  margin: 0;
  color: ${fade('white', 40)};

  cursor: pointer;

  :hover,
  :focus {
    color: ${fade('white', 30)};
  }

  :active {
    color: ${fade('white', 20)};
  }
`;

Notifications.propTypes = {
  handleUpdateStatus: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
  toggleVisible: PropTypes.func,
};

Notifications.defaultProps = {
  toggleVisible: () => {},
};
