import styled from 'styled-components/macro';

import { colors, lighten } from '../styles/colors';
import { TextInput } from '../Input';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
`;
export const InputContainer = styled.div`
  margin-bottom: 15px;
`;
export const StyledInput = styled(TextInput)`
  width: 100%;
  margin-bottom: 15px;
`;
export const SidebarFilter = styled.div`
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 5px;

  cursor: pointer;

  background: ${(props) => (props.isActive ? colors['light-blue-40'] : '')};

  &:hover,
  &:focus {
    background: ${(props) =>
      props.isActive ? colors['light-blue-40'] : colors['light-grey-50']};
  }
`;
export const SidebarFilterTitle = styled.h3`
  margin: 0;

  color: ${(props) => (props.isActive ? colors['dark-blue-10'] : '')};
`;
export const LowerContent = styled.div``;
export const UpperContent = styled.div``;
export const Link = styled.a`
  cursor: pointer;

  color: ${colors['light-grey-20']};

  :hover,
  :focus {
    color: ${colors['light-grey-10']};
  }

  :active {
    color: ${colors.grey};
  }
`;
export const ProjectFolders = styled.div`
  margin-top: 30px;
`;
export const SubHeader = styled.h3`
  margin: 0;
  margin-bottom: 10px;
  color: ${lighten(colors.grey, 20)};
`;
