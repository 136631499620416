import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import AppRouter from './AppRouter';
import { Container } from './AppStyles';
import theme from '../styles/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Router>
          <AppRouter />
        </Router>
      </Container>
    </ThemeProvider>
  );
}

App.propTypes = {};

App.defaultProps = {};

export default App;
