import React from 'react';
import { Box, BoxProps } from 'rebass/styled-components';

export default function SnackbarContainer({ children, sx }: BoxProps) {
  return (
    <Box
      sx={{
        px: 15,
        py: 10,
        borderRadius: '4px',
        bg: 'dark-3',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
