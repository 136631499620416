import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { post } from '../../util/fetchUtil';

import Button from '../Button';

import { colors, lighten, darken } from '../styles/colors';

function ProjectGroup(props) {
  const { handleToggleDialog, onAfterSave } = props;

  const [groupName, setGroupName] = useState('');
  const [showError, setShowError] = useState(false);

  const saveNewProjectFolder = () => {
    post('/admin/folders/createGroup', {
      name: groupName,
    }).then((res) => {
      if (!res.projectGroup) {
        setShowError(true);
      } else {
        onAfterSave();
      }
    });
  };

  const handleReviveFolder = () => {
    post('/admin/folders/reviveGroupByName', {
      groupName,
    }).then(() => {
      post('/admin/getAllProjectGroups').then(() => onAfterSave());
    });
  };

  return (
    <Container>
      <Header>Add project folder</Header>
      <Body>
        <InputContainer className="text-input">
          <InputLabel>Folder name</InputLabel>
          <input
            onChange={(e) => setGroupName(e.target.value)}
            value={groupName}
            placeholder="My project folder..."
            type="text"
          />
        </InputContainer>
        {showError && (
          <ErrorState>
            <ErrorText>
              Oh oh, that folder already exists, but was deleted.
            </ErrorText>
            <CenterContainer>
              <Button
                className="red basic box left-icon"
                onClick={handleReviveFolder}
              >
                <FontAwesomeIcon icon={['fad', 'feather']} />
                Revive
              </Button>
            </CenterContainer>
          </ErrorState>
        )}
      </Body>
      <Footer>
        <FooterButton
          className="box green thin"
          onClick={saveNewProjectFolder}
          enable={groupName.length > 0}
        >
          Save
        </FooterButton>
        <FooterButton className="box thin" onClick={handleToggleDialog}>
          Cancel
        </FooterButton>
      </Footer>
    </Container>
  );
}

const Container = styled.div``;

const Header = styled.h2`
  margin: 0;
  margin-bottom: 20px;
`;

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Body = styled.div`
  margin-bottom: 20px;
`;
const Footer = styled.div``;

const FooterButton = styled(Button)`
  margin-right: 10px;
`;

const InputLabel = styled.h4`
  margin: 0;
  margin-bottom: 10px;
`;

const InputContainer = styled.div``;

const ErrorText = styled.div`
  margin-bottom: 10px;
`;

const ErrorState = styled.div`
  background: ${lighten(colors.red, 40)};
  color: ${darken(colors.red, 10)};
  margin-top: 10px;

  padding: 15px;
  border-radius: 5px;

  max-width: 300px;
`;

ProjectGroup.propTypes = {
  handleToggleDialog: PropTypes.func.isRequired,
  onAfterSave: PropTypes.func.isRequired,
};

ProjectGroup.defaultProps = {};

export default ProjectGroup;
