import { dom, library } from '@fortawesome/fontawesome-svg-core';
// import the library
import {
  faChevronDown as faChevronDownRegular,
  faPlus as faPlusRegular,
  faTimes as faTimesRegular,
  faPause as faPauseRegular,
} from '@fortawesome/pro-regular-svg-icons';

import { faTrello } from '@fortawesome/free-brands-svg-icons';

import {
  faCheckCircle as faCheckCircleLight,
  faPlus as faPlusLight,
} from '@fortawesome/pro-light-svg-icons';
// Import duotone
import {
  faCloudUploadAlt as faCloudUploadAltDuotone,
  faLightbulb as faLightbulbDuotone,
  faStream as faStreamDuotone,
  faCommentEdit as faCommentEditDuotone,
  faInfoCircle as faInfoCircleDuotone,
  faSquare as faSquareDuotone,
  faDog as faDogDuotone,
  faScalpelPath as faScalpelPathDuotone,
  faStop as faStopDuotone,
  faVolumeUp as faVolumeuDuotone,
  faMinusCircle as faMinusCircleDuotone,
  faPlusCircle as faPlusCircleDuotone,
  faPause as faPauseDuotone,
  faNarwhal as faNarwhalDuotone,
  faText as faTextDuotone,
  faArchive as faArchiveDuotone,
  faCheck as faCheckDuotone,
  faComment as faCommentDuotone,
  faListUl as faListUlDuotone,
  faListOl as faListOlDuotone,
  faBold as faBoldDuotone,
  faItalic as faItalicDuotone,
  faUnderline as faUnderlineDuotone,
  faEraser as faEraserDuotone,
  faScroll as faScrollDuotone,
  faPencilPaintbrush as faPencilPaintbrushDuotone,
  faMicrophoneAlt as faMicrophoneAltDuotone,
  faMicrophoneAltSlash as faMicrophoneAltSlashDuotone,
  faPhotoVideo as faPhotoVideoDuotone,
  faCalendar as faCalendarDuotone,
  faCalendarExclamation as faCalendarExclamationDuotone,
  faFileUpload as faFileUploadDuotone,
  faHighlighter as faHighlighterDuotone,
  faDrawSquare as faDrawSquareDuotone,
  faBezierCurve as faBezierCurveDuotone,
  faRectangleLandscape as faRectangleLandscapeDuotone,
  faImage as faImageDuotone,
  faMask as faMaskDuotone,
  faLock as faLockDuotone,
  faTag as faTagDuotone,
  faTasks as faTasksDuotone,
  faFolders as faFoldersDuotone,
  faFile as faFileDuotone,
  faFileExport as faFileExportDuotone,
  faChartBar as faChartBarDuotone,
  faUsers as faUsersDuotone,
  faXRay as faXRayDuotone,
  faFileChartLine as faFileChartLineDuotone,
  faBug as faBugDuotone,
  faExclamationCircle as faExclamationCircleDuotone,
  faRocket as faRocketDuotone,
  faEnvelope as faEnvelopeDuotone,
  faFeather as faFeatherDuotone,
  faTrashAlt as faTrashAltDuotone,
  faFolderPlus as faFolderPlusDuotone,
  faCalendarAlt as faCalendarAltDuotone,
  faGlobeAfrica as faGlobeAfricaDuotone,
  faCog as faCogDuotone,
  faCopy as faCopyDuotone,
  faCloudUpload as faCloudUploadDuotone,
  faPaintBrush as faPaintBrushDuotone,
  faEdit as faEditDuotone,
  faSave as faSaveDuotone,
  faGripVertical as faGripVerticalDuotone,
  faTrash as faTrashDuotone,
  faLayerGroup as faLayerGroupDuotone,
  faFileImage as faFileImageDuotone,
  faBooks as faBooksDuotone,
  faSparkles as faSparklesDuotone,
  faCamera as faCameraDuotone,
  faPenNib as faPenNibDuotone,
  faPencil as faPencilDuotone,
  faWandMagic as faWandMagicDuotone,
  faHandPaper as faHandPaperDuotone,
  faFileAudio as faFileAudioDuotone,
  faFileVideo as faFileVideoDuotone,
  faCommentExclamation as faCommentExclamationDuotone,
  faFileDownload as faFileDownloadDuotone,
  faClosedCaptioning as faClosedCaptioningDuotone,
  faComments as faCommentsDuotone,
  faCommentCheck as faCommentCheckDuotone,
  faCompactDisc as faCompactDiscDuotone,
  faHome as faHomeDuotone,
  faCircle as faCircleDuotone,
  faList as faListDuotone,
  faBell as faBellDuotone,
  faExternalLink as faExternalLinkDuotone,
  faSearchPlus as faSearchPlusDuotone,
  faSearchMinus as faSearchMinusDuotone,
  faSearch as faSearchDuotone,
  faBellOn as faBellOnDuotone,
  faBellSlash as faBellSlashDuotone,
  faFastBackward as faFastBackwardDuotone,
  faFastForward as faFastForwardDuotone,
  faCompressAlt as faCompressAltDuotone,
  faExpandAlt as faExpandAltDuotone,
  faDownload as faDownloadDuotone,
  faImages as faImagesDuotone,
  faLink as faLinkDuotone,
  faAnalytics as faAnalyticsDuotone,
  faSignOut as faSignOutDuotone,
  faFilePdf as faFilePdfDuotone,
  faFileAlt as faFileAltDuotone,
  faCameraMovie as faCameraMovieDuotone,
  faFilter as faFilterDuotone,
  faSpinnerThird as faSpinnerThirdDuotone,
  faFolderOpen as faFolderOpenDuotone,
  faFolder as faFolderDuotone,
  faSyncAlt as faSyncAltDuotone,
  faMapMarker as faMapMarkerDuotone,
  faBackward as faBackwardDuotone,
  faForward as faForwardDuotone,
  faMinusSquare as faMinusSquareDuotone,
  faPlusSquare as faPlusSquareDuotone,
  faCheckCircle as faCheckCircleDuotone,
  faArrowRight as faArrowRightDuotone,
} from '@fortawesome/pro-duotone-svg-icons';

// Import solid
import {
  faCommentEdit as faCommentEditSolid,
  faTimes as faTimesSolid,
  faCheck as faCheckSolid,
  faChevronDown as faChevronDownSolid,
  faText as faTextSolid,
  faCheckCircle as faCheckCircleSolid,
  faPlus as faPlusSolid,
  faEllipsisV as faEllipsisVSolid,
  faMale as faMaleSolid,
  faUndoAlt as faUndoAltSolid,
  faFileImport as faFileImportSolid,
  faComment as faCommentSolid,
  faSquare as faSquareSolid,
  faMousePointer as faMousePointerSolid,
  faChevronLeft as faChevronLeftSolid,
  faChevronRight as faChevronRightSolid,
  faArrowUp as faArrowUpSolid,
  faArrowDown as faArrowDownSolid,
  faArrowLeft as faArrowLeftSolid,
  faArrowRight as faArrowRightSolid,
  faPlay as faPlaySolid,
  faPause as faPauseSolid,
  faChevronUp as faChevronUpSolid,
  faCog as faCogSolid,
  faStop as faStopSolid,
} from '@fortawesome/pro-solid-svg-icons';

// import your icons
library.add(
  faTrello,
  faStopSolid,
  faArrowRightDuotone,
  faPlusRegular,
  faPlusLight,
  faChevronDownRegular,
  faScrollDuotone,
  faPencilPaintbrushDuotone,
  faMicrophoneAltDuotone,
  faMicrophoneAltSlashDuotone,
  faPhotoVideoDuotone,
  faCalendarDuotone,
  faCalendarExclamationDuotone,
  faFileUploadDuotone,
  faHighlighterDuotone,
  faDrawSquareDuotone,
  faBezierCurveDuotone,
  faRectangleLandscapeDuotone,
  faImageDuotone,
  faMaskDuotone,
  faLockDuotone,
  faTagDuotone,
  faTasksDuotone,
  faFoldersDuotone,
  faFileDuotone,
  faFileExportDuotone,
  faPauseRegular,
  faChartBarDuotone,
  faUsersDuotone,
  faXRayDuotone,
  faFileChartLineDuotone,
  faBugDuotone,
  faExclamationCircleDuotone,
  faRocketDuotone,
  faEnvelopeDuotone,
  faFeatherDuotone,
  faTrashAltDuotone,
  faFolderPlusDuotone,
  faCalendarAltDuotone,
  faGlobeAfricaDuotone,
  faCogDuotone,
  faCopyDuotone,
  faCloudUploadDuotone,
  faPaintBrushDuotone,
  faEditDuotone,
  faSaveDuotone,
  faGripVerticalDuotone,
  faTrashDuotone,
  faLayerGroupDuotone,
  faFileImageDuotone,
  faBooksDuotone,
  faSparklesDuotone,
  faCameraDuotone,
  faPenNibDuotone,
  faPencilDuotone,
  faWandMagicDuotone,
  faHandPaperDuotone,
  faFileAudioDuotone,
  faFileVideoDuotone,
  faCommentExclamationDuotone,
  faFileDownloadDuotone,
  faClosedCaptioningDuotone,
  faCommentsDuotone,
  faCommentCheckDuotone,
  faCompactDiscDuotone,
  faDogDuotone,
  faHomeDuotone,
  faCircleDuotone,
  faListDuotone,
  faBellDuotone,
  faExternalLinkDuotone,
  faSearchPlusDuotone,
  faSearchMinusDuotone,
  faBellOnDuotone,
  faBellSlashDuotone,
  faFastBackwardDuotone,
  faFastForwardDuotone,
  faCompressAltDuotone,
  faExpandAltDuotone,
  faDownloadDuotone,
  faImagesDuotone,
  faLinkDuotone,
  faAnalyticsDuotone,
  faSignOutDuotone,
  faFilePdfDuotone,
  faFileAltDuotone,
  faCameraMovieDuotone,
  faFilterDuotone,
  faSpinnerThirdDuotone,
  faFolderOpenDuotone,
  faFolderDuotone,
  faSyncAltDuotone,
  faMapMarkerDuotone,
  faBackwardDuotone,
  faForwardDuotone,
  faMinusSquareDuotone,
  faPlusSquareDuotone,
  faCommentEditSolid,
  faTimesSolid,
  faCheckSolid,
  faChevronDownSolid,
  faTextSolid,
  faCheckCircleSolid,
  faPlusSolid,
  faEllipsisVSolid,
  faMaleSolid,
  faUndoAltSolid,
  faFileImportSolid,
  faCommentSolid,
  faSquareSolid,
  faMousePointerSolid,
  faChevronLeftSolid,
  faChevronRightSolid,
  faArrowUpSolid,
  faArrowDownSolid,
  faEllipsisVSolid,
  faArrowLeftSolid,
  faArrowRightSolid,
  faPlaySolid,
  faPauseSolid,
  faChevronUpSolid,
  faCogSolid,
  faCheckCircleDuotone,
  faBoldDuotone,
  faItalicDuotone,
  faUnderlineDuotone,
  faEraserDuotone,
  faListOlDuotone,
  faListUlDuotone,
  faCommentDuotone,
  faCheckDuotone,
  faArchiveDuotone,
  faTextDuotone,
  faPauseDuotone,
  faCheckCircleLight,
  faNarwhalDuotone,
  faTimesRegular,
  faStreamDuotone,
  faTimesRegular,
  faPlusCircleDuotone,
  faMinusCircleDuotone,
  faScalpelPathDuotone,
  faSearchDuotone,
  faVolumeuDuotone,
  faStopDuotone,
  faInfoCircleDuotone,
  faSquareDuotone,
  faCommentEditDuotone,
  faLightbulbDuotone,
  faCloudUploadAltDuotone
);

dom.watch();
