import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React from 'react';

import { post } from '../../util/fetchUtil';

import { Store } from '../../state/store';
import { fetchActiveProject } from '../../state/projects/actions';

import uuid from '../../app/utilities/uuid';
import { formatDegrees } from '../../app/utilities/user';

import Button from '../Button';
import OptionSelector from '../OptionSelector';

import { colors, lighten } from '../styles/colors';

function TrackingTask(props) {
  const { task, team, projectID, tracking } = props;

  const [isAddingTask, setIsAddingTask] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState({});
  const [timeInputValue, setTimeInputValue] = React.useState('');

  const {
    state: {
      user,
      activeProject: { credits },
    },
    dispatch,
  } = React.useContext(Store);

  const taskUser =
    tracking &&
    tracking.data &&
    tracking.data.tasks &&
    tracking.data.tasks.find((t) => t.taskID === task.taskID);
  const userObject =
    taskUser && team.find((teammate) => teammate.userID === taskUser.userID);

  const userName = userObject && userObject.fullName;

  // Only allow floating point
  const handleInputChange = (e) => {
    const { value } = e.target;
    if (value.length === 1 && value.indexOf('.') === 0) setTimeInputValue('0.');
    // If the starting value is '.'
    else if (value.indexOf('.') === value.length - 1) setTimeInputValue(value);
    // '.' is the last character
    else if (value.match(/^-?\d*(\.\d+)?$/g)) setTimeInputValue(value); // Matches floating point
  };

  const handleSelectUser = (newUser) => {
    setSelectedUser(newUser);
  };

  const handleSave = () => {
    post('/tracking/updateTask', {
      projectID,
      taskID: task.taskID,
      userID: selectedUser.userID,
      hours: parseFloat(timeInputValue),
    }).then(() => {
      setIsAddingTask(false);

      fetchActiveProject(projectID, dispatch);
    });

    // Format user degrees if they exist
    const teammate = team.find((u) => u.userID === selectedUser.userID);
    let degrees = '';
    if (teammate.degrees.length > 0) {
      degrees = formatDegrees(teammate.degrees);
    }

    const name = `${selectedUser.fullName}${degrees}`;
    const role = task.taskName;

    // Only add new credit if it doesn't already exist
    if (
      credits.data &&
      credits.data.items &&
      !credits.data.items.find(
        (item) => item.name === name && item.role === role
      )
    ) {
      // Automatically add to credits section
      post('/project/addCreditEntry', {
        projectID,
        id: uuid(),
        name,
        role,
      }).then(() => {
        fetchActiveProject(projectID, dispatch);
      });
    }
  };

  const handleAddTask = () => {
    setSelectedUser({
      fullName: `${user.givenName} ${user.familyName}`,
      userID: user.userID,
    });

    setIsAddingTask(true);
  };

  const handleEditTask = () => {
    setSelectedUser({
      fullName: userName,
      userID: taskUser.userID,
    });

    setTimeInputValue(taskUser.hours);
    setIsAddingTask(true);
  };

  const handleRemoveTask = () => {
    post('/tracking/removeTask', {
      projectID,
      taskID: task.taskID,
    }).then(() => {
      setIsAddingTask(false);

      fetchActiveProject(projectID, dispatch);
    });
  };

  return (
    <Container>
      {!taskUser && !isAddingTask && (
        <AddTaskButton thin box basic leftIcon onClick={handleAddTask}>
          <FontAwesomeIcon icon={['far', 'plus']} />
          {task.taskName}
        </AddTaskButton>
      )}
      {isAddingTask && (
        <TaskContainer>
          <TaskText>{task.taskName}:&nbsp;</TaskText>
          <OptionSelector
            options={team.map((teammate) => ({
              ...teammate,
              name: teammate.fullName,
              id: teammate.userID,
            }))}
            selectedUser={selectedUser}
            onSelectOption={handleSelectUser}
            hasAutocomplete={true}
            autocompletePlaceholder="Filter teammates..."
            buttonClasses="thin box basic"
            saveSelection={false}
            placeholder={selectedUser.fullName}
          />
          <TextInput className="text-input">
            <input
              type="text"
              placeholder="Hrs."
              onChange={handleInputChange}
              value={timeInputValue}
            />
          </TextInput>
          <Button
            box
            thin
            color={!!timeInputValue && 'white'}
            backgroundColor={!!timeInputValue && colors.green}
            enable={!!timeInputValue}
            onClick={handleSave}
          >
            <FontAwesomeIcon icon={['fad', 'check']} />
          </Button>
          {taskUser && (
            <Button thin box basic color="red" onClick={handleRemoveTask}>
              <FontAwesomeIcon icon={['fad', 'trash-alt']} />
            </Button>
          )}
          <Button box thin basic onClick={() => setIsAddingTask(!isAddingTask)}>
            <FontAwesomeIcon icon={['fas', 'times']} />
          </Button>
        </TaskContainer>
      )}
      {taskUser && !isAddingTask && (
        <TaskText>
          <Task>{task.taskName}:&nbsp;</Task>
          <Name>{userName}</Name>
          <StyledButton thin icon onClick={handleEditTask}>
            <FontAwesomeIcon icon={['fad', 'edit']} />
          </StyledButton>
        </TaskText>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const AddTaskButton = styled(Button)`
  margin-top: 5px;
  margin-bottom: 5px;
`;

const TextInput = styled.div`
  input {
    width: 50px;
    padding: 5px;

    text-align: center;
  }
`;

const Name = styled.span`
  padding: 2px 5px;
  background: ${lighten(colors.grey, 50)};
  border-radius: 3px;
  color: ${colors['light-grey-10']};
`;

const Task = styled.span`
  color: ${colors['light-grey-20']};
`;

const TaskText = styled.p`
  margin: 0;
`;

const TaskContainer = styled.div`
  display: flex;
  align-items: center;

  margin-top: 5px;
  margin-bottom: 5px;

  > * {
    margin-right: 5px;
  }
`;

const StyledButton = styled(Button)`
  opacity: 0;
  margin-left: 10px;
  ${Container}:hover & {
    opacity: 1;
  }
`;

TrackingTask.propTypes = {
  task: PropTypes.shape({
    taskID: PropTypes.number,
    taskName: PropTypes.string,
  }),
  team: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  projectID: PropTypes.number,
  tracking: PropTypes.object,
};

TrackingTask.defaultProps = {
  projectID: null,
  tracking: {},
};

export default TrackingTask;
