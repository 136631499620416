import PropTypes from 'prop-types';

export const CommentProps = PropTypes.shape({
  commentID: PropTypes.number,
  createdOn: PropTypes.string,
  data: PropTypes.shape({
    timestamp: PropTypes.string,
    text: PropTypes.string,
    user: PropTypes.shape({
      givenName: PropTypes.string,
    }),
  }),
});

export default CommentProps;
