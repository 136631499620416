import PropTypes from 'prop-types';

const { number, string, shape } = PropTypes;

export default shape({
  labelID: number,
  data: shape({
    color: string,
  }),
  name: string,
});
