import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';

import { post } from '../../util/fetchUtil';

import { Store } from '../../state/store';
import { fetchActiveProject } from '../../state/projects/actions';

import uuid from '../../app/utilities/uuid';

import { roles } from '../../app/credits';
import { formatDegrees } from '../../app/utilities/user';

import Autocomplete from '../Autocomplete';
import Button from '../RebassButton';

import {
  Container,
  HeaderContainer,
  BodyContainer,
  Text,
  HeaderText,
  ButtonContainer,
  CreditsRow,
  FooterRow,
} from './CreditsEditorStyles';

function CreditsEditor(props) {
  const {
    activeProject: { projectID, credits },
  } = props;

  const { state, dispatch } = useContext(Store);
  const [newName, setNewName] = useState('');
  const [newRole, setNewRole] = useState('');

  const creditsValid = !!newName && !!newRole;

  const removeCredit = (credit) => {
    post('/project/removeCreditEntry', {
      projectID,
      creditID: credit.id,
    }).then(() => fetchActiveProject(projectID, dispatch));
  };

  const handleRoleChange = (e) => {
    const { value } = e.target;
    setNewRole(value);
  };

  const handleSelectRole = (item) => {
    setNewRole(item.name);
  };

  const handleNameChange = (e) => {
    const { value } = e.target;
    setNewName(value);
  };

  const handleSelectName = (item) => {
    setNewName(item.name);
  };

  const handleSaveCredit = () => {
    const newCredit = {
      name: newName,
      role: newRole,
    };

    post('/project/addCreditEntry', {
      projectID,
      id: uuid(),
      name: newCredit.name,
      role: newCredit.role,
    }).then(() => {
      fetchActiveProject(projectID, dispatch);

      setNewName('');
      setNewRole('');
    });
  };

  const creditsComponents =
    credits &&
    credits.data &&
    credits.data.items.map((credit) => {
      if (credit.type === 'entry') {
        return (
          <CreditsRow key={credit.id}>
            <Text>{credit.name}</Text>
            <Text>{credit.role}</Text>
            <ButtonContainer>
              <Button
                size="s"
                variant="transparent"
                color="dark-6"
                onClick={() => removeCredit(credit)}
              >
                <FontAwesomeIcon icon={['fas', 'times']} />
              </Button>
            </ButtonContainer>
          </CreditsRow>
        );
      }

      return null;
    });

  // Assemble teammate names with degrees, e.g. Tanner Marshall, MS, Etc...
  const creditSuggestions = state.team.map((teammate) => {
    const name = `${teammate.fullName}`;
    let degrees = '';

    if (teammate.degrees.length) {
      degrees = formatDegrees(teammate.degrees);
    }
    return `${name}${degrees}`;
  });

  return (
    <Container>
      <HeaderContainer>
        <HeaderText>Name</HeaderText>
        <HeaderText>Role</HeaderText>
        <ButtonContainer />
      </HeaderContainer>
      <BodyContainer>{creditsComponents}</BodyContainer>
      <FooterRow>
        <Autocomplete
          value={newName}
          options={creditSuggestions.map((suggestion) => ({
            id: suggestion,
            name: suggestion,
          }))}
          placeholder="Name"
          onItemSelect={handleSelectName}
          onChange={handleNameChange}
        />
        <Autocomplete
          value={newRole}
          options={roles.map((role) => ({ id: role, name: role }))}
          placeholder="Role"
          onItemSelect={handleSelectRole}
          onChange={handleRoleChange}
        />
        <ButtonContainer>
          <Button
            bg="positive"
            onClick={handleSaveCredit}
            enable={creditsValid}
            startIcon={<FontAwesomeIcon icon={['fad', 'check']} />}
          >
            Save
          </Button>
        </ButtonContainer>
      </FooterRow>
    </Container>
  );
}

CreditsEditor.propTypes = {
  activeProject: PropTypes.shape({
    projectID: PropTypes.number,
    credits: PropTypes.object,
  }),
};

CreditsEditor.defaultProps = {
  activeProject: [],
};

export default CreditsEditor;
