import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import React, { useState, useRef, useEffect } from 'react';

import { motion, AnimatePresence } from 'framer-motion';

import { TextInput } from './Input';
import { P } from './styles/typography';

import elevations from './styles/elevations';
import { colors, lighten } from './styles/colors';

/**
 * Display a list of options based on user input
 */
function Autocomplete(props) {
  const {
    options,
    placeholder,
    onItemSelect,
    onChange,
    value,
    showListWhenEmpty,
  } = props;

  // Container ref
  const suggestionsContainerRef = useRef();
  const [focusedIndex, setFocusedIndex] = useState(0);
  const [focused, setFocused] = useState(false);

  // Handle using arrows to scroll up and down
  useEffect(() => {
    const parent = suggestionsContainerRef.current;
    if (parent && parent.children) {
      const child = parent.children[focusedIndex];
      if (child) {
        const childOffsetTop = child.offsetTop;
        const parentScrollTop = parent.scrollTop;
        const parentHeight = parent.offsetHeight;
        const childHeight = child.offsetHeight;

        if (childOffsetTop + childHeight > parentHeight + parentScrollTop) {
          if (suggestionsContainerRef.current) {
            const scrollToY = childOffsetTop + childHeight - parentHeight;
            suggestionsContainerRef.current.scrollTo(0, scrollToY);
          }
        } else if (childOffsetTop < parentScrollTop) {
          suggestionsContainerRef.current.scrollTo(0, childOffsetTop);
        }
      }
    }
  }, [focusedIndex]);

  const matchedOptions = options.filter(
    (option) =>
      option.noFilter || option.name.toLowerCase().includes(value.toLowerCase())
  );

  // Look for any exact matches and add them to the front of the array
  const exactMatchIndex = matchedOptions.findIndex(
    (o) => o.name.toLowerCase() === value.toLowerCase()
  );

  if (exactMatchIndex > -1) {
    const exactMatch = matchedOptions.splice(exactMatchIndex, 1);
    matchedOptions.unshift(...exactMatch);
  }

  const handleItemSelect = (option) => () => onItemSelect(option);

  const handleChange = (e) => onChange(e);

  const handleKeyDown = (e) => {
    let newFocusedIndex = focusedIndex;
    switch (e.keyCode) {
      case 38: // Up
        e.preventDefault();
        if (!(focusedIndex - 1 < 0)) newFocusedIndex -= 1;
        break;
      case 40: // Down
        e.preventDefault();
        if (!(focusedIndex + 1 >= matchedOptions.length)) newFocusedIndex += 1;
        break;
      case 13: // Enter
        e.preventDefault();
        onItemSelect(matchedOptions[focusedIndex]);
        break;
      default:
        break;
    }

    setFocusedIndex(newFocusedIndex);
  };

  const visibleOptions = showListWhenEmpty && value ? options : matchedOptions;

  const matchedOptionsComponents = visibleOptions.map((option, index) => (
    <SuggestionContainer key={index} onClick={handleItemSelect(option)}>
      <Suggestion hasFocus={index === focusedIndex}>
        {option.component || <P>{option.name}</P>}
      </Suggestion>
    </SuggestionContainer>
  ));

  const dropdownVisible =
    focused && (showListWhenEmpty || (value && visibleOptions.length));

  return (
    <Container>
      <InputContainer>
        <TextInput
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={value}
          placeholder={placeholder}
          onBlur={() => setFocused(false)}
          onFocus={() => setFocused(true)}
        />
      </InputContainer>
      <AnimatePresence>
        {dropdownVisible && (
          <SuggestionsContainer
            ref={suggestionsContainerRef}
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0, transition: { easing: 'easeOut' } }}
          >
            {matchedOptionsComponents}
          </SuggestionsContainer>
        )}
      </AnimatePresence>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const InputContainer = styled.div`
  input {
    width: 100%;
  }
`;

const SuggestionsContainer = styled(motion.div)`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;

  background: ${lighten(colors.grey, 58)};

  margin-top: 10px;

  border-radius: 3px;

  box-shadow: ${elevations['5']};

  max-height: 400px;
  overflow: auto;
`;

const SuggestionContainer = styled.div``;
const Suggestion = styled.div`
  padding: 8px 13px;

  color: ${colors['light-grey-10']};

  cursor: pointer;

  :hover,
  :focus {
    background: ${colors['light-grey-50']};
    color: ${colors['dark-grey-10']};
  }

  ${(props) =>
    props.hasFocus &&
    css`
      background: ${colors['light-grey-50']};
      color: ${colors['dark-grey-10']};
    `}
`;

Autocomplete.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string, // The display name to compare the search term with
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Unique identifier
      component: PropTypes.element, // (optional) Custom react element
      noFilter: PropTypes.bool, // (optional) Do not filter this option
    })
  ),
  onChange: PropTypes.func,
  onItemSelect: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string, // The search text value
  showListWhenEmpty: PropTypes.bool, // The search text value
};

Autocomplete.defaultProps = {
  onItemSelect: () => {},
  onChange: () => {},
  options: [],
  placeholder: '',
  value: '',
};

export default Autocomplete;
