import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useContext, useEffect } from 'react';

import { useRouteMatch, useHistory } from 'react-router-dom';

import tinycolor from 'tinycolor2';

import fetchUtil, { post } from '../../util/fetchUtil';

import { Store } from '../../state/store';
import { setSelectedCommentID } from '../../state/comments/actions';
import { fetchNotifications } from '../../state/notifications/actions';
import { setSavedAnnotationSvg } from '../../state/annotations/actions';
import { fetchActiveProject } from '../../state/projects/actions';

import players from '../../app/videoPlayer';
import notifications from '../../app/notifications/notifications';
import playback from '../../app/playback';

import CommentEditor from './CommentEditor';
import CommentWrapper from './CommentWrapper';
import CommentActions from './CommentActions';
import Spelling from './Spelling';

import { colors } from '../styles/colors';
import { getHash } from '../../shared/links';
import {
  fetchSavedWords,
  setMisspelledWords,
} from '../../state/misspelledWords/actions';
import useLocalStorage from '../../app/hooks/useLocalStorage';

const ANONYMOUS_SETTINGS_KEY = 'setting:anonymous-commenting';

const Container = styled.div`
  width: 100%;
  /* background-color: #f7f7f7; */
  display: flex;
  flex-direction: column;
`;

const ResolvedCommentsHeader = styled.h4`
  padding: 10px;
  margin: 0;

  background-color: ${tinycolor(colors.grey).lighten(52).toHexString()};
  border-bottom: 1px solid ${colors['light-grey-50']};
  color: ${colors['light-grey-20']};
`;

const CommentsContainer = styled.div``;

const filters = {
  Timestamp: (a, b) => {
    return parseFloat(b.data.timestamp) - parseFloat(a.data.timestamp);
  },
  Newest: (a, b) => new Date(a.createdOn) - new Date(b.createdOn),
  Oldest: (a, b) => new Date(b.createdOn) - new Date(a.createdOn),
  Commenter: (a, b) => {
    const nameA = a.data.user.givenName.toLowerCase();
    const nameB = b.data.user.givenName.toLowerCase();
    if (nameA > nameB) return -1;
    if (nameB > nameA) return 1;
    return 0;
  },
};

function CommentPanel(props) {
  const { comments } = props;

  const context = useContext(Store);
  const {
    state: {
      selectedCommentID,
      sceneMode,
      activeProject,
      activeFile,
      misspelledWords,
      savedWords,
    },
    dispatch,
  } = context;

  const [activeFilter, setActiveFilter] = useState('Timestamp');
  const [isAnonymous, setIsAnonymous] = useLocalStorage(
    ANONYMOUS_SETTINGS_KEY,
    false,
    {
      type: 'boolean',
    }
  );

  const history = useHistory();

  // Filter saved words out of misspelled words
  const filteredWords = misspelledWords.filter(
    (word) => !savedWords.includes(word)
  );

  const activeComments =
    (comments &&
      comments
        .filter((comment) => !comment.data.status || comment.data.status === 0) // Is active
        .filter((comment) => !comment.data.parentCommentID) // Not a reply
        .sort(filters[activeFilter])) ||
    [];

  const resolvedComments =
    (comments && comments.filter((comment) => comment.data.status === 1)) || [];

  /**
   * Activates a comment
   *
   * @param {Object} comment - a comment object
   * @returns
   * @memberof CommentController
   */
  const handleSelectComment = (commentID, timestamp) => {
    // Seek to timestamp for comment
    if (sceneMode === 'video') {
      // Set the player video player
      players.reviewPlayer.currentTime(parseFloat(timestamp / 1000));
    } else {
      // Set the SVG player
      playback.seek(timestamp);
    }

    // Set the comment as active
    setSelectedCommentID(parseInt(commentID, 10), dispatch);

    // Add saved annotation
    const comment = comments.find((c) => c.commentID === commentID);

    if (comment) {
      if (comment.data.annotation) {
        // Fetch SVG annotation from S3
        fetch(comment.data.annotation.svg)
          .then((res) => res.json())
          .then((res) => setSavedAnnotationSvg(res.svg, dispatch));
      } else {
        // Legacy support
        setSavedAnnotationSvg(comment.data.svg || '', dispatch);
      }
    }
  };

  /**
   * Delete a comment
   *
   * @memberof CommentPanel
   */
  const handleDeleteComment = (commentID) => async (event) => {
    event.stopPropagation();

    const isSelected = commentID === selectedCommentID;
    const totalComments = comments.length;

    // Remove this comment and all child reply comments
    const commentIDs = [
      commentID,
      ...activeProject.comments
        .filter((comment) => comment.data.parentCommentID === commentID)
        .map((comment) => comment.commentID),
    ];

    await post('/comments/deleteComments', {
      commentIDs,
    });

    // Clear saved annotation if it's this comment this was the last comment
    if (isSelected || totalComments <= 1) setSavedAnnotationSvg('', dispatch);

    fetchActiveProject(activeProject.projectID, dispatch);
  };

  /**
   * Resolve a comment
   *
   * @memberof CommentPanel
   */
  const handleResolveComment = (commentID) => (event) => {
    event.stopPropagation();

    const isResolved =
      comments.find((comment) => comment.commentID === commentID).data
        .status === 1;

    if (isResolved) {
      fetchUtil
        .post('/comments/unresolveComment', {
          commentID,
        })
        .then(() => fetchActiveProject(activeProject.projectID, dispatch));
    } else {
      fetchUtil
        .post('/comments/resolveComment', {
          commentID,
        })
        .then(() => fetchActiveProject(activeProject.projectID, dispatch));
    }
  };

  /**
   * Save a reply comment to a parent comment
   *
   * @param {String} replyText
   * @param {Number} parentCommentID - commentID for the parent comment that all replies are under
   * @param {Number} replyToCommentID - commentID for the comment in which the "reply" button was pressed (which can be the parentComment)
   * @param {Array} mentionedUsers - array of users mentioned in the comment
   * @memberof CommentPanel
   */
  const handleSaveReply = (
    replyText,
    parentCommentID,
    replyToCommentID,
    mentionedUsers
  ) => {
    const { projectID } = activeProject;
    const { fileID } = activeFile;
    const { userID } = window;

    if (replyText === '') return;

    const parentComment = activeProject.comments.find(
      (c) => c.commentID === parentCommentID
    );

    const data = {
      svg: parentComment.svg || '', // Copy parent SVG
      anonymous: isAnonymous,
      text: replyText,
      parentCommentID,
      replyToCommentID,
      status: 0,
    };

    fetchUtil
      .post('/comments/saveComment', {
        projectID,
        fileID,
        data,
      })
      .then((res) => {
        // Update projects
        fetchActiveProject(activeProject.projectID, dispatch);

        // Send comment reply notifications
        const replyToUserID = activeProject.comments.find(
          (c) => c.commentID === replyToCommentID
        ).userID;
        if (replyToUserID !== window.userID) {
          notifications
            .send({
              recipientUserID: replyToUserID,
              action: 'commentReply',
              actionData: {
                commentingUserID: userID,
                projectID: activeProject.projectID,
                fileID,
                parentCommentID: parentComment.commentID,
                replyCommentID: res.comment.commentID,
                replyCommentText: res.comment.data.text,
                anonymous: res.comment.data.anonymous,
              },
            })
            .then(() => {
              fetchNotifications(dispatch);
            });
        }

        // Send mention notifications
        const promises = [];
        for (let i = 0; i < mentionedUsers.length; i += 1) {
          promises.push(
            notifications.send({
              recipientUserID: mentionedUsers[i].userID,
              action: 'commentMention',
              actionData: {
                commentingUserID: userID,
                projectID,
                fileID,
                parentCommentID: res.comment.commentID,
                replyCommentID: res.comment.commentID,
                replyCommentText: res.comment.data.text,
              },
            })
          );
        }

        Promise.all(promises).then(() => {
          fetchNotifications(dispatch);
        });
      });
  };

  const handleUpdateComment = (commentID, newText, mentionedUsers) => {
    const { projectID } = activeProject;
    const { fileID } = activeFile;

    fetchUtil
      .post('/comments/updateCommentText', {
        commentID,
        text: newText,
      })
      .then(() => {
        // Send mention notifications
        const promises = [];
        for (let i = 0; i < mentionedUsers.length; i += 1) {
          promises.push(
            notifications.send({
              recipientUserID: mentionedUsers[i].userID,
              action: 'commentMention',
              actionData: {
                commentingUserID: window.userID,
                projectID,
                fileID,
                parentCommentID: commentID,
                replyCommentID: commentID,
                replyCommentText: newText,
              },
            })
          );
        }

        return Promise.all(promises).then(() => {
          fetchNotifications(dispatch);
        });
      })
      .then(() => {
        // Update active project
        fetchActiveProject(projectID, dispatch);
      });
  };

  const handleSelectSortOption = (filter) => setActiveFilter(filter.name);

  const handleUpdateList = () => {
    fetchSavedWords(dispatch);
  };

  const handleSetMisspelledWords = (updatedMisspelledWords) => {
    setMisspelledWords(updatedMisspelledWords, dispatch);
  };

  const handleNavigateToComment = (commentID) => {
    if (commentID !== selectedCommentID) {
      history.push(
        getHash({
          projectID: activeProject.projectID,
          fileID: activeFile.fileID,
          commentID,
        })
      );
    } else {
      history.push(
        `${getHash({
          projectID: activeProject.projectID,
          fileID: activeFile.fileID,
        })}/comments`
      );
    }
  };

  // Handle routing to a comment
  const commentIDMatch = useRouteMatch(
    '/project/:projectID/files/:fileID/comments/:commentID'
  );

  // Update current comment based on route
  useEffect(() => {
    let routeCommentID = null;
    if (commentIDMatch) {
      routeCommentID = parseInt(commentIDMatch.params.commentID, 10);
      const routeComment =
        comments.find((c) => c.commentID === routeCommentID) || {};
      const timestamp = routeComment.commentID
        ? routeComment.data.timestamp
        : '';

      if (selectedCommentID !== routeCommentID)
        handleSelectComment(routeCommentID, timestamp);
    } else {
      // Deselect if already selected
      setSelectedCommentID(null, dispatch);
      setSavedAnnotationSvg('', dispatch);
    }
    // eslint-disable-next-line
  }, [commentIDMatch]);

  return (
    <Container>
      {!!filteredWords.length && (
        <Spelling
          misspelledWords={misspelledWords}
          savedWords={savedWords}
          handleUpdateList={handleUpdateList}
          handleSetMisspelledWords={handleSetMisspelledWords}
        />
      )}
      <CommentEditor
        isAnonymous={isAnonymous}
        setIsAnonymous={setIsAnonymous}
      />
      <CommentActions
        filters={filters}
        activeFilter={activeFilter}
        onSelectSortOption={handleSelectSortOption}
      />
      <CommentsContainer>
        {activeComments &&
          activeComments
            .reverse() // Show comments from oldest to most recent
            .filter((comment) => !comment.data.parentCommentID) // Show only comments without parent comments
            .map((comment) => {
              // Pass any comments that are replies to this comment
              const replies = comments
                .filter(
                  (comment) => !comment.data.status || comment.data.status === 0
                )
                .filter((c) => c.data.parentCommentID === comment.commentID);

              // Check if this comment or one of this comment's replies are selected
              const isSelected = !![comment, ...replies].find(
                (c) => c.commentID === selectedCommentID
              );

              return (
                <div
                  key={comment.commentID}
                  onClick={() => handleNavigateToComment(comment.commentID)}
                >
                  <CommentWrapper
                    replies={replies}
                    comment={comment}
                    totalComments={activeComments.length}
                    isSelected={isSelected}
                    handleDeleteComment={handleDeleteComment}
                    handleResolveComment={handleResolveComment}
                    handleSelectComment={handleSelectComment}
                    handleSaveReply={handleSaveReply}
                    handleUpdateComment={handleUpdateComment}
                    darkMode
                  />
                </div>
              );
            })}
        {!!resolvedComments.length && (
          <ResolvedCommentsHeader>Resolved</ResolvedCommentsHeader>
        )}
        {!!resolvedComments.length &&
          resolvedComments.reverse().map((comment) => {
            // Pass any comments that are replies to this comment
            const replies = resolvedComments.filter(
              (c) => c.data.parentCommentID === comment.commentID
            );
            return (
              <div
                key={comment.commentID}
                onClick={() => handleNavigateToComment(comment.commentID)}
              >
                <CommentWrapper
                  key={comment.commentID}
                  comment={comment}
                  replies={replies}
                  totalComments={resolvedComments.length}
                  isSelected={selectedCommentID === comment.commentID}
                  isResolved={true}
                  resolved={comment.data.resolved}
                  handleDeleteComment={handleDeleteComment}
                  handleResolveComment={handleResolveComment}
                  handleSaveReply={handleSaveReply}
                  handleUpdateComment={handleUpdateComment}
                  darkMode
                />
              </div>
            );
          })}
      </CommentsContainer>
    </Container>
  );
}

CommentPanel.propTypes = {
  comments: PropTypes.array,
};

CommentPanel.defaultProps = {
  comments: [],
};

export default CommentPanel;
