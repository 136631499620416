import React from 'react';
import { Box } from 'rebass/styled-components';
import { CommentProps } from '../editor/types/CommentProps';
import { colors } from '../styles/colors';
import Comment from './Comment';

type Props = {
  comments: CommentProps[];
};

const CommentList = ({ comments }: Props) => {
  const commentComponents = comments
    .filter((c) => !c.data.parentCommentID)
    .map((comment) => (
      <Box key={comment.commentID}>
        <Comment comment={comment} />

        {comments
          .filter((c) => c.data.parentCommentID === comment.commentID)
          .map((c) => (
            <Box
              style={{
                paddingLeft: 15,
                marginLeft: 10,
                borderLeft: `1px solid ${colors['light-grey-30']}`,
              }}
              key={c.commentID}
            >
              <Comment comment={c} />
            </Box>
          ))}
      </Box>
    ));

  return <>{commentComponents}</>;
};

export default CommentList;
