import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  useState,
  useRef,
  useEffect,
  memo,
  SyntheticEvent,
} from 'react';
import styled from 'styled-components/macro';

import Tippy from '@tippyjs/react';
import TimelinePlayBar from './TimelinePlayBar';
import TimelineControls from './TimelineControls';
import TimelineContent from './TimelineContent';

import { addKeyframeGroup } from '../../../app/editor/add';
import { keyframeMap } from '../keyframeMap';

import Box, { Flex } from '../../Box';
import OptionSelector from '../../OptionSelector';
import Button from '../../Button';
import AddKeyframeIcon from '../../icons/AddKeyframeIcon';
import { colors, fade } from '../../styles/colors';
import Draggable from '../../Draggable';

import flattenElements from '../../../app/editor/util/flattenElements';
import { ElementProps } from '../types/ElementProps';
import { SceneProps } from '../types/SceneProps';
import { CommentProps } from '../types/CommentProps';
import {
  getPreference,
  updatePreference,
} from '../../../app/editor/preferenceStore';

// Width of the left container
const leftWidth = 10;

// Height of a row
const rowHeight = 30;

const LeftControlsContainer = styled(Box)`
  width: ${leftWidth}%;
  /* height: 30px; */
  background-color: ${colors['light-night-10']};
  color: white;
  display: flex;
  justify-content: space-between;
`;

const RightControlsContainer = styled(Box)`
  overflow-x: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ControlsContainer = styled(Box)`
  display: flex;
`;

type Props = {
  elements: ElementProps[];
  scene: SceneProps;
  comments: CommentProps[];
  selectedElements: ElementProps[];
  progress: number;
  currentTime: number;
  duration: number;
  isPlaying: boolean;
  elementsToUpdate: {
    current: string[];
  };
  layersVisible?: boolean;
  canEdit: boolean;
  isAnnotating: boolean;
  handleSelectElements: (elements: ElementProps[]) => void;
  handleUpdateElement: (elementIds: string[]) => void;
  setProgress: (progress: number) => void;
  setLayersVisible: (layersVisible: boolean) => void;
  handleUpdateDuration: (duration: number) => void;
  handlePlay: () => void;
  handleSkip: (amount: number) => void;
  setIsPlaying: (isPlaying: boolean) => void;
  onClickComment: (comment: CommentProps) => Promise<void>;
};

/**
 * Timeline utility
 *
 * @param {*} props
 * @returns
 */
function Timeline(props: Props) {
  const {
    elements,
    comments,
    selectedElements,
    progress,
    currentTime,
    duration,
    isPlaying,
    elementsToUpdate,
    canEdit = true,
    layersVisible = true,
    setLayersVisible,
    handleSelectElements,
    handleUpdateElement,
    setProgress,
    handleUpdateDuration,
    handlePlay,
    handleSkip,
    setIsPlaying,
    onClickComment,
  } = props;

  // Set scale of timeline
  const [pixelsPerMs, setPixelsPerMs] = useState(0.07);
  const [commentsVisible, setCommentsVisible] = useState(
    !!getPreference('timelineCommentsVisible')
  );

  const rightControlsContainerRef = useRef<HTMLDivElement>(null);
  const lowerScrollContainerRef = useRef<HTMLDivElement>(null);
  const verticalScrollRef = useRef<HTMLDivElement>(null);

  const handleSetPixelsPerMs = (value: number) => {
    let newPxPerMs = value;
    // Clamp zoom of timeline to show full timeline width
    const offsetWidth = rightControlsContainerRef?.current?.offsetWidth;
    if (offsetWidth) {
      const minPxPerMs = offsetWidth / duration;
      if (newPxPerMs < minPxPerMs) newPxPerMs = minPxPerMs;

      setPixelsPerMs(newPxPerMs);
    }
  };

  const handleMouseMove = (move: { dx: number }) => {
    const change = move.dx / pixelsPerMs / duration;
    const newProgress = progress + change;
    if (newProgress >= 0 && newProgress <= 1) {
      setProgress(newProgress);
    }
  };

  const handleMouseUp = () => {
    // Update all elements on mouse up
    elementsToUpdate.current = flattenElements(elements).map((el) => el.id);
    setIsPlaying(false);
  };

  const handleAddKeyframeGroup = (key: string) => {
    selectedElements.forEach((el) => {
      addKeyframeGroup(el.id, key, elements);
    });

    handleUpdateElement(selectedElements.map((el) => el.id));
  };

  const handleScrollPlaybar = (e: SyntheticEvent<HTMLDivElement>) => {
    // Keep two containers scroll-x in sync
    if (
      lowerScrollContainerRef.current?.scrollLeft !==
      e.currentTarget?.scrollLeft
    ) {
      if (lowerScrollContainerRef.current) {
        lowerScrollContainerRef.current.scrollLeft = e.currentTarget.scrollLeft;
      }
    }
  };

  const handleToggleCommentsVisible = () => {
    setCommentsVisible(!commentsVisible);
    updatePreference('timelineCommentsVisible', !commentsVisible);
  };

  useEffect(() => {
    // Automatically scroll to selected element in timeline
    if (selectedElements.length) {
      const scrollEl = selectedElements[0];
      const domRow = document.getElementById(`timeline-row-${scrollEl.id}`);

      if (domRow && verticalScrollRef.current) {
        verticalScrollRef.current.scrollTop = domRow.offsetTop;
      }
    }
  }, [selectedElements]);

  // Set the zoom to span the entire timeline on initial load
  useEffect(() => {
    const offsetWidth = rightControlsContainerRef?.current?.offsetWidth;
    if (offsetWidth && duration > 0) {
      setPixelsPerMs(offsetWidth / duration);
    }
  }, [duration]);

  const options = Object.keys(keyframeMap).map((key) => ({
    id: key,
    name: key,
  }));

  return (
    <Flex
      style={{
        flexDirection: 'column',
        flex: layersVisible ? 1 : undefined,
        overflow: 'hidden',
      }}
    >
      <TimelineControls
        handlePlay={handlePlay}
        handleSkip={handleSkip}
        isPlaying={isPlaying}
        currentTime={currentTime}
        duration={duration}
        handleUpdateDuration={handleUpdateDuration}
        setPixelsPerMs={handleSetPixelsPerMs}
        pixelsPerMs={pixelsPerMs}
        canEdit={canEdit}
        layersVisible={layersVisible}
        setLayersVisible={setLayersVisible}
      />
      <ControlsContainer>
        {layersVisible && (
          <LeftControlsContainer>
            <Box></Box>
            <Flex>
              <Tippy content="Toggle comments">
                <Box>
                  <Button
                    onClick={handleToggleCommentsVisible}
                    icon
                    thin
                    style={{ height: 30, width: 30 }}
                    color={commentsVisible ? colors.teal : fade('white', 75)}
                  >
                    <FontAwesomeIcon icon={['fas', 'comment']} />
                  </Button>
                </Box>
              </Tippy>
              <Box>
                <OptionSelector
                  options={options}
                  enable={!!selectedElements.length}
                  onSelectOption={(option) => handleAddKeyframeGroup(option.id)}
                >
                  <Button
                    icon
                    thin
                    enable={!!selectedElements.length}
                    style={{ height: 30, width: 30, fill: 'white' }}
                  >
                    <AddKeyframeIcon />
                  </Button>
                </OptionSelector>
              </Box>
            </Flex>
          </LeftControlsContainer>
        )}
        <RightControlsContainer
          ref={rightControlsContainerRef}
          style={{ width: `${layersVisible ? 100 - leftWidth : 100}%` }}
          onScroll={handleScrollPlaybar}
        >
          <Draggable
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseDown={() => setIsPlaying(true)}
          >
            <TimelinePlayBar
              progress={progress}
              pixelsPerMs={pixelsPerMs}
              duration={duration}
              setProgress={setProgress}
              width={pixelsPerMs * duration}
              comments={comments}
              commentsVisible={commentsVisible}
              isPlaying={isPlaying}
              onClickComment={onClickComment}
              layersVisible={layersVisible}
            />
          </Draggable>
        </RightControlsContainer>
      </ControlsContainer>
      {layersVisible && (
        <TimelineContent
          elements={elements}
          elementsToUpdate={elementsToUpdate}
          selectedElements={selectedElements}
          handleUpdateElement={handleUpdateElement}
          handleSelectElements={handleSelectElements}
          progress={progress}
          setProgress={setProgress}
          duration={duration}
          leftWidth={leftWidth}
          lowerScrollContainerRef={lowerScrollContainerRef}
          rightControlsContainerRef={rightControlsContainerRef}
          verticalScrollRef={verticalScrollRef}
          pixelsPerMs={pixelsPerMs}
          rowHeight={rowHeight}
          isPlaying={isPlaying}
        />
      )}
    </Flex>
  );
}

const shouldMemo = (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
  if (nextProps.isAnnotating) return true;
  return false;
};

export default memo(Timeline, shouldMemo);
