import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { getTimeStringFromMs } from '../../../app/utilities/durations';
import playbackRates from '../../../app/playbackRates';
import Box, { Flex } from '../../Box';
import Button from '../../Button';
import { colors, fade, lighten } from '../../styles/colors';

const CurrentTime = styled(Box)`
  padding: 5px 18px;

  font-size: 14px;

  color: ${fade('white', 30)};
  border-radius: 100px;

  background-color: ${colors.night};
`;

const ControlButton = styled(Button)`
  font-size: 13px;
  color: white;
  :hover,
  :focus {
    color: ${fade('white', 10)};
  }
`;

const StyledControlButton = styled(ControlButton)`
  font-size: 35px;
`;

const StyledCurrentTime = styled(CurrentTime)`
  font-size: 24px;
  margin-bottom: 15px;
`;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TimelineProgressBar = styled.div`
  border-radius: 50px;
  background-color: ${colors.night};
  width: 100%;
  height: 15px;
`;

const TimelineProgress = styled(Box)`
  border-radius: 50px;
  background-color: ${lighten(colors.olive, 20)};
  height: 100%;
`;

type Props = {
  currentTime: number;
  duration: number;
  isPlaying: boolean;
  sceneIDs: number[];
  activeSceneID: number;
  rate?: number;
  isLoading?: boolean;
  handleSetActiveScene: (newSceneID: number, playOnLoad: boolean) => void;
  handleTogglePlay: () => void;
  handleSetProgress: (progress: number) => void;
  handleSkip: (amount: number) => void;
  handleSetRate?: (newRate: number) => void;
  onFinish?: () => void;
};

const TimelinePortalControls: React.FC<Props> = (props: Props) => {
  const {
    currentTime,
    duration,
    isPlaying,
    sceneIDs,
    activeSceneID,
    rate,
    isLoading,
    handleSetRate,
    onFinish,
    handleSetActiveScene,
    handleTogglePlay,
    handleSkip,
    handleSetProgress,
  } = props;

  const timelineRef = useRef<HTMLDivElement>(null);

  const handleClickTimeline = (e: React.MouseEvent) => {
    const offset = e.nativeEvent.offsetX;
    if (timelineRef?.current?.offsetWidth) {
      handleSetProgress(offset / timelineRef.current.offsetWidth);
    }
  };

  const activeSceneIndex = sceneIDs.findIndex(
    (sceneID) => sceneID === activeSceneID
  );
  const nextSceneID = sceneIDs[activeSceneIndex + 1];

  useEffect(() => {
    // If we're at the end
    if (duration > 0 && currentTime === duration) {
      handleTogglePlay();

      if (nextSceneID) {
        handleSetActiveScene(nextSceneID, true);
      } else if (onFinish) onFinish();
    }

    // eslint-disable-next-line
  }, [currentTime]);

  const handleRateClick = () => {
    if (handleSetRate) {
      const rateIndex = playbackRates.findIndex((r) => r === rate);
      let newRateIndex = rateIndex + 1;
      if (newRateIndex === playbackRates.length) {
        newRateIndex = 0;
      }

      handleSetRate(playbackRates[newRateIndex]);
    }
  };

  return (
    <Container>
      <Flex display="flex" justifyContent="center">
        <StyledCurrentTime>
          {isLoading && <Box>Loading...</Box>}
          {!isLoading && (
            <span>
              {getTimeStringFromMs(currentTime)} /{' '}
              {getTimeStringFromMs(duration)}
            </span>
          )}
        </StyledCurrentTime>
      </Flex>
      <Box width="100%" p="10px 20px">
        <TimelineProgressBar ref={timelineRef} onClick={handleClickTimeline}>
          <TimelineProgress
            style={{ width: `${(currentTime / duration) * 100}%` }}
          />
        </TimelineProgressBar>
      </Box>
      <Flex style={{ marginRight: 20 }}>
        {/* Add button here to center controls */}
        <StyledControlButton
          box
          icon
          enabled={false}
          style={{ opacity: 0, pointerEvents: 'none' }}
        >
          <FontAwesomeIcon icon={['fad', 'narwhal']} />
        </StyledControlButton>
        <StyledControlButton box icon onClick={() => handleSkip(-1000)}>
          <FontAwesomeIcon icon={['fad', 'backward']} />
        </StyledControlButton>
        <StyledControlButton box icon onClick={handleTogglePlay}>
          {!isPlaying && <FontAwesomeIcon icon={['fas', 'play']} />}
          {isPlaying && <FontAwesomeIcon icon={['fas', 'pause']} />}
        </StyledControlButton>
        <StyledControlButton box icon onClick={() => handleSkip(1000)}>
          <FontAwesomeIcon icon={['fad', 'forward']} />
        </StyledControlButton>
        {rate && (
          <StyledControlButton box icon onClick={handleRateClick}>
            {rate}x
          </StyledControlButton>
        )}
        {/* <StyledControlButton
          box
          icon
          onClick={() => handleSkip(duration - currentTime)}
        >
          <FontAwesomeIcon icon={['fad', 'fast-forward']} />
        </StyledControlButton> */}
      </Flex>
    </Container>
  );
};

export default TimelinePortalControls;
