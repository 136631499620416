import React from 'react';
import PropTypes from 'prop-types';
import { goToUrl } from '../../shared/links';

import Avatar from '../Avatar';
import {
  Container,
  NotificationContent,
  NotificationAction,
  UserName,
  ProjectName,
} from './CommentReplyStyles';

function ProjectTaskAssignment(props) {
  const {
    actionText,

    sendingUserName,
    sendingUserAvatar,
    projectName,
    projectID,
    notificationID,
    isActive,

    handleUpdateStatus,
  } = props;

  const handleClick = () => {
    handleUpdateStatus([notificationID], 1);

    goToUrl({
      projectID,
      view: 'projectContents',
    });
  };

  return (
    <Container isActive={isActive} onClick={handleClick}>
      <Avatar size={30} src={sendingUserAvatar} />
      <NotificationContent>
        <UserName isActive={isActive}>{sendingUserName}</UserName>
        <NotificationAction>&nbsp;{actionText}</NotificationAction>
        <ProjectName isActive={isActive}>&nbsp;{projectName}</ProjectName>
      </NotificationContent>
    </Container>
  );
}

ProjectTaskAssignment.propTypes = {
  actionText: PropTypes.string.isRequired,
  handleUpdateStatus: PropTypes.func.isRequired,
  notificationID: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  projectID: PropTypes.number.isRequired,

  sendingUserName: PropTypes.string,
  sendingUserAvatar: PropTypes.string,
  projectName: PropTypes.string,
  timestamp: PropTypes.string,
};

ProjectTaskAssignment.defaultProps = {
  sendingUserName: '',
  sendingUserAvatar: '',
  projectName: '',
  timestamp: '',
};

export default ProjectTaskAssignment;
