import SceneObject from './SceneObject';
import drawErase from '../app/draw/erase';

class EraseSvg extends SceneObject {
  constructor(args) {
    // Call the super constructor
    super(args);

    // Create the erase element
    this.svg = drawErase(
      this.scene,
      this.doc,
      this.id,
      this.sceneData,
      this.dimensions
    );
    this.anim = this.svg.anim;
    this.type = this.svg.type;
    this.name = this.svg.name;
  }
}

export default EraseSvg;
