import styled from 'styled-components/macro';

import React, { useRef } from 'react';

import Box from './Box';

const removeDragData = (e: React.DragEvent) => {
  if (e.dataTransfer.items) {
    // Use DataTransferItemList interface to remove the drag data
    e.dataTransfer.items.clear();
  } else {
    // Use DataTransfer interface to remove the drag data
    e.dataTransfer.clearData();
  }
};

const StyledBox = styled(Box)`
  height: 100%;
  position: relative;
`;

type Props = {
  onDrop: (e: React.DragEvent) => void;
  onDragLeave?: (e: React.DragEvent) => void;
  onDragOver?: (e: React.DragEvent) => void;
  onDragEnter?: (e: React.DragEvent) => void;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
};

/**
 * A simple dropzone container with drag and drop functionality
 */
function DropZone(props: Props) {
  const {
    onDrop,
    onDragLeave = () => {},
    onDragOver = () => {},
    onDragEnter = () => {},
    children,
    style,
    className,
  } = props;

  const boxRef = useRef<HTMLDivElement>(null);
  // Use counter to keep track of child enter and leaves
  const counterRef = useRef(0);

  const dragEnterHandler = (e: React.DragEvent) => {
    counterRef.current += 1;
    onDragEnter(e);
  };

  const dragOverHandler = (e: React.DragEvent) => {
    e.preventDefault();
    onDragOver(e);
  };

  const dragLeaveHandler = (e: React.DragEvent) => {
    counterRef.current -= 1;
    if (counterRef.current === 0) onDragLeave(e);
  };

  const dropHandler = (e: React.DragEvent) => {
    // Reset counter ref
    counterRef.current = 0;
    onDrop(e);

    // Pass event to removeDragData for cleanup
    removeDragData(e);
  };

  return (
    <StyledBox
      ref={boxRef}
      className={className}
      style={style}
      onDragOver={dragOverHandler}
      onDragLeave={dragLeaveHandler}
      onDragEnter={dragEnterHandler}
      onDrop={dropHandler}
    >
      {children}
    </StyledBox>
  );
}

export default DropZone;
