import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components/macro';

import React, { useState, useRef, useContext, DragEvent } from 'react';

import uuid from '../app/utilities/uuid';
import { getExtension } from '../utilities/files';
import { Store } from '../state/store';
import { fetchActiveProject } from '../state/projects/actions';

import fileManager from '../app/fileManager';
import LoadingProgressBar from './LoadingProgressBar';

import { colors, lighten, fade } from './styles/colors';
import LoadingSpinner from './LoadingSpinner';
import Box from './Box';

function removeDragData(event: React.DragEvent) {
  if (event.dataTransfer.items) {
    // Use DataTransferItemList interface to remove the drag data
    event.dataTransfer.items.clear();
  } else {
    // Use DataTransfer interface to remove the drag data
    event.dataTransfer.clearData();
  }
}

const getFilesFromEvent = (event: React.DragEvent) => {
  const { items } = event.dataTransfer;
  const files = [];

  if (items.length) {
    // Use DataTransferItemList interface to access the file(s)
    for (let i = 0; i < event.dataTransfer.items.length; i += 1) {
      // If dropped items aren't files, reject them
      if (event.dataTransfer.items[i].kind === 'file') {
        const file = event.dataTransfer.items[i].getAsFile();

        files.push(file);
      }
    }
  }

  return files;
};

const getUploadProgress = (progressObject: {
  [index: string]: { total: number; loaded: number };
}) => {
  const keys = Object.keys(progressObject);

  let totalLoaded = 0;
  let totalSize = 0;
  for (let i = 0; i < keys.length; i += 1) {
    totalLoaded += progressObject[keys[i]].loaded;
    totalSize += progressObject[keys[i]].total;
  }

  return totalLoaded / totalSize;
};

const getType = (filename: string) => {
  const i = filename.lastIndexOf('.');
  return i < 0 ? '' : filename.substr(i + 1);
};

type Props = {
  isProjectUpload?: boolean;
  onAfterUpload?: ((res: any) => Promise<void>) | ((res: any) => void);
  onBeforeUpload?: () => Promise<void>;
  fileData?: any;
  types?: string[];
  s3Dir?: string;
  allowMultiple?: boolean;
  uploadText?: string;
  isOverlay?: boolean;
  isHoverable?: boolean;
  className?: string;
  style?: any;
  darkMode?: boolean;
};

function UploadBox(props: Props) {
  const {
    isProjectUpload,
    onAfterUpload,
    onBeforeUpload,
    fileData = {},
    types = [],
    s3Dir,
    allowMultiple,
    uploadText,
    isOverlay,
    isHoverable,
    className,
    style,
    darkMode,
  } = props;

  const context = useContext(Store);
  const {
    state: { activeProject },
    dispatch,
  } = context;

  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(true);
  const [fileProgress, setFileProgress] = useState({});
  const fileProgressRef = useRef({});

  const inputRef = useRef<HTMLInputElement>(null);
  const uploadBoxRef = useRef<HTMLDivElement>(null);

  const onProgress = (
    loaded: number,
    total: number,
    uploader: { id: string }
  ) => {
    const newFileProgress = {
      ...fileProgressRef.current,
      [uploader.id]: {
        loaded,
        total,
      },
    };

    const progress = getUploadProgress(newFileProgress);
    fileProgressRef.current = newFileProgress;

    // Toggle progress off if loaded
    if (progress === 1) {
      setIsLoading(false);
      setFileProgress({});
    } else {
      setFileProgress(newFileProgress);
    }
  };

  /**
   * Trigger click on the Lhidden file input
   *
   * @memberof UploadBox
   */
  const uploadFileClickHandler = () => inputRef.current?.click();

  const handleInputUpload = (e: any) => {
    e.persist();
    const { files } = e.target;

    prepareUpload(files);
  };

  /**
   * Loads upload promises into Promise array
   *
   * @param {Array} files
   * @memberof UploadBox
   */
  const prepareUpload = async (files: File[]) => {
    // Run onBeforeUpload
    if (onBeforeUpload) onBeforeUpload();

    const uploadPromises = [];

    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      uploadPromises.push(uploadFile(file));
    }

    // Update projects after all have uploaded
    const res = await Promise.all(uploadPromises);

    if (isProjectUpload) fetchActiveProject(activeProject.projectID, dispatch);

    if (onAfterUpload) onAfterUpload(res);
  };

  /**
   * Initiate upload of passed file object
   *
   * @param {Native File Object} file
   * @returns {Promise}
   * @memberof UploadBox
   */
  const uploadFile = (file: File) =>
    new Promise((resolve, reject) => {
      // Check that file extension matches upload type
      const type = getType(file.name);
      if (types.length && !types.find((t) => t === type)) {
        reject(new Error('Wrong file type.'));
        // FIXME: ADD ERROR DIALOG BACK IN
        // return dialog('ui/dialogs/Error', {
        //   body: `
        //     <p style="margin-top: 0">Wrong file type! You can only upload ${getTypesMessage(
        //       types
        //     )} files.</p>
        //     <p style="margin-bottom: 0">${errorInstructions}</p>
        //   `,
        // });
      }

      setIsLoading(true);
      setIsFinished(false);

      if (isProjectUpload) {
        // Project file upload
        fileManager
          .uploadProjectFile(file, type, context, {
            // Update total progress for multiple files
            onProgress,
            data: fileData,
            onBeforeUpload,
            setIsFinished,
          })
          .then((res) => resolve(res));
      } else {
        const id = `${uuid()}${getExtension(file.name)}`;
        const newKey = `${s3Dir}/${id}` || id;
        // Generic file upload
        fileManager
          .uploadFile(file, newKey, {
            // Update total progress for multiple files
            onProgress,
            onBeforeUpload,
            setIsFinished,
          })
          .then((res) => resolve(res));
      }
    });

  const handleDragEnter = (e: DragEvent) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault();

    // Only toggle if the container leaving into isn't a child container
    if (!uploadBoxRef.current?.contains(e.relatedTarget as Node))
      setIsDraggingOver(false);
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    const files = getFilesFromEvent(e);

    // Prepare the files for upload
    prepareUpload(files as File[]);

    // Pass event to removeDragData for cleanup
    removeDragData(e);

    setIsDraggingOver(false);
  };

  const acceptAttribute = types.reduce((previous, current) => {
    if (previous) return `${previous},.${current}`;
    return `.${current}`;
  }, '');

  const progress = getUploadProgress(fileProgress);

  return (
    <Container className={className} style={style} isOverlay={!!isOverlay}>
      <UploadBoxWrapper isOverlay={!!isOverlay}>
        <UploadBoxContainer
          ref={uploadBoxRef}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onClick={uploadFileClickHandler}
          isOverlay={!!isOverlay}
          isHoverable={!!isHoverable}
          isDraggingOver={!!isDraggingOver}
          isLoading={!!isLoading}
          darkMode={!!darkMode}
        >
          {isOverlay && <BackgroundOverlay />}

          <DraggingOverContainer isDraggingOver={isDraggingOver}>
            <DraggingOverText>
              <DraggingOverIcon icon={['fad', 'file-upload']} />
              <span>Drop File!</span>
            </DraggingOverText>
          </DraggingOverContainer>

          {!isLoading && isFinished && (
            <TextContainer
              isOverlay={!!isOverlay}
              isDraggingOver={isDraggingOver}
              darkMode={!!darkMode}
            >
              <Text darkMode={!!darkMode}>
                <FontAwesomeIcon icon={['fad', 'file-upload']} />
                {uploadText}
              </Text>
            </TextContainer>
          )}

          {isLoading && <LoadingProgressBar progress={progress || 0} />}
          {!isFinished && !isLoading && (
            <Box display={'flex'} justifyContent={'center'}>
              <LoadingSpinner />
            </Box>
          )}
        </UploadBoxContainer>
      </UploadBoxWrapper>

      <HiddenInput
        ref={inputRef}
        onChange={handleInputUpload}
        accept={acceptAttribute}
        multiple={allowMultiple}
        type="file"
      />
    </Container>
  );
}

const Container = styled.div`
  height: 100%;

  ${(props: { isOverlay: boolean }) =>
    props.isOverlay &&
    css`
      width: 100%;
      height: 100%;
    `}
`;

const HiddenInput = styled.input`
  display: none;
`;

type DraggingOverContainerProps = {
  isDraggingOver: boolean;
};

const DraggingOverContainer = styled.div`
  position: absolute;
  background-color: ${fade(colors.blue, 20)};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;

  opacity: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  pointer-events: none;

  transition: 0.1s ease-in-out all;

  ${(props: DraggingOverContainerProps) =>
    props.isDraggingOver &&
    css`
      opacity: 1;
    `}
`;

const Text = styled.p`
  margin: 0;
  color: ${(props: { darkMode: boolean }) =>
    props.darkMode ? fade('white', 30) : colors['light-grey-10']};
`;

type TextContainerProps = {
  darkMode: boolean;
  isDraggingOver: boolean;
  isOverlay: boolean;
};

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  transition: 0.1s ease-in-out all;

  .or {
    color: ${(props: TextContainerProps) =>
      props.darkMode ? fade('white', 30) : colors['light-grey-30']};
  }

  i,
  svg {
    margin-right: 10px;
  }

  ${(props: TextContainerProps) =>
    props.isOverlay &&
    css`
      position: relative;
      z-index: 1;

      p,
      i,
      svg {
        color: white;
      }
    `}

  ${(props: TextContainerProps) =>
    props.isDraggingOver &&
    css`
      opacity: 0;
    `}
`;

const DraggingOverText = styled.h3`
  color: white;
`;

const DraggingOverIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
  color: white;
`;

const UploadBoxWrapper = styled.div`
  height: 100%;

  ${(props: { isOverlay: boolean }) =>
    props.isOverlay &&
    css`
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 15px;
    `}
`;

type UploadBoxContainerProps = {
  isOverlay: boolean;
  isHoverable: boolean;
  isLoading: boolean;
  isDraggingOver: boolean;
  darkMode: boolean;
};

const UploadBoxContainer = styled.div`
  padding: 15px;

  position: relative;

  width: 100%;
  background: transparent;
  border: 2px dashed
    ${(props: UploadBoxContainerProps) =>
      props.darkMode ? fade('white', 75) : colors['light-grey-40']};
  border-radius: 3px;

  cursor: pointer;

  :hover,
  :focus {
    background: ${(props: UploadBoxContainerProps) =>
      props.darkMode ? fade('white', 90) : lighten(colors.grey, 55)};
  }

  :active {
    background: ${(props: UploadBoxContainerProps) =>
      props.darkMode ? fade('white', 95) : lighten(colors.grey, 50)};
  }

  ${(props: UploadBoxContainerProps) =>
    props.isOverlay &&
    css`
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      opacity: 0;

      transition: 0.1s ease-in-out all;

      :hover,
      :focus {
        background: transparent;
      }

      ${props.isHoverable &&
      css`
        ${Container}:hover & {
          opacity: 1;
        }
      `}

      ${(props.isDraggingOver || props.isLoading) &&
      css`
        opacity: 1;
      `}
    `}
`;

const BackgroundOverlay = styled.div`
  pointer-events: none;

  background: ${fade('black', 50)};

  width: 100%;
  height: 100%;
  position: absolute;
`;

UploadBox.defaultProps = {
  onAfterUpload: () => Promise.resolve(),
  onBeforeUpload: () => Promise.resolve(),
  allowMultiple: false,
  uploadText: 'Drop or choose from files',
  errorInstructions: '',
  fileData: {},
  isOverlay: false,
  isHoverable: true,
  isProjectUpload: true,
  key: '',
  style: {},
  darkMode: false,
};

export default UploadBox;
