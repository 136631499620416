import { isInputActive } from '../keydown';
import modifiersMap from '../keyModifierMap';

const modifiers = ['shiftKey', 'ctrlKey', 'altKey', 'metaKey'];
const keySymbolMap = {
  cmd: '⌘',
  shift: '⇧',
};

const keyPressed = (name, e) =>
  name.toLowerCase() === e.key.toLowerCase() ||
  name.toLowerCase() === e.code.toLowerCase();

/**
 * Manages a group of hotkeys
 *
 * @class HotkeyManager
 * @property {Object} hotkeys
 * {
 *   [HOTKEY_NAME]: {
 *     name: String, // the event.key name property
 *     modifiers: [String], // (optional) an array of modifiers that must be present, e.g. 'cmd', 'shift', etc.
 *     priority: Number, // (optional) order in which the onPress functions will fire if more than one with the same name are present
 *     onPress: Function, // handler that fires when criteria are met,
 *        // returns Boolean - if true, stop propagation to next key with the same name (should pair with priority)
 *   }
 * }
 */
class HotkeyManager {
  constructor(hotkeys = {}) {
    this.hotkeys = hotkeys;

    document.addEventListener('keydown', this.handleKeydown.bind(this));
  }

  handleKeydown(e) {
    // Don't activate hotkeys when inputs are active
    if (!isInputActive()) {
      // Find the keys that were pressed (can have multiple of the same with different modifiers)
      let keysPressed = Object.values(this.hotkeys).filter((k) =>
        k.names // Handle an array of names
          ? k.names.some((name) => keyPressed(name, e))
          : keyPressed(k.name, e)
      );

      if (keysPressed.length > 1) {
        // If there are more than one hotkey that trigger, check if any have priority
        keysPressed = keysPressed.sort((a, b) => a.priority - b.priority);
      }

      keysPressed.every((key) => {
        if (key) {
          if (key.modifiers) {
            // Make sure all the passed modifiers are the same as the event's modifiers
            const modifiersMet = modifiers.every(
              (modifier) =>
                e[modifier] ===
                !!key.modifiers.find(
                  (m) => modifiersMap[m.toLowerCase()] === modifier
                )
            );
            if (!modifiersMet) return true; // Continue loop
          }

          const stopPropagation = key.onPress(e);

          // TODO: MIGHT STILL BE SOME ISSUES TO SUSS OUT HERE
          if (stopPropagation) return false;
        }

        return true;
      });
    }
  }

  register(newHotkeys) {
    this.hotkeys = {
      ...this.hotkeys,
      ...newHotkeys,
    };
  }

  get(identifer) {
    return this.hotkeys[identifer];
  }

  getKeyString(identifier) {
    const hotkey = this.get(identifier);

    if (hotkey) {
      const name = hotkey.names ? hotkey.names[0] : hotkey.name;
      const modifierString = hotkey.modifiers
        ?.map((modifier) => keySymbolMap[modifier] || modifier)
        .join('');

      if (modifierString) {
        return `${modifierString}${name}`;
      }

      return name;
    }

    return null;
  }
}

export default HotkeyManager;
