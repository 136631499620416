import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import React, { useContext } from 'react';

import { post } from '../../util/fetchUtil';

import { Store } from '../../state/store';
import {
  fetchActiveProject,
  removeProject,
} from '../../state/projects/actions';

import { goToUrl } from '../../shared/links';

import { HeaderSection, SectionTitle } from '../views/ProjectContentsStyles';

import Button from '../RebassButton';

import { colors } from '../styles/colors';
import WarningDialog from '../dialogs/WarningDialog';
import useModal from '../../app/hooks/useModal';
import Dialog from '../Dialog';

function StatusSection(props) {
  const {
    activeProject,
    activeProject: { projectID, members },
  } = props;

  const {
    dispatch,
    state: { projects },
  } = useContext(Store);

  const isProjectOwner =
    members &&
    !!members.find((user) => user.userID === window.userID && user.owner);

  const archiveProject = () => {
    post('/project/updateStatus', {
      projectID,
      status: 2,
    }).then(() => {
      // Update projects array
      fetchActiveProject(projectID, dispatch);
    });
  };

  const reactivateProject = () => {
    post('/project/updateStatus', {
      projectID,
      status: 1,
    }).then(() => {
      // Update projects array
      fetchActiveProject(projectID, dispatch);
    });
  };

  const deleteProject = () => {
    post('/admin/projects/delete', {
      projectID,
    }).then(() => {
      // Update projects array (remove project)
      removeProject(projectID, projects, dispatch);

      goToUrl({ view: 'home' });
    });
  };

  const deleteProjectDialog = useModal();

  let status;
  switch (activeProject.status) {
    case 1:
      status = 'Active';
      break;
    case 2:
      status = 'Archived';
      break;
    default:
      status = null;
      break;
  }

  return (
    <Container>
      <Dialog
        isVisible={deleteProjectDialog.isVisible}
        handleHideDialog={() => deleteProjectDialog.hide()}
      >
        <WarningDialog
          header="Are you sure you want to delete this project?"
          onConfirm={deleteProject}
          color={colors.red}
        />
      </Dialog>
      <HeaderSection>
        <SectionTitle>Status</SectionTitle>
      </HeaderSection>
      <Status status={status}>{status}</Status>
      <Actions>
        {status === 'Active' && (
          <Button
            size="s"
            color="dark-6"
            variant="outline"
            onClick={archiveProject}
            enable={isProjectOwner}
          >
            Archive
          </Button>
        )}
        {status === 'Archived' && (
          <Button
            size="s"
            color="dark-6"
            variant="outline"
            onClick={reactivateProject}
            enable={isProjectOwner}
          >
            Reactivate
          </Button>
        )}
        <Button
          size="s"
          ml="10px"
          color="negative"
          variant="outline"
          onClick={() => deleteProjectDialog.show()}
          enable={isProjectOwner}
        >
          Delete
        </Button>
      </Actions>
    </Container>
  );
}

const Container = styled.div``;

const Actions = styled.div`
  margin-top: 15px;
  display: flex;
`;

const Status = styled.div`
  padding: 5px 10px;
  display: inline-block;
  border-radius: 3px;

  ${(props) =>
    props.status === 'Active' &&
    css`
      background: ${colors['light-green-40']};
      color: ${colors['dark-green-10']};
    `}

  ${(props) =>
    props.status === 'Archived' &&
    css`
      background: ${colors['light-grey-50']};
      color: ${colors['dark-grey-10']};
    `}
`;

StatusSection.propTypes = {
  activeProject: PropTypes.shape({
    projectID: PropTypes.number,
    members: PropTypes.array,
    status: PropTypes.number,
  }),
};

StatusSection.defaultProps = {
  activeProject: {},
};

export default StatusSection;
