import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { DragHandle } from './CreditsStyles';
import Button from '../Button';

import { colors } from '../styles/colors';

function EntryUI(props) {
  const {
    credit,
    credit: { role, name, attribution, shareAlike, id },
    handleToggleCC,
    handleRemoveCredit,
  } = props;

  return (
    <Container>
      <DragHandle>
        <FontAwesomeIcon icon={['fad', 'grip-vertical']} />
      </DragHandle>
      <Content>
        <TextContainer>
          <Text>{role}</Text>
        </TextContainer>
        <TextContainer>
          <Text>{name}</Text>
        </TextContainer>
        <Controls>
          <IconButton
            onClick={() => handleToggleCC(credit, 'attribution')}
            box
            basic={attribution !== 'true'}
            color={attribution !== 'true' ? null : 'white'}
            backgroundColor={attribution === 'true' ? colors.green : null}
          >
            <FontAwesomeIcon icon={['fas', 'male']} />
          </IconButton>
          <IconButton
            onClick={() => handleToggleCC(credit, 'shareAlike')}
            box
            basic={shareAlike !== 'true'}
            color={shareAlike !== 'true' ? null : 'white'}
            backgroundColor={shareAlike === 'true' ? colors.green : null}
          >
            <FontAwesomeIcon icon={['fas', 'undo-alt']} />
          </IconButton>
          <IconButton
            onClick={() => handleRemoveCredit(id)}
            icon
            thin
            basic
            color="red"
          >
            <FontAwesomeIcon icon={['fas', 'times']} />
          </IconButton>
        </Controls>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 15px;

  display: flex;
  justify-content: space-between;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

const TextContainer = styled.div`
  width: 150px;
  margin-right: 10px;
`;

const Text = styled.h3`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  margin: 0;
`;

const Controls = styled.div`
  display: flex;
`;

const IconButton = styled(Button)`
  i,
  svg {
    font-size: 14px;
  }
  margin-left: 5px;
`;

EntryUI.propTypes = {
  credit: PropTypes.shape({
    name: PropTypes.string,
    role: PropTypes.string,
    attribution: PropTypes.string, // Backend converted from bool to string
    shareAlike: PropTypes.string, // Backend converted from bool to string
    id: PropTypes.string,
  }).isRequired,
  handleToggleCC: PropTypes.func.isRequired,
  handleRemoveCredit: PropTypes.func.isRequired,
};

EntryUI.defaultProps = {};

export default EntryUI;
