import seedrandom from 'seedrandom';
import $ from 'jquery';
import font from '../font';
import characterData from '../data/characters';
import replaceTags from './replaceTags';
import uuid from '../utilities/uuid';

// The font size used when designing the paths for each letter
const PATH_FONT_SIZE = 300;

export const getMetrics = (fontWeightNumber) => {
  let metrics = null;
  let fontWeight = null;

  // Parse numerical fontWeight to fontWeight
  switch (fontWeightNumber.toString()) {
    case '200':
      fontWeight = 'light';
      metrics = font.metrics.Light;
      break;
    case '700':
      fontWeight = 'bold';
      metrics = font.metrics.Bold;
      break;
    default:
      fontWeight = 'regular';
      metrics = font.metrics.Regular;
  }

  return { metrics, fontWeight };
};

/**
 * Get a decoded HTML string with html characters
 *
 * @param {String} html
 * @returns {String} - decoded HTML, e.g. converts & to &amp;
 */
export const decodeHtml = (html) => {
  const text = document.createElement('textarea');
  // Replace tags
  const newHtml = replaceTags(html);

  text.innerHTML = newHtml;
  return text.value;
};

window.decodeHtml = decodeHtml;

/**
 * Gets the info for an array of characters
 *
 * @param {[Object]} characters
 * [{
 *  char: String,
 *  fontWeight: String,
 *  paths: [{
 *   d: String,
 *   stroke: String,
 *   strokeLinecap: String,
 *   strokeLinejoin: String,
 *   strokeMiterLimit: String,
 *   strokeWidth: Number,
 *   totalLength: Number,
 * }],
 *  scale: { x: Number, y: Number },
 *  stroke: String,
 *  totalLength: Number,
 *  x: Number,
 *  y: Number,
 * char: "N"
 * }]
 * @param {Number} currentIndex - current character index
 * @returns {Object}
 * {
 *  charName: {String}, // character string, e.g. 'x'
 *  incrementCount: {Number},
 * }
 */
function getCharInfo(characters, currentIndex) {
  let charName = '';
  const incrementCount = 0;

  const char = decodeHtml(characters[currentIndex].char);

  // Check for white space
  if (char.charCodeAt(0) === 32 || char.charCodeAt(0) === 160) {
    // If space (32) or non-breaking space (160) entered, add space
    charName = 'space';
  } else if (char.charCodeAt(0) === 10) {
    // If newline (10) ↵
    charName = 'nonmarkingreturn';
  } else if (char.charCodeAt(0) === 9) {
    // If tab (9) add 8 spaces to xPosition
    // TODO: tab may need to be added to font?
    // TODO: ADD TO ARRAY OF ERRORS
    // eslint-disable-next-line no-alert
    alert('Sorry, no tabs yet!', 'Whoops!');
  } else {
    // Find the character in characters.js
    const characterTypeKeys = Object.keys(characterData);
    for (let i = 0; i < characterTypeKeys.length; i += 1) {
      const type = characterTypeKeys[i];
      const charEntry = characterData[type].find(
        (c) =>
          c.key === char || // Check if key exists
          (c.alt && c.alt.find((alternate) => alternate === char))
      ); // Check if there are alternate options

      if (charEntry) {
        // Lowercase letters denoted by an underscore on the end, e.g. 'a_'
        const isLowercase = charEntry.name[charEntry.name.length - 1] === '_';
        if (isLowercase) {
          // Only use first character if it's a lowercase letter (i.e. parse out the underscore)
          charName = charEntry.name.slice(0, -1);
        } else {
          charName = charEntry.name;
        }

        break;
      }
    }
  }

  return { charName, incrementCount };
}

/**
 * Helper function to parse an HTML string to HTML elements and return an array of paths
 *
 * @param {String} htmlString
 * @param {Object} attributes
 * @returns
 */
function getPathsFromHTML(htmlString, attributes) {
  const html = $.parseHTML(htmlString);
  const paths = [];

  const searchChildren = (child) => {
    if (child.localName === 'path') {
      const d = child.getAttribute('d');
      // Create ghost paths to get their properties
      const path = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'path'
      );
      path.setAttribute('d', d);

      paths.push({ ...attributes, d, totalLength: path.getTotalLength() });
    } else if (child.children.length > 0) {
      for (let i = 0; i < child.children.length; i += 1) {
        searchChildren(child.children[i]);
      }
    }
  };

  if (html) {
    for (let i = 0; i < html.length; i += 1) {
      // Recursive search of children
      searchChildren(html[i]);
    }
  }

  return paths;
}

/**
 * Get the kern for two adjacent glyphs
 *
 * @param {Object} glyph - glyph properties
 * @param {Object} nextGlyph - the next glyph properties
 * @param {Object} metrics - font metrics
 * @returns
 */
function getKern(glyph, nextGlyph, metrics) {
  let kern = 0;
  if (glyph && nextGlyph) {
    const key = `${glyph.index.toString()},${nextGlyph.index.toString()}`;
    kern = metrics.kerningPairs[key] ? metrics.kerningPairs[key] : 0;
  }
  return kern;
}

const randomIntFromInterval = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

/**
 * Generate the SVG text characters from a text string
 *
 * @param {String} text
 * @returns {Object}
 */
const getText = (text) => {
  const newText = { ...text };
  const { tannerscript } = font;

  const errors = [];

  // subsequent calls to Math.random() will be remain the same as long as 'textContent' stays the same
  seedrandom(newText.raw, { global: true });

  // Store cumulative x position of letters
  let xPosition = 0;
  let lineStartPx = 0;

  for (let j = 0; j < newText.characters.length; j += 1) {
    // Create <g> to store character
    // const characterGroup = draw.group();
    const character = newText.characters[j];

    // Variable to store type of font (regular, bold, etc.)
    const { metrics, fontWeight } = getMetrics(character.fontWeight);

    // Variable to hold original bbox of <text> element
    const { position, fontSize, lineHeight, transform } = text;

    // Number of pixels per em for 72 DPI
    const pixelsPerEm = 0.29875;

    // Get the ascender and descender
    const { ascender, descender } = metrics;

    // Convert font metrics to pixels
    const lineHeightPx = fontSize * lineHeight;
    const ascenderPx = (ascender / 1000) * fontSize;
    const descenderPx = (descender / 1000) * fontSize;
    const textHeightPx = ascenderPx + descenderPx;
    const lineHeightPaddingPx = (lineHeightPx - textHeightPx) / 2;

    // Get char info for current character (use currentIndex j and full array for finding html codes)
    const charInfo = getCharInfo(newText.characters, j);
    const nextCharInfo = newText.characters[j + 1]
      ? getCharInfo(newText.characters, j + 1)
      : '';

    // Increment j if html code found; set charName
    if (charInfo.incrementCount) {
      j += charInfo.incrementCount;
    }

    let { charName } = charInfo;
    let glyph = Object.values(metrics.glyphs).filter(
      (g) => g.name === charInfo.charName
    )[0];

    const nextGlyph = nextCharInfo
      ? Object.values(metrics.glyphs).filter(
          (g) => g.name === nextCharInfo.charName
        )[0]
      : '';

    if (!glyph) {
      const message = `
        Glyph for "${character.char}" does not exist in the Tannerscript library.
        Try using a different character or submit a request for the character to be created.
      `;

      errors.push({
        id: uuid(),
        type: 'text',
        message,
      });

      // Add red question mark in place of missing glyph
      glyph = Object.values(metrics.glyphs).find((g) => g.name === 'question');
      charName = 'question';
      character.stroke = 'red';
    }

    // Set width to advance character
    const advanceWidth = glyph.advanceWidth * pixelsPerEm;
    const kern = getKern(glyph, nextGlyph, metrics) * pixelsPerEm;
    // const yMax = glyph.yMax * pixelsPerEm;
    const yMaxPx = (glyph.yMax / 1000) * fontSize;

    // Calculate scale factor
    const fontScaleFactor = fontSize / PATH_FONT_SIZE;

    let animPaths;

    // Checks global 'tannerscript' object for character
    if (charName === 'space') {
      xPosition += advanceWidth * fontScaleFactor;
    } else if (charName === 'nonmarkingreturn') {
      xPosition = 0;
      // Increment line start by the lineHeight
      lineStartPx += lineHeightPx;
    } else if (tannerscript[fontWeight][charName]) {
      // Get random index to choose letter
      const randomIndex = randomIntFromInterval(
        0,
        tannerscript[fontWeight][charName].anim.length - 1
      );

      const strokeWidthMap = {
        bold: 65,
        regular: 50,
        light: 35,
      };

      animPaths = getPathsFromHTML(
        tannerscript[fontWeight][charName].anim[randomIndex],
        {
          strokeWidth: strokeWidthMap[fontWeight],
          fill: 'none',
          stroke: character.stroke,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeMiterlimit: '1.5',
        }
      );

      character.paths = animPaths;
      character.totalLength = animPaths.reduce(
        (total, p) => total + p.totalLength,
        0
      );

      // Add clipping paths
      const clipPaths = getPathsFromHTML(
        tannerscript[fontWeight][charName].scene[randomIndex]
      );

      character.clipPaths = clipPaths;

      // Move the character forward and add a random amount of deviation from the center between -1 and 1 pixels
      const randomJitterY = randomIntFromInterval(-1, 1);

      const [scaleX, , , scaleY, translateX] = transform;

      const textTransformX = translateX + position.x * scaleX;

      // SCALE CHARACTER //
      const scale = {
        x: fontScaleFactor * scaleX,
        y: fontScaleFactor * scaleY,
      };
      const x = textTransformX + xPosition * scaleX;
      const y =
        (lineHeightPaddingPx + // The distance from the top of the ascender to the top of the line for line height
          (textHeightPx - yMaxPx) + // The distance of lowercase letters from the top of the text height
          lineStartPx + // The new line if multiline
          randomJitterY) * // Random movement in the y direction
        scaleY; // Add transformY which accounts for scaling and y position

      character.x = x;
      character.y = y;
      character.scale = scale;

      // Increment xPosition by width of character, bearing between letters,
      // and any kerning (all adjusted with scaling factor)
      xPosition += (advanceWidth + kern) * fontScaleFactor;
    }
  }

  // Add in a slight pause between each subsequent path for a character to emulate hand-writing
  const pauseLength = 100;

  newText.totalLength = newText.characters.reduce((total, c) => {
    // If total length has been calculated, character has paths
    if (c.totalLength) {
      return total + c.totalLength + c.paths.length * pauseLength;
    }

    // Otherwise it's just a space
    return total + pauseLength;
  }, 0);

  let currentLength = 0;
  newText.characters = newText.characters.map((char) => ({
    ...char,
    ...(char.paths
      ? {
          paths: char.paths.map((path) => {
            const currentPathLength = currentLength;
            currentLength += path.totalLength + pauseLength;
            return {
              ...path,
              start: (currentPathLength + pauseLength) / newText.totalLength,
              end:
                (currentPathLength + pauseLength + path.totalLength) /
                newText.totalLength,
            };
          }),
        }
      : {}),
  }));

  return { text: newText, errors };
};

/**
 * Render the text as an animatable group of paths
 *
 * @param {String} rawText
 * @param {HTMLElement} textElement - an SVG text element
 * @returns {Object}
 */
export const render = (rawText, props = {}) => {
  const { style = {} } = props;
  const { fontWeight, color, fontSize, lineHeight } = style;

  const decodedHtml = decodeHtml(rawText);

  const characters = decodedHtml.split('').map((char) => ({
    char,
    fontWeight: fontWeight || '400',
    stroke: color || 'black',
  }));

  // NOTE: CAN MAYBE REMOVE THIS
  const matrixStringArray = [1, 0, 0, 1, 0, 0];

  const text = {
    characters,
    raw: decodedHtml,
    // bbox,
    fontSize,
    lineHeight: lineHeight || 1.5, // TODO: GET BROWSER DEFAULT LINE HEIGHT?
    position: {
      x: 0,
      y: 0,
    },
    transform: matrixStringArray,
  };

  const res = getText(text);

  return { text: res.text, errors: res.errors };
};

export default {
  render,
  getMetrics,
};
