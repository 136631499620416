import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useEffect } from 'react';

import moment from 'moment';

import fetchUtil from '../../util/fetchUtil';

import { goToUrl } from '../../shared/links';

import { Header } from '../views/ProfileStyles';
import Button from '../Button';
import { H4, P } from '../styles/typography';
import { colors, lighten } from '../styles/colors';

import UserChart from './UserChart';

import getTasks from '../../app/analytics/getTasks';

const rangeOptions = [
  {
    text: 'This month',
    value: 'thisMonth',
    date: moment().month(),
    filter: (task) => moment(task.date).month() === moment().month(),
  },
  {
    text: 'Last month',
    value: 'lastMonth',
    date: moment().subtract(1, 'month').month(),
    filter: (task) =>
      moment(task.date).month() === moment().subtract(1, 'month').month(),
  },
  { text: 'All time', value: 'allTime', filter: (task) => task },
];

export default function AnalyticsSection(props) {
  const [projects, setProjects] = useState([]);
  const [selectedRange, setSelectedRange] = useState(rangeOptions[0].value);
  const [byProject, setByProject] = useState(true);

  useEffect(() => {
    fetchUtil
      .post('/tracking/getUserTasks')
      .then((res) => setProjects(res.tracking));
  }, []);

  const selectRange = (range) => {
    setSelectedRange(range.value);
  };

  const rangeOption = rangeOptions.find(
    (option) => option.value === selectedRange
  );
  const filteredProjects = projects.map((project) => ({
    ...project,
    data: {
      ...project.data,
      tasks:
        project.data.tasks && project.data.tasks.filter(rangeOption.filter),
    },
  }));

  const tasks = getTasks(filteredProjects);

  const rangeComponents = rangeOptions.map((range) => {
    const isSelected = selectedRange === range.value;
    return (
      <div key={range.value}>
        <Button
          thin
          box
          basic={!isSelected}
          color={isSelected ? 'white' : ''}
          backgroundColor={isSelected ? colors.blue : ''}
          onClick={() => selectRange(range)}
        >
          {range.text}
        </Button>
      </div>
    );
  });

  const projectComponents = tasks.map((task) => (
    <ProjectWrapper key={task.taskID}>
      <Subheader>{task.taskName}</Subheader>
      {selectedRange !== 'allTime' && (
        <UserChart byProject={byProject} task={task} month={rangeOption.date} />
      )}
      <StatisticsContainer>
        <StatisticContainer>
          <StatisticValue>{task.list.length}</StatisticValue>
          <StatisticHeader>Projects</StatisticHeader>
        </StatisticContainer>
        <StatisticContainer>
          <StatisticValue>{task.hours}</StatisticValue>
          <StatisticHeader>Hours</StatisticHeader>
        </StatisticContainer>
        <StatisticContainer>
          <StatisticValue>{task.wordCount}</StatisticValue>
          <StatisticHeader>Words</StatisticHeader>
        </StatisticContainer>
      </StatisticsContainer>
      <div>
        {task.list.map((project) => (
          <ProjectItem
            key={project.projectID}
            onClick={() =>
              goToUrl({
                projectID: project.projectID,
                newWindow: true,
                view: 'projectContents',
              })
            }
          >
            <ProjectName>{project.projectName}</ProjectName>
            <CompletionDate>
              &nbsp;-&nbsp;{moment(project.date).format('MM-DD-YY')}
            </CompletionDate>
          </ProjectItem>
        ))}
      </div>
    </ProjectWrapper>
  ));

  return (
    <Container>
      <Header>Analytics</Header>
      <Controls>
        <RangeSelection>
          <RangeHeader>Data type</RangeHeader>
          <RangeButtons>
            <Button
              thin
              box
              basic={!byProject}
              color={byProject && 'white'}
              backgroundColor={byProject && colors.blue}
              onClick={() => setByProject(true)}
            >
              Project
            </Button>
            <Button
              thin
              box
              basic={byProject}
              color={!byProject ? 'white' : ''}
              backgroundColor={!byProject ? colors.blue : ''}
              onClick={() => setByProject(false)}
            >
              Words
            </Button>
          </RangeButtons>
        </RangeSelection>
        <VerticalDivider />
        <RangeSelection>
          <RangeHeader>Date range</RangeHeader>
          <RangeButtons>{rangeComponents}</RangeButtons>
        </RangeSelection>
      </Controls>
      {projectComponents}
      {!projectComponents.length && (
        <EmptyMessage className="message">
          <Emoji>
            <span role="img" aria-label="Shrug">
              🤷‍♂️
            </span>
          </Emoji>
          No data in this range.
        </EmptyMessage>
      )}
    </Container>
  );
}

const Container = styled.div``;
const ProjectItem = styled.div`
  padding: 5px 10px;
  border-radius: 3px;
  background: ${lighten(colors.grey, 50)};

  display: flex;

  cursor: pointer;

  :hover,
  :focus {
    background: ${lighten(colors.grey, 45)};
  }

  :active {
    background: ${lighten(colors.grey, 40)};
  }

  :not(:last-child) {
    margin-bottom: 5px;
  }
`;

const ProjectName = styled(P)``;
const CompletionDate = styled(P)`
  color: ${colors['light-grey-30']};
`;

const ProjectWrapper = styled.div`
  background: #ececec;
  padding: 15px;
  margin-top: 15px;
  border-radius: 5px;
`;

const RangeHeader = styled(H4)`
  margin-bottom: 10px;
  color: ${colors['light-grey-30']};
`;

const RangeButtons = styled.div`
  display: flex;

  > * {
    :not(:last-child) {
      margin-right: 10px;
    }
  }
`;
const Controls = styled.div`
  display: flex;
`;

const VerticalDivider = styled.div`
  margin-left: 15px;
  margin-right: 15px;
  width: 1px;

  background: ${colors['light-grey-40']};
`;

const StatisticHeader = styled.p`
  margin: 0;
  margin-bottom: 5px;
  color: ${colors['light-grey-20']};
`;

const StatisticValue = styled.h1`
  margin: 0;
  color: ${colors.grey};
  margin-right: 10px;
`;

const StatisticContainer = styled.div`
  padding: 10px;
  border-radius: 3px;
  /* background: ${colors['light-grey-50']}; */
  margin-right: 30px;

  display: flex;
  /* flex-direction: column; */
  align-items: flex-end;
`;

const StatisticsContainer = styled.div`
  display: flex;
`;

const Subheader = styled(H4)`
  margin-bottom: 10px;
`;

const RangeSelection = styled.div``;

const EmptyMessage = styled.div`
  display: flex;
  align-items: center;
`;

const Emoji = styled.p`
  margin: 0;
  margin-right: 15px;

  font-size: 24px;
`;

AnalyticsSection.propTypes = {
  propName: PropTypes.string,
};

AnalyticsSection.defaultProps = {
  propName: '',
};
