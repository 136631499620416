// Store the current preferences for new elements,
// e.g. new path elements will use the strokeWidth currently
// defined here.
export const style = {
  // Multiple
  stroke: 'black',
  fill: 'transparent',

  // Rect
  rx: 0,

  // Path
  strokeWidth: 16,

  // Text
  fontWeight: 400,
  fontSize: 64,
  color: 'black',
};

export const path = {
  pressure: [
    [0, 1],
    [1, 1],
  ],
};

export default {
  path,
  style,
};

type PreferenceValues = string | boolean;
type PreferenceKeys =
  | 'timelineCommentsVisible'
  | 'leftPanelView'
  | 'timelineLayersVisible';
type PreferenceMap = {
  [key in PreferenceKeys]: PreferenceValues;
};

export const getPreferences = (): PreferenceMap => {
  if (window.localStorage) {
    const prefString = window.localStorage.getItem('editorPreferences');
    if (prefString) {
      return JSON.parse(prefString);
    }

    return {} as PreferenceMap;
  }

  return {} as PreferenceMap;
};

export const getPreference = (key: PreferenceKeys): PreferenceValues => {
  const prefs = getPreferences();
  return prefs[key];
};

export const updatePreference = (
  key: PreferenceKeys,
  value: PreferenceValues
): void => {
  if (window.localStorage) {
    const prefs = getPreferences();
    if (prefs) {
      prefs[key] = value;

      window.localStorage.setItem('editorPreferences', JSON.stringify(prefs));
    }
  }
};
