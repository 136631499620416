import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import React, { useState, useContext, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserTask from '../profile/UserTask';
import { Store } from '../../state/store';

import { CompletedAnimation } from '../views/ProfileStyles';

import {
  HeaderSection,
  SectionTitle,
  Icon,
} from '../views/ProjectContentsStyles';

import { colors } from '../styles/colors';
import { A } from '../styles/typography';
import Button from '../RebassButton';
import Dialog from '../Dialog';
import TaskDialog from './TaskDialog';
import useModal from '../../app/hooks/useModal';

const StyledUserTask = styled(UserTask)`
  margin-bottom: 15px;

  ${(props) =>
    props.completed &&
    css`
      margin-bottom: 5px;
    `}
`;

const Container = styled.div``;
const StyledA = styled(A)`
  color: ${colors['light-grey-30']};
  text-decoration: underline;

  :hover,
  :focus {
    color: ${colors['light-grey-20']};
    text-decoration: underline;
  }
`;

function ResourcesSection(props) {
  const { getTasks, tasks } = props;

  const taskDialog = useModal();

  const {
    state: {
      activeProject,
      user,
      activeProject: { projectID },
    },
  } = useContext(Store);
  const [showCompletedOverflow, setShowCompletedOverflow] = useState(false);

  useEffect(() => {
    if (projectID) {
      getTasks();
    }
    // eslint-disable-next-line
  }, [projectID]);

  const completedTasks = tasks.filter((task) => task.status === 2);

  const completedTaskComponents = completedTasks.splice(0, 5).map((task) => (
    <CompletedAnimation
      key={task.taskID}
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
    >
      <StyledUserTask
        task={task}
        onAfterSave={getTasks}
        completed
        checkColor={colors.grey}
      />
    </CompletedAnimation>
  ));

  const completedTaskOverflowComponents = completedTasks.map((task) => (
    <CompletedAnimation
      key={task.taskID}
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0 }}
    >
      <StyledUserTask
        task={task}
        onAfterSave={getTasks}
        completed
        checkColor={colors.grey}
      />
    </CompletedAnimation>
  ));

  const incompleteTaskComponents = tasks
    .filter((task) => task.status === 1)
    .map((task) => (
      <StyledUserTask
        key={task.taskID}
        task={task}
        onAfterSave={getTasks}
        showUser
        checkColor={colors.grey}
      />
    ));

  return (
    <Container>
      <Dialog
        isVisible={taskDialog.isVisible}
        handleHideDialog={() => taskDialog.hide()}
      >
        <TaskDialog
          task={{
            project: activeProject,
            userID: user.userID,
            data: {},
          }}
          handleHideDialog={() => taskDialog.hide()}
          onAfterSave={getTasks}
        />
      </Dialog>

      <HeaderSection>
        <SectionTitle>
          <Icon icon={['fad', 'tasks']} />
          Tasks
        </SectionTitle>
      </HeaderSection>
      {incompleteTaskComponents}
      {completedTaskComponents}
      {showCompletedOverflow && completedTaskOverflowComponents}
      {!!completedTasks.length && !showCompletedOverflow && (
        <StyledA
          onClick={() => setShowCompletedOverflow(!showCompletedOverflow)}
        >
          Show {completedTasks.length} more completed tasks
        </StyledA>
      )}
      <Button
        variant="outline"
        color="dark-6"
        onClick={() => taskDialog.show()}
        startIcon={<FontAwesomeIcon icon={['fas', 'plus']} />}
      >
        Add task
      </Button>
    </Container>
  );
}

ResourcesSection.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape({})),
  getTasks: PropTypes.func,
};

export default ResourcesSection;
