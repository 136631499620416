import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { DragHandle } from './CreditsStyles';
import Button from '../Button';

import { colors } from '../styles/colors';

function SectionUI(props) {
  const {
    credit: { title, id },
    handleRemoveCredit,
  } = props;

  return (
    <Container>
      <DragHandle>
        <FontAwesomeIcon icon={['fad', 'grip-vertical']} />
      </DragHandle>
      <Divider className="inline-divider" />
      <Content>
        <Text>{title}</Text>
      </Content>
      <Divider className="inline-divider" />
      <RemoveButton
        onClick={() => handleRemoveCredit(id)}
        basic
        icon
        color="red"
      >
        <FontAwesomeIcon icon={['fas', 'times']} />
      </RemoveButton>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Divider = styled.div`
  background: ${colors['light-grey-50']};

  height: 2px;

  flex-grow: 1;

  :first-child {
    margin-right: 10px;
  }

  :last-child {
    margin-left: 10px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.h3`
  margin-right: 10px;
  margin-left: 10px;
`;

const RemoveButton = styled(Button)`
  margin-left: 10px;
`;

SectionUI.propTypes = {
  credit: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  handleRemoveCredit: PropTypes.func.isRequired,
};

SectionUI.defaultProps = {
  title: '',
};

export default SectionUI;
