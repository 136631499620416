import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import React, { useState, useRef, useEffect } from 'react';

import Box from '../Box';
import Button from '../Button';
import Draggable from '../Draggable';

import { colors, lighten } from '../styles/colors';

function PartnerLogo(props) {
  const {
    partnerLogo,
    handleUpdatePartnerLogo,
    handleRemovePartnerLogo,
  } = props;

  const [width, setWidth] = useState(
    (partnerLogo.style && partnerLogo.style.width) || 300
  );
  const [x, setX] = useState(
    (partnerLogo.style && partnerLogo.style.transform.x) || 0
  );
  const [y, setY] = useState(
    (partnerLogo.style && partnerLogo.style.transform.y) || 0
  );
  const [isEditing, setIsEditing] = useState(false);
  const [isResizing, setIsResizing] = useState(false);

  const imageRef = useRef();
  const resizableRef = useRef();
  const resizerRef = useRef();

  const handleDragImage = (move) => {
    if (!isResizing) {
      setX(+x + move.dx);
      setY(+y + move.dy);
    }
  };

  const handleResizeImage = (move) => {
    setIsResizing(true);
    setWidth(+width + move.dx);
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveEdits = () => {
    handleUpdatePartnerLogo(partnerLogo.id, { width, transform: { x, y } });
  };

  const handleCancelEdits = () => {
    setWidth(partnerLogo.style.width);
    setX(partnerLogo.style.transform.x);
    setY(partnerLogo.style.transform.y);
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  return (
    <Box style={{ position: 'relative' }}>
      <ResizeArea ref={resizerRef} visible={isEditing}></ResizeArea>
      <Draggable onMouseMove={handleDragImage}>
        <PartnerLogoContainer
          onClick={toggleEditing}
          ref={resizableRef}
          width={width}
          transform={{ x, y }}
          isEditing={isEditing}
        >
          <Draggable onMouseMove={handleResizeImage}>
            <ResizeArea ref={resizerRef} visible={isEditing}>
              <ResizeLine />
              <ResizeDot />
            </ResizeArea>
          </Draggable>
          <img ref={imageRef} src={partnerLogo.src} alt="Partner logo" />

          {isEditing && (
            <ButtonContainer>
              <Button
                backgroundColor={colors.green}
                color={'white'}
                thin
                box
                leftIcon
                onClick={handleSaveEdits}
              >
                <FontAwesomeIcon icon={['fad', 'check']} />
                Save
              </Button>
              <Button
                thin
                box
                backgroundColor={colors.red}
                color="white"
                onClick={() => handleRemovePartnerLogo(partnerLogo)}
              >
                <FontAwesomeIcon icon={['fad', 'trash']} />
              </Button>
              <Button box thin onClick={handleCancelEdits}>
                Cancel
              </Button>
            </ButtonContainer>
          )}
        </PartnerLogoContainer>
      </Draggable>
    </Box>
  );
}

const ResizeArea = styled.div`
  position: absolute;
  width: 10px;

  top: 0;
  bottom: 0;
  right: -5px;

  cursor: ew-resize;

  display: flex;
  justify-content: center;

  opacity: 0;

  ${(props) =>
    props.visible &&
    css`
      opacity: 1;
    `}
`;

const ResizeLine = styled.div`
  height: 100%;
  width: 1px;
  background: ${colors.blue};
`;

const ResizeDot = styled.div`
  position: absolute;
  width: 15px;
  height: 15px;

  border-radius: 100%;
  background: ${lighten(colors.blue, 5)};
  border: 3px solid white;
  top: 50%;
`;

const PartnerLogoContainer = styled.div`
  position: absolute;

  ${(props) =>
    props.isEditing &&
    css`
      box-shadow: 0 0 0 1px ${colors.blue};
    `}

  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
  
  ${(props) =>
    props.transform &&
    css`
      transform: translate(${props.transform.x}px, ${props.transform.y}px);
    `}

  img {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 10px;

  display: flex;

  button {
    margin-left: 10px;
  }
`;

PartnerLogo.propTypes = {
  handleUpdatePartnerLogo: PropTypes.func.isRequired,
  handleRemovePartnerLogo: PropTypes.func.isRequired,
  partnerLogo: PropTypes.shape({
    id: PropTypes.string,
    src: PropTypes.string,
    style: PropTypes.shape({
      width: PropTypes.string,
      transform: PropTypes.shape({
        x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        y: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    }),
  }),
};

PartnerLogo.defaultProps = {
  partnerLogo: {
    style: {},
  },
};

export default PartnerLogo;
