import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import Sortable from 'sortablejs';

import { post } from '../../util/fetchUtil';

import { Store } from '../../state/store';

import Autocomplete from '../Autocomplete';
import Button from '../Button';
import PartnerLogoUI from './PartnerLogoUI';
import EntryUI from './EntryUI';
import SectionUI from './SectionUI';

import { fetchFile, downloadFile } from '../../app/fileManager';

import rolesData from '../../shared/roles';

import {
  Container,
  PanelHeader,
  Header,
  PanelBody,
  EntriesContainer,
  AddNewContentContainer,
  FieldContainer,
  InputHeader,
  InputSubheader,
} from './CreditsPanelStyles';
import { TextInput } from '../Input';

function CreditsPanel(props) {
  const {
    projectID,
    handleAddEntry,
    activeProject,
    credits,
    handleToggleCC,
    handleAddSection,
    handleRemoveCredit,
    handleAddPartnerLogo,
    handleChangeOrder,
  } = props;

  const {
    state: { team },
  } = useContext(Store);

  const [newSectionTitle, setNewSectionTitle] = useState('');
  const [newRole, setNewRole] = useState('');
  const [newName, setNewName] = useState('');
  const [roles] = useState(rolesData.map((role) => role.taskName));

  const entriesContainerRef = useRef();
  const sortable = useRef();

  const onUpdate = (e) => {
    // Rearrange preview elements on update
    const { oldIndex, newIndex } = e;
    handleChangeOrder(oldIndex, newIndex);
  };

  useEffect(() => {
    // Use layers in layers panel as items in SortableJS
    sortable.current = Sortable.create(entriesContainerRef.current, {
      animation: 150,
      ghostClass: 'sortable-ghost',
      onStart() {},
      onEnd() {},
      onUpdate,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (sortable.current) {
      sortable.current.options.onUpdate = onUpdate;
    }
    // eslint-disable-next-line
  }, [credits]);

  const handleNewSectionChange = (e) => {
    const { value } = e.target;
    setNewSectionTitle(value);
  };

  const handleRoleChange = (e) => {
    const { value } = e.target;
    setNewRole(value);
  };

  const handleNameChange = (e) => {
    const { value } = e.target;
    setNewName(value);
  };

  const handleSelectRole = (item) => {
    setNewRole(item.name);
  };

  const handleSelectName = (item) => {
    setNewName(item.name);
  };

  const handleAddEntryClick = () => {
    handleAddEntry(newName, newRole);

    setNewName('');
    setNewRole('');
  };

  /**
   * Takes screenshot with Puppeteer on the backend, downloads, and saves to project
   *
   * @returns {Promise}
   * @memberof CreditsPanel
   */
  const handleDownloadImage = async () => {
    try {
      if (projectID) {
        const res = await post('/project/getCreditsScreenshot', {
          projectID,
        });

        await fetchFile(res.src).then((blob) =>
          downloadFile(blob, 'credits.png')
        );
      }

      return true;
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert('Uh-oh, something went wrong exporting credits.');
      return false;
    }
  };

  const items = credits.items.map((item) => {
    if (item.type === 'section') {
      return (
        <SectionUI
          key={item.id}
          credit={item}
          handleRemoveCredit={handleRemoveCredit}
        />
      );
    }
    if (item.type === 'entry') {
      return (
        <EntryUI
          key={item.id}
          credit={item}
          handleToggleCC={handleToggleCC}
          handleRemoveCredit={handleRemoveCredit}
        />
      );
    }
    return null;
  });

  return (
    <Container>
      <PanelHeader>
        <Header>
          Credits
          {activeProject.name && <span> - {activeProject.name}</span>}
        </Header>
      </PanelHeader>
      <PanelBody>
        <PartnerLogoUI handleAddPartnerLogo={handleAddPartnerLogo} />
        <EntriesContainer ref={entriesContainerRef}>{items}</EntriesContainer>
        <AddNewContentContainer>
          <InputHeader>Add new credit</InputHeader>
          <FieldContainer>
            <Autocomplete
              value={newRole}
              options={roles.map((role) => ({ id: role, name: role }))}
              placeholder="Role"
              onItemSelect={handleSelectRole}
              onChange={handleRoleChange}
            />
            <Autocomplete
              value={newName}
              suggestions={team.map((teammate) => teammate.fullName)}
              placeholder="Name"
              onItemSelect={handleSelectName}
              onChange={handleNameChange}
            />
            <Button
              box
              leftIcon
              basic
              color="green"
              className="box left-icon basic green"
              onClick={handleAddEntryClick}
            >
              <FontAwesomeIcon icon={['fad', 'check']} />
              Save
            </Button>
          </FieldContainer>
        </AddNewContentContainer>
        <AddNewContentContainer>
          <InputHeader>Add new section</InputHeader>
          <FieldContainer>
            <TextInput
              value={newSectionTitle}
              onChange={handleNewSectionChange}
              placeholder="Title, e.g. 'Osmosis'"
            />
            <Button
              onClick={() => handleAddSection(newSectionTitle)}
              box
              leftIcon
              basic
              color="green"
            >
              <FontAwesomeIcon icon={['fad', 'check']} />
              Save
            </Button>
          </FieldContainer>
        </AddNewContentContainer>
        <AddNewContentContainer>
          <InputHeader>
            Export
            <InputSubheader>Image will also save to project.</InputSubheader>
          </InputHeader>

          <Button
            box
            leftIcon
            basic
            color="green"
            enable={!!projectID}
            onClick={handleDownloadImage}
          >
            <FontAwesomeIcon icon={['fad', 'save']} />
            Download
          </Button>
        </AddNewContentContainer>
      </PanelBody>
    </Container>
  );
}

CreditsPanel.propTypes = {
  activeProject: PropTypes.object.isRequired,
  handleRemoveCredit: PropTypes.func.isRequired,
  handleAddEntry: PropTypes.func.isRequired,
  handleAddSection: PropTypes.func.isRequired,
  handleToggleCC: PropTypes.func.isRequired,
  handleAddPartnerLogo: PropTypes.func.isRequired,
  handleChangeOrder: PropTypes.func.isRequired,
  projectID: PropTypes.number.isRequired,
  credits: PropTypes.shape({
    items: PropTypes.array,
    partnerLogo: PropTypes.shape({
      src: PropTypes.string,
      style: PropTypes.shape({
        width: PropTypes.string,
        transform: PropTypes.shape({
          x: PropTypes.string,
          y: PropTypes.string,
        }),
      }),
    }),
  }),
};

CreditsPanel.defaultProps = {
  credits: {},
};

export default CreditsPanel;
