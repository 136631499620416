import styled, { css } from 'styled-components/macro';

import { NavLink } from 'react-router-dom';

import { motion } from 'framer-motion';

import Avatar from '../Avatar';

import { colors, lighten, fade } from '../styles/colors';
import { H3, H4 } from '../styles/typography';
import { IconButton } from './NavigationStyles';
import themeColors from '../../styles/themeColors';

export const Container = styled.div``;
export const HeaderBarWrapper = styled.div`
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const UpperWrapper = styled(HeaderBarWrapper)`
  background: ${lighten(colors.night, 5)};
  height: 40px;
`;
export const LowerWrapper = styled(HeaderBarWrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${colors['light-night-10']};

  height: 50px;
`;
export const HomeButton = styled(IconButton)`
  position: relative;
  ${(props) =>
    props.isActive &&
    css`
      background: ${colors['light-night-10']};
    `}
`;
export const ProjectButton = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  margin-left: 15px;
  padding-left: 15px;
  padding-right: 15px;

  cursor: pointer;

  ${(props) =>
    props.isActive &&
    css`
      background: ${colors['light-night-10']};
    `}

  ${(props) =>
    !props.isActive &&
    css`
      &:hover,
      &:focus {
        background: ${fade('white', 95)};
      }

      &:active {
        background: ${fade('white', 90)};
      }
    `}
`;
export const ProjectName = styled(H4)`
  color: rgba(255, 255, 255, 0.45);

  ${(props) =>
    props.isActive &&
    css`
      color: white;
    `}
`;
export const BellIcon = styled(IconButton)`
  color: ${fade('white', 80)};
  ${(props) =>
    props.hasActiveNotifications &&
    css`
      color: white;
    `}
`;
export const BellNotification = styled.div`
  position: absolute;
  top: 3px;
  right: 10px;
  font-size: 10px;

  padding: 2px 3px;

  border-radius: 3px;

  background: ${colors.red};
  color: white;
`;
export const StyledAvatar = styled(Avatar)`
  padding: 5px;
`;
export const AvatarButton = styled(IconButton)`
  cursor: pointer;

  padding: 0 5px;
`;
export const MotionContainer = styled(motion.div)`
  position: relative;
  z-index: 2;
`;
export const StyledNavLink = styled(NavLink)`
  ${(props) =>
    props.isActive &&
    css`
      > div {
        background: ${colors['light-night-10']};
      }
    `}
  &.active > div {
    background: ${themeColors['dark-1']};
  }

  &.active h3 {
    color: ${fade('white', 30)};
  }
`;
export const ButtonText = styled(H3)`
  margin-left: 10px;
  color: ${colors['light-night-30']};
`;
