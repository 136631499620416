import plupload from 'plupload';
import * as tus from 'tus-js-client';

const CHUNK_SIZE = 300; // Upload chunk size in MB
const TUS_THRESHOLD = 1; // Maximum file size to use with AWS uploader
/**
 *
 * @class Uploader
 * @arg {String} browseButtonId - a browse container for the new uploader that will show file browser dialog and upload after selection
 * @arg {Function} onProgress - a callback function that passes the current upload progress
 */
class Uploader {
  constructor(args = {}) {
    const hiddenBrowseButton = 'hidden-browse-button';
    const {
      browseButtonId = hiddenBrowseButton,
      onProgress = (loaded, total) => {
        // eslint-disable-next-line no-console
        // console.log(loaded / total);
      },
    } = args;

    this.onProgress = onProgress;

    // If no browse button was passed, create hidden button
    if (browseButtonId === hiddenBrowseButton) {
      const el = document.createElement('BUTTON');
      el.id = browseButtonId;
      el.style.display = 'none';

      document.body.append(el);
    }

    this.args = args;

    this.bucket = window.AWSMediaBucket;

    const config = {
      runtimes: 'html5',
      browse_button: browseButtonId,
      url: `/api/v1/upload`,
      filters: [],
    };

    // Create plupload object
    this.uploader = new plupload.Uploader(config);

    // Set up hooks

    // eslint-disable-next-line no-console
    this.uploader.bind('FilesAdded', () => console.log('Files added'));

    this.uploader.bind('UploadProgress', (uploader) =>
      this.onProgress(uploader.total.loaded, uploader.total.size, uploader)
    );

    this.uploader.bind('Error', (uploader, err) => {
      // eslint-disable-next-line no-console
      console.error(`Error: ${err.message}`);
    });
  }

  init() {
    return new Promise((resolve) => {
      this.uploader.bind('Init', () => {
        resolve();
      });

      this.uploader.init();
    });
  }

  /**
   *
   *
   * @memberof Uploader
   * @param {String} key
   * @returns {Promise} - returns a promise that resolves a data object if successful
   */
  upload(key) {
    // eslint-disable-next-line consistent-return
    return new Promise((resolve, reject) => {
      if (!key) return reject(new Error('You must include a key for upload.'));

      if (this.useTUS) {
        const onProgressFunc = this.onProgress;
        const fileName = this.file.name;
        const fileType = fileName.split('.').pop();
        const { bucket } = this;
        const url = window.location.origin;

        const upload = new tus.Upload(this.file, {
          endpoint: `${url}/api/v1/tusupload/`,
          retryDelays: [0, 2000, 3000, 50000],
          metadata: {
            name: fileName,
            type: `video/${fileType}`,
            key,
          },
          chunkSize: CHUNK_SIZE * 1.0e6,
          onError(error) {
            reject(error);
          },
          onSuccess() {
            resolve({ url: `https://${bucket}.s3.amazonaws.com/${key}` });
          },
          onProgress(bytesSent, bytesTotal) {
            if (this.onProgress)
              onProgressFunc(bytesSent, bytesTotal, { id: key });
          },
          onBeforeRequest(req) {
            const xhr = req.getUnderlyingObject();
            xhr.withCredentials = true;
          },
        });

        return upload.start();
      }

      this.uploader.setOption('multipart_params', {
        key,
      });

      // Resolve once uploaded
      this.uploader.bind('FileUploaded', () => {
        resolve({
          url: `https://${this.bucket}.s3.amazonaws.com/${key}`,
        });
      });

      // Reject if error
      this.uploader.bind('Error', (uploader, err) => {
        reject(err);
      });

      this.uploader.start();
    });
  }

  /**
   * Update the onProgress function
   *
   * @param {*} onProgress
   * @memberof Uploader
   */
  setOnProgress(onProgress) {
    this.onProgress = onProgress;
  }

  /**
   * Add a file to the upload queue
   *
   * @param {Native File Object} file
   * @memberof Uploader
   */
  addFile(file) {
    this.file = file;
    this.useTUS = file.size / 1.0e6 > TUS_THRESHOLD; // If file bigger than TUS_THRESHOLD use TUS uploader
    this.uploader.addFile(file);
  }
}

export default Uploader;
