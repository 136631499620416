import {
  isGroupElement,
  ElementsArrayProps,
  MixedElementProps,
} from '../../ui/editor/types/ElementProps';

/**
 * Deletes an element from an array of elements
 *
 * @param {[ElementProps]} elementsToDelete - an array of elements that should be removed
 * @param {[ElementProps]} elements - main array of elements
 * @returns {[ElementProps]} - an updated main array of elements
 */ export const element = (
  elementsToDelete: ElementsArrayProps,
  elements: ElementsArrayProps
): ElementsArrayProps => {
  // Recursively look for and remove target elements
  const removeElements = (els: ElementsArrayProps) => {
    // eslint-disable-next-line no-param-reassign
    els = els.filter(
      (el: MixedElementProps) => !elementsToDelete.some((e) => e.id === el.id)
    );

    els.forEach((el) => {
      if (isGroupElement(el) && el.elements.length > 0) {
        // eslint-disable-next-line no-param-reassign
        el.elements = removeElements(el.elements);
      }
    });

    return els;
  };

  const newElements = removeElements(elements);

  return newElements;
};

/**
 * Removes a group of keyframes from an element, e.g. if key is 'opacity', will remove the keyframes.opacity value
 *
 * @param {String} elementId
 * @param {String} key - keyframe key, e.g. 'opacity'
 * @param {[SvgElement]} elements
 */ export const keyframeGroup = (
  elementId: string,
  key: string,
  elements: ElementsArrayProps
): void => {
  // Recursively look for and remove target elements
  const removeRecursive = (els: ElementsArrayProps) => {
    // eslint-disable-next-line no-param-reassign
    const el = els.find((e) => e.id === elementId);

    if (el) {
      el.removeKeyframeGroup(key);
    } else {
      els.forEach((e) => {
        if (isGroupElement(e)) removeRecursive(e.elements);
      });
    }
  };

  removeRecursive(elements);
};

export default {
  keyframeGroup,
  element,
};
