import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import { lighten, colors } from '../styles/colors';

function SideBar(props) {
  const { sections, goToSection } = props;

  const getSectionHeaders = (sectionsArray, level = 0, id = '') =>
    sectionsArray.map((section, index) => {
      const newId = `${section.header}`;
      return (
        section.header && (
          <Section key={newId}>
            <SectionHeader
              onClick={goToSection(newId)}
              id={`header-${newId}`}
              level={level}
            >
              {section.header}
            </SectionHeader>
            <SubSections>
              {section.subSections &&
                getSectionHeaders(section.subSections, level + 1, newId)}
            </SubSections>
          </Section>
        )
      );
    });

  return <Container>{getSectionHeaders(sections)}</Container>;
}

const Container = styled.div`
  width: 250px;
  height: 100%;

  padding: 15px;
  border-right: 1px solid ${colors['light-grey-50']};

  overflow: auto;
`;

const Section = styled.div``;

const SectionHeader = styled.h3`
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 3px;

  :hover,
  :focus {
    background: ${lighten(colors.grey, 55)};
  }

  :active {
    background: ${lighten(colors.grey, 50)};
  }

  margin-top: 5px;
  margin-bottom: 5px;

  ${(props) =>
    props.level === 1 &&
    css`
      font-size: 16px;
      color: ${colors.grey};
    `}

  ${(props) =>
    props.level === 2 &&
    css`
      font-size: 15px;
      color: ${lighten(colors.grey, 10)};
    `}
  
  ${(props) =>
    props.level >= 3 &&
    css`
      font-size: 14px;
      font-weight: 400;
      color: ${lighten(colors.grey, 20)};
    `}
`;

const SubSections = styled.div`
  margin-left: 10px;
`;

SideBar.propTypes = {
  goToSection: PropTypes.func.isRequired,
  sections: PropTypes.array.isRequired,
};

export default SideBar;
