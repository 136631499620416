import React from 'react';

import { lighten } from 'polished';
import { post } from '../../util/fetchUtil';
import useSnackbar from '../../app/hooks/useSnackbar';
import { Box, Flex } from '../Box';
import Checkbox from '../Checkbox';
import colors from '../../styles/themeColors';
import Snackbar from '../Snackbar';
import SnackbarContainer from '../editor/SnackbarContainer';
import Text from '../Text';

type Props = {
  isChecked: boolean;
  handleChange: () => void;
  label: string;
  projectGroupID: number;
  count: number;
};

function SidebarGroup(props: Props) {
  const { isChecked, handleChange, count, label, projectGroupID } = props;

  const snackbar = useSnackbar();

  const [isDraggingOver, setIsDraggingOver] = React.useState(false);

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    if (!isDraggingOver) setIsDraggingOver(true);
  };

  const handleDragLeave = () => {
    if (isDraggingOver) setIsDraggingOver(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    if (isDraggingOver) setIsDraggingOver(false);

    const projectIDsString = e.dataTransfer.getData('projectIDs');
    if (!projectIDsString) return;

    const projectIDs = JSON.parse(projectIDsString);

    if (projectIDs.length) {
      post('/project/addToGroup', {
        projectIDs,
        projectGroupID,
      });

      snackbar.show();
    }
  };

  return (
    <Box
      sx={{
        background: isDraggingOver ? lighten(0.4, colors.primary) : 'initial',
        borderRadius: 3,
      }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <Snackbar isVisible={snackbar.isVisible}>
        <SnackbarContainer bg="dark-3">
          <Text color="white">Project added to {label}</Text>
        </SnackbarContainer>
      </Snackbar>
      <Flex key={label} mb="10px" alignItems="center">
        <Checkbox label={label} checked={isChecked} onChange={handleChange} />
        <Box
          ml={2}
          p="3px 6px"
          bg="light-2"
          color="dark-6"
          fontSize="14px"
          sx={{ borderRadius: 40 }}
        >
          {count}
        </Box>
      </Flex>
    </Box>
  );
}

export default SidebarGroup;
