import uuid from '../../utilities/uuid';
import dataUriToBlob from './dataUriToBlob';
import { uploadFile } from '../../fileManager';

export default async (imageNodes) => {
  const imageUploads = imageNodes.map((node) => {
    const blob = dataUriToBlob(node.attributes.href);
    const extension = blob.type.split('/')[1];
    return uploadFile(blob, `${uuid()}.${extension}`);
  });

  const uploadRes = await Promise.all(imageUploads);

  const newNodes = uploadRes.map((res, i) => ({
    ...imageNodes[i],
    attributes: {
      ...imageNodes[i].attributes,
      href: res.url,
    },
  }));

  return newNodes;
};
