import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import React, { useState, useEffect } from 'react';
import tippy from 'tippy.js';

import Tippy from '@tippyjs/react';
import fetchUtil from '../../util/fetchUtil';

import tools from './tools';
import create from '../../editor/create';
import { downloadFile } from '../../app/fileManager';
import sceneScreenshots from '../../app/editor/sceneScreenshots';

import SceneProps from './props/SceneProps';

import Box, { Flex } from '../Box';
import Button from '../Button';
import { colors, fade } from '../styles/colors';
import ViewerSettingsDialog from './ViewerSettingsDialog';

const Container = styled(Box)`
  margin: 5px;
  padding: 5px;
  background: ${colors.night};
  border-radius: 5px;

  display: flex;
  justify-content: space-between;
`;

const ToolButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;

  width: 30px;
  height: 30px;

  border-radius: 3px;

  cursor: pointer;

  :not(:last-child) {
    margin-right: 5px;
  }

  color: ${fade('white', 40)};
  fill: ${fade('white', 40)};

  :hover,
  :focus {
    background-color: ${fade('black', 95)};
  }

  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${fade(colors.teal, 50)};
      color: white;
      fill: white;

      :hover,
      :focus {
        color: white;
        fill: white;
        background-color: ${fade(colors.teal, 50)};
      }
    `};
`;

/**
 * Parses the kit param and gets the appropriate kit information
 *
 */
function Toolbar(props) {
  const {
    selectedTool,
    setSelectedTool,
    handleAddElement,
    duration,
    gridVisible,
    setGridVisible,
    scene,
    currentTime,
  } = props;

  const [settingsDialogVisible, setSettingsDialogVisible] = useState(false);

  const handleToggleViewerSettings = () => {
    setSettingsDialogVisible(!settingsDialogVisible);
  };

  const handleUpdateThumbnail = (url) => {
    fetchUtil.post('/scene/updateThumbnail', {
      thumbnail: url,
      sceneID: scene.sceneID,
    });
  };

  const handleScreenshot = async () => {
    const screenshots = await sceneScreenshots([
      { s3Key: scene.data.s3Key, time: currentTime, temp: true, duration },
    ]);

    downloadFile(screenshots[0].blob, 'Screenshot.jpeg');

    if (!scene.data.thumbnail) {
      handleUpdateThumbnail(screenshots[0].url);
    }
  };

  useEffect(() => {
    tippy('[data-tippy-content]');
  }, []);

  const toolComponents = Object.values(tools).map((tool) => {
    const isSelected = tool.id === selectedTool;
    return (
      <Tippy key={tool.id} content={tool.tooltip}>
        <Box>
          <ToolButton
            box
            icon
            isSelected={isSelected}
            onClick={() => setSelectedTool(tool.id)}
          >
            {tool.icon}
          </ToolButton>
        </Box>
      </Tippy>
    );
  });

  return (
    <Container>
      <ViewerSettingsDialog
        gridVisible={gridVisible}
        setGridVisible={setGridVisible}
        isVisible={settingsDialogVisible}
        handleHideDialog={() => setSettingsDialogVisible(false)}
      />
      <Flex c>
        {toolComponents}

        <Tippy content="Test doggo">
          <Box>
            <ToolButton
              box
              icon
              onClick={() => handleAddElement(create.testImage())}
            >
              <FontAwesomeIcon icon={['fad', 'dog']} />
            </ToolButton>
          </Box>
        </Tippy>
      </Flex>
      <Flex c>
        <ToolButton
          box
          icon
          onClick={handleScreenshot}
          data-tippy-content="Save Screenshot"
        >
          <FontAwesomeIcon icon={['fad', 'camera']} />
        </ToolButton>
        <ToolButton
          box
          icon
          onClick={handleToggleViewerSettings}
          data-tippy-content="Canvas Settings"
        >
          <FontAwesomeIcon icon={['fad', 'cog']} />
        </ToolButton>
      </Flex>
    </Container>
  );
}

const { bool, func, number, string } = PropTypes;

Toolbar.propTypes = {
  selectedTool: string.isRequired,
  setSelectedTool: func.isRequired,
  handleAddElement: func.isRequired,
  duration: number.isRequired,
  gridVisible: bool.isRequired,
  setGridVisible: func.isRequired,
  currentTime: number.isRequired,
  scene: SceneProps,
};

export default Toolbar;
