import React, { useState } from 'react';
import styled from 'styled-components/macro';

import ImagesList from './ImagesList';
import SymbolsList from './SymbolsList';

import Box from '../../Box';

import { PanelButton, PanelHeader } from '../EditorStyles';
import { ElementProps } from '../types/ElementProps';

type OptionProps = {
  name: string;
  id: string;
  icon: string;
};

const options = [
  {
    name: 'Images',
    id: 'images',
    icon: 'fad fa-layer-group',
  },
  {
    name: 'Symbols',
    id: 'symbols',
    icon: 'fad fa-file',
  },
];

const Container = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

type Props = {
  duration: number;
  handleAddElement: (element: ElementProps) => void;
};

/**
 * Container that can display several library views
 * for choosing elements
 */
const Library = (props: Props) => {
  const { duration, handleAddElement } = props;
  const [selectedView, setSelectedView] = useState<OptionProps>(options[0]);

  const handleSelectView = (option: OptionProps) => {
    setSelectedView(option);
  };

  return (
    <Container>
      <PanelHeader style={{ display: 'flex' }}>
        {options.map((option) => (
          <PanelButton
            key={option.id}
            isActive={selectedView.id === option.id}
            onClick={() => handleSelectView(option)}
          >
            {option.name}
          </PanelButton>
        ))}
      </PanelHeader>
      {selectedView.id === 'images' && (
        <ImagesList duration={duration} handleAddElement={handleAddElement} />
      )}
      {selectedView.id === 'symbols' && (
        <SymbolsList handleAddElement={handleAddElement} />
      )}
    </Container>
  );
};

export default Library;
