import fetchUtil from '../../util/fetchUtil';

export const fetchSavedWords = async (dispatch) => {
  const res = await fetchUtil.post('/dictionary/getAllWords');

  return dispatch({
    type: 'FETCH_SAVED_WORDS',
    payload: res.words.map((item) => item.word),
  });
};

export const setMisspelledWords = (misspelledWords, dispatch) => {
  dispatch({
    type: 'SET_MISSPELLED_WORDS',
    payload: misspelledWords,
  });
};
