import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

import React, { useState, useEffect } from 'react';

import moment from 'moment';

import { post } from '../../util/fetchUtil';

import Table from '../Table';
import Button from '../Button';
import { Flex } from '../Box';
import { colors, lighten, darken } from '../styles/colors';
import OptionSelector from '../OptionSelector';

const ButtonBox = styled(Flex)`
  padding: 15px;
  > * {
    margin-right: 10px;
  }
`;

function AdminFileExports() {
  const [selectedExports, setSelectedExports] = useState('scenes');
  const [fileExports, setFileExports] = useState([]);
  const [sceneExports, setSceneExports] = useState([]);

  const handleLaunchExporter = async () => post('/admin/launchExporter');

  const getExports = async () => {
    const es = await post('/export/getRecentExports');

    if (es.fileExports) {
      setFileExports(
        es.fileExports.map((item) => ({ ...item, id: item.fileID }))
      );
    }

    if (es.sceneExports) {
      setSceneExports(
        es.sceneExports.map((item) => ({
          ...item,
          id: item.sceneID,
          name: item.sceneData.name,
        }))
      );
    }
  };

  useEffect(() => {
    getExports();
  }, []);

  const headers = [
    {
      id: 'id',
      value: 'ID',
      width: 100,
    },
    {
      id: 'name',
      value: 'Name',
    },
    {
      id: 'url',
      value: 'URL',
    },
    {
      id: 'createdOn',
      value: 'Created on',
    },
    {
      id: 'status',
      value: 'Status',
    },
  ];

  let data = [];
  if (selectedExports === 'scenes') data = sceneExports;
  else if (selectedExports === 'files') data = fileExports;

  const rows = data.map((file) => {
    let statusComponent = <DeletedTag>Error</DeletedTag>;
    if (file.status === 1)
      statusComponent = <ArchivedTag>In progress</ArchivedTag>;
    else if (file.status === 2)
      statusComponent = <ActiveTag>Completed</ActiveTag>;

    return {
      id: file.id.toString(),
      file: { data: file },
      name: file.name,
      status: statusComponent,
      url: (
        <a
          href={file.data.url}
          download
          target="_blank"
          rel="noopener noreferrer"
        >
          {file.data.url}
        </a>
      ),
      createdOn: moment(file.createdOn).format('hh:mma MM-DD-YYYY'),
    };
  });

  return (
    <Container>
      <ButtonBox style={{ padding: 15 }}>
        <Button leftIcon box onClick={handleLaunchExporter}>
          <FontAwesomeIcon icon={['fad', 'rocket']} />
          Launch exporter
        </Button>
      </ButtonBox>
      <ButtonBox>
        <Button
          thin
          box
          basic={selectedExports !== 'files'}
          backgroundColor={selectedExports === 'files' ? colors.blue : null}
          color={selectedExports === 'files' ? 'white' : null}
          onClick={() => setSelectedExports('files')}
        >
          V1 exports
        </Button>
        <Button
          thin
          box
          basic={selectedExports !== 'scenes'}
          backgroundColor={selectedExports === 'scenes' ? colors.blue : null}
          color={selectedExports === 'scenes' ? 'white' : null}
          onClick={() => setSelectedExports('scenes')}
        >
          V2 exports
        </Button>
      </ButtonBox>
      <Table
        headers={headers}
        rows={rows}
        // onRowClick={handleToggleDialog}
      />
    </Container>
  );
}

const Container = styled.div``;

const ActiveTag = styled.div`
  display: inline-flex;
  padding: 3px 6px;
  border-radius: 3px;
  background: ${lighten(colors.green, 40)};
  color: ${darken(colors.green, 10)};
`;

const DeletedTag = styled(ActiveTag)`
  background: ${lighten(colors.red, 40)};
  color: ${darken(colors.red, 10)};
`;

const ArchivedTag = styled(ActiveTag)`
  background: ${lighten(colors.grey, 50)};
  color: ${lighten(colors.grey, 20)};
`;

AdminFileExports.propTypes = {};

AdminFileExports.defaultProps = {};

export default AdminFileExports;
