import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';

import { motion, AnimatePresence } from 'framer-motion';

import moment from 'moment';

import { Store } from '../../state/store';
import { getDurationSinceNow } from '../../app/utilities/durations';
import { getArrayString } from '../../utilities/array';

import Box, { Flex } from '../Box';

import {
  Container,
  UpperContainer,
  LowerContainer,
  CommentTextContainer,
  AnnotationIcon,
  AuthorName,
  AuthorDate,
  ActionButton,
  DeleteButton,
  SaveButton,
  ResolvedInfoContainer,
  CommentTimestamp,
  AuthorContainer,
  ReplyButton,
  ActionsContainer,
  EditingInputContainer,
} from './CommentStyles';

import CommentTextArea from './CommentTextArea';
import FormattedCommentText from './FormattedCommentText';
import { getAnonymousName } from '../../shared/anonymousNames';

const handleKeyDown = (save) => (event) => {
  const { keyCode, shiftKey } = event;
  switch (keyCode) {
    case 13:
      if (!shiftKey) {
        event.preventDefault();

        save();
      }
      break;
    default:
      break;
  }
};

const handleSelectMention = (
  value,
  mentionedUser,
  mentionedUsers,
  setReply,
  setMentionedUsers
) => {
  // Add new mentioned user if it doesn't already exist in list
  const newMentionedUsers = mentionedUsers;
  if (!mentionedUsers.find((user) => user.userID === mentionedUser.userID)) {
    newMentionedUsers.push(mentionedUser);
  }

  setReply(value);
  setMentionedUsers(newMentionedUsers);
};

const NotEditingSection = ({
  isEditing,
  darkMode,
  toggleReply,
  isReply,
  isResolved,
  handleResolveComment,
  commentID,
  userID,
  handleEditComment,
  handleDeleteComment,
}) => {
  return (
    !isEditing && (
      <ActionsContainer>
        <ReplyButton darkMode={darkMode} onClick={toggleReply} box thin>
          Reply
        </ReplyButton>
        <div>
          {!isReply && (
            <ActionButton
              isResolved={isResolved}
              onClick={handleResolveComment(commentID)}
              darkMode={darkMode}
              fontSize={14}
              icon
            >
              <FontAwesomeIcon
                icon={[isResolved ? 'fad' : 'fal', 'check-circle']}
              />
            </ActionButton>
          )}
          {!isResolved && userID === window.userID && (
            <ActionButton
              onClick={handleEditComment}
              darkMode={darkMode}
              fontSize={14}
              icon
            >
              <FontAwesomeIcon icon={['fad', 'edit']} />
            </ActionButton>
          )}
          {userID === window.userID && (
            <DeleteButton
              onClick={handleDeleteComment(commentID)}
              darkMode={darkMode}
              fontSize={14}
              icon
            >
              <FontAwesomeIcon icon={['fad', 'trash-alt']} />
            </DeleteButton>
          )}
        </div>
      </ActionsContainer>
    )
  );
};

/**
 * A single comment for either video or SVG review
 *
 * @class Comment
 * @extends {Component}
 */
function Comment(props) {
  const {
    text = '',
    isReply = false,
    handleSaveReply,
    parentCommentID,
    commentID,
    handleUpdateComment,
    hasSvg,
    userID,
    authorName = '',
    authorDate = '',
    resolved = {},
    isResolved = false,
    timestamp = '',
    handleDeleteComment,
    handleResolveComment,
    darkMode,
    anonymous,
  } = props;

  const {
    state: { team, user },
  } = useContext(Store);

  const [isReplying, setIsReplying] = useState(false);
  const [reply, setReply] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [newText, setNewText] = useState(text);
  const [mentionedUsers, setMentionedUsers] = useState([]);

  const resetReplying = () => {
    setIsReplying(false);
    setReply('');
  };

  const resetEditing = () => {
    setIsEditing(false);
    setMentionedUsers([]);
  };

  const toggleReply = (e) => {
    const newIsReplying = !isReplying;
    e.stopPropagation();
    setIsReplying(newIsReplying);
  };

  const handleReplyChange = (value) => setReply(value);

  const handleSelectEditMention = (value, mentionedUser) => {
    // Add new mentioned user if it doesn't already exist in list
    const newMentionedUsers = mentionedUsers;
    if (!mentionedUsers.find((u) => u.userID === mentionedUser.userID)) {
      newMentionedUsers.push(mentionedUser);
    }

    setNewText(value);
    setMentionedUsers(newMentionedUsers);
  };

  const doHandleSaveReply = () => {
    if (isReply) {
      // If this comment is itself a reply, pass the parent comment ID as well
      handleSaveReply(reply, parentCommentID, commentID, mentionedUsers);
    } else {
      // Otherwise pass this commentID as the new parent
      handleSaveReply(reply, commentID, commentID, mentionedUsers);
    }

    resetReplying();
  };

  const handleEditComment = () => setIsEditing(true);

  const doHandleUpdateComment = () => {
    if (newText !== text) {
      handleUpdateComment(commentID, newText, mentionedUsers);
    }

    resetEditing();
  };

  const handleEditingChange = (value) => setNewText(value);

  const timestampFormatted = moment(
    moment.duration(timestamp / 1000, 'seconds').asMilliseconds()
  ).format('mm:ss');

  const options = team.map((u) => ({
    ...u,
    id: u.userID,
    name: u.fullName,
    text: `@${u.fullName}`,
    subheader: u.roles ? getArrayString(u.roles, ' ,') : null,
  }));

  return (
    <Container isResolved={isResolved} isReply={isReply} darkMode={darkMode}>
      <UpperContainer>
        <AuthorContainer>
          <AuthorName darkMode={darkMode}>
            {anonymous ? getAnonymousName(userID) : authorName}
            <Box as="span" sx={{ fontWeight: '300', opacity: 0.5 }}>
              {userID === user?.userID ? ' (you)' : ''}
            </Box>
          </AuthorName>
          <AuthorDate>{getDurationSinceNow(authorDate)}</AuthorDate>
        </AuthorContainer>
        <CommentTextContainer>
          {!isEditing && (
            <div>
              <Flex style={{ marginBottom: 5 }}>
                {hasSvg && <AnnotationIcon icon={['fad', 'paint-brush']} />}
                {!!timestamp && (
                  <CommentTimestamp darkMode={darkMode}>
                    [{timestampFormatted}]
                  </CommentTimestamp>
                )}
              </Flex>
              <FormattedCommentText darkMode={darkMode} text={text} />
            </div>
          )}
          {isEditing && (
            <EditingInputContainer>
              <CommentTextArea
                value={newText}
                onSelect={handleSelectEditMention}
                placeholder="Start typing comment..."
                onChange={handleEditingChange}
                onKeyDown={handleKeyDown(doHandleUpdateComment)}
                options={options}
                darkMode={darkMode}
              />
            </EditingInputContainer>
          )}
        </CommentTextContainer>
      </UpperContainer>
      <LowerContainer>
        <NotEditingSection
          isEditing={isEditing}
          darkMode={darkMode}
          toggleReply={toggleReply}
          isReply={isReply}
          isResolved={isResolved}
          handleResolveComment={handleResolveComment}
          commentID={commentID}
          userID={userID}
          handleEditComment={handleEditComment}
          handleDeleteComment={handleDeleteComment}
        />

        {isEditing && (
          <ActionsContainer style={{ marginTop: 5 }}>
            <SaveButton thin transparent onClick={doHandleUpdateComment}>
              Save
            </SaveButton>
            <ActionButton thin transparent onClick={resetEditing}>
              Cancel
            </ActionButton>
          </ActionsContainer>
        )}
      </LowerContainer>
      {isResolved && (
        <div>
          <ResolvedInfoContainer darkMode={darkMode}>
            {`Resolved by ${resolved.givenName} at ${moment(
              resolved.timestamp
            ).format('h:mma MM/DD/YY')}`}
          </ResolvedInfoContainer>
        </div>
      )}
      <AnimatePresence>
        {isReplying && (
          <motion.div
            style={{ overflow: 'hidden' }}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ easing: 'ease-in-out' }}
          >
            <CommentTextArea
              value={reply}
              onSelect={(value, mentionedUser) =>
                handleSelectMention(
                  value,
                  mentionedUser,
                  mentionedUsers,
                  setReply,
                  setMentionedUsers
                )
              }
              placeholder="Reply to this comment..."
              onChange={handleReplyChange}
              onKeyDown={handleKeyDown(doHandleSaveReply)}
              options={options}
              darkMode={darkMode}
            />
            <ActionsContainer style={{ marginTop: 5 }}>
              <SaveButton thin transparent onClick={doHandleSaveReply}>
                Save
              </SaveButton>
              <ActionButton thin transparent onClick={resetReplying}>
                Cancel
              </ActionButton>
            </ActionsContainer>
          </motion.div>
        )}
      </AnimatePresence>
    </Container>
  );
}

const { bool, number, string, func, shape, array } = PropTypes;

Comment.propTypes = {
  hasSvg: bool.isRequired,
  commentID: number.isRequired,
  timestamp: string,
  userID: number.isRequired,
  totalComments: number.isRequired,

  handleDeleteComment: func.isRequired,
  handleUpdateComment: func.isRequired,

  handleResolveComment: func,
  handleSaveReply: func,

  text: string,
  authorName: string,
  authorDate: string,
  isResolved: bool,
  resolved: shape({
    timestamp: string,
    givenName: string,
  }),

  replies: array,
  isReply: bool,
  parentCommentID: number,
  darkMode: bool,
};

Comment.defaultProps = {
  handleSaveReply: () => {},
  handleResolveComment: () => {},
  replies: [],
  parentCommentID: null,
};

export default Comment;
