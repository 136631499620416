import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useEffect } from 'react';

import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { post } from '../../util/fetchUtil';

import Table from '../Table';
import Checkbox from '../Checkbox';

import Dialog from '../Dialog';
import OptionSelector from '../OptionSelector';
import { TextInput } from '../Input';
import ProjectInfoDialog from './projects/ProjectInfoDialog';

import Pagination from '../home/Pagination';
import { colors, lighten, darken } from '../styles/colors';
import useDebounce from '../../app/hooks/useDebounce';
import Button from '../Button';
import { getHash } from '../../shared/links';

const sortBy = [
  { id: 'alphabetical', name: 'Alphabetical' },
  { id: 'newest', name: 'Recent' },
];

const statusOptions = [
  { name: 'Active', value: 1, checked: false },
  { name: 'Archived', value: 2, checked: false },
  { name: 'Deleted', value: 0, checked: false },
];

function AdminProjects() {
  const [projects, setProjects] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [activeSortBy, setActiveSortBy] = useState(sortBy[0]);
  const [page, setPage] = useState(0);
  const [count] = useState(20);
  const [totalProjects, setTotalProjects] = useState(0);
  const [statusFilter, setStatusFilter] = useState(null);
  const [projectDialogVisible, setProjectDialogVisible] = useState(false);
  const [selectedProject, setSelectedProject] = useState({});

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const filters = [
    { id: 'all', filter: () => getAllProjects() },
    { id: 'search', filter: () => searchProjects(searchValue) },
  ];

  const [activeFilter, setActiveFilter] = useState(filters[0]);

  const getAllProjects = () => {
    post('/project/getAll', {
      sortBy: activeSortBy.id,
      count,
      start: page,
      status: statusFilter,
    }).then((res) => {
      setProjects(res.projects);
      setTotalProjects(res.total);
    });

    setActiveFilter(filters.find((filter) => filter.id === 'all'));
  };

  const getProjects = () =>
    filters.find((filter) => filter.id === activeFilter.id).filter();

  useEffect(() => {
    getAllProjects();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line
  }, [page, statusFilter]);

  const handleCheckboxChange = (e) => {
    const { name } = e.target;

    const status = statusOptions.find((option) => option.name === name);
    const newStatusFilter = statusFilter === status.value ? null : status.value;

    setStatusFilter(newStatusFilter);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const searchProjects = (text) =>
    post('/project/searchProjects', {
      text,
      start: page,
      count,
      sortBy: activeSortBy.id,
      status: statusFilter,
    }).then((res) => {
      setProjects(res.projects);
      setTotalProjects(res.total);
      setActiveFilter(filters.find((filter) => filter.id === 'search'));
    });

  useEffect(() => {
    searchProjects(debouncedSearchValue);
    // eslint-disable-next-line
  }, [debouncedSearchValue]);

  const handleSelectSortBy = (option) => {
    post('/project/searchProjects', {
      text: searchValue,
      start: page,
      count,
      sortBy: option.id,
      status: statusFilter,
    }).then((res) => {
      setProjects(res.projects);
      setTotalProjects(res.total);
    });

    setActiveSortBy(option);
  };

  const handlePageChange = (newPage) => {
    if (newPage > -1) setPage(newPage);
  };

  const handleDeleteProject = async (e, projectID) => {
    e.stopPropagation();
    await post('/admin/projects/delete', {
      projectID,
    });

    getProjects();
  };

  const handleOpenPage = (e, project) => {
    e.stopPropagation();
    window.open(
      `/#${getHash({ view: 'projectContents', projectID: project.projectID })}`,
      '_blank'
    );
  };

  const handleArchiveProject = (e, projectID) => {
    e.stopPropagation();
    post('/admin/projects/archive', {
      projectID,
    }).then(() => getProjects());
  };

  const handleReviveProject = (e, projectID) => {
    e.stopPropagation();
    post('/admin/projects/revive', {
      projectID,
    }).then(() => getProjects());
  };

  const handleToggleDialog = (row) => {
    setProjectDialogVisible(!projectDialogVisible);
    setSelectedProject(row.project);
  };

  const headers = [
    {
      id: 'id',
      value: 'ID',
      width: 75,
    },
    {
      id: 'projectName',
      value: 'Project',
    },
    {
      id: 'status',
      value: 'Status',
    },
    {
      id: 'created',
      value: 'Created',
    },
    {
      id: 'actions',
      value: 'Actions',
    },
  ];

  const rows = projects.map((project) => {
    let statusComponent = <DeletedTag>Deleted</DeletedTag>;
    if (project.status === 1) statusComponent = <ActiveTag>Active</ActiveTag>;
    else if (project.status === 2)
      statusComponent = <ArchivedTag>Archived</ArchivedTag>;

    return {
      id: project.projectID.toString(),
      project: { data: project },
      trello: project.data.trello,
      projectName: project.name,
      status: statusComponent,
      created: moment(project.createdOn).format('MM-DD-YYYY'),
      actions: (
        <div>
          {project.status === 1 && (
            <Button icon>
              <Icon
                icon={['fad', 'external-link']}
                onClick={(e) => handleOpenPage(e, project)}
              />
            </Button>
          )}
          {project.status === 1 && (
            <Button icon>
              <Icon
                icon={['fad', 'archive']}
                onClick={(e) => handleArchiveProject(e, project.projectID)}
              />
            </Button>
          )}
          {(project.status === 2 || project.status === 0) && (
            <Button icon>
              <Icon
                icon={['fad', 'feather']}
                onClick={(e) => handleReviveProject(e, project.projectID)}
              />
            </Button>
          )}
          {project.status > 0 && (
            <Button icon>
              <Icon
                color={colors.red}
                icon={['fad', 'trash-alt']}
                onClick={(e) => handleDeleteProject(e, project.projectID)}
              />
            </Button>
          )}
        </div>
      ),
    };
  });

  const checkboxes = statusOptions.map((option) => (
    <Checkbox
      key={option.name}
      name={option.name}
      checked={option.value === statusFilter}
      onChange={handleCheckboxChange}
      label={
        <>
          <Name>{option.name}</Name>
          <Description>{option.description}</Description>
        </>
      }
    ></Checkbox>
  ));

  const pages = Math.ceil(totalProjects / count);

  return (
    <Container>
      <HeaderContainer>
        <FlexContainer>
          <TextInput
            onChange={handleSearchChange}
            value={searchValue}
            placeholder="Filter projects..."
          />
          <OptionSelector
            zindex={10}
            options={sortBy}
            selectedOption={activeSortBy}
            onSelectOption={(option) => handleSelectSortBy(option)}
          />
        </FlexContainer>
        <StatusContainer>{checkboxes}</StatusContainer>
      </HeaderContainer>
      <Table headers={headers} rows={rows} onRowClick={handleToggleDialog} />
      <FooterContainer>
        {pages > 1 && (
          <Pagination
            pages={pages}
            page={page}
            handlePageChange={handlePageChange}
          />
        )}
      </FooterContainer>
      <Dialog
        handleHideDialog={() => setProjectDialogVisible(false)}
        isVisible={projectDialogVisible}
      >
        <ProjectInfoDialog
          project={selectedProject}
          getAllProjects={getAllProjects}
        />
      </Dialog>
    </Container>
  );
}

const Container = styled.div``;

const StatusContainer = styled.div`
  margin-top: 15px;
  display: flex;
  > * {
    margin-right: 20px;
  }
`;

const Name = styled.div``;
const Description = styled.div``;
const FooterContainer = styled.div`
  padding: 15px;
`;

const HeaderContainer = styled.div`
  padding: 15px;
`;

const Icon = styled(FontAwesomeIcon)`
  &.button.icon {
    font-size: 14px;
  }
`;

const ActiveTag = styled.div`
  display: inline-flex;
  padding: 3px 6px;
  border-radius: 3px;
  background: ${lighten(colors.green, 40)};
  color: ${darken(colors.green, 10)};
`;

const DeletedTag = styled(ActiveTag)`
  background: ${lighten(colors.red, 40)};
  color: ${darken(colors.red, 10)};
`;

const ArchivedTag = styled(ActiveTag)`
  background: ${lighten(colors.grey, 50)};
  color: ${lighten(colors.grey, 20)};
`;

const FlexContainer = styled.div`
  display: flex;
  > * {
    margin-right: 10px;
  }
`;

AdminProjects.propTypes = {
  propName: PropTypes.string,
};

AdminProjects.defaultProps = {
  propName: '',
};

export default AdminProjects;
