import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from '../../Lightbox';
import { Flex } from '../../Box';

function AudioAsset(props) {
  const { file, showPreview, setShowPreview } = props;

  return (
    <Flex c style={{ height: '100%' }}>
      <audio controls>
        <source src={file.data.url} type={`audio/${file.type}`} />
      </audio>
      <Lightbox
        handleHideLightbox={() => setShowPreview(false)}
        isVisible={showPreview}
      >
        <Flex cx>
          <audio controls>
            <source src={file.data.url} type={`audio/${file.type}`} />
          </audio>
        </Flex>
      </Lightbox>
    </Flex>
  );
}

AudioAsset.propTypes = {
  file: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  showPreview: PropTypes.bool,
  setShowPreview: PropTypes.func.isRequired,
};

AudioAsset.defaultProps = {
  file: {},
  showPreview: false,
};

export default AudioAsset;
