import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Path, Point } from 'paper';

import coords from '../../../app/editor/coords';

import { colors } from '../../styles/colors';
import { RefProps } from '../../../props/general';

const StyledPath = styled.path`
  stroke: ${colors.blue};
  stroke-width: 2px;
  fill: none;
`;

/**
 * Add new points to a path with a bezier pen tool
 */
function PencilPathEditor(props) {
  const { svgRef, onMouseMove, onMouseDown, onMouseUp, onFinishDraw } = props;

  const [isDragging, setIsDragging] = useState(false);
  const [, update] = useState();
  const path = useRef(null);

  const handleMouseMove = (e) => {
    if (isDragging) {
      // Add point to path
      const point = coords.getSvgCoords(svgRef.current, e);
      path.current.add(new Point(point));

      update({});

      onMouseMove(e);
    }
  };

  const handleMouseDown = (e) => {
    onMouseDown(e);
    setIsDragging(true);

    path.current = new Path({});
  };

  const handleMouseUp = (e) => {
    setIsDragging(false);

    // Simplify the path
    path.current.simplify(10);

    // Update UI
    update({});

    // Make sure path exists
    if (path.current.length) onFinishDraw(path.current.getPathData());

    onMouseUp(e);
  };

  useEffect(() => {
    const svg = svgRef.current;
    if (svg) {
      svg.addEventListener('mousedown', handleMouseDown);
      svg.addEventListener('mouseup', handleMouseUp);
      svg.addEventListener('mousemove', handleMouseMove);
    }
    return () => {
      if (svg) {
        svg.removeEventListener('mousedown', handleMouseDown);
        svg.removeEventListener('mouseup', handleMouseUp);
        svg.removeEventListener('mousemove', handleMouseMove);
      }
    };
    // eslint-disable-next-line
  }, [svgRef.current, isDragging]);

  return <g>{path.current && <StyledPath d={path.current.getPathData()} />}</g>;
}

const { func } = PropTypes;

PencilPathEditor.propTypes = {
  svgRef: RefProps.isRequired,
  onMouseMove: func,
  onMouseDown: func,
  onMouseUp: func,
  onFinishDraw: func,
};

PencilPathEditor.defaultProps = {
  isNew: false,
  onMouseDown: () => {},
  onMouseMove: () => {},
  onMouseUp: () => {},
  onFinishDraw: () => {},
};

export default PencilPathEditor;
