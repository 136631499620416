import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components/macro';

import React, { useState, useContext } from 'react';

import moment from 'moment';

import { Box } from 'rebass';
import { post } from '../../util/fetchUtil';

import { Store } from '../../state/store';
import { goToUrl } from '../../shared/links';

import Checkbox from '../Checkbox';

import { P } from '../styles/typography';
import { colors, lighten, getColorCombination } from '../styles/colors';
import Button from '../Button';
import Dialog from '../Dialog';
import DateLabel from '../DateLabel';
import Warning from '../dialogs/WarningDialog';
import TaskProps from '../../types/TaskProps';
import UserProps from '../../types/UserProps';

const TaskContainer = styled.div`
  display: flex;
`;

const RightContainer = styled.div``;
const TextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Timestamp = styled(P)`
  color: ${colors['light-grey-30']};
  margin-left: 10px;
`;

const TaskNotes = styled(P)`
  color: ${colors['light-grey-20']};
  font-size: 14px;

  ${(props: { completed: boolean }) =>
    props.completed &&
    css`
      color: ${colors['light-grey-30']};
    `}
`;

const Label = styled(P)`
  margin-bottom: 5px;
  background: ${colors['light-grey-50']};
  border-radius: 3px;
  padding: 3px 6px;

  font-size: 14px;

  display: inline-block;

  margin-right: 10px;

  ${(props) =>
    props.color &&
    css`
      background: ${getColorCombination(props.color).background};
      color: ${getColorCombination(props.color).color};
    `}
`;

const TaskProject = styled(Label)`
  cursor: pointer;

  :hover,
  :focus {
    background: ${lighten(colors.grey, 45)};
  }

  :active {
    background: ${lighten(colors.grey, 40)};
  }
`;

const TaskDueDate = styled(DateLabel)`
  margin-bottom: 5px;
`;

const TaskUser = styled(Label)`
  margin-bottom: 5px;
`;

const TaskText = styled(P)`
  ${(props: { completed: boolean }) =>
    props.completed &&
    css`
      text-decoration: line-through;
      color: ${colors['light-grey-20']};
    `}
`;

const CreatorText = styled(P)`
  font-size: 14px;
  color: ${lighten(colors.grey, 20)};

  margin-bottom: 5px;
`;

const Bullet = styled.div`
  margin: 0 11px 0 4px;
  color: ${colors['light-grey-30']};
`;

type Props = {
  task: TaskProps;
  onAfterSave: () => void;
  className?: string;
  showProject?: boolean;
  showUser?: boolean;
};

function UserTask(props: Props) {
  const { task, onAfterSave, className, showProject, showUser } = props;

  const [warningDialogVisible, setWarningDialogVisible] = useState(false);
  const {
    state: { user, team },
  } = useContext(Store);

  const handleCheckboxChange = () => {
    let status = 2;
    if (task.status === 2) {
      status = 1;
    }

    post('/tasks/userToggleTask', {
      taskID: task.taskID,
      status,
    }).then(() => onAfterSave());
  };

  const handleDeleteTask = () => {
    post('/tasks/delete', {
      taskID: task.taskID,
    }).then(() => onAfterSave());
  };

  const creatorUser = team.find(
    (teammate: UserProps) => teammate.userID === task.data.creatorUserID
  );

  return (
    <TaskContainer className={className} key={task.taskID}>
      <Dialog
        isVisible={warningDialogVisible}
        handleHideDialog={() => setWarningDialogVisible(false)}
      >
        <Warning
          onConfirm={handleDeleteTask}
          onCancel={() => setWarningDialogVisible(false)}
          header="Are you sure you want to delete this task?"
        />
      </Dialog>
      {user.userID !== task.userID && task.status === 1 && <Bullet>•</Bullet>}
      {user.userID !== task.userID && task.status === 2 && (
        <Bullet>
          <FontAwesomeIcon icon={['fas', 'check']} />
        </Bullet>
      )}
      {user.userID === task.userID && (
        <Checkbox
          labelSx={{ width: 'unset' }}
          checked={task.status === 2}
          onChange={handleCheckboxChange}
        />
      )}
      <RightContainer>
        <TextContainer>
          <TaskText completed={task.status === 2}>{task.data.text}</TaskText>
          {task.status === 2 && (
            <Timestamp>
              {moment(task.data.completedOn, 'YYYY-MM-DD HH:MM:SS').format(
                'MM-DD-YY @ hh:mma'
              )}
            </Timestamp>
          )}
          {user.userID === task.data.creatorUserID && (
            <Box ml={10}>
              <Button
                color={colors.red}
                thin
                icon
                transparent
                fontSize={12}
                onClick={() => setWarningDialogVisible(true)}
              >
                <FontAwesomeIcon icon={['fad', 'trash-alt']} />
              </Button>
            </Box>
          )}
        </TextContainer>
        {task.status === 1 && (
          <div>
            {creatorUser && (
              <CreatorText>{`Assigned by ${creatorUser.givenName}`}</CreatorText>
            )}
            {showUser && task.user && (
              <TaskUser color={task.userID === user.userID ? 'blue' : ''}>
                {task.user && task.user.givenName}
              </TaskUser>
            )}
            {showProject && task.project && task.project.projectID && (
              <TaskProject
                onClick={() =>
                  goToUrl({
                    projectID: task.project.projectID,
                    view: 'projectContents',
                  })
                }
              >
                {task.project && task.project.name}
                &nbsp;&nbsp;
                <FontAwesomeIcon icon={['fad', 'external-link']} />
              </TaskProject>
            )}
            {task.data.dueDate && <TaskDueDate date={task.data.dueDate} />}
            <TaskNotes completed={task.status !== 1}>
              {task.data.notes}
            </TaskNotes>
          </div>
        )}
      </RightContainer>
    </TaskContainer>
  );
}

UserTask.defaultProps = {
  onAfterSave: () => {},
  className: '',
  showProject: false,
  showUser: false,
  checkColor: colors.blue,
};

export default UserTask;
