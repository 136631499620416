import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { motion, AnimatePresence } from 'framer-motion';

import { colors } from './styles/colors';
import elevations from './styles/elevations';
import { ChildrenProps } from '../props/general';

function Snackbar(props) {
  const { isVisible, children, style, className } = props;

  return (
    <AnimatePresence>
      {isVisible && (
        <Container
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 30, opacity: 0, transition: { easing: 'easeOut' } }}
          style={style}
          className={className}
        >
          {children}
        </Container>
      )}
    </AnimatePresence>
  );
}

const Container = styled(motion.div)`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;

  margin: 30px;

  max-width: 300px;
  /* background: ${colors.night}; */
  /* border-radius: 3px; */

  box-shadow: ${elevations[3]};
`;

const { bool, string, shape } = PropTypes;

Snackbar.propTypes = {
  isVisible: bool.isRequired,
  children: ChildrenProps,
  style: shape({}),
  className: string,
};

export default Snackbar;
