import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import Draggable from '../../Draggable';
import Keyframe from './Keyframe';

import { colors, fade } from '../../styles/colors';

import { Row, ElementContainer } from './TimelineStyles';

import ElementProps from '../props/ElementProps';

const { number, func, arrayOf, shape, bool, string } = PropTypes;

const RowHandleEnd = styled.div`
  position: absolute;
  right: -3px;
  height: 100%;
  width: 6px;
  z-index: 1;
  cursor: ew-resize;
`;

const RowHandleStart = styled(RowHandleEnd)`
  left: -3px;
`;

const KeyframeConnectorContainer = styled.div`
  cursor: pointer;
  position: absolute;
  height: 3px;

  display: flex;
  align-items: center;

  height: inherit;

  ${(props) => css`
    left: ${props.left}px;
  `}
`;

const KeyframeConnector = styled.div`
  background-color: ${fade(colors['light-lavender-10'], 50)};
  height: 30%;
  ${(props) => css`
    width: ${props.width}px;
  `};

  ${(props) =>
    props.active &&
    css`
      background-color: ${fade(colors['light-teal-10'], 50)};
    `}
`;

const getMin = (keyframes, pixelsPerMs) => keyframes[0].time * pixelsPerMs;
const getMax = (keyframes, pixelsPerMs) =>
  keyframes[keyframes.length - 1].time * pixelsPerMs;

const TimelineElementRow = (props) => {
  const {
    height,
    handleDragElement,
    element,
    elementStart,
    elementDuration,
    pixelsPerMs,
    isSelected,
    handleDragEnd,
    handleDragStart,
    handleSelectAll,
    keyframeKey,
    selectedKeyframes,
    handleSelectKeyframes,
    handleMouseUp,
  } = props;

  const [localKeyframes, setLocalKeyframes] = useState(
    element.keyframes[keyframeKey] ? [...element.keyframes[keyframeKey]] : []
  );

  const min = localKeyframes.length
    ? getMin(localKeyframes, pixelsPerMs)
    : null;
  const max = localKeyframes.length
    ? getMax(localKeyframes, pixelsPerMs)
    : null;
  let allKeyframesSelected = false;

  // Update position when updated from parent
  useEffect(() => {
    if (
      element.keyframes[keyframeKey] &&
      element.keyframes[keyframeKey] !== localKeyframes
    ) {
      setLocalKeyframes(element.keyframes[keyframeKey]);
    }
  }, [element.keyframes, keyframeKey, localKeyframes]);

  if (localKeyframes && localKeyframes.length > 1) {
    allKeyframesSelected = localKeyframes.every((keyframe) =>
      selectedKeyframes.some((s) => s.id === keyframe.id)
    );
  }

  const handleDragKeyframes = (move) => {
    const change = move.dx / pixelsPerMs;
    // Shift all keyframes
    element.moveKeyframes(keyframeKey, change);
    setLocalKeyframes([...element.keyframes[keyframeKey]]);
  };

  const handleDragKeyframe = (move, e, keyframe) => {
    e.stopPropagation();

    const change = move.dx / pixelsPerMs;

    element.moveKeyframe(keyframeKey, keyframe.id, change);
    setLocalKeyframes([...element.keyframes[keyframeKey]]);
  };

  return (
    <Row height={height}>
      <Draggable onMouseMove={handleDragElement} onMouseUp={handleMouseUp}>
        <ElementContainer
          style={{
            width: `${elementDuration * pixelsPerMs}px`,
            left: `${elementStart * pixelsPerMs}px`,
          }}
          isSelected={isSelected}
        >
          <Draggable
            onMouseMove={handleDragEnd}
            onMouseUp={handleMouseUp}
            onMouseDown={(e) => e.stopPropagation()}
          >
            <RowHandleEnd />
          </Draggable>
          <Draggable
            onMouseMove={handleDragStart}
            onMouseUp={handleMouseUp}
            onMouseDown={(e) => e.stopPropagation()}
          >
            <RowHandleStart />
          </Draggable>

          <Draggable
            onMouseMove={handleDragKeyframes}
            onMouseDown={(e) => e.stopPropagation()}
            style={{ height: '100%' }}
          >
            <KeyframeConnectorContainer
              left={min}
              onClick={() => handleSelectAll(keyframeKey)}
            >
              <KeyframeConnector
                active={allKeyframesSelected}
                left={min}
                width={max - min}
              />
            </KeyframeConnectorContainer>
          </Draggable>

          {localKeyframes &&
            localKeyframes.map((keyframe, i) => {
              const isActive = !!selectedKeyframes.find(
                (k) => k.id === keyframe.id
              );

              return (
                <Keyframe
                  key={i}
                  keyframeKey={keyframeKey}
                  isActive={isActive}
                  pixelsPerMs={pixelsPerMs}
                  keyframe={keyframe}
                  element={element}
                  selectedKeyframes={selectedKeyframes}
                  handleSelectKeyframes={handleSelectKeyframes}
                  handleDragKeyframe={handleDragKeyframe}
                />
              );
            })}
        </ElementContainer>
      </Draggable>
    </Row>
  );
};

TimelineElementRow.propTypes = {
  height: number,
  handleDragElement: func.isRequired,
  element: ElementProps.isRequired,
  pixelsPerMs: number.isRequired,
  isSelected: bool.isRequired,
  handleDragEnd: func.isRequired,
  handleDragStart: func.isRequired,
  elementStart: number.isRequired,
  elementDuration: number.isRequired,
  handleMouseUp: func.isRequired,
  handleSelectAll: func,
  keyframeKey: string,
  keyframes: shape({}),
  selectedKeyframes: arrayOf(shape({})),
  handleSelectKeyframes: func,
};

TimelineElementRow.defaultProps = {
  handleSelectAll: () => {},
  keyframeKey: '',
  keyframes: {},
  selectedKeyframes: [],
  handleSelectKeyframes: () => {},
  handleMouseUp: () => {},
};

export default TimelineElementRow;
