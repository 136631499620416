import PropTypes from 'prop-types';

const { shape, string } = PropTypes;

const AnnotationProps = shape({
  attributes: shape({
    style: shape({}),
  }),
  id: string,
  type: string,
});

export { AnnotationProps };
