import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

const Container = styled.div`
  ${(props) =>
    props.hasCursor &&
    css`
      cursor: ${props.isDragging ? 'grabbing' : 'grab'};
    `}
`;

/**
 * A container that passes back the mouse move on drag of the children elements.
 */
function Draggable(props) {
  const {
    onMouseMove,
    onMouseDown,
    onMouseUp,
    cursor,
    children,
    style,
  } = props;

  const [isDragging, setIsDragging] = useState(false);
  const [move, setMove] = useState(null);
  const eventRef = useRef();

  const handleMouseUp = (e) => {
    if (isDragging) onMouseUp(e);

    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const dx = e.movementX;
      const dy = e.movementY;

      // Update state with last move
      setMove({ dx, dy });
      // Save event
      eventRef.current = e;

      e.stopPropagation();
      e.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
    };
    // eslint-disable-next-line
  }, [isDragging]);

  useEffect(() => {
    // Return updated move after react render to avoid conflicting with native event listeners
    if (move) {
      onMouseMove(move, eventRef.current);
    }
    // eslint-disable-next-line
  }, [move]);

  const handleMouseDown = (e) => {
    setIsDragging(true);

    onMouseDown(e);
  };

  return (
    <Container
      style={style}
      hasCursor={cursor}
      isDragging={isDragging}
      onMouseDown={handleMouseDown}
    >
      {children}
    </Container>
  );
}

Draggable.propTypes = {
  onMouseMove: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  cursor: PropTypes.bool,
  style: PropTypes.shape({}),
};

Draggable.defaultProps = {
  onMouseMove: () => {},
  onMouseDown: () => {},
  onMouseUp: () => {},
  cursor: false,
  style: {},
};

export default Draggable;
