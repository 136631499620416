import React, { useCallback, useEffect, useState } from 'react';
import { Box, BoxProps, Text, TextProps } from 'rebass/styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../RebassButton';
import { HeaderSection } from '../views/ProjectContentsStyles';
import Dialog from '../Dialog';
import useModal from '../../app/hooks/useModal';
import SearchOsmosisVideos from '../SearchOsmosisVideos';
import { post } from '../../util/fetchUtil';
import Label from '../Label';
import { Flex } from '../Box';
import WarningDialog from '../dialogs/WarningDialog';
import { colors } from '../styles/colors';
import LoadingSpinner from '../LoadingSpinner';
import copyToClipboard from '../../app/copyToClipboard';
import useSnackbar from '../../app/hooks/useSnackbar';
import Snackbar from '../Snackbar';
import SnackbarContainer from '../SnackbarContainer';
import { osmosisHost } from '../../app/host';
import OsmosisNodeProps from '../../types/OsmosisNodeProps';
import Message from '../Message';

type Props = {
  projectID: number;
};

const Heading = (props: TextProps) => (
  <Text {...props} mb={2} as="h3" opacity="0.5">
    {props.children}
  </Text>
);

const InfoSection = (props: BoxProps) => (
  <Box
    {...props}
    sx={{
      ':not(:first-child)': {
        marginTop: 4,
      },
    }}
  >
    {props.children}
  </Box>
);

const statusMap: { [index: number]: { text: string; color: string } } = {
  2: {
    text: 'Published - Prime',
    color: 'green',
  },
  1: {
    text: 'Published',
    color: 'green',
  },
  0: {
    text: 'Draft',
    color: 'gray',
  },
};

const s3Host = 'https://osmose-it.s3.amazonaws.com';
const host = osmosisHost(3001);

export default function OsmosisSection(props: Props) {
  const { projectID } = props;

  const [osmosisData, setOsmosisData] = useState<OsmosisNodeProps>();
  const [isLoading, setIsLoading] = useState(true);
  const [existingLink, setExistingLink] = useState();

  const dialog = useModal();
  const unlinkModal = useModal();
  const snackbar = useSnackbar();

  const s3Link = `${s3Host}/${osmosisData?.media.data.s3}`;
  const osmosisLink = `https://osmosis.org/learn/${osmosisData?.learn.name}`;
  const youtubeLink = `https://www.youtube.com/watch?v=${osmosisData?.media.data.v}`;
  const thumbnailLink = `${s3Host}/${
    osmosisData?.media.data.$$ || osmosisData?.media.data.$
  }`;
  const status = osmosisData && statusMap[osmosisData.media.status];
  console.log(osmosisData);

  const handleCopyLink = (link: string) => {
    copyToClipboard(link);
    snackbar.show();
  };

  const getNodeData = useCallback(async () => {
    if (projectID) {
      setIsLoading(true);
      const res = await post('/osmosis/getLearnNode', {
        projectID,
      });

      if (res?.learn) {
        setOsmosisData({
          learn: res.learn,
          media: res.media,
        });
      }

      setIsLoading(false);
    }
  }, [projectID]);

  const handleSelectVideo = async (option: { id: number }) => {
    const res = await post('/osmosis/linkOsmosisNode', {
      nodeID: option.id,
      projectID,
    });

    if (res.error) {
      setExistingLink(res.project.projectID);
    } else {
      setExistingLink(undefined);
      await getNodeData();
    }

    dialog.hide();
  };

  const handleConfirmUnlink = async () => {
    await post('/osmosis/unlinkOsmosisNode', {
      projectID,
    });

    setOsmosisData(undefined);
    unlinkModal.hide();
  };

  useEffect(() => {
    if (projectID) getNodeData();
  }, [projectID, getNodeData]);

  return (
    <Box>
      <Snackbar isVisible={snackbar.isVisible}>
        <SnackbarContainer>Copied to clipboard</SnackbarContainer>
      </Snackbar>
      <Dialog handleHideDialog={dialog.hide} isVisible={dialog.isVisible}>
        <SearchOsmosisVideos onSelectItem={handleSelectVideo} />
      </Dialog>
      <Dialog
        handleHideDialog={unlinkModal.hide}
        isVisible={unlinkModal.isVisible}
      >
        <WarningDialog
          header="Are you sure you want to unlink this project from Osmosis?"
          confirmText="Yes, unlink"
          cancelText="Cancel"
          onConfirm={handleConfirmUnlink}
          onCancel={unlinkModal.hide}
          color={colors.red}
        />
      </Dialog>
      {isLoading && (
        <Flex c p={3}>
          <LoadingSpinner />
        </Flex>
      )}
      {osmosisData && (
        <>
          <HeaderSection>
            <Text></Text>
            <Button
              onClick={() => {
                window.open(
                  `${host}/editlearn?title=${osmosisData.learn.name}`,
                  '_blank'
                );
              }}
              variant="transparent"
              color="light-7"
              startIcon={<FontAwesomeIcon icon={['fad', 'external-link']} />}
            >
              Edit on Osmosis
            </Button>
          </HeaderSection>
          <Box>
            <InfoSection>
              <Heading>Title</Heading>
              {osmosisData?.learn?.name && (
                <Text fontWeight="bold" as="h2">
                  {osmosisData?.learn?.name}
                </Text>
              )}
            </InfoSection>
            <InfoSection>
              <Heading>URL</Heading>
              <Label onClick={() => window.open(osmosisLink, '_blank')}>
                {osmosisLink}
              </Label>
            </InfoSection>
            <InfoSection>
              <Heading>Status</Heading>
              <Label round color={status?.color}>
                {status?.text}
              </Label>
            </InfoSection>
            <InfoSection>
              <Heading>Video</Heading>
              {osmosisData?.media?.data.v && (
                <Box mb={2}>
                  <Box mb={2}>
                    <Text as="h4">YouTube ID</Text>
                  </Box>
                  <Flex>
                    <Label onClick={() => window.open(youtubeLink, '_blank')}>
                      {osmosisData.media.data.v}
                    </Label>
                    <Box ml={1}>
                      <Button
                        size="s"
                        bg="light-2"
                        color="dark-6"
                        onClick={() => handleCopyLink(youtubeLink)}
                      >
                        <FontAwesomeIcon icon={['fad', 'copy']} />
                      </Button>
                    </Box>
                  </Flex>
                </Box>
              )}
              {osmosisData?.media?.data.s3 && (
                <Box>
                  <Box mb={2}>
                    <Text as="h4">MP4</Text>
                  </Box>
                  <Flex>
                    <Label onClick={() => window.open(s3Link, '_blank')}>
                      {osmosisData.media.data.s3}
                    </Label>
                    <Box ml={1}>
                      <Button
                        size="s"
                        bg="light-2"
                        color="dark-6"
                        onClick={() => handleCopyLink(s3Link)}
                      >
                        <FontAwesomeIcon icon={['fad', 'copy']} />
                      </Button>
                    </Box>
                  </Flex>
                </Box>
              )}
            </InfoSection>
            <InfoSection>
              <Box>
                <Heading>Thumbnail</Heading>
                <Box>
                  <img
                    alt="Live video thumbnail"
                    style={{ maxWidth: 250 }}
                    src={thumbnailLink}
                  />
                </Box>
              </Box>
            </InfoSection>
          </Box>
          <Flex mt={3} justifyContent="flex-end">
            <Button
              variant="outline"
              color="negative-3"
              onClick={() => unlinkModal.show()}
            >
              Unlink
            </Button>
          </Flex>
        </>
      )}
      {!osmosisData && !isLoading && (
        <Flex c>
          <Flex c flexDirection="column">
            {existingLink && (
              <Message negative>
                Video is already linked to a&nbsp;
                <a href={`/#/projectContents/${existingLink}`}>
                  different project
                </a>
              </Message>
            )}
            <Text as="h2" opacity="0.5" m={5} mb={3}>
              Not currently linked to Osmosis
            </Text>
            <Button onClick={() => dialog.show()}>Link to Osmosis</Button>
          </Flex>
        </Flex>
      )}
    </Box>
  );
}
