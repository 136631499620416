import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { getCardData } from '../app/utilities/trello';

import elevations from './styles/elevations';
import { colors } from './styles/colors';

const Wrapper = styled.div`
  display: inline-block;
  box-shadow: ${elevations[1]};
  border: 1px solid ${colors['light-grey-50']};
  border-radius: 3px;
  overflow: hidden;
`;

function TrelloCardWrapper(props) {
  const {
    cardId,
    onClick,
    onLabelTextChanged,
    colorblind,
    labelText,
    style,
    className,
  } = props;

  const [card, setCard] = useState(null);
  const el = useRef();

  useEffect(() => {
    if (!el.current) {
      return;
    }

    el.current.card = card;
  });

  const handleClick = (e) => {
    if (typeof onClick === 'function') {
      e.preventDefault();
      onClick();
    }
  };

  const handleLabelTextChanged = (e) => {
    if (typeof onLabelTextChanged === 'function') {
      onLabelTextChanged(e.detail.labeltext);
    }
  };

  const handleGetCardData = () => {
    getCardData(cardId).then((res) => {
      setCard(res);
    });

    if (!el.current) {
      return;
    }

    el.current.card = card;
    el.current.addEventListener('click', handleClick);
    el.current.addEventListener('labelTextChanged', handleLabelTextChanged);
  };

  useEffect(() => {
    handleGetCardData();
    // eslint-disable-next-line
  }, [cardId]);

  return (
    <Wrapper style={style} className={className}>
      {card && (
        <trello-card ref={el} colorblind={colorblind} labeltext={labelText} />
      )}
    </Wrapper>
  );
}

TrelloCardWrapper.propTypes = {
  cardId: PropTypes.string.isRequired, // The Trello card id
  colorblind: PropTypes.bool,
  labelText: PropTypes.bool,
  onClick: PropTypes.func,
  onLabelTextChanged: PropTypes.func,
  style: PropTypes.shape({}),
  className: PropTypes.string,
};

TrelloCardWrapper.defaultProps = {
  colorblind: false,
  labelText: true,
  onClick: null,
  onLabelTextChanged: null,
};

export default TrelloCardWrapper;
