import { isMac } from './device';

export default {
  shift: 'shiftKey',
  control: 'ctrlKey',
  ctrl: 'ctrlKey',
  cmd: isMac() ? 'metaKey' : 'ctrlKey',
  command: isMac() ? 'metaKey' : 'ctrlKey',
  meta: 'metaKey',
  alt: 'altKey',
  metaKey: 'metaKey',
  altKey: 'altKey',
  shiftKey: 'shiftKey',
  ctrlKey: 'ctrlKey',
};
