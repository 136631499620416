import React from 'react';
import styled from 'styled-components/macro';

import moment from 'moment';

import Box from '../../Box';
import Message from '../../Message';

import { colors } from '../../styles/colors';
import { H4, P } from '../../styles/typography';

import { Heading } from '../AssetStyles';
import UploadBox from '../../UploadBox';
import FileProps from '../../editor/types/FileProps';

const Container = styled.div`
  padding: 10px;
`;

const RowText = styled(P)`
  color: ${colors['light-grey-20']};
`;

const Row = styled(Box)`
  display: flex;
  padding: 5px 8px;
  border-radius: 3px;

  cursor: pointer;

  :hover,
  :focus {
    background-color: ${colors['light-grey-50']};
  }

  :active {
    background-color: ${colors['light-grey-40']};
  }
`;

type Props = {
  file: FileProps;
  s3Dir: string;
  canEdit: boolean;
  handleAfterUpload: (res: any) => Promise<void>;
};

function FileVersions(props: Props) {
  const { file, s3Dir, canEdit, handleAfterUpload } = props;

  const handleVersionClick = (version: { url: string }) => {
    window.open(version.url, '_blank');
  };

  const fileVersions =
    file.data.versions &&
    file.data.versions
      .sort((a, b) => +new Date(b.date) - +new Date(a.date))
      .map((f) => (
        <Row key={f.date} onClick={() => handleVersionClick(f)}>
          <RowText title={`Uploaded by ${f.user.name}`}>
            {moment(f.date).format('hh:mma MM-DD-YYYY')}
          </RowText>
        </Row>
      ));

  const uploader = (
    <UploadBox
      isProjectUpload={false}
      types={[file.type]}
      s3Dir={s3Dir}
      onAfterUpload={handleAfterUpload}
      uploadText="Upload new version"
    />
  );

  return (
    <Container>
      <Heading>Versions</Heading>
      {canEdit && uploader}
      {file.data.versions && file.data.versions.length && (
        <Box>
          <H4
            style={{ color: colors['light-grey-10'], margin: '10px 0 5px 0' }}
          >
            Previous versions
          </H4>
          <Box style={{ overflow: 'auto', maxHeight: 250 }}>{fileVersions}</Box>
        </Box>
      )}
      {(!file.data.versions ||
        (file.data.versions && file.data.versions.length === 0)) && (
        <Message style={{ marginTop: 10 }}>
          This is the only version of this asset.
        </Message>
      )}
    </Container>
  );
}

FileVersions.defaultProps = {
  file: {},
  uploader: null,
  canEdit: true,
};

export default FileVersions;
