/**
 * Helper class for searching through SVG nodes
 * @constructor
 */
function NodeSearch() {}

/**
 * Gets an array of children types inside passed node (exclusive of parent)
 * @param  {Object object} node Parent element
 * @return {array}      Array of nodeNames
 */
NodeSearch.prototype.getChildTypeArray = function (node) {
  const childrenTypes = [];
  const searchChildren = (node) => {
    childrenTypes.push(node.nodeName);
    if (node.children) {
      for (let i = 0; i < node.children.length; i += 1) {
        searchChildren(node.children[i]);
      }
    }
  };
  searchChildren(node);

  // Remove first entry (parent node)
  childrenTypes.shift();

  return childrenTypes;
};

/**
 * Gets an array of child nodes inside passed parent node (exclusive of parent)
 * @param  {Object object} node Parent element
 * @return {array} Array of nodeNames
 */
NodeSearch.prototype.getChildArray = function (node) {
  const children = [];
  const searchChildren = (node) => {
    children.push(node);
    if (node.children) {
      for (let i = 0; i < node.children.length; i += 1) {
        searchChildren(node.children[i]);
      }
    }
  };
  searchChildren(node);

  // Remove first entry (parent node)
  children.shift();

  return children;
};

/**
 * Checks to see if a given node has only the type(s) of passed child node(s)
 * @param  {Object object} node Parent element
 * @param  {Array} type Array of String types (g, text, div, etc.)
 * @return {Boolean} True if it contains only the passed node types, false otherwise
 */
NodeSearch.prototype.hasOnlyChildrenOfType = function (node, types) {
  const childrenTypes = this.getChildTypeArray(node);
  for (let i = 0; i < childrenTypes.length; i += 1) {
    let match = false;
    for (let j = 0; j < types.length; j += 1) {
      if (childrenTypes[i] === types[j]) {
        match = true;
      }
    }
    if (!match) {
      return false;
    }
  }
  return true;
};

/**
 * Determines whether the given node has a child node with the given nodeName
 * @param  {Object object} node     parent DOM node to check
 * @param  {String} nodeName 				node name to find
 * @return {Boolean}          			true or false
 */
NodeSearch.prototype.hasChild = function (node, nodeName) {
  const element = this.getChildArray(node).find((x) => x.nodeName === nodeName);
  if (element) {
    return true;
  }
  return false;
};

NodeSearch.prototype.getChildByNodeName = function (node, nodeName) {
  const element = this.getChildArray(node).find((x) => x.nodeName === nodeName);
  if (element) {
    return element;
  }
  return null;
};

NodeSearch.prototype.getChildById = function (node, id) {
  // Find matching id, case-insensitive
  const re = new RegExp(`^${id}`, 'i');

  const element = this.getChildArray(node).find((x) => re.test(x.id));
  if (element) {
    return element;
  }
  return null;
};

export default new NodeSearch();
