import tinycolor from 'tinycolor2';

type Colors = {
  [index: string]: string;
};

export const colors: Colors = {
  blue: '#2079DF',
  teal: '#1C95BA',
  olive: '#2AA788',
  green: '#31AA31',
  yellow: '#EEC430',
  orange: '#DF6C20',
  red: '#D53939',
  pink: '#D33162',
  lavender: '#6E60C9',
  purple: '#9D4DCB',
  grey: '#636363',
  gray: '#636363',
  black: '#000000',
  night: '#2f3137',
};

const keys = Object.keys(colors);
for (let i = 0; i < keys.length; i += 1) {
  for (let j = 1; j < 10; j += 1) {
    const amount = j * 10;
    colors[`light-${keys[i]}-${amount}`] = tinycolor(colors[keys[i]])
      .lighten(amount)
      .toHexString();
    colors[`dark-${keys[i]}-${amount}`] = tinycolor(colors[keys[i]])
      .darken(amount)
      .toHexString();
  }
}

/**
 * Lighten
 *
 * @param {String} color - string representation of the color (can be a key to the colors object)
 * @param {Number} amount - amount to lighten between 0 - 100
 * @returns {String} - the new color
 */
export const lighten = (color: string, amount: number): string => {
  let newColor = color;
  if (colors[color]) newColor = colors[color];

  return tinycolor(newColor).lighten(amount).toHexString();
};

/**
 * Darken
 *
 * @param {String} color - string representation of the color (can be a key to the colors object)
 * @param {Number} amount - amount to lighten between 0 - 100
 * @returns {String} - the new color
 */
export const darken = (color: string, amount: number): string => {
  let newColor = color;
  if (colors[color]) newColor = colors[color];

  return tinycolor(newColor).darken(amount).toHexString();
};

/**
 * Helper function to fade out a color
 *
 * @param {String} color
 * @param {Number} amount - number between 0 and 100 (0 = transparent, 100 = opaque)
 * @returns
 */
export const fade = (color: string, amount: number): string => {
  const tColor = tinycolor(color);
  const previousAlpha = tColor.getAlpha();

  let newAlpha = previousAlpha - amount / 100;
  if (newAlpha > 1) newAlpha = 1;
  if (newAlpha < 0) newAlpha = 0;

  return tColor.setAlpha(newAlpha).toRgbString();
};

export const getColorCombination = (
  color: string
): { background: string; color: string } => {
  let background = '';
  let textColor = '';

  if (colors[color]) {
    background = lighten(colors[color], 40);
    textColor = darken(colors[color], 10);

    if (color === 'teal' || color === 'olive' || color === 'green') {
      background = lighten(colors[color], 45);
    }

    if (color === 'yellow' || color === 'lavender') {
      background = lighten(colors[color], 35);
      textColor = darken(colors[color], 25);
    }

    if (color === 'grey' || color === 'gray') {
      background = lighten(colors[color], 53);
      textColor = darken(colors[color], 5);
    }
  }

  return { background, color: textColor };
};
