import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { isNumber } from '../utilities/numbers';
import { TextInput } from './Input';
import useHotkeys from '../app/hooks/useHotkeys';

/**
 * Input for handling number inputs only
 */
function NumberInput({
  className,
  style,
  onChange,
  value,
  min,
  max,
  fixed,
  step,
  placeholder,
  darkMode,
}) {
  const [localValue, setLocalValue] = useState(value);
  const [hasFocus, setHasFocus] = useState(false);

  const handleLocalChange = (val) => {
    setLocalValue(val);
  };

  const updateValue = (newVal) => {
    if (isNumber(newVal) && newVal >= min && newVal <= max) {
      onChange(newVal);
    }
  };

  const handleChange = () => {
    const number = +localValue;

    updateValue(number);
  };

  const onBlur = () => {
    setHasFocus(false);

    handleChange();
  };

  useHotkeys(
    [
      {
        // Up arrow
        name: 'ArrowUp',
        modifiers: [],
        onPress: (e) => {
          if (hasFocus) {
            e.preventDefault();
            let newVal = +localValue + step;
            if (newVal > max) newVal = max;

            setLocalValue(newVal);
            updateValue(newVal);
          }
        },
      },
      {
        // Up arrow
        name: 'ArrowUp',
        modifiers: ['shift'],
        onPress: (e) => {
          if (hasFocus) {
            e.preventDefault();
            let newVal = +localValue + step * 10;
            if (newVal > max) newVal = max;

            setLocalValue(newVal);
            updateValue(newVal);
          }
        },
      },
      {
        // Down arrow
        name: 'ArrowDown',
        modifiers: [],
        onPress: (e) => {
          if (hasFocus) {
            e.preventDefault();
            let newVal = +localValue - step;
            if (newVal < min) newVal = min;

            setLocalValue(newVal);
            updateValue(newVal);
          }
        },
      },
      {
        // Down arrow
        name: 'ArrowDown',
        modifiers: ['shift'],
        onPress: (e) => {
          if (hasFocus) {
            e.preventDefault();
            let newVal = +localValue - step * 10;
            if (newVal < min) newVal = min;

            setLocalValue(newVal);
            updateValue(newVal);
          }
        },
      },
      {
        // Enter
        name: 'Enter',
        modifiers: [],
        onPress: () => {
          if (hasFocus) {
            handleChange();
          }
        },
      },
    ],
    [],
    { allowInputsActive: true }
  );

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <TextInput
      className={className}
      value={
        Number.isInteger(fixed) && !Number.isNaN(localValue)
          ? (+localValue).toFixed(fixed).toString()
          : localValue.toString()
      }
      placeholder={placeholder}
      onChange={(e) => handleLocalChange(e.target.value)}
      onBlur={onBlur}
      onFocus={() => setHasFocus(true)}
      style={style}
      darkMode={darkMode}
    />
  );
}

const { number, string, bool, shape, func } = PropTypes;

NumberInput.propTypes = {
  min: number,
  max: number,
  fixed: number,
  value: number,
  step: number,
  placeholder: string,
  darkMode: bool,
  className: string,
  style: shape({}),
  onChange: func,
};

NumberInput.defaultProps = {
  min: -Infinity,
  max: Infinity,
  value: 0,
  step: 10,
  placeholder: '',
  darkMode: false,
  fixed: 0,
  style: {},
  onChange: () => {},
};

export default NumberInput;
