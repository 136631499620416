import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Dispatch, SetStateAction } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Flex } from '../../Box';
import Lightbox from '../../Lightbox';
import { colors } from '../../styles/colors';

const IframeLightbox = styled.iframe`
  border-radius: 3px;
  overflow: hidden;

  width: 100%;
  height: 100%;

  background: white;
`;

type Props = {
  file: any;
  showPreview: boolean;
  setShowPreview: Dispatch<SetStateAction<boolean>>;
};

function PdfAsset(props: Props) {
  const { file, showPreview, setShowPreview } = props;

  return (
    <Flex c style={{ height: '100%' }}>
      <FontAwesomeIcon
        style={{ fontSize: 32, color: colors['light-grey-20'] }}
        icon={['fad', 'file-pdf']}
      />
      <Lightbox
        isVisible={showPreview}
        handleHideLightbox={() => setShowPreview(false)}
      >
        <IframeLightbox src={file.data.url} />
      </Lightbox>
    </Flex>
  );
}

PdfAsset.propTypes = {
  file: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  showPreview: PropTypes.bool,
  setShowPreview: PropTypes.func.isRequired,
};

PdfAsset.defaultProps = {
  file: {},
  showPreview: false,
};

export default PdfAsset;
