import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo } from 'react';

import Box, { Flex } from '../../Box';
import { colors, fade } from '../../styles/colors';
import TimelineRowInfo from './TimelineRowInfo';

import ElementProps from '../props/ElementProps';

const shouldMemo = (prevProps, nextProps) => {
  if (
    nextProps.element.type === 'group' ||
    nextProps.isSelected !== prevProps.isSelected ||
    nextProps.elementsToUpdate.current.find((id) => id === nextProps.element.id)
  )
    return false;
  return true;
};

/**
 * Wrapper for a timeline info component, allows for recursive grouping
 */
const TimelineRowInfoWrapper = memo((props) => {
  const { element } = props;

  const isGroup = element.type === 'group';
  const hasMask = !!element.maskElements.length;

  return (
    <>
      <TimelineRowInfo {...props} />
      {hasMask && (
        <Box>
          {element.maskElements.map((el) => (
            <Flex key={el.id}>
              <Box
                style={{
                  padding: 5,
                  border: `1px solid ${fade('white', 75)}`,
                }}
              >
                <FontAwesomeIcon
                  icon={['fad', 'mask']}
                  style={{ color: 'white' }}
                />
              </Box>
              <TimelineRowInfoWrapper
                {...props}
                element={el}
                style={{
                  width: '100%',
                }}
              />
            </Flex>
          ))}
        </Box>
      )}
      {isGroup && (
        <>
          <Box
            style={{
              marginLeft: 15,
              borderLeft: `1px solid ${colors['light-night-20']}`,
            }}
          >
            {element.elements.map((el) => (
              <TimelineRowInfoWrapper {...props} key={el.id} element={el} />
            ))}
          </Box>
        </>
      )}
    </>
  );
}, shouldMemo);

TimelineRowInfoWrapper.displayName = 'TimelineRowInfoWrapper';

TimelineRowInfoWrapper.propTypes = {
  element: ElementProps,
};

export default TimelineRowInfoWrapper;
