import React from 'react';
import PropTypes from 'prop-types';
import VideoComment from './VideoComment';
import Box from '../Box';

/**
 * A video js bar that's overlaid on the progress bar and displays comments
 */
function CommentBar(props) {
  const {
    comments,
    player,
  } = props;

  const duration = player.duration();

  return (
    <Box style={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      bottom: 0,
      padding: '0 10px',
    }}>
      {comments.map(comment => (
        <VideoComment
          key={comment.commentID}
          comment={comment}
          duration={duration * 1000}
        />
      ))}
    </Box>
  );
}

CommentBar.propTypes = {
  comments: PropTypes.array,
  player: PropTypes.shape({
    duration: PropTypes.func,
  }),
};

CommentBar.defaultProps = {
  comments: [],
  player: {},
};

export default CommentBar;
