import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';

import fetchUtil from '../../util/fetchUtil';

import Button from '../Button';
import Box, { Flex } from '../Box';
import UploadBox from '../UploadBox';
import Dialog from '../Dialog';
import Warning from '../dialogs/WarningDialog';
import Message from '../Message';
import Snackbar from '../Snackbar';

import { KitProps } from './assetsProps';

import { colors } from '../styles/colors';
import { H1, H3, P } from '../styles/typography';

import copyToClipboard from '../../app/copyToClipboard';

const Title = styled(H3)`
  color: ${colors['light-grey-30']};
  margin-bottom: 5px;
`;

const Section = styled(Box)`
  margin-bottom: 30px;
`;

const Thumbnail = styled.div`
  max-width: 400px;
  border-radius: 4px;
  overflow: hidden;

  img {
    width: 100%;
  }
`;

const RadioContainer = styled(Box)`
  padding: 10px;
  display: inline-block;

  label {
    margin-left: 10px;
  }
`;

const VisibilityWarning = styled(P)`
  opacity: 0.5;
  max-width: 300px;
  margin-left: 20px;
  margin-top: 10px;
`;

const formatUrl = (name) => name.replace(' ', '-');

function KitSettings(props) {
  const { kit, getSettings } = props;

  const [warningDialogVisible, setWarningDialogVisible] = React.useState(false);
  const [selectedVisibility, setSelectedVisibility] = React.useState(
    kit.data.visibility || 'osmosis'
  );
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const url = `https://osmosify.osmosis.org/kit/${formatUrl(kit.name)}-${
    kit.data.url
  }`;

  const history = useHistory();

  const handleDelete = () => {
    fetchUtil.post('/assets/deleteKit', {
      kitID: kit.kitID,
    });

    history.push('/assets');
  };

  const handleCopyUrl = () => {
    copyToClipboard(url);
    setSnackbarMessage(true);

    setTimeout(() => setSnackbarMessage(false), 2000);
  };

  const handleAfterUpload = async (res) => {
    const [file] = res;

    await fetchUtil.post('/assets/updateKitThumbnail', {
      kitID: kit.kitID,
      thumbnail: file.url,
    });

    getSettings(kit.kitID);
  };

  const handleUpdateVisibility = async (option) => {
    fetchUtil.post('/assets/updateKitVisibility', {
      kitID: kit.kitID,
      visibility: option.id,
    });

    setSelectedVisibility(option.id);

    getSettings(kit.kitID);
  };

  const visibilityOptions = [
    {
      name: 'Osmosis',
      id: 'osmosis',
      message: 'Only Osmosis team members can view or edit this kit.',
    },
    {
      name: 'Anyone can view',
      id: 'public-read',
      message: (
        <Flex>
          <Box>
            <FontAwesomeIcon
              style={{ color: colors.red, fontSize: 24, marginRight: 10 }}
              icon={['fad', 'exclamation-circle']}
            />
          </Box>
          Anyone can view or download assets in this kit. Only Osmosis team
          members can edit.
        </Flex>
      ),
    },
  ];

  return (
    <Box style={{ maxWidth: 1200, margin: 'auto' }}>
      <Snackbar
        isVisible={!!snackbarMessage}
        style={{ background: colors.night }}
      >
        <Box style={{ padding: 15 }}>
          <P>Copied to clipboard</P>
        </Box>
      </Snackbar>
      <Box style={{ height: '100%', padding: 30 }}>
        <Section>
          <Title>Name</Title>
          <H1>{kit.name}</H1>
        </Section>
        {selectedVisibility === 'public-read' && (
          <Section>
            <Title>Public URL</Title>
            <Flex style={{ alignItems: 'center' }}>
              <Box
                style={{ alignItems: 'center', cursor: 'pointer' }}
                onClick={() => window.open(url, { target: '_blank' })}
              >
                <Message>
                  <P>{url}</P>
                </Message>
              </Box>
              <Button onClick={handleCopyUrl} icon style={{ marginLeft: 5 }}>
                <FontAwesomeIcon icon={['fad', 'copy']} />
              </Button>
            </Flex>
          </Section>
        )}
        <Section>
          <Title>Thumbnail</Title>
          <Box style={{ maxWidth: 300 }}>
            {kit.data.thumbnail && (
              <Thumbnail>
                <img alt="Thumbnail" src={kit.data.thumbnail} />
              </Thumbnail>
            )}
            <UploadBox
              isProjectUpload={false}
              types={['jpeg', 'jpg', 'png']}
              s3Dir={`kits/${kit.kitID}`}
              onAfterUpload={handleAfterUpload}
            />
          </Box>
        </Section>
        <Section>
          <Title>Visibility</Title>
          {visibilityOptions.map((option) => (
            <Box key={option.id}>
              <RadioContainer onClick={() => handleUpdateVisibility(option)}>
                <input
                  type="radio"
                  checked={selectedVisibility === option.id}
                  onChange={() => handleUpdateVisibility(option)}
                />
                <label>{option.name}</label>
                {selectedVisibility === option.id && option.message && (
                  <VisibilityWarning>{option.message}</VisibilityWarning>
                )}
              </RadioContainer>
            </Box>
          ))}
        </Section>

        <Section>
          <Button
            onClick={() => setWarningDialogVisible(true)}
            box
            color="white"
            backgroundColor={colors.red}
          >
            Delete
          </Button>
          <Dialog
            isVisible={warningDialogVisible}
            handleHideDialog={() => setWarningDialogVisible(false)}
          >
            <Warning
              onConfirm={handleDelete}
              onCancel={() => setWarningDialogVisible(false)}
              header="Are you sure you want to delete this kit?"
              error
            />
          </Dialog>
        </Section>
      </Box>
    </Box>
  );
}

KitSettings.propTypes = {
  kit: KitProps,
  getSettings: PropTypes.func.isRequired,
};

export default KitSettings;
