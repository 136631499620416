import { SearchIndex } from '../../types/search';

const searchConfig = {
  [SearchIndex.PROJECTS]: {
    search_attributes: ['name^10', 'name.edge_ngram'],
    result_attributes: [
      'id',
      'name',
      'comments',
      'createdOn',
      'groups',
      'members',
      'recents',
      'status',
      'thumbnail',
      'types',
      'updatedOn',
    ],
    facet_attributes: [
      { attribute: 'recents', field: 'recents', type: 'string' },
      { attribute: 'members.userID', field: 'members.userID', type: 'number' },
    ],
  },
  [SearchIndex.FILES]: {
    search_attributes: [
      'name^10',
      'name.edge_ngram',
      'kits.keyword',
      'tags.keyword',
      'tags.edge_ngram',
    ],
    filter_attributes: [
      { attribute: 'kits', field: 'kits', type: 'string' },
      { attribute: 'id', field: 'id', type: 'string' },
    ],
    facet_attributes: [
      { attribute: 'tags', field: 'tags.keyword', type: 'string' },
    ],
    result_attributes: [
      'createdOn',
      'directories',
      'kits',
      'modified',
      'name',
      'id',
      'uuid',
      'projects',
      'status',
      'tags',
      'type',
      'url',
      'user',
    ],
  },
  [SearchIndex.PRONOUNCE]: {
    filter_attributes: [],
  },
};

export default searchConfig;
