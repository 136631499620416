export type PointProps = {
  x: number;
  y: number;
};

export type RectProps = {
  x: number;
  y: number;
  width: number;
  height: number;
};

/**
 * calculateSelectionBox - Calculate dimensions of selection box
 * @param sp - start point
 * @param ep - end point
 */
export const calculateSelectionBox = (
  sp: PointProps,
  ep: PointProps
): RectProps => {
  // The extra 1 pixel is to ensure that the mouse is on top
  // of the selection box and avoids triggering clicks on the target.
  // borrowed from react-ds
  const box = {
    x: Math.min(sp.x, ep.x) - 1,
    y: Math.min(sp.y, ep.y) - 1,
    width: Math.abs(sp.x - ep.x) + 1,
    height: Math.abs(sp.y - ep.y) + 1,
  };
  return box;
};

export default { calculateSelectionBox };
