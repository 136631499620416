import React from 'react';
import PropTypes from 'prop-types';
import { getDurationSinceNow } from '../../app/utilities/durations';
import CommentReply from './CommentReply';
import ProjectContentsMention from './ProjectContentsMention';
import ProjectTaskAssignment from './ProjectTaskAssignment';

import {
  Container,
  RightContainer,
  LeftContainer,
  Timestamp,
  MarkerContainer,
  Marker,
} from './NotificationRowStyles';

function NotificationRow(props) {
  const {
    sendingUserName,
    sendingUserAvatar,
    projectID,
    projectName,
    action,
    actionText,
    notificationID,
    status,

    data,

    handleUpdateStatus,

    timestamp,
  } = props;

  const isActive = status === 0;

  const handleMarkerClick = (e) => {
    e.stopPropagation();
    handleUpdateStatus([notificationID], isActive ? 1 : 0);
  };

  let notificationBody;

  switch (action) {
    case 'commentReply': // Same as commentMention
    case 'commentMention':
      notificationBody = (
        <CommentReply
          sendingUserName={sendingUserName}
          sendingUserAvatar={sendingUserAvatar}
          projectName={projectName}
          projectID={projectID}
          actionText={actionText}
          notificationID={notificationID}
          status={status}
          isActive={isActive}
          file={data.file}
          commentID={data.replyComment.commentID}
          handleUpdateStatus={handleUpdateStatus}
        />
      );
      break;
    case 'projectContentsMention':
      notificationBody = (
        <ProjectContentsMention
          sendingUserName={sendingUserName}
          sendingUserAvatar={sendingUserAvatar}
          projectName={projectName}
          projectID={projectID}
          actionText={actionText}
          notificationID={notificationID}
          status={status}
          isActive={isActive}
          handleUpdateStatus={handleUpdateStatus}
        />
      );
      break;
    case 'projectTaskAssignment':
      notificationBody = (
        <ProjectTaskAssignment
          sendingUserName={sendingUserName}
          sendingUserAvatar={sendingUserAvatar}
          projectName={projectName}
          projectID={projectID}
          actionText={actionText}
          notificationID={notificationID}
          status={status}
          isActive={isActive}
          handleUpdateStatus={handleUpdateStatus}
        />
      );
      break;

    default:
      break;
  }

  return (
    <Container isActive={isActive}>
      <LeftContainer>{notificationBody}</LeftContainer>

      <RightContainer>
        <Timestamp>{getDurationSinceNow(timestamp)}</Timestamp>
        <MarkerContainer>
          <Marker isActive={isActive} onClick={handleMarkerClick} />
        </MarkerContainer>
      </RightContainer>
    </Container>
  );
}

NotificationRow.propTypes = {
  actionText: PropTypes.string.isRequired,
  handleUpdateStatus: PropTypes.func.isRequired,
  notificationID: PropTypes.number.isRequired,
  status: PropTypes.number.isRequired,
  action: PropTypes.oneOf([
    'commentReply',
    'commentMention',
    'projectContentsMention',
    'projectTaskAssignment',
  ]).isRequired,
  data: PropTypes.object.isRequired,

  sendingUserName: PropTypes.string,
  sendingUserAvatar: PropTypes.string,
  projectName: PropTypes.string,
  projectID: PropTypes.number,
  timestamp: PropTypes.string,
  file: PropTypes.object,
};

NotificationRow.defaultProps = {
  file: {},
  sendingUserName: '',
  sendingUserAvatar: '',
  projectName: '',
  projectID: null,
  timestamp: '',
};

export default NotificationRow;
