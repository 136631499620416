import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useContext } from 'react';

import fetchUtil from '../../util/fetchUtil';

import { Store } from '../../state/store';
import { goToUrl } from '../../shared/links';

import Button from '../Button';
import ProjectSearch from '../ProjectSearch';
import OptionSelector from '../OptionSelector';
import { TextInput, TextArea } from '../Input';

import { H4 } from '../styles/typography';
import { colors } from '../styles/colors';

import { ProjectLabel, ProjectName } from '../admin/resources/ResourceStyles';

const Container = styled.div``;
const ButtonContainer = styled.div`
  > * {
    margin-right: 10px;
  }
`;

const StyledTextArea = styled(TextArea)`
  width: 100%;
`;

const Field = styled.div`
  margin-bottom: 15px;
`;

const Label = styled(H4)`
  margin-bottom: 5px;
`;

const Sublabel = styled.span`
  color: ${colors['light-grey-30']};
`;

const TaskDialog = (props) => {
  const { task, handleHideDialog, onAfterSave } = props;

  const {
    state: { user, team },
  } = useContext(Store);
  const options = team.map((teammate) => ({
    ...teammate,
    name: teammate.fullName,
    id: teammate.userID,
  }));

  const originalTask = {
    text: task.data.text,
    userID: task.userID,
    projectID: task.project && task.project.projectID,
    dueDate: task.data.dueDate,
    notes: task.data.notes,
  };

  const [taskName, setTaskName] = useState(task.data.text || '');
  const [selectedMember, setSelectedMember] = useState(
    options.find((u) => u.userID === task.userID)
  );
  const [selectedProject, setSelectedProject] = useState(task.project || {});
  const [taskDueDate, setTaskDueDate] = useState(task.data.dueDate || '');
  const [taskNotes, setTaskNotes] = useState(task.data.notes || '');

  const handleCreateTask = () => {
    const targetUserID = selectedMember.userID;
    fetchUtil
      .post('/tasks/save', {
        text: taskName,
        targetUserID,
        projectID: selectedProject.projectID,
        notes: taskNotes,
        dueDate: taskDueDate,
      })
      .then((res) =>
        fetchUtil.post('/notifications/createNotification', {
          action: 'projectTaskAssignment',
          userID: targetUserID,
          data: {
            creatorUserID: user.userID,
            projectID: selectedProject.projectID,
            taskID: res.taskID,
          },
        })
      )
      .then(() => {
        onAfterSave();
        handleHideDialog();
      });
  };

  const handleSaveTask = () => {
    const targetUserID = selectedMember.userID;
    fetchUtil
      .post('/tasks/save', {
        taskID: task.taskID,
        text: taskName,
        targetUserID,
        projectID: selectedProject.projectID,
        notes: taskNotes,
        dueDate: taskDueDate,
      })
      .then(() => {
        onAfterSave();
        handleHideDialog();
      });
  };

  const handleDeleteTask = () => {
    fetchUtil
      .post('/tasks/adminDelete', {
        taskID: task.taskID,
      })
      .then(() => {
        onAfterSave();
        handleHideDialog();
      });
  };

  const handleItemSelect = (item) => {
    setSelectedProject(item);
  };

  const inputsValid = {
    name: !!taskName,
    member: !!selectedMember && !!selectedMember.userID,
    project: !!selectedProject && !!selectedProject.projectID,
  };

  const allInputsValid = Object.values(inputsValid).reduce(
    (previous, current) => current && previous,
    true
  );

  const newTask = {
    text: taskName,
    userID: selectedMember && selectedMember.userID,
    projectID: selectedProject.projectID,
    dueDate: taskDueDate,
    notes: taskNotes,
  };

  const hasUpdates = Object.keys(originalTask).reduce((previous, current) => {
    if (previous) return true;
    if (originalTask[current] === newTask[current]) return false;
    return true;
  }, false);

  return (
    <Container>
      <Field>
        <Label>Teammate</Label>
        <OptionSelector
          options={options}
          selectedOption={options.find(
            (option) =>
              option.userID === (selectedMember && selectedMember.userID)
          )}
          onSelectOption={(member) => setSelectedMember(member)}
          hasAutocomplete={true}
          autocompletePlaceholder="Filter teammates..."
          buttonClasses="thin box basic"
          saveSelection={false}
          placeholder="Add"
        />
      </Field>
      <Field>
        <Label>Project</Label>
        {!selectedProject.projectID && (
          <ProjectSearch
            onItemSelect={handleItemSelect}
            placeholder="Add project..."
          />
        )}
        {selectedProject.projectID && (
          <ProjectLabel key={selectedProject.projectID}>
            <ProjectName
              onClick={() =>
                goToUrl({
                  projectID: selectedProject.projectID,
                  view: 'projectContents',
                  newWindow: true,
                })
              }
            >
              {selectedProject.name}
            </ProjectName>
            <FontAwesomeIcon
              icon={['fas', 'times']}
              onClick={() => setSelectedProject({})}
            />
          </ProjectLabel>
        )}
      </Field>
      <Field>
        <Label>Task</Label>
        <TextInput
          value={taskName}
          onChange={(e) => setTaskName(e.target.value)}
          placeholder="Task name"
        />
      </Field>
      <Field>
        <Label>
          Due date <Sublabel>(optional)</Sublabel>
        </Label>
        <TextInput
          type="date"
          value={taskDueDate}
          onChange={(e) => setTaskDueDate(e.target.value)}
          placeholder="Task name"
        />
      </Field>
      <Field>
        <Label>
          Notes<Sublabel>(optional)</Sublabel>
        </Label>
        <StyledTextArea
          value={taskNotes}
          onChange={(e) => setTaskNotes(e.target.value)}
          placeholder="Additional information about the task..."
        />
      </Field>
      {!task.taskID && (
        <Button
          className="box"
          enable={allInputsValid}
          onClick={handleCreateTask}
        >
          Create
        </Button>
      )}
      {task.taskID && (
        <ButtonContainer>
          <Button className="box" onClick={handleSaveTask} enable={hasUpdates}>
            Save
          </Button>
          <Button className="box red" onClick={handleDeleteTask}>
            Delete
          </Button>
        </ButtonContainer>
      )}
    </Container>
  );
};

TaskDialog.propTypes = {
  handleHideDialog: PropTypes.func.isRequired,
  onAfterSave: PropTypes.func,
  task: PropTypes.shape({
    data: PropTypes.shape({}),
    taskID: PropTypes.number,
  }),
};

TaskDialog.defaultProps = {
  onAfterSave: () => {},
  task: {
    data: {},
    taskID: null,
  },
};

export default TaskDialog;
