import styled from 'styled-components/macro';

import React, { useState, useRef, useEffect, Fragment } from 'react';

import Debouncer from '../../../app/utilities/debouncer';

// import useClickOutside from '../../../app/hooks/useClickOutside';
import ContentEditable from '../../ContentEditable';
import TextPath from './TextPath';

import { colors } from '../../styles/colors';
import { TextData } from '../types/TextElementProps';
import { TextElementProps } from '../types/ElementProps';
import { ToolOptionProps } from '../tools';

const StyledContentEditable = styled(ContentEditable)`
  font-family: 'Tannerscript';

  caret-color: black;

  :focus {
    outline: none;
    border: 1px solid ${colors.blue};
    border-radius: 5px;
  }
`;

const debouncer = new Debouncer(null, 500);

type Props = {
  element: TextElementProps;
  selectedTool: ToolOptionProps;
  isSelected: boolean;
  handleAddErrors: (errors: string[]) => void;
  x: number;
  y: number;
  style?: React.CSSProperties;
  className?: string;
};

/**
 * Parses the kit param and gets the appropriate kit information
 */
const Text: React.FC<Props> = (props: Props) => {
  const { element, selectedTool, isSelected, handleAddErrors } = props;

  const [newText, setNewText] = useState(element.text);
  const [textData, setTextData] = useState<TextData>();
  const [isLoading, setIsLoading] = useState(true);

  const containerRef = useRef<SVGGElement>(null);
  const contentEditableDimensions = useRef<
    { width: number; height: number } | undefined
  >();
  const contentEditableRef = useRef<HTMLDivElement>();

  // const handleClickOutside = () => {};

  // useClickOutside(containerRef, handleClickOutside);

  const handleType = (e: any) => {
    setNewText(e.target.value);
  };

  useEffect(() => {
    const { text, errors } = element.renderText();
    if (errors.length) handleAddErrors(errors);
    setTextData(text);
    // eslint-disable-next-line
  }, [
    element.text,
    element.currentProps.style.color,
    element.currentProps.style.fontSize,
    element.currentProps.style.fontWeight,
    element.currentProps.style.lineHeight,
  ]);

  /**
   * Get the dimensions of the content editable div, use
   * those dimensions for the dummy div
   *
   */
  const setDimensions = () => {
    if (contentEditableRef.current) {
      contentEditableDimensions.current = {
        width: contentEditableRef.current?.offsetWidth,
        height: contentEditableRef.current?.offsetHeight,
      };
    }
  };

  useEffect(() => {
    // Debounce text input
    debouncer.setValue(newText, () => {
      element.text = newText;
    });

    // Refresh dimensions
    setDimensions();
    // eslint-disable-next-line
  }, [newText]);

  // Initialize dimensions
  // On first load get dimensions of content editable div
  useEffect(() => {
    setDimensions();
    setIsLoading(false);
  }, []);

  // Give focus to the content editable div whenever
  // it's selected and the text tool is used
  useEffect(() => {
    if (isSelected && selectedTool === 'text') {
      if (contentEditableRef.current) contentEditableRef.current.focus();
    }
  }, [isSelected, selectedTool]);

  const elementProps = {
    x: props.x,
    y: props.y,
    style: props.style,
    className: props.className,
  };

  return (
    <g ref={containerRef}>
      {/* Animation text paths */}
      {textData && (selectedTool !== 'text' || !isSelected) && (
        <g>
          {textData.characters.map((character, i) => {
            if (character.paths?.length) {
              return (
                <g
                  key={i}
                  transform={`translate(${character.x} ${character.y}) scale(${character.scale.x} ${character.scale.y})`}
                >
                  {character.paths.map((path, j) => {
                    const clipPathId = `${element.id}-clip-${i}-${j}`;
                    return (
                      <Fragment key={clipPathId}>
                        {character.clipPaths && character.clipPaths[j] && (
                          <clipPath key={`clip-${i}-${j}`} id={clipPathId}>
                            <path d={character.clipPaths[j].d} />
                          </clipPath>
                        )}
                        <TextPath
                          key={`text-${i}-${j}`}
                          path={path}
                          progress={element.currentProps.progress}
                          clipPath={clipPathId}
                        />
                      </Fragment>
                    );
                  })}
                </g>
              );
            }

            return null;
          })}
        </g>
      )}

      {/* Dummy container that's used for sizing the <g> container */}
      <foreignObject
        {...elementProps}
        y={containerRef.current?.getBBox().y}
        width={contentEditableDimensions.current?.width}
        height={contentEditableDimensions.current?.height}
      >
        <div
          dangerouslySetInnerHTML={{ __html: newText }}
          style={{
            whiteSpace: 'pre',
            fontFamily: 'Tannerscript',
            opacity: 0,
            display: 'inline-block',

            color: element.currentProps.style.color,
            fontSize: element.currentProps.style.fontSize,
            fontWeight: element.currentProps.style.fontWeight,
            lineHeight: element.currentProps.style.lineHeight,
          }}
        />
      </foreignObject>

      {/* Content editable div */}
      {(isLoading || (selectedTool === 'text' && isSelected)) && (
        <foreignObject
          {...elementProps}
          height="1"
          width="1"
          style={{
            overflow: 'visible',
          }}
        >
          <StyledContentEditable
            html={newText}
            domRef={contentEditableRef}
            style={{
              whiteSpace: 'pre',
              display: 'inline-block',
              opacity: isLoading ? 0 : 1,

              color: element.currentProps.style.color,
              fontSize: element.currentProps.style.fontSize,
              fontWeight: element.currentProps.style.fontWeight,
              lineHeight: element.currentProps.style.lineHeight,
            }}
            onChange={handleType}
          />
        </foreignObject>
      )}
    </g>
  );
};

export default Text;
