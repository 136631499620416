import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { TextInput } from './Input';
import OptionSelector from './OptionSelector';
import Button from './Button';
import { colors } from './styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const height = 40;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${colors['light-grey-20']};
`;

const StyledInput = styled(TextInput)`
  height: ${height}px;
  border-radius: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
`;

const StyledOptionSelector = styled(OptionSelector)``;

const StyledButton = styled(Button)`
  border-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;

  height: ${height}px;
  padding: 0.5em;
`;

/**
 * Component that has a user input field as well as a list of dropdown items to autofill with
 */
function InputOptionSelector(props) {
  const { options, value, setValue, inputStyle, style, onBlur } = props;

  const handleSelectOption = (option) => {
    setValue(option.name);
  };

  return (
    <Container style={style}>
      <StyledInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        style={inputStyle}
        onBlur={onBlur}
      />
      <StyledOptionSelector
        options={options}
        onSelectOption={handleSelectOption}
      >
        <StyledButton backgroundColor={colors['light-grey-40']}>
          <Icon icon={['fas', 'chevron-down']} />
        </StyledButton>
      </StyledOptionSelector>
    </Container>
  );
}

InputOptionSelector.propTypes = {
  options: PropTypes.array,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  inputStyle: PropTypes.shape({}),
  style: PropTypes.shape({}),
  onBlur: PropTypes.func,
};

InputOptionSelector.defaultProps = {
  options: [],
  inputStyle: {},
  style: {},
  onBlur: null,
};

export default InputOptionSelector;
