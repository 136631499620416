import React from 'react';
import { connectHits } from 'react-instantsearch-dom';

import { Box, Flex } from '../../ui/Box';
import WordRow from './WordRow';

export type PronounceHitProps = {
  id: number;
  word: string;
  audio: string;
  phonetics: string;
  createdOn: string;
  createdBy: {
    givenName: string;
    familyName: string;
    userID: number;
  };
};

interface Props {
  hits: PronounceHitProps[];
  handleSelectEntry: (wordID: number) => void;
}

const Hits = (props: Props) => {
  const { hits, handleSelectEntry } = props;

  return (
    <Flex flexDirection="column">
      {hits.map((entry: PronounceHitProps) => (
        <Box key={entry.id}>
          <WordRow
            key={entry.id}
            entry={entry}
            onClick={() => handleSelectEntry(entry.id)}
          />
        </Box>
      ))}
    </Flex>
  );
};

const PronouceHits = connectHits(Hits as any); // NOTE: issue with custom props in Hit component

export default PronouceHits as any;
