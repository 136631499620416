import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Box from '../../Box';
import Message from '../../Message';
import { colors, lighten  } from '../../styles/colors';
import { P, H4  } from '../../styles/typography';
import ErrorProps from '../props/SceneErrorProps';

const Container = styled(Box)`
  color: ${lighten(colors.night, 45)};

  p,
  li {
    color: ${lighten(colors.night, 45)};
  }
`;

const ErrorMessage = styled(Message)`
  width: 100%;
`;

/**
 * A basic note editor for adding notes about a scene
 */
const ErrorsPanel = (props) => {
  const { errors } = props;

  return (
    <Container>
      {errors.map((error) => (
        <Box key={error.id} style={{ padding: '10px 5px 0 5px' }}>
          <ErrorMessage backgroundColor={lighten(colors.red, 30)}>
            <Box>
              <H4
                style={{
                  color: colors['dark-red-20'],
                  textTransform: 'capitalize',
                  marginBottom: 5,
                }}
              >
                {error.type}
              </H4>
              <P style={{ color: colors['dark-red-20'], opacity: 0.8 }}>
                {error.message}
              </P>
            </Box>
          </ErrorMessage>
        </Box>
      ))}
    </Container>
  );
};

const { arrayOf } = PropTypes;

ErrorsPanel.propTypes = {
  errors: arrayOf(ErrorProps),
};

ErrorsPanel.defaultProps = {
  errors: [],
};

export default ErrorsPanel;
