import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { image as imageFileTypes } from '../../shared/utilities/fileTypes';
import OptionSelector from '../OptionSelector';
import Button from '../Button';
import { Flex } from '../Box';

import { downloadZipFile } from '../../app/fileManager';
import sortOptions from '../../utilities/sortFiles';

import { colors } from '../styles/colors';
import { getHash } from '../../shared/links';

import {
  downloadFile,
  deleteFile,
  sort,
  fileInfo,
  exportComments,
} from './FileRowOptions';

import {
  Container,
  Files,
  FilesContainer,
  FileTypeContainer,
  FileRow,
  CommentIcon,
  LeftContainer,
  RightContainer,
  Name,
  StyledUploadBox,
  MoreButton,
} from './LayersPanelStyles';

import { H4 } from '../styles/typography';

const viewableTypes = ['png', 'jpeg', 'jpg', 'gif', 'svg'];

function ImageFiles(props) {
  const {
    files,
    activeFile,
    activeProject,
    fileHasComments,
    setSelectedDelete,
    handleRowClick,
    onProgress,
    onDownloadError,
  } = props;

  const [currentSort, setCurrentSort] = useState(
    sortOptions.find((o) => o.id === 'number').id
  );
  const [reverseSort, setReverseSort] = useState(false);

  const handleDownloadAllImages = () => {
    downloadZipFile(files, onProgress);
  };

  const handleSelectSort = (newSort) => {
    const currentSortOption = sortOptions.find((o) => o.id === currentSort);
    if (currentSortOption.id === newSort && currentSortOption.reverse) {
      setReverseSort(!reverseSort);
    } else {
      setReverseSort(false);
      setCurrentSort(newSort);
    }
  };

  const currentSortOption = sortOptions.find((o) => o.id === currentSort);

  const comments = activeProject.comments.filter((c) =>
    files.some((file) => file.fileID === c.fileID)
  );

  const imageFiles = files
    .sort(
      reverseSort && currentSortOption.reverse
        ? currentSortOption.reverse
        : currentSortOption.sort
    )
    .filter((file) => viewableTypes.find((type) => type === file.type))
    .map((file) => {
      const commentStatus = fileHasComments(file);
      return (
        <FileRow
          key={file.fileID}
          darkMode
          onClick={() =>
            handleRowClick(
              getHash({
                projectID: activeProject.projectID,
                fileID: file.fileID,
              })
            )
          }
          isActive={activeFile.fileID === file.fileID}
        >
          <LeftContainer>
            <Name darkMode>{file.name}</Name>
          </LeftContainer>
          <RightContainer>
            {!!commentStatus && (
              <CommentIcon status={commentStatus}>
                <FontAwesomeIcon
                  style={{ width: 'unset' }}
                  icon={[
                    'fad',
                    commentStatus === 'unresolved'
                      ? 'comment-exclamation'
                      : 'comment-check',
                  ]}
                />
              </CommentIcon>
            )}
            <OptionSelector
              onClick={(e) => e.stopPropagation()}
              options={[
                fileInfo(file),
                downloadFile(file, onProgress, onDownloadError),
                deleteFile(file, setSelectedDelete),
              ]}
            >
              <MoreButton icon transparent color={colors['light-grey-20']}>
                <FontAwesomeIcon
                  style={{ width: 'unset' }}
                  icon={['fas', 'ellipsis-v']}
                />
              </MoreButton>
            </OptionSelector>
          </RightContainer>
        </FileRow>
      );
    });

  const options = [];
  if (files.length) {
    options.push({
      id: 'download-all',
      name: 'Download Images',
      icon: 'file-download',
      onClick: handleDownloadAllImages,
    });

    if (comments.length) {
      options.push(exportComments(activeProject.projectID, 'image'));
    }

    options.push(
      sort(
        sortOptions,
        currentSort,
        handleSelectSort,
        currentSortOption.reverse && reverseSort
      )
    );
  }

  return (
    <Container>
      <FileTypeContainer darkMode>
        <Flex style={{ justifyContent: 'space-between' }}>
          <H4 color="white">Images</H4>
          {!!options.length && (
            <OptionSelector
              onClick={(e) => e.stopPropagation()}
              options={options}
            >
              <Button
                icon
                transparent
                style={{ fontSize: 14 }}
                color={colors['light-grey-20']}
              >
                <FontAwesomeIcon icon={['fas', 'ellipsis-v']} />
              </Button>
            </OptionSelector>
          )}
        </Flex>
        {!!files.length && (
          <FilesContainer darkMode>
            <Files>{imageFiles}</Files>
          </FilesContainer>
        )}
        <StyledUploadBox types={imageFileTypes} allowMultiple={true} darkMode />
      </FileTypeContainer>
    </Container>
  );
}

ImageFiles.propTypes = {
  files: PropTypes.array,
  activeFile: PropTypes.object,
  activeProject: PropTypes.object,
  fileHasComments: PropTypes.func.isRequired,
  setSelectedDelete: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  onProgress: PropTypes.func.isRequired,
  onDownloadError: PropTypes.func.isRequired,
};

ImageFiles.defaultProps = {
  files: [],
  activeFile: {},
  activeProject: {},
};

export default ImageFiles;
