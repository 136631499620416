import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import '@simonwep/pickr/dist/themes/classic.min.css'; // 'classic' theme
import Pickr from '@simonwep/pickr/dist/pickr.es5.min';

import Box from './Box';

import { colors } from './styles/colors';

function ColorPicker(props) {
  const {
    onColorSave,
    onColorSelect,
    initialColor,
    swatches,
    options,
    saveOnSelect,
  } = props;
  const [currentColor, setCurrentColor] = useState(initialColor);

  const colorPickerContainerRef = useRef();
  const pickrRef = useRef();

  const recentColors = localStorage.getItem('recentColors')
    ? localStorage.getItem('recentColors').split(',')
    : [];

  const defaultSwatches = swatches || [
    '#2970C2',
    'rgba(233, 30, 99, 0.95)',
    'rgba(156, 39, 176, 0.9)',
    'rgba(103, 58, 183, 0.85)',
    'rgba(63, 81, 181, 0.8)',
    'rgba(33, 150, 243, 0.75)',
    'rgba(3, 169, 244, 0.7)',
    'rgba(0, 188, 212, 0.7)',
    'rgba(0, 150, 136, 0.75)',
    'rgba(76, 175, 80, 0.8)',
    'rgba(139, 195, 74, 0.85)',
    'rgba(205, 220, 57, 0.9)',
    'rgba(255, 235, 59, 0.95)',
    'rgba(255, 193, 7, 1)',
  ];

  useEffect(() => {
    // Simple example, see optional options for more configuration.
    pickrRef.current = Pickr.create({
      el: colorPickerContainerRef.current,
      theme: 'classic',
      default: initialColor,

      swatches: recentColors.length ? recentColors : defaultSwatches,

      components: {
        // Main components
        preview: true,
        opacity: true,
        hue: true,

        // Input / output Options
        interaction: {
          hex: true,
          rgba: true,
          hsla: true,
          hsva: true,
          cmyk: true,
          input: true,
          clear: true,
          save: !saveOnSelect,
          ...options.interactions,
        },
      },

      ...options,
    });

    pickrRef.current.on('save', (color) => {
      onColorSave(color);
      setCurrentColor(color.toHEXA().toString());
    });

    pickrRef.current.on('change', (color) => {
      onColorSelect(color);
      pickrRef.current.applyColor(currentColor);
      if (saveOnSelect) setCurrentColor(color.toHEXA().toString());
    });
    // eslint-disable-next-line
  }, []);

  const onHide = useCallback(() => {
    const isNew = !recentColors.includes(currentColor);
    if (isNew) {
      if (!recentColors.length) {
        // remove default swatches from picker
        defaultSwatches.forEach(() => {
          pickrRef.current.removeSwatch(0);
        });
      }
      recentColors.push(currentColor);
      pickrRef.current.addSwatch(currentColor);
      if (recentColors.length > 9) {
        recentColors.shift();
        pickrRef.current.removeSwatch(0);
      }
      localStorage.setItem('recentColors', recentColors);
    }
  }, [currentColor, defaultSwatches, recentColors]);

  useEffect(() => {
    pickrRef.current.on('hide', onHide);
    return () => pickrRef.current.off('hide', onHide);
  }, [currentColor, onHide]);

  return (
    <Container>
      <Box
        style={{
          overflow: 'hidden',
          borderRadius: 5,
          border: '1px solid grey',
        }}
      >
        <Box ref={colorPickerContainerRef} />
      </Box>
      <CurrentColor>{currentColor}</CurrentColor>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const CurrentColor = styled.p`
  margin: 0;
  margin-left: 10px;
  color: ${colors['light-grey-20']};
`;

const { func, string, shape, bool, arrayOf } = PropTypes;

ColorPicker.propTypes = {
  onColorSave: func,
  onColorSelect: func,
  initialColor: string,
  swatches: arrayOf(string),
  options: shape({
    interactions: shape({}),
  }),
  saveOnSelect: bool,
};

ColorPicker.defaultProps = {
  onColorSave: () => {},
  onColorSelect: () => {},
  initialColor: '#000000',
  options: {},
  saveOnSelect: false,
};

export default ColorPicker;
