import React from 'react';
import PropTypes from 'prop-types';
import ChildrenProps from '../../../props/general';

const { string, shape } = PropTypes;

const SvgElement = ({ node }) => {
  // Transform style attribute to object if it exists
  return (
    <node.type {...node.attributes}>
      {node.value}
      {node.children &&
        node.children.map((c) => <SvgElement key={c.id} node={c} />)}
    </node.type>
  );
};

SvgElement.propTypes = {
  node: shape({
    attributes: shape({}),
    value: string,
    children: ChildrenProps,
  }),
};

export default SvgElement;
