import React, { ReactNode, useState } from 'react';
import moment from 'moment';
import { Box } from 'rebass/styled-components';

// import useDebounce from '../../app/hooks/useDebounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import Input from '../../ui/RebassInput';
import { post } from '../../util/fetchUtil';
import CustomButton from '../RebassButton';
import UploadBox from '../UploadBox';
import Text from '../Text';
import useAudioRecorder from '../../app/hooks/useAudioRecorder';
import { uploadFile } from '../../app/fileManager';
import uuid from '../../app/utilities/uuid';
import Message from '../Message';
import PronounceWordProps from './PronounceWordProps';
import { Flex } from '../Box';
import Avatar from '../Avatar';

type Props = {
  entry?: PronounceWordProps;
  canEdit?: boolean;
  handleHideDialog?: () => void;
};

const Label = ({ children }: { children: ReactNode }) => (
  <Box as="h4" mb="5px" opacity="0.75">
    {children}
  </Box>
);

export default function AddWordDialog(props: Props) {
  const { entry, canEdit } = props;

  const [newWord, setNewWord] = useState('');
  const [newPhonetics, setNewPhonetics] = useState('');
  const [audioUrl, setAudioUrl] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState('');

  // const debouncedWord = useDebounce(newWord, 1000);

  const audioRecorder = useAudioRecorder();
  const { isRecording } = audioRecorder;

  const handleAddAudio = (res: { url: string }[]) => {
    const [file] = res;
    setAudioUrl(file.url);
  };

  const hide = () => {
    if (props.handleHideDialog) props.handleHideDialog();
  };

  const handleAddWord = async () => {
    if (entry) {
      await post('/pronounce/updateWord', {
        wordID: entry.wordID,
        newWord,
        newAudio: audioUrl,
        newPhonetics,
      });
    } else {
      const res = await post('/pronounce/addWord', {
        word: newWord,
        audio: audioUrl,
        phonetics: newPhonetics,
      });

      if (res.error) {
        return setError(res.error);
      }
    }

    hide();
  };

  const handleInitRecorder = async () => {
    setAudioUrl('');

    await audioRecorder.start();
  };

  const handleStopRecorder = async () => {
    const { blob, type } = audioRecorder.stop();

    const res = await uploadFile(blob, `${uuid()}.${type}`);
    setAudioUrl(res.url);
  };

  const handleDelete = async () => {
    if (entry) {
      await post('/pronounce/deleteWord', {
        wordID: entry.wordID,
      });

      hide();
    }
  };

  const handleEdit = () => {
    if (entry) {
      setIsEditing(true);
      setNewWord(entry.word);
      setAudioUrl(entry.data.audio);
      setNewPhonetics(entry.data.phonetics);
    }
  };

  const handleClearAudio = () => {
    setAudioUrl('');
  };

  return (
    <Box width={['100%']}>
      {entry && !isEditing && (
        <Box>
          <Box>
            <Box>
              <Label>Word</Label>
              <Text as="h2">{entry.word}</Text>
            </Box>
            {entry.data.phonetics && (
              <Box mt={15}>
                <Label>Phonetics</Label>
                <Text as="h3">{entry.data.phonetics}</Text>
              </Box>
            )}
            <Flex alignItems="center" mt={15}>
              <Box mr={10}>
                <Avatar size={25} src={entry.createdBy.avatar} />
              </Box>
              <Box>
                <Text>
                  {entry.createdBy.givenName} {entry.createdBy.familyName}
                </Text>
                <Text fontSize="12px" opacity="0.7">
                  {moment(entry.createdOn).format('MM-DD-YYYY')}
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box mt="20px">
            <audio src={entry.data.audio} controls style={{ width: '100%' }} />
          </Box>
          {canEdit && (
            <Box mt="10px">
              <CustomButton
                width="100%"
                variant="outline"
                color="dark-6"
                onClick={handleEdit}
                sx={{ mb: '10px' }}
              >
                Edit
              </CustomButton>
              <CustomButton
                width="100%"
                variant="outline"
                color="negative"
                onClick={handleDelete}
              >
                Delete
              </CustomButton>
            </Box>
          )}
        </Box>
      )}
      {(isEditing || !entry) && (
        <Box>
          <Box mb="20px">
            <Label>Word</Label>
            <Input
              value={newWord}
              onChange={(e) => setNewWord(e.currentTarget.value)}
              placeholder="E.g. Sarlacc"
            />
          </Box>
          <Box mb="30px">
            <Label>Phonetics</Label>
            <Input
              value={newPhonetics}
              onChange={(e) => setNewPhonetics(e.currentTarget.value)}
              placeholder="E.g. SAR-lack"
            />
            <ul>
              <li>Syllables are separated by dashes</li>
              <li>Stressed syllables are capitalized</li>
            </ul>
          </Box>
          <Box mb="20px">
            <Label>Audio</Label>
            {audioUrl && (
              <>
                <Label>URL</Label>
                <Box
                  p={10}
                  sx={{
                    borderRadius: 3,
                    bg: 'light-2',
                    color: 'dark-6',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    maxWidth: '300px',
                  }}
                >
                  {audioUrl}
                </Box>
              </>
            )}
            {!audioUrl && !isRecording && (
              <>
                <CustomButton
                  onClick={handleInitRecorder}
                  variant="outline"
                  color="dark-3"
                  sx={{ width: '100%' }}
                  startIcon={
                    <FontAwesomeIcon color="red" icon={['fad', 'circle']} />
                  }
                >
                  Record
                </CustomButton>
                <Box
                  sx={{ textAlign: 'center' }}
                  my="20px"
                  color="dark-6"
                  width="100%"
                >
                  OR
                </Box>
                <UploadBox
                  types={['mp3', 'wav', 'ogg']}
                  onAfterUpload={handleAddAudio}
                  isProjectUpload={false}
                />
              </>
            )}

            {isRecording && (
              <>
                <Message
                  negative
                  style={{
                    width: '100%',
                    marginBottom: '10px',
                  }}
                >
                  <Box sx={{ textAlign: 'center', width: '100%' }}>
                    Recording...
                  </Box>
                </Message>
                <CustomButton
                  variant="outline"
                  color="negative"
                  sx={{ width: '100%' }}
                  onClick={handleStopRecorder}
                  startIcon={<FontAwesomeIcon icon={['fas', 'stop']} />}
                >
                  Stop
                </CustomButton>
              </>
            )}

            {audioUrl && (
              <Box>
                <Box mt="20px">
                  <audio src={audioUrl} controls style={{ width: '100%' }} />
                </Box>
                <Box mt="10px">
                  <CustomButton
                    width="100%"
                    variant="outline"
                    color="dark-6"
                    onClick={handleClearAudio}
                  >
                    Clear recording
                  </CustomButton>
                </Box>
              </Box>
            )}
          </Box>

          <CustomButton
            width="100%"
            onClick={handleAddWord}
            enabled={!!newWord && !!audioUrl}
          >
            Save pronunciation
          </CustomButton>
        </Box>
      )}
      <AnimatePresence>
        {error && (
          <motion.div
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ marginTop: 10 }}
          >
            <Message negative style={{ width: '100%' }}>
              <div style={{ textAlign: 'center' }}>{error}</div>
            </Message>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
}
