import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';

import { AnimatePresence } from 'framer-motion';

import { post } from '../../util/fetchUtil';

import { Store } from '../../state/store';
import { fetchActiveProject } from '../../state/projects/actions';

import { HeaderSection, SectionTitle } from '../views/ProjectContentsStyles';

import {
  Container,
  RightContainer,
  NameContainer,
  RemoveLink,
  LinkContainer,
  LinkName,
  LinkUrl,
  StyledTextInput,
  InputContainer,
  AddLinkButton,
  LinkImage,
  LinkIcon,
  StyledMessage,
} from './LinkAttachmentSectionStyles';

import Button from '../RebassButton';
import Icon from '../Icon';

function LinkAttachmentSection(props) {
  const {
    activeProject: { data, projectID },
  } = props;

  const { dispatch } = useContext(Store);

  const [addingLink, setAddingLink] = useState(false);
  const [linkUrl, setNewLink] = useState('');
  const [newName, setNewName] = useState('');

  const links = (data && data.links) || [];

  const cancel = () => {
    setAddingLink(false);
    setNewLink('');
    setNewName('');
  };

  const openLink = (link) => {
    window.open(link.url, '_blank');
  };

  const removeLink = (e, link) => {
    e.stopPropagation();

    post('/project/removeLink', {
      projectID,
      url: link.url,
    }).then(() => {
      fetchActiveProject(projectID, dispatch);
    });
  };

  // FIXME: CHECK IF LINK ALREADY EXISTS
  const saveNewLink = () => {
    post('/project/addLink', {
      projectID,
      link: {
        type: 'script',
        url: linkUrl,
        name: newName,
      },
    }).then(() => {
      fetchActiveProject(projectID, dispatch);
    });

    setAddingLink(false);
  };

  const linkImages = [
    {
      regex: /docs.google.com/g,
      src: '/assets/images/icons/google-docs.png',
    },
    {
      regex: /trello.com/g,
      src: '/assets/images/icons/trello.png',
    },
  ];

  // Determine if this link already exists
  const existingLink = links.find((link) => link.url === linkUrl);

  const newLinkValid = newName && linkUrl && !existingLink;

  const linkComponents = links.map((link) => {
    const image = linkImages.find((linkImage) =>
      link.url.match(linkImage.regex)
    );

    return (
      <LinkContainer onClick={() => openLink(link)} key={link.url}>
        {!image && <LinkIcon icon={['fad', 'link']} />}
        {image && (
          <LinkImage>
            <img alt="Link" src={image.src} />
          </LinkImage>
        )}
        <RightContainer>
          <NameContainer>
            {link.name && <LinkName>{link.name}</LinkName>}
            <LinkUrl>{link.url}</LinkUrl>
          </NameContainer>
          <RemoveLink
            className="icon transparent"
            onClick={(e) => removeLink(e, link)}
          >
            <FontAwesomeIcon icon={['fas', 'times']} />
          </RemoveLink>
        </RightContainer>
      </LinkContainer>
    );
  });

  return (
    <Container>
      <HeaderSection>
        <SectionTitle>
          <Icon icon="link" />
          Links
        </SectionTitle>
      </HeaderSection>
      {links && linkComponents}
      <AnimatePresence initial={false}>
        {!addingLink && (
          <AddLinkButton leftIcon box onClick={() => setAddingLink(true)}>
            <FontAwesomeIcon icon={['fad', 'link']} />
            Add link
          </AddLinkButton>
        )}
        {addingLink && (
          <InputContainer
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{}}
          >
            <StyledTextInput
              type="text"
              onChange={(e) => setNewName(e.target.value)}
              value={newName}
              placeholder="Link name..."
            />
            <StyledTextInput
              type="text"
              onChange={(e) => setNewLink(e.target.value)}
              value={linkUrl}
              placeholder="Paste URL here..."
            />
            <Button enable={newLinkValid} bg="positive" onClick={saveNewLink}>
              <FontAwesomeIcon icon={['fad', 'check']} />
            </Button>
            <Button variant="outline" color="dark-6" onClick={cancel}>
              <FontAwesomeIcon icon={['fas', 'times']} />
            </Button>
          </InputContainer>
        )}
        {existingLink && (
          <StyledMessage negative>
            Link with that URL already exists!
          </StyledMessage>
        )}
      </AnimatePresence>
    </Container>
  );
}

LinkAttachmentSection.propTypes = {
  activeProject: PropTypes.shape({
    data: PropTypes.object,
    projectID: PropTypes.number,
    name: PropTypes.string,
  }),
  linkRegex: PropTypes.object,
  linkImage: PropTypes.string,
};

LinkAttachmentSection.defaultProps = {
  activeProject: {},
  linkRegex: /.*/g,
  linkImage: '',
};

export default LinkAttachmentSection;
