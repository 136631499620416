import styled from 'styled-components/macro';

import React, { ReactNode } from 'react';
import Tippy from '@tippyjs/react';
import { useHistory, useParams } from 'react-router';
import { colors, lighten } from '../styles/colors';
import { RouteItem } from './AdminNavigationStyles';
import { isAllowed } from '../../shared/grants';

export type RouteProps = {
  name: string;
  value: string;
  icon: string;
  id: string;
  component: ReactNode;
};

type Props = {
  grants: string[];
  routes: RouteProps[];
  handleUpdateRoute: (newRoute: string) => void;
};

const selectedMouseStyles = {
  background: lighten(colors.blue, 45),
  color: colors['dark-blue-10'],
};

const selectedStyles = {
  background: lighten(colors.blue, 45),
  color: colors['dark-blue-10'],

  ':hover': selectedMouseStyles,
  ':active': selectedMouseStyles,
  ':focus': selectedMouseStyles,
};

const disabledMouseStyles = {
  background: 'none',
  color: colors.grey,
};

const disabledStyles = {
  opacity: 0.35,

  ':hover': disabledMouseStyles,
  ':active': disabledMouseStyles,
  ':focus': disabledMouseStyles,
};

const AdminNavigation: React.FC<Props> = (props: Props) => {
  const { routes, grants } = props;

  const history = useHistory();
  const params = useParams<{
    routeId: string;
  }>();

  const activeRoute = routes.find((r) => r.id === params.routeId);

  const routeComponents = routes.map((r) => {
    const isDisabled = !isAllowed(r.value, grants);

    return (
      <Tippy
        key={r.value}
        content={
          isDisabled ? `You don't have permission to view ${r.name}` : false
        }
        disabled={!isDisabled}
      >
        <RouteItem
          onClick={isDisabled ? () => {} : () => history.push(`/admin/${r.id}`)}
          sx={{
            ':hover,:focus': {
              background: lighten(colors.grey, 55),
            },
            ':active': {
              background: lighten(colors.grey, 50),
            },
            color: colors.grey,
            ...(activeRoute?.value === r.value ? selectedStyles : {}),
            ...(isDisabled ? disabledStyles : {}),
          }}
        >
          <i className={r.icon} />
          <span>{r.name}</span>
        </RouteItem>
      </Tippy>
    );
  });

  return <Container>{routeComponents}</Container>;
};

const Container = styled.div`
  flex: 1;
  border-right: 1px solid ${colors['light-grey-50']};
  overflow: auto;
`;

export default AdminNavigation;
