import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Box, { Flex } from '../../Box';
import { Section, Label, Unit, StyledNumberInput } from './PropertyStyles';

import ElementProps from '../props/ElementProps';

const StyledLabel = styled(Label)`
  margin-right: 5px;
`;

/**
 * Shared properties set that displays for all elements that have transforms
 */
function Transform(props) {
  const { selectedElement, handleUpdateElement, addKeyframe } = props;

  const handleChange = (key, newValue) => {
    selectedElement.update('currentProps', {
      ...selectedElement.currentProps,
      [key]: newValue,
    });

    addKeyframe(key, newValue);
    handleUpdateElement([selectedElement.id]);
  };

  let { width, scaleX, height, scaleY } = selectedElement.currentProps;

  width = parseFloat(width);
  scaleX = parseFloat(scaleX);
  height = parseFloat(height);
  scaleY = parseFloat(scaleY);

  return (
    <Box>
      <Flex>
        <Section>
          <StyledLabel>X</StyledLabel>
          <StyledNumberInput
            value={selectedElement.currentProps.translateX}
            onChange={(newVal) => handleChange('translateX', newVal)}
            fixed={0}
            darkMode={true}
          />
          <Unit>px</Unit>
        </Section>
        <Section>
          <StyledLabel>Y</StyledLabel>
          <StyledNumberInput
            value={selectedElement.currentProps.translateY}
            onChange={(newVal) => handleChange('translateY', newVal)}
            fixed={0}
            darkMode={true}
          />
          <Unit>px</Unit>
        </Section>
      </Flex>
      <Flex>
        <Section>
          <StyledLabel>W</StyledLabel>
          <StyledNumberInput
            value={width * scaleX}
            onChange={(newVal) => handleChange('scaleX', newVal / width)}
            fixed={0}
            darkMode={true}
          />
          <Unit>px</Unit>
        </Section>
        <Section>
          <StyledLabel>H</StyledLabel>
          <StyledNumberInput
            value={height * scaleY}
            onChange={(newVal) => handleChange('scaleY', newVal / height)}
            fixed={0}
            darkMode={true}
          />
          <Unit>px</Unit>
        </Section>
      </Flex>
      <Flex>
        <Section>
          <StyledLabel>
            <FontAwesomeIcon icon={['fad', 'sync-alt']} />
          </StyledLabel>
          <StyledNumberInput
            value={selectedElement.currentProps.rotate}
            onChange={(newVal) => handleChange('rotate', newVal)}
            fixed={0}
            darkMode={true}
          />
          <Unit>deg</Unit>
        </Section>
        <Section>
          <StyledLabel>Opacity</StyledLabel>
          <StyledNumberInput
            min={0}
            max={100}
            value={selectedElement.currentProps.opacity * 100}
            onChange={(newVal) => handleChange('opacity', newVal / 100)}
            step={1}
            darkMode={true}
          />
          <Unit>%</Unit>
        </Section>
      </Flex>
    </Box>
  );
}

const { func } = PropTypes;

Transform.propTypes = {
  selectedElement: ElementProps.isRequired,
  handleUpdateElement: func.isRequired,
  addKeyframe: func.isRequired,
};

export default Transform;
