import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import OptionSelector from '../OptionSelector';

import { colors } from '../styles/colors';

function CommentActions(props) {
  const { activeFilter, filters, onSelectSortOption } = props;

  return (
    <Container>
      <OptionSelector
        header="Sort by"
        selectedOption={{ id: activeFilter, name: activeFilter }}
        options={Object.keys(filters).map((key) => ({ id: key, name: key }))}
        onSelectOption={onSelectSortOption}
        buttonClasses="thin transparent"
      ></OptionSelector>
    </Container>
  );
}

const Container = styled.div`
  padding: 10px;

  border-top: 1px solid ${colors['light-grey-50']};
`;

CommentActions.propTypes = {
  activeFilter: PropTypes.string,
  filters: PropTypes.object,
  onSelectSortOption: PropTypes.func,
};

CommentActions.defaultProps = {};

export default CommentActions;
