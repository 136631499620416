import React from 'react';
const Annotations = (props) => {
  const { annotations } = props;

  return annotations.map((annotation) => {
    const Element = annotation.type;

    return <Element key={annotation.id} {...annotation.attributes} />;
  });
};

export default Annotations;
