import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components/macro';

import Snackbar from './Snackbar';
import LoadingProgressBar from './LoadingProgressBar';
import { H4 } from './styles/typography';
import Button from './Button';

import { fade, lighten, colors } from './styles/colors';

const CloseButton = styled(Button)`
  margin-left: 30px;
`;

const DownloadFailedText = styled.h3`
  margin: 0;
  color: white;
`;

const DownloadFailedSubtext = styled.h3`
  margin: 0;
  color: ${fade('white', 30)};

  a {
    color: ${lighten(colors.blue, 30)};
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;

  padding: 15px;
`;

const DownloadProgress = styled.div`
  width: 300px;
  padding: 15px;
`;

const DownloadingText = styled(H4)`
  color: ${fade('white', 65)};
  margin-bottom: 10px;
`;

const Emoji = styled.div`
  margin-right: 10px;
  font-size: 32px;
`;

type Props = {
  isVisible: boolean;
  progress: number;
  hasDownloadError: boolean;
  downloadLink: string;
  handleClose: () => void;
};

export default function DownloadSnackbar(props: Props) {
  const {
    isVisible,
    progress,
    hasDownloadError,
    downloadLink,
    handleClose,
  } = props;

  return (
    <Snackbar isVisible={isVisible} style={{ background: colors.night }}>
      {!hasDownloadError && (
        <DownloadProgress>
          <DownloadingText>Downloading...</DownloadingText>
          <LoadingProgressBar theme="dark" progress={progress} />
        </DownloadProgress>
      )}

      {hasDownloadError && (
        <FlexWrapper>
          <Emoji>
            <span role="img" aria-label="Uh oh">
              😨
            </span>
          </Emoji>
          <div>
            <DownloadFailedText>Download failed</DownloadFailedText>
            <DownloadFailedSubtext>
              Try{' '}
              <a href={downloadLink} rel="noopener noreferrer" target="_blank">
                this link
              </a>{' '}
              instead.
            </DownloadFailedSubtext>
          </div>
          <CloseButton icon onClick={handleClose}>
            <FontAwesomeIcon icon={['fas', 'times']} />
          </CloseButton>
        </FlexWrapper>
      )}
    </Snackbar>
  );
}
