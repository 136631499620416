import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import { motion, AnimatePresence } from 'framer-motion';

import moment from 'moment';

import { H4 } from '../../styles/typography';
import { colors, fade, darken, getColorCombination } from '../../styles/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`;

const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 10px;
`;

const Title = styled(H4)`
  margin-top: 5px;
  color: ${colors['light-grey-30']};
  text-align: center;
  font-size: 14px;

  white-space: nowrap;

  ${(props) =>
    props.color &&
    css`
      color: ${fade(getColorCombination(props.color).color, 25)};
    `}
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${colors['light-grey-20']};
  margin-right: 10px;
  font-size: 30px;

  ${(props) =>
    props.color &&
    css`
      color: ${fade(getColorCombination(props.color).color, 25)};
    `}
`;

const Total = styled.div`
  padding: 15px;
  font-size: 32px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: ${colors['light-grey-50']};

  display: inline-block;
  border-radius: 3px;

  ${(props) =>
    props.color &&
    css`
      background: ${fade(colors[props.color], 75)};
      color: ${darken(colors[props.color], 10)};
    `}
`;

function AnalyticsTotals({ tasks, month }) {
  const filteredTasks = tasks.map((task) => ({
    ...task,
    list: task.list.filter(
      (project) =>
        moment(project.date).format('MM/YY') === month.format('MM/YY')
    ),
  }));

  const totalComponents = filteredTasks.map(
    (task) =>
      !!task.list.length && (
        <motion.div
          key={task.taskID}
          initial={{ width: 0, height: 0, scale: 0, opacity: 0 }}
          animate={{ width: 'auto', height: 'auto', scale: 1, opacity: 1 }}
          exit={{
            width: 0,
            scale: 0,
            opacity: 0,
            height: 0,
            transition: { easing: 'easeOut' },
          }}
        >
          <StatContainer>
            <Total color={task.color}>
              <FlexContainer>
                <Icon color={task.color} icon={['fad', task.icon]} />
                {task.list.length}
              </FlexContainer>
              <Title color={task.color}>{task.taskName}</Title>
            </Total>
          </StatContainer>
        </motion.div>
      )
  );
  return (
    <Container>
      <AnimatePresence>{totalComponents}</AnimatePresence>
    </Container>
  );
}

AnalyticsTotals.propTypes = {
  tasks: PropTypes.array,
  month: PropTypes.shape({}),
};

AnalyticsTotals.defaultProps = {
  tasks: [],
  month: moment(),
};

export default AnalyticsTotals;
