import React, {
  useState,
  useRef,
  useEffect,
  memo,
  MutableRefObject,
} from 'react';

import Rect from './Rect';
import Text from './Text';
import Image from './Image';
import Path from './Path';
import Group from './Group';
import Mask from './Mask';

import useSubjx, { DragProps } from '../../../app/hooks/useSubjx';

import AnimationPathEditor from '../AnimationPathEditor';
import {
  ElementProps,
  ImageElementProps,
  MixedElementProps,
  PathElementProps,
} from '../types/ElementProps';
import ErrorProps from '../types/ErrorProps';
import { ToolOptionProps } from '../tools';

/**
 * Removes props from object that aren't recognized as dom props
 *
 * @param {{ [index: string]: any }} props
 * @returns
 */
const getDomProps = (props: { [index: string]: any }) => {
  const {
    translateX,
    translateY,
    scaleX,
    scaleY,
    skewX,
    skewY,
    ...domProps
  } = props;

  return domProps;
};

/**
 * Determines whether the child component should update on the stage
 *
 * @param {Props} prevProps
 * @param {Props} nextProps
 * @returns
 */
const areEqual = (prevProps: Props, nextProps: Props) => {
  const { isPlaying } = nextProps;
  const isGroup = nextProps.element.type === 'group';

  let shouldMemo = false;

  const cache = nextProps.element.getKeyframeCache(nextProps.currentTime);

  if (
    isPlaying && // Make sure we're currently playing
    !isGroup && // Always update group types because they have children
    !cache.shouldUpdate // Check keyframe cache to see if element needs to render
  ) {
    shouldMemo = true;
  }

  return shouldMemo;
};

type Props = {
  element: MixedElementProps;
  childElements: ElementProps[];
  svgRef: MutableRefObject<SVGElement | null>;
  selectedElements: ElementProps[];
  selectedTool: ToolOptionProps;
  currentTime: number;
  shiftKeyDown: boolean;
  isPlaying: boolean;
  handleUpdateElement: (elementIDs: string[]) => void;
  handleSelectElements?: (elements: ElementProps[]) => void;
  handleAddErrors: (errors: ErrorProps[]) => void;
};

type Drag = {
  disable: () => void;
};

/**
 * A base function for containing an SVG element (Text, Path, etc.) on the stage
 */
const Child: React.FC<Props> = memo((props: Props) => {
  const {
    element,
    childElements,
    svgRef,
    selectedElements,
    selectedTool,
    currentTime,
    shiftKeyDown,
    handleUpdateElement,
    handleSelectElements,
    handleAddErrors,
  } = props;

  const [, render] = useState({});

  const gRef = useRef<SVGGElement>(null);

  const isSelected = selectedElements.find((e) => e.id === element.id);

  // FIXME:
  const drag: DragProps = useSubjx({
    proportions: element.maintainAspect !== shiftKeyDown,
    container: svgRef.current,
  });

  const cleanup = () => {
    drag.disable();
  };

  const handleGetTransform = () => {
    const {
      translateX,
      translateY,
      scaleX,
      scaleY,
      rotate,
    } = element.currentProps;

    // If the element currently has a transform, use that
    let transform = '';
    if (element.currentProps.transform) {
      ({ transform } = element.currentProps);
    } else {
      // NOTE: ORDER MATTERS!
      transform = `
        translate(${translateX} ${translateY})
        rotate(${rotate})
        scale(${scaleX} ${scaleY})
      `;
    }

    return transform;
  };

  const handleClickChild = (e: React.MouseEvent) => {
    if (element.locked) return;

    e.stopPropagation();

    if (selectedTool === 'move' && handleSelectElements) {
      if (shiftKeyDown) {
        handleSelectElements([...selectedElements, element]);
      } else {
        handleSelectElements([element]);
      }
    }
  };

  useEffect(() => {
    if ((!isSelected && drag.isEnabled()) || selectedTool !== 'move') {
      drag.disable();
    } else if (isSelected && !drag.isEnabled()) {
      if (gRef.current && !drag.xDraggable) drag.init(gRef.current); // Init drag if it hasn't yet
      drag.enable();
    }
    // eslint-disable-next-line
  }, [isSelected, selectedTool, gRef.current]);

  useEffect(() => {
    if (isSelected) {
      drag.redraw();
    }
    // eslint-disable-next-line
  }, [childElements.length, element]);

  useEffect(() => {
    // Handle updating element
    const transformStr = drag.transformToString();

    if (transformStr) {
      // Have to pull out transform details from the
      // transform matrix supplied by subjx
      if (drag.transform) element.transform(drag.transform);

      handleUpdateElement([element.id]);
    }
    // eslint-disable-next-line
  }, [drag.transform]); // Fires on drop

  useEffect(() => {
    // UI updates are triggered by timeline scrubbing (not updating element position)
    element.setCurrentProps(currentTime);

    // Have to manually render to reflect new state
    // NOTE: this causes a second render whenever currentTime is updated, may need to optimize
    render({});
    // eslint-disable-next-line
  }, [currentTime]);

  // Run clean up before unmount
  useEffect(() => {
    // Add accessor for retrieving the element's draggable ref
    return () => {
      cleanup();
    };
    // eslint-disable-next-line
  }, []);

  let elementComponent;
  let Element = '';

  switch (element.type) {
    case 'rect':
      elementComponent = (
        <Rect
          element={element}
          gRef={gRef}
          handleGetTransform={handleGetTransform}
        />
      );
      break;

    case 'text':
      elementComponent = (
        <Text
          {...element.props}
          element={element}
          isSelected={isSelected}
          selectedTool={selectedTool}
          handleAddErrors={handleAddErrors}
        />
      );
      break;

    case 'image':
      elementComponent = (
        <Image
          element={element as ImageElementProps}
          handleUpdateElement={handleUpdateElement}
        />
      );
      break;

    case 'path':
      elementComponent = (
        <Path
          svgRef={svgRef}
          element={element as PathElementProps}
          selectedTool={selectedTool}
          isSelected={!!isSelected}
        />
      );
      break;

    case 'group':
      elementComponent = <Group {...props} Child={Child} />;
      break;

    default:
      // Otherwise insert generic element
      Element = element.type;
      elementComponent = <Element {...getDomProps(element.currentProps)} />;
      break;
  }

  const transform = handleGetTransform();

  const maskId = element.maskElements?.length ? `mask-${element.id}` : '';

  return (
    <>
      <g
        ref={gRef}
        onClick={handleClickChild}
        transform={transform}
        id={element.id}
        className={isSelected ? 'element' : ''}
        style={{
          opacity: element.currentProps.opacity,
          // Remove pointer events if the item's locked (inherit from parent if it's a child group)
          pointerEvents: element.locked ? 'none' : 'inherit',
        }}
      >
        {maskId && element.maskElements && (
          <Mask
            {...props}
            maskElements={element.maskElements}
            maskId={maskId}
            Child={Child}
          />
        )}

        <g mask={maskId && `url(#${maskId})`}>{elementComponent}</g>

        {selectedTool === 'animation-path' && isSelected && (
          <AnimationPathEditor
            element={element}
            svgRef={svgRef}
            currentTime={currentTime}
            handleUpdateElement={handleUpdateElement}
          />
        )}
      </g>
    </>
  );
}, areEqual);

Child.defaultProps = {
  shiftKeyDown: false,
  handleAddErrors() {
    // Default
  },
};

export default Child;
