import React from 'react';
import { connectRefinementList } from 'react-instantsearch-dom';
import Checkbox from './Checkbox';
import { Flex, Box } from './Box';
import Input from './RebassInput';
import Text from './Text';

type ItemProps = {
  count: number;
  isRefined: boolean;
  label: string;
  value: string[];
};

type ItemControl = {
  label: string;
  isRefined: boolean;
};

interface Props {
  items: any;
  refine: (value: string[]) => void;
  searchForItems: (value: string) => void;

  searchable?: boolean;
  itemControls?: ItemControl[];
  refineControl?: (value: string) => undefined;
}

const RefinementList = ({
  items,
  refine,
  searchable,
  searchForItems,
  itemControls,
  refineControl,
}: Props) => {
  return (
    <Box>
      {searchable && (
        <Input
          type="search"
          placeholder="Filter tags..."
          onChange={(e) => searchForItems(e.currentTarget.value)}
          mb={2}
        />
      )}
      {items.map((item: ItemProps) => {
        const itemControl = itemControls?.find((i) => i.label === item.label);

        return (
          <Flex key={item.label} mb="10px">
            <Checkbox
              label={item.label}
              checked={itemControl ? itemControl.isRefined : item.isRefined}
              onChange={
                refineControl
                  ? () => refineControl(item.label)
                  : () => refine(item.value)
              }
            />
            <Box
              fontSize={12}
              ml={2}
              padding="4px 8px"
              bg="light-2"
              sx={{ borderRadius: 40 }}
              display="inline-block"
            >
              <Text>{item.count}</Text>
            </Box>
          </Flex>
        );
      })}
    </Box>
  );
};

const StatusRefinementList = connectRefinementList(RefinementList);
export default StatusRefinementList as any;
