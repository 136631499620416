import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useRef } from 'react';

import fetchUtil from '../../../util/fetchUtil';

import { goToUrl } from '../../../shared/links';

import { TextInput, TextArea } from '../../Input';
import Button from '../../Button';
import OptionSelector from '../../OptionSelector';
import ProjectSearch from '../../ProjectSearch';
import UploadBox from '../../UploadBox';

import { H4 } from '../../styles/typography';
import { ProjectLabel, ProjectName } from './ResourceStyles';

import { colors } from '../../styles/colors';

/**
 *
 *
 * @param {*} props
 * @returns
 */
function AddResource(props) {
  const { rowInfo, handleHideDialog, onAfterSave } = props;
  const { resourceID } = rowInfo;

  // const { state: { projectGroups } } = useContext(Store);

  const [errorState, setErrorState] = useState(false);
  const [nameValue, setNameValue] = useState(rowInfo.name || '');
  const [attributionValue, setAttributionValue] = useState(
    rowInfo.attribution || ''
  );
  const [copyright, setCopyright] = useState(rowInfo.copyright || '');
  const [linkValue, setLinkValue] = useState(rowInfo.link || '');
  const [notesValue, setNotesValue] = useState(rowInfo.notes || '');
  const [projects, setProjects] = useState(rowInfo.projects || []);
  const [imageSrc, setImageSrc] = useState(rowInfo.image || '');

  const projectsToRemove = useRef([]);

  const handleSave = () => {
    if (!nameValue || !linkValue || !copyright) {
      setErrorState(true);
      return;
    }

    fetchUtil
      .post('/resources/save', {
        resourceID,
        name: nameValue,
        data: {
          attribution: attributionValue,
          copyright: copyright.name,
          link: linkValue,
          notes: notesValue,
          // groups: groupOptions.filter(group => group.checked),
          projects,
          image: imageSrc,
        },
        projectsToRemove: projectsToRemove.current,
      })
      .then((resource) => {
        onAfterSave(resource);
        handleHideDialog();
      });
  };

  // const handleCheckboxChange = (e) => {
  //   const { name } = e.target;

  //   const newOptions = Array.from(groupOptions);
  //   const index = newOptions.findIndex(permission => permission.name === name);
  //   newOptions[index].checked = !newOptions[index].checked;

  //   setGroupOptions(newOptions);
  // };

  const handleAddImage = (res) => {
    const [file] = res;
    setImageSrc(file.url);
  };

  const handleAddProject = (project) => {
    setProjects([...projects, project]);
  };

  const handleRemoveProject = (project) => {
    const newProjects = Array.from(projects);

    const index = projects.findIndex((p) => p.projectID === project.projectID);
    newProjects.splice(index, 1);

    setProjects(newProjects);

    if (resourceID > -1) projectsToRemove.current.push(project.projectID);
  };

  const projectLabels = projects.map((project) => (
    <ProjectLabel key={project.projectID}>
      <ProjectName
        onClick={() =>
          goToUrl({ projectID: project.projectID, newWindow: true })
        }
      >
        {project.name}
      </ProjectName>
      <FontAwesomeIcon
        icon={['fas', 'times']}
        onClick={() => handleRemoveProject(project)}
      />
    </ProjectLabel>
  ));

  const errors = {
    name: errorState && !nameValue,
    link: errorState && !linkValue,
    copyright: errorState && !copyright,
  };

  const hasErrors = Object.values(errors).reduce(
    (previous, current) => current || false,
    false
  );
  const ccOptions = [
    'CC',
    'CC-BY',
    'CC-BY-SA',
    'Public Domain',
  ].map((item) => ({ id: item, name: item }));

  return (
    <Container>
      <Body>
        <EntryContainer>
          <Label>Name</Label>
          <StyledTextInput
            error={errors.name}
            value={nameValue}
            onChange={(e) => setNameValue(e.target.value)}
            placeholder="Add resource name..."
          />
        </EntryContainer>
        <FlexWrap>
          <EntryContainer>
            <Label>Source link</Label>
            <TextInput
              error={errors.link}
              value={linkValue}
              onChange={(e) => setLinkValue(e.target.value)}
              placeholder="Paste URL..."
            />
          </EntryContainer>
          <EntryContainer>
            <Label>Attribution</Label>
            <SubLabel>&nbsp;(Optional)</SubLabel>
            <TextInput
              value={attributionValue}
              onChange={(e) => setAttributionValue(e.target.value)}
              placeholder="E.g. Dr. Jeremy Jones"
            />
          </EntryContainer>
        </FlexWrap>
        <EntryContainer>
          <Label>Copyright</Label>
          <OptionSelector
            error={errors.copyright}
            options={ccOptions}
            onSelectOption={(option) => setCopyright(option)}
            selectedOption={copyright}
            buttonClasses="box"
            placeholder="Choose copyright..."
          />
        </EntryContainer>
        <EntryContainer>
          <LabelContainer>
            <Label>Projects</Label>
            <SubLabel>&nbsp;(Optional)</SubLabel>
          </LabelContainer>
          <ProjectSearch
            onItemSelect={handleAddProject}
            placeholder="Search projects to add this resource to..."
          />
          <ProjectsContainer>{projectLabels}</ProjectsContainer>
        </EntryContainer>
        {/* <EntryContainer>
          <LabelContainer>
            <Label>
              Folders
            </Label>
            <SubLabel>&nbsp;(Optional)</SubLabel>
          </LabelContainer>
          {groupCheckboxes}
        </EntryContainer> */}
        <EntryContainer>
          <Label>Notes</Label>
          <SubLabel>&nbsp;(Optional)</SubLabel>
          <StyledTextArea
            value={notesValue}
            onChange={(e) => setNotesValue(e.target.value)}
            placeholder="Case numbers, etc."
          />
        </EntryContainer>
        {imageSrc && (
          <Image>
            <img alt="Resource" src={imageSrc} />
          </Image>
        )}
        <UploadBox
          types={['jpeg', 'jpg', 'png']}
          s3Dir={'resouces'}
          onAfterUpload={handleAddImage}
          isProjectUpload={false}
        />
      </Body>
      <Footer>
        <ButtonContainer>
          <Button onClick={handleSave} className="box green">
            Save
          </Button>
        </ButtonContainer>
        {errorState && hasErrors && (
          <div className="negative message">
            Please fill out the required fields.
          </div>
        )}
      </Footer>
    </Container>
  );
}

const StyledTextArea = styled(TextArea)`
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Container = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;

  max-height: 70vh;
`;

const Body = styled.div`
  overflow: auto;
`;

const ProjectsContainer = styled.div`
  margin-top: 10px;
`;

const Footer = styled.div`
  margin-top: 20px;
`;

const FlexWrap = styled.div`
  display: flex;
`;

const EntryContainer = styled.div`
  margin-bottom: 10px;
  margin-right: 30px;

  flex: 1;

  input {
    width: 100%;
  }
`;

const Label = styled(H4)`
  margin-bottom: 5px;
  display: inline-block;
`;

const SubLabel = styled(H4)`
  display: inline-block;
  color: ${colors['light-grey-30']};
`;

const Image = styled.div`
  margin-bottom: 15px;

  img {
    width: 100%;
  }
`;

const StyledTextInput = styled(TextInput)`
  width: 100%;
`;

const LabelContainer = styled.div`
  display: flex;
`;

AddResource.propTypes = {
  rowInfo: PropTypes.shape({
    resourceID: PropTypes.number,
  }),
  onAfterSave: PropTypes.func,
  handleHideDialog: PropTypes.func.isRequired,
};

AddResource.defaultProps = {
  rowInfo: {},
  onAfterSave: () => {},
};

export default AddResource;
