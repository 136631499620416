import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { colors, lighten, darken } from './styles/colors';

const Icon = styled(FontAwesomeIcon)`
  font-size: 18px;
  margin-right: 10px;
`;

const Emoji = styled.div`
  font-size: 20px;
  margin-right: 10px;
`;

type ContainerProps = {
  positive?: boolean;
  negative?: boolean;
  color?: string;
  backgroundColor?: string;
  darkMode?: boolean;
};

const Container = styled.div`
  color: ${colors['light-grey-20']};
  background: ${lighten(colors.grey, 52)};
  display: inline-flex;

  font-family: 'Nunito', 'Helvetica Neue', Arial, Helvetica, sans-serif;

  border-radius: 5px;

  padding: 10px 15px;

  font-weight: 700;

  ${(props: ContainerProps) =>
    props.negative &&
    css`
      color: ${colors.red};
      background: ${colors['light-red-40']};
    `}

  ${(props: ContainerProps) =>
    props.positive &&
    css`
      color: ${darken(colors.green, 5)};
      background: ${lighten(colors.green, 45)};
    `}

      
  ${(props: ContainerProps) =>
    props.darkMode &&
    css`
      background: ${lighten(colors.night, 10)};
      color: ${lighten(colors.night, 40)};
    `}

  ${(props: ContainerProps) =>
    props.color &&
    css`
      color: ${props.color};
    `}
  
  ${(props: ContainerProps) =>
    props.backgroundColor &&
    css`
      background: ${props.backgroundColor};
    `}
`;

type Props = {
  children: ReactNode;
  positive?: boolean;
  negative?: boolean;
  color?: string;
  backgroundColor?: string;
  icon?: IconName;
  className?: string;
  emoji?: string;
  darkMode?: boolean;
  style?: React.CSSProperties;
};

function Message(props: Props) {
  const {
    children,
    positive,
    negative,
    color,
    backgroundColor,
    icon,
    className,
    style,
    emoji,
    darkMode,
  } = props;

  return (
    <Container
      style={style}
      positive={positive}
      negative={negative}
      color={color}
      backgroundColor={backgroundColor}
      className={className}
      darkMode={darkMode}
    >
      {icon && <Icon icon={['fad', icon]} />}
      {emoji && <Emoji>{emoji}</Emoji>}
      {children}
    </Container>
  );
}

const { bool, string, element, shape, oneOfType } = PropTypes;

Message.propTypes = {
  negative: bool,
  positive: bool,
  color: string,
  icon: string,
  backgroundColor: string,
  children: oneOfType([element, string]),
  className: string,
  emoji: string,
  darkMode: bool,
  style: shape({}),
};

Message.defaultProps = {
  negative: false,
  positive: false,
  color: null,
  icon: null,
  backgroundColor: null,
  className: '',
  emoji: '',
  style: {},
  darkMode: false,
};

export default Message;
