import styled from 'styled-components/macro';

import Button from '../Button';

export const Container = styled.div`
  padding: 10px;
`;
export const TextAreaContainer = styled.div`
  textarea {
    width: 100%;
    background: #eaeaea;
  }
`;
export const StyledButton = styled(Button)`
  margin-left: 10px;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;
