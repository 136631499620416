import { post } from '../../util/fetchUtil';

import Debouncer from '../utilities/debouncer';
import { uploadFile } from '../fileManager';
import uuid from '../utilities/uuid';
import deepClone from '../deepClone';
import cleanElements from './cleanElements';
import { ElementProps } from '../../ui/editor/types/ElementProps';
import { SceneProps } from '../../ui/editor/types/SceneProps';

/**
 * Save the current scene data to S3
 *
 * @param {String} s3Key
 * @param {Object} scene - the osmosify DB scene object
 * @param {[SvgElements]} elements
 */
export const save = async (
  s3Key: string | null,
  scene: SceneProps,
  elements: ElementProps[]
): Promise<string> => {
  // Jsonify the scene
  const json = {
    sceneID: scene.sceneID,
    duration: scene.data.duration,
    elements: cleanElements(deepClone(elements)),
  };

  let newS3Key = s3Key;
  // If the s3Key doesn't exist yet, create one
  if (!newS3Key) {
    newS3Key = `${uuid()}.json`;

    await post('/editor/addSceneKey', {
      sceneID: scene.sceneID,
      s3Key: newS3Key,
    });
  }

  const jsonFile = new Blob([JSON.stringify(json)], {
    type: 'application/json',
  });

  await uploadFile(jsonFile, newS3Key);
  // eslint-disable-next-line no-console
  return newS3Key;
};

const debouncer = new Debouncer(null, 1000);

/**
 * Debounce the calls to save by 1000s
 *
 * @param {String} s3Key
 * @param {Number} sceneID
 * @param {[SvgElements]} elements
 */
export const saveDebounce = (
  s3Key: string | null,
  scene: SceneProps,
  elements: ElementProps[]
): Promise<void> =>
  new Promise((resolve) => {
    debouncer.setValue(elements, async () => {
      await save(s3Key, scene, elements);
      resolve();
    });
  });

export default {
  save,
  saveDebounce,
};
