import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { get } from '../../util/fetchUtil';
import Table from '../Table';
import Box from '../Box';
import Label from '../Label';

type FormProps = {
  data: { text: string; type: 'bug' | 'idea' };
  userID: number;
  formID: number;
  createdOn: string;
};

const AdminFeedbackForms = () => {
  const [forms, setForms] = useState<FormProps[]>([]);
  const getFeedback = async () => {
    const res = await get('/v0/feedback_forms');
    setForms(res.forms);
  };

  useEffect(() => {
    getFeedback();
  }, []);

  const headers = [
    {
      id: 'formID',
      value: 'Form ID',
    },
    {
      id: 'userID',
      value: 'User ID',
    },
    {
      id: 'text',
      value: 'Text',
      width: 400,
    },
    {
      id: 'type',
      value: 'Type',
    },
    {
      id: 'createdOn',
      value: 'Created On',
    },
  ];

  const rows = forms
    .sort((a, b) => +new Date(b.createdOn) - +new Date(a.createdOn))
    .map((form) => ({
      formID: form.formID,
      userID: form.userID,
      type: {
        component: <Label round>{form.data.type}</Label>,
      },
      text: {
        component: (
          <Box
            sx={{
              img: {
                maxWidth: 300,
              },
            }}
            dangerouslySetInnerHTML={{ __html: form.data.text }}
          />
        ),
      },
      createdOn: moment(form.createdOn).format('HH:mma MM-DD-YYYY'),
    }));

  return (
    <div>
      <Table rows={rows} headers={headers} />
    </div>
  );
};

export default AdminFeedbackForms;
