import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import React, { useRef, useEffect } from 'react';

import { colors, fade, lighten } from './styles/colors';
import fileManager from '../app/fileManager';
import Quill from '../app/Quill';

import uuid from '../app/utilities/uuid';
import { getExtension } from '../utilities/files';

const Container = styled.div`
  .ql-toolbar.ql-snow {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    background-color: ${lighten(colors.grey, 55)};

    ${(props) =>
      props.darkMode &&
      css`
        background-color: ${lighten(colors.night, 15)};
        border: 1px solid ${lighten(colors.night, 20)};

        button {
          path,
          line,
          rect,
          polyline {
            stroke: white;
            color: white;
          }
        }

        button:hover {
          path,
          line,
          rect,
          polyline {
            stroke: white;
            color: ${lighten(colors.blue, 30)};
          }
        }
      `}
  }

  .ql-snow.ql-tooltip {
    z-index: 1;
  }

  .ql-container.ql-snow {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    ${(props) =>
      props.darkMode &&
      css`
        border: 1px solid ${lighten(colors.night, 30)};
      `}

    .ql-editor {
      font-family: 'Nunito';
      background-color: white;

      ${(props) =>
        props.darkMode &&
        css`
          background-color: ${lighten(colors.night, 23)};
          p,
          li {
            color: ${fade('white', 10)};
          }

          a {
            color: ${lighten(colors.blue, 30)};
          }
        `}

      &.ql-blank::before {
        color: ${colors['light-grey-30']};
        font-size: 16px;
        font-family: 'Nunito';
      }
    }
  }
`;

function QuillEditor(props) {
  const {
    toolbar,
    value,
    onChange,
    onEnterPress,
    placeholder,
    darkMode,
  } = props;

  const editorRef = useRef();
  const quillRef = useRef();

  const customEnterId = 'custom-enter-binding';

  const handleImageUpload = () => {
    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const formData = new FormData();

      formData.append('image', file);

      // Save current cursor state
      const range = quillRef.current.getSelection(true);

      const fileType = getExtension(file.name);
      const acceptedFiles = ['jpg', 'jpeg', 'png'];
      if (
        !acceptedFiles.find((t) => t === fileType.slice(1, fileType.length))
      ) {
        return;
      }

      // Insert temporary loading placeholder image
      quillRef.current.insertEmbed(
        range.index,
        'image',
        'https://osmosify-src.s3.amazonaws.com/assets/Tkyaat5wQXaMWNnmsLG8TwfmQSC1b-gO.gif'
      );

      // Move cursor to right side of image (easier to continue typing)
      quillRef.current.setSelection(range.index + 1);

      const key = `${uuid()}${getExtension(file.name)}`;
      // Post to an api endpoint which uploads to s3. It returns the s3 url
      const res = await fileManager.uploadFile(file, key);

      // Remove placeholder image
      quillRef.current.deleteText(range.index, 1);

      // Insert uploaded image
      quillRef.current.insertEmbed(range.index, 'image', res.url);
    };
  };

  const onTextChange = (quill) => {
    // args for callback -> delta, oldDelta, source
    quill.on('text-change', () => {
      onChange(quill.getHTML());
    });
  };

  useEffect(() => {
    const bindings = {};
    if (onEnterPress) {
      bindings.enter = {
        key: 13,
        id: customEnterId,
        handler: onEnterPress,
      };
    }

    const quill = new Quill(editorRef.current, {
      theme: 'snow',
      placeholder,
      modules: {
        toolbar: toolbar.length ? toolbar : false,
        keyboard: {
          bindings,
        },
      },
    });

    const quillToolbar = quill.getModule('toolbar');
    quillToolbar.addHandler('image', handleImageUpload);

    quill.getHTML = () => quill.root.innerHTML;

    quill.setHTML = (html) => {
      quill.root.innerHTML = html;
    };

    onTextChange(quill);

    quillRef.current = quill;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // If the editor's value is different than the passed value, update the editor
    if (quillRef.current && quillRef.current.getHTML() !== value) {
      quillRef.current.setHTML(value);
    }

    // Update value on change
    onTextChange(quillRef.current);

    // Update custom enter binding whenever value changes (can't use native addBinding for special keys like 'enter')
    const enterBindings = quillRef.current.keyboard.bindings[13];
    if (enterBindings) {
      const customBinding = enterBindings.find(
        (binding) => binding.id === customEnterId
      );
      if (customBinding) customBinding.handler = onEnterPress;
    }
    // eslint-disable-next-line
  }, [value, onEnterPress]);

  return (
    <Container darkMode={darkMode}>
      <div ref={editorRef} />
    </Container>
  );
}

const { array, string, func, bool } = PropTypes;

QuillEditor.propTypes = {
  toolbar: array,
  placeholder: string,
  value: string,
  onEnterPress: func,
  darkMode: bool,
  onChange: func,
};

QuillEditor.defaultProps = {
  toolbar: [],
  placeholder: '',
  value: '',
  darkMode: false,
  onEnterPress: () => {},
  onChange: () => {},
};

export default QuillEditor;
