import React from 'react';
import { Box } from 'rebass/styled-components';
import moment from 'moment';
import { formatAsMinutesSeconds } from '../../app/utilities/timestamps';
import { colors } from '../styles/colors';
import { P } from '../styles/typography';
import { stripTags } from '../../shared/utilities/strip';
import { CommentProps } from '../editor/types/CommentProps';

type Props = {
  comment: CommentProps;
};

const Comment: React.FC<Props> = ({ comment }: Props) => (
  <Box style={{ marginTop: 20 }}>
    <P>
      {comment.data.timestamp && (
        <span style={{ color: colors['light-grey-10'] }}>
          {formatAsMinutesSeconds(comment.data.timestamp)}&nbsp;-&nbsp;
        </span>
      )}
      <span style={{ color: colors['light-grey-20'] }}>
        {comment.data.user?.givenName}
        &nbsp;@&nbsp;{moment(comment.createdOn).format('HH:mma DD-MM-YY')}
      </span>
    </P>
    <P>{stripTags(comment.data.text)}</P>
  </Box>
);

export default Comment;
