import styled from 'styled-components/macro';
import { Line } from 'react-chartjs-2';
import React from 'react';
import moment from 'moment';

function UserChart(props) {
  const { task, month, byProject } = props;

  const data = {};

  if (month) {
    const momentMonth = moment().month(month).year(moment().year());
    const daysInMonth = momentMonth.daysInMonth();

    for (let i = 1; i <= daysInMonth; i += 1) {
      momentMonth.date(i);
      let dataValue = null;
      const projects = task.list.filter(
        (project) => moment(project.date).date() === momentMonth.date()
      );
      if (byProject) {
        dataValue = projects.length;
      } else {
        dataValue = projects.reduce(
          (previous, current) => previous + current.wordCount,
          0
        );
      }

      data[momentMonth.format('MM/DD')] = dataValue;
    }
  }

  return (
    <Container>
      <Line
        data={{
          labels: Object.keys(data),
          datasets: [
            {
              label: byProject ? 'Projects' : 'Words',
              data: Object.values(data),
              backgroundColor: ['rgba(255, 99, 132, 0.2)'],
              borderColor: ['rgba(255, 99, 132, 1)'],
              borderWidth: 1,
            },
          ],
        }}
        options={{
          scales: {
            yAxes: [
              {
                ticks: {
                  // min: 0,
                  // stepSize: 1,
                },
              },
            ],
          },
        }}
      />
    </Container>
  );
}

const Container = styled.div``;

UserChart.propTypes = {};

UserChart.defaultProps = {};

export default UserChart;
