export const degToRad = (deg) => (deg / 180) * Math.PI;

export const sin = (deg) => Math.sin(degToRad(deg));
export const cos = (deg) => Math.cos(degToRad(deg));
export const tan = (deg) => Math.tan(degToRad(deg));

export const calcOpposite = (deg, hyp) => sin(deg) * hyp;

export const calcAdjacent = (deg, hyp) => cos(deg) * hyp;

/**
 * Rotates the specified point { x, y } around the origin by the specified angle (deg)
 *
 * @param {Number} x
 * @param {Number} y
 * @param {Number} deg
 * @returns
 */
export const rotate = (x, y, deg) => {
  const xp = x * Math.cos(degToRad(deg)) - y * Math.sin(degToRad(deg));
  const yp = y * Math.cos(degToRad(deg)) + x * Math.sin(degToRad(deg));

  return { x: xp, y: yp, dx: xp - x, dy: yp - y };
};

export default {
  degToRad,
  sin,
  tan,
  cos,
  calcOpposite,
  calcAdjacent,
  rotate,
};
