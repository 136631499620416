import { useState, useEffect } from 'react';
import { isMac } from '../device';

const modifiers = {
  shift: 'shift',
  control: 'control',
  ctrl: 'control',
  cmd: isMac() ? 'meta' : 'control',
  command: isMac() ? 'meta' : 'control',
  alt: 'alt',
};

/**
 * Hook to determine if a modifier key, e.g. 'command' is being pressed
 *
 * @param {String} modifier
 * @returns {Object}
 * {
 *   isDown: bool, - is the modifier down
 * }
 */
export default (
  modifier: 'shift' | 'control' | 'ctrl' | 'cmd' | 'command' | 'alt'
): { isDown: boolean } => {
  const [modifierDown, setModifierDown] = useState(false);

  const handleKeydown = (e: KeyboardEvent) => {
    if (e.key.toLowerCase() === modifiers[modifier]) {
      setModifierDown(true);
    }
  };

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key.toLowerCase() === modifiers[modifier]) {
      setModifierDown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
      document.removeEventListener('keyup', handleKeyUp);
    };
    // eslint-disable-next-line
  }, [modifier]);

  return { isDown: modifierDown };
};
