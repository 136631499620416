import styled, { css } from 'styled-components/macro';

import { colors, fade, lighten } from '../styles/colors';

export const Container = styled.div`
  display: flex;
  width: 400px;

  padding: 5px 10px;
  border-radius: 3px;

  background: ${colors['light-night-10']};

  :not(:last-child) {
    margin-bottom: 10px;
  }

  :hover,
  :focus {
    background: ${lighten(colors.night, 8)};
  }

  :active {
    background: ${lighten(colors.night, 5)};
  }

  ${(props) =>
    !props.isActive &&
    css`
      opacity: 0.5;
    `}
`;
export const RightContainer = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const LeftContainer = styled.div`
  flex: 4;
`;
export const Timestamp = styled.p`
  margin: 0;
  font-size: 14px;

  margin-left: 10px;
`;
export const MarkerContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const diameter = 15;
export const Marker = styled.div`
  width: ${diameter}px;
  height: ${diameter}px;
  border-radius: 100%;

  border: 1px solid ${fade('white', 45)};

  transition: 0.1s ease-in-out all;

  ${(props) =>
    props.isActive &&
    css`
      border: none;
      background-color: ${colors.red};
      :hover {
        box-shadow: 0px 0px 0px 2px ${fade(colors.red, 50)};
      }
    `}

  ${(props) =>
    !props.isActive &&
    css`
      :hover {
        background-color: ${fade('white', 80)};
        box-shadow: 0px 0px 0px 2px ${fade('white', 50)};
      }
    `}
`;
