import { useEffect, useRef, useState } from 'react';

const filetype = 'webm';

const useAudioRecorder = (
  options: {
    timeslice?: number; // Interval data chunks are logged
  } = {}
): {
  start: () => void;
  stop: () => { blob: Blob; url: string; type: string };
  isRecording: boolean;
} => {
  const { timeslice = 100 } = options;

  const [isRecording, setIsRecording] = useState(false);

  const mediaRecorder = useRef<MediaRecorder>();
  const stream = useRef<MediaStream>();
  const chunks = useRef<Blob[]>([]);

  const onDataAvailable = (e: { data: Blob }) => {
    chunks.current?.push(e.data);
  };

  const init = async () => {
    try {
      // Ask for permission for recording
      stream.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });

      // Create recorder
      mediaRecorder.current = new MediaRecorder(stream.current, {
        mimeType: `audio/${filetype}`,
      });
    } catch (err) {
      console.error(err);
    }

    return true;
  };

  const start = async () => {
    // Make sure all items are cleared
    cleanup();

    // Initialize media recorder if it doesn't already exist
    await init();

    if (mediaRecorder.current) {
      // Get data every 100ms
      mediaRecorder.current.start(timeslice);

      // Add data to chunks when available
      mediaRecorder.current.ondataavailable = onDataAvailable;

      setIsRecording(true);
    }

    return true;
  };

  const cleanup = () => {
    // Clear chunks
    chunks.current = [];

    // Stop using mediaRecorder
    stream.current?.getTracks().forEach((track) => {
      track.stop();
    });

    // Clear stream
    stream.current = undefined;
  };

  const stop = () => {
    // TODO: THIS POTENTIALLY MISSES A SMALL BIT OF DATA CHUNKS
    mediaRecorder.current?.stop();

    // Create blob and url
    const blob = new Blob(chunks.current, { type: `audio/${filetype}` });
    const url = URL.createObjectURL(blob);

    setIsRecording(false);

    cleanup();

    return { blob, url, type: filetype };
  };

  useEffect(() => {
    return () => {
      // Clean up before unmount
      cleanup();
    };
  }, []);

  return {
    start,
    stop,
    isRecording,
  };
};

export default useAudioRecorder;
