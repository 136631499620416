import React, { useState, MutableRefObject } from 'react';
import styled from 'styled-components/macro';

import { getSvgCoords } from '../../../app/editor/coords';

import { colors } from '../../styles/colors';
import useDraggable from '../../../app/hooks/useDraggable';
import { calculateSelectionBox } from '../../../app/editor/rect';
import { MixedElementProps } from '../types/ElementProps';
import create from '../../../editor/create';

const StyledRect = styled.rect`
  fill: none;
  stroke-width: 1px;
  stroke: ${colors.blue};
`;

type Props = {
  svgRef: MutableRefObject<SVGElement | null>;
  handleAddElement: (newElement: MixedElementProps) => void;
};

/**
 * Add new points to a path with a bezier pen tool
 */
type PointProps = {
  x: number;
  y: number;
};

type RectProps = {
  width: number;
  height: number;
  x: number;
  y: number;
};

const RectEditor: React.FC<Props> = (props: Props) => {
  const { svgRef, handleAddElement } = props;

  const [, update] = useState({});
  const [origin, setOrigin] = useState<PointProps>();
  const [rect, setRect] = useState<RectProps>();

  const handleDrag = (e: DragEvent) => {
    if (svgRef.current && origin && rect) {
      const point = getSvgCoords(svgRef.current, e);

      setRect(calculateSelectionBox(origin, point));

      update({});
    }
  };

  const handleMouseDown = (e: MouseEvent) => {
    // onMouseDown(e);
    if (svgRef.current) {
      const point = getSvgCoords(svgRef.current, e);
      const newRect = {
        ...point,
        width: 0,
        height: 0,
      };

      setOrigin(point);
      setRect(newRect);
    }
  };

  const handleMouseUp = () => {
    // Update UI
    update({});

    if (rect && rect.width > 0 && rect.height > 0) {
      handleAddElement(
        create.rect({
          props: {
            ...rect,
            translateX: rect?.x,
            translateY: rect?.y,
          },
        })
      );
    }
  };

  useDraggable(svgRef, [origin, rect], {
    onMouseDown: handleMouseDown,
    onMouseUp: handleMouseUp,
    onDrag: handleDrag,
  });

  return (
    <g>
      <StyledRect {...rect} />
    </g>
  );
};

export default RectEditor;
