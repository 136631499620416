import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import { useRef } from 'react';


import useClickOutside from '../../../app/hooks/useClickOutside';

import Box from '../../Box';
import Button from '../../Button';
import { colors, lighten, fade  } from '../../styles/colors';

import { Row  } from './TimelineStyles';

import ElementProps from '../props/ElementProps';
import KeyframeGroupProps from '../props/ElementProps';

const KeyframeButton = styled(Button)`
  font-size: 10px;
  padding: 0.2em;
`;

const KeyframeRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  color: ${fade('white', 50)};
  align-items: center;

  ${(props) =>
    props.isActive &&
    css`
      color: ${fade('white', 50)};
      background-color: ${lighten(colors.night, 25)};
    `}
`;

/**
 * Left-hand side row info for a keyframe group, e.g. all the keyframes for 'opacity'
 */
const KeyframeRowInfo = (props) => {
  const {
    height,
    // handleSelectKeyframeGroup,
    handleRemoveKeyframeGroup,
    handleDeselectKeyframeGroup,
    selectedKeyframeGroups,
    element,
    keyframeKey,
    handleAddKeyframe,
  } = props;

  const containerRef = useRef();

  useClickOutside(containerRef, () => {
    if (
      selectedKeyframeGroups.some(
        (group) => group.elementId === element.id && group.key === keyframeKey
      )
    ) {
      handleDeselectKeyframeGroup(element, keyframeKey);
    }
  });

  return (
    <KeyframeRow
      ref={containerRef}
      height={height}
      // onClick={() => handleSelectKeyframeGroup(element, keyframeKey)}
      isActive={selectedKeyframeGroups.some(
        (group) => group.elementId === element.id && group.key === keyframeKey
      )}
    >
      <Box>
        <KeyframeButton
          icon
          style={{ marginRight: 5 }}
          onClick={() => handleRemoveKeyframeGroup(element, keyframeKey)}
          color={fade('white', 50)}
        >
          <FontAwesomeIcon icon={['fas', 'times']} />
        </KeyframeButton>
        <span>{keyframeKey}</span>
      </Box>
      <KeyframeButton
        onClick={() => handleAddKeyframe(element, keyframeKey)}
        icon
        color={fade('white', 50)}
      >
        <FontAwesomeIcon icon={['fad', 'map-marker']} />
      </KeyframeButton>
    </KeyframeRow>
  );
};

const { arrayOf, func, number, string } = PropTypes;

KeyframeRowInfo.propTypes = {
  element: ElementProps.isRequired,
  height: number.isRequired,
  handleSelectKeyframeGroup: func.isRequired,
  handleDeselectKeyframeGroup: func.isRequired,
  selectedKeyframeGroups: arrayOf(KeyframeGroupProps),
  handleAddKeyframe: func.isRequired,
  handleRemoveKeyframeGroup: func.isRequired,
  keyframeKey: string.isRequired,
};

export default KeyframeRowInfo;
