const rolesObject = {
  voiceover: 'Voiceover',
  illustrator: 'Illustrator',
  videoEditor: 'Video Editor',
  scriptEditor: 'Script Editor',
};

const rolesArray = Object.values(rolesObject);

export const roles = rolesArray;

const { voiceover, illustrator, videoEditor, scriptEditor } = rolesObject;

export const credits = [
  {
    name: 'Allison Dollar, BFA',
    roles: [illustrator],
  },
  {
    name: 'Brittany Norton, MFA',
    roles: [illustrator],
  },
  {
    name: 'Elizabeth Shapiro, MSMI, CMI',
    roles: [illustrator, voiceover],
  },
  {
    name: 'Justin Ling, MD, MS',
    roles: [illustrator, scriptEditor],
  },
  {
    name: 'Kaia Chessen, MScMBC',
    roles: [illustrator],
  },
  {
    name: 'Kara Lukasiewicz, PhD, MScMBC',
    roles: [illustrator],
  },
  {
    name: 'Pauline Rowsome, BSc (Hons)',
    roles: [illustrator],
  },
  {
    name: 'Samantha McBundy, MFA',
    roles: [illustrator, voiceover],
  },
  {
    name: 'Tanner Marshall, MS',
    roles: [illustrator, videoEditor, voiceover],
  },
  {
    name: 'Vince Waldman, PhD',
    roles: [illustrator],
  },
  {
    name: 'Ursula Florjanczyk, MScBMC',
    roles: [illustrator],
  },
  {
    name: 'Alex Aranda',
    roles: [videoEditor],
  },
  {
    name: 'Evan Debevec-McKenney',
    roles: [videoEditor],
  },
  {
    name: 'Marisa Pedron',
    roles: [videoEditor],
  },
  {
    name: 'Sam Gillespie, BSC',
    roles: [videoEditor],
  },
  {
    name: 'Will Wei',
    roles: [videoEditor, voiceover],
  },
  {
    name: 'Rishi Desai, MD, MPH',
    roles: [scriptEditor],
  },
  {
    name: 'Yifan Xiao, MD',
    roles: [scriptEditor],
  },
  {
    name: 'Supriya Sreedhar, PharmD',
    roles: [scriptEditor],
  },
];
