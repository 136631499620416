import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { memo } from 'react';

import { colors } from './styles/colors';

const GridLine = styled.line`
  stroke: ${colors['light-grey-50']};
`;

/**
 * A basic grid to be displayed on an SVG canvas
 */
const SvgGrid = (props) => {
  const { spacing, width, height } = props;

  const horLines = [];
  const vertLines = [];

  const numRows = Math.ceil(height / spacing);
  const numColumns = Math.ceil(width / spacing);

  for (let i = 0; i < numRows; i += 1) {
    horLines.push(
      <GridLine
        key={i}
        x1={0}
        x2={spacing * numColumns}
        y1={i * spacing}
        y2={i * spacing}
        strokeWidth={i % 5 === 0 ? 2 : 1}
      />
    );
  }

  for (let i = 0; i < numColumns; i += 1) {
    vertLines.push(
      <GridLine
        key={i}
        x1={i * spacing}
        x2={i * spacing}
        y1={0}
        y2={spacing * numRows}
        strokeWidth={i % 5 === 0 ? 2 : 1}
      />
    );
  }

  return (
    <g>
      <g>
        {horLines}
        {vertLines}
      </g>
    </g>
  );
};

const { number } = PropTypes;

SvgGrid.propTypes = {
  spacing: number,
  width: number.isRequired,
  height: number.isRequired,
};

SvgGrid.defaultProps = {
  spacing: 50,
};

export default memo(SvgGrid);
