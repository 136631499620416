import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { Flex } from '../../Box';
import Lightbox from '../../Lightbox';

const Embed = styled.embed`
  width: 100%;
  height: 50vh;
  background: white;
`;

function TextAsset(props) {
  const { file, showPreview, setShowPreview } = props;

  return (
    <Flex c style={{ height: '100%' }}>
      <FontAwesomeIcon icon={['fad', 'file-alt']} size={50} />
      <Lightbox
        isVisible={showPreview}
        handleHideLightbox={() => setShowPreview(false)}
      >
        <Embed src={file.data.url} />
      </Lightbox>
    </Flex>
  );
}

TextAsset.propTypes = {
  file: PropTypes.shape({
    type: PropTypes.string,
    data: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  showPreview: PropTypes.bool,
  setShowPreview: PropTypes.func.isRequired,
};

TextAsset.defaultProps = {
  file: {},
  showPreview: false,
};

export default TextAsset;
