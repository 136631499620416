import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { uploadFile } from '../app/fileManager';
import uuid from '../app/utilities/uuid';

export const formatSize = function (size: number): string {
  return `${Math.round(size / 1000 / 100) / 10} MB`;
};

export const getExtension = (filename: string): string => {
  const i = filename.lastIndexOf('.');
  return i < 0 ? '' : filename.substr(i);
};

export const getBasename = (filename: string): string => {
  const ext = getExtension(filename);
  const basename = filename.slice(0, filename.length - ext.length);

  return basename;
};

/**
 * Load an image from a given URL
 * @param {String} url The URL of the image resource
 * @returns {Promise<Image>} The loaded image
 */ export const loadImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve) => {
    const image = new Image();
    image.addEventListener('load', () => {
      resolve(image);
    });
    image.src = url;
  });

export const readFile = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function fileReadComplete() {
      resolve(reader.result);
    };

    reader.onerror = function fileReadError(err) {
      reject(err);
    };

    reader.readAsDataURL(file);
  });

export const loadFiles = async (files: File[], data: any = {}) => {
  // Upload files to s3
  const uploads: { url: string }[] = await Promise.all(
    files.map((f: File) => {
      const key = `assets/${uuid()}${getExtension(f.name)}`;
      return uploadFile(f, key);
    })
  );

  // Load mock temporary file objects
  const loadedFiles = files.map((file, i) => ({
    createdOn: moment(file.lastModified).format('YYYY-MM-DD HH:mm:ss'),
    data: {
      url: uploads[i].url,
      size: file.size,
      projectAssetType: '',
      user: {
        id: 0,
        name: '',
      },
      versions: [],
      ...data,
    },
    fileID: Math.round(Math.random() * 10000000),
    modified: file.lastModified.toString(),
    name: getBasename(file.name),
    projectID: 0,
    status: 1 as 0 | 1,
    type: getExtension(file.name)?.slice(1),
    projects: [],
    uuid: uuidv4(),
  }));

  return loadedFiles;
};

export default {
  getBasename,
  loadFiles,
  loadImage,
  getExtension,
  formatSize,
};
