import PropTypes from 'prop-types';
import React from 'react';
import $ from 'jquery';
import { event, line as d3Line, curveBasis, select, drag } from 'd3';

import { Store } from '../../state/store';

import { Container } from './AnnotationStyles';

/**
 * Controls SVG-based annotation
 *
 * @class Annotation
 * @extends {React.Component}
 */
class Annotation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    // Create ref to React element
    this.svgRef = React.createRef();

    // Add jquery reference (for easy width/height measurement, this could probably be done differently)
    this.$svg = null;

    // Map for toggling between shapes
    this.modes = {
      pencil: this.pencilDragStarted,
      box: this.boxDragStarted,
    };

    // Initialize the mode
    this.mode = this.modes.pencil;
  }

  componentDidMount() {
    // Set jquery reference after element has mounted to DOM
    this.$svg = $(this.svgRef.current);

    // Initialize drawing
    this.initDraw();
  }

  componentWillUnmount() {
    // Clean up event listeners before unmounting
    this.removeDraw();
  }

  /**
   * Initializes the annotation with whatever mode is currently selected
   *
   * @memberof Annotation
   */
  initDraw() {
    // Use d3 library to draw annotations
    this.svg = select(this.svgRef.current).call(
      drag()
        .subject(() => {
          const p = this.convertToSvgCoords([event.x, event.y]);
          return [p, p];
        })
        .on('start', this.mode(this))
        .on('end', this.onDragEnd(this))
    );
  }

  /**
   * Called whenever a drag event ends
   * Calls the passed onDragEnd callback prop
   *
   * @param {Object} self
   * @returns
   * @memberof Annotation
   */
  onDragEnd(self) {
    return () => {
      const { onDragEnd } = self.props;
      onDragEnd(self.svgRef.current);
    };
  }

  /**
   * Scales the container coordinates to the SVG view box
   *
   * @param {*} coords [array] - [x, y] coordinates to scale
   * @returns [array] - scaled coordinates
   */
  convertToSvgCoords(coords) {
    const {
      state: { sceneDimensions },
    } = this.context;
    const [x, y] = coords;

    let scaledX;
    let scaledY;

    const containerWidth = this.$svg.width();
    const containerHeight = this.$svg.height();

    const svgViewboxWidth = sceneDimensions.w;
    const svgViewboxHeight = sceneDimensions.h;

    const containerAspect = containerWidth / containerHeight;

    if (containerAspect > svgViewboxWidth / svgViewboxHeight) {
      // Get adjusted width and X position in SVG coordinates
      const svgWidth = (containerWidth / containerHeight) * svgViewboxHeight;
      const svgX =
        (x / containerHeight) * svgViewboxHeight -
        (svgWidth - svgViewboxWidth) / 2;
      scaledX = svgX;

      scaledY = (y / containerHeight) * svgViewboxHeight;
    } else {
      scaledX = (x / containerWidth) * svgViewboxWidth;

      // Get adjusted height and Y position in SVG coordinates
      const svgHeight = (containerHeight / containerWidth) * svgViewboxWidth;
      const svgY =
        (y / containerWidth) * svgViewboxWidth -
        (svgHeight - svgViewboxHeight) / 2;
      scaledY = svgY;
    }

    return [scaledX, scaledY];
  }

  /**
   * Logic for creating a basic line drawing
   *
   * @param {Object} self - reference to this component
   * @returns
   * @memberof Annotation
   */
  pencilDragStarted(self) {
    return function () {
      const line = d3Line().curve(curveBasis);

      const d = event.subject;
      const active = self.svg
        .append('g')
        .append('path')
        .attr('class', 'pencil-annotation')
        .datum(d);
      // Scale coordinates to SVG view box
      let [x0, y0] = self.convertToSvgCoords([event.x, event.y]);

      event.on('drag', () => {
        const [x1, y1] = self.convertToSvgCoords([event.x, event.y]);
        const dx = x1 - x0;
        const dy = y1 - y0;

        if (dx * dx + dy * dy > 100) {
          d.push([(x0 = x1), (y0 = y1)]);
        } else {
          d[d.length - 1] = [x1, y1];
        }

        // Update path element in DOM and also store the value to the observable for reading
        active.attr('d', line);
      });
    };
  }

  /**
   * Logic for creating a basic box
   *
   * @param {Object} self - reference to this component
   * @returns
   * @memberof Annotation
   */
  boxDragStarted(self) {
    return function () {
      const rect = {
        r: null,
        x0: null,
        y0: null,
      };

      const [x0, y0] = self.convertToSvgCoords([event.x, event.y]);
      rect.x0 = x0;
      rect.y0 = y0;

      rect.r = self.svg
        .append('g')
        .append('rect')
        .attr('x', rect.x0)
        .attr('y', rect.y0)
        .attr('width', 1)
        .attr('height', 1)
        .attr('class', 'box-annotation');

      event.on('drag', () => {
        const [x1, y1] = self.convertToSvgCoords([event.x, event.y]);
        rect.r
          .attr('x', Math.min(rect.x0, x1))
          .attr('y', Math.min(rect.y0, y1))
          .attr('width', Math.abs(rect.x0 - x1))
          .attr('height', Math.abs(rect.y0 - y1));
      });
    };
  }

  /**
   * Clean up listeners
   *
   * @memberof Annotation
   */
  removeDraw() {
    // Remove event listener for drag
    select(this.svgRef.current).call(drag().on('start', null).on('end', null));
  }

  render() {
    const { isActive } = this.props;
    const {
      state: { annotationMode, sceneDimensions },
    } = this.context;

    // If annotation mode is set and it's not set to the same mode that's currently set
    if (annotationMode && this.modes[annotationMode] !== this.mode) {
      this.mode = this.modes[annotationMode];
      this.initDraw();
    }

    return (
      <Container isActive={isActive}>
        <svg
          ref={this.svgRef}
          width="100%"
          height="100%"
          viewBox={`0 0 ${sceneDimensions.w} ${sceneDimensions.h}`}
        ></svg>
      </Container>
    );
  }
}

Annotation.contextType = Store;

Annotation.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onDragEnd: PropTypes.func,
};

Annotation.defaultProps = {
  onDragEnd: () => {},
};

export default Annotation;
