import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import elevations from '../styles/elevations';
import Box from '../Box';
import { colors, lighten } from '../styles/colors';
import { P } from '../styles/typography';
import CommentProps from '../../props/comments';
import { getAnonymousName } from '../../shared/anonymousNames';

const Icon = styled(FontAwesomeIcon)`
  font-size: 18px;
  color: white;
  padding: 5px;

  color: ${lighten(colors.teal, 20)};
`;

const Wrapper = styled.div`
  position: absolute;
  ${(props) =>
    (props.left || props.left === 0) &&
    css`
      left: ${props.left * 100}%;
    `}

  cursor: pointer;

  transform: translateX(-50%);

  top: 0;

  padding: 5px;

  z-index: 1;

  transition: 0.1s all ease-in-out;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${lighten(colors.teal, 20)};
  border-radius: 100%;

  :hover {
    opacity: 1;
    border: 1px solid white;
    z-index: 2;
  }
`;

const CommentWrapper = styled.div`
  position: absolute;
  bottom: calc(100%);

  padding-bottom: 10px;

  transform: translateX(-50%);
  pointer-events: none;

  ${(props) =>
    props.mouseIsOver &&
    css`
      pointer-events: initial;
    `}
`;

const Comment = styled.div`
  padding: 5px 8px;
  background-color: ${colors.night};
  border-radius: 3px;
  box-shadow: ${elevations[3]};

  width: 250px;

  opacity: 0;
  transform: scale(0.5) translateY(10px);
  transition: 0.1s all ease-in-out;

  ${Wrapper}:hover & {
    opacity: 1;
    transform: scale(1) translateY(0px);
  }
`;

const Name = styled(P)`
  font-size: 10px;
  color: white;

  opacity: 0.5;
  text-align: left;
`;

const Content = styled.div`
  font-size: 14px;
  color: white;
  opacity: 0.75;
  display: inline-block;
  margin-top: 5px;

  max-height: 200px;
  overflow: auto;

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  li,
  ul,
  div {
    color: white;
    margin: 0;

    text-align: left;

    line-height: 1.25em;
  }

  img {
    width: 100%;
  }
`;

function VideoComment(props) {
  const { comment, duration } = props;

  const [mouseOver, setMouseOver] = useState(false);

  const timestamp = parseFloat(comment.data.timestamp);
  const progress = timestamp / duration;

  return (
    <Wrapper
      left={progress}
      onClick={(e) => comment.onClick(e)}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <Dot />
      <CommentWrapper mouseIsOver={mouseOver}>
        <Comment>
          <Box>
            <Name>
              {comment.data.anonymous
                ? getAnonymousName(comment.userID)
                : comment.data.user.givenName}
            </Name>
          </Box>
          <Box style={{ display: 'flex', alignItems: 'flex-start' }}>
            {comment.data.annotation && comment.data.annotation.svg && (
              <Icon icon={['fad', 'paint-brush']} />
            )}
            <Content dangerouslySetInnerHTML={{ __html: comment.data.text }} />
          </Box>
        </Comment>
      </CommentWrapper>
    </Wrapper>
  );
}

VideoComment.propTypes = {
  duration: PropTypes.number,
  comment: CommentProps,
};

VideoComment.defaultProps = {
  duration: 0,
  comment: {},
};

export default VideoComment;
