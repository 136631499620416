import React, { memo } from 'react';

import easings from '../../../app/easings';
import { CharacterPath } from '../types/TextElementProps';

type Props = {
  path: CharacterPath;
  clipPath: string;
  progress: number;
};

/**
 * A path component of a text character
 */
function TextPath(props: Props) {
  const { path, progress, clipPath } = props;
  // Set stroke dash offset to correct timepoint
  const strokeProgress = 1 - (progress - path.start) / (path.end - path.start);
  let strokeDashoffset =
    path.totalLength * easings.easeInOutCubic(strokeProgress);

  // Show no path
  if (progress < path.start) strokeDashoffset = path.totalLength;
  // Show completed path
  if (progress > path.end) strokeDashoffset = 0;

  return (
    <path
      d={path.d}
      fill={path.fill}
      stroke={path.stroke}
      strokeLinecap={path.strokeLinecap}
      strokeLinejoin={path.strokeLinejoin}
      strokeMiterlimit={path.strokeMiterlimit}
      strokeWidth={path.strokeWidth}
      strokeDashoffset={strokeDashoffset}
      strokeDasharray={path.totalLength}
      clipPath={`url(#${clipPath})`}
    />
  );
}

export default memo(TextPath);
