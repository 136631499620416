import React from 'react';
import Typography from './Typography';

const Global = () => (
  <>
    <Typography />
  </>
);

export default Global;
