import { post } from '../../util/fetchUtil';

export const fetchProjectGroups = async (dispatch) => {
  const res = await post('/project/getProjectGroups');

  return dispatch({
    type: 'FETCH_PROJECT_GROUPS',
    payload: res.groups,
  });
};

export const fetchAllProjects = async (
  { start, count, sortBy = 'alphabetical' },
  dispatch
) => {
  const res = await post('/project/getAll', { start, count, sortBy });

  dispatch({
    type: 'FETCH_ALL_PROJECTS',
    payload: res.projects,
  });

  dispatch({
    type: 'SET_TOTAL_PROJECTS',
    payload: res.total,
  });

  return null;
};

export const fetchSubscribedProjects = async (
  { start, count, sortBy },
  dispatch
) => {
  const res = await post('/project/getSubscribed', {
    start,
    count,
    sortBy,
  });

  dispatch({
    type: 'FETCH_SUBSCRIBED_PROJECTS',
    payload: res.projects,
  });

  dispatch({
    type: 'SET_TOTAL_PROJECTS',
    payload: res.total,
  });

  return null;
};

export const fetchRecentProjects = async (
  { start, count, sortBy },
  dispatch
) => {
  const res = await post('/project/getRecent', {
    start,
    count,
    sortBy,
  });

  if (res.projects) {
    dispatch({
      type: 'FETCH_RECENT_PROJECTS',
      payload: res.projects,
    });

    dispatch({
      type: 'SET_TOTAL_PROJECTS',
      payload: res.total,
    });
  }

  return null;
};

export const fetchProjectsByGroup = async (
  { projectGroupID, start, count, sortBy },
  dispatch
) => {
  const res = await post('/project/getGroup', {
    projectGroupID,
    start,
    count,
    sortBy,
  });

  dispatch({
    type: 'FETCH_PROJECTS_BY_GROUP',
    payload: res.projects,
  });

  dispatch({
    type: 'SET_TOTAL_PROJECTS',
    payload: res.total,
  });

  return null;
};

export const searchProjects = async (
  { text, start, count, sortBy },
  dispatch
) => {
  const res = await post('/project/searchProjects', {
    text,
    start,
    count,
    sortBy,
  });

  dispatch({
    type: 'SEARCH_PROJECTS',
    payload: res.projects,
  });

  dispatch({
    type: 'SET_TOTAL_PROJECTS',
    payload: res.total,
  });

  return null;
};

export const setProjects = (projects, dispatch) =>
  dispatch({
    type: 'SET_PROJECTS',
    payload: projects,
  });

export const setActiveProject = (project, dispatch) =>
  dispatch({
    type: 'SET_ACTIVE_PROJECT',
    payload: project,
  });

export const setActiveProjectByProjectID = async (
  projectID,
  projects,
  dispatch
) => {
  let project = projects.find((p) => p.projectID === projectID);
  if (!project) {
    const res = await post('/project/getProjectByProjectID', {
      projectID,
    });
    ({ project } = res);
  }

  return dispatch({
    type: 'SET_ACTIVE_PROJECT_BY_PROJECT_ID',
    payload: project,
  });
};

export const fetchActiveProject = async (projectID, dispatch) => {
  const { project } = await post('/project/getProjectByProjectID', {
    projectID,
  });

  return dispatch({
    type: 'FETCH_ACTIVE_PROJECT',
    payload: project,
  });
};

export const fetchProject = async (projectID, projects, dispatch) => {
  const { project } = await post('/project/getProjectByProjectID', {
    projectID,
  });
  const projectIndex = projects.findIndex((p) => p.projectID === projectID);
  if (projectIndex > -1) {
    projects.splice(projectIndex, 1, project);
  }

  // Update projects
  setProjects(projects, dispatch);

  return project;
};

export const fetchProjects = async (
  projectIDs,
  projects,
  activeProject,
  dispatch
) => {
  const res = await post('/project/getProjectsByProjectIDs', {
    projectIDs,
  });
  const updatedProjects = res.projects;

  // Update active project if it's one of the projects that was updated
  const project = updatedProjects.find(
    (p) => p.projectID === activeProject.projectID
  );
  if (project) setActiveProject(activeProject.projectID, dispatch);

  // Update other projects
  for (let i = 0; i < updatedProjects.length; i += 1) {
    const projectIndex = projects.findIndex(
      (p) => p.projectID === updatedProjects[i].projectID
    );
    // If projectIndex exists, splice in updated project
    if (projectIndex > -1) projects.splice(projectIndex, 1, updatedProjects[i]);
  }

  // Update projects
  setProjects(projects, dispatch);
};

export const setActiveProjectByNameOrID = async (
  projectIdentifier,
  projects,
  activeProject,
  dispatch
) => {
  // Only if this is not the current project
  if (
    activeProject.name !== projectIdentifier &&
    activeProject.projectID !== projectIdentifier
  ) {
    // Try to find project in current list of projects
    const project =
      projects.find((p) => p.name === projectIdentifier) ||
      projects.find((p) => p.projectID === projectIdentifier);
    if (!project) {
      // Otherwise get from db
      let res = null;
      // Get project by project ID if it exists as a number
      if (!Number.isNaN(projectIdentifier))
        res = await post('/project/getProjectByProjectID', {
          projectID: projectIdentifier,
        });
      else
        res = await post('/project/getProjectByName', {
          projectName: projectIdentifier,
        });

      // Set the active project
      if (res.project) {
        setActiveProject(res.project, dispatch);
      }

      return res.project;
    }

    setActiveProject(project, dispatch);
    return project;
  }

  return activeProject;
};

export const removeProject = (projectID, projects, dispatch) => {
  projects.splice(
    projects.findIndex((project) => project.projectID === projectID),
    1
  );
  setProjects(projects, dispatch);
};
