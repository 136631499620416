import fetchUtil from '../../util/fetchUtil';

export const send = (args) =>
  new Promise((resolve) => {
    const { recipientUserID = 0, action = '', actionData = {} } = args;

    fetchUtil
      .post('/notifications/createNotification', {
        userID: recipientUserID,
        action,
        data: actionData,
      })
      .then(() => {
        resolve();
      });
  });

export default {
  send,
};
