import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import styled from 'styled-components/macro';
import Label from '../Label';
import { FilterProps } from './Kit';

const CloseIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
  opacity: 0.5;

  cursor: pointer;

  :hover,
  :focus {
    opacity: 0.65;
  }
`;

const LabelContainer = styled.div`
  max-width: 218px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LabelAnimationContainer = styled(motion.div)`
  display: inline-block;
  margin-right: 10px;
`;

const StyledLabel = styled(Label)``;

type Props = {
  filter: FilterProps;
  onClick?: () => void;
  handleRemoveFilter?: (filter: FilterProps) => void;
};

const FilterTag: React.FC<Props> = (props: Props) => {
  const { filter, handleRemoveFilter, onClick } = props;
  return (
    <LabelContainer>
      <LabelAnimationContainer
        initial={{ y: -10 }}
        animate={{ y: 0 }}
        exit={{ y: -10 }}
        key={filter.value}
      >
        <StyledLabel color="blue" onClick={onClick || undefined}>
          <CloseIcon
            icon={['fas', 'times']}
            onClick={() =>
              handleRemoveFilter ? handleRemoveFilter(filter) : null
            }
          />
          {filter.name || filter.value}
        </StyledLabel>
      </LabelAnimationContainer>
      {/* ))} */}
    </LabelContainer>
  );
};

export default FilterTag;
