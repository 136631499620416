import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useContext } from 'react';

import { post } from '../../util/fetchUtil';

import { Store } from '../../state/store';
import { fetchActiveProject } from '../../state/projects/actions';

import Checkbox from '../Checkbox';

import {
  HeaderSection,
  SectionTitle,
  Icon,
} from '../views/ProjectContentsStyles';
import Box from '../Box';

function GroupsSection(props) {
  const {
    projectGroups,
    activeProject: { groups = [], projectID },
  } = props;

  const { dispatch } = useContext(Store);

  // Add / remove project to group when checked / unchecked
  const handleCheckboxChange = (e) => {
    const { name } = e.target;
    const group = projectGroups.find((g) => g.name === name);
    const existingGroupIndex = groups.findIndex(
      (g) => g === group.projectGroupID
    );

    let promise = null;
    // Add if it doesn't exist
    if (existingGroupIndex === -1) {
      promise = post('/project/addToGroup', {
        projectIDs: [projectID],
        projectGroupID: group.projectGroupID,
      });
    } else {
      // Remove if it already exist
      promise = post('/project/removeFromGroup', {
        projectIDs: [projectID],
        projectGroupID: group.projectGroupID,
      });
    }

    promise.then(() => fetchActiveProject(projectID, dispatch));
  };

  const newProjectGroups = projectGroups.map((group) => ({
    ...group,
    checked: !!groups.find((g) => g === group.projectGroupID),
  }));

  const groupComponents = newProjectGroups.map((group) => (
    <Box key={group.projectGroupID}>
      <StyledCheckbox
        name={group.name}
        checked={group.checked}
        onChange={handleCheckboxChange}
        label={group.name}
      />
    </Box>
  ));

  return (
    <Container>
      <HeaderSection>
        <SectionTitle>
          <Icon icon={['fad', 'folders']} />
          Folders
        </SectionTitle>
      </HeaderSection>
      <GroupsContainer>{groupComponents}</GroupsContainer>
    </Container>
  );
}

const Container = styled.div``;
const GroupsContainer = styled.div`
  margin-left: 15px;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

GroupsSection.propTypes = {
  activeProject: PropTypes.shape({
    groups: PropTypes.array,
    projectID: PropTypes.number,
  }),
  projectGroups: PropTypes.array,
};

GroupsSection.defaultProps = {
  projectGroups: [],
};

export default GroupsSection;
