import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React from 'react';

import { useState, useRef, useEffect } from 'react';

import { motion, AnimatePresence } from 'framer-motion';

import { stripTags } from '../../shared/utilities/strip';

import QuillEditor from '../QuillEditor';

import MentionPicker from '../MentionPicker';

/**
 * Component that has a textarea with ability to auto-insert matched options. Use the `@` to initiate matched options.
 */
function CommentTextArea(props) {
  const {
    options,
    value,
    placeholder,
    onSelect,
    onKeyDown,
    onChange,
    onEnterPress,
    quillEditorProps,
    darkMode,
  } = props;

  const [focusedIndex, setFocusedIndex] = useState(0);
  const containerRef = useRef();
  const updateCaret = useRef();

  /**
   * Handles clicking or pressing enter on a user from the list
   *
   * @param {Object} user
   * @memberof CommentTextArea
   */
  const handleSelectOption = (option) => {
    const { matchedText } = option;
    const trimmedMatchedText = matchedText.trim();

    const markupBefore = value.slice(0, value.lastIndexOf(trimmedMatchedText));
    const markupAfter = value.slice(
      value.lastIndexOf(trimmedMatchedText) + trimmedMatchedText.length,
      value.length
    );

    // Append full name after '@'
    const newText = `${markupBefore}${option.text} ${markupAfter}`;

    // Call parent method
    onSelect(newText, option);

    updateCaret.current = true;
  };

  /**
   * Uses regex pattern to match the @[USER_NAME]
   *
   * @param {String} string - string to search
   * @returns {Array} matchingOptions - an array of user objects that
   * match the current @[USER_NAME] text
   * @memberof CommentTextArea
   */
  const getMatchingOptions = (string) => {
    // Strip out any html tags
    const strippedText = stripTags(string);
    // Get all the @[name]s in the string, matches a string that starts with '@[word]'
    //    and does not end with '[space]'
    // OR matches anything that starts with '[space]@[word]' and does not end with '[space]'
    const pattern = /^@\w+[^\s]$|\s@\w+[^\s]$/g;
    const mentionName = pattern.exec(strippedText);

    let matchingOptions = [];
    if (mentionName) {
      matchingOptions = options
        .filter((option) =>
          option.name
            .toLowerCase()
            .includes(
              strippedText
                .toLowerCase()
                .slice(mentionName.index + 2, strippedText.length)
            )
        )
        .map((option) => ({
          ...option,
          matchedText: mentionName[0],
        }));
    }

    return matchingOptions;
  };

  /**
   * Handle Up, Down, and Enter key presses
   *
   * @param {Event Object} e
   * @memberof CommentTextArea
   */
  const handleKeyDown = (e) => {
    const matchingOptions = getMatchingOptions(value);

    let newFocusedIndex = focusedIndex;
    if (focusedIndex >= matchingOptions.length)
      newFocusedIndex = matchingOptions.length;
    // If there are matching users, adjust focused index of MentionPicker
    if (matchingOptions.length > 0) {
      switch (e.keyCode) {
        case 38: // Up
          e.preventDefault();
          if (!(focusedIndex - 1 < 0)) newFocusedIndex -= 1;
          break;
        case 40: // Down
          e.preventDefault();
          if (!(focusedIndex + 1 >= matchingOptions.length))
            newFocusedIndex += 1;
          break;
        case 13: // Enter
          e.preventDefault();
          e.stopPropagation();
          handleSelectOption(matchingOptions[focusedIndex]);
          break;
        default:
          // Otherwise call parent onKeyDown method
          onKeyDown(e);
          break;
      }

      setFocusedIndex(newFocusedIndex);
    } else {
      // Call parent onKeyDown method
      onKeyDown(e);
    }
  };

  useEffect(() => {
    if (updateCaret.current) {
      setTimeout(() => {
        // HACK: NOT SURE HOW ELSE TO DO THIS
        const node = document.getSelection().focusNode;
        if (node) {
          document.getSelection().setPosition(node, node.textContent.length);
        }
      }, 0);

      updateCaret.current = false;
    }
    // eslint-disable-next-line
  }, [updateCaret.current]);

  const matchingOptions = getMatchingOptions(value);

  return (
    <Container ref={containerRef} onKeyDown={handleKeyDown}>
      <QuillEditor
        onChange={onChange}
        placeholder={placeholder}
        toolbar={[
          'bold',
          'italic',
          'link',
          { list: 'bullet' },
          { list: 'ordered' },
          'image',
        ]}
        value={value}
        onEnterPress={matchingOptions.length ? () => {} : onEnterPress}
        darkMode={darkMode}
        {...quillEditorProps}
      />
      <AnimatePresence>
        {matchingOptions.length > 0 && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10, transition: { easing: 'easeOut' } }}
          >
            <MentionPicker
              matchingOptions={matchingOptions}
              focusedIndex={focusedIndex}
              onSelect={handleSelectOption}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Container>
  );
}

const { func, string, number, array, shape, bool } = PropTypes;

CommentTextArea.propTypes = {
  value: string,
  placeholder: string,
  onSelect: func,
  onChange: func,
  onFocus: func,
  onKeyDown: func,
  rows: number,
  options: array,
  quillEditorProps: shape({}),
  onEnterPress: func,
  darkMode: bool,
};

CommentTextArea.defaultProps = {
  value: '',
  placeholder: '',
  rows: 2,
  options: [],
  onSelect: () => {},
  onKeyDown: () => {},
  onChange: () => {},
  onFocus: () => {},
  onEnterPress: () => {},
  quillEditorProps: {},
  darkMode: false,
};

const Container = styled.div`
  position: relative;

  textarea {
    width: 100%;
    line-height: 1.5em;
  }
`;

export default CommentTextArea;
