import styled, { css } from 'styled-components/macro';

import { colors } from '../styles/colors';

export const Container = styled.div`
  display: flex;
`;
export const NotificationContent = styled.div`
  flex: 3;

  margin: 0;

  margin-left: 10px;

  line-height: 1.1em;
`;

const Text = styled.p`
  margin: 0;
  color: white;
`;
export const NotificationAction = styled(Text)`
  opacity: 0.75;
  display: inline;
`;
export const UserName = styled(Text)`
  font-weight: bold;
  display: inline;

  ${(props) =>
    props.isActive &&
    css`
      color: ${colors['light-teal-30']};
    `}
`;
export const ProjectName = styled(Text)`
  font-weight: bold;
  display: inline;

  ${(props) =>
    props.isActive &&
    css`
      color: ${colors['light-orange-30']};
    `}
`;
export const FileName = styled(Text)`
  font-size: 14px;
  color: white;
  opacity: 0.5;

  margin-top: 5px;
`;
