import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components/macro';

import React, { useState, useRef, useContext, useEffect } from 'react';

import { Route, useRouteMatch } from 'react-router-dom';

import { Store } from '../../state/store';
import { setActiveProjectByNameOrID } from '../../state/projects/actions';
import { setActiveFile } from '../../state/scene/actions';

import {
  video as videoFileTypes,
  image as imageFileTypes,
} from '../../shared/utilities/fileTypes';

import layersPanelController from '../../app/layersPanelController';

import Scene from '../project/Scene';
import EditorDashboard from '../editor/EditorDashboard';
import EditorWrapper from '../editor/EditorWrapper';
import VideoViewer from '../project/VideoViewer';
import ImageViewer from '../project/ImageViewer';
import Timeline from '../project/Timeline';
import CommentPanel from '../comments/CommentPanel';
import LayersPanel from '../layers/LayersPanel';

import { colors, darken } from '../styles/colors';
import elevations from '../styles/elevations';
import WarningDialog from '../dialogs/WarningDialog';
import useModal from '../../app/hooks/useModal';
import Dialog from '../Dialog';
import OsmosisDashboard from '../osmosis/OsmosisDashboard';
import Button from '../RebassButton';
import { Box } from '../Box';

export const getErrorString = (errors) =>
  errors.reduce(
    (str, curr) => `
${str}
· ${curr}`,
    ''
  );

function Project() {
  const context = useContext(Store);
  const {
    state: { isFullScreen, sceneMode, activeProject, projects, activeFile },
    dispatch,
  } = context;

  const [timelineVisible, setTimelineVisible] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [leftPanelVisible, setLeftPanelVisible] = useState(true);

  const errorDialog = useModal();

  const timelineTimeout = useRef(null);

  const { projectID } = useRouteMatch('/project/:projectID').params;
  const fileRouteMatch = useRouteMatch('/project/:projectID/files/:fileID');

  const handleHideTimeline = () => {
    // Hide the timeline after 2 seconds of not moving the mouse
    timelineTimeout.current = setTimeout(() => {
      setTimelineVisible(false);
    }, 2000);
  };

  const showTimeline = () => {
    clearTimeout(timelineTimeout.current);
    // If panel isn't already showing
    if (!timelineVisible) setTimelineVisible(true);
    // If the panel's already showing, then clear the timer

    handleHideTimeline();
  };

  const handleMouseMove = () => {
    if (isFullScreen) showTimeline();
  };

  useEffect(() => {
    // Set the project
    setActiveProjectByNameOrID(projectID, projects, activeProject, dispatch);
    // eslint-disable-next-line
  }, [projectID]);

  // Make sure timeline is always visible if we're not fullscreen
  useEffect(() => {
    if (!isFullScreen) {
      setTimelineVisible(true);
      clearTimeout(timelineTimeout.current);
    } else {
      handleHideTimeline();
    }
  }, [isFullScreen]);

  useEffect(() => {
    if (isFullScreen) document.addEventListener('mousemove', handleMouseMove);
    // Cleanup
    return () => document.removeEventListener('mousemove', handleMouseMove);
    // eslint-disable-next-line
  }, [timelineVisible, isFullScreen]);

  const handleLoadError = (errors) => {
    console.error(errors);
    setErrorMessage(getErrorString(errors));
    errorDialog.show();
  };

  /**
   * Loads a file depending on its type
   *
   * @param {Object} file
   */
  const handleLoadFile = async (file) => {
    setIsLoading(true);
    setActiveFile(file, dispatch);

    if (file.type === 'svg') {
      await layersPanelController.loadSvgFromFile(file, context, {
        onError: handleLoadError,
      });
    } else if (videoFileTypes.find((type) => type === file.type)) {
      await layersPanelController.loadVideoFromFile(file, context);
    } else if (imageFileTypes.find((type) => type === file.type)) {
      await layersPanelController.loadImageFromFile(file, context);
    }

    setIsLoading(false);
  };

  const routeFileID =
    fileRouteMatch && parseInt(fileRouteMatch.params.fileID, 10);

  // Loads the file depending on the route
  useEffect(() => {
    if (
      !activeFile.fileID ||
      // Only reload if active file is new
      (fileRouteMatch &&
        activeFile.fileID.toString() !==
          fileRouteMatch.params.fileID.toString())
    ) {
      const file = activeProject.files.find((f) => f.fileID === routeFileID);
      if (file) handleLoadFile(file);
    }
    // eslint-disable-next-line
  }, [activeProject, routeFileID]);

  // Get comments for file
  const comments =
    activeProject.comments &&
    activeProject.comments.filter(
      (comment) => comment.fileID === activeFile.fileID
    );

  return (
    <Container>
      <Dialog
        isVisible={errorDialog.isVisible}
        handleHideDialog={() => errorDialog.hide()}
      >
        <WarningDialog
          color={colors.red}
          header={<span style={{ color: colors.red }}>Uh oh!</span>}
        >
          {errorMessage}
        </WarningDialog>
      </Dialog>

      <>
        <Route path="/project/:projectID/osmosis" exact>
          <div style={{ width: '100%' }}>
            <OsmosisDashboard projectID={projectID} />
          </div>
        </Route>
        <Route path="/project/:projectID/editor/dashboard" exact>
          <div style={{ width: '100%' }}>
            <EditorDashboard />
          </div>
        </Route>
        <Route
          path="/project/:projectID/editor/scene/:sceneID"
          component={EditorWrapper}
        />
        <Route path={['/project/:projectID/files/']}>
          <LeftPanelContainer visible={!isFullScreen}>
            <Button
              icon
              variant="transparent"
              color="light-3"
              onClick={() => setLeftPanelVisible(!leftPanelVisible)}
              sx={{
                display: 'none',
                '@media only screen and (max-width: 769px)': {
                  display: 'block',
                },
              }}
            >
              <FontAwesomeIcon
                icon={['fas', `chevron-${leftPanelVisible ? 'up' : 'down'}`]}
              />
            </Button>
            {leftPanelVisible && (
              <Box>
                <Route
                  path={[
                    '/project/:projectID/files/:fileID',
                    '/project/:projectID/files/',
                  ]}
                  exact
                >
                  <PanelContainer>
                    <LayersPanel handleLoadFile={handleLoadFile} />
                  </PanelContainer>
                </Route>
                <Route path="/project/:projectID/files/:fileID/comments">
                  <PanelContainer>
                    <CommentPanel
                      handleLoadFile={handleLoadFile}
                      comments={comments}
                    />
                  </PanelContainer>
                </Route>
              </Box>
            )}
          </LeftPanelContainer>
          <RightContainer>
            <SceneContainer visible={sceneMode === 'svg'}>
              <Scene isLoading={isLoading} />
              <TimelineContainer fixed={isFullScreen} visible={timelineVisible}>
                <Timeline
                  handleLoadError={handleLoadError}
                  comments={comments}
                  setVisible={setTimelineVisible}
                />
              </TimelineContainer>
              <div id="ui-Timeline" />
            </SceneContainer>
            <ViewerContainer visible={sceneMode === 'video'}>
              {activeFile.fileID && <VideoViewer comments={comments} />}
            </ViewerContainer>
            <ViewerContainer visible={sceneMode === 'image'}>
              <ImageViewer />
            </ViewerContainer>
          </RightContainer>
        </Route>
      </>
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
  height: 100%;

  display: flex;
`;

const LeftPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${darken(colors.night, 10)};
  overflow: auto;

  width: 350px;
  min-width: 350px;

  background-color: ${colors.night};

  @media only screen and (max-width: 769px) {
    position: absolute;
    z-index: 1;
    overflow: auto;
    max-height: 60vh;
    border-radius: 5px;
    margin: 10px;
    box-shadow: ${elevations[3]};
  }

  ${(props) =>
    !props.visible &&
    css`
      display: none;
    `}
`;

const RightContainer = styled.div`
  flex: 1;

  position: relative;
`;

const SceneContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  flex: 1;

  ${(props) =>
    !props.visible &&
    css`
      display: none;
    `}
`;

const ViewerContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;

  background-color: ${colors['light-grey-50']};

  ${(props) =>
    !props.visible &&
    css`
      display: none;
    `}
`;

const PanelContainer = styled.div``;

const TimelineContainer = styled.div`
  display: none;

  ${(props) =>
    props.visible &&
    css`
      display: initial;
    `}

  ${(props) =>
    props.fixed &&
    css`
      position: fixed;

      width: 100%;
      bottom: 0;
    `}
`;

Project.propTypes = {
  propName: PropTypes.string,
};

Project.defaultProps = {
  propName: '',
};

export default Project;
