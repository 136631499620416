import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

import React, { useState, useContext } from 'react';

import { post } from '../../util/fetchUtil';

import { Store } from '../../state/store';
import { fetchActiveProject } from '../../state/projects/actions';
import { colors, lighten } from '../styles/colors';
import { P } from '../styles/typography';

import { updateCardDue } from '../../app/utilities/trello';

import Label from '../Label';
import DateLabel from '../DateLabel';

import { SectionTitle, Icon } from '../views/ProjectContentsStyles';

import Button from '../RebassButton';
import InputConfirm from '../InputConfirm';
import { Flex } from '../Box';
import { ProjectProps } from '../editor/types/ProjectProps';

type Props = {
  activeProject: ProjectProps;
  cardData: {
    due: string;
    dueComplete: boolean;
    id: string;
  };
};
function DueDateSection(props: Props) {
  const { activeProject, cardData } = props;

  const { projectID } = activeProject;
  const due = activeProject.data?.due || {};

  const { dispatch } = useContext(Store);
  const [isEditing, setIsEditing] = useState(false);
  const [newDueDate, setNewDueDate] = useState(due.date || '');

  // NOTE: Removing this feature until it can be refactored because it's causing too
  // many issues.
  // useEffect(() => {
  // If we have a due date but the Trello card doesn't have a matching due date
  // if (due.date && cardData) {
  //   if (
  //     // If the trello card's due date doesn't match
  //     (cardData.due &&
  //       moment(cardData.due).format('MM-DD-YYYY') !==
  //         moment(due.date).format('MM-DD-YYYY')) ||
  //     // Or if we've marked ours as completed but the trello card hasn't been completed
  //     (!!due.completedOn && !cardData.dueComplete) ||
  //     // Or if there's no due date
  //     !cardData.due
  //   ) {
  //     // Update the Trello card
  //     trello.updateCardDue(cardData.id, due.date, !!due.completedOn);
  //   }
  // } else if (!due.date && cardData && cardData.due) {
  //   console.log('Sync from Trello');
  // }
  // }, [cardData, activeProject]);

  const handleSave = () => {
    post('/project/addDueDate', {
      projectID,
      dueDate: newDueDate,
    }).then(() => {
      fetchActiveProject(projectID, dispatch);

      // Update the Trello card
      updateCardDue(cardData.id, newDueDate, false);

      setIsEditing(false);
      setNewDueDate('');
    });
  };

  const handleRemoveDueDate = () => {
    post('/project/addDueDate', {
      projectID,
      dueDate: '',
    }).then(() => {
      updateCardDue(cardData.id, undefined, false);

      fetchActiveProject(projectID, dispatch);

      setIsEditing(false);
      setNewDueDate('');
    });
  };

  const handleMarkAsComplete = () => {
    post('/project/dueDateComplete', {
      projectID,
    }).then(() => {
      updateCardDue(cardData.id, due.date, true);

      fetchActiveProject(projectID, dispatch);
    });
  };

  return (
    <Container>
      <SectionTitle>
        <Icon icon={['fad', 'calendar']} />
        Due Date
      </SectionTitle>
      <ContentContainer>
        {due.completedOn && !isEditing && (
          <FlexContainer>
            <Completed size={16} color="grey">
              <CheckIcon icon={['fas', 'check']} />
              <CompletedText>Completed on {due.completedOn}</CompletedText>
            </Completed>
            <Actions>
              <Button
                onClick={() => setIsEditing(!isEditing)}
                className="icon transparent"
              >
                <FontAwesomeIcon icon={['fad', 'edit']} />
              </Button>
            </Actions>
          </FlexContainer>
        )}
        {due.date && !due.completedOn && !isEditing && (
          <FlexContainer>
            <DateLabel date={due.date} showIcon={false} size={18} />
            <Actions>
              <Button onClick={handleMarkAsComplete} bg="positive">
                <FontAwesomeIcon icon={['fad', 'check']} />
              </Button>
              <Button
                onClick={() => setIsEditing(!isEditing)}
                variant="outline"
                color="dark-6"
              >
                <FontAwesomeIcon icon={['fad', 'edit']} />
              </Button>
              <Button
                onClick={handleRemoveDueDate}
                variant="outline"
                color="dark-6"
              >
                <FontAwesomeIcon icon={['fad', 'trash-alt']} />
              </Button>
            </Actions>
          </FlexContainer>
        )}
        {!isEditing && !due.date && (
          <Button
            variant="outline"
            color="dark-6"
            onClick={() => setIsEditing(!isEditing)}
            startIcon={<FontAwesomeIcon icon={['fas', 'plus']} />}
          >
            Add due date
          </Button>
        )}
        {isEditing && (
          <InputConfirm
            inputType="date"
            inputValue={newDueDate}
            handleInputChange={(e) => setNewDueDate(e.target.value)}
            handleCancelClick={() => setIsEditing(false)}
            handleSaveClick={handleSave}
          />
        )}
      </ContentContainer>
    </Container>
  );
}

const Container = styled.div``;
const CompletedText = styled(P)`
  color: ${lighten(colors.grey, 20)};
  margin-left: 10px;
`;

const ContentContainer = styled.div`
  margin-top: 10px;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Completed = styled(Label)`
  display: inline-flex;
  align-items: center;
`;
const CheckIcon = styled(FontAwesomeIcon)`
  color: ${colors.green};
`;

const Actions = styled(Flex)`
  margin-left: 10px;
  opacity: 0;
  transform: translateX(-10px);

  transition: 0.1s all ease-in-out;

  ${FlexContainer}:hover & {
    opacity: 1;
    transform: translateX(0px);
  }

  > * {
    margin-right: 10px;
  }
`;

DueDateSection.defaultProps = {
  activeProject: {
    data: {
      due: {},
    },
  },
};

export default DueDateSection;
