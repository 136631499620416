import React, { useState, useContext } from 'react';

import { AnimatePresence } from 'framer-motion';
import $ from 'jquery';

import moment from 'moment';

import fetchUtil from '../../util/fetchUtil';

import { Store } from '../../state/store';
import { fetchNotifications } from '../../state/notifications/actions';
import { fetchActiveProject } from '../../state/projects/actions';

import notifications from '../../app/notifications/notifications';
import { getArrayString } from '../../utilities/array';

import CommentTextArea from '../comments/CommentTextArea';
import ActivityBoxComment from './ActivityBoxComment';
import ActivityBoxFile from './ActivityBoxFile';

import {
  Container,
  StyledAvatar,
  CommentTextAreaContainer,
  CommentBox,
  ProjectCommentsContainer,
  ActivityAnimation,
} from './ActivityBoxStyles';

/**
 * List of activity for the project, including comments
 *
 * @class ActivityBox
 * @extends {Component}
 */
function ActivityBox() {
  const {
    state: { activeProject, user, team },
    dispatch,
  } = useContext(Store);

  const [commentText, setCommentText] = useState('');
  const [mentionedUsers, setMentionedUsers] = useState([]);

  /**
   * Called whenever the comment input changes
   *
   * @param {Event Object} e
   * @memberof ActivityBox
   */
  const handleCommentChange = (content, delta, source, editor) => {
    setCommentText(content);
  };

  /**
   * Delete a comment from the database
   *
   * @param {Number} commentID
   * @returns
   * @memberof ActivityBox
   */
  const handleDeleteComment = (commentID) => () => {
    fetchUtil
      .post('/comments/deleteComments', {
        commentIDs: [commentID],
      })
      .then(() => {
        fetchActiveProject(activeProject.projectID, dispatch);
      });
  };

  /**
   * Append the @[NAME] of the original user to the comment input
   *
   * @param {Object} user
   * @returns
   * @memberof ActivityBox
   */
  const handleReplyClick = (replyUser) => () => {
    const $commentText = $(commentText || '<p></p>');
    $commentText.append(
      `<span> @${replyUser.givenName} ${replyUser.familyName}</span>`
    );
    const newCommentText = $commentText[0].outerHTML;

    if (!mentionedUsers.find((u) => u.userID === replyUser.userID)) {
      const newMentionedUsers = [...mentionedUsers, replyUser];
      setMentionedUsers(newMentionedUsers);
    }

    setCommentText(newCommentText);
  };

  /**
   * Update the text with @[NAME] and add the mentioned user to the mentionedUsers array
   *
   * @param {String} text
   * @param {Object} mentionedUser
   * @memberof ActivityBox
   */
  const handleSelectMention = (text, option) => {
    // Override if it has its own onClick handler
    if (option.onClick) {
      option.onClick(text, option);
    } else {
      // Add new mentioned user if it doesn't already exist in list
      const newMentionedUsers = mentionedUsers;
      if (!mentionedUsers.find((u) => u.userID === option.userID)) {
        newMentionedUsers.push(option);
      }

      setCommentText(text);
      setMentionedUsers(newMentionedUsers);
    }
  };

  /**
   * Update the comment text in the Database
   *
   * @param {Number} commentID
   * @param {String} newText
   * @memberof ActivityBox
   */
  const handleUpdateComment = (commentID, newText) => {
    const { projectID } = activeProject;

    fetchUtil
      .post('/comments/updateCommentText', {
        commentID,
        text: newText,
      })
      .then(() => {
        // Update projects
        fetchActiveProject(projectID, dispatch);
      });
  };

  /**
   * Save the comment to the database
   *
   * @memberof ActivityBox
   */
  const handleSaveComment = () => {
    const { projectID } = activeProject;
    const { userID } = window;

    if (commentText === '') return;

    const data = {
      text: commentText,
      status: 0,
    };

    fetchUtil
      .post('/comments/saveComment', {
        projectID,
        fileID: -1, // Set fileID to -1 because there's no associated file
        data,
      })
      .then((res) => {
        setCommentText('');
        setMentionedUsers([]);

        // Send mention notifications
        const promises = [];
        for (let i = 0; i < mentionedUsers.length; i += 1) {
          promises.push(
            notifications.send({
              recipientUserID: mentionedUsers[i].userID,
              action: 'projectContentsMention',
              actionData: {
                commentingUserID: userID,
                projectID,
                commentID: res.comment.commentID,
                commentText: res.comment.data.text,
              },
            })
          );
        }

        Promise.all(promises).then(() => {
          fetchNotifications(dispatch);
        });

        // Update projects view
        fetchActiveProject(projectID, dispatch);
      });
  };

  /**
   * Register shortcuts
   *
   * @param {Function} save - save function to use when Enter is pressed
   * @returns
   * @memberof ActivityBox
   */
  const handleKeyDown = (save) => (event) => {
    const { keyCode, shiftKey } = event;
    switch (keyCode) {
      case 13:
        if (!shiftKey) {
          event.preventDefault();

          save();
        }
        break;
      default:
        break;
    }
  };

  // Generic array to hold activities
  const activities = [];

  // Sort files into groups based on the day they were uploaded and the user that uploaded them
  const { files } = activeProject;
  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];

    // Group by entries that are within the same hour of the day
    const index = activities.findIndex(
      (component) =>
        moment(component.date).format('YYYY-MM-DD HH') ===
        moment(file.createdOn).format('YYYY-MM-DD HH')
    );
    // If an entry for that day isn't already in the array, add one
    if (
      index > -1 &&
      activities[index].userID === (file.data.user && file.data.user.id)
    ) {
      activities[index].files.push(file);
    } else {
      activities.push({
        type: 'files',
        date: moment(file.createdOn).format(),
        userID: (file.data.user && file.data.user.id) || -1,
        files: [file],
      });
    }
  }

  // Add comments to activities array
  if (activeProject.comments) {
    activeProject.comments.forEach((comment) => {
      activities.push({
        type: 'comment',
        date: comment.createdOn,
        comment,
      });
    });
  }

  const variants = {
    open: { opacity: 1, height: 'auto' },
    collapsed: { opacity: 0, height: 0, transition: { easing: 'easeOut' } },
  };

  const options = team.map((u) => ({
    ...u,
    id: u.userID,
    name: u.fullName,
    text: `@${u.fullName}`,
    subheader: u.roles ? getArrayString(u.roles, ' ,') : null,
  }));

  if (activeProject.members) {
    // Add option for pinging everyone in the project
    options.push({
      name: 'Here',
      id: 0,
      subheader: getArrayString(
        activeProject.members.map((m) => m.givenName),
        ' '
      ),
      text: activeProject.members.reduce(
        (previous, current, index) =>
          `${previous}${current.givenName} ${current.familyName}${
            index < activeProject.members.length - 1 ? ' @' : ''
          }`,
        ''
      ),
      onClick(text) {
        if (activeProject.members) {
          // Add everyone in the project
          const newMentionedUsers = Array.from(mentionedUsers);
          activeProject.members.forEach((member) => {
            newMentionedUsers.push(member);
          });

          setCommentText(text);
          setMentionedUsers(newMentionedUsers);
        }
      },
    });
  }

  const activityComponents = activities
    // Sort by date
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .map((activity) => {
      // Render comment types (only if comment file is not attached to a project)
      if (activity.type === 'comment' && activity.comment.fileID === -1) {
        const { comment } = activity;
        return (
          <ActivityAnimation
            key={comment.commentID}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={variants}
          >
            <ActivityBoxComment
              givenName={comment.data.user.givenName}
              createdOn={comment.createdOn}
              text={comment.data.text}
              user={comment.data.user}
              commentID={comment.commentID}
              avatar={comment.data.user.avatar}
              handleReplyClick={handleReplyClick}
              handleDeleteComment={handleDeleteComment}
              handleUpdateComment={handleUpdateComment}
              handleKeyDown={handleKeyDown}
            />
          </ActivityAnimation>
        );
        // Render file upload type
      } else if (activity.type === 'files') {
        return (
          <ActivityAnimation
            key={activity.date}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={variants}
          >
            <ActivityBoxFile files={activity.files} userID={activity.userID} />
          </ActivityAnimation>
        );
      }

      return null;
    });

  return (
    <Container>
      <CommentBox>
        <StyledAvatar size={35} src={user.avatar} />
        <CommentTextAreaContainer>
          <CommentTextArea
            rows={1}
            placeholder="Write a comment..."
            value={commentText}
            onSelect={handleSelectMention}
            onChange={handleCommentChange}
            options={options}
            onEnterPress={handleSaveComment}
          />
        </CommentTextAreaContainer>
      </CommentBox>
      <ProjectCommentsContainer>
        {!!activityComponents.length && (
          <AnimatePresence initial={false}>
            {activityComponents}
          </AnimatePresence>
        )}
      </ProjectCommentsContainer>
    </Container>
  );
}

ActivityBox.propTypes = {};

ActivityBox.defaultProps = {};

export default ActivityBox;
