import React from 'react';
import videojs from 'video.js';
import ReactDOM from 'react-dom';

/**
 * commentBar.js
 *
 * Here is where we register a Video JS Component and
 * mount the React component to it when the player is ready.
 */
import CommentBar from '../../ui/videojs/CommentBar';

const vjsComponent = videojs.getComponent('Component');

class commentBar extends vjsComponent {
  constructor(player, options) {
    super(player, options);
    this.player = player;

    /* Bind the current class context to the mount method */
    this.render = this.render.bind(this);

    /* When player is ready, call method to mount React component */
    // player.ready(() => {
    //   this.mount();
    // });

    // Wait until data is loaded to mount
    player.on('loadeddata', () => {
      this.render(options);
    });

    /* Remove React root when component is destroyed */
    this.on('dispose', () => {
      ReactDOM.unmountComponentAtNode(this.el());
    });
  }

  /**
   * Render the component via ReactDOM
   */
  render(args) {
    ReactDOM.render(
      <CommentBar
        vjsComponent={this}
        player={this.player}
        comments={args.comments}
      />,
      this.el()
    );
  }
}

/**
 * Make sure to register the vjsComponent so Video JS knows it exists
 */
vjsComponent.registerComponent('CommentBar', commentBar);

export default commentBar;
