import styled, { css } from 'styled-components/macro';

import {
  Box as RebassBox,
  Flex as RebassFlex,
  BoxProps,
  FlexProps,
} from 'rebass/styled-components';

type CustomFlexProps = FlexProps & {
  c?: boolean;
  cx?: boolean;
  cy?: boolean;
};

// A Utility container with basic quick-access attributes
export const Box = styled(RebassBox)<BoxProps>``;

export const Flex = styled(RebassFlex)<CustomFlexProps>`
  ${(props) =>
    props.c &&
    css`
      justify-content: center;
      align-items: center;
    `}

  ${(props) =>
    props.cx &&
    css`
      justify-content: center;
    `}
  
  ${(props) =>
    props.cy &&
    css`
      align-items: center;
    `}
`;

export default Box;
