import React, { ReactNode } from 'react';
import moment from 'moment';

import { Flex } from 'rebass';
import { goToUrl } from '../../shared/links';
import { colors, lighten } from '../styles/colors';
import { H2 } from '../styles/typography';

import {
  Wrapper,
  HFlexContainer,
  LowerContainer,
  ProjectContainer,
  LeftContainer,
  ProjectName,
  LastModified,
  AvatarContainer,
  AvatarImage,
  IconPreviewContainer,
  ProjectInfoContainer,
} from './ProjectCardStyles';

import Box from '../Box';
import { ProjectHitProps } from './ProjectHits';

type Props = {
  isSelected: boolean;
  selectionActive: boolean;
  project: ProjectHitProps;
  thumbnail: string;
  projectID: number;
  icons?: ReactNode[];
  onProjectSelectClick: (projectID: number) => void;
  handleProjectDragStart: (e: React.DragEvent, projectID: number) => void;
};

function ProjectCard(props: Props) {
  const {
    isSelected,
    selectionActive,
    project,
    thumbnail,
    onProjectSelectClick,
    handleProjectDragStart,
    projectID,
    icons = [],
    project: { createdOn, name, members },
  } = props;

  /**
   * Opens the selected project contents
   * @memberof Home
   */
  const handleProjectContentsClick = (e: React.MouseEvent) => {
    if (!selectionActive) {
      e.stopPropagation();
      goToUrl({ projectID, view: 'projectContents' });
    }
  };

  /**
   * Opens the selected project
   * @memberof Home
   */
  const handleProjectClick = (e: React.MouseEvent) => {
    if (!selectionActive) {
      e.stopPropagation();
      goToUrl({ projectID, view: 'project' });
    }
  };

  const handleDragStart = (e: React.DragEvent) => {
    handleProjectDragStart(e, projectID);
  };

  const handleSelectClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    // Pass selected project back
    onProjectSelectClick(projectID);
  };

  const avatars = members.map((member, index) => (
    <AvatarImage key={member.userID} positionIndex={index}>
      <img alt="Avatar" src={member.avatar} />
    </AvatarImage>
  ));

  return (
    <Wrapper
      isSelected={isSelected}
      onClick={selectionActive ? handleSelectClick : () => {}}
    >
      <ProjectContainer
        onClick={handleProjectClick}
        isSelected={isSelected}
        draggable={true}
        onDragStart={handleDragStart}
      >
        <Flex
          alignItems="center"
          justifyContent="center"
          height={150}
          mb={10}
          overflow="hidden"
        >
          {thumbnail && (
            <img
              style={{
                objectFit: 'contain',
                maxWidth: '100%',
                maxHeight: '100%',
              }}
              alt="Thumbnail"
              src={thumbnail}
            />
          )}
          {!thumbnail && (
            <Flex
              overflow="auto"
              alignItems="center"
              justifyContent="center"
              sx={{
                backgroundColor: lighten(colors.grey, 50),
                width: '100%',
                height: '100%',
                textAlign: 'center',
                padding: 10,
              }}
            >
              <H2
                style={{
                  color: colors['light-grey-30'],
                  height: '100%',
                  overflow: 'auto',
                }}
              >
                {project.name}
              </H2>
            </Flex>
          )}
        </Flex>
        <Box style={{ padding: 10 }}>
          <HFlexContainer>
            <LeftContainer>
              <ProjectInfoContainer>
                <ProjectName title={name}>{name}</ProjectName>
                <LastModified>
                  {moment(createdOn).format('MM-DD-YYYY')}
                </LastModified>
              </ProjectInfoContainer>
              {/* <SelectIcon>
                <FontAwesomeIcon
                  onClick={handleSelectClick}
                  icon={['fad', 'check-circle']}
                />
              </SelectIcon> */}
            </LeftContainer>
          </HFlexContainer>

          <LowerContainer onClick={handleProjectContentsClick}>
            <AvatarContainer>{avatars}</AvatarContainer>
            <IconPreviewContainer>{icons}</IconPreviewContainer>
          </LowerContainer>
        </Box>
      </ProjectContainer>
    </Wrapper>
  );
}

ProjectCard.defaultProps = {
  isSelected: false,
  selectionActive: false,
  handleProjectDragStart: () => {},
};

export default ProjectCard;
