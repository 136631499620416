import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useEffect } from 'react';

import Content from './Content';
import SideBar from './SideBar';
import data from './data';

const Manual = () => {
  const goToSection = (id) => {
    return () => {
      window.location.hash = id;
    };
  };

  useEffect(() => {
    if (window.location.hash) {
      // HACK
      const { hash } = window.location;
      window.location.hash = '';
      window.location.hash = hash;
    }
  }, []);

  return (
    <Container>
      <SideBar sections={data} goToSection={goToSection} />
      <ContentContainer>
        <Content sections={data} />
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  display: flex;
`;

const ContentContainer = styled.div`
  flex: 1;

  padding: 15px 30px;

  overflow-y: auto;
`;

Manual.propTypes = {
  propName: PropTypes.string,
};

Manual.defaultProps = {
  propName: '',
};

export default Manual;
