import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import DurationEdit from '../DurationEdit';
import { Label  } from '../properties/PropertyStyles';

/**
 * Settings for the timeline, e.g. duration
 */
function TimelineSettingsDialog(props) {
  const { duration, handleUpdateDuration } = props;

  return (
    <Container>
      <Header style={{ color: 'white' }}>Timeline Settings</Header>
      <Body>
        <Label style={{ marginBottom: 10 }}>Duration</Label>
        <DurationEdit
          duration={duration}
          onUpdate={(newVal) => handleUpdateDuration(newVal)}
        />
      </Body>
      <Footer>
        {/* <FooterButton className="box green thin" color="white">
          Save
        </FooterButton>
        <FooterButton className="box thin" onClick={handleToggleDialog}>
          Cancel
        </FooterButton> */}
      </Footer>
    </Container>
  );
}

const Container = styled.div``;

const Header = styled.h2`
  margin: 0;
  margin-bottom: 20px;

  color: white;
  opacity: 0.75;
`;

const Body = styled.div`
  margin-bottom: 20px;
`;

const Footer = styled.div``;

const { number, func } = PropTypes;
TimelineSettingsDialog.propTypes = {
  handleUpdateDuration: func.isRequired,
  duration: number.isRequired,
};

TimelineSettingsDialog.defaultProps = {};

export default TimelineSettingsDialog;
