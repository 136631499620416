export default {
  0: 'none',
  1: '0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.03), 0 1px 3px 0 rgba(0,0,0,.04)',
  2: '0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.03), 0 1px 5px 0 rgba(0,0,0,.04)',
  3: '0 3px 3px -2px rgba(0,0,0,.2), 0 3px 4px 0 rgba(0,0,0,.03), 0 1px 8px 0 rgba(0,0,0,.04)',
  4: '0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.03), 0 1px 10px 0 rgba(0,0,0,.04)',
  5: '0 3px 5px -1px rgba(0,0,0,.2), 0 5px 8px 0 rgba(0,0,0,.03), 0 1px 14px 0 rgba(0,0,0,.04)',
  6: '0 3px 5px -1px rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.03), 0 1px 18px 0 rgba(0,0,0,0.04)',
  7: '0 4px 5px -2px rgba(0,0,0,0.2), 0 7px 10px 1px rgba(0,0,0,0.03), 0 2px 16px 1px rgba(0,0,0,0.04)',
  8: '0 5px 5px -3px rgba(0,0,0,0.2), 0 8px 10px 1px rgba(0,0,0,0.03), 0 3px 14px 2px rgba(0,0,0,0.04)',
  9: '0 5px 6px -3px rgba(0,0,0,0.2), 0 9px 12px 1px rgba(0,0,0,0.03), 0 3px 16px 2px rgba(0,0,0,0.04)',
  10: '0 6px 6px -3px rgba(0,0,0,.2), 0 10px 14px 1px rgba(0,0,0,.03), 0 4px 18px 3px rgba(0,0,0,.04)',
};
