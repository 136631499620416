import React from 'react';
import Box, { Flex } from '../Box';
import Button from '../RebassButton';
import useScroll from '../../app/hooks/useScroll';
import elevations from '../styles/elevations';

const HEIGHT = 70;

interface Props {
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
}

const Navbar = (props: Props) => {
  const { containerRef } = props;
  const { scrollY } = useScroll(containerRef);

  const opaqueStyles =
    Math.abs(scrollY) > HEIGHT
      ? { backgroundColor: 'white', boxShadow: elevations[6] }
      : { backgroundColor: 'transparent' };

  return (
    <Box sx={{ transition: '0.1s all ease-in-out', ...opaqueStyles }}>
      <Flex
        maxWidth={1200}
        height={HEIGHT}
        m="auto"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        px={3}
      >
        <Flex
          alignItems="center"
          maxWidth="100px"
          height="100%"
          sx={{ img: { height: '100%', p: 2 } }}
        >
          <img alt="logo" src="/images/Logo.png" />
        </Flex>
        <Box>
          <Button as="a" href="/login" fontSize="1.25em">
            Sign in
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default Navbar;
