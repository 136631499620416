import React, { useRef, useEffect } from 'react';

import { ImageElementProps } from '../types/ElementProps';

type Props = {
  element: ImageElementProps;
  handleUpdateElement: (elementIds: string[]) => void;
};

/**
 * An <image> svg element
 */
const ImageReact: React.FC<Props> = (props: Props) => {
  const { element, handleUpdateElement } = props;
  const ref = useRef(null);

  // Initialize image width and height if it doesn't have one
  const initImage = () => {
    if (!element.currentProps.width || !element.currentProps.height) {
      const img = new Image();
      img.onload = () => {
        element.update('props', {
          ...element.props,
          ...(!element.props.height ? { height: img.height } : {}),
          ...(!element.props.width ? { width: img.width } : {}),
        });

        element.update('currentProps', {
          ...element.currentProps,
          ...(!element.currentProps.height ? { height: img.height } : {}),
          ...(!element.currentProps.width ? { width: img.width } : {}),
        });

        handleUpdateElement([element.id]);
      };

      img.src = element.src;
    }
  };

  useEffect(() => {
    initImage();
    // eslint-disable-next-line
  }, []);

  return <image ref={ref} href={element.src} />;
};

// Use react memo to improve update speeds - https://reactjs.org/docs/react-api.html#reactmemo
export default ImageReact;
