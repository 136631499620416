import styled from 'styled-components/macro';

import { IconButton } from './NavigationStyles';

export const SettingsButton = styled(IconButton)`
  i,
  svg {
    transition: 0.1s ease-in-out all;
  }

  :hover {
    i,
    svg {
      transform: rotate(90deg);
    }
  }
`;
