import Debouncer from '../utilities/debouncer';
import undoStack from './undoStackInstance';
import deepClone from '../deepClone';

// Use debouncer for now
const debouncer = new Debouncer(null, 250);
export const addElements = (elements) => {
  debouncer.setValue(elements, (els) => {
    undoStack.add({
      elements: els.map((el) => deepClone(el)),
    });
  });
};

export const addElement = (element) => {
  const stack = undoStack.get();
  const lastState = stack[stack.length - 1];

  const newElements = deepClone(lastState.elements);
  const index = newElements.findIndex((el) => el.id === element.id);

  newElements.splice(index, 1, deepClone(element));

  undoStack.add({
    elements: newElements,
  });
};

export default {
  addElement,
  addElements,
};
