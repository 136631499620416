import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '../Box';

import {
  getMinutesFromMs,
  getSecondsFromMs,
  getTotalDuration,
} from '../../app/utilities/durations';

import {
  PropertyContainer,
  Unit,
  StyledNumberInput,
} from './properties/PropertyStyles';

/**
 * A set of inputs for editing minutes and seconds
 * @prop duration - should be given in milliseconds
 */
const DurationEdit = (props) => {
  const { duration, onUpdate } = props;

  const minutes = getMinutesFromMs(duration);
  const seconds = getSecondsFromMs(duration);

  const handleUpdate = (value, type) => {
    if (type === 'minutes') {
      onUpdate(getTotalDuration(value, seconds));
    } else if (type === 'seconds') {
      onUpdate(getTotalDuration(minutes, value));
    }
  };

  return (
    <PropertyContainer>
      <Flex>
        <Flex style={{ marginRight: 15 }}>
          <StyledNumberInput
            min={0}
            max={15}
            step={1}
            value={Number.isNaN(minutes) ? null : minutes}
            onChange={(val) => handleUpdate(val, 'minutes')}
            placeholder={'-'}
            darkMode={true}
          />
          <Unit>min</Unit>
        </Flex>
        <Flex>
          <StyledNumberInput
            min={1}
            max={60}
            step={10}
            value={Number.isNaN(seconds) ? null : seconds}
            onChange={(val) => handleUpdate(val, 'seconds')}
            placeholder={'-'}
            darkMode={true}
          />
          <Unit>s</Unit>
        </Flex>
      </Flex>
    </PropertyContainer>
  );
};

const { number, func } = PropTypes;

DurationEdit.propTypes = {
  duration: number.isRequired, // in ms
  onUpdate: func,
};

DurationEdit.defaultProps = {
  onUpdate: () => {},
};

export default DurationEdit;
