import React, { useState } from 'react';
import styled from 'styled-components/macro';

import SceneReview from './SceneReview';
import ScenePicker from './ScenePicker';
import Box from '../Box';

import { darken, colors } from '../styles/colors';
import { SceneProps } from '../editor/types/SceneProps';
import { CommentProps } from '../editor/types/CommentProps';

const Container = styled(Box)`
  background-color: ${darken(colors.night, 10)};
  height: 100vh;
`;

type Props = {
  scenes: SceneProps[];
  comments: {
    comments: CommentProps[];
    sceneID: number;
  }[];
  handleGetComments: () => void;
};

const ScenesReview: React.FC<Props> = (props: Props) => {
  const { scenes, comments, handleGetComments } = props;
  const [activeScene, setActiveScene] = useState(scenes[0]);

  const handleSelectScene = (scene: SceneProps) => {
    setActiveScene(scene);
  };

  const sceneComments =
    comments.find((comment) => comment.sceneID === activeScene.sceneID)
      ?.comments || [];

  return (
    <Container display="flex">
      {scenes.length > 1 && (
        <ScenePicker
          scenes={scenes}
          comments={comments}
          activeScene={activeScene}
          handleSelectScene={handleSelectScene}
        />
      )}
      <Box style={{ flex: 1, minWidth: '50%' }}>
        <SceneReview
          scene={activeScene}
          comments={sceneComments}
          handleGetComments={handleGetComments}
        />
      </Box>
    </Container>
  );
};

export default ScenesReview;
