exports.getHash = (options = {}) => {
  const {
    view = 'project',
    projectID = '',
    fileID = '',
    commentID = '',
  } = options;

  const project = `/${view}/${projectID}`;
  const file = fileID ? `/files/${fileID}` : '';
  const comment = commentID ? `/comments/${commentID}` : '';

  return `${project}${file}${comment}`;
};

exports.getFullUrl = (options) => {
  const hostname = window.LOCAL
    ? 'http://localhost:3000'
    : 'https://osmosify.osmosis.org';
  const url = `${hostname}/#${exports.getHash(options)}`;

  return url;
};

exports.goToUrl = (options = {}) => {
  const { newWindow = false } = options;

  if (newWindow) {
    window.open(exports.getFullUrl(options), '_blank');
  } else {
    window.location.hash = exports.getHash(options);
  }
};
