/**
 * Cleans each element's contents and makes sure things like
 * the cache aren't saved.
 *
 * @param {[SvgElement]} elements
 * @returns {[SvgElement]}
 */
export default (elements) => {
  const newElements = [...elements];
  const cleanRecursive = (element) => {
    // Remove the cache before saving
    // eslint-disable-next-line no-param-reassign
    delete element.cache;

    if (element.elements) {
      element.elements.forEach((el) => cleanRecursive(el));
    }

    return element;
  };

  newElements.forEach((element) => cleanRecursive(element));

  return newElements;
};
