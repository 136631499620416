/**
 * Convert kebab-case to camelcase
 *
 * @param {String} str
 * @returns {String}
 */
export const kebabToCamel = (str) =>
  str
    .split('-')
    .reduce(
      (newStr, curr) =>
        `${newStr}${curr.charAt(0).toUpperCase() + curr.slice(1)}`
    );

export default {
  kebabToCamel,
};
