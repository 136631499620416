import 'instantsearch.css/themes/satellite.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useContext } from 'react';
import { Configure, InstantSearch, Pagination } from 'react-instantsearch-dom';

import { post } from '../../util/fetchUtil';

import { Store } from '../../state/store';
import { fetchProjects } from '../../state/projects/actions';

import Button from '../RebassButton';
import { Flex } from '../Box';
import NewProject from '../dialogs/NewProject';
import HomeSidebar from './HomeSidebar';
import ProjectActions from './ProjectActions';

import ProjectHits from './ProjectHits';

import {
  Container,
  RightContainer,
  HomeSidebarContainer,
  ProjectListContainer,
} from './HomeStyles';
import useModal from '../../app/hooks/useModal';
import Dialog from '../Dialog';
import useSearchClient from '../../app/hooks/useSearchClient';
import { SearchIndex } from '../../types/search';

function Home() {
  const {
    state: { activeProject, user, projectGroups, projects },
    dispatch,
  } = useContext(Store);

  const [groupSelectVisible, setGroupSelectVisible] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [recentsChecked, setRecentsChecked] = useState(true);
  const [subscribedChecked, setSubscribedChecked] = useState(false);

  const newProjectDialog = useModal();

  const { searchClient, indexName } = useSearchClient(SearchIndex.PROJECTS);

  const handleDeselectAll = () => {
    setSelectedProjects([]);
  };

  const handleUnsubscribe = () => {
    post('/project/subscribeToProject', {
      projectIDs: selectedProjects.map((project) => project.projectID),
      userIDs: [window.userID],
      subscribed: false,
    }).then(() =>
      fetchProjects(
        selectedProjects.map((p) => p.projectID),
        projects,
        activeProject,
        dispatch
      )
    );
  };

  const handleSubscribe = () => {
    post('/project/subscribeToProject', {
      projectIDs: selectedProjects.map((project) => project.projectID),
      userIDs: [window.userID],
      subscribed: true,
    }).then(() =>
      fetchProjects(
        selectedProjects.map((p) => p.projectID),
        projects,
        activeProject,
        dispatch
      )
    );
  };

  const handleAddToGroup = () => {
    setGroupSelectVisible(true);
  };

  const handleProjectDragStart = (e, projectID) => {
    // Transfer the projectIDs
    const projectIDs = [];
    if (selectedProjects.length) {
      for (let i = 0; i < selectedProjects.length; i += 1)
        projectIDs.push(selectedProjects[i].projectID);
    } else {
      projectIDs.push(projectID);
    }
    e.dataTransfer.setData('projectIDs', JSON.stringify(projectIDs));
  };

  const filters = [];
  if (subscribedChecked) {
    filters.push({ attr: 'members.userID', value: user.userID });
  }

  if (recentsChecked) {
    filters.push({ attr: 'recents', value: user.userID });
  }

  const filtersAttribute = filters.reduce(
    (string, filter, i, arr) =>
      `${string}${filter.attr}:${filter.value}${
        i < arr.length - 1 ? ' AND ' : ''
      }`,
    ''
  );

  return (
    <InstantSearch indexName={indexName} searchClient={searchClient}>
      <Configure filters={filtersAttribute} />
      <Container>
        <Dialog
          isVisible={newProjectDialog.isVisible}
          handleHideDialog={() => newProjectDialog.hide()}
        >
          <NewProject
            groups={projectGroups}
            handleHideDialog={() => newProjectDialog.hide()}
          />
        </Dialog>
        <HomeSidebarContainer>
          <HomeSidebar
            subscribedChecked={subscribedChecked}
            setSubscribedChecked={setSubscribedChecked}
            recentsChecked={recentsChecked}
            setRecentsChecked={setRecentsChecked}
          />
        </HomeSidebarContainer>
        <RightContainer>
          {!!selectedProjects.length && (
            <ProjectActions
              handleAddToGroup={handleAddToGroup}
              // handleRemoveFromGroup={handleRemoveFromGroup}
              handleUnsubscribe={handleUnsubscribe}
              handleSubscribe={handleSubscribe}
              handleDeselectAll={handleDeselectAll}
              groupSelectVisible={groupSelectVisible}
              groups={projectGroups}
              totalSelectedProjects={selectedProjects.length}
              // folderIsActive={folderIsActive}
            />
          )}
          <Flex style={{ padding: 20, alignItems: 'center' }}>
            <Button
              style={{ marginRight: 20 }}
              startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
              variant="outline"
              color="dark-2"
              onClick={() => newProjectDialog.show()}
            >
              <span>New project</span>
            </Button>
          </Flex>

          <ProjectListContainer>
            <ProjectHits handleProjectDragStart={handleProjectDragStart} />
          </ProjectListContainer>

          <Flex justifyContent="center">
            <Pagination />
          </Flex>
        </RightContainer>
      </Container>
    </InstantSearch>
  );
}

export default Home;
