import { Point, Path } from 'paper';
import uuid from '../../utilities/uuid';
import { getSvgCoords } from '../coords';
import { calculateSelectionBox } from '../rect';

const createAnnotation = (d = '') => ({
  type: 'path',
  id: uuid(),
  attributes: {
    d,
    style: {
      strokeWidth: 10,
      stroke: 'red',
      fill: 'none',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      opacity: 0.65,
    },
  },
});

const createBox = ({ x, y }) => ({
  type: 'rect',
  id: uuid(),
  origin: { x, y },
  attributes: {
    width: 0,
    height: 0,
    x,
    y,
    style: {
      strokeWidth: 10,
      stroke: 'red',
      fill: 'none',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      opacity: 0.65,
    },
  },
});

export const handleStageDragPen = ({
  e,
  setIsAnnotating,
  svg,
  paperPath,
  annotations,
  currentAnnotation,
  setCurrentAnnotation,
  setAnnotations,
}) => {
  setIsAnnotating(true);

  const point = getSvgCoords(svg, e);
  if (!paperPath.current) {
    const path = new Path({});
    path.add(
      new Point({
        x: point.x,
        y: point.y,
      })
    );

    // eslint-disable-next-line no-param-reassign
    paperPath.current = path;
  } else {
    paperPath.current.add(point);
  }

  const newAnnotations = [...annotations];
  if (currentAnnotation) {
    const index = annotations.findIndex((a) => a.id === currentAnnotation.id);
    if (index > -1)
      newAnnotations[index].attributes.d = paperPath.current.getPathData();
  } else {
    const newAnnotation = createAnnotation(paperPath.current.getPathData());
    newAnnotations.push(newAnnotation);

    setCurrentAnnotation(newAnnotation);
  }

  setAnnotations(newAnnotations);
};

export const handleStageDragBox = ({
  e,
  svg,
  setIsAnnotating,
  annotations,
  currentAnnotation,
  setCurrentAnnotation,
  setAnnotations,
}) => {
  setIsAnnotating(true);

  const point = getSvgCoords(svg, e);

  const newAnnotations = [...annotations];
  if (currentAnnotation) {
    const index = annotations.findIndex((a) => a.id === currentAnnotation.id);
    if (index > -1) {
      const annotation = newAnnotations[index];

      const newRect = calculateSelectionBox(annotation.origin, point);

      annotation.attributes = {
        ...annotation.attributes,
        ...newRect,
      };
    }
  } else {
    const newAnnotation = createBox(point);
    newAnnotations.push(newAnnotation);

    setCurrentAnnotation(newAnnotation);
  }

  setAnnotations(newAnnotations);
};

export default { handleStageDragBox, handleStageDragPen };
