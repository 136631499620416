import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

import { Box } from '../Box';
import { colors, darken } from '../styles/colors';

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  i,
  svg {
    font-size: 14px;
  }
`;
export const SectionTitle = styled.div`
  font-size: 18px;
  font-weight: bold;

  color: ${colors['light-grey-10']};

  i,
  svg {
    margin-right: 10px;
  }
`;

export const LargeSectionTitle = styled(SectionTitle)`
  color: ${darken(colors.grey, 5)};

  font-size: 20px;
  font-weight: 700;
`;
export const Section = styled(Box)`
  margin-bottom: 30px;
  padding: 20px;
  border: 1px solid ${colors['light-grey-40']};
  border-radius: 5px;
`;
export const Icon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;
