import create from '../../../editor/create';
import getElementArgs from './getElementArgs';
import { getMetrics } from '../text';

export default (
  node,
  transform,
  startTime,
  duration,
  currentTime,
  hasAnimation
) => {
  // Flatten tspans
  // TODO: FIGURE OUT INDIVIDUAL CHARACTER STYLES
  const text = node.children.reduce((str, curr) => {
    if (curr.name === '') return `${str}${curr.value}`;
    if (curr.type === 'tspan') return `${str}${curr.children[0].value}`;
    return str;
  }, '');

  const { fontWeight, fontSize } = node.attributes.style;

  const {
    metrics: { ascender },
  } = getMetrics(fontWeight || 400);

  // Need to correct for difference in position for imported <text> element and
  // generated <foreignObject> and <g> elements. <text> elements are positioned from their baseline,
  // so we translate up by the ascender height
  const correctionY = (ascender / 1000) * fontSize;
  const newTransform = {
    ...transform,
    translateY: transform.translateY - correctionY,
  };

  const newElement = create.text(
    ...getElementArgs(
      node,
      {
        props: {
          ...node.attributes,
          ...newTransform,
          style: {
            ...(node.attributes.style
              ? {
                  ...node.attributes.style,
                  color: node.attributes.style.fill,
                }
              : {}),
          },
        },
        name: text,
        duration,
        text,
      },
      hasAnimation
        ? {
            startTime,
            currentTime,
          }
        : {}
    )
  );

  return newElement;
};
