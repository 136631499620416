import styled, { css } from 'styled-components/macro';

import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  DragEvent,
  MouseEvent,
  CSSProperties,
} from 'react';

import ImageAsset from './ImageAsset';
import VideoAsset from './VideoAsset';
import SvgAsset from './SvgAsset';
import SceneSvgAsset from './SceneSvgAsset';
import AudioAsset from './AudioAsset';
import TextAsset from './TextAsset';
import PdfAsset from './PdfAsset';

import Label from '../../Label';
import { Flex } from '../../Box';

import { colors, lighten } from '../../styles/colors';
import { P } from '../../styles/typography';

import { getMediaType } from '../../../shared/utilities/fileTypes';
import FileProps from '../../editor/types/FileProps';

const Container = styled.div`
  background: ${lighten(colors.grey, 53)};
  border-radius: 3px;

  position: relative;

  width: 150px;
  height: 150px;

  margin: 10px;
  padding: 10px 10px 22px 10px;

  cursor: pointer;
  border: 2px solid transparent;

  transition: 0.1s all ease-in-out;

  :hover,
  :focus {
    background: ${lighten(colors.grey, 45)};
  }

  :active {
    background: ${lighten(colors.grey, 40)};
  }

  ${(props: { isSelected: boolean }) =>
    props.isSelected &&
    css`
      background: ${colors['light-blue-40']};
      border: 2px solid ${colors['light-blue-10']};
      :hover,
      :focus,
      :active {
        background: ${colors['light-blue-40']};
      }
    `}
`;

const NameContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  display: flex;
  justify-content: center;
`;

const Filename = styled(P)`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
  white-space: nowrap;

  text-align: center;

  font-size: 14px;
  color: ${colors['light-grey-20']};
`;

type Props = {
  file: FileProps;
  isSelected?: boolean;
  style?: CSSProperties;
  selectFiles?: (files: FileProps[], e: any) => void;
  handleCopyToClipboard?: (file: any) => void;
};

function AssetPreview(props: Props) {
  const {
    file,
    style,
    isSelected = false,
    selectFiles,
    handleCopyToClipboard,
  } = props;

  const [showPreview, setShowPreview] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleDragStart = (e: DragEvent) => {
    if (!isSelected && selectFiles) selectFiles([file], e);
  };

  const handleSelectFile = (e: MouseEvent) => {
    e.stopPropagation();
    // Stop this from bubbling into useClickOutside
    // e.nativeEvent.stopImmediatePropagation();
    if (selectFiles) selectFiles([file], e);
  };

  /**
   * Handle shortcut keys
   *
   * @param {Event} event
   * @memberof Timeline
   */
  const handleKeyDown = useCallback(
    (event) => {
      const activeTag = document.activeElement?.tagName;
      switch (event.keyCode) {
        case 32: // spacebar
          if (isSelected && activeTag !== 'INPUT' && activeTag !== 'TEXTAREA') {
            event.preventDefault();
            setShowPreview(!showPreview);
          }
          break;
        default:
          break;
      }
    },
    [isSelected, showPreview]
  );

  // Register key down
  useEffect(() => {
    // Listens for keydown events
    window.addEventListener('keypress', handleKeyDown);
    return () => {
      window.removeEventListener('keypress', handleKeyDown);
    };
  }, [isSelected, showPreview, handleKeyDown]);

  // Set default asset preview
  let asset = (
    <Flex c style={{ height: '100%', flexDirection: 'column' }}>
      <Label size={18}>{file.type}</Label>
      <P
        style={{
          marginTop: 10,
          textAlign: 'center',
          color: colors['light-grey-30'],
        }}
      >
        No preview yet{' '}
        <span role="img" aria-label="sad face">
          😢
        </span>
      </P>
    </Flex>
  );

  let mediaType = getMediaType(file.type);

  if (file.data.projectAssetType === 'scene-svg') mediaType = 'scene-svg';
  if (file.name.includes('.cmproj')) mediaType = 'cmproj';

  switch (mediaType) {
    case 'image':
      asset = (
        <ImageAsset
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          file={file}
        />
      );
      break;
    case 'svg':
      asset = (
        <SvgAsset
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          file={file}
        />
      );
      break;
    case 'audio':
      asset = (
        <AudioAsset
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          file={file}
        />
      );
      break;
    case 'scene-svg':
      asset = (
        <SceneSvgAsset
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          file={file}
        />
      );
      break;
    case 'text':
      asset = (
        <TextAsset
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          file={file}
        />
      );
      break;
    case 'cmproj':
      asset = (
        <Flex c style={{ height: '100%' }}>
          <Label size={18}>Camtasia</Label>
        </Flex>
      );
      break;
    case 'video':
      asset = (
        <VideoAsset
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          file={file}
        />
      );
      break;
    case 'pdf':
      asset = (
        <PdfAsset
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          file={file}
        />
      );
      break;
    default:
      break;
  }

  return (
    <Container
      onClick={handleSelectFile}
      onDoubleClick={() => handleCopyToClipboard?.(file)}
      isSelected={isSelected}
      onDragStart={handleDragStart}
      ref={containerRef}
      style={style}
      draggable
    >
      {asset}
      <NameContainer>
        <Filename title={file.name}>{file.name}</Filename>
      </NameContainer>
    </Container>
  );
}

AssetPreview.defaultProps = {
  file: {},
  isSelected: false,
};

export default AssetPreview;
