import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../Box';
import Message from '../../Message';
import StrokeProperties from './StrokeProperties';
import TextProperties from './TextProperties';
import RectProperties from './RectProperties';
import AnimationPathProperties from './AnimationPathProperties';
import Transform from './Transform';
import Durations from './Durations';

import ElementProps from '../props/ElementProps';

import { fade, lighten, colors  } from '../../styles/colors';

/**
 * Display the properties for one or more elements
 */
function ElementProperties(props) {
  const { selectedElements, handleUpdateElement, addKeyframe } = props;

  const selectedElement = selectedElements.length === 1 && selectedElements[0];

  return (
    <Box>
      {!selectedElements.length && (
        <Box style={{ margin: 5 }}>
          <Message
            style={{ width: '100%' }}
            color={fade('white', 60)}
            backgroundColor={lighten(colors.night, 10)}
          >
            No elements selected
          </Message>
        </Box>
      )}
      {!!selectedElements.length && (
        <>
          <Box style={{ marginBottom: 30 }}>
            <Durations
              selectedElements={selectedElements}
              handleUpdateElement={handleUpdateElement}
            />
          </Box>
          {selectedElement && (
            <Transform
              handleUpdateElement={handleUpdateElement}
              selectedElement={selectedElement}
              addKeyframe={addKeyframe}
            />
          )}
          {selectedElement && selectedElement.type === 'path' && (
            <StrokeProperties
              element={selectedElement}
              handleUpdateElement={handleUpdateElement}
              addKeyframe={addKeyframe}
            />
          )}
          {selectedElement && selectedElement.type === 'text' && (
            <TextProperties
              element={selectedElement}
              addKeyframe={addKeyframe}
              handleUpdateElement={handleUpdateElement}
            />
          )}
          {selectedElement && selectedElement.type === 'rect' && (
            <RectProperties
              element={selectedElement}
              addKeyframe={addKeyframe}
              handleUpdateElement={handleUpdateElement}
            />
          )}
          {selectedElement && (
            <AnimationPathProperties
              element={selectedElement}
              handleUpdateElement={handleUpdateElement}
            />
          )}
        </>
      )}
    </Box>
  );
}

const { arrayOf, func } = PropTypes;

ElementProperties.propTypes = {
  selectedElements: arrayOf(ElementProps),
  handleUpdateElement: func.isRequired,
  addKeyframe: func.isRequired,
};

export default ElementProperties;
