import { useState, useRef } from 'react';

import subjx from '../../3rd/subjx/subjx.dev.esm.mod';

import matrix from '../matrix';

type MoveProps = {
  clientX: number;
  clientY: number;
  dx: number;
  dy: number;
  transform: DOMMatrix;
};

type RotateProps = {
  clientX: number;
  clientY: number;
  delta: number;
  transform: DOMMatrix;
};

// TODO: FIGURE OUT TYPES
type XDraggableProps = {
  options: any;
  storage: any;
  enable: (options: any) => void;
  disable: () => void;
};

export type DragProps = {
  transform?: DOMMatrix;
  xDraggable?: XDraggableProps;
  init: (elRef: SVGElement) => void;
  isEnabled: () => boolean;
  enable: () => void;
  disable: () => void;
  redraw: () => void;
  transformToString: () => string;
};

/**
 * Hook for controlling subjx library behavior
 *
 * @param {HTMLElement} element
 * @param {Object} [options={}] - a subjx options object (https://github.com/nichollascarter/subjx)
 * @param {Object} [callbacks={}] - callbacks to be fired
 * {
 *  onMove: func, - called whenever element is dragged
 * }
 * @returns {Object}
 * {
 *   transform: String, - matrix transform string
 *   enable: Func, - enable the drag box
 *   disable: Func, - disable the drag box
 *   isEnabled: Bool,
 *   transformToString: Func, - turns DOMMatrix to string
 *   xDraggable: Object, - subjx draggable ref
 * }
 */
const useSubjx = (
  options = {},
  callbacks: {
    onMove?: (e: MoveProps) => void;
  } = {}
): DragProps => {
  const xElemRef = useRef<any>();
  const transformRef = useRef<DOMMatrix>();
  const [transform, setTransform] = useState<DOMMatrix>();
  const xDraggableRef = useRef<XDraggableProps>();

  const methods = {
    onInit() {
      // fires on tool activation
      // console.log('Init');
    },
    onMove(e: MoveProps) {
      // console.log(e);
      // elemTransform.current = transform;
      transformRef.current = e.transform;
      // fires on moving
      if (callbacks.onMove) callbacks.onMove(e);
    },
    onResize() {
      // fires on resizing, construct current transform (since it's not passed back)
      const transformStr =
        xElemRef.current && xElemRef.current[0].attributes.transform.value;
      const newTransform = matrix.stringToObject(transformStr);
      transformRef.current = newTransform;
    },
    onRotate(e: RotateProps) {
      // fires on rotation
      transformRef.current = e.transform;
    },
    onDrop() {
      // fires on drop

      // Trigger an update to state, which should trigger a state update in parent
      setTransform(transformRef.current);
    },
    onDestroy() {
      // fires on tool deactivation
    },
  };

  const newOptions = {
    ...methods,
    snap: {
      x: 1,
      y: 1,
      angle: 1,
    },

    ...options,
  };

  if (xDraggableRef.current && xElemRef.current && options) {
    // Update options
    xDraggableRef.current.options = {
      ...xDraggableRef.current.options,
      ...options,
    };
  }

  const init = (elRef: SVGElement) => {
    if (elRef && !xElemRef.current) {
      xElemRef.current = subjx(elRef);

      const xDraggables = xElemRef.current.drag(newOptions);

      const [xDraggable] = xDraggables;

      xDraggableRef.current = xDraggable;

      // Init as disabled
      xDraggable.disable();
    }
  };

  const isEnabled = () =>
    !!(xDraggableRef.current && xDraggableRef.current.storage);

  const enable = () =>
    xDraggableRef.current && xDraggableRef.current.enable(newOptions);

  const disable = () =>
    xDraggableRef.current && xDraggableRef.current.disable();

  const redraw = () => {
    disable();
    enable();
  };

  const transformToString = () => {
    if (transform) {
      const { a, b, c, d, e, f } = transform;
      return `matrix(${a},${b},${c},${d},${e},${f})`;
    }

    return '';
  };

  return {
    transform,
    init,
    enable,
    disable,
    redraw,
    isEnabled,
    transformToString,
    xDraggable: xDraggableRef.current,
  };
};

export default useSubjx;
