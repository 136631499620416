import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import DraggableSvg from '../../DraggableSvg';

import { colors, fade  } from '../../styles/colors';

const Line = styled.line`
  stroke: ${fade(colors.blue, 20)};
`;

const Handle = styled.circle`
  fill: ${fade(colors.blue, 30)};
  stroke: ${fade('white', 30)};
`;

const DraggableControlPoint = ({ cpx, cpy, x, y, onDrag, svgRef }) => (
  <g>
    <DraggableSvg onMouseMove={onDrag} svgRef={svgRef}>
      <Handle cx={cpx} cy={cpy} r={5} />
    </DraggableSvg>
    <Line x1={cpx} y1={cpy} x2={x} y2={y} />
  </g>
);

const { func, shape, number } = PropTypes;

DraggableControlPoint.propTypes = {
  x: number,
  y: number,
  cpx: number,
  cpy: number,
  onDrag: func,
  svgRef: shape({}),
};

export default DraggableControlPoint;
