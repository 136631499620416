import React, { useState } from 'react';
import { Flex } from 'rebass';

import styled from 'styled-components';
import Checkbox from '../Checkbox';

const CheckboxContainer = styled.div`
  margin-bottom: 10px;
`;

const Name = styled.h4`
  margin: 0;
  margin-bottom: 5px;
`;

const Description = styled.p`
  margin: 0;
  font-size: 14px;
`;

export type GrantProps = {
  name: string;
  checked: boolean;
  value: string;
  description: string;
  level?: 'view' | 'edit';
};

const Grant = ({
  option,
  handleCheckboxChange,
}: {
  option: GrantProps;
  handleCheckboxChange: (
    grant: GrantProps,
    isChecked: boolean,
    level: 'view' | 'edit'
  ) => void;
}) => {
  const [selectedType, setSelectedType] = useState<'view' | 'edit'>(
    option.level || 'edit'
  );

  const handleSelectType = (newType: 'view' | 'edit') => {
    setSelectedType(newType);
    handleCheckboxChange(option, option.checked, newType);
  };

  const [type] = option.name.split('.');

  return (
    <CheckboxContainer key={option.name}>
      <Checkbox
        name={option.name}
        checked={option.checked}
        onChange={() =>
          handleCheckboxChange(option, !option.checked, selectedType)
        }
        label={
          <>
            <Name>{option.name}</Name>
            <Description>{option.description}</Description>
          </>
        }
      ></Checkbox>
      {type === 'content' && option.checked && (
        <Flex mt={2} ml={4}>
          <label>
            <input
              type="radio"
              checked={selectedType === 'view'}
              onChange={() => handleSelectType('view')}
            />
            View
          </label>
          <label>
            <input
              type="radio"
              checked={selectedType === 'edit'}
              onChange={() => handleSelectType('edit')}
            />
            Edit
          </label>
        </Flex>
      )}
    </CheckboxContainer>
  );
};

export default Grant;
