import { useState } from 'react';

type Props = {
  onHide?: () => void;
};

export default (props: Props = {}) => {
  const { onHide } = props;

  const [isVisible, setIsVisible] = useState(false);

  const hide = () => {
    setIsVisible(false);
    if (onHide) onHide();
  };

  const show = () => setIsVisible(true);

  const toggle = () => {
    setIsVisible(!isVisible);
    if (isVisible && onHide) onHide();
  };

  return {
    isVisible,
    hide,
    show,
    toggle,
  };
};
