import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React from 'react';

import { useState, useContext } from 'react';

import fetchUtil from '../../util/fetchUtil';

import { Store } from '../../state/store';
import { fetchActiveProject } from '../../state/projects/actions';

import Button from '../Button';
import { H2 } from '../styles/typography';

import { TextInput } from '../Input';
import Message from '../Message';
import { colors, lighten } from '../styles/colors';

function EditProjectName({ handleHideDialog }) {
  const [newName, setNewName] = useState('');
  const {
    dispatch,
    state: { activeProject },
  } = useContext(Store);

  const hasTrelloData = activeProject.data && !!activeProject.data.trello;

  const handleUpdateName = () =>
    new Promise((resolve, reject) => {
      fetchUtil
        .post('/project/updateName', {
          projectID: activeProject.projectID,
          name: newName,
        })
        .then((res) => {
          fetchActiveProject(activeProject.projectID, dispatch);
          resolve(res);

          handleHideDialog();
        })
        .catch((err) => {
          reject(err);
        });
    });

  return (
    <Container>
      <Header>Edit name</Header>
      <TextInput
        value={newName}
        onChange={(e) => setNewName(e.target.value)}
        placeholder={activeProject.name}
        style={{
          width: '100%',
        }}
      />
      {hasTrelloData && (
        <TrelloMessage
          backgroundColor={lighten(colors.orange, 40)}
          color={colors.orange}
          icon="exclamation-circle"
        >
          This project is linked to a Trello card. Updating it&apos;s name
          &nbsp;will also update the Trello card&apos;s name.
        </TrelloMessage>
      )}
      <ButtonsContainer>
        <Button
          enable={newName.length > 0}
          className="box green thin"
          onClick={() => handleUpdateName()}
        >
          Save
        </Button>
        <Button className="box thin" onClick={handleHideDialog}>
          Cancel
        </Button>
      </ButtonsContainer>
    </Container>
  );
}

const Container = styled.div``;
const TrelloMessage = styled(Message)`
  max-width: 300px;
  margin-top: 15px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 15px;
  > * {
    margin-right: 10px;
  }
`;

const Header = styled(H2)`
  margin-bottom: 15px;
`;

EditProjectName.propTypes = {
  project: PropTypes.shape({
    name: PropTypes.string,
    data: PropTypes.shape({
      trello: PropTypes.shape({}),
    }),
  }),
  handleHideDialog: PropTypes.func,
};

EditProjectName.defaultProps = {
  project: {},
  handleHideDialog: () => {},
};

export default EditProjectName;
