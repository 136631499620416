import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useContext } from 'react';

import { useHistory } from 'react-router-dom';

import { Store } from '../../state/store';
import DownloadSnackbar from '../DownloadSnackbar';

import { fetchActiveProject } from '../../state/projects/actions';

import SvgFiles from './SvgFiles';
import ImageFiles from './ImageFiles';
import VideoFiles from './VideoFiles';

import Warning from '../dialogs/WarningDialog';
import Dialog from '../Dialog';

import {
  video as videoFileTypes,
  image as imageFileTypes,
} from '../../shared/utilities/fileTypes';

import { colors } from '../styles/colors';

import { post } from '../../util/fetchUtil';

function LayersPanel(props) {
  const { handleLoadFile } = props;

  const context = useContext(Store);
  const {
    state: { activeFile, activeProject },
    dispatch,
  } = context;

  // Select file for deletion
  const [selectedDelete, setSelectedDelete] = useState(null);

  const [downloadingFiles, setDownloadingFiles] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [downloadError, setDownloadError] = useState(false);
  const [downloadFile, setDownloadFile] = useState(null);

  const history = useHistory();

  const handleRowClick = (url) => {
    history.push(url);
  };

  const onDownloadError = (file) => {
    setDownloadFile(file);
    setDownloadError(true);
  };

  const onProgress = (progress) => {
    const { received, total } = progress;
    const amount = received / total;

    if (amount > 0 && !downloadingFiles) setDownloadingFiles(true);
    if (amount === 1) setDownloadingFiles(false);

    setDownloadProgress(amount);
  };

  const handleDeleteFile = () =>
    post('/project/removeFiles', {
      projectID: activeProject.projectID,
      fileIDs: [selectedDelete.fileID],
    }).then(() => {
      setSelectedDelete(null);
      fetchActiveProject(activeProject.projectID, dispatch);
    });

  const allProjectSvgs =
    activeProject.files &&
    activeProject.files.filter((file) => file.type === 'svg');

  const fileHasComments = (file) => {
    const comments =
      activeProject.comments &&
      activeProject.comments.filter(
        (comment) => comment.fileID === file.fileID
      );

    if (!comments || comments.length === 0) return null;

    if (
      comments.some(
        (comment) => !comment.data.parentCommentID && comment.data.status === 0
      )
    )
      return 'unresolved';

    return 'resolved';
  };

  const projectVideos =
    activeProject.files &&
    activeProject.files
      .filter((file) => videoFileTypes.find((type) => type === file.type))
      .filter((file) => file.data.tag !== 'export');

  const projectImages =
    activeProject.files &&
    activeProject.files.filter((file) =>
      imageFileTypes.find((type) => type === file.type)
    );

  return (
    <Container>
      <DownloadSnackbar
        isVisible={downloadingFiles || downloadError}
        progress={downloadProgress}
        hasDownloadError={downloadError}
        link={downloadFile && downloadFile.data.url}
      />
      <Dialog
        isVisible={!!selectedDelete}
        handleHideDialog={() => setSelectedDelete(null)}
      >
        <Warning
          onConfirm={handleDeleteFile}
          onCancel={() => setSelectedDelete(null)}
          confirmText="Yes, delete"
          header="Are you sure you want to delete this file?"
          color={colors.red}
        />
      </Dialog>
      {/* <LayersPanelHeader></LayersPanelHeader> */}
      <div className="divider" />
      <Container>
        <SvgFiles
          files={allProjectSvgs}
          activeFile={activeFile}
          activeProject={activeProject}
          fileHasComments={fileHasComments}
          handleLoadFile={handleLoadFile}
          setSelectedDelete={setSelectedDelete}
          handleRowClick={handleRowClick}
          onProgress={onProgress}
          onDownloadError={onDownloadError}
        />
        <VideoFiles
          files={projectVideos}
          activeFile={activeFile}
          activeProject={activeProject}
          fileHasComments={fileHasComments}
          handleLoadFile={handleLoadFile}
          setSelectedDelete={setSelectedDelete}
          handleRowClick={handleRowClick}
          onProgress={onProgress}
          onDownloadError={onDownloadError}
        />
        <ImageFiles
          files={projectImages}
          activeFile={activeFile}
          activeProject={activeProject}
          handleLoadFile={handleLoadFile}
          fileHasComments={fileHasComments}
          setSelectedDelete={setSelectedDelete}
          handleRowClick={handleRowClick}
          onProgress={onProgress}
          onDownloadError={onDownloadError}
        />
      </Container>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  height: 100%;
  margin: 0;
  border-radius: 0;

  overflow: hidden;

  box-shadow: none;
`;

LayersPanel.propTypes = {
  handleLoadFile: PropTypes.func.isRequired,
};

LayersPanel.defaultProps = {};

export default LayersPanel;
