import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Button from '../../Button';
import Box, { Flex } from '../../Box';
import { TextInput } from '../../Input';

import { P } from '../../styles/typography';

import { fade } from '../../styles/colors';

import KeyframeRowInfo from './KeyframeRowInfo';

import { RowContainer, HeaderRow } from './TimelineStyles';

import ElementProps from '../props/ElementProps';
import KeyframeGroupProps from '../props/ElementProps';

const Name = styled(P)`
  color: ${fade('white', 40)};
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90px;
  overflow: hidden;
  margin-right: 5px;
`;

const StyledTextInput = styled(TextInput)`
  height: 10px;
  font-size: 14px;
  max-width: 120px;
  margin-right: 10px;
`;

function TimelineRowInfo(props) {
  const {
    element,
    handleUpdateElement,
    handleAddKeyframe,
    height,
    selectedElements,
    handleSelectKeyframeGroup,
    handleDeselectKeyframeGroup,
    selectedKeyframeGroups,
    handleSelectElement,
    handleRemoveKeyframeGroup,
    style,
    className,
  } = props;

  const { keyframes, keyframesOpen } = element;

  const [isEditingName, setIsEditingName] = useState(false);
  const [newName, setNewName] = useState(element.name || '');

  const inputRef = useRef();

  const isSelected = selectedElements.some((el) => el.id === element.id);
  const hasKeyframes = !!Object.keys(keyframes).length;

  const handleSaveNewName = () => {
    element.update('name', newName);

    setIsEditingName(false);

    handleUpdateElement([element.id]);
  };

  const handleClickText = () => {
    if (isSelected) {
      setIsEditingName(true);
    }
  };

  const handleToggleKeyframes = (e) => {
    e.stopPropagation();
    element.update('keyframesOpen', !element.keyframesOpen);
    handleUpdateElement([element.id]);
  };

  const keyframeComponents = Object.keys(keyframes).map((key) => (
    <KeyframeRowInfo
      key={key}
      height={height}
      handleSelectKeyframeGroup={handleSelectKeyframeGroup}
      handleDeselectKeyframeGroup={handleDeselectKeyframeGroup}
      selectedKeyframeGroups={selectedKeyframeGroups}
      element={element}
      keyframeKey={key}
      handleAddKeyframe={handleAddKeyframe}
      handleRemoveKeyframeGroup={handleRemoveKeyframeGroup}
    />
  ));

  return (
    <RowContainer active={isSelected} style={style} className={className}>
      <HeaderRow
        height={height}
        style={{ justifyContent: 'space-between', paddingRight: 0 }}
        onClick={() => handleSelectElement(element)}
      >
        <Flex onClick={handleClickText}>
          {isEditingName && (
            <Box ref={inputRef}>
              <StyledTextInput
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                handleEnterPress={handleSaveNewName}
                placeholder={element.name || ''}
                darkMode={true}
              />
            </Box>
          )}
          {!isEditingName && (
            <Flex>
              <Name style={{ color: 'white' }}>{element.name}</Name>
              &nbsp;
              <Box style={{ color: 'white', opacity: 0.5 }}>
                ({element.type})
              </Box>
            </Flex>
          )}
        </Flex>
        {!hasKeyframes && <span></span>}
        {hasKeyframes && (
          <Button
            onClick={handleToggleKeyframes}
            style={{ padding: '0.2em', fontSize: 10 }}
            icon
            color={fade('white', 50)}
          >
            <FontAwesomeIcon
              icon={['fas', keyframesOpen ? 'chevron-up' : 'chevron-down']}
            />
          </Button>
        )}
      </HeaderRow>
      {keyframesOpen && keyframeComponents}
    </RowContainer>
  );
}

const { arrayOf, func, number, string, shape } = PropTypes;

TimelineRowInfo.propTypes = {
  element: ElementProps.isRequired,
  handleUpdateElement: func.isRequired,
  handleAddKeyframe: func.isRequired,
  height: number.isRequired,
  selectedElements: arrayOf(ElementProps).isRequired,
  handleSelectKeyframeGroup: func.isRequired,
  handleDeselectKeyframeGroup: func.isRequired,
  selectedKeyframeGroups: arrayOf(KeyframeGroupProps),
  handleSelectElement: func.isRequired,
  handleRemoveKeyframeGroup: func.isRequired,
  style: shape({}),
  className: string,
};

export default TimelineRowInfo;
