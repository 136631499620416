import styled from 'styled-components/macro';

import { colors } from '../styles/colors';

export const ReviewVideoContainer = styled.div`
  width: 100%;
  height: 100%;

  .vjs-control-bar {
    font-size: 16px;
  }

  li.vjs-menu-item {
    color: white;

    &.vjs-selected {
      color: ${colors.grey};
    }
  }

  .vjs-progress-holder.vjs-slider.vjs-slider-horizontal {
    border-radius: 50px;
    margin: 0;
  }

  .vjs-progress-control {
    .vjs-progress-holder {
      font-size: 18px;
    }
    :hover .vjs-progress-holder {
      font-size: 18px;
    }
  }

  .vjs-play-progress {
    background-color: ${colors['light-orange-20']};
  }
`;
