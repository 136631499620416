import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { colors, fade  } from '../../styles/colors';

const Handle = styled.circle`
  fill: ${fade(colors.blue, 30)};
  stroke: ${fade('white', 30)};
`;

const Line = styled.line`
  stroke: ${fade(colors.blue, 20)};
`;

/**
 * A control point for a bezier curve
 */
const ControlPoint = ({ cpx, cpy, x, y }) => (
  <g>
    <Handle cx={cpx} cy={cpy} r={5} />
    <Line x1={cpx} y1={cpy} x2={x} y2={y} />
  </g>
);

const { number } = PropTypes;

ControlPoint.propTypes = {
  cpx: number,
  cpy: number,
  x: number,
  y: number,
};

export default ControlPoint;
