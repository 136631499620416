import React from 'react';
import { BoxProps } from 'rebass';
import Box, { Flex } from '../Box';
import Text from '../Text';
import Video from './Video';

interface Props {}

const Column = (props: BoxProps) => (
  <Box flex={1} px={[4, 5]} py={[4, 0]} {...props} />
);

const Hero = (props: Props) => {
  return (
    <Flex
      height="700px"
      sx={{ background: 'linear-gradient(0deg, #fff,#dcecff 100%)' }}
      width="100%"
      pt={70}
    >
      <Flex
        maxWidth={1200}
        width="100%"
        m="auto"
        flexDirection={['column', 'row']}
        alignItems="center"
      >
        <Column>
          <Text as="h1" mb={3} fontSize="38px">
            Create engaging whiteboard-style videos
          </Text>
          <Text as="p" fontSize="20px">
            Whiteboard-style video production workflows and asset management.
          </Text>
        </Column>
        <Column width="100%">
          <Video youtube="R9wijnDBpcw" />
        </Column>
      </Flex>
    </Flex>
  );
};

export default Hero;
