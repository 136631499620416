// Max-width breakpoints
export const largeDesktopMax = '1280px';
export const desktopMax = '980px';
export const responsiveTabletMax = '768px';
export const responsiveMobileMax = '480px';

// Min-width breakpoints
export const largeDesktopMin = 'only screen and (min-width: 981px)';
export const desktopMin = 'only screen and (min-width: 769px)';
export const tabletMin = 'only screen and (min-width: 481px)';

const breakpoints = {
  largeDesktopMax,
  largeDesktopMin,
  desktopMax,
  desktopMin,
  responsiveMobileMax,
  responsiveTabletMax,
  tabletMin,
};

export default breakpoints;
