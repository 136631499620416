import React from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { transparentize } from 'polished';
import Text from '../Text';
import Button from '../RebassButton';
import useAudio from '../../app/hooks/useAudio';
import { PronounceHitProps } from './PronounceHits';

type Props = {
  entry: PronounceHitProps;
  onClick: () => void;
};

export default function WordRow(props: Props) {
  const { entry, onClick } = props;
  const audio = useAudio(entry.audio);

  const handlePlay = (e: React.MouseEvent) => {
    e.stopPropagation();

    audio.play();
  };

  const handleStop = (e: React.MouseEvent) => {
    e.stopPropagation();

    audio.reset();
  };

  return (
    <Flex
      p={10}
      my={10}
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      onClick={onClick}
      sx={{
        borderRadius: 3,
        cursor: 'pointer',
        ':hover': {
          bg: transparentize(0.97, 'white'),
        },
      }}
    >
      <Flex>
        <Box>
          <Box mb="5px">
            <Text fontSize="20px" fontWeight="bold" color="light-1">
              {entry.word}
              {entry.phonetics && (
                <Text
                  as="span"
                  fontWeight="300"
                  color={transparentize(0.5, 'white')}
                >
                  &nbsp;·&nbsp;{entry.phonetics}
                </Text>
              )}
            </Text>
          </Box>
          <Text color={transparentize(0.5, 'white')}>
            Recorded by {entry.createdBy.givenName}
          </Text>
        </Box>
      </Flex>
      <Box>
        {!audio.isPlaying && (
          <Button
            variant="outline"
            color="primary-1"
            endIcon={<FontAwesomeIcon icon={['fad', 'volume-up']} />}
            onClick={handlePlay}
          >
            Listen
          </Button>
        )}
        {audio.isPlaying && (
          <Button
            variant="outline"
            color="light-1"
            endIcon={<FontAwesomeIcon icon={['far', 'pause']} />}
            onClick={handleStop}
          >
            Stop
          </Button>
        )}
      </Box>
    </Flex>
  );
}
