import React from 'react';
import PropTypes from 'prop-types';
import VideoComment from '../videojs/VideoComment';
import Box from '../Box';

/**
 * A video js bar that's overlaid on the progress bar and displays comments
 */
function CommentBar(props) {
  const { comments, duration } = props;

  return (
    <Box
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: -14,
        padding: '0 10px',
      }}
    >
      {comments.map((comment) => (
        <VideoComment
          key={comment.commentID}
          comment={comment}
          duration={duration}
        />
      ))}
    </Box>
  );
}

CommentBar.propTypes = {
  comments: PropTypes.array,
  duration: PropTypes.number,
};

CommentBar.defaultProps = {
  comments: [],
  duration: 0,
};

export default CommentBar;
