import React from 'react';
import styled, { css } from 'styled-components/macro';

import { colors, fade } from './styles/colors';

type Props = {
  progress: number;
  theme: string;
};

function LoadingProgressBar(props: Props) {
  const { progress, theme } = props;

  const progressText = `${Math.round(progress * 100)}%`;

  return (
    <Container theme={theme}>
      <LoadingBar>
        <ProgressText>{progressText}</ProgressText>
        <ProgressBar width={progressText} />
      </LoadingBar>
    </Container>
  );
}

const Container = styled.div`
  padding: 10px;
  /* border: 1px solid ${colors['light-grey-50']}; */
  width: 100%;

  /* border-radius: 3px; */

  ${(props) =>
    props.theme === 'dark' &&
    css`
      border: 1px solid ${fade('white', 75)};
    `}
`;

const LoadingBar = styled.div`
  background: ${colors['light-grey-50']};
  border-radius: 3px;
  overflow: hidden;

  display: flex;
  justify-content: center;

  position: relative;
`;

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  background: ${colors['light-green-30']};
  height: 100%;

  transition: 0.1s ease-in-out all;

  ${(props: { width?: string }) =>
    props.width &&
    css`
      width: ${props.width};
    `}
`;

const ProgressText = styled.div`
  position: relative;
  z-index: 1;

  color: ${colors.green};
`;

LoadingProgressBar.defaultProps = {
  progress: 0,
  theme: '',
};

export default LoadingProgressBar;
