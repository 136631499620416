import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useState, useEffect } from 'react';

import { post } from '../../../util/fetchUtil';

import InputOptionSelector from '../../InputOptionSelector';
import OptionSelector from '../../OptionSelector';
import Button from '../../Button';
import { TextInput } from '../../Input';

import { colors, fade } from '../../styles/colors';
import { P } from '../../styles/typography';

import { fetchFile, downloadFile } from '../../../app/fileManager';
import { getBasename } from '../../../utilities/files';
import { exportTypes, getMediaType } from '../../../shared/utilities/fileTypes';

import { Heading } from '../AssetStyles';

const exporterTypes = ['png', 'jpeg', 'jpg'];

const Container = styled.div`
  padding: 10px;
`;

const ExportButton = styled(Button)`
  width: 100%;
`;

const FileTypeSelector = styled(OptionSelector)``;

const SelectorButton = styled.div`
  padding: 5px 10px;
  border: 1px solid transparent;
  display: inline-block;

  cursor: pointer;

  i,
  svg {
    margin-left: 10px;
    color: ${colors['light-grey-40']};
  }

  :hover,
  :focus {
    border: 1px solid ${colors['light-grey-50']};
  }

  :active {
    background: ${fade('black', 97)};
  }
`;

const ExportSettingsContainer = styled.div`
  margin-bottom: 10px;

  display: flex;
  align-items: center;
`;

const SuffixInput = styled(TextInput)`
  margin-right: 5px;
  width: 0;
  flex: 1;
`;

function FileExporter(props) {
  const { file, kitID } = props;

  const mediaType = getMediaType(file.type);
  const options =
    exportTypes[mediaType]?.map((types) => {
      // Handle file name variants
      return {
        name: types[0].toUpperCase(),
        id: types[0].toLowerCase(),
        alts: types,
      };
    }) || [];

  const [selectedOption, setSelectedOption] = useState({});
  const [suffixValue, setSuffixValue] = useState('');

  const [resizeValue, setResizeValue] = useState('1x');

  const basename = getBasename(file.name);

  useEffect(() => {
    const option = options.find((o) => o.alts.find((alt) => alt === file.type));
    if (option) {
      setSelectedOption(option);
    }
    // eslint-disable-next-line
  }, [file.fileID]);

  /**
   * Formats the resize value to have an x at the end (or removes anything after an x)
   *
   * @param {String} value
   * @returns {String} formatted string in '[number]x' format
   */
  const formatResizeValue = (value) => {
    let newValue = '';
    for (let i = 0; i < value.length; i += 1) {
      if (!Number.isNaN(value[i]) && value[i] !== 'x') {
        newValue += value[i];
      } else if (value[i] === 'x') {
        newValue += 'x';
        return newValue;
      }
    }

    newValue += 'x';
    return newValue;
  };

  /**
   * Gets a converted image with the desired properties
   */
  const exportFile = async () => {
    let resize = formatResizeValue(resizeValue);
    resize = resize.slice(0, resize.length - 1);

    // Get converted image
    const res = await post('/assets/convertImage', {
      fileID: file.fileID,
      type: selectedOption.id,
      kitID,
      resize,
    });

    // Download image
    const blob = await fetchFile(res.url);
    await downloadFile(blob, `${basename}${suffixValue}.${selectedOption.id}`);
  };

  const handleFocusOut = (e) => {
    const { value } = e.target;
    setResizeValue(formatResizeValue(value));
  };

  const resizeOptions = [
    { id: '0.5', name: '0.5' },
    { id: '0.75', name: '0.75' },
    { id: '1', name: '1' },
    { id: '1.5', name: '1.5' },
    { id: '2', name: '2' },
    { id: '3', name: '3' },
    { id: '4', name: '4' },
  ];

  const canExport = exporterTypes.find((type) => file.type === type);

  return (
    <>
      {canExport && (
        <Container>
          <Heading>Export</Heading>
          <ExportSettingsContainer>
            <InputOptionSelector
              options={resizeOptions}
              value={resizeValue}
              setValue={(value) => setResizeValue(value)}
              inputStyle={{
                width: 50,
                textAlign: 'center',
              }}
              style={{
                marginRight: 10,
              }}
              onBlur={handleFocusOut}
            />
            <SuffixInput
              value={suffixValue}
              onChange={(e) => setSuffixValue(e.target.value)}
              placeholder="Suffix"
            />
            <FileTypeSelector
              options={options}
              selectedOption={selectedOption}
              onSelectOption={(option) => setSelectedOption(option)}
            >
              <SelectorButton>
                <P>
                  {selectedOption.name}
                  <FontAwesomeIcon icon={['fas', 'chevron-down']} />
                </P>
              </SelectorButton>
            </FileTypeSelector>
          </ExportSettingsContainer>
          <ExportButton box basic color="grey" onClick={exportFile}>
            <P
              style={{
                maxWidth: '75%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              Export {basename}
              {suffixValue}.
              {selectedOption.name && selectedOption.name.toLowerCase()}
            </P>
          </ExportButton>
        </Container>
      )}
    </>
  );
}

const { number, string, shape } = PropTypes;

FileExporter.propTypes = {
  file: shape({
    name: string,
    type: string,
    fileID: number,
  }),
  kitID: number.isRequired,
};

FileExporter.defaultProps = {};

export default FileExporter;
