import { post } from '../../util/fetchUtil';

type Props = {
  onCreateTag?: () => void;
  onAddTag?: () => void;
  onRemoveTag?: () => void;
};

export type CreateTagProps = (text: string) => Promise<number>;
export type AddTagProps = (tagID: number, fileIDs: number[]) => Promise<void>;
export type RemoveTagProps = (
  tagID: number,
  fileIDs: number[]
) => Promise<void>;

export default function (
  props: Props
): {
  createTag: CreateTagProps;
  addTag: AddTagProps;
  removeTag: RemoveTagProps;
} {
  const { onCreateTag, onAddTag, onRemoveTag } = props;

  const createTag = async (text: string): Promise<number> => {
    const res = await post('/tags/createTag', {
      text,
    });

    onCreateTag?.();

    return res.tag.tagID;
  };

  const addTag = async (tagID: number, fileIDs: number[]) => {
    await post('/tags/createFileTagLink', {
      tagID,
      fileIDs,
    });

    onAddTag?.();
  };

  const removeTag = async (tagID: number, fileIDs: number[]) => {
    // e.stopPropagation();

    await post('/tags/removeFileTagLink', {
      tagID,
      fileIDs,
    });

    onRemoveTag?.();
  };

  return { addTag, removeTag, createTag };
}
