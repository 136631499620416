import React from 'react';

import {
  Note,
  Container,
  SchemaHighlight,
  SchemaHeader,
  PSpan,
} from './FileNamingStyles';

function SvgNaming(props) {
  return (
    <Container>
      <div>
        <p>
          SVGs should be formatted with the SVG number, followed by the type
          ("o", "r", or "u"), followed by the timestamp (YYYYMMDD)
        </p>
        <SchemaHighlight>[NUMBER]_[TYPE]_[TIMESTAMP].svg</SchemaHighlight>
        <SchemaHeader>Examples</SchemaHeader>
        <div>
          <SchemaHighlight>1_o_20191029.svg</SchemaHighlight>
          <PSpan> - Original SVG #1 added on 10-29-19</PSpan>
        </div>
        <div>
          <SchemaHighlight>1_r_20191030.svg</SchemaHighlight>
          <PSpan> - Revised SVG #1 added on 10-30-19</PSpan>
        </div>
        <div>
          <SchemaHighlight>1_u_20191031.svg</SchemaHighlight>
          <PSpan> - Updated SVG #1 added on 10-31-19</PSpan>
        </div>
      </div>
      <Note>
        We count SVGs that have been corrected after the Illustration Review
        step as Originals. If there are SVG drafts after the Editing Review step
        we would refer to those as revisions.
      </Note>
    </Container>
  );
}

export default SvgNaming;
