import React from 'react';
import { ElementProps } from '../types/ElementProps';

type Props = {
  maskElements: ElementProps[];
  maskId: string;
  Child: any; // FIXME:
};

/**
 * A <mask> component representing a Mask of elements
 */
function Mask(props: Props) {
  // NOTE: the Child.jsx component is passed through props because
  // otherwise it isn't included if required is used since both Mask.jsx and Child.jsx require each other
  const { maskElements, maskId, Child } = props;

  const maskComponents = maskElements.map((el) => (
    <Child {...props} element={el} key={el.id} />
  ));

  return <mask id={maskId}>{maskComponents}</mask>;
}

export default Mask;
