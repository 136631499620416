import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React from 'react';

import moment from 'moment';

import fetchUtil from '../../util/fetchUtil';

import Table from '../Table';

import Button from '../Button';
import Dialog from '../Dialog';

import ProjectGroup from '../dialogs/ProjectGroup';
import RowInfoDialog from './projects/RowInfoDialog';
import { colors, lighten, darken } from '../styles/colors';

class AdminProjectGroups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      rowInfo: {},
      newGroupDialogVisible: false,
      rowInfoDialogVisible: false,
    };

    fetchUtil
      .post('/admin/getAllProjectGroups')
      .then((res) => this.setState({ groups: res.groups }));

    this.handleToggleNewGroupDialog = this.handleToggleNewGroupDialog.bind(
      this
    );
    this.handleAfterSave = this.handleAfterSave.bind(this);
    this.handleDeleteFolder = this.handleDeleteFolder.bind(this);
    this.handleArchiveFolder = this.handleArchiveFolder.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }

  handleAfterSave() {
    fetchUtil.post('/admin/getAllProjectGroups').then((res) => {
      this.setState({ groups: res.groups });
      this.handleToggleNewGroupDialog();
    });
  }

  handleToggleNewGroupDialog() {
    const { newGroupDialogVisible } = this.state;
    this.setState({ newGroupDialogVisible: !newGroupDialogVisible });
  }

  handleDeleteFolder(e, projectGroupID) {
    e.stopPropagation();

    fetchUtil
      .post('/project/deleteGroup', {
        projectGroupID,
      })
      .then(() => {
        fetchUtil
          .post('/admin/getAllProjectGroups')
          .then((res) => this.setState({ groups: res.groups }));
      });
  }

  handleArchiveFolder(e, projectGroupID) {
    e.stopPropagation();

    fetchUtil
      .post('/admin/folders/archiveGroup', {
        projectGroupID,
      })
      .then(() => {
        fetchUtil
          .post('/admin/getAllProjectGroups')
          .then((res) => this.setState({ groups: res.groups }));
      });
  }

  handleReviveFolder(e, projectGroupID) {
    e.stopPropagation();

    fetchUtil
      .post('/admin/folders/reviveGroupByID', {
        projectGroupID,
      })
      .then(() => {
        fetchUtil
          .post('/admin/getAllProjectGroups')
          .then((res) => this.setState({ groups: res.groups }));
      });
  }

  onRowClick(row) {
    this.setState({ rowInfoDialogVisible: true, rowInfo: row });
  }

  render() {
    const {
      groups,
      newGroupDialogVisible,
      rowInfoDialogVisible,
      rowInfo,
    } = this.state;

    const headers = [
      {
        id: 'id',
        value: 'ID',
        width: 50,
      },
      {
        id: 'folder',
        value: 'Folder',
      },
      {
        id: 'status',
        value: 'Status',
      },
      {
        id: 'created',
        value: 'Created',
      },
      {
        id: 'projects',
        value: 'Projects',
      },
      {
        id: 'actions',
        value: 'Actions',
      },
    ];

    const rows = groups.map((group) => {
      let statusComponent = <DeletedTag>Deleted</DeletedTag>;
      if (group.status === 1) statusComponent = <ActiveTag>Active</ActiveTag>;
      else if (group.status === 2)
        statusComponent = <ArchivedTag>Archived</ArchivedTag>;

      return {
        id: group.projectGroupID.toString(),
        group,
        trello: group.data.trello,
        folder: group.name,
        status: statusComponent,
        created: moment(group.createdOn).format('MM-DD-YYYY'),
        projects: group.projects.length,
        actions: (
          <div>
            {group.status === 1 && (
              <Icon
                icon={['fad', 'archive']}
                onClick={(e) =>
                  this.handleArchiveFolder(e, group.projectGroupID)
                }
              />
            )}
            {(group.status === 2 || group.status === 0) && (
              <Icon
                icon={['fad', 'feather']}
                onClick={(e) =>
                  this.handleReviveFolder(e, group.projectGroupID)
                }
              />
            )}
            {group.status > 0 && (
              <Icon
                icon={['fad', 'trash-alt']}
                onClick={(e) =>
                  this.handleDeleteFolder(e, group.projectGroupID)
                }
              />
            )}
          </div>
        ),
      };
    });

    return (
      <Container>
        <HeaderContainer>
          <Button
            onClick={this.handleToggleNewGroupDialog}
            leftIcon
            thin
            box
            color="white"
            backgroundColor={colors.blue}
          >
            <FontAwesomeIcon icon={['fad', 'folder-plus']} />
            New folder
          </Button>
          <Dialog
            isVisible={newGroupDialogVisible}
            handleHideDialog={this.handleToggleNewGroupDialog}
          >
            <ProjectGroup
              onAfterSave={this.handleAfterSave}
              handleToggleDialog={this.handleToggleNewGroupDialog}
            />
          </Dialog>
          <Dialog
            isVisible={rowInfoDialogVisible}
            handleHideDialog={() =>
              this.setState({ rowInfoDialogVisible: !rowInfoDialogVisible })
            }
          >
            <RowInfoDialog row={rowInfo} />
          </Dialog>
        </HeaderContainer>
        <Table headers={headers} rows={rows} onRowClick={this.onRowClick} />
      </Container>
    );
  }
}

const Container = styled.div``;

const HeaderContainer = styled.div`
  display: flex;
  padding: 15px;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 10px;
  &.button.icon {
    font-size: 14px;
  }
`;

const ActiveTag = styled.div`
  display: inline-flex;
  padding: 3px 6px;
  border-radius: 3px;
  background: ${lighten(colors.green, 40)};
  color: ${darken(colors.green, 10)};
`;

const DeletedTag = styled(ActiveTag)`
  background: ${lighten(colors.red, 40)};
  color: ${darken(colors.red, 10)};
`;

const ArchivedTag = styled(ActiveTag)`
  background: ${lighten(colors.grey, 50)};
  color: ${lighten(colors.grey, 20)};
`;

AdminProjectGroups.propTypes = {
  propName: PropTypes.string,
};

AdminProjectGroups.defaultProps = {
  propName: '',
};

export default AdminProjectGroups;
