import { post } from '../../util/fetchUtil';

type Props = {
  onAdd?: () => void;
  onRemove?: () => void;
};

export type AddProjectProps = (
  projectID: number,
  fileIDs: number[]
) => Promise<void>;
export type RemoveProjectProps = (
  projectID: number,
  fileIDs: number[]
) => Promise<void>;

const fileProjects = (
  props: Props
): {
  addProject: AddProjectProps;
  removeProject: RemoveProjectProps;
} => {
  const { onAdd, onRemove } = props;

  const addProject = async (projectID: number, fileIDs: number[]) => {
    await post('/project/createProjectFileLink', {
      projectID,
      fileIDs,
    });

    onAdd?.();
  };

  const removeProject = async (projectID: number, fileIDs: number[]) => {
    await post('/project/removeFiles', {
      fileIDs,
      projectID,
    });

    onRemove?.();
  };

  return { addProject, removeProject };
};

export default fileProjects;
