import hotkeyManager from '../editorHotkeys';
import undoStack from '../undoStackInstance';
import { loadElement } from '../load';
import { isInputActive } from '../../keydown';
import { isMac } from '../../device';

import tools from '../../../ui/editor/tools';
import flattenElements from '../util/flattenElements';

// Change to cmd if on mac, otherwise use ctrl
const ctrlCmd = isMac() ? 'cmd' : 'ctrl';

export default ({
  handleGroupElements,
  handleUngroupElements,
  handleCloneElements,
  handleRemoveElements,
  elementsToUpdate,
  handlePlay,
  setElements,
  setSnackbarMessage,
  setCopiedElements,
  setSelectedElements,
  setProgress,
  setSelectedTool,
  undoPressed,
  selectedElements,
  copiedElements,
  gridVisible,
  setGridVisible,
  progress,
  duration,
  elements,
  handleSetLocked,
}) => {
  hotkeyManager.register({
    UNDO_ELEMENT_CHANGE: {
      // Undo
      name: 'z',
      modifiers: [ctrlCmd],
      onPress: (e) => {
        e.preventDefault();

        const undo = undoStack.undo();
        if (undo) {
          // eslint-disable-next-line no-param-reassign
          elementsToUpdate.current = flattenElements(elements).map(
            (el) => el.id
          );
          // Flag that undo was pressed so we don't add to undostack on elements update
          undoPressed.current = true;
          // Load all elements in the undostack
          setElements(undo.elements.map((el) => loadElement(el)));

          setSnackbarMessage('Undo action');
        }
      },
    },
    REDO_ELEMENT_CHANGE: {
      // Redo
      name: 'z',
      modifiers: ['shift', ctrlCmd],
      onPress: (e) => {
        e.preventDefault();

        const redo = undoStack.redo();
        if (redo) {
          // eslint-disable-next-line no-param-reassign
          elementsToUpdate.current = flattenElements(elements).map(
            (el) => el.id
          );
          // Flag that undo was pressed so we don't add to undostack on elements update
          undoPressed.current = true;
          // Update elements
          setElements(redo.elements.map((el) => loadElement(el)));

          setSnackbarMessage('Redo action');
        }
      },
    },
    SELECT_ALL_ELEMENTS: {
      name: 'a',
      modifiers: [ctrlCmd],
      priority: 1,
      onPress: (e) => {
        e.preventDefault();
        setSelectedElements(elements);
        return true;
      },
    },
    DESELECT_ELEMENT: {
      name: 'escape',
      modifiers: [],
      onPress: (e) => {
        if (selectedElements.length) {
          e.preventDefault();
          setSelectedElements([]);
        }
      },
    },
    LOCK_ELEMENT: {
      name: 'l',
      modifiers: [ctrlCmd],
      onPress: (e) => {
        if (selectedElements) {
          e.preventDefault();
          handleSetLocked(selectedElements, true);

          setSnackbarMessage(`${selectedElements.length} elements locked`);
        }
      },
    },
    UNLOCK_ELEMENT: {
      name: 'l',
      modifiers: ['shift', ctrlCmd],
      onPress: (e) => {
        if (selectedElements) {
          e.preventDefault();
          handleSetLocked(selectedElements, false);

          setSnackbarMessage(`${selectedElements.length} elements unlocked`);
        }
      },
    },
    GROUP_ELEMENTS: {
      // Group elements
      name: 'g',
      modifiers: [ctrlCmd],
      onPress: (e) => {
        if (selectedElements) {
          e.preventDefault();
          handleGroupElements(selectedElements);
        }
      },
    },
    UNGROUP_ELEMENTS: {
      // Ungroup elements
      name: 'g',
      modifiers: ['shift', ctrlCmd],
      onPress: (e) => {
        if (selectedElements) {
          e.preventDefault();
          handleUngroupElements(selectedElements);
        }
      },
    },
    COPY_ELEMENTS: {
      // Copy elements
      name: 'c',
      modifiers: [ctrlCmd],
      onPress: (e) => {
        if (selectedElements.length) {
          e.preventDefault();
          setSnackbarMessage(`Copied ${selectedElements.length} elements`);
          setCopiedElements(selectedElements);
        }
      },
    },
    PASTE_ELEMENTS: {
      // Paste elements
      name: 'v',
      modifiers: [ctrlCmd],
      onPress: (e) => {
        if (copiedElements.length) {
          e.preventDefault();

          setSnackbarMessage(`Pasted ${copiedElements.length} elements`);

          const newElements = handleCloneElements(copiedElements);
          setSelectedElements(newElements);
        }
      },
    },
    SCRUB_TIMELINE_RIGHT: {
      // scrub right
      name: 'arrowright',
      modifiers: [],
      onPress: (e) => {
        e.preventDefault();

        const increment = ((1 / 30) * 1000) / duration;
        setProgress(progress + increment);
      },
    },
    SCRUB_TIMELINE_LEFT: {
      // scrub left
      name: 'arrowleft',
      modifiers: [],
      onPress: (e) => {
        e.preventDefault();

        const decrement = ((1 / 30) * 1000) / duration;
        setProgress(progress - decrement);
      },
    },
    TOGGLE_PLAY: {
      // Toggle play
      name: 'space',
      modifiers: [],
      onPress: (e) => {
        e.preventDefault();
        handlePlay();
      },
    },
    DELETE_ELEMENTS: {
      // Remove elements
      names: ['backspace', 'delete'],
      priority: 3,
      modifiers: [],
      onPress: (e) => {
        if (selectedElements.length && !isInputActive()) {
          e.preventDefault();
          handleRemoveElements(selectedElements);
        }
      },
    },
    TOGGLE_GRID: {
      name: "'",
      modifiers: [ctrlCmd],
      onPress: (e) => {
        e.preventDefault();
        setGridVisible(!gridVisible);
      },
    },
    // Tool selector shortcuts
    ...Object.values(tools)
      .filter((tool) => tool.hotkeyId)
      .reduce(
        (obj, tool) => ({
          ...obj,
          [tool.hotkeyId]: {
            name: tool.hotkey,
            onPress: (e) => {
              e.preventDefault();
              setSelectedTool(tool.id);
            },
          },
        }),
        {}
      ),
  });
};
