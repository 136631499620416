import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { useHistory } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { chooseFromFiles } from '../../app/fileManager';
import { Store } from '../../state/store';
import useFileTagger from '../../app/hooks/useFileTagger';
import KitProps from '../../types/KitProps';
import { post } from '../../util/fetchUtil';
import { loadFiles } from '../../utilities/files';
import Box, { Flex } from '../Box';
import FileProps from '../editor/types/FileProps';
import { H1, H3, P } from '../styles/typography';
import AssetPreviewWrapper from './AssetPreviewWrapper';
import AssetControls from './controls/AssetControls';
import FileProjects from './controls/FileProjects';
import FileDownload from './controls/FileDownload';
import FileTagger from './controls/FileTagger';
import FileInfo from './controls/FileInfo';
import KitActions from './controls/KitActions';
import Button from '../RebassButton';
import colors from '../../styles/themeColors';
import KitUploader from './KitUploader';
import WarningDialog from '../dialogs/WarningDialog';
import Dialog from '../Dialog';
import useModal from '../../app/hooks/useModal';
import LoadingSpinner from '../LoadingSpinner';

type Files = { [index: string]: File };

interface Props {
  files: FileProps[];
  setFiles: (files: FileProps[]) => void;
  handleUploadFiles: (filelist: Files) => Promise<void>;
}

const saveSteps = [
  'Saving files',
  'Linking projects',
  'Linking kits',
  'Linking tags',
];

const slowPost = async (url: string, options: any) => {
  const [res] = await Promise.all([
    post(url, options),
    new Promise((r) => setTimeout(r, 1000)),
  ]);
  return res;
};

const UploadStaging = (props: Props) => {
  const { files, setFiles, handleUploadFiles } = props;
  const {
    state: { user },
  } = useContext(Store);

  const [selectedFiles, setSelectedFiles] = useState<FileProps[]>([]);
  const [fileTaggerUserInput, setFileTaggerUserInput] = useState('');
  const [saveProgress, setSaveProgress] = useState(
    saveSteps.map((step) => ({ step, status: 'pending' }))
  );
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState('');

  const history = useHistory();
  const warningModal = useModal();
  const loadingModal = useModal();
  const errorModal = useModal();

  useEffect(() => {
    // Updated selected file references whenever we update the files
    setSelectedFiles(
      files.filter((f) =>
        selectedFiles.find((file) => file.fileID === f.fileID)
      )
    );
    // eslint-disable-next-line
  }, [files]);

  const handleDiscardAll = () => {
    setFiles([]);
    warningModal.hide();
  };

  const updateSaveProgress = (steps: { step: string; status: string }[]) => {
    setSaveProgress((prev) => {
      return prev.map((step) => {
        const newStep = steps.find((s) => s.step === step.step);
        return newStep ?? step;
      });
    });
  };

  const resetProgress = () => {
    setProcessing(false);
    updateSaveProgress(saveSteps.map((step) => ({ step, status: 'pending' })));
  };

  const handleSave = async () => {
    if (!files.every((f) => f.kits?.length)) {
      setError('Every file must be added to a Kit.');
      errorModal.show();
      return;
    }

    setProcessing(true);
    loadingModal.show();

    const newFiles = files.map((file, i) => {
      return {
        name: file.name,
        url: file.data.url,
        type: file.type,
        size: file.data.size,
        userID: file.data.user.id,
        projects: file.projects?.map((p) => p.projectID) || [],
        kits:
          file.kits?.map((k) => ({
            kitID: k.kitID,
            directoryID: k.directoryID,
          })) || [],
        tags: file.tags?.map((t) => t.tagID) || [],
      };
    });

    const { files: filesWithIDs, error: filesErr } = await post(
      '/files/saveStagedFiles',
      {
        files: newFiles,
      }
    );

    updateSaveProgress([
      { step: 'Saving files', status: filesErr ? 'error' : 'success' },
      { step: 'Linking projects', status: 'inprogress' },
    ]);

    if (filesErr) {
      setError(filesErr.msg);
      errorModal.show();
      resetProgress();
      return;
    }

    const { error: linksErr } = await slowPost('/files/addProjectLinks', {
      files: filesWithIDs,
    });

    updateSaveProgress([
      { step: 'Linking projects', status: linksErr ? 'error' : 'success' },
      { step: 'Linking kits', status: 'inprogress' },
    ]);

    if (linksErr) {
      setError(linksErr.msg);
      errorModal.show();
      resetProgress();
      return;
    }

    const { tags, error: kitsErr } = await slowPost('/files/addKitLinks', {
      files: filesWithIDs,
    });

    updateSaveProgress([
      { step: 'Linking kits', status: kitsErr ? 'error' : 'success' },
      { step: 'Linking tags', status: 'inprogress' },
    ]);

    if (kitsErr) {
      setError(kitsErr.msg);
      errorModal.show();
      resetProgress();
      return;
    }

    const { error: tagsErr } = await slowPost('/files/addFileTagLinks', {
      files: filesWithIDs,
      tags,
    });

    updateSaveProgress([
      { step: 'Linking tags', status: tagsErr ? 'error' : 'success' },
    ]);

    if (tagsErr) {
      setError(tagsErr.msg);
      errorModal.show();
      resetProgress();
      return;
    }

    // Clear local files
    setFiles([]);
    setTimeout(() => {
      resetProgress();
    }, 500);
  };

  const updateTags = () => {
    setFileTaggerUserInput('');
  };

  const handleClickOutside = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelectedFiles([]);
  };

  // Handle switching out the URL property of the file
  const handleUploadVersion = (file: FileProps) => {
    chooseFromFiles(
      async (fileObjects: { [index: string]: File }) => {
        const loadedFiles = await loadFiles([fileObjects[0]], {
          user: {
            id: user.userID,
            name: `${user.givenName} ${user.familyName}`,
          },
        });
        const newFiles = [...files];
        const index = newFiles.findIndex((f) => f.fileID === file.fileID);
        if (index > -1) {
          newFiles[index].data.url = loadedFiles[0].data.url;
          setFiles(newFiles);
        }
      },
      { multiple: false }
    );
  };

  const addProject = async (projectID: number, fileIDs: number[]) => {
    const { project } = await post('/project/getProjectByProjectID', {
      projectID,
    });
    const newFiles = files.map((f) => {
      if (fileIDs.includes(f.fileID)) {
        return {
          ...f,
          projects: [...(f.projects || []), project],
        };
      }

      return f;
    });

    setFiles(newFiles);
  };

  const removeProject = async (projectID: number, fileIDs: number[]) => {
    const newFiles = files.map((f) => {
      if (fileIDs.includes(f.fileID) && f.projects?.length) {
        return {
          ...f,
          projects: f.projects.filter((p) => projectID !== p.projectID),
        };
      }

      return f;
    });

    setFiles(newFiles);
  };

  const addTag = async (tagID: number, fileIDs: number[]) => {
    const { tag } = await post('/tags/getTag', { tagID });

    const newFiles = files.map((f) => {
      if (fileIDs.includes(f.fileID)) {
        return {
          ...f,
          tags: [...(f.tags || []), tag],
        };
      }
      return f;
    });

    setFiles(newFiles);
    updateTags();
    return Promise.resolve();
  };

  const removeTag = (tagID: number, fileIDs: number[]) => {
    const newFiles = files.map((f) => {
      if (fileIDs.includes(f.fileID) && f.tags?.length) {
        return {
          ...f,
          tags: f.tags.filter((t) => tagID !== t.tagID),
        };
      }

      return f;
    });

    setFiles(newFiles);
    updateTags();
    return Promise.resolve();
  };

  const { createTag } = useFileTagger({
    onCreateTag: updateTags,
  });

  const handleSelectFiles = (newFiles: FileProps[]) => {
    // Filter out files that are being deselected
    const newSelectedFiles = selectedFiles.filter(
      (f) => !newFiles.find((file) => file.fileID === f.fileID)
    );

    setSelectedFiles([
      ...newSelectedFiles,
      ...newFiles
        // filter out files that are already selected
        .filter((file) => !selectedFiles.find((f) => f.fileID === file.fileID)),
    ]);
  };

  const handleRemoveFile = (idx: number) => {
    const newFiles = [...files];
    newFiles.splice(idx, 1);
    setFiles(newFiles);
  };

  const handleUpdateFile = (file: FileProps) => {
    const newFiles = [...files];
    const index = newFiles.findIndex((f) => f.fileID === file.fileID);

    if (index > -1) {
      newFiles.splice(index, 1, file);
      setFiles(newFiles);
    }
  };

  const handleUpdateName = (newName: string, file: FileProps) => {
    const newFileAsset = { ...file, name: newName };
    handleUpdateFile(newFileAsset);
  };

  const handleAddToKit = async (kit: KitProps) => {
    const newFiles = files.map((f) => {
      if (
        selectedFiles.find((file) => file.fileID === f.fileID) &&
        !f.kits?.find((k) => k.kitID === kit.kitID) // No repeats
      ) {
        return {
          ...f,
          kits: [...(f.kits || []), kit],
        };
      }

      return f;
    });

    setFiles(newFiles);
  };

  const handleRemoveFromKit = async (kitID: number) => {
    const newFiles = files.map((f) => {
      if (selectedFiles.find((file) => file.fileID === f.fileID)) {
        return {
          ...f,
          kits: f.kits?.filter((k) => k.kitID !== kitID) || [],
        };
      }
      return f;
    });

    setFiles(newFiles);
  };

  const handleAddToKitDirectory = async (
    kitID: number,
    directoryID: number
  ) => {
    const newFiles = files.map((f) => {
      if (selectedFiles.find((file) => file.fileID === f.fileID)) {
        return {
          ...f,
          kits: f.kits?.map((kit) => ({
            ...kit,
            ...(kit.kitID === kitID ? { directoryID } : {}),
          })),
        };
      }

      return f;
    });
    setFiles(newFiles);
  };

  const handleChooseFromFiles = () => {
    chooseFromFiles(
      (f: Files) => {
        handleUploadFiles(f);
      },
      { multiple: true }
    );
  };

  const fileTaggerComponent = (
    <FileTagger
      files={selectedFiles}
      addTag={addTag}
      createTag={createTag}
      removeTag={removeTag}
      userInput={fileTaggerUserInput}
      setUserInput={setFileTaggerUserInput}
      canEdit={true}
      // onTagClick={handleSearchTag}
    />
  );

  const fileProjectComponent = (
    <FileProjects
      files={selectedFiles}
      addProject={addProject}
      removeProject={removeProject}
      canEdit={true}
    />
  );

  const fileInfoComponent = (
    <FileInfo files={selectedFiles} onSaveName={handleUpdateName} />
  );
  const fileDownloadComponent = <FileDownload files={selectedFiles} />;
  const kitActionsComponent = (
    <KitActions
      files={selectedFiles}
      addToKit={handleAddToKit}
      removeFromKit={handleRemoveFromKit}
      addToKitDirectory={handleAddToKitDirectory}
    />
  );

  const saveChangesComponent = (
    <Flex p={2}>
      <Box mr={2}>
        <Button
          enabled={!!files.length}
          variant="outline"
          color="negative"
          onClick={warningModal.show}
        >
          Discard all
        </Button>
      </Box>
      <Box>
        <Button
          enabled={!!files.length}
          bg="positive"
          color="white"
          width="100%"
          onClick={handleSave}
        >
          Save &amp; publish
        </Button>
      </Box>
    </Flex>
  );

  const components = [
    fileInfoComponent,
    fileDownloadComponent,
    fileTaggerComponent,
    fileProjectComponent,
    kitActionsComponent,
  ];

  const getStatusIcon = useCallback((status: string): IconProp => {
    if (status === 'pending') return ['fas', 'circle'];
    if (status === 'success') return ['fas', 'check'];
    return ['fas', 'times'];
  }, []);

  const getStatusColor = useCallback((status: string) => {
    if (['pending', 'inprogress'].includes(status))
      return colors['light-grey-30'];
    if (status === 'success') return colors.positive;
    return colors.negative;
  }, []);

  return (
    <Box sx={{ height: '100%' }}>
      <Dialog
        handleHideDialog={loadingModal.hide}
        isVisible={loadingModal.isVisible}
      >
        {processing && (
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <H3>Processing...</H3>
            {saveProgress.map((step) => (
              <Flex
                key={step.step}
                alignItems="center"
                justifyContent="space-between"
                sx={{ gap: 3 }}
              >
                <P>{step.step}</P>
                <Box>
                  {step.status === 'inprogress' ? (
                    <LoadingSpinner size={18} />
                  ) : (
                    <FontAwesomeIcon
                      icon={getStatusIcon(step.status)}
                      color={getStatusColor(step.status)}
                    />
                  )}
                </Box>
              </Flex>
            ))}
          </Flex>
        )}
        {!processing && !files.length && (
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <H3>Upload complete!</H3>
            <P>Your files can now be found in their appropriate Kits</P>
            <FontAwesomeIcon
              fontSize={32}
              color={colors.positive}
              icon={['fas', 'check-circle']}
            />
          </Flex>
        )}
      </Dialog>
      <Dialog
        handleHideDialog={warningModal.hide}
        isVisible={warningModal.isVisible}
      >
        <WarningDialog
          header="Red alert!"
          confirmText="Discard all"
          cancelText="Nevermind"
          color={colors.negative}
          onConfirm={handleDiscardAll}
          onCancel={warningModal.hide}
        >
          <P>
            You have not yet saved these uploads, they&apos;ll be lost to the
            ether forevermore.
          </P>
        </WarningDialog>
      </Dialog>
      <Dialog
        handleHideDialog={errorModal.hide}
        isVisible={errorModal.isVisible}
      >
        <WarningDialog
          header="Hold up!"
          confirmText="Okay"
          onConfirm={errorModal.hide}
        >
          <P>{error}</P>
        </WarningDialog>
      </Dialog>
      <KitUploader handleUploadFiles={handleUploadFiles}>
        <Flex justifyContent="space-between" sx={{ height: '100%' }}>
          <Flex
            flex={1}
            p={[3, 5]}
            flexDirection="column"
            justifyContent="space-between"
            overflow="auto"
            onClick={handleClickOutside}
          >
            <Box>
              <Button
                variant="transparent"
                color="dark-6"
                startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                onClick={() => history.push('/assets')}
              >
                Kits
              </Button>
              <Flex justifyContent="space-between">
                <H1 mb={4}>My Uploads</H1>
                <Box mr={2}>
                  <Button
                    variant="outline"
                    color="dark-5"
                    onClick={handleChooseFromFiles}
                  >
                    Upload
                  </Button>
                </Box>
              </Flex>
              <Flex flexWrap="wrap">
                {files.map((file, i) => (
                  <Box key={i} m={2}>
                    <AssetPreviewWrapper
                      index={i}
                      file={file}
                      handleUpdateFile={handleUpdateFile}
                      handleSelectFiles={handleSelectFiles}
                      handleRemoveFile={handleRemoveFile}
                      selectedFiles={selectedFiles}
                      handleUploadVersion={handleUploadVersion}
                    />
                  </Box>
                ))}
              </Flex>
              {!files.length && (
                <Flex height="100%" alignItems="center" justifyContent="center">
                  <Button
                    variant="outline"
                    color="dark-6"
                    onClick={handleChooseFromFiles}
                  >
                    <P>Drop or choose from files</P>
                  </Button>
                </Flex>
              )}
            </Box>

            <Flex justifyContent="flex-end">{saveChangesComponent}</Flex>
          </Flex>
          <Box sx={{ overflow: 'auto' }}>
            {!!selectedFiles.length && (
              <AssetControls
                files={selectedFiles}
                getFileData={() => Promise.resolve()}
                handleUpdate={() => {}}
                handleDeselectFiles={() => {}}
                canEdit={true}
                components={components}
              />
            )}
          </Box>
        </Flex>
      </KitUploader>
    </Box>
  );
};

export default UploadStaging;
