import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import CreditsEditor from './CreditsEditor';

import { HeaderSection, SectionTitle } from '../views/ProjectContentsStyles';

import Button from '../RebassButton';

function CreditsSection(props) {
  const {
    downloadFile,
    activeProject,
    activeProject: { projectID, files },
  } = props;

  const handleCreditsBuilderClick = () => {
    window.open(`/credits?projectID=${projectID}`);
  };

  const creditsImage =
    files && files.find((file) => file.data.projectAssetType === 'credits');

  return (
    <Container>
      <HeaderSection>
        <SectionTitle>Credits</SectionTitle>
      </HeaderSection>
      {!creditsImage && (
        <Button
          variant="outline"
          color="dark-6"
          onClick={handleCreditsBuilderClick}
          startIcon={<FontAwesomeIcon icon={['fas', 'plus']} />}
        >
          Add credits
        </Button>
      )}
      {creditsImage && (
        <CreditsThumbnailContainer>
          <Thumbnail>
            <img alt="Credits" src={creditsImage.data.url} />
          </Thumbnail>
          <CreditsThumnbnailActions>
            <CreditsButton
              onClick={handleCreditsBuilderClick}
              startIcon={<FontAwesomeIcon icon={['fad', 'edit']} />}
              variant="outline"
              color="dark-6"
            >
              Edit
            </CreditsButton>
            <CreditsButton
              onClick={() => downloadFile(creditsImage)}
              variant="outline"
              color="dark-6"
            >
              <FontAwesomeIcon icon={['fad', 'file-download']} />
            </CreditsButton>
          </CreditsThumnbnailActions>
        </CreditsThumbnailContainer>
      )}
      <CreditsDivider className="divider" />
      <CreditsEditor activeProject={activeProject} />
    </Container>
  );
}

const Container = styled.div``;

const CreditsDivider = styled.div`
  margin: 20px 0;
`;

const CreditsThumbnailContainer = styled.div``;
const CreditsThumnbnailActions = styled.div`
  display: flex;
  justify-content: center;
`;

const CreditsButton = styled(Button)`
  margin-right: 10px;
`;

const Thumbnail = styled.div`
  width: 100%;
  img {
    width: 100%;
  }
`;

CreditsSection.propTypes = {
  activeProject: PropTypes.shape({
    files: PropTypes.array,
    projectID: PropTypes.number,
  }),
  downloadFile: PropTypes.func.isRequired,
};

CreditsSection.defaultProps = {
  activeProject: '',
};

export default CreditsSection;
