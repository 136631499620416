import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import Button from '../Button';

import { colors, lighten } from '../styles/colors';

function ProjectActions(props) {
  const {
    handleSubscribe,
    handleUnsubscribe,
    handleDeselectAll,
    handleRemoveFromGroup,
    folderIsActive,
    totalSelectedProjects,
  } = props;

  return (
    <Container>
      <FlexContainer>
        <IconButton icon={['fas', 'times']} onClick={handleDeselectAll} />
        <SelectText>{totalSelectedProjects} selected</SelectText>
      </FlexContainer>
      <FlexContainer>
        {folderIsActive && (
          <ActionButton
            className="box basic thin"
            onClick={handleRemoveFromGroup}
          >
            Remove from folder
          </ActionButton>
        )}
        <ActionButton className="box basic thin" onClick={handleSubscribe}>
          Subscribe
        </ActionButton>
        <ActionButton className="box basic thin" onClick={handleUnsubscribe}>
          Unsubscribe
        </ActionButton>
      </FlexContainer>
    </Container>
  );
}

const Container = styled.div`
  padding: 15px;
  background: ${lighten(colors.grey, 50)};

  display: flex;
  justify-content: space-between;
`;

const ActionButton = styled(Button)`
  margin-right: 15px;
  position: relative;
`;

const IconButton = styled(FontAwesomeIcon)`
  padding: 5px 5px 5px 10px;
  font-size: 22px;

  cursor: pointer;

  color: ${colors['light-grey-20']};

  &:focus,
  &:hover {
    color: ${colors['light-grey-10']};
  }

  &:active {
    color: ${colors.grey};
  }
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SelectText = styled.p`
  margin: 0;
  margin-left: 15px;
  font-size: 18px;
  color: ${lighten(colors.grey, 10)};
`;

ProjectActions.propTypes = {
  groups: PropTypes.array,
  groupSelectVisible: PropTypes.bool,
  handleAddToGroup: PropTypes.func.isRequired,
  handleRemoveFromGroup: PropTypes.func.isRequired,
  totalSelectedProjects: PropTypes.number,
};

ProjectActions.defaultProps = {
  groups: [],
  groupSelectVisible: false,
  totalSelectedProjects: 0,
};

export default ProjectActions;
