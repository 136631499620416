import styled, { css } from 'styled-components/macro';

import { motion } from 'framer-motion';

import UserTask from '../profile/UserTask';
import { colors, lighten } from '../styles/colors';
import { H4 } from '../styles/typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Container = styled.div`
  height: 100%;
  background-color: #f4f4f4;

  overflow: scroll;
  padding: 30px;

  display: flex;
  justify-content: center;
`;
export const Column = styled.div`
  flex: 1;

  margin-right: 50px;
  margin-left: 50px;
`;
export const Section = styled.div`
  margin-bottom: 30px;
`;
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Name = styled.h2`
  margin: 0;
`;
export const RolesWrapper = styled.div``;
export const SectionContainer = styled.div``;
export const TagContainer = styled(motion.div)`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  background: ${colors['light-grey-50']};
  border-radius: 3px;
  padding: 4px 0 4px 8px;

  margin-right: 10px;
  margin-bottom: 10px;
`;
export const Icon = styled(FontAwesomeIcon)`
  &.button.icon {
    font-size: 14px;
  }

  margin-left: 15px;
`;
export const TagText = styled.h4`
  margin: 0;
`;
export const ContentValue = styled.p`
  border-radius: 3px;
  background: ${lighten(colors.grey, 50)};
  padding: 3px 6px;

  font-size: 18px;

  margin: 0;

  margin-right: 10px;

  i.button {
    margin-left: 15px;
    padding: 3px 5px;
    display: inline;
  }
`;
export const Header = styled.h3``;
export const Subheader = styled(H4)`
  margin-bottom: 10px;
  margin-top: 15px;
  color: ${colors['light-grey-30']};
`;
export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const AddingContainer = styled.div`
  display: flex;

  > * {
    margin-right: 10px;
  }
`;
export const StyledUserTask = styled(UserTask)`
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${colors['light-grey-50']};

  ${(props) =>
    props.completed &&
    css`
      margin-bottom: 5px;
      padding-bottom: 5px;
    `}
`;
export const CompletedAnimation = styled(motion.div)``;
