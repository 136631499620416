import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useRef, useEffect } from 'react';

import fetchUtil from '../../util/fetchUtil';

import ProjectTagger from './ProjectTagger';

import {
  HeaderSection,
  SectionTitle,
  Icon,
} from '../views/ProjectContentsStyles';

function TagSection(props) {
  const { activeProject } = props;

  const [tags, setTags] = useState([]);
  const currentProject = useRef();

  const getTagData = async () => {
    const res = await fetchUtil.post('/tags/getProjectTags', {
      projectID: activeProject.projectID,
    });

    setTags(res.tags);
  };

  useEffect(() => {
    // Prevent multiple requests for the same resources
    if (
      activeProject.projectID &&
      activeProject.projectID !== currentProject.current
    ) {
      getTagData();
      currentProject.current = activeProject.projectID;
    }
    // eslint-disable-next-line
  }, [activeProject]);

  return (
    <Container>
      <HeaderSection>
        <SectionTitle>
          <Icon icon={['fad', 'tag']} />
          Tags
        </SectionTitle>
      </HeaderSection>
      <ProjectTagger
        tags={tags}
        project={activeProject}
        getTagData={getTagData}
      />
    </Container>
  );
}

const Container = styled.div``;

TagSection.propTypes = {
  activeProject: PropTypes.shape({
    name: PropTypes.string,
    projectID: PropTypes.number,
  }),
};

TagSection.defaultProps = {};

export default TagSection;
