import Quill from 'quill/core';

import Toolbar from 'quill/modules/toolbar';
import Snow from 'quill/themes/snow';

import Bold from 'quill/formats/bold';
import Italic from 'quill/formats/italic';
import Header from 'quill/formats/header';
import Underline from 'quill/formats/underline';
import Link from 'quill/formats/link';
import List, { ListItem } from 'quill/formats/list';
import Image from 'quill/formats/image';

import Icons from 'quill/ui/icons';

Quill.register({
  'modules/toolbar': Toolbar,
  'themes/snow': Snow,
  'formats/bold': Bold,
  'formats/italic': Italic,
  'formats/header': Header,
  'formats/underline': Underline,
  'formats/link': Link,
  'formats/list': List,
  'formats/list/item': ListItem,
  'formats/image': Image,
  'ui/icons': Icons,
});

const icons = Quill.import('ui/icons');

icons.image = '<i class="fad fa-image" />';
icons.list.ordered = '<i class="fad fa-list-ol" />';
icons.list.bullet = '<i class="fad fa-list-ul" />';
icons.bold = '<i class="fad fa-bold" aria-hidden="true"></i>';
icons.italic = '<i class="fad fa-italic" aria-hidden="true"></i>';
icons.underline = '<i class="fad fa-underline" aria-hidden="true"></i>';
icons.link = '<i class="fad fa-link" aria-hidden="true"></i>';
icons.clean = '<i class="fad fa-eraser" aria-hidden="true"></i>';

export default Quill;
