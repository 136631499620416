import styled from 'styled-components/macro';

import React, { useState, useRef, useEffect } from 'react';

import { post } from '../../../util/fetchUtil';

import { getDirSettings, loadDirSettings } from '../../../app/assetDirs';

import useClickOutside from '../../../app/hooks/useClickOutside';

import Folder from './Folder';

import Warning from '../../dialogs/WarningDialog';
import Dialog from '../../Dialog';
import Box, { Flex } from '../../Box';

import {
  StyledTextInput,
  NewFolderButton,
  StyledIcon,
} from './FileExplorerStyles';

import { colors } from '../../styles/colors';
import KitProps from '../../../types/KitProps';
import { KitDirectoryProps } from '../../../types/KitDirectoryProps';
import FileProps from '../../editor/types/FileProps';
import { FileObjectProps } from '../../../types/Browser';
import { FilterProps } from '../Kit';

const UnorganizedFolder = styled(Folder)`
  border: 1px solid ${colors['light-grey-50']};
  border-radius: 3px;
  padding: 3px;
`;

type Props = {
  kit: KitProps;
  filters: FilterProps[];
  selectedFiles: FileProps[];
  setFilters: (filters: FilterProps[]) => void;
  selectFiles: (files: FileProps[], e: React.MouseEvent) => void;
  setSelectedDir: (dir?: KitDirectoryProps) => void;
  handleUpdate?: () => Promise<void>;
  canEdit?: boolean;
  unorganizedVisible?: boolean;
  selectedDir?: KitDirectoryProps;
  uploadToKit?: (files: FileObjectProps[]) => Promise<FileProps[]>;
};

const unorganizedFolderId = -1;

export type DirectorySettingsProps = {
  directoryID: number;
  open: boolean;
};

const FileExplorer: React.FC<Props> = (props: Props) => {
  const {
    kit,
    setFilters,
    filters,
    selectFiles,
    selectedFiles,
    uploadToKit,
    handleUpdate,
    canEdit,
    unorganizedVisible,
    selectedDir,
    setSelectedDir,
  } = props;

  const [directories, setDirectories] = useState<KitDirectoryProps[]>([]);
  const [directorySettings, setDirectorySettings] = useState<
    DirectorySettingsProps[]
  >([]);
  const [unorganizedSettings, setUnorganizedSettings] = useState([
    {
      directoryID: unorganizedFolderId,
      open: false,
    },
  ]);
  const [newFolderName, setNewFolderName] = useState('');
  const [isEditingNewFolder, setIsEditingNewFolder] = useState(false);

  // Create special folder for any assets that aren't "organized"
  const [
    unorganizedFileDir,
    setUnorganizedFileDir,
  ] = useState<KitDirectoryProps>({
    kitID: -1,
    data: { files: [] },
    createdOn: '',
    modified: '',
    files: [],
    name: 'Not in folder',
    directoryID: unorganizedFolderId,
    open: false,
    dirs: [],
  });

  const [warningDialogVisible, setWarningDialogVisible] = useState(false);
  const [dirToDelete, setDirToDelete] = useState<KitDirectoryProps>();

  const boxRef = useRef();

  const saveSettings = () => {
    const dirSettings = getDirSettings(directories);
    window.localStorage.setItem('kitSettings', JSON.stringify(dirSettings));
  };

  const updateDirectories = (dirs: KitDirectoryProps[]) => {
    setDirectories(dirs);
  };

  const updateUnorganized = (files: FileProps[]) => {
    setUnorganizedFileDir({
      ...unorganizedFileDir,
      files,
    });
  };

  const getKitDirectories = async () =>
    post('/assets/getKitDirectories', {
      kitID: kit.kitID,
    }).then((res) => {
      updateUnorganized(res.unorganizedFiles);
      updateDirectories(res.directories);
      setDirectorySettings(
        res.flattenedDirectories.map((dir: KitDirectoryProps) => ({
          directoryID: dir.directoryID,
          open: !!directorySettings.find(
            (s) => s.directoryID === dir.directoryID
          )?.open,
        }))
      );
    });

  useEffect(() => {
    getKitDirectories();
    // eslint-disable-next-line
  }, [filters]);

  const handleSelectDir = (dir?: KitDirectoryProps) => {
    if (dir) {
      setFilters([
        ...filters.filter((f) => f.attr !== 'directories'), // only allow one directories filter
        {
          attr: 'directories',
          value: dir.directoryID,
          name: dir.name,
        },
      ]);
    } else {
      setFilters(filters.filter((filter) => filter.attr !== 'directories'));
    }

    // Select dir
    setSelectedDir(dir);
  };

  const handleSelectFile = (
    e: React.MouseEvent,
    file: FileProps,
    dir: KitDirectoryProps
  ) => {
    if (
      !selectedDir ||
      (selectedDir && dir.directoryID !== selectedDir.directoryID)
    )
      handleSelectDir(dir);
    selectFiles([file], e);
  };

  const handleClickOutside = () => {
    setIsEditingNewFolder(false);
  };

  useClickOutside(boxRef, handleClickOutside);

  const loadSettings = () => {
    const settingsString = localStorage.getItem('kitSettings');
    if (settingsString) {
      const settings = JSON.parse(settingsString);
      const newDirs = loadDirSettings(directories, settings);
      setDirectories(newDirs);
    }
  };

  // Load saved settings on mount
  useEffect(() => {
    loadSettings();
    // eslint-disable-next-line
  }, []);

  // Save dir settings any time selected dir changes
  useEffect(() => {
    saveSettings();
    // eslint-disable-next-line
  }, [selectedDir]);

  const handleDeleteDirectoryConfirm = (
    e: React.MouseEvent,
    dir: KitDirectoryProps
  ) => {
    e.stopPropagation();
    setDirToDelete(dir);

    setWarningDialogVisible(true);
  };

  const handleDeleteDirectory = async () => {
    const dir = dirToDelete;

    let res = null;
    if (dir) {
      res = await post('/assets/deleteKitDirectory', {
        kitID: kit.kitID,
        directoryID: dir.directoryID,
      });
    }

    if (res) {
      updateUnorganized(res.unorganizedFiles);
      updateDirectories(res.directories);
    }

    setWarningDialogVisible(false);
  };

  const handleAddFiles = async (dir: KitDirectoryProps, files: FileProps[]) => {
    const res = await post('/assets/addDirectoryFiles', {
      fileIDs: files.map((f) => f.fileID),
      directoryID: dir.directoryID,
      kitID: kit.kitID,
    });

    updateUnorganized(res.unorganizedFiles);
    updateDirectories(res.directories);
    if (handleUpdate) handleUpdate();
  };

  const handleRemoveFile = async (dir: KitDirectoryProps, file: FileProps) => {
    const res = await post('/assets/removeDirectoryFile', {
      fileID: file.fileID,
      directoryID: dir.directoryID,
      kitID: kit.kitID,
    });

    updateUnorganized(res.unorganizedFiles);
    updateDirectories(res.directories);
  };

  const handleCreateFolder = async (name: string, parentID?: number) => {
    setIsEditingNewFolder(false);
    setNewFolderName('');

    const res = await post('/assets/addKitDirectory', {
      kitID: kit.kitID,
      name,
      parentID,
      files: [],
    });

    updateDirectories(res.directories);
    // Add new dir to settings array
    setDirectorySettings([
      ...directorySettings,
      {
        directoryID: res.directoryID,
        open: false,
      },
    ]);
  };

  const handleChangeFolderName = async (
    dir: KitDirectoryProps,
    name: string
  ) => {
    const res = await post('/assets/updateDirectoryName', {
      kitID: kit.kitID,
      name,
      directoryID: dir.directoryID,
    });

    updateDirectories(res.directories);
  };

  const handleToggleFolder = (dir: KitDirectoryProps) => {
    const newDirSettings = Array.from(directorySettings);
    const directorySetting = newDirSettings.find(
      (d) => d.directoryID === dir.directoryID
    );

    if (directorySetting) directorySetting.open = !directorySetting.open;

    setDirectorySettings(newDirSettings);
  };

  const handleToggleUnorganized = () => {
    setUnorganizedSettings([
      {
        ...unorganizedSettings[0],
        open: !unorganizedSettings[0].open,
      },
    ]);
  };

  const folders = directories.map((dir) => (
    <Folder
      key={dir.directoryID}
      directorySettings={directorySettings}
      dir={dir}
      handleRemoveFile={handleRemoveFile}
      handleChangeFolderName={handleChangeFolderName}
      handleAddFiles={handleAddFiles}
      handleCreateFolder={handleCreateFolder}
      directories={directories}
      setDirectories={setDirectories}
      selectDir={handleSelectDir}
      selectedDir={selectedDir}
      selectedFiles={selectedFiles}
      selectFile={handleSelectFile}
      handleDeleteDirectory={handleDeleteDirectoryConfirm}
      onToggleDir={handleToggleFolder}
      uploadToKit={uploadToKit}
      handleUpdate={handleUpdate}
      editable={canEdit}
    />
  ));

  return (
    <Flex
      style={{
        overflow: 'hidden',
        flexDirection: 'column',
      }}
    >
      <Box style={{ padding: 15, overflow: 'auto', height: '100%' }}>
        {!!unorganizedFileDir.files.length && unorganizedVisible && (
          <UnorganizedFolder
            dir={unorganizedFileDir}
            directorySettings={unorganizedSettings}
            editable={false}
            directories={[]}
            setDirectories={() => {}}
            selectDir={() => handleSelectDir(unorganizedFileDir)}
            selectedDir={selectedDir}
            selectedFiles={selectedFiles}
            selectFile={handleSelectFile}
            onToggleDir={handleToggleUnorganized}
            uploadToKit={uploadToKit}
            handleUpdate={handleUpdate}
          />
        )}

        {folders}
        <Box ref={boxRef}>
          {isEditingNewFolder && (
            <StyledTextInput
              value={newFolderName}
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setNewFolderName(e.currentTarget.value)
              }
              onClick={(e: React.MouseEvent) => e.stopPropagation()}
              handleEnterPress={() => handleCreateFolder(newFolderName)}
              placeholder="Enter folder name..."
            />
          )}
          {canEdit && (
            <NewFolderButton
              onClick={() => setIsEditingNewFolder(!isEditingNewFolder)}
              style={{ display: isEditingNewFolder ? 'none' : 'inline-block' }}
            >
              <StyledIcon
                style={{ color: colors['light-grey-30'] }}
                icon="plus"
                prefix="far"
              />
              New folder
            </NewFolderButton>
          )}
        </Box>
      </Box>
      <Dialog
        isVisible={warningDialogVisible}
        handleHideDialog={() => setWarningDialogVisible(false)}
      >
        <Warning
          onConfirm={handleDeleteDirectory}
          onCancel={() => setWarningDialogVisible(false)}
          header="Are you sure you want to delete this folder?"
          color={colors.red}
        />
      </Dialog>
    </Flex>
  );
};

FileExplorer.defaultProps = {
  selectedFiles: [],
  filters: [],
  canEdit: true,
  unorganizedVisible: true,
  setSelectedDir: () => {},
};

export default FileExplorer;
