import styled from 'styled-components/macro';

import Button from '../Button';

import { colors, lighten } from '../styles/colors';
import { P } from '../styles/typography';

export const Container = styled.div`
  background: ${lighten(colors.grey, 57)};
  border-left: 1px solid ${colors['light-grey-50']};
  width: 300px;

  height: 100%;
`;
export const UpperContainer = styled.div``;
export const LowerContainer = styled.div`
  padding: 10px;
`;
export const WideButton = styled(Button)`
  width: 100%;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
`;
export const HeaderBar = styled.div`
  background: ${lighten(colors.grey, 53)};
  width: 100%;
  padding: 5px 10px;
`;
export const InfoContainer = styled.div`
  padding: 10px 15px;
`;
export const FileInfoContainer = styled.div``;
export const FileName = styled(P)`
  color: ${colors.grey};
`;
export const FileSize = styled(P)`
  margin-bottom: 10px;

  color: ${colors['light-grey-30']};
`;
export const Divider = styled.div`
  height: 1px;
  background-color: ${colors['light-grey-50']};
  width: 100%;
`;
