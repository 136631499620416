import React from 'react';

import Box from '../../Box';

import {
  Container,
  UpperContainer,
  Wrapper,
  FileInfoContainer,
  Divider,
} from '../ControlsStyles';
import FileProps from '../../editor/types/FileProps';

type Props = {
  files: FileProps[];
  getFileData: () => Promise<void>;
  handleUpdate: () => void;
  handleDeselectFiles: () => void;
  canEdit: boolean;
  components?: React.ReactChild[];
};

function AssetControls(props: Props) {
  const { files, components } = props;

  return (
    <Container>
      {files && (
        <Wrapper>
          <UpperContainer>
            <FileInfoContainer>
              {components?.map((Component, i) => (
                <Box key={i}>
                  {Component}
                  <Divider />
                </Box>
              ))}
            </FileInfoContainer>
          </UpperContainer>
        </Wrapper>
      )}
    </Container>
  );
}

AssetControls.defaultProps = {
  files: null,
  canEdit: true,
};

export default AssetControls;
