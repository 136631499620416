import styled from 'styled-components/macro';

import { motion } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, lighten } from '../styles/colors';
import { TextInput } from '../Input';
import Message from '../Message';
import Button from '../Button';

export const Container = styled.div``;
export const RightContainer = styled.div`
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;

  overflow: hidden;
`;
export const NameContainer = styled.div`
  overflow: hidden;
`;
export const RemoveLink = styled(Button)`
  margin-left: 15px;
`;
export const LinkContainer = styled.div`
  padding: 10px 15px;
  border: 1px solid ${colors['light-grey-50']};
  border-radius: 3px;

  display: flex;
  align-items: center;

  overflow: hidden;

  cursor: pointer;

  margin-bottom: 10px;

  :hover,
  :focus {
    background: ${lighten(colors.grey, 55)};
  }

  :active {
    background: ${lighten(colors.grey, 52)};
  }
`;
export const LinkIcon = styled(FontAwesomeIcon)`
  margin-right: 15px;
  font-size: 22px;

  width: 50px;
  text-align: center;

  color: ${colors['light-grey-20']};
`;
export const LinkImage = styled.div`
  width: 50px;
  margin-right: 15px;
  padding: 5px;

  display: flex;
  align-items: center;

  img {
    width: 100%;
  }
`;
export const LinkName = styled.h4`
  margin: 0;
  margin-bottom: 5px;
`;
export const LinkUrl = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  color: ${colors['light-grey-30']};
`;
export const InputContainer = styled(motion.div)`
  display: flex;
  margin-top: 10px;
  > *:not(:last-child) {
    margin-right: 10px;
  }
`;
export const StyledTextInput = styled(TextInput)`
  flex: 1;
`;
export const AddLinkButton = styled(Button)`
  width: 100%;
`;
export const StyledMessage = styled(Message)`
  margin-top: 10px;
  width: 100%;
`;
