import { Box } from 'rebass';
import styled, { css } from 'styled-components/macro';

import { colors, lighten } from '../styles/colors';
import elevations from '../styles/elevations';

export const Wrapper = styled.div`
  display: inline-flex;
  width: 250px;
  min-height: 100px;
  border-radius: 5px;
  flex-direction: column;
  overflow: hidden;

  cursor: pointer;

  margin: 10px;

  transition: all 0.1s ease-in-out;

  :hover,
  :focus {
    transform: translateY(-5px);
    box-shadow: ${elevations['2']};
  }

  ${(props: { isSelected: boolean }) =>
    props.isSelected &&
    css`
      background-color: ${lighten(colors.blue, 40)};
      :hover,
      :focus {
        transform: translateY(0);
        box-shadow: none;
      }
    `}
`;
export const HFlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const LowerContainer = styled(HFlexContainer)`
  margin-top: 10px;
`;
export const ProjectContainer = styled.div`
  flex: 1;

  background-color: white;

  transition: 0.1s ease-in-out all;
  border-radius: 5px;

  ${(props: { isSelected: boolean }) =>
    props.isSelected &&
    css`
      transform: scale(0.9);
    `}
`;
export const LeftContainer = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
`;
export const ProjectName = styled.p`
  margin: 0;
  margin-bottom: 5px;
  font-weight: bold;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const LastModified = styled.p`
  margin: 0;
  font-size: 14px;
  color: ${colors['light-gray-20']};
`;

const avatarWidth = 30;
export const AvatarContainer = styled.div`
  position: relative;
  width: ${avatarWidth * 2}px;
  height: ${avatarWidth}px;
`;
export const AvatarImage = styled.div`
  width: ${avatarWidth}px;
  height: ${avatarWidth}px;
  border-radius: 100%;
  overflow: hidden;

  position: absolute;
  top: 0;
  left: ${(props: { positionIndex: number }) => props.positionIndex * 15}px;

  img {
    width: 100%;
  }
`;
export const IconPreviewContainer = styled.div`
  display: flex;
`;
export const IconContainer = styled.div`
  cursor: pointer;

  i,
  svg {
    font-size: 24px;
    color: ${colors['light-grey-30']};
  }

  &:not(:last-of-type) {
    margin-right: 5px;
  }

  &:hover,
  &:focus {
    i,
    svg {
      color: ${colors['light-grey-20']};
    }
  }

  &:active {
    i,
    svg {
      color: ${colors['light-grey-10']};
    }
  }
`;
export const SelectIcon = styled(Box)`
  display: flex;
  justify-content: flex-end;

  transition: 0.2s ease-in-out all;

  font-size: 24px;
  color: ${colors['light-grey-30']};

  opacity: 0;

  i,
  svg {
    transition: 0.2s ease-in-out all;
    transform: scale(0.75);
    padding: 0 0 0 5px;

    &:hover,
    &:focus {
      color: ${colors['light-grey-20']};
    }

    &:active {
      color: ${colors['light-grey-10']};
    }
  }

  ${(props: { isSelected?: boolean }) =>
    props.isSelected &&
    css`
      opacity: 1;
      i,
      svg {
        transform: scale(1);
        color: ${colors.blue};

        :hover,
        :focus {
          color: ${colors['dark-blue-10']};
        }

        :active {
          color: ${colors['dark-blue-20']};
        }
      }
    `}

  ${Wrapper}:hover & {
    opacity: 1;
    i,
    svg {
      transform: scale(1);
    }
  }
`;
export const ProjectInfoContainer = styled.div`
  overflow: hidden;
`;
