// UPDATE_SKIP
import React from 'react';

import { StoreProvider } from '../state/store';
import Global from '../styles/Global';

// Third party libraries
import '../styles/libs';

import App from './App';

const AppWrapper = () => (
  <StoreProvider>
    <Global />
    <App />
  </StoreProvider>
);

export default AppWrapper;
