// HACK: For some reason, sometimes the next dash starts to appear
// on the opposite end when the animation is at 0, resulting in a tiny dot.
// Adding 2 more px of length seems to clear it up.
const setDashoffset = (el: SVGPathElement) => {
  const pathLength = el.getTotalLength() + 2;
  el.setAttribute('stroke-dasharray', pathLength.toString());
  return pathLength;
};

export default setDashoffset;
