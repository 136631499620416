import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { getDurationUntilDatetime } from '../app/utilities/durations';

import Label from './Label';

/**
 * A date label that changes colors depending on how near the given date is
 *
 * @prop {String} date - a date string
 * @prop {Boolean} showIcon - whether to show a calendar icon or not
 * @prop {Number} size - the font size for the label
 * @returns
 */
function DateLabel(props) {
  const { date, showIcon, size } = props;

  const duration = getDurationUntilDatetime(date, 'days');

  let color = 'grey';
  let icon = 'calendar';
  if (duration < 3) color = 'orange';
  if (duration < 0) {
    color = 'red';
    icon = 'calendar-exclamation';
  }

  return (
    <Label size={size} color={color}>
      {showIcon && <Icon icon={['fad', icon]} />}
      {date}
    </Label>
  );
}

const Icon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

DateLabel.propTypes = {
  date: PropTypes.string,
  showIcon: PropTypes.bool,
  size: PropTypes.number,
};

DateLabel.defaultProps = {
  date: '',
  showIcon: true,
  size: 14,
};

export default DateLabel;
