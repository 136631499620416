import { Path } from 'paper';
// https://css-tricks.com/transforms-on-svg-elements/

import { keyframeMap, typesMap } from '../ui/editor/keyframeMap';
import { isNumber } from '../utilities/numbers';
import easings from '../app/easings';
import uuid from '../app/utilities/uuid';
import pathUtil from '../app/editor/path';

import { decomposeMatrix, isFlipped } from '../app/matrix';

/**
 * Base svg element class
 *
 * @class SvgElement
 */
class SvgElement {
  constructor(args) {
    const {
      type, // Required
      id = uuid(),
      duration = 10000,
      start = 0,
      props = {},
      currentProps = {},
      keyframesOpen = true,
      keyframes = {},
      name = '',
      animationPathD = '',
      maskElements = [],
      locked = false,
    } = args;

    // Unique identifier
    this.id = id;

    // Name of the layer
    this.name = name;

    // Element type, e.g. 'rect' or 'path'
    this.type = type;

    // Element duration
    this.duration = duration;
    this.start = start;

    // Element locked state
    this.locked = locked;

    // props that are passed to the component
    this.props = props;

    this.currentProps = {
      // Initialize transform props
      translateX: 0,
      translateY: 0,
      scaleX: 1,
      scaleY: 1,
      rotate: 0,
      skewX: 0,
      skewY: 0,

      style: {},
      ...this.props,
      width: props.width,
      height: props.height,
      opacity: props.opacity || 1,
      ...currentProps, // Override with any passed current props
    };

    // Whether the keyframes dropdown is visible or not
    this.keyframesOpen = keyframesOpen;

    // Array of keyframes
    this.keyframes = keyframes;

    // Whether this layer maintains its aspect ratio by default when resizing
    this.maintainAspect = true;

    // An animation path to follow
    this.animationPathD = animationPathD
      ? pathUtil.toAbsolute(animationPathD) // Use Snap to make the path absolute if it exists
      : '';

    this.keyframeAnimations = {
      'animation-path': this.animationPath,
    };

    this.cache = {
      // Save a cache of the max and min keyframe so we're only
      // recalculating props when we have to
      animationPath: new Path({}),
      keyframes: {
        min: null,
        max: null,
        updatedMax: false,
        updatedMin: false,
      },
    };

    // Default easing
    this.easing = 'easeInOutCubic';

    // Add any masking elements
    this.maskElements = maskElements;

    if (Object.keys(this.keyframes).length) this.cacheKeyframes();
    this.cacheAnimationPath();
  }

  cacheAnimationPath() {
    this.cache.animationPath.setPathData(this.animationPathD);
  }

  cacheTransform() {
    this.cache.transform = {
      translateX: this.currentProps.translateX,
      translateY: this.currentProps.translateY,
      scaleX: this.currentProps.scaleX,
      scaleY: this.currentProps.scaleY,
      rotate: this.currentProps.rotate,
    };
  }

  clearTransformCache() {
    delete this.cache.transform;
  }

  /**
   * Adds a keyframe to the list of this element's keyframes
   *
   * @param {String} key - a key identifier for the keyframe type, e.g. 'opacity'
   * @param {Number} value - the value to use
   * @param {Number} absoluteTime - the current time from the timeline
   * @param {Func} [callback=() => {}]
   * @memberof SvgElement
   */
  addKeyframe(key, value, absoluteTime, callback = () => {}) {
    // Get the relative keyframe time
    const time = absoluteTime - this.start;

    const timeOffset = 50; // Have a buffer of +/- 50 ms for adding new keyframes (i.e. update existing keyframes if you're within 50ms)
    const keyframeDef = keyframeMap[key];

    if (keyframeDef) {
      // Create keyframes array if it doesn't exist yet
      if (!this.keyframes[key]) {
        this.keyframes[key] = [];
      }

      const newKeyframesArray = [...this.keyframes[key]];
      let newKeyframe;

      // If there's currently an entry that's already close to the current time (within +/- 50ms)
      if (newKeyframesArray.length) {
        const existingKeyframe = newKeyframesArray.find(
          (entry) =>
            time > entry.time - timeOffset && time < entry.time + timeOffset
        );

        if (existingKeyframe) {
          newKeyframe = existingKeyframe;
        }
      }

      if (!newKeyframe) {
        newKeyframe = {
          time,
          id: uuid(),
        };
        newKeyframesArray.push(newKeyframe);
      }

      // Create new keyframe
      // Use the passed value or the current value of the property
      Object.keys(keyframeDef).forEach((prop) => {
        newKeyframe[prop] = isNumber(value) ? value : this.currentProps[prop];
      });

      // Update the property
      this.updateKeyframes({
        ...this.keyframes,
        [key]: newKeyframesArray,
      });

      callback();
    } else {
      // eslint-disable-next-line no-console
      console.error(`No keyframe definition for ${key}`);
    }
  }

  deleteKeyframe(key, id) {
    const index = this.keyframes[key].findIndex((k) => k.id === id);
    const newKeyframes = { ...this.keyframes };
    newKeyframes[key].splice(index, 1);

    this.updateKeyframes(newKeyframes);
  }

  /**
   * Returns information about the keyframe cache
   */
  getKeyframeCache(currentTime) {
    const relativeTime = currentTime - this.start;
    const keyframesCache = this.cache.keyframes;
    const isBelowMin = relativeTime <= keyframesCache.min;
    const isAboveMax = relativeTime >= keyframesCache.max;

    // If we're in between the min and max, then we must update
    if (!isBelowMin && !isAboveMax) {
      return { shouldUpdate: true, isBelowMin, isAboveMax };
    }

    let useKeyframeCache =
      (isBelowMin && keyframesCache.updatedMin) ||
      (isAboveMax && keyframesCache.updatedMax);

    // If we're below the minimum but we still haven't updated the element (should update once)
    if (isBelowMin && !keyframesCache.updatedMin) {
      useKeyframeCache = false;
    }

    // If we're above the maximum but we still haven't updated the element (should update once)
    if (isAboveMax && !keyframesCache.updatedMax) {
      useKeyframeCache = false;
    }

    return { shouldUpdate: !useKeyframeCache, isBelowMin, isAboveMax };
  }

  /**
   * Sets relevant keyframe cache information
   */
  setKeyframeCache(currentTime) {
    const keyframesCache = this.cache.keyframes;
    const cache = this.getKeyframeCache(currentTime);

    // If we're in between the min and max, then we must update
    if (!cache.isBelowMin && !cache.isAboveMax) {
      this.cache.keyframes.updatedMin = false;
      this.cache.keyframes.updatedMax = false;
    }

    // If we're below the minimum but we still haven't updated the element (should update once)
    if (cache.isBelowMin && !keyframesCache.updatedMin) {
      this.cache.keyframes.updatedMin = true;
      this.cache.keyframes.updatedMax = false;
    }

    // If we're above the maximum but we still haven't updated the element (should update once)
    if (cache.isAboveMax && !keyframesCache.updatedMax) {
      this.cache.keyframes.updatedMax = true;
      this.cache.keyframes.updatedMin = false;
    }
  }

  /**
   * Computes current props based on the current time and element keyframes
   *
   * @param {Number} currentTime - time in ms
   * @memberof SvgElement
   */
  setCurrentProps(currentTime) {
    const cache = this.getKeyframeCache(currentTime);
    this.setKeyframeCache(currentTime);

    // Check to see if an update is required
    if (cache.shouldUpdate) {
      const relativeTime = currentTime - this.start;

      const keyframeKeys = Object.keys(this.keyframes);
      // Update all keyframe types (position, etc.)
      keyframeKeys.forEach((type) => {
        const keyframesArray = this.keyframes[type];
        if (keyframesArray.length) {
          // Make sure keyframes are sorted by their time
          const sortedKeyframes = keyframesArray.sort(
            (a, b) => a.time - b.time
          );
          // Find the first keyframe that occurs before the playhead
          const keyframeStart = sortedKeyframes
            .filter((k) => k.time < relativeTime)
            .reduce(
              (maxK, k) => (k.time > maxK.time ? k : maxK),
              sortedKeyframes[0]
            );

          // Find the first keyframe that occurs after the playhead
          const keyframeEnd = sortedKeyframes
            .filter((k) => k.time > relativeTime)
            .reduce(
              (minK, k) => (k.time < minK.time ? k : minK),
              sortedKeyframes[sortedKeyframes.length - 1]
            );

          // If the keyframes aren't the same
          const transformDuration = keyframeEnd.time - keyframeStart.time;
          const transformTimeProgress = relativeTime - keyframeStart.time;

          let keyframeProgress;
          // If they're the same, we're outside of the keyframes
          if (keyframeStart.time === keyframeEnd.time) {
            if (keyframeStart.time > relativeTime) keyframeProgress = 0;
            else keyframeProgress = 1;
          } else {
            // Otherwise compute the current value
            keyframeProgress = easings[this.easing](
              transformTimeProgress / transformDuration
            );
          }

          // Update each propName
          Object.keys(keyframeMap[type] || {}).forEach((propName) => {
            if (keyframeMap[type].update) {
              // Run custom update function if tweening isn't enough
              keyframeMap[type].update(this, keyframeProgress);
            }

            const currentProp =
              keyframeStart[propName] +
              (keyframeEnd[propName] - keyframeStart[propName]) *
                keyframeProgress;

            this.currentProps[propName] = currentProp;
          });
        }
      });
    }
  }

  /**
   * Shifts all keyframe types by the given time
   *
   * @param {Number} time - ms
   * @memberof SvgElement
   */
  moveAllKeyframes(time) {
    Object.keys(this.keyframes).forEach((key) => {
      this.moveKeyframes(key, time);
    });
  }

  /**
   * Shifts all keyframes of a given keyframe type
   *
   * @param {String} type - the keyframe type, e.g. 'position'
   * @param {Number} time - ms
   * @memberof SvgElement
   */
  moveKeyframes(type, time) {
    if (this.keyframes[type]) {
      const newKeyframes = {
        ...this.keyframes,
        [type]: this.keyframes[type].map((keyframe) => ({
          ...keyframe,
          time: keyframe.time + time,
        })),
      };

      this.updateKeyframes(newKeyframes);
    }
  }

  /**
   * Shifts a single keyframe type
   *
   * @param {String} type - the keyframe type, e.g. 'position'
   * @param {Number} time - ms
   * @memberof SvgElement
   */
  moveKeyframe(type, id, time) {
    if (this.keyframes[type]) {
      const newKeyframes = { ...this.keyframes };
      const index = newKeyframes[type].findIndex((k) => k.id === id);
      if (index > -1) {
        newKeyframes[type][index].time += time;

        this.updateKeyframes(newKeyframes);
      }
    }
  }

  /**
   * Adds a keyframe group the the keyframes object, e.g. 'opacity'
   *
   * @param {String} type - name of group, e.g. 'opacity' or 'scale', see keyframeMap for all options
   * @memberof SvgElement
   */
  addKeyframeGroup(type) {
    if (
      !this.keyframes[type] &&
      // Make sure this type of element can have the passed keyframe type
      typesMap[this.type] &&
      typesMap[this.type].some((k) => k === type)
    ) {
      const newKeyframes = { ...this.keyframes, [type]: [] };
      this.updateKeyframes(newKeyframes);
    }
  }

  /**
   * Removes a keyframe group and all its keyframes
   *
   * @param {String} type - the name of the group, e.g. 'opacity', see keyframeMap for all options
   * @memberof SvgElement
   */
  removeKeyframeGroup(type) {
    if (this.keyframes[type]) {
      const newKeyframes = { ...this.keyframes };
      delete newKeyframes[type];

      this.updateKeyframes(newKeyframes);
    }
  }

  getMinMaxKeyframes() {
    let min = null;
    let max = null;
    Object.values(this.keyframes).forEach((keyframes) => {
      keyframes.forEach((keyframe) => {
        if (!isNumber(min) || !isNumber(max)) {
          min = keyframe.time;
          max = keyframe.time;
        } else {
          if (keyframe.time > max) {
            max = keyframe.time;
          }

          if (keyframe.time < min) {
            min = keyframe.time;
          }
        }
      });
    });

    return { min, max };
  }

  /**
   * Saves the minimum and maximum time for keyframes
   * so we can test against the current time to see if
   * an update is required
   *
   * @memberof SvgElement
   */
  cacheKeyframes() {
    const { min, max } = this.getMinMaxKeyframes();
    this.cache.keyframes = {
      min,
      max,
    };
  }

  /**
   * Transforms the element
   *
   * @param {DOMMatrix} transform
   * @memberof SvgElement
   */
  transform(matrix) {
    const transform = decomposeMatrix(matrix);
    const {
      translateX,
      translateY,
      scaleX,
      scaleY,
      rotate,
      skewX,
      skewY,
    } = transform;

    const invert = isFlipped(transform) ? -1 : 1;

    this.update('currentProps', {
      ...this.currentProps,
      translateX,
      translateY,
      scaleX: scaleX * invert,
      scaleY,
      rotate,
      skewX,
      skewY,
    });
  }

  /**
   * Update hook for keyframes, every time a keyframe is updated,
   * update the keyframe cache. All keyframe updates should go
   * through this.
   *
   * @param {Object} newKeyframes
   * @memberof SvgElement
   */
  updateKeyframes(newKeyframes) {
    this.update('keyframes', newKeyframes);
    // Cache new keyframes
    this.cacheKeyframes();
  }

  clearKeyframeCache() {
    this.cache.keyframes = {
      min: null,
      max: null,
      updatedMax: false,
      updatedMin: false,
    };
  }

  updateHooks(key) {
    if (key === 'animationPathD') this.cacheAnimationPath();
  }

  /**
   * Generic update function, all updates should ultimately go through this
   *
   * @param {String} key
   * @param {Any} value
   * @memberof SvgElement
   */
  update(key, value) {
    this[key] = value;

    this.updateHooks(key, value);
  }
}

export default SvgElement;
