import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import { colors, darken } from '../styles/colors';

function Content(props) {
  const { sections } = props;

  const getSections = (sectionsArray, level = 0, id = '') =>
    sectionsArray.map((section, index) => {
      const newId = `${section.header}`;
      return (
        <Section id={newId} key={newId}>
          <SectionHeader level={level}>{section.header}</SectionHeader>
          <SectionContent>{section.content}</SectionContent>
          {typeof section.img === 'string' ? (
            <SectionImage src={section.img} />
          ) : (
            section.img
          )}
          <SubSections>
            {section.subSections &&
              getSections(section.subSections, level + 1, newId)}
          </SubSections>
        </Section>
      );
    });

  return <Container>{getSections(sections)}</Container>;
}

const Container = styled.div``;

const Section = styled.div``;

const SectionContent = styled.div``;

const SectionImage = styled.img`
  max-width: 700px;
  img {
    width: 100%;
  }
`;

const SubSections = styled.div``;

const SectionHeader = styled.h1`
  ${(props) =>
    props.level === 0 &&
    css`
      font-size: 32px;
      color: ${darken(colors.grey, 20)};
    `}

  ${(props) =>
    props.level === 1 &&
    css`
      font-size: 24px;
      color: ${darken(colors.grey, 15)};
    `}
  
  ${(props) =>
    props.level === 2 &&
    css`
      font-size: 18px;
      color: ${darken(colors.grey, 10)};
    `}
  
  ${(props) =>
    props.level >= 3 &&
    css`
      font-size: 18px;
      color: ${darken(colors.grey, 5)};
    `}
`;

Content.propTypes = {
  sections: PropTypes.array.isRequired,
};

export default Content;
