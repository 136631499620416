import styled, { css } from 'styled-components/macro';

import Box from '../../Box';
import { fade, colors, darken, lighten } from '../../styles/colors';

export const Row = styled(Box)`
  display: flex;
  align-items: center;

  color: white;
  height: ${(props) => props.height || 25}px;
  border-top: 1px solid ${colors['light-night-20']};
`;
export const RowInfo = styled(Row)`
  padding: 0 10px;
`;
export const HeaderRow = styled(Row)`
  padding-left: 5px;
  background-color: ${fade(colors.blue, 90)};
  border-top: 1px solid ${fade(lighten(colors.blue, 30), 50)};
  border-bottom: 1px solid ${fade(lighten(colors.blue, 30), 50)};
`;
export const ElementContainer = styled(Box)`
  position: relative;

  display: flex;
  background-color: ${colors.night};
  color: white;
  height: ${(props) => props.height || 25}px;
  border: 1px solid ${colors['light-night-10']};
  justify-content: space-between;

  border-radius: 4px;

  overflow: hidden;

  :hover,
  :focus {
    background-color: ${darken(colors.night, 2)};
  }
  :active {
    background-color: ${darken(colors.night, 4)};
  }

  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${darken(colors.blue, 30)};
      border: 1px solid transparent;

      :hover,
      :focus {
        background-color: ${darken(colors.blue, 33)};
      }
      :active {
        background-color: ${darken(colors.blue, 37)};
      }
    `}
`;
export const RowContainer = styled(Box)`
  ${(props) =>
    props.active &&
    css`
      background-color: ${fade(colors.blue, 65)};
    `}
`;
