import React, { useContext, useEffect } from 'react';
import '../../util/fontawesome';

import { Route, Redirect, Switch } from 'react-router-dom';

import { Store } from '../../state/store';
import { fetchTeamData } from '../../state/user/actions';
import { fetchNotifications } from '../../state/notifications/actions';
import { fetchSavedWords } from '../../state/misspelledWords/actions';
import { fetchProjectGroups } from '../../state/projects/actions';

import HeaderBar from '../navigation/HeaderBar';
import Home from '../home/Home';
import Project from '../views/Project';
import ProjectContents from '../views/ProjectContents';
import Profile from '../views/Profile';
import Assets from '../assets/Assets';
import RouteRedirects from '../RouteRedirects';

import {
  Container,
  AppContainer,
  HeaderBarContainer,
  Content,
} from '../AppStyles';
import { post } from '../../util/fetchUtil';
import Pronounce from './Pronounce';
import AccessWrapper from '../AccessWrapper';

// eslint-disable-next-line @typescript-eslint/no-var-requires
import { isAllowed } from '../../shared/grants';

declare global {
  interface Window {
    puppeteerActive: boolean;
  }
}

function Main() {
  const {
    state: { isFullScreen, activeProject, user },
    dispatch,
  } = useContext(Store);

  useEffect(() => {
    // Create array of promises
    const promises = [];

    // Only get data from server if puppeteer is not active
    if (!window.puppeteerActive) {
      promises.push(
        fetchTeamData(dispatch),
        fetchNotifications(dispatch),
        fetchSavedWords(dispatch)
      );

      if (isAllowed('content.projects', user.grants))
        fetchProjectGroups(dispatch);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (activeProject?.projectID && user?.userID) {
      post('/project/accessProject', {
        projectID: activeProject.projectID,
      });
    }
  }, [activeProject, user]);

  return (
    <Container>
      <AppContainer>
        <HeaderBarContainer visible={!isFullScreen}>
          <HeaderBar />
        </HeaderBarContainer>
        <Content>
          <RouteRedirects />

          <Switch>
            <Route exact path="/">
              <Redirect to="/home" />
            </Route>
            <Route path="/home/:filter?" exact>
              <AccessWrapper grant="content.projects">
                <Home />
              </AccessWrapper>
            </Route>
            <Route path="/assets" component={Assets} />
            <Route path="/project/:projectID">
              <AccessWrapper grant="content.projects">
                <Project />
              </AccessWrapper>
            </Route>
            <Route path="/projectContents/:projectID/">
              <AccessWrapper grant="content.projects">
                <ProjectContents />
              </AccessWrapper>
            </Route>
            <Route path="/profile">
              <Profile />
            </Route>
            <Route path="/pronounce">
              <Pronounce />
            </Route>
            <Route>
              <Redirect to="/home" />
            </Route>
          </Switch>
        </Content>
      </AppContainer>
    </Container>
  );
}

Main.propTypes = {};

Main.defaultProps = {};

export default Main;
