import styled, { css } from 'styled-components/macro';

import React, { useState } from 'react';
import CustomSearchBox from '../../CustomSearchBox';
import FileExplorer from './FileExplorer';

import Box, { Flex } from '../../Box';
import Text from '../../Text';
import CustomRefinementList from '../../CustomRefinementList';

import { colors, lighten } from '../../styles/colors';
import { P } from '../../styles/typography';

import FileProps from '../../editor/types/FileProps';
import KitProps from '../../../types/KitProps';
import { FileObjectProps } from '../../../types/Browser';
import { KitDirectoryProps } from '../../../types/KitDirectoryProps';
import { FilterProps } from '../Kit';

const Container = styled.div`
  background: ${lighten(colors.grey, 57)};
  border-right: 1px solid ${colors['light-grey-50']};
  width: 350px;

  display: flex;
  flex-direction: column;

  height: 100%;
`;

const ViewButton = styled(P)`
  padding: 5px;
  margin: 5px;

  color: ${colors['light-grey-40']};

  cursor: pointer;

  ${(props: { active?: boolean }) =>
    props.active &&
    css`
      color: ${colors['light-grey-10']};
    `}
`;

type Props = {
  kit: KitProps;
  selectedFiles: FileProps[];
  filters: FilterProps[];
  setFilters: (filters: FilterProps[]) => void;
  selectFiles: (files: FileProps[], e: React.MouseEvent) => void;
  uploadToKit: (files: FileObjectProps[]) => Promise<FileProps[]>;
  setSelectedDir: (dir?: KitDirectoryProps) => void;
  canEdit?: boolean;
  selectedDir?: KitDirectoryProps;
};

const Navigation: React.FC<Props> = (props: Props) => {
  const {
    kit,
    selectedFiles,
    canEdit,
    selectedDir,
    filters,
    selectFiles,
    uploadToKit,
    setSelectedDir,
    setFilters,
  } = props;

  const [view, setView] = useState(kit.kitID ? 'files' : 'search');

  const handleRefineTags = (label: string) => {
    const index = filters.findIndex((filter) => filter.value === label);
    if (index > -1) {
      const newFilters = [...filters];
      newFilters.splice(index, 1);
      setFilters(newFilters);
    } else {
      setFilters([...filters, { attr: 'tags', value: label }]);
    }
  };

  return (
    <Container>
      <Flex c>
        {kit.kitID && (
          <ViewButton
            onClick={() => setView('files')}
            active={view === 'files'}
          >
            Folders
          </ViewButton>
        )}
        <ViewButton
          onClick={() => setView('search')}
          active={view === 'search'}
        >
          Filters
        </ViewButton>
      </Flex>

      <Box style={{ margin: 10 }}>
        <CustomSearchBox placeholder="Search assets..." />
      </Box>

      <Box display={view === 'search' ? 'block' : 'none'}>
        <Box p={3}>
          <Text mb={2} as="h3">
            Tags
          </Text>
          <CustomRefinementList
            attribute="tags"
            itemControls={filters
              .filter((filter) => filter.attr === 'tags')
              .map((filter) => ({
                label: filter.value,
                isRefined: true,
              }))}
            refineControl={handleRefineTags}
            searchable={true}
          />
        </Box>
      </Box>
      {kit.kitID && (
        <Box display={view === 'files' ? 'block' : 'none'}>
          <FileExplorer
            kit={kit}
            selectedFiles={selectedFiles}
            selectFiles={selectFiles}
            filters={filters}
            setFilters={setFilters}
            uploadToKit={uploadToKit}
            canEdit={canEdit}
            setSelectedDir={setSelectedDir}
            selectedDir={selectedDir}
          />
        </Box>
      )}
    </Container>
  );
};

Navigation.defaultProps = {
  selectedFiles: [],
};

export default Navigation;
