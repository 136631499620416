import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import fileManager from '../../../app/fileManager';

import Button from '../../Button';

const Container = styled.div`
  padding: 10px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

function FileAttribution(props) {
  const { files } = props;

  const handleDownload = async () => {
    if (files.length === 1) {
      const file = files[0];
      const blob = await fileManager.fetchFile(file.data.url);
      await fileManager.downloadFile(blob, `${file.name}.${file.type}`);
    } else {
      await fileManager.downloadZipFile(files);
    }
  };

  return (
    <Container>
      <StyledButton basic box color="grey" onClick={handleDownload}>
        Download{files.length > 1 ? ` (${files.length} files)` : ''}
      </StyledButton>
    </Container>
  );
}

FileAttribution.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      data: PropTypes.shape({
        url: PropTypes.string,
      }),
      name: PropTypes.string,
    })
  ),
};

FileAttribution.defaultProps = {
  file: {},
};

export default FileAttribution;
