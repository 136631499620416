import styled from 'styled-components/macro';

import Button from '../Button';
import { colors, fade, lighten } from '../styles/colors';

export const Container = styled.div`
  display: flex;

  border-top: 1px solid ${colors['light-grey-40']};

  margin: 0;
  padding: 15px;
  border-radius: 0;
  width: 100%;

  box-shadow: none;

  /* background: ${lighten(colors.grey, 57)}; */
  background-color: ${colors.night};
`;
export const PlaybackControls = styled.div`
  display: flex;
  align-items: center;

  flex: 1;

  button {
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
`;
export const Controls = styled.div`
  display: flex;
  align-items: center;

  margin-left: 30px;
`;
export const TimelineRangeContainer = styled.div`
  display: flex;
  align-items: center;

  position: relative;

  margin-left: 15px;

  flex: 8;
`;
export const TimelineProgressWrapper = styled.div`
  position: relative;

  height: 40px;
  width: 100%;

  border-radius: 3px;
  overflow: hidden;
`;
export const TimelineProgressContainer = styled.div`
  height: 100%;
  display: flex;

  cursor: pointer;
  background-color: ${colors['light-night-10']};
`;
export const TimelineProgress = styled.div.attrs((props) => ({
  style: {
    width: (props.progressWidth && `${props.progressWidth * 100}%`) || 0,
    backgroundColor: `${lighten(colors.olive, 30)}`,
  },
}))`
  pointer-events: none;
`;

const playheadWidth = 4;
export const TimelinePlayhead = styled.div`
  width: ${playheadWidth}px;
  background-color: ${colors.olive};

  position: relative;
  right: ${playheadWidth}px;

  pointer-events: none;
`;
export const TimeContainer = styled.div`
  display: flex;
  margin-left: 15px;
`;
export const Time = styled.p`
  color: ${colors['light-grey-20']};
`;
export const TimeDivider = styled.p`
  margin-left: 5px;
  margin-right: 5px;

  color: ${colors['light-grey-30']};
`;
export const ControlButton = styled(Button)`
  font-size: 16px;
  padding: 0.5em;

  color: ${fade('white', 30)};

  svg {
    fill: ${fade('white', 30)};
  }
`;
