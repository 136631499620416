import React from 'react';
import { Route, Redirect, useRouteMatch } from 'react-router-dom';

const RouteRedirects = () => {
  const projectMatch = useRouteMatch('/project/:projectID');
  const legacyFilesPath = '/project/:projectID/:fileID(\\d+)';
  const legacyFilesMatch = useRouteMatch(legacyFilesPath);
  const legacyCommentsPath =
    '/project/:projectID/:fileID(\\d+)/comments/:commentID(\\d+)';
  const legacyCommentsMatch = useRouteMatch(legacyCommentsPath);

  return (
    <>
      {/* Redirect projects/projectID to projects/projectID/files */}
      <Route path="/project/:projectID" exact>
        <Redirect
          to={`/project/${projectMatch && projectMatch.params.projectID}/files`}
        />
      </Route>
      {/* Redirect legacy projects/projectID/fileID to projects/projectID/files/fileID */}
      <Route path={'/project/:projectID/:fileID(\\d+)'} exact>
        <Redirect
          to={`/project/${
            legacyFilesMatch && legacyFilesMatch.params.projectID
          }/files/${legacyFilesMatch && legacyFilesMatch.params.fileID}`}
        />
      </Route>
      {/* Redirect legacy projects/projectID/fileID/comments/commentID to projects/projectID/files/fileID/comments/commentID */}
      <Route
        path={'/project/:projectID/:fileID(\\d+)/comments/:commentID(\\d+)'}
        exact
      >
        <Redirect
          to={`/project/${
            legacyCommentsMatch && legacyCommentsMatch.params.projectID
          }/files/${
            legacyCommentsMatch && legacyCommentsMatch.params.fileID
          }/comments/${
            legacyCommentsMatch && legacyCommentsMatch.params.commentID
          }`}
        />
      </Route>
    </>
  );
};

export default RouteRedirects;
