import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled, { css } from 'styled-components/macro';

import Button from './Button';

import { colors, getColorCombination, darken } from './styles/colors';

type Props = {
  color?: string;
  backgroundColor?: string;
  size?: number;
  onClick?: (e: React.MouseEvent) => void;
  onClose?: (e: React.MouseEvent) => void;
  children?: React.ReactNode;
  className?: string;
  round?: boolean;
};

/**
 * A basic label component
 *
 * @param {Object} props
 * @prop {String} color - a string color name, e.g. 'blue'
 * @prop {Number} size - the font size for the label
 * @returns
 */
const Label: React.FC<Props> = (props: Props) => {
  const {
    color = 'grey',
    backgroundColor,
    size,
    onClick,
    onClose,
    children,
    className,
    round,
  } = props;

  const clickable = !!onClick;

  const handleOnClose = (e: React.MouseEvent) => {
    if (onClose) onClose(e);
  };

  return (
    <Container
      className={className}
      color={color}
      size={size}
      clickable={clickable}
      onClick={onClick}
      round={round}
      hasClose={!!onClose}
      backgroundColor={backgroundColor}
    >
      {onClose && (
        <Button
          icon
          style={{
            fontSize: 10,
            height: '1.5em',
            width: '1.5em',
            marginRight: 3,
            opacity: 0.75,
            color,
          }}
          onClick={handleOnClose}
        >
          <FontAwesomeIcon icon={['fas', 'times']} />
        </Button>
      )}
      {children}
    </Container>
  );
};

type ContainerProps = {
  color: string;
  size?: number;
  round?: boolean;
  hasClose?: boolean;
  clickable?: boolean;
  backgroundColor?: string;
};

const Container = styled.div`
  background: ${colors['light-grey-40']};
  color: ${colors.grey};
  border-radius: ${(props: ContainerProps) => (props.round ? '50px' : '3px')};
  padding: 0.25em
    ${(props: ContainerProps) => (props.round ? '0.85em' : '0.65em')};
  ${(props: ContainerProps) =>
    props.hasClose &&
    css`
      padding-left: 0.5em;
    `}

  font-size: ${(props) => props.size}px;

  display: inline-block;

  transition: all 0.1s ease-in-out;

  ${(props) =>
    props.color &&
    css`
      background-color: ${getColorCombination(props.color).background ||
      props.backgroundColor};
      color: ${getColorCombination(props.color).color || props.color};
    `}

  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: ${darken(
          getColorCombination(props.color).background,
          5
        )};
        color: ${darken(getColorCombination(props.color).color, 5)};
      }

      &:active {
        background-color: ${darken(
          getColorCombination(props.color).background,
          10
        )};
        color: ${darken(getColorCombination(props.color).color, 10)};
      }
    `}
`;

export default Label;
