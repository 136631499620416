import React from 'react';
import PropTypes from 'prop-types';
import { PropertyContainer,
  Label,
 } from './PropertyStyles';
import DurationEdit from '../DurationEdit';

import ElementProps from '../props/ElementProps';

/**
 * Display and allow editing of durations
 */
const Durations = (props) => {
  const { selectedElements, handleUpdateElement } = props;

  let { duration } = selectedElements[0];

  if (selectedElements.length > 1) {
    if (selectedElements.some((el) => el.duration !== duration)) {
      duration = null;
    }
  }

  const onUpdate = (newDuration) => {
    selectedElements.forEach((el) => {
      el.update('duration', newDuration);
    });

    handleUpdateElement(selectedElements.map((el) => el.id));
  };

  return (
    <PropertyContainer>
      <Label>Duration</Label>
      <DurationEdit duration={duration} onUpdate={onUpdate} />
    </PropertyContainer>
  );
};

const { arrayOf, func } = PropTypes;

Durations.propTypes = {
  selectedElements: arrayOf(ElementProps),
  handleUpdateElement: func,
};

export default Durations;
