export const getSearchParams = (url) => {
  const newUrl = new URL(url);
  const params = [...newUrl.searchParams.entries()];
  return params.reduce(
    (obj, curr) => ({
      ...obj,
      [curr[0]]: curr[1],
    }),
    {}
  );
};

export default {
  getSearchParams,
};
