import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fetchUtil from '../../../util/fetchUtil';

import roles from '../../../shared/roles';

import InputConfirm from '../../InputConfirm';
import Button from '../../Button';
import OptionSelector from '../../OptionSelector';
import LoadingSpinner from '../../LoadingSpinner';
import { TextInput } from '../../Input';

import { colors, lighten } from '../../styles/colors';

/**
 * A single row for a Trello board
 *
 * @returns
 */
function BoardRow(props) {
  const { board, saveTrelloBoard, removeTrelloBoard } = props;

  const [isLoading, setIsLoading] = useState(true);

  const [boardName, setBoardName] = useState('');
  const [boardLists, setBoardLists] = useState([]);

  const [boardIdValue, setBoardId] = useState(board.id);
  const [isEditingBoardId, setIsEditingBoardId] = useState(false);
  const [newListId, setNewListId] = useState('');

  const taskOptions = roles.map((role) => ({
    ...role,
    name: role.taskName,
    id: role.taskName,
  }));

  /**
   * Gets trello board data based on the entered board ID
   */
  const getTrelloInformation = () => {
    fetchUtil
      .post('/trello/getBoardData', {
        boardId: board.id,
      })
      .then((res) => {
        if (res.board.id) setBoardName(res.board.name);
        if (Array.isArray(res.lists)) setBoardLists(res.lists);

        setIsLoading(false);
      });
  };

  /**
   * Save the entered trello board ID
   */
  const saveTrelloBoardId = () => {
    board.id = boardIdValue;

    saveTrelloBoard(board);

    setIsEditingBoardId(false);
  };

  /**
   * Add the trello list to the given trello board
   * Osmosify will now "watch" this list for new cards
   */
  const addTrelloList = () => {
    if (!newListId) return;

    const newList = {
      id: newListId,
    };

    if (board.lists) {
      board.lists.push(newList);
    } else {
      board.lists = [newList];
    }

    saveTrelloBoard(board);

    setNewListId('');
  };

  /**
   * Stop watching a trello list
   */
  const removeTrelloList = (list) => {
    const index = board.lists.findIndex((l) => l.id === list.id);
    board.lists.splice(index, 1);

    saveTrelloBoard(board);
  };

  /**
   * Associate a role with a trello list, Osmosify will "watch" for when
   * this project moves out of this list and update tracking accordingly
   */
  const handleAddListRole = (listId, option) => {
    const { taskName } = option;
    const task = roles.find((t) => t.taskName === taskName);

    const newBoard = { ...board };
    const listIndex = newBoard.lists.findIndex((l) => l.id === listId);

    const newList = { ...newBoard.lists[listIndex], taskID: task.taskID };

    newBoard.lists.splice(listIndex, 1, newList);

    saveTrelloBoard(newBoard);
  };

  useEffect(() => {
    getTrelloInformation();
    // eslint-disable-next-line
  }, []);

  const lists =
    board &&
    board.lists &&
    board.lists.map((list) => {
      let name = '';
      if (boardLists.length) {
        const trelloList = boardLists.find((l) => l.id === list.id);
        name = trelloList ? trelloList.name : '[LIST NOT FOUND]';
      }

      // Get associated task if it exists
      const task = roles.find(
        (t) =>
          (t.taskID && t.taskID.toString()) ===
          (list.taskID && list.taskID.toString())
      );

      return (
        <ListContainer key={list.id}>
          <FlexContainer>
            {name && (
              <Name title={`List ID: ${list.id}`}>{name}&nbsp;-&nbsp;</Name>
            )}
            <OptionSelector
              options={taskOptions}
              onSelectOption={(option) => handleAddListRole(list.id, option)}
              selectedOption={
                task ? taskOptions.find((t) => t.taskID === task.taskID) : null
              }
              buttonClasses="thin box basic"
              placeholder="Choose task..."
              initialOption={task ? task.taskName : null}
            />
            <Button
              className="transparent icon"
              onClick={() => removeTrelloList(list)}
            >
              <StyledIcon icon={['fas', 'times']} />
            </Button>
          </FlexContainer>
        </ListContainer>
      );
    });

  return (
    <BoardContainer>
      {!isLoading && (
        <div>
          {!isEditingBoardId && (
            <WordsContainer>
              <BoardName>{boardName}&nbsp;-&nbsp;</BoardName>
              <ListID>{boardIdValue}</ListID>
              <Button
                onClick={() => setIsEditingBoardId(true)}
                className="transparent icon"
              >
                <StyledIcon icon={['fad', 'edit']} />
              </Button>
              <Button
                className="transparent icon"
                onClick={() => removeTrelloBoard(board)}
              >
                <StyledIcon icon={['fad', 'trash-alt']} />
              </Button>
            </WordsContainer>
          )}
          {isEditingBoardId && (
            <InputConfirm
              handleInputChange={(e) => setBoardId(e.target.value)}
              handleSaveClick={saveTrelloBoardId}
              handleCancelClick={() => setIsEditingBoardId(!isEditingBoardId)}
              inputValue={boardIdValue}
              placeholder="Enter Trello board ID"
              enableSave={!!boardIdValue}
              cancelButtonVisible={!!boardIdValue}
            />
          )}
          {boardIdValue && (
            <div>
              <ListsContainer>
                <List>{lists}</List>
              </ListsContainer>
              <AddListContainer>
                <FlexContainer>
                  <TextInput
                    className="text-input"
                    value={newListId}
                    onChange={(e) => setNewListId(e.target.value)}
                    placeholder="Enter list id"
                  />
                  <Button onClick={addTrelloList} className="icon transparent">
                    <StyledIcon icon={['fas', 'plus']} />
                  </Button>
                </FlexContainer>
              </AddListContainer>
            </div>
          )}
        </div>
      )}
      {isLoading && (
        <SpinnerContainer>
          <LoadingSpinner />
        </SpinnerContainer>
      )}
    </BoardContainer>
  );
}

BoardRow.propTypes = {
  board: PropTypes.object,
  saveTrelloBoard: PropTypes.func,
  removeTrelloBoard: PropTypes.func,
};

function RowInfoDialog(props) {
  const {
    row: { trello, id },
  } = props;

  const [boards, setBoards] = useState((trello && trello.boards) || []);
  const [newBoardId, setNewBoardId] = useState('');

  /**
   * Saves a trello board's properties to the project group
   */
  const saveTrelloBoard = (board) => {
    let trelloData = {};
    if (trello) trelloData = { ...trello };

    // Find old board and replace with new one
    const newBoards = Array.from(boards);
    newBoards.splice(
      newBoards.findIndex((b) => b.id === board.id),
      1,
      board
    );

    fetchUtil.post('/admin/folders/updateGroupTrelloData', {
      projectGroupID: id,
      trelloData: {
        ...trelloData,
        boards: newBoards,
      },
    });

    setBoards(newBoards);
  };

  /**
   * Add a trello board to this project group
   */
  const newTrelloBoard = () => {
    let trelloData = {};
    if (trello) trelloData = { ...trello };

    const newBoards = Array.from(boards);
    newBoards.push({
      id: newBoardId,
    });

    fetchUtil.post('/admin/folders/updateGroupTrelloData', {
      projectGroupID: id,
      trelloData: {
        ...trelloData,
        boards: newBoards,
      },
    });

    setNewBoardId('');
    setBoards(newBoards);
  };

  /**
   * Remove a trello board and all it's properties from the group
   */
  const removeTrelloBoard = (board) => {
    let trelloData = {};
    if (trello) trelloData = { ...trello };

    // Find old board and remove
    const newBoards = Array.from(boards);
    newBoards.splice(
      newBoards.findIndex((b) => b.id === board.id),
      1
    );

    fetchUtil.post('/admin/folders/updateGroupTrelloData', {
      projectGroupID: id,
      trelloData: {
        ...trelloData,
        boards: newBoards,
      },
    });

    setBoards(newBoards);
  };

  const boardComponents = boards.map((board) => (
    <BoardRow
      key={board.id}
      board={board}
      projectGroupID={id}
      saveTrelloBoard={saveTrelloBoard}
      removeTrelloBoard={removeTrelloBoard}
    />
  ));

  return (
    <Container>
      {boardComponents}
      <AddBoardContainer>
        <TextInput
          className="text-input"
          value={newBoardId}
          onChange={(e) => setNewBoardId(e.target.value)}
          placeholder="Enter Trello board id..."
          type="text"
        />
        <Button className="basic box" onClick={newTrelloBoard}>
          Add board
        </Button>
      </AddBoardContainer>
    </Container>
  );
}

const Container = styled.div``;

const BoardContainer = styled.div`
  margin-bottom: 30px;
  padding: 15px;
  background: ${lighten(colors.blue, 45)};
  border-radius: 5px;
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AddBoardContainer = styled(FlexContainer)`
  margin-top: 15px;
  input {
    margin-right: 15px;
  }
`;
const ListContainer = styled.li``;
const AddListContainer = styled.div`
  margin-top: 5px;
  padding-left: 30px;

  input {
    margin-right: 15px;
  }
`;

const ListsContainer = styled.div`
  margin-bottom: 5px;
`;

const List = styled.ul`
  margin: 0;
`;

const BoardName = styled.h3`
  margin: 0;
`;

const Name = styled.p`
  font-weight: 700;
  margin: 0;
  display: inline-block;
`;
const ListID = styled.span`
  color: ${colors['light-grey-20']};
`;

const WordsContainer = styled.div`
  display: flex;
  align-items: center;
`;
const StyledIcon = styled(FontAwesomeIcon)``;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

RowInfoDialog.propTypes = {
  row: PropTypes.shape({
    trello: PropTypes.object,
  }),
  projectGroupID: PropTypes.number.isRequired,
};

RowInfoDialog.defaultProps = {};

export default RowInfoDialog;
