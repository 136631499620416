import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useRef } from 'react';

import Box from '../Box';
import DraggableSvg from '../DraggableSvg';
import SvgGrid from '../SvgGrid';
import { colors, darken } from '../styles/colors';

import { RefProps } from '../../props/general';

const { number, arrayOf, func, string, shape } = PropTypes;

const Line = styled.line`
  stroke: ${colors.blue};
`;

const StyledCircle = styled.circle`
  fill: ${colors.blue};

  :hover,
  :focus {
    fill: ${darken(colors.blue, 5)};
  }

  :active {
    fill: ${darken(colors.blue, 10)};
  }
`;

function Control(props) {
  const {
    point,
    index,
    svgRef,
    containerRef,
    viewBox,
    handleUpdatePoint,
  } = props;

  const handleMouseMove = (move) => {
    handleUpdatePoint(index, move);
  };

  return (
    <DraggableSvg
      svgRef={svgRef}
      containerRef={containerRef}
      onMouseMove={handleMouseMove}
      viewBox={viewBox}
    >
      <StyledCircle r={3} cx={point[0]} cy={point[1]} />
    </DraggableSvg>
  );
}

Control.propTypes = {
  point: arrayOf(number),
  index: number,
  svgRef: RefProps.isRequired,
  containerRef: RefProps.isRequired,
  viewBox: shape({
    x: number,
    y: number,
    h: number,
    w: number,
  }),
  handleUpdatePoint: func,
};

const height = 100;
const width = 100;
const padding = 10;
const viewBox = {
  x: -padding,
  y: -padding,
  w: width + 2 * padding,
  h: height + 2 * padding,
};

function PressureCurvePicker(props) {
  const { points, setPoints, className, style } = props;

  const pressurePoints = points.map((point) => [
    point[0] * width,
    (1 - point[1]) * height,
  ]);

  const svgRef = useRef();
  const containerRef = useRef();

  const handleUpdatePoint = (index, move) => {
    const newPoints = Array.from(points);
    const point = newPoints[index];
    let newY = point[1] - move.dy / height;

    if (newY >= 0 && newY <= 1) {
      if (Math.round(newY * 100) === 100) newY = 1;

      point[1] = newY;
      setPoints(newPoints);
    }
  };

  const lines = pressurePoints.map((point, i, array) => {
    if (i > 0) {
      return (
        <Line
          key={i}
          x1={array[i - 1][0]}
          y1={array[i - 1][1]}
          x2={point[0]}
          y2={point[1]}
        />
      );
    }

    return null;
  });

  const controls = pressurePoints.map((point, i) => (
    <Control
      point={point}
      index={i}
      containerRef={containerRef}
      svgRef={svgRef}
      viewBox={viewBox}
      handleUpdatePoint={handleUpdatePoint}
      key={i}
    />
  ));

  return (
    <Box ref={containerRef} style={style} className={className}>
      <svg
        ref={svgRef}
        height="100%"
        width="100%"
        viewBox={`${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`}
      >
        <g opacity={0.1}>
          <SvgGrid spacing={10} width={width} height={height} />
          <rect
            width={width}
            height={height}
            x={0}
            y={0}
            stroke="white"
            strokeWidth={2}
            fill="transparent"
          />
        </g>
        {lines}
        {controls}
      </svg>
    </Box>
  );
}

PressureCurvePicker.propTypes = {
  points: arrayOf(arrayOf(number)),
  setPoints: func.isRequired,
  className: string,
  style: shape({}),
};

PressureCurvePicker.defaultProps = {
  points: [],
  className: '',
  style: {},
};

export default PressureCurvePicker;
