import styled from 'styled-components/macro';

import { motion } from 'framer-motion';

import { colors } from '../styles/colors';
import { H4 } from '../styles/typography';

export const Heading = styled(H4)`
  color: ${colors['light-grey-40']};
  margin-bottom: 10px;
`;
export const LabelContainer = styled(motion.div)`
  display: inline-block;
  margin-right: 10px;
  margin-top: 10px;
`;
export const LabelsContainer = styled.div``;
