import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import paper, { Size } from 'paper';

import { useRouteMatch } from 'react-router-dom';

import { AnimatePresence, motion } from 'framer-motion';
import { post } from '../../util/fetchUtil';

// const EditorContext = require('ui/editor/EditorContext');

import handleDropFile from '../../app/editor/helpers/Editor';

import useAnimationFrame from '../../app/hooks/useAnimationFrame';
import useModifier from '../../app/hooks/useModifier';

import create from '../../editor/create';
import { group, ungroup, addToGroup, reorder } from '../../app/editor/group';
import mask from '../../app/editor/mask';
import deleteUtil from '../../app/editor/delete';
import path from '../../app/editor/path';
import { getSvgCoords } from '../../app/editor/coords';
import { saveDebounce } from '../../app/editor/save';
import { loadFromS3 } from '../../app/editor/load';
import cloneElement from '../../app/editor/clone';
import undoStackUtil from '../../app/editor/undoStackUtil';
import editorDefaults from '../../app/editor/defaults';
import keyframes from '../../app/editor/keyframes';
import setupHotkeys from '../../app/editor/helpers/setupHotkeys';

import font from '../../app/font';

import Viewer from './Viewer';
import Toolbar from './Toolbar';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import Timeline from './timeline/Timeline';
import EditorNavigationSceneHeader from '../navigation/EditorNavigationSceneHeader';
import Snackbar from '../Snackbar';
import { Flex } from '../Box';

import { colors, darken } from '../styles/colors';
import LoadingScreen from '../LoadingScreen';
import {
  ElementsArrayProps,
  GroupElementProps,
  MixedElementProps,
} from './types/ElementProps';
import { SceneProps } from './types/SceneProps';
import { CommentProps } from './types/CommentProps';
import { ToolOptionProps } from './tools';
import ErrorProps from './types/ErrorProps';
import {
  getPreference,
  updatePreference,
} from '../../app/editor/preferenceStore';
import Text from '../Text';
import SnackbarContainer from './SnackbarContainer';

// Setup paper canvas
paper.setup(new Size(1000, 1000));

function Editor() {
  const [activeScene, setActiveScene] = useState<SceneProps>();
  const [elements, setElements] = useState<ElementsArrayProps>([]);
  const [selectedElements, setSelectedElements] = useState<ElementsArrayProps>(
    []
  );
  const [selectedTool, setSelectedTool] = useState<ToolOptionProps>('move');
  const [progress, setProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [copiedElements, setCopiedElements] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [duration, setDuration] = useState(0);
  const [gridVisible, setGridVisible] = useState(true);
  const [errors, setErrors] = useState<ErrorProps[]>([]);
  const [layersVisible, setLayersVisible] = useState(
    !!getPreference('timelineLayersVisible')
  );

  const [comments, setComments] = useState([]);
  const [savedAnnotations, setSavedAnnotations] = useState([]);
  const [selectedCommentID, setSelectedCommentID] = useState<number>();

  const [stageDimensions, setStageDimensions] = useState({
    width: 1920,
    height: 1080,
  });

  const hasLoadedInitialElements = useRef(false);
  const svgRef = useRef<SVGElement>(null);
  const snackbarTimer = useRef<any>();
  const undoPressed = useRef(false);
  // Keep track of whether we've dragged-to-cloned (otherwise we'd create a ton of cloned els)
  const hasClonedRef = useRef(false);
  const elementsToUpdate = useRef<string[]>([]);

  const altDown = useModifier('alt').isDown;
  const match = useRouteMatch<{
    projectID: string;
    sceneID: string;
  }>('/project/:projectID/editor/scene/:sceneID');

  const currentTime = progress * duration;

  const handleGetComments = useCallback(async () => {
    if (activeScene) {
      const res = await post('/editor/getSceneComments', {
        sceneID: activeScene.sceneID,
      });

      setComments(res.comments);
    }
  }, [activeScene]);

  useEffect(() => {
    if (activeScene) handleGetComments();
    // eslint-disable-next-line
  }, [activeScene]);

  useEffect(() => {
    // If we just deselected a comment, remove any annotations
    if (!selectedCommentID) {
      setSavedAnnotations([]);
    }
  }, [selectedCommentID]);

  const handleCloneElements = useCallback(
    (els: ElementsArrayProps) => {
      const newElements = els.map((el) => cloneElement(el));

      setElements([...elements, ...newElements]);

      return newElements;
    },
    [elements]
  );

  const handleAddToGroup = useCallback(
    (elementsToAdd: ElementsArrayProps, groupElement: GroupElementProps) => {
      setElements(addToGroup(elementsToAdd, groupElement, elements));
    },
    [elements]
  );

  const handleUngroupElements = useCallback(
    (elementsToUngroup: ElementsArrayProps) => {
      setElements(ungroup(elementsToUngroup, elements));
    },
    [elements]
  );

  const handleSetLayersVisible = useCallback((newLayersVisible: boolean) => {
    updatePreference('timelineLayersVisible', newLayersVisible);
    setLayersVisible(newLayersVisible);
  }, []);

  const handleUpdateDuration = useCallback(
    (newDuration: number) => {
      if (activeScene) {
        const newProgress = (duration / newDuration) * progress;
        setProgress(newProgress);
        setDuration(newDuration);

        post('/scene/updateDuration', {
          sceneID: activeScene.sceneID,
          duration: newDuration,
        });
      }
    },
    [activeScene, progress, duration]
  );

  const handleUpdateProgress = useCallback(
    (delta: number) => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + delta / duration;
        // Clamp progress at 1
        if (newProgress > 1) return 1;

        return newProgress;
      });
    },
    [duration]
  );

  const { controls } = useAnimationFrame(handleUpdateProgress);

  const handlePlay = () => {
    if (!isPlaying) {
      controls.start();
    } else {
      controls.stop();
    }
    setIsPlaying(!isPlaying);
  };

  const handleSkip = useCallback(
    (time: number) => {
      const skipProgress = time / duration;
      const newProgress = skipProgress + progress;
      if (newProgress >= 1) setProgress(1);
      else if (newProgress <= 0) setProgress(0);
      else setProgress(newProgress);
    },
    [duration, progress]
  );

  // Stop animation when we're at 1
  useEffect(() => {
    if (progress === 1 && isPlaying) {
      setIsPlaying(false);
      controls.stop();
    }
    // eslint-disable-next-line
  }, [progress]);

  const handleReorderElement = useCallback(
    (droppedElementId: string, targetElementId: string, newIndex: 0 | 1) => {
      const newElements = reorder(
        droppedElementId,
        targetElementId,
        newIndex,
        elements
      );
      setElements(newElements);
    },
    [elements]
  );

  const handleSelectElements = useCallback((els: ElementsArrayProps) => {
    setSelectedElements(els);
  }, []);

  const handleSetCurrentTime = useCallback(
    (time: number) => {
      const newProgress = time / duration;

      setProgress(newProgress);
    },
    [duration]
  );

  const handleClickComment = useCallback(
    async (comment: CommentProps) => {
      const { timestamp } = comment.data;

      if (comment.data.annotations) {
        const commentAnnotations = await fetch(
          comment.data.annotations
        ).then((res) => res.json());

        setSavedAnnotations(commentAnnotations);
      } else {
        setSavedAnnotations([]);
      }

      if (timestamp) {
        setElements([...elements]);
        handleSetCurrentTime(timestamp);
      }

      setSelectedCommentID(comment.commentID);
    },
    [elements, handleSetCurrentTime]
  );

  const handleSaveElements = useCallback(async () => {
    if (activeScene) {
      setIsSaving(true);
      await saveDebounce(activeScene.data.s3Key, activeScene, elements);
      setIsSaving(false);
    }
  }, [activeScene, elements]);

  const handleUpdateElement = useCallback(
    (elementIds: string[]) => {
      elementsToUpdate.current = elementIds;
      setElements([...elements]);
    },
    [elements]
  );

  const handleGroupElements = useCallback(
    (elementsToGroup: ElementsArrayProps) => {
      const newElements = group(elementsToGroup, elements, {
        duration,
      });
      setElements(newElements);
    },
    [elements, duration]
  );

  const handleAddElement = useCallback(
    (newElement: MixedElementProps) => {
      setElements([...elements, newElement]);
    },
    [elements]
  );

  const handleMaskElement = useCallback(
    (maskingElement: MixedElementProps, elementToMask: MixedElementProps) => {
      setElements(mask(maskingElement, elementToMask, elements));
    },
    [elements]
  );

  const handleRemoveElements = useCallback(
    (elementsToDelete: ElementsArrayProps) => {
      const newElements = deleteUtil.element(elementsToDelete, elements);

      setElements(newElements);
      setSelectedElements([]);
    },
    [elements]
  );

  const fetchActiveScene = useCallback(async () => {
    if (match) {
      const { sceneID } = match.params;
      const res = await post('/editor/getScene', {
        sceneID,
      });
      return res;
    }
  }, [match]);

  const loadScene = useCallback(async () => {
    const res = await fetchActiveScene();

    if (res.scene.data) {
      const sceneDuration = res.scene.data.duration;

      if (res.scene.data.s3Key) {
        const data = await loadFromS3(res.scene.data.s3Key);
        const newElements = data.elements;

        setElements(newElements);
        setProgress(keyframes.getMaxTime(newElements) / sceneDuration);
      } else {
        await saveDebounce(null, res.scene, elements);
      }

      // Flag that the undoStack can start
      hasLoadedInitialElements.current = true;

      setDuration(sceneDuration);
    }

    setActiveScene(res.scene);
    setStageDimensions({
      width:
        (res.scene.data.dimensions && res.scene.data.dimensions.width) || 1920,
      height:
        (res.scene.data.dimensions && res.scene.data.dimensions.height) || 1080,
    });

    setIsLoading(false);
  }, [elements, fetchActiveScene]);

  const handleAddErrors = useCallback(
    (newErrors: ErrorProps[]) => {
      setErrors([...errors, ...newErrors]);
    },
    [errors]
  );

  const loadEditor = async () => {
    await font.init();

    loadScene();
  };

  useEffect(() => {
    loadEditor();
    // eslint-disable-next-line
  }, []);

  // Keep track of which element was updated in a ref to determine which rows to update
  // then clear after each re-render
  useEffect(() => {
    elementsToUpdate.current = [];
  });

  useEffect(() => {
    if (snackbarMessage) {
      // Clear current timer
      clearTimeout(snackbarTimer.current);
      // Set new timer to clear after time
      snackbarTimer.current = setTimeout(() => {
        setSnackbarMessage('');
      }, 5000);
    }
  }, [snackbarMessage]);

  const handleStageClick = useCallback(
    (svg: SVGElement, e: MouseEvent) => {
      if (selectedTool === 'text' && !selectedElements.length) {
        const { x, y } = getSvgCoords(svg, e);
        const text = 'New text';

        const newTextElement = create.text(
          {
            text,
            start: 0,
            duration,
            props: {
              translateX: x,
              translateY: y,
            },
          },
          {
            animStart: currentTime,
          }
        );

        handleAddElement(newTextElement);
        setProgress(
          progress + (editorDefaults.durationPerLetter * text.length) / duration
        );
      }
    },
    [
      currentTime,
      duration,
      handleAddElement,
      progress,
      selectedElements.length,
      selectedTool,
    ]
  );

  const handleStageMouseDown = useCallback(
    (svg: SVGElement, e: React.MouseEvent) => {
      if (!selectedTool || selectedTool === 'move') {
        e.preventDefault();
        const target = e.target as SVGElement;

        if (target.nodeName.toLowerCase() === 'svg') {
          // Clear selected elements if the no tool or the move tool is selected
          if (selectedElements.length) handleSelectElements([]);
        }
      }
    },
    [handleSelectElements, selectedElements.length, selectedTool]
  );

  const handleAddPath = useCallback(
    (e: MouseEvent, initialPoint: { x: number; y: number }) => {
      if (selectedElements.length === 0 && svgRef.current) {
        const animDuration = 1000;
        const newPathElement = create.path(
          {
            duration,
            props: {
              d: path.M(initialPoint.x, initialPoint.y),
              style: {
                strokeWidth: 15,
              },
            },
          },
          {
            animStart: currentTime,
            animDuration,
          }
        );

        newPathElement.addPoint(svgRef.current, e, initialPoint);
        handleAddElement(newPathElement);

        setProgress(progress + animDuration / duration);
        setSelectedElements([newPathElement]);
      }
    },
    [currentTime, duration, handleAddElement, progress, selectedElements.length]
  );

  const handleStageMouseUp = () => {
    // Reset cloned ref and allow another clone
    if (hasClonedRef.current === true) {
      hasClonedRef.current = false;
    }
  };

  const handleStageDrag = useCallback(() => {
    // Clone the selected elements if alt is down
    if (selectedElements.length === 1 && altDown && !hasClonedRef.current) {
      hasClonedRef.current = true;

      // Find index and insert clone one index before
      const index = elements.findIndex(
        (el) => el.id === selectedElements[0].id
      );

      const newElements = [...elements];
      const clonedElement = cloneElement(selectedElements[0]);

      // Scoot keyframes forward by the difference between the min and max keyframe
      const keyframesDuration =
        selectedElements[0].cache.keyframes.max -
        selectedElements[0].cache.keyframes.min;

      selectedElements[0].moveAllKeyframes(keyframesDuration);
      newElements.splice(index, 0, clonedElement);
      setElements(newElements);
    }
  }, [altDown, elements, selectedElements]);

  const handleStageDrop = useCallback(
    async (e: React.DragEvent) => {
      e.preventDefault();

      if (svgRef.current && match) {
        const res = await handleDropFile(e, svgRef.current, {
          progress,
          duration,
          currentTime,
          projectID: parseInt(match.params.projectID, 10),
        });

        if (res.duration > duration) setDuration(res.duration);
        setProgress(res.progress);
        setElements([...elements, ...res.elements]);
      }
    },
    [currentTime, duration, elements, match, progress]
  );

  useEffect(() => {
    // Make sure we don't update the stack after undo / redo has been pressed (which updates state)
    if (
      !undoPressed.current &&
      !(elements.length === 0 && !hasLoadedInitialElements.current) // Skip the first empty array before scene has loaded
    ) {
      undoStackUtil.addElements(elements);
    } else {
      undoPressed.current = false;
    }

    if (hasLoadedInitialElements.current) handleSaveElements();
    // eslint-disable-next-line
  }, [elements]);

  const addKeyframe = useCallback(
    (key: string) => {
      if (selectedElements.length) {
        const element = elements.find((e) => e.id === selectedElements[0].id);
        if (element) element.addKeyframe(key, null, currentTime);
      }
    },
    [currentTime, elements, selectedElements]
  );

  const handleSetLocked = useCallback(
    (elementsToLock: ElementsArrayProps, state: boolean) => {
      elementsToLock.forEach((element) => {
        // eslint-disable-next-line no-param-reassign
        element.locked = state;
      });

      handleUpdateElement(elementsToLock.map((el) => el.id));
    },
    [handleUpdateElement]
  );

  // Register and set up the hotkeys
  setupHotkeys({
    handleGroupElements,
    handleUngroupElements,
    handleCloneElements,
    handleRemoveElements,
    elementsToUpdate,
    handlePlay,
    setElements,
    setSnackbarMessage,
    setCopiedElements,
    setSelectedElements,
    setProgress,
    setSelectedTool,
    handleSetLocked,
    elements,
    undoPressed,
    selectedElements,
    copiedElements,
    progress,
    duration,
    gridVisible,
    setGridVisible,
  });

  // const editorState = {};
  return (
    // <EditorContext.Provider value={editorState}>
    <>
      <AnimatePresence>
        {isLoading && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <LoadingScreen message="Loading scene..." />
          </motion.div>
        )}
      </AnimatePresence>
      <Snackbar
        isVisible={!!snackbarMessage}
        style={{ background: colors.night }}
      >
        <SnackbarContainer>
          <Text color="white">{snackbarMessage}</Text>
        </SnackbarContainer>
      </Snackbar>
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: darken(colors.night, 5),
        }}
      >
        {activeScene && match && (
          <EditorNavigationSceneHeader
            scene={activeScene}
            projectID={match.params.projectID}
            setActiveScene={setActiveScene}
            fetchActiveScene={fetchActiveScene}
            isSaving={isSaving}
          />
        )}
        <Flex flex={layersVisible ? 2 : 1} overflow="hidden">
          <LeftPanel
            elements={elements}
            selectedElements={selectedElements}
            currentTime={currentTime}
            duration={duration}
            elementsToUpdate={elementsToUpdate}
            setSelectedElements={setSelectedElements}
            handleAddElement={handleAddElement}
            handleGroupElements={handleGroupElements}
            handleAddToGroup={handleAddToGroup}
            handleReorderElement={handleReorderElement}
            handleUpdateElement={handleUpdateElement}
            handleCloneElements={handleCloneElements}
            handleRemoveElements={handleRemoveElements}
            handleMaskElement={handleMaskElement}
            isLoading={isLoading}
            isPlaying={isPlaying}
          />
          <Flex style={{ flex: 3, flexDirection: 'column' }}>
            <Toolbar
              setSelectedTool={setSelectedTool}
              selectedTool={selectedTool}
              handleAddElement={handleAddElement}
              duration={duration}
              gridVisible={gridVisible}
              setGridVisible={setGridVisible}
              scene={activeScene}
              currentTime={currentTime}
            />
            <Viewer
              currentTime={currentTime}
              elements={elements}
              selectedElements={selectedElements}
              selectedTool={selectedTool}
              handleUpdateElement={handleUpdateElement}
              handleSelectElements={handleSelectElements}
              handleStageClick={handleStageClick}
              handleStageMouseDown={handleStageMouseDown}
              handleStageMouseUp={handleStageMouseUp}
              handleStageDrag={handleStageDrag}
              handleStageDrop={handleStageDrop}
              handleAddPath={handleAddPath}
              handleAddErrors={handleAddErrors}
              handleAddElement={handleAddElement}
              handleSetCurrentTime={handleSetCurrentTime}
              svgRef={svgRef}
              gridVisible={gridVisible}
              isPlaying={isPlaying}
              stageDimensions={stageDimensions}
              annotations={savedAnnotations}
            />
          </Flex>
          {activeScene && (
            <RightPanel
              scene={activeScene}
              elements={elements}
              selectedElements={selectedElements}
              handleUpdateElement={handleUpdateElement}
              currentTime={progress * duration}
              addKeyframe={addKeyframe}
              duration={duration}
              comments={comments}
              handleGetComments={handleGetComments}
              isPlaying={isPlaying}
              handleClickComment={handleClickComment}
              selectedCommentID={selectedCommentID}
              setSelectedCommentID={setSelectedCommentID}
              errors={errors}
            />
          )}
        </Flex>
        {activeScene && (
          <Timeline
            scene={activeScene}
            elements={elements}
            comments={comments}
            elementsToUpdate={elementsToUpdate}
            selectedElements={selectedElements}
            handleSelectElements={handleSelectElements}
            progress={progress}
            currentTime={currentTime}
            setProgress={setProgress}
            duration={duration}
            handleUpdateDuration={handleUpdateDuration}
            handleUpdateElement={handleUpdateElement}
            handlePlay={handlePlay}
            handleSkip={handleSkip}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            onClickComment={handleClickComment}
            layersVisible={layersVisible}
            setLayersVisible={handleSetLayersVisible}
            canEdit={true}
            isAnnotating={false}
          />
        )}
      </div>
    </>
    // </EditorContext.Provider>
  );
}

const { bool } = PropTypes;

Editor.propTypes = {
  reviewMode: bool,
};

Editor.defaultProps = {
  reviewMode: false,
};

export default Editor;
