import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState, useRef, useContext, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import moment from 'moment';

import fetchUtil from '../../util/fetchUtil';

import { Store } from '../../state/store';
import { setIsFullScreen } from '../../state/panels/actions';
import CommentBar from './CommentBar';

import playback from '../../app/playback';
import playbackRates from '../../app/playbackRates';
import fileManager from '../../app/fileManager';
import { getHash } from '../../shared/links';
import { getBasename, getExtension } from '../../utilities/files';

import Dialog from '../Dialog';

import PlayAllIcon from '../icons/PlayAllIcon';
import PlayAllDialog from './PlayAllDialog';

import {
  Container,
  PlaybackControls,
  Controls,
  TimelineRangeContainer,
  TimelineProgressWrapper,
  TimelineProgressContainer,
  TimelineProgress,
  TimelinePlayhead,
  TimeContainer,
  Time,
  TimeDivider,
  ControlButton,
} from './TimelineStyles';

import useAnimationFrame from '../../app/hooks/useAnimationFrame';
import timelineRefs from './timelineRefs';
import useHotkeys from '../../app/hooks/useHotkeys';

const skipDuration = 5000;

function Timeline(props) {
  const { comments } = props;
  const context = useContext(Store);
  const {
    dispatch,
    state: { activeProject, isFullScreen, activeFile },
  } = context;

  const history = useHistory();

  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const [rate, setRate] = useState(1);
  const [playAllDialogVisible, setPlayAllDialogVisible] = useState(false);
  const [shouldRingBell, setShouldRingBell] = useState(true);

  const [draggingOnTimeline, setDraggingOnTimeline] = useState(false);

  const scenesToPlay = useRef([]);

  const timelineProgressContainerRef = useRef();
  const bellRef = useRef();

  const handleUpdateProgress = (delta) => {
    setCurrentTime((prevCurrentTime) => prevCurrentTime + delta * rate);
  };

  const { controls, isPlaying } = useAnimationFrame(handleUpdateProgress);

  useEffect(() => {
    setDuration(playback.duration);
    setCurrentTime(0);
    // eslint-disable-next-line
  }, [playback.duration]);

  /**
   * Play the SVG
   *
   * @memberof Timeline
   */
  const handlePlay = () => {
    controls.start();
  };

  const handleUpdateRate = () => {
    const rateIndex = playbackRates.findIndex((r) => r === rate);
    let newRateIndex = rateIndex + 1;
    if (newRateIndex === playbackRates.length) {
      newRateIndex = 0;
    }

    setRate(playbackRates[newRateIndex]);

    // Restart
    if (isPlaying) {
      controls.stop();
      controls.start();
    }
  };

  /**
   * Pause the SVG animation
   *
   * @memberof Timeline
   */
  const handlePause = () => {
    scenesToPlay.current = []; // Reset play all feature
    controls.stop();
  };

  /**
   * Skip by the passed duration
   *
   * @param {Number} dur - the duration
   * @memberof Timeline
   */
  const handleSkip = (dur) => {
    const newCurrentTime = currentTime + dur;
    if (newCurrentTime < 0) setCurrentTime(0);
    else if (newCurrentTime > playback.duration)
      setCurrentTime(playback.duration);
    else setCurrentTime(newCurrentTime);
  };

  /**
   * Seek a spot in playback
   *
   * @param {Event} e
   * @memberof Timeline
   */
  const handleSeekPlayback = (progress) => {
    let newProgress = progress;
    if (progress < 0) newProgress = 0;
    if (progress > 1) newProgress = 1;

    const newCurrentTime = newProgress * duration;

    playback.seek(newCurrentTime);
    setCurrentTime(newCurrentTime);
  };

  useEffect(() => {
    // HACK: Allows reading of current time from other places...gets around moving
    // currentTime up to parents and having to deal with optimizing renders.
    timelineRefs.currentTime = currentTime;

    // Stop if we've passed the duration
    if (currentTime > playback.duration) controls.stop();
    else playback.seek(currentTime);
    // eslint-disable-next-line
  }, [currentTime]);

  /**
   * Handle when mouse is pressed down on the timeline
   *
   * @param {Event} e
   * @memberof Timeline
   */
  const handleMouseDown = (e) => {
    // Calculate offset position of mouse
    const width = timelineProgressContainerRef.current.offsetWidth;
    const offset = e.nativeEvent.offsetX;
    const progress = offset / width;

    handleSeekPlayback(progress);

    setDraggingOnTimeline(true);

    // Stop playback if it's playing
    if (isPlaying) controls.stop();
  };

  /**
   * Handle lifting mouse up from timeline
   *
   * @memberof Timeline
   */
  const handleMouseUp = () => {
    setDraggingOnTimeline(false);
  };

  /**
   * Handle mouse move on the timeline
   *
   * @param {Event} e
   * @memberof Timeline
   */
  const handleMouseMove = (nativeEvent) => {
    if (draggingOnTimeline) {
      // Calculate location of mouse relative to the window
      const {
        x,
        width,
      } = timelineProgressContainerRef.current.getBoundingClientRect();
      const { clientX } = nativeEvent;
      handleSeekPlayback((clientX - x) / width);
    }
  };

  const handlePlaySelected = async (files) => {
    // Add files to localStorage
    handleRecordScreen(files.map((f) => f.fileID));
  };

  useHotkeys([
    {
      // F - Fullscreen
      name: 'f',
      modifiers: [],
      onPress: () => {
        setIsFullScreen(!isFullScreen, dispatch);
      },
    },
    {
      // Handle normal play/pause
      name: 'k',
      modifiers: [],
      onPress: () => {
        if (isPlaying) handlePause();
        else handlePlay();
      },
    },
    {
      // Handle play all
      name: 'k',
      modifiers: ['shift'],
      onPress: () => {
        // Shift + K
        setPlayAllDialogVisible(true);
      },
    },
    {
      // Skip forward
      names: ['l', 'arrowright'],
      modifiers: [],
      onPress: () => {
        handleSkip(skipDuration);
      },
    },
    {
      // Skip backward
      names: ['j', 'arrowleft'],
      modifiers: [],
      onPress: () => {
        handleSkip(-skipDuration);
      },
    },
    {
      // Skip backward 10s
      name: '.',
      modifiers: [],
      onPress: () => {
        handleSkip(10);
      },
    },
    {
      // Skip backward 10s
      name: ',',
      modifiers: [],
      onPress: () => {
        handleSkip(-10);
      },
    },
  ]);

  const handleGetScreenshot = async () => {
    const time = currentTime;
    const svgUrl = activeFile.data.url;

    const res = await fetchUtil.post('/getSceneScreenshot', {
      time,
      sceneUrl: svgUrl,
    });

    const blob = await fileManager.fetchFile(res.imageUrl);
    await fileManager.downloadFile(
      blob,
      `${getBasename(activeFile.name)}${getExtension(res.imageUrl)}`
    );
  };

  const handleRecordScreen = (fileIDs) => {
    window.localStorage.setItem(
      'exporterSettings',
      JSON.stringify({
        playBell: shouldRingBell,
      })
    );

    window.localStorage.setItem('screenRecordV1', JSON.stringify(fileIDs));

    window.open('/exporter');
  };

  useEffect(() => {
    // Handlers for moving the mouse on the timeline
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
    // eslint-disable-next-line
  }, [draggingOnTimeline]);

  const formattedCurrentTime = moment(
    moment.duration(currentTime / 1000, 'seconds').asMilliseconds()
  ).format('mm:ss');
  const formattedDuration = moment(
    moment.duration(duration / 1000, 'seconds').asMilliseconds()
  ).format('mm:ss');

  return (
    <Container>
      <audio ref={bellRef} src="/assets/sounds/microwave.mp3" />
      <Dialog
        handleHideDialog={() => setPlayAllDialogVisible(false)}
        isVisible={playAllDialogVisible}
      >
        <PlayAllDialog
          handleHideDialog={() => setPlayAllDialogVisible(false)}
          onPlayClick={(items) => handlePlaySelected(items)}
          project={activeProject}
          shouldRingBell={shouldRingBell}
          setShouldRingBell={setShouldRingBell}
        />
      </Dialog>
      <PlaybackControls>
        <ControlButton
          basic
          icon
          color="white"
          className="tooltip"
          dataTippyContent="J"
          onClick={() => handleSkip(-skipDuration)}
        >
          <FontAwesomeIcon icon={['fad', 'fast-backward']} />
        </ControlButton>
        {!isPlaying && (
          <ControlButton
            basic
            color="white"
            icon
            className="tooltip"
            dataTippyContent="K"
            onClick={handlePlay}
          >
            <FontAwesomeIcon icon={['fas', 'play']} />
          </ControlButton>
        )}
        {isPlaying && (
          <ControlButton
            basic
            color="white"
            icon
            className="tooltip"
            dataTippyContent="K"
            onClick={handlePause}
          >
            <FontAwesomeIcon icon={['fad', 'pause']} />
          </ControlButton>
        )}
        <ControlButton
          basic
          icon
          color="white"
          className="tooltip"
          dataTippyContent="Play all - ⇧K"
          onClick={() => setPlayAllDialogVisible(true)}
        >
          <PlayAllIcon />
        </ControlButton>
        <ControlButton
          basic
          color="white"
          icon
          className="tooltip"
          dataTippyContent="L"
          onClick={() => handleSkip(skipDuration)}
        >
          <FontAwesomeIcon icon={['fad', 'fast-forward']} />
        </ControlButton>
      </PlaybackControls>
      <TimelineRangeContainer>
        <CommentBar
          comments={comments.map((comment) => ({
            ...comment,
            onClick(e) {
              e.stopPropagation();
              history.push(
                getHash({
                  view: 'project',
                  projectID: activeProject.projectID,
                  fileID: activeFile.fileID,
                  commentID: comment.commentID,
                })
              );
            },
          }))}
          duration={duration}
        />
        <TimelineProgressWrapper>
          <TimelineProgressContainer
            onMouseDown={handleMouseDown}
            // onClick={handleTimelineClick}
            ref={timelineProgressContainerRef}
          >
            <TimelineProgress progressWidth={currentTime / duration} />
            <TimelinePlayhead />
          </TimelineProgressContainer>
        </TimelineProgressWrapper>
      </TimelineRangeContainer>
      <TimeContainer>
        <Time>{formattedCurrentTime}</Time>
        <TimeDivider>/</TimeDivider>
        <Time>{formattedDuration}</Time>
      </TimeContainer>
      <Controls>
        <ControlButton
          color="white"
          style={{ marginRight: 10 }}
          onClick={handleGetScreenshot}
          basic
          icon
          dataTippyContent="Download screenshot"
        >
          <FontAwesomeIcon icon={['fad', 'camera']} />
        </ControlButton>
        <ControlButton
          color="white"
          onClick={handleUpdateRate}
          style={{ marginRight: 10 }}
          basic
          icon
          dataTippyContent="Change rate"
        >
          {rate}X
        </ControlButton>
        <ControlButton
          color="white"
          onClick={() => handleRecordScreen([activeFile.fileID])}
          style={{ marginRight: 10 }}
          basic
          icon
          dataTippyContent="Record screen"
        >
          <FontAwesomeIcon icon={['fad', 'camera-movie']} />
        </ControlButton>
        <ControlButton
          onClick={() => setIsFullScreen(!isFullScreen, dispatch)}
          basic
          color="white"
          icon
          className="tooltip"
          dataTippyContent="F"
        >
          {isFullScreen && <FontAwesomeIcon icon={['fad', 'compress-alt']} />}
          {!isFullScreen && <FontAwesomeIcon icon={['fad', 'expand-alt']} />}
        </ControlButton>
      </Controls>
    </Container>
  );
}

Timeline.propTypes = {
  comments: PropTypes.array,
};

Timeline.defaultProps = {
  comments: [],
};

export default Timeline;
