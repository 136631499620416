import React, { useCallback, useEffect, useState } from 'react';
import { Box } from 'rebass';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDebounce from '../app/hooks/useDebounce';
import { post } from '../util/fetchUtil';
import Text from './Text';
import Input from './RebassInput';
import colors from '../styles/themeColors';
import { Flex } from './Box';
import Button from './RebassButton';

type VideoProps = {
  name: string;
  id: number;
};

const Row = styled(Flex)`
  cursor: pointer;
  justify-content: space-between;
  align-items: center;

  :hover,
  :focus {
    background-color: ${colors['light-1']};
  }

  :active {
    background-color: ${colors['light-2']};
  }
`;

export default function SearchOsmosisVideos({
  onSelectItem,
}: {
  onSelectItem: (option: any) => void;
}) {
  const [userInput, setUserInput] = useState('');
  const [videos, setVideos] = useState<VideoProps[]>([]);

  const debouncedUserInput = useDebounce(userInput, 750);

  const getLearnNodes = useCallback(async () => {
    if (debouncedUserInput) {
      const res = await post('/osmosis/searchLearnNodes', {
        text: debouncedUserInput,
        limit: 100,
      });

      if (res.data) {
        setVideos(res.data);
      }
    }
  }, [debouncedUserInput]);

  const handleOpenLink = (e: React.MouseEvent, video: VideoProps) => {
    e.stopPropagation();
    window.open(
      `https://osmosis.org/editlearn?title=${video.name.replaceAll(' ', '_')}`
    );
  };

  useEffect(() => {
    getLearnNodes();
  }, [getLearnNodes]);

  const results = videos.map((video) => (
    <Row key={video.id} p={2} mb={1} onClick={() => onSelectItem(video)}>
      <Box>
        <Text>{video.name}</Text>
      </Box>
      <Box>
        <Button
          variant="transparent"
          color="dark-6"
          size="s"
          onClick={(e: React.MouseEvent) => handleOpenLink(e, video)}
        >
          <FontAwesomeIcon icon={['fad', 'external-link']} />
        </Button>
      </Box>
    </Row>
  ));

  return (
    <Box>
      <Text mb={2} as="h4">
        Search Osmosis learn pages
      </Text>
      <Input
        onChange={(e) => setUserInput(e.currentTarget.value)}
        placeholder="Start typing video name..."
      />
      <Box maxHeight="400px" width="400px" overflow="auto">
        {results}
      </Box>
    </Box>
  );
}
