/**
 * Calculate a point along the profile
 *
 * @param {Array} strokeWidthProfile
 * @param {Number} length
 * @returns
 */

type Point = [number, number];

export const calcLinearPoint = (
  strokeWidthProfile: Point[],
  length: number
): number | null => {
  let width = null;
  strokeWidthProfile.forEach((point, i) => {
    if (i > 0) {
      const x1 = strokeWidthProfile[i - 1][0];
      const y1 = strokeWidthProfile[i - 1][1];
      const x2 = point[0];
      const y2 = point[1];
      // TODO: MIGHT BE TROUBLE HERE WITH CHECK
      if (length <= x2) {
        const m = (y2 - y1) / (x2 - x1);
        const b = y2 - m * x2;
        width = length * m + b;
      }
    }
  });

  return width;
};

export default {
  calcLinearPoint,
};
