import React, { useRef } from 'react';
import styled, { css } from 'styled-components/macro';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from 'rebass';
import Tippy from '@tippyjs/react';
import { post } from '../../../util/fetchUtil';

import Box from '../../Box';

import useDroppable from '../../../app/hooks/useDroppable';

import { getFilesFromEvent, uploadFile } from '../../../app/fileManager';
import uuid from '../../../app/utilities/uuid';
import { getExtension } from '../../../utilities/files';

import Checkbox from '../../Checkbox';
import { fade, colors } from '../../styles/colors';
import { P } from '../../styles/typography';
import { ImageElementProps } from '../types/ElementProps';
import { KitFileProps } from './MediaImages';

const Preview = styled(Box)`
  padding: 5px;
  margin: 5px;
  background: ${fade('white', 86)};
  border-radius: 5px;
  position: relative;

  transition: 0.1s all ease-in-out;

  cursor: pointer;

  width: 45%;

  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  :hover,
  :focus {
    background: ${fade('white', 89)};
  }

  :active {
    background: ${fade('white', 92)};
  }

  ${(props: { isActive: boolean }) =>
    props.isActive &&
    css`
      border: 2px solid ${colors.blue};
    `}
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const Name = styled(P)`
  color: ${fade('white', 40)};
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
`;

const ImageContainer = styled(Box)`
  height: 100px;
  display: flex;
`;

type Props = {
  image: ImageElementProps;
  handleUpdateElement: (elementIds: string[]) => void;
  handleSelectItem: (elementId: string) => void;
  kits?: KitFileProps[];
  selected: boolean;
};

/**
 * Dislays an image or other media item to be inserted into the scene
 */
const MediaItem = (props: Props) => {
  const {
    image,
    kits,
    handleUpdateElement,
    handleSelectItem,
    selected,
  } = props;

  const previewRef = useRef<HTMLDivElement>(null);

  const handleDropFile = async (e: React.DragEvent) => {
    const [file] = getFilesFromEvent(e);
    let upload = null;
    if (file) {
      upload = await uploadFile(file, `${uuid()}${getExtension(file.name)}`);
    }

    if (upload) {
      // Update file
      await post('/editor/updateFileVersion', {
        fileID: image.fileID,
        url: upload.url,
        size: file.size,
      });

      // Update element
      image.src = upload.url;

      handleUpdateElement([image.id]);
    }
  };

  const { isDraggingOver } = useDroppable(previewRef, [image], {
    onDrop: handleDropFile,
  });

  return (
    <Preview
      ref={previewRef}
      isActive={isDraggingOver}
      onClick={() => handleSelectItem(image.id)}
    >
      <Flex
        sx={{ position: 'absolute', left: 0, top: 0 }}
        justifyContent="center"
        width="100%"
      >
        <Checkbox
          checked={selected}
          onChange={() => handleSelectItem(image.id)}
        />
        {!!kits?.length && (
          <Box mr="12px" mt="5px">
            <Tippy content={`Image is in ${kits.length} kits`}>
              <Box color="white">
                <FontAwesomeIcon icon={['fad', 'image']} />
              </Box>
            </Tippy>
          </Box>
        )}
      </Flex>
      <ImageContainer>
        <Image src={image.src} />
      </ImageContainer>
      <Name>{image.name}</Name>
    </Preview>
  );
};

export default MediaItem;
