import styled from 'styled-components/macro';

import { colors, lighten } from '../styles/colors';
import { tabletMin } from '../styles/breakpoints';

export const Container = styled.div`
  background-color: #f4f4f4;

  position: relative;

  display: flex;
  height: 100%;

  flex-direction: column;

  @media ${tabletMin} {
    flex-direction: row;
  }
`;
export const RightContainer = styled.div`
  overflow: scroll;

  flex: 6;
`;
export const HomeSidebarContainer = styled.div`
  flex: 1;
  padding: 20px;
  border-right: 1px solid #e3e3e3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const ProjectListContainer = styled.div`
  flex: 6;
  padding: 0 30px 30px 30px;

  display: flex;
  flex-wrap: wrap;
`;
export const NewProjectContainer = styled.div`
  border: 2px dashed ${colors['light-grey-50']};
  align-items: center;
  justify-content: center;

  width: 250px;
  min-height: 100px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  cursor: pointer;

  margin: 10px;

  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus {
    background: ${lighten(colors.grey, 55)};
  }

  &:active {
    background: ${lighten(colors.grey, 53)};
  }
`;
export const NewProjectText = styled.h3`
  color: ${colors['light-grey-20']};
  display: flex;
  align-items: center;

  i,
  svg {
    margin-right: 10px;
  }
`;
export const HideArchivedText = styled.h4`
  display: inline-block;

  color: ${colors['light-grey-20']};

  margin: 0;
  margin-left: 40px;

  cursor: pointer;

  &:focus,
  &:hover {
    color: ${colors['light-grey-10']};
  }

  &:active {
    color: ${colors.grey};
  }
`;
