import PropTypes from 'prop-types';
import React from 'react';

import { useState } from 'react';

import Autocomplete from './Autocomplete';
import useAbortController from '../app/hooks/useAbortController';

function ProjectSearch(props) {
  const { onItemSelect, placeholder } = props;

  const [newProjectSearchValue, setNewProjectSearchValue] = useState('');
  const [projectSearchResults, setProjectSearchResults] = useState([]);

  useAbortController(
    'project/searchProjects',
    {
      text: newProjectSearchValue,
    },
    [newProjectSearchValue],
    (data) => {
      setProjectSearchResults(data.projects);
    }
  );

  // const handleSearchProjects = (e) => {
  //   const { value } = e.target;
  //   setNewProjectSearchValue(value);
  //   fetchUtil.post('/project/searchProjects', { text: value }).then((res) =>
  //     setProjectSearchResults(res.projects)
  //   );
  // };

  const handleItemSelect = (item) => {
    setNewProjectSearchValue('');
    onItemSelect(item);
  };

  return (
    <Autocomplete
      value={newProjectSearchValue}
      options={projectSearchResults.map((project) => ({
        ...project,
        id: project.projectID,
      }))}
      placeholder={placeholder}
      onItemSelect={handleItemSelect}
      onChange={(e) => setNewProjectSearchValue(e.target.value)}
    />
  );
}

ProjectSearch.propTypes = {
  onItemSelect: PropTypes.func,
  placeholder: PropTypes.string,
};

ProjectSearch.defaultProps = {
  onItemSelect: () => {},
  placeholder: '',
};

export default ProjectSearch;
