import React, { useState } from 'react';
import styled from 'styled-components/macro';

import group from '../../app/editor/group';
import {
  calculateSelectionBox,
  RectProps,
  PointProps,
} from '../../app/editor/rect';
import { ElementsArrayProps, MixedElementProps } from './types/ElementProps';

type BoxProjectionAxisProps = [number, number];

type BoxProjectionProps = {
  x: BoxProjectionAxisProps;
  y: BoxProjectionAxisProps;
};

/**
 * lineIntersects - Calculate if two segments overlap in 1D
 * @param lineA [min, max]
 * @param lineB [min, max]
 */
const lineIntersects = (
  lineA: BoxProjectionAxisProps,
  lineB: BoxProjectionAxisProps
) => lineA[1] >= lineB[0] && lineB[1] >= lineA[0];

/**
 * boxIntersects - Detect 2D box intersection - the two boxes will intersect
 * if their projections to both axis overlap
 * @param boxA { left, top, width, height }
 * @param boxB DOMRect { left, top, width, height }
 */
const boxIntersects = (boxA: RectProps, boxB: RectProps) => {
  // calculate coordinates of all points
  const boxAProjection: BoxProjectionProps = {
    x: [boxA.x, boxA.x + boxA.width],
    y: [boxA.y, boxA.y + boxA.height],
  };

  const boxBProjection: BoxProjectionProps = {
    x: [boxB.x, boxB.x + boxB.width],
    y: [boxB.y, boxB.y + boxB.height],
  };

  return (
    lineIntersects(boxAProjection.x, boxBProjection.x) &&
    lineIntersects(boxAProjection.y, boxBProjection.y)
  );
};

const StyledSelectionBox = styled.div`
  position: fixed;
  border: 1px dashed #00a8ff;
  background: rgba(0, 168, 255, 0.2);
`;

type Props = {
  enabled: boolean;
  elements: ElementsArrayProps;
  children: React.ReactNode;
  selectionBoxStyle: React.CSSProperties;
  handleSelectElements?: (elements: ElementsArrayProps) => void;
};

const SelectionMarquee: React.FC<Props> = ({
  enabled,
  elements,
  children,
  selectionBoxStyle,
  handleSelectElements,
}: Props) => {
  const [mouseDown, setMouseDown] = useState(false);
  const [startPoint, setStartPoint] = useState<PointProps>();
  const [endPoint, setEndPoint] = useState<PointProps>();
  const [selectionBox, setSelectionBox] = useState<RectProps>();

  /**
   * resetState - resets to initial state
   */
  const resetState = () => {
    setMouseDown(false);
    setStartPoint(undefined);
    setEndPoint(undefined);
    setSelectionBox(undefined);
  };

  /**
   * handleMouseMove - draw the box as the mouse moves
   * @param {*} e
   */
  const handleMouseMove = (e: React.MouseEvent) => {
    if (!enabled) return;

    e.preventDefault();

    if (mouseDown) {
      setEndPoint({
        x: e.pageX,
        y: e.pageY,
      });

      if (!startPoint || !endPoint) {
        return null;
      }

      const box = calculateSelectionBox(startPoint, endPoint);

      setSelectionBox({
        ...box,
        x: box.x,
        y: box.y,
      });
    }
  };

  const intersectsSelectionBox = (el: MixedElementProps) => {
    const elBox = document.getElementById(el.id)?.getBoundingClientRect();
    return selectionBox && elBox ? boxIntersects(selectionBox, elBox) : false;
  };

  const handleMouseUp = () => {
    if (!enabled) return;

    if (selectionBox && handleSelectElements) {
      const filteredEls = group.filterElements(elements, (el) => !el.locked);
      const flattenedEls = group.flattenFilteredTree(filteredEls);

      const selected = flattenedEls.filter((el) => intersectsSelectionBox(el));
      handleSelectElements(selected);
    }
    resetState();
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (!enabled || e.button === 2 || e.nativeEvent.which === 2) {
      return;
    }

    setMouseDown(true);
    setStartPoint({
      x: e.pageX,
      y: e.pageY,
    });
  };

  /**
   * renderSelectionBox
   */
  const renderSelectionBox = () =>
    !mouseDown || endPoint === null || startPoint === null ? null : (
      <StyledSelectionBox
        style={{
          ...selectionBox,
          left: selectionBox?.x,
          top: selectionBox?.y,
          ...selectionBoxStyle,
        }}
      />
    );

  const className = `selection ${mouseDown ? 'dragging' : ''}`;

  return (
    <div
      className={className}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      {children}
      {renderSelectionBox()}
    </div>
  );
};

SelectionMarquee.defaultProps = {
  enabled: true,
  elements: [],
  handleSelectElements: () => {},
};

export default SelectionMarquee;
