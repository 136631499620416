import React, { useState } from 'react';
import PropTypes from 'prop-types';

import QuillEditor from '../QuillEditor';

import { getDurationSinceNow } from '../../app/utilities/durations';

import FormattedCommentText from '../comments/FormattedCommentText';

import {
  FlexContainer,
  CommentTimestamp,
  CommentRow,
  CommentText,
  CommentActions,
  CommentAction,
  EditingInputContainer,
  RightContainer,
} from './ActivityBoxCommentStyles';
import { StyledAvatar, UserName } from './ActivityBoxStyles';

/**
 * Functional component that controls individual comment items in the projectContents view
 */
function ActivityBoxComment(props) {
  const {
    commentID,
    givenName,
    createdOn,
    text,
    user,
    avatar,

    handleReplyClick,
    handleDeleteComment,
    handleUpdateComment,
  } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [newText, setNewText] = useState(text);

  const updateComment = () => {
    setIsEditing(false);

    handleUpdateComment(commentID, newText);
  };

  const handleToggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleEditingChange = (value) => {
    setNewText(value);
  };

  return (
    <CommentRow>
      <FlexContainer>
        <StyledAvatar src={avatar} size={35} />

        <RightContainer>
          <FlexContainer>
            <UserName>{givenName}</UserName>
            <CommentTimestamp>
              {getDurationSinceNow(createdOn)}
            </CommentTimestamp>
          </FlexContainer>
          {isEditing && (
            <EditingInputContainer className="text-input">
              <QuillEditor
                onChange={handleEditingChange}
                placeholder="Start typing comment..."
                toolbar={['bold', 'italic', 'link']}
                value={newText}
              />
            </EditingInputContainer>
          )}
          {!isEditing && (
            <div>
              <CommentText>
                <FormattedCommentText text={text} />
              </CommentText>
              <CommentActions>
                {/* {comment.data.user.userID !== window.userID && ( */}
                <CommentAction onClick={handleReplyClick(user)}>
                  Reply
                </CommentAction>
                {/* )} */}
                {user.userID === window.userID && (
                  <FlexContainer>
                    <CommentAction onClick={handleToggleEdit}>
                      Edit
                    </CommentAction>
                    <CommentAction onClick={handleDeleteComment(commentID)}>
                      Delete
                    </CommentAction>
                  </FlexContainer>
                )}
              </CommentActions>
            </div>
          )}
          {isEditing && (
            <CommentActions>
              <FlexContainer>
                <CommentAction onClick={updateComment}>Save</CommentAction>
                <CommentAction onClick={handleToggleEdit}>Cancel</CommentAction>
              </FlexContainer>
            </CommentActions>
          )}
        </RightContainer>
      </FlexContainer>
    </CommentRow>
  );
}

ActivityBoxComment.propTypes = {
  propName: PropTypes.string,
  commentID: PropTypes.number.isRequired,
  user: PropTypes.shape({
    userID: PropTypes.number,
  }).isRequired,
  createdOn: PropTypes.string.isRequired,

  handleReplyClick: PropTypes.func.isRequired,
  handleDeleteComment: PropTypes.func.isRequired,
  handleUpdateComment: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func.isRequired,

  avatar: PropTypes.string,
  givenName: PropTypes.string,
  text: PropTypes.string,
};

ActivityBoxComment.defaultProps = {
  givenName: '',
  text: '',
  avatar: '',
};

export default ActivityBoxComment;
