import styled, { css } from 'styled-components/macro';

import { colors, lighten } from '../styles/colors';

export const Container = styled.div`
  display: flex;
  height: 100vh;
`;
export const ConstructionMessage = styled.div`
  background: ${lighten(colors.yellow, 35)};
  color: ${colors['dark-yellow-20']};

  font-size: 24px;

  text-align: center;

  margin: 15px;
  padding: 30px;
`;
export const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  :nth-child(even) {
    background: ${lighten(colors.grey, 58)};
  }
`;
export const Column = styled.div`
  width: 200px;
  color: ${colors.grey};

  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;

  ${(props) =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
`;
export const HeaderColumn = styled(Column)`
  color: ${lighten(colors.grey, 30)};
`;
