// Keyframes that are allowed for the various element types
const defaultTypes = ['position', 'scale', 'rotate', 'opacity', 'path'];
const typesMap = {
  text: [...defaultTypes, 'text'],
  path: [...defaultTypes, 'stroke'],
  rect: [...defaultTypes, 'dimensions'],
  group: [...defaultTypes],
  image: [...defaultTypes],
};

/**
 * Updates translations along an animation path
 *
 * @param {SvgElement} element
 * @param {Number} progress - number between 0 and 1
 */
const updateAnimationPath = (element, progress) => {
  if (element.animationPathD) {
    const path = element.cache.animationPath;
    const { currentProps } = element;

    const lastPoint = element.currentProps.animationPathPoint || {
      x: path.getPointAt(0).x,
      y: path.getPointAt(0).y,
    };
    const point = path.getPointAt(progress * path.length);

    if (point && lastPoint) {
      const dx = (point.x - lastPoint.x) * currentProps.scaleX; // TODO: HAVE TO ADJUST FOR ROTATE
      const dy = (point.y - lastPoint.y) * currentProps.scaleY;

      element.update('currentProps', {
        ...currentProps,
        animationPathPoint: { x: point.x, y: point.y }, // Save previous value
        translateX: currentProps.translateX + dx,
        translateY: currentProps.translateY + dy,
      });
    }
  }
};

/**
 * Definitions map for keyframes
 *  {
 *    [NAME]: {
 *      [PROPERTY] : [TYPE], // The PROPERTY key maps directly to element.currentProps (the TYPE isn't really used...)
 *      ...other properties to animate
 *    }
 *  }
 */
const keyframeMap = {
  position: {
    translateX: 'number',
    translateY: 'number',
  },
  dimensions: {
    width: 'number',
    height: 'number',
  },
  scale: {
    scaleX: 'number',
    scaleY: 'number',
  },
  rotate: {
    rotate: 'number',
  },
  opacity: {
    opacity: 'number',
  },
  stroke: {
    dashoffset: 'number', // Number between 0 and 1 (progress of line)
  },
  text: {
    progress: 'number', // Number between 0 and 1 for animating text
  },
  path: {
    pathProgress: 'number', // Number between 0 and 1 for animating along a path
    update: updateAnimationPath, // Custom update function
  },
};

export { typesMap, keyframeMap };
