import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

function SearchReset({ className }) {
  const styles = {
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    strokeLinejoin: 'round',
    strokeMiterlimit: 2,
  };

  return (
    <Container
      className={className}
    >
      <svg width="100%" height="100%" viewBox="0 0 517 511" style={styles}>
        <g id="duotone">
          <path
            d="M136.075,104.688l14.725,-1.002c22.026,-1.499 41.125,15.166 42.624,37.192c1.498,22.025 -15.167,41.124 -37.192,42.623l-102.991,7.008c-21.622,1.472 -40.484,-14.544 -42.536,-36.119l-10.523,-110.599c-2.091,-21.977 14.055,-41.518 36.032,-43.609c21.977,-2.091 41.518,14.055 43.609,36.032l0.959,10.086c71.566,-57.92 175.832,-63.125 253.739,-6.346c92.923,67.723 113.382,198.147 45.659,291.07c-67.722,92.922 -198.146,113.382 -291.069,45.659c-18.641,-13.585 -20.859,-39.385 -8.892,-55.353c13.338,-17.799 38.259,-22.236 56.01,-9.299c57.241,41.718 137.582,29.115 179.3,-28.126c41.717,-57.241 29.114,-137.582 -28.126,-179.3c-46.211,-33.678 -107.477,-31.954 -151.328,0.083Z"
            style={{
              fillOpacity: 0.4,
            }}
          />
          <path
            d="M509.495,475.609l-28.3,28.3c-4.484,4.512 -10.589,7.051 -16.95,7.051c-6.361,0 -12.465,-2.539 -16.95,-7.051l-99.7,-99.7c-4.495,-4.511 -7.015,-10.631 -7,-17l0,-16.3l36,-36l16.3,0c6.369,-0.015 12.489,2.505 17,7l99.7,99.7c9.262,9.37 9.217,24.685 -0.1,34Z"
            style={{
              fillRule: 'nonzero',
            }}
          />
        </g>
      </svg>
    </Container>
  );
}

const Container = styled.div`
  height: 18px;
  fill: #757575;
  display: flex;
  align-items: center;
  justify-content: center;
`;

SearchReset.propTypes = {
  propName: PropTypes.string,
};

SearchReset.defaultProps = {
  propName: '',
};

export default SearchReset;
