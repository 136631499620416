import React, { useEffect, useState } from 'react';

import { HashRouter } from 'react-router-dom';

import ExporterV1 from './Exporter';
import ExporterV2 from '../exporterV2/Exporter';
import { StoreProvider } from '../../state/store';

import { getSearchParams } from '../../app/params';

function ExporterWrapper() {
  const [version, setVersion] = useState();

  useEffect(() => {
    const { v } = getSearchParams(window.location.href);
    setVersion(+v || 1);
  }, []);

  return (
    <HashRouter>
      <StoreProvider>
        {version === 1 && <ExporterV1 />}
        {version === 2 && <ExporterV2 />}
      </StoreProvider>
    </HashRouter>
  );
}

ExporterWrapper.propTypes = {};

export default ExporterWrapper;
