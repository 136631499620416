import React, { ReactNode, useContext } from 'react';
import { Store } from '../state/store';
import AccessMessage from './AccessMessage';
import { Flex } from './Box';
import { isAllowed } from '../shared/grants';

interface Props {
  children: ReactNode;
  grant: string;
}

const AccessWrapper: React.FC<Props> = (props: Props) => {
  const { children, grant } = props;
  const {
    state: { user },
  } = useContext(Store);

  const hasAccess = isAllowed(grant, user.grants);

  return (
    <>
      {!hasAccess && (
        <Flex c height="100%">
          <AccessMessage grant={grant} />
        </Flex>
      )}
      {hasAccess && children}
    </>
  );
};

export default AccessWrapper;
