import styled from 'styled-components/macro';

import { colors } from '../styles/colors';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  flex: 4;
`;
export const SceneContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 0;
  top: 0;
  overflow: hidden;

  background: ${colors['light-grey-50']};

  .svg-container {
    position: absolute;
    height: 100%;
    width: 100%;
  }
`;
export const SceneBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  rect {
    fill: white;
  }
`;
