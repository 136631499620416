import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import { colors, fade  } from '../../styles/colors';

const StyledPoint = styled.circle`
  fill: white;
  stroke: ${fade(colors.blue, 30)};
  stroke-width: 1px;

  ${(props) =>
    props.isActive &&
    css`
      stroke-width: 2px;
      stroke: ${colors.red};
      fill: ${colors.blue};
    `}
`;

const Point = ({ x, y, isActive }) => (
  <g>
    <StyledPoint cx={x} cy={y} r={5} isActive={isActive} />
  </g>
);

const { number, bool } = PropTypes;

Point.propTypes = {
  x: number,
  y: number,
  isActive: bool,
};

export default Point;
