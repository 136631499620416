import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useEffect } from 'react';

import tippy from 'tippy.js';

import ToggleInput from '../ToggleInput';
import ListItemSelector from '../ListItemSelector';
import Button from '../Button';
import Box, { Flex } from '../Box';
import OptionSelector from '../OptionSelector';
import { H3 } from '../styles/typography';
import { colors, fade } from '../styles/colors';

import { sort } from '../layers/FileRowOptions';
import sortOptions from '../../utilities/sortFiles';

const Container = styled.div``;

const PlayAmount = styled.span`
  color: ${fade('white', 35)};
`;

const Heading = styled(H3)`
  margin-bottom: 20px;
`;

/**
 * Allows user to select which items to play
 *
 * @prop {object} project - a project object (e.g. activeProject)
 * @returns
 */
const PlayAllDialog = (props) => {
  const {
    project,
    onPlayClick,
    handleHideDialog,
    shouldRingBell,
    setShouldRingBell,
  } = props;

  const [svgs, setSvgs] = useState([]);
  const [currentSort, setCurrentSort] = useState(
    sortOptions.find((o) => o.id === 'number').id
  );
  const [reverseSort, setReverseSort] = useState(false);

  const handleSelectSort = (newSort) => {
    const currentSortOption = sortOptions.find((o) => o.id === currentSort);
    if (currentSortOption.id === newSort && currentSortOption.reverse) {
      setReverseSort(!reverseSort);
    } else {
      setReverseSort(false);
      setCurrentSort(newSort);
    }
  };

  useEffect(() => {
    tippy('[data-tippy-content]');
  }, []);

  const currentSortOption = sortOptions.find((o) => o.id === currentSort);

  useEffect(() => {
    const svgFiles = project.files
      .filter((file) => file.type === 'svg')
      .sort(
        reverseSort && currentSortOption.reverse
          ? currentSortOption.reverse
          : currentSortOption.sort
      )
      .map((file) => ({
        ...file,
        checked: true,
        id: file.fileID,
        name: file.name,
      }));

    setSvgs(svgFiles);
    // eslint-disable-next-line
  }, [project, currentSort, reverseSort]);

  const handlePlay = () => {
    onPlayClick(svgs.filter((svg) => svg.checked));
    handleHideDialog();
  };

  const handleSvgClick = (svg) => {
    const newSvgs = Array.from(svgs);
    const newSvg = newSvgs.find((s) => s.id === svg.id);
    newSvg.checked = !newSvg.checked;

    setSvgs(newSvgs);
  };

  const handleUpdateAll = (checked) => {
    const newSvgs = svgs.map((svg) => ({
      ...svg,
      checked,
    }));

    setSvgs(newSvgs);
  };

  const handleToggleBell = () => {
    setShouldRingBell(!shouldRingBell);
  };

  const checked = svgs.filter((svg) => svg.checked);
  const options = [
    sort(
      sortOptions,
      currentSort,
      handleSelectSort,
      currentSortOption.reverse && reverseSort
    ),
  ];

  return (
    <Container>
      <Heading>Select files</Heading>
      <Flex justifyContent="space-between" alignItems="center" mb={10}>
        <OptionSelector onClick={(e) => e.stopPropagation()} options={options}>
          <Button thin box style={{ marginRight: 10 }}>
            Sort
          </Button>
        </OptionSelector>
        <Box
          flex
          style={{
            display: 'inline-block',
            alignItems: 'center',
          }}
          data-tippy-content="Ring bell when finished"
        >
          <ToggleInput
            isOn={shouldRingBell}
            onIcon={<FontAwesomeIcon icon={['fad', 'bell-on']} />}
            offIcon={<FontAwesomeIcon icon={['fad', 'bell-slash']} />}
            handleToggle={handleToggleBell}
          />
        </Box>
      </Flex>
      <ListItemSelector
        items={svgs}
        onItemClick={handleSvgClick}
        handleSelectAll={() => handleUpdateAll(true)}
        handleDeselectAll={() => handleUpdateAll(false)}
      />
      <Button
        enable={checked.length > 0}
        box
        leftIcon
        color="white"
        backgroundColor={colors.blue}
        onClick={handlePlay}
      >
        <FontAwesomeIcon icon={['fas', 'play']} />
        Play&nbsp;
        <PlayAmount>
          ({checked.length === svgs.length ? 'All' : checked.length})
        </PlayAmount>
      </Button>
    </Container>
  );
};

PlayAllDialog.propTypes = {
  project: PropTypes.shape({
    files: PropTypes.array,
  }),
  onPlayClick: PropTypes.func.isRequired,
  handleHideDialog: PropTypes.func.isRequired,
  setShouldRingBell: PropTypes.func.isRequired,
  shouldRingBell: PropTypes.bool,
};

PlayAllDialog.defaultProps = {
  shouldRingBell: true,
};

export default PlayAllDialog;
