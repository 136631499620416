import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactNode } from 'react';
import NodeToolIcon from '../icons/NodeToolIcon';

export type ToolProps = {
  id: string;
  name: string;
  icon: ReactNode;
  cursor: string;
  hotkeyId: string;
  hotkey: string;
  tooltip: string;
};

export type ToolOptionProps =
  | 'move'
  | 'pen'
  | 'pencil'
  | 'node'
  | 'animation-path'
  | 'text'
  | 'rect'
  | 'moveStage'
  | 'annotate';

// Tools to display for interacting with the svg canvas
export default {
  // Allow moving of elements on the screen
  move: {
    id: 'move',
    name: 'Move',
    icon: <FontAwesomeIcon icon={['fas', 'mouse-pointer']} />,
    cursor: 'unset',
    hotkeyId: 'MOVE_TOOL',
    hotkey: 'v',
    tooltip: 'Move Tool (V)',
  },
  // The pen tool to draw bezier curves
  pen: {
    id: 'pen',
    name: 'Pen',
    icon: <FontAwesomeIcon icon={['fad', 'pen-nib']} />,
    cursor: 'pointer',
    hotkeyId: 'PEN_TOOL',
    hotkey: 'p',
    tooltip: 'Pen Tool (P)',
  },
  // The pencil tool to draw paths
  pencil: {
    id: 'pencil',
    name: 'Pencil',
    icon: <FontAwesomeIcon icon={['fad', 'pencil']} />,
    cursor: 'pointer',
    hotkeyId: 'PENCIL_TOOL',
    hotkey: 'n',
    tooltip: 'Pencil Tool (N)',
  },
  // Node editor tool to edit nodes on curves
  node: {
    id: 'node',
    name: 'Node',
    icon: <NodeToolIcon />,
    cursor: 'pointer',
    hotkeyId: 'NODE_TOOL',
    hotkey: 'a',
    tooltip: 'Node Tool (A)',
  },
  // Node editor tool to edit nodes on curves
  animationPath: {
    id: 'animation-path',
    name: 'Animation Path',
    icon: <FontAwesomeIcon icon={['fad', 'wand-magic']} />,
    cursor: 'pointer',
    hotkeyId: 'ANIMATION_PATH_TOOL',
    hotkey: 'o',
    tooltip: `Animation Path Tool (O)`,
  },
  // Write text to svg
  text: {
    id: 'text',
    name: 'Text',
    icon: <FontAwesomeIcon icon={['fas', 'text']} />,
    cursor: 'text',
    hotkeyId: 'TEXT_TOOL',
    hotkey: 't',
    tooltip: `Text Tool (T)`,
  },
  // Create a rect
  rect: {
    id: 'rect',
    name: 'Rect',
    icon: <FontAwesomeIcon icon={['fad', 'draw-square']} />,
    cursor: 'crosshair',
    hotkeyId: 'RECT_TOOL',
    hotkey: 'm',
    tooltip: `Rect Tool (M)`,
  },
  // Move the stage viewBox around
  moveStage: {
    id: 'moveStage',
    name: 'Move',
    icon: <FontAwesomeIcon icon={['fad', 'hand-paper']} />,
    cursor: 'grab',
    cursorDrag: 'grabbing',
    hotkeyId: 'GRAB_TOOL',
    hotkey: 'd',
    tooltip: `View Tool (D)`,
  },
  // Move the stage viewBox around
  annotate: {
    id: 'annotate',
    cursor: `url(https://d16qt3wv6xm098.cloudfront.net/_gukC53BRkCE0_xw-xyvz-AxQau9NFvX/_.png)
      0 32,
    pointer;`,
  },
};
