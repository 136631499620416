import React from 'react';
import { Box } from '../../ui/Box';
import Text from '../../ui/Text';

import {
  Container,
  Link,
  LowerContent,
  UpperContent,
} from './HomeSidebarStyles';

import CustomSearchBox from '../CustomSearchBox';
import GroupRefinementList from './GroupRefinementList';
import Checkbox from '../Checkbox';

type Props = {
  subscribedChecked: boolean;
  setSubscribedChecked: (isChecked: boolean) => void;
  recentsChecked: boolean;
  setRecentsChecked: (isChecked: boolean) => void;
};

function HomeSidebar(props: Props) {
  const {
    subscribedChecked,
    setSubscribedChecked,
    recentsChecked,
    setRecentsChecked,
  } = props;

  return (
    <Container>
      <UpperContent>
        <CustomSearchBox placeholder="Search projects..." />
        <Box mt={3}>
          <Text
            as="h3"
            pb={2}
            mb={3}
            sx={{
              borderBottom: '1px solid grey',
              borderBottomColor: 'light-3',
            }}
          >
            Filters
          </Text>

          <Box mb={2}>
            <Checkbox
              label="Recent"
              checked={recentsChecked}
              onChange={() => setRecentsChecked(!recentsChecked)}
            />
          </Box>

          <Box mb={3}>
            <Checkbox
              label="Subscribed"
              checked={subscribedChecked}
              onChange={() => setSubscribedChecked(!subscribedChecked)}
            />
          </Box>

          <Box mb={3}>
            <Text color="dark-6" as="h4" mb={2}>
              Folders
            </Text>
            <GroupRefinementList attribute="groups" />
          </Box>
        </Box>
      </UpperContent>
      <LowerContent>
        <Link href="/manual" target="_blank">
          User Manual
        </Link>
      </LowerContent>
    </Container>
  );
}

export default HomeSidebar;
