import moment from 'moment';

const getDurationSinceNow = (datetime: string): string => {
  const duration = moment.duration(moment().diff(datetime));

  const timestampMinutes = duration.asMinutes();
  let timestampReadable = '< 1 minute ago';
  // Reformat if greater than an hour, day, etc.
  // If between 1 and 60 minutes, show 'X min ago'
  if (timestampMinutes > 1 && timestampMinutes <= 60)
    timestampReadable = `${Math.ceil(timestampMinutes)} min ago`;
  // If between 1 and 6 hours, show 'X hrs ago'
  else if (timestampMinutes > 60 && timestampMinutes <= 60 * 6)
    timestampReadable = `${Math.ceil(timestampMinutes / 60)} hrs ago`;
  // If greater than 6 hours from now
  else if (timestampMinutes > 60 * 6) {
    // If today, show 'Today at hh:mma'
    if (moment(datetime).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'))
      timestampReadable = `Today at ${moment(datetime).format('h:mma')}`;
    // If yesterday, show 'Yesterday at hh:mma'
    else if (
      moment(datetime).format('YYYY-MM-DD') ===
      moment().subtract(1, 'day').format('YYYY-MM-DD')
    )
      timestampReadable = `Yesterday at ${moment(datetime).format('h:mma')}`;
    // Otherwise just show days ago
    else
      timestampReadable = `${Math.ceil(timestampMinutes / 60 / 24)} days ago`;
  }

  return timestampReadable;
};

/**
 * Duration until time
 *
 * @param {String} datetime - datetime string
 * @param {String} type
 * @returns
 */
const getDurationUntilDatetime = (datetime: string, type: string): number => {
  const duration = moment.duration(moment(datetime).diff(moment()));

  if (type === 'days') {
    const days = Math.ceil(duration.asDays());
    return days;
  }

  return duration.asMinutes();
};

/**
 * Get the number of minutes since a certain time (in ms)
 *
 * @param {Number} ms - duration in ms
 */
const getMinutesFromMs = (ms: number): number => Math.trunc(ms / 60000);

/**
 * Get the number of second since a certain time (in ms)
 *
 * @param {Number} ms - duration in ms
 */
const getSecondsFromMs = (ms: number): number =>
  Math.trunc((ms % 60000) / 1000);

/**
 * Get the number of ms since a certain time (in ms)
 *
 * @param {Number} ms - duration in ms
 */
const getMsFromMs = (ms: number): number =>
  Math.trunc(((ms % 60000) % 1000) / 10);

/**
 * Gets a formatted duration time string from ms
 *
 * @param {number} ms
 * @param {number} [padStart=2]
 * @returns
 */
const getTimeStringFromMs = (ms: number, padStart = 2): string => {
  const minutes = getMinutesFromMs(ms).toString().padStart(padStart, '0');
  const seconds = getSecondsFromMs(ms).toString().padStart(padStart, '0');
  const milleseconds = getMsFromMs(ms).toString().padStart(padStart, '0');

  return `${minutes}:${seconds}:${milleseconds}`;
};

const getTotalDuration = (min: number, sec: number): number =>
  min * 60 * 1000 + sec * 1000;

export {
  getTimeStringFromMs,
  getMinutesFromMs,
  getSecondsFromMs,
  getMsFromMs,
  getDurationUntilDatetime,
  getDurationSinceNow,
  getTotalDuration,
};
