const CELL_TYPES = [
  'adipocyte',
  'astrocyte',
  'erythrocyte',
  'granulocyte',
  'hepatocyte',
  'keratinocyte',
  'leukocyte',
  'lymphocyte',
  'melanocyte',
  'phagocyte',
  'thrombocyte',
  'gametocyte',
  'bacteriocyte',
  'megakaryocyte',
  'mycetocyte',
  'necrocyte',
];

exports.CELL_TYPES = CELL_TYPES;

exports.getAnonymousName = (userID) => {
  const index = userID % CELL_TYPES.length;
  return `Anonymous ${CELL_TYPES[index]}`;
};
