import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useContext, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { post } from '../../util/fetchUtil';

import { Store } from '../../state/store';
import {
  setActiveProjectByNameOrID,
  fetchActiveProject,
} from '../../state/projects/actions';

import { getCardData } from '../../app/utilities/trello';
import {
  fetchFile,
  downloadFile as managerDownloadFile,
  downloadZipFile,
} from '../../app/fileManager';

import NameSection from '../projectContents/NameSection';
import ThumbnailSection from '../projectContents/ThumbnailSection';
import ContributorsSection from '../projectContents/ContributorsSection';
import TrelloSection from '../projectContents/TrelloSection';
import StatusSection from '../projectContents/StatusSection';
import CreditsSection from '../projectContents/CreditsSection';
import LinkAttachmentSection from '../projectContents/LinkAttachmentSection';
import UploadsSection from '../projectContents/UploadsSection';
import GroupsSection from '../projectContents/GroupsSection';
import TrackingSection from '../projectContents/TrackingSection';
import ResourcesSection from '../projectContents/ResourcesSection';
import TaskSection from '../projectContents/TaskSection';
import DueDateSection from '../projectContents/DueDateSection';
import TagSection from '../projectContents/TagSection';
import KitsSection from '../projectContents/KitsSection';

import ActivityBox from '../projectContents/ActivityBox';
import Button from '../Button';
import OptionSelector from '../OptionSelector';
import { Option, OptionsText } from '../OptionSelectorStyles';

import {
  HeaderSection,
  LargeSectionTitle,
  Section,
} from './ProjectContentsStyles';

import { colors, lighten } from '../styles/colors';
import { desktopMin } from '../styles/breakpoints';

function ProjectContentsReact() {
  const {
    state: { team, projectGroups, user, activeProject, projects },
    dispatch,
  } = useContext(Store);

  const [downloadError, setDownloadError] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [trelloCardData, setTrelloCardData] = useState(null);

  const { projectID } = useParams();

  const handleFetchProject = () => {
    fetchActiveProject(projectID, dispatch);
  };

  useEffect(() => {
    // Set the project
    setActiveProjectByNameOrID(projectID, projects, activeProject, dispatch);
    // eslint-disable-next-line
  }, [projectID]);

  const getTrelloCard = async () => {
    if (activeProject.data.trello) {
      const res = await getCardData(activeProject.data.trello.id);
      setTrelloCardData(res);
    } else {
      setTrelloCardData(null);
    }
  };

  useEffect(() => {
    getTrelloCard();
    // eslint-disable-next-line
  }, [activeProject]);

  /**
   * Downloads a file
   *
   * @memberof ProjectContents
   */
  const downloadFile = (file, onProgress) => {
    fetchFile(file.data.url, onProgress)
      .catch(() => {
        setDownloadError(file.data.url);
      })
      .then((blob) => {
        managerDownloadFile(blob, file.name);
        setDownloadError(false);
      });
  };

  const getTasks = () => {
    post('/tasks/getProjectTasks', {
      projectID: activeProject.projectID,
    }).then((res) => setTasks(res.tasks));
  };

  const downloadAllClick = () => {
    const { files } = activeProject;

    downloadZipFile(files);
  };

  const options = [
    {
      id: 'downloadAll',
      name: 'Download All',
      component: (
        <Option style={{ padding: '1rem' }} onClick={downloadAllClick}>
          <OptionsText>
            <FontAwesomeIcon icon={['fad', 'download']} />
            &nbsp;&nbsp; Download all
          </OptionsText>
        </Option>
      ),
    },
  ];

  return (
    <Container>
      <FlexContainer>
        <Column>
          <Section>
            <NameSection activeProject={activeProject} user={user} />
          </Section>
        </Column>
        <Column>
          <ThumbnailSection
            downloadFile={downloadFile}
            activeProject={activeProject}
          />
        </Column>
        <Column></Column>
      </FlexContainer>
      <FlexContainer>
        <Column>
          <Section>
            <TagSection activeProject={activeProject} />
          </Section>
          <Section>
            <ContributorsSection
              team={team}
              activeProject={activeProject}
              getTasks={getTasks}
            />
          </Section>
          <Section>
            <DueDateSection
              activeProject={activeProject}
              cardData={trelloCardData}
            />
          </Section>
          <Section>
            <TrackingSection activeProject={activeProject} />
          </Section>
          <Section>
            <TaskSection getTasks={getTasks} tasks={tasks} />
          </Section>
          <Section>
            <GroupsSection
              activeProject={activeProject}
              projectGroups={projectGroups}
            />
          </Section>
          <Section>
            <TrelloSection
              cardData={trelloCardData}
              getTrelloCard={getTrelloCard}
            />
          </Section>
          <Section>
            <StatusSection activeProject={activeProject} />
          </Section>
          <Section>
            <CreditsSection
              activeProject={activeProject}
              downloadFile={downloadFile}
            />
          </Section>
        </Column>
        <Column>
          <HeaderSection>
            <LargeSectionTitle>Activity</LargeSectionTitle>
          </HeaderSection>
          <ActivityBox />
        </Column>
        <Column>
          <HeaderSection>
            <LargeSectionTitle>Assets</LargeSectionTitle>
            <AssetControls>
              <OptionSelector options={options}>
                <Button className="icon transparent">
                  <FontAwesomeIcon icon={['fas', 'ellipsis-v']} />
                </Button>
              </OptionSelector>
            </AssetControls>
          </HeaderSection>
          <Section>
            <LinkAttachmentSection activeProject={activeProject} />
          </Section>
          <KitsSection
            activeProject={activeProject}
            fetchProject={handleFetchProject}
            downloadFile={downloadFile}
          />
          <UploadsSection
            activeProject={activeProject}
            downloadFile={downloadFile}
            downloadError={downloadError}
            fetchProject={handleFetchProject}
          />
          <ResourcesSection activeProject={activeProject} />
        </Column>
      </FlexContainer>
    </Container>
  );
}

const Container = styled.div`
  background-color: ${lighten(colors.grey, 57)};

  padding: 30px;
`;

const AssetControls = styled.div`
  display: flex;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  margin-bottom: 30px;

  @media ${desktopMin} {
    flex-direction: row;
  }
`;

const Column = styled.div`
  :not(:last-of-type) {
    margin-right: 50px;
  }

  flex: 1;

  width: 100%;

  @media ${desktopMin} {
    max-width: 33%;
  }
`;

ProjectContentsReact.propTypes = {
  propName: PropTypes.string,
};

ProjectContentsReact.defaultProps = {
  propName: '',
};

export default ProjectContentsReact;
