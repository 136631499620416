import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Line } from 'react-chartjs-2';

import React from 'react';

import moment from 'moment';

import { post } from '../../../util/fetchUtil';

import { colors, fade } from '../../styles/colors';

import roles from '../../../shared/roles';

class AnalyticsChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    post('/admin/getAdminAnalytics').then((res) => {
      this.setState({ projects: res.tracking });
    });
  }

  render() {
    const {
      activeMonth,
      rangeOptions,
      selectedMetric,
      groupBy,
      tasks,
    } = this.props;

    // Create a dataset to plot for each task
    const datasets = [];
    const range = rangeOptions.find((option) => option.value === activeMonth);
    let momentDate = null;
    if (range) momentDate = moment(range.date.format());
    else momentDate = moment(activeMonth);

    let dataPoints = momentDate.daysInMonth();
    if (groupBy === 'week')
      dataPoints = Math.ceil(momentDate.daysInMonth() / 7);

    const labels = [];
    for (let i = 0; i < dataPoints; i += 1) {
      const newMomentDate = moment(momentDate.format());
      if (groupBy === 'week') {
        const startingWeek = moment(newMomentDate.format()).date(1).week();
        const label = `${newMomentDate
          .week(i + startingWeek)
          .format('MM/DD')} - ${newMomentDate
          .week(i + 1 + startingWeek)
          .subtract(1, 'day')
          .format('MM/DD')}`;
        labels.push(label);
      } else {
        labels.push(newMomentDate.date(i + 1).format('MM/DD'));
      }
    }

    for (let i = 0; i < tasks.length; i += 1) {
      const task = tasks[i];
      const role = roles.find((r) => r.taskID === task.taskID).taskName;
      const dataset = {
        label: role,
        data: [],
        fill: false,
        borderColor: fade(colors[task.color], 25),
        borderWidth: 3,
      };

      // Create an array of days
      for (let j = 0; j < dataPoints; j += 1) {
        const newMomentDate = moment(momentDate.format());
        const week = moment(momentDate.format()).date(1).week() + j;
        if (groupBy === 'week') {
          newMomentDate.week(week);
        } else {
          newMomentDate.date(j + 1);
        }

        let rangedProjects;

        if (groupBy === 'week')
          rangedProjects = task.list.filter(
            (project) => moment(project.date).week() === week
          );
        else
          rangedProjects = task.list.filter(
            (project) =>
              moment(project.date).format('MM-DD-YYYY') ===
              momentDate.format('MM-DD-YYYY')
          );

        const projectCount = rangedProjects.length;
        const wordCount = rangedProjects.reduce(
          (previous, current) => previous + current.wordCount,
          0
        );

        const dataCount =
          selectedMetric === 'Projects' ? projectCount : wordCount;

        dataset.data.push(dataCount);
      }

      datasets.push(dataset);
    }

    return (
      <Wrapper>
        <Container>
          <Line
            data={{
              labels,
              datasets,
            }}
            options={{
              legend: {
                display: false,
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      min: 0,
                    },
                    scaleLabel: {
                      display: true,
                      labelString: selectedMetric,
                    },
                  },
                ],
              },
            }}
          />
          <ChartBottomContainer>
            <ChartBottomHeader>
              {momentDate.format('MMMM, YYYY')}
            </ChartBottomHeader>
          </ChartBottomContainer>
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ChartBottomHeader = styled.h3`
  margin: 0;
`;

const ChartBottomContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
`;

const Container = styled.div`
  width: 75%;
  padding: 0 20px 20px;
`;

AnalyticsChart.propTypes = {
  projects: PropTypes.array,
  groups: PropTypes.array,
  team: PropTypes.array,
  groupBy: PropTypes.string,
};

AnalyticsChart.defaultProps = {
  projects: [],
  groups: [],
  team: [],
  groupBy: 'week',
};

export default AnalyticsChart;
