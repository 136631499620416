import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useState } from 'react';
import { Box, Flex } from 'rebass/styled-components';
import { InstantSearch, Pagination } from 'react-instantsearch-dom';

import useModal from '../../app/hooks/useModal';
import { Store } from '../../state/store';
import { post } from '../../util/fetchUtil';
import Dialog from '../Dialog';
import CustomButton from '../RebassButton';
import AddWordDialog from './AddWordDialog';
import PronounceWordProps from './PronounceWordProps';
import PronouceHits from './PronounceHits';

import CustomSearchBox from '../CustomSearchBox';
import { SearchIndex } from '../../types/search';
import useSearchClient from '../../app/hooks/useSearchClient';

export default function PronouncePage() {
  const [selectedEntry, setSelectedEntry] = useState<PronounceWordProps>();
  const { searchClient, indexName } = useSearchClient(SearchIndex.PRONOUNCE);
  const {
    state: { user },
  } = useContext(Store);

  const dialog = useModal({
    onHide: () => setSelectedEntry(undefined),
  });

  const handleSelectEntry = async (wordID: number) => {
    const res = await post('/pronounce/getWord', {
      wordID,
    });

    if (res.word) {
      setSelectedEntry(res.word);

      dialog.show();
    }
  };

  return (
    <InstantSearch indexName={indexName} searchClient={searchClient}>
      <Flex height="100%" bg="dark-3" flexDirection="column">
        <Dialog
          isVisible={dialog.isVisible}
          handleHideDialog={() => dialog.hide()}
        >
          <AddWordDialog
            entry={selectedEntry}
            canEdit={user.userID === selectedEntry?.createdBy.userID}
            handleHideDialog={dialog.hide}
          />
        </Dialog>

        <Flex justifyContent="space-between" width="100%" p={15} bg="dark-1">
          <Box flex={['unset', 1]}>
            <CustomButton
              onClick={dialog.show}
              startIcon={<FontAwesomeIcon icon={['fas', 'plus']} />}
            >
              Add<Box display={['none', 'inline']}>&nbsp;pronunciation</Box>
            </CustomButton>
          </Box>
          <Flex alignItems="center" flex={1} mx={20}>
            <Box mr="20px" color="white">
              <FontAwesomeIcon icon={['fad', 'search']} />
            </Box>
            <Box width="300px">
              <CustomSearchBox
                placeholder="Search pronunciation guide..."
                darkMode
              />
            </Box>
          </Flex>
          <Box display={['none', 'block']} flex={1}></Box>
        </Flex>

        <Flex justifyContent="center" flex="1" overflow="auto">
          <Box maxWidth="600px" width={['100%', '50%']}>
            <PronouceHits handleSelectEntry={handleSelectEntry} />
          </Box>
        </Flex>
        <Flex justifyContent="center" p={3} bg="dark-2">
          <Pagination />
        </Flex>
      </Flex>
    </InstantSearch>
  );
}
