import styled, { css } from 'styled-components/macro';

import { colors } from './styles/colors';
import elevations from './styles/elevations';
import { P } from './styles/typography';

export const Container = styled.div`
  position: absolute;
  background: white;

  top: 100%;
  right: 0;
  left: 0;

  z-index: 1;

  border-radius: 3px;

  margin-top: 10px;

  overflow: hidden;

  box-shadow: ${elevations[3]};
`;
export const UserName = styled(P)`
  margin: 0;
`;
export const UserRow = styled.div`
  padding: 5px 10px;

  cursor: pointer;

  :not(:last-child) {
    margin-bottom: 5px;
  }

  :hover {
    background: ${colors['light-grey-50']};
  }

  ${(props) =>
    props.hasFocus &&
    css`
      background: ${colors['light-grey-50']};
    `}
`;
export const Subheader = styled(P)`
  font-size: 14px;
  color: ${colors['light-grey-30']};
`;
