import React from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import AssetPreview from './asset-previews/AssetPreview';

import { Box, Flex } from '../../ui/Box';
import Button from '../../ui/Button';
import FileProps from '../editor/types/FileProps';

export type AssetHitProps = {
  name: string;
  id: number;
  createdOn: string;
  url: string;
};

interface Props {
  hits: AssetHitProps[];
  refineNext: () => void;
  hasMore: boolean;

  selectFiles: () => void;
  handleCopyToClipboard: () => void;
  selectedFiles: FileProps[];
}

const Hits = (props: Props) => {
  const {
    hits,
    refineNext,
    hasMore,

    selectedFiles,
    selectFiles,
    handleCopyToClipboard,
  } = props;

  return (
    <>
      <Flex flexWrap="wrap">
        {hits.map((fileObject: AssetHitProps) => (
          <Box key={fileObject.id}>
            <AssetPreview
              file={
                {
                  ...fileObject,
                  fileID: fileObject.id,
                  data: { url: fileObject.url },
                } as any
              }
              selectFiles={selectFiles}
              isSelected={
                !!selectedFiles.find(
                  (f) => f.fileID.toString() === fileObject.id.toString()
                )
              }
              handleCopyToClipboard={handleCopyToClipboard}
            />
          </Box>
        ))}
      </Flex>

      {hasMore && <Button onClick={refineNext}>Load more</Button>}
    </>
  );
};

const AssetHits = connectInfiniteHits(Hits as any); // NOTE: issue with custom props in Hit component

export default AssetHits as any;
