import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components/macro';

import React, { useState, useRef, useContext, useEffect } from 'react';

import tippy from 'tippy.js';

import { Store } from '../../state/store';

import AnnotationArea from '../comments/AnnotationArea';
import Draggable from '../Draggable';
import Button from '../Button';
import SearchReset from '../icons/SearchReset';

import { colors, lighten } from '../styles/colors';
import elevations from '../styles/elevations';

/**
 * Displays the selected MP4 file
 *
 * @class VideoViewer
 * @extends {Component}
 */
function ImageViewer() {
  const {
    state: { activeFile },
  } = useContext(Store);

  const [containerRatio, setContainerRatio] = useState(0);
  const [imageRatio, setImageRatio] = useState(0);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [zoomWidth, setZoomWidth] = useState(1);

  const [mouseIsOver, setMouseIsOver] = useState(false);

  const containerRef = useRef();
  const imageRef = useRef();

  const onResize = () => {
    // Recalculate the container's ratio whenever the window resizes
    if (containerRef.current) {
      setContainerRatio(
        containerRef.current.clientWidth / containerRef.current.clientHeight
      );
      setPosition({ x: 0, y: 0 });
    }
  };

  const reset = () => {
    setZoomWidth(1);
    setPosition({ x: 0, y: 0 });
  };

  const zoom = (amount = 0.05) => {
    setZoomWidth(zoomWidth + amount);
  };

  const onKeyDown = (e) => {
    const { keyCode, ctrlKey, metaKey, shiftKey } = e;

    const modifiersActive =
      (window.navigator.platform === 'MacIntel' && metaKey && shiftKey) ||
      (window.navigator.platform !== 'MacIntel' && ctrlKey && shiftKey);

    switch (keyCode) {
      case 187:
        if (modifiersActive) {
          e.preventDefault(0.05);
          zoom();
        }
        break;
      case 189:
        if (modifiersActive) {
          e.preventDefault();
          zoom(-0.05);
        }
        break;
      case 67:
        if (modifiersActive && mouseIsOver) {
          e.preventDefault();
          reset();
        }
        break;
      default:
        break;
    }
  };

  const onLoad = () => {
    if (containerRef.current) {
      setImageRatio(
        imageRef.current.naturalWidth / imageRef.current.naturalHeight
      );
      setContainerRatio(
        containerRef.current.clientWidth / containerRef.current.clientHeight
      );
    }
  };

  useEffect(() => {
    tippy('[data-tippy-content]');
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    window.addEventListener('keydown', onKeyDown);

    imageRef.current.addEventListener('load', onLoad);

    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('keydown', onKeyDown);
      if (imageRef.current)
        // eslint-disable-next-line
        imageRef.current.removeEventListener('load', onLoad);
    };
    // eslint-disable-next-line
  }, [zoomWidth]);

  const handleMouseMove = ({ dx, dy }) => {
    const newLeft = position.x + dx;
    const newTop = position.y + dy;

    setPosition({ x: newLeft, y: newTop });
  };

  let imageWidth = '100%';
  if (containerRatio.toFixed(2) > imageRatio.toFixed(2)) {
    // Round to 2 decimal places, otherwise image always forces it's own width
    imageWidth = `${
      containerRef.current.clientHeight * imageRatio * zoomWidth
    }px`;
  }

  return (
    <Container
      ref={containerRef}
      onMouseOver={() => setMouseIsOver(true)}
      onMouseLeave={() => setMouseIsOver(false)}
    >
      <NavigationContainer>
        <Button
          dataTippyContent="Zoom in (cmd shift +)"
          onClick={() => zoom(0.05)}
          className="icon"
        >
          <FontAwesomeIcon icon={['fad', 'search-plus']} />
        </Button>
        <Button
          dataTippyContent="Zoom out (cmd shift c)"
          onClick={() => zoom(-0.05)}
          className="icon"
        >
          <FontAwesomeIcon icon={['fad', 'search-minus']} />
        </Button>
        <Button
          dataTippyContent="Reset zoom (cmd shift -)"
          onClick={reset}
          className="icon"
        >
          <SearchReset />
        </Button>
      </NavigationContainer>
      <Draggable cursor onMouseMove={handleMouseMove}>
        <ImageContainer left={position.x} top={position.y}>
          <AnnotationArea id="video-annotations" />
          <Image
            ref={imageRef}
            src={activeFile.data && activeFile.data.url}
            imageWidth={imageWidth}
          />
        </ImageContainer>
      </Draggable>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
`;

// Needed for proper SVG sizing at different video widths / heights
const ImageContainer = styled.div`
  position: relative;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) => css`
    left: ${props.left}px;
    top: ${props.top}px;
  `}
`;

const Image = styled.img`
  ${(props) =>
    props.imageWidth &&
    css`
      width: ${props.imageWidth};
    `}
`;

const NavigationContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  background: ${lighten(colors.grey, 40)};

  padding: 5px;

  border-radius: 3px;
  margin: 10px;

  z-index: 1;

  box-shadow: ${elevations[3]};
`;

export default ImageViewer;
