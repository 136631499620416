import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { TextInput } from '../Input';
import Button from '../Button';
import Box from '../Box';
import { P } from '../styles/typography';
import { colors } from '../styles/colors';
import fetchUtil from '../../util/fetchUtil';

function AddKitDialog(props) {
  const { getKits } = props;

  const [newName, setNewName] = useState('');
  const handleCreateKit = () =>
    fetchUtil
      .post('/assets/addKit', {
        name: newName,
      })
      .then(() => {
        getKits();
      });

  return (
    <Box>
      <P>Name</P>
      <TextInput
        value={newName}
        onChange={(e) => setNewName(e.target.value)}
        placeholder="Enter kit name..."
      />
      <Button
        box
        backgroundColor={colors.blue}
        color="white"
        onClick={handleCreateKit}
      >
        Create kit
      </Button>
    </Box>
  );
}

AddKitDialog.propTypes = {
  getKits: PropTypes.func,
};

AddKitDialog.defaultProps = {
  getKits: () => {},
};

export default AddKitDialog;
