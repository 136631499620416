import styled from 'styled-components/macro';

import { colors } from '../styles/colors';

export const avatarSize = 150;
export const Container = styled.div`
  display: flex;
  justify-content: center;
`;
export const Input = styled.input`
  display: none;
`;
export const AvatarWrapper = styled.div`
  position: relative;
  border-radius: 100%;
  overflow: hidden;

  background: ${colors['light-grey-50']};

  display: flex;
  justify-content: center;
  align-items: center;

  width: ${avatarSize}px;
  height: ${avatarSize}px;

  cursor: pointer;
`;
export const AvatarOverlay = styled.div`
  position: absolute;
  opacity: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;

  transition: 0.1s ease-in-out all;

  ${AvatarWrapper}:hover & {
    opacity: 1;
  }
`;
export const OverlayBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  background: black;

  opacity: 0.5;
`;
export const Message = styled.p`
  color: white;
  text-align: center;
  max-width: 100px;

  position: relative;
`;
