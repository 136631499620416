import { post } from '../../util/fetchUtil';
import { fetchFile } from '../fileManager';

export type ScreenshotDataProps = {
  s3Key: string;
  duration: number;
  time: number;
  temp?: boolean;
};

/**
 * Get multiple screenshots from multiple scenes
 *
 * @param {[Object]} scenesData
 * [{
 *    s3Key: String, // the s3Key for the scene
 *    time: Number, // the time to take the screenshot
 *    duration: number, // the scene duration
 *    temp: Boolean, // whether this should be saved to a temporary s3 bucket or permanent
 * }]
 * @returns
 */
export default async (scenesData: ScreenshotDataProps[]) => {
  const { screenshots } = await post('getSceneScreenshotsV2', {
    scenesData,
  });

  const blobs: Promise<Blob>[] = [];
  screenshots.forEach((url: string) => {
    blobs.push(fetchFile(url));
  });

  const blobsRes = await Promise.all(blobs);

  return screenshots.map((url: string, i: number) => ({
    url,
    blob: blobsRes[i],
  }));
};
