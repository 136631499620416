import SVG from 'svgjs';
import seedrandom from 'seedrandom';
import $ from 'jquery';

import Anim from '../anim';

import font from '../font';
import { check } from '../../utilities/spelling';
import characterData from '../data/characters';
import setDashoffset from '../setDashoffset';

function decodeHtml(html) {
  const text = document.createElement('textarea');
  text.innerHTML = html;
  return text.value;
}

const matrixStringToArray = function (matrixString) {
  if (!matrixString) return [1, 0, 0, 1, 0, 0];
  return matrixString
    .replace(/^\w*\(/, '')
    .replace(')', '')
    .split(/\s*,\s*/);
};

// Helper function to extract all characters and attributes of the <text> element
const getTextObject = function (
  textElement,
  transformArray = [1, 0, 0, 1, 0, 0]
) {
  const fontSizeStr = textElement.style['font-size'].slice(0, -2);
  const fontSize = parseInt(fontSizeStr, 10);

  const TextObject = {
    characters: null,
    textContent: textElement.textContent,
    draw: null,
    position: {
      x: parseFloat(textElement.getAttribute('x').slice(0, -2)),
      y: parseFloat(textElement.getAttribute('y').slice(0, -2)),
    },
    transform: transformArray,
    fontSize,
  };

  const textInnerHTML = textElement.innerHTML;
  const textStyle = textElement.hasAttribute('style')
    ? textElement.getAttribute('style')
    : null;

  const textArr = [];
  let parentAttributes = null;
  let children = null;

  // Array that holds attributes of parent <text> element if they exist
  if (textElement.hasAttributes()) {
    parentAttributes = textElement.attributes;
  }
  // Array that holds <tspan> elements if they exist
  if (textElement.hasChildNodes()) {
    ({ children } = textElement);
  }

  // Counter to keep track of number of <tspan> child elements
  let count = 0;

  // Go through <text> element
  for (let i = 0; i < textInnerHTML.length; i += 1) {
    // If <tspan> element is found in <text> element
    if (textInnerHTML.substr(i, 6) === '<tspan') {
      const tspan = children[count];
      // Add individual characters of <tspan> element to textArray
      for (let j = 0; j < children[count].innerHTML.length; j += 1) {
        textArr.push({
          text: children[count].innerHTML[j],
          style: tspan.hasAttribute('style')
            ? tspan.getAttribute('style')
            : textStyle,
          color: textElement.style.fill ? textElement.style.fill : 'black',
          fillOpacity: textElement.style.fillOpacity
            ? textElement.style.fillOpacity
            : 1,
          fontWeight: textElement.style.fontWeight
            ? textElement.style.fontWeight
            : 'regular',
          attr: children[count].attributes,
        });
      }

      // Increment count of child <tspan> elements
      count += 1;
      // Skip ahead to end of <tspan> element
      i = textInnerHTML.indexOf('/tspan>', i) + 6;
    } else {
      // Add character found in root <text> element
      textArr.push({
        text: textInnerHTML[i],
        style: textStyle,
        color: textElement.style.fill ? textElement.style.fill : 'black',
        fillOpacity: textElement.style.fillOpacity
          ? textElement.style.fillOpacity
          : 1,
        fontWeight: textElement.style.fontWeight
          ? textElement.style.fontWeight
          : 'regular',
        attr: parentAttributes,
      });
    }
  }

  TextObject.characters = textArr;

  return TextObject;
};

// Helper function to parse an HTML string to HTML elements and return an array of paths
function getPathsFromHTML(letterString) {
  const html = $.parseHTML(letterString);
  const paths = [];

  if (html) {
    for (let i = 0; i < html.length; i += 1) {
      if (html[i].localName === 'g') {
        for (let j = 0; j < html[i].children.length; j += 1) {
          paths.push(html[i].children[j]);
        }
      } else {
        paths.push(html[i]);
      }
    }
  }

  return paths;
}

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function getCharInfo(characters, currentIndex, onError) {
  let textString = '';
  let charName = '';
  let incrementCount = 0;

  if (characters[currentIndex].text === '&') {
    for (let i = currentIndex; i < characters.length; i += 1) {
      textString += characters[i].text;
      if (characters[i].text === ';') {
        incrementCount = textString.length - 1;
        break;
      }
    }
  } else {
    textString = characters[currentIndex].text;
  }

  // Check for white space
  if (textString.charCodeAt(0) === 32 || textString.charCodeAt(0) === 160) {
    // If space (32) or non-breaking space (160) entered, add space
    charName = 'space';
    // If tab (9) add 8 spaces to xPosition
    // NOTE tab may need to be added to font?
  } else if (textString.charCodeAt(0) === 9) {
    // eslint-disable-next-line no-console
    console.error('Sorry, tabs are not allowed!');
    onError('Sorry, tabs are not allowed!');
  } else {
    // Decode the HTML character reference (e.g. &amp; ==> &)
    const char = decodeHtml(textString);
    // Find the character in characters.js
    const characterTypeKeys = Object.keys(characterData);
    for (let i = 0; i < characterTypeKeys.length; i += 1) {
      const type = characterTypeKeys[i];
      const charEntry = characterData[type].find(
        (c) =>
          c.key === char || // Check if key exists
          (c.alt && c.alt.find((alternate) => alternate === char))
      ); // Check if there are alternate options

      if (charEntry) {
        // Lowercase letters denoted by an underscore on the end, e.g. 'a_'
        const isLowercase = charEntry.name[charEntry.name.length - 1] === '_';
        if (isLowercase) {
          // Only use first character if it's a lowercase letter (i.e. parse out the underscore)
          charName = charEntry.name.slice(0, -1);
        } else {
          charName = charEntry.name;
        }

        break;
      }
    }
  }
  return { charName, incrementCount };
}

function getKern(glyph, nextGlyph, metrics) {
  let kern = 0;
  if (glyph && nextGlyph) {
    const key = `${glyph.index.toString()},${nextGlyph.index.toString()}`;
    kern = metrics.kerningPairs[key] ? metrics.kerningPairs[key] : 0;
  }
  return kern;
}

/**
 * Splits a string by spaces and adds any misspelled words to the global misspelledWords array
 *
 * @param {String} textContent
 */
function checkSpelling(textContent, sceneData) {
  const words = textContent.split(' ');
  if (sceneData) {
    const currentWords = sceneData.misspelledWords;
    const newWords = check(words);
    sceneData.misspelledWords.push(
      ...newWords.filter(
        (newWord) =>
          !currentWords.find((currentWord) => currentWord === newWord)
      )
    );
  }
}

// Pass in SceneObject, id, and boolean directing whether to attach animation or not.
export default function (
  scene,
  id,
  animBool,
  sceneData,
  dimensions,
  onError = () => {}
) {
  const { tannerscript } = font;

  const elementId = id.toString();

  const draw = SVG(elementId);
  const name = 'Text Group';
  const type = 'textSvg';
  let anim = null;
  let bbox = null;

  draw.style({
    'stroke-linecap': 'round',
    'stroke-miterlimit': '1.5',
    'stroke-linejoin': 'round',
  });

  draw.viewbox(0, 0, dimensions.w, dimensions.h);

  // ------------------------------------------------ //

  // ------ Parse scene information into discreet "TextObjects" ----- //

  // Master array that holds all the parsed text information
  const text = [];

  const searchChild = (child, previousMatrixArray = [1, 0, 0, 1, 0, 0]) => {
    // Check if exported <g> has a transform attribute
    const transform = child.hasAttribute('transform')
      ? child.getAttribute('transform')
      : null;

    // If there are multiple nested <g> elements, accumulate transforms
    const matrixString = transform || '';
    // If matrix string exists, convert to array, otherwise use default matrix array
    const matrixStringArray = matrixString
      ? matrixStringToArray(matrixString)
      : [1, 0, 0, 1, 0, 0];
    const matrixArray = matrixStringArray.map((item) => parseFloat(item));

    const newMatrixArray = [
      previousMatrixArray[0] * matrixArray[0], // (parentScaleX * childScaleX)
      0, // Skew (?) - not needed
      0, // Skew (?) - not needed
      previousMatrixArray[3] * matrixArray[3], // (parentScaleY * childScaleY)
      previousMatrixArray[4] + previousMatrixArray[0] * matrixArray[4], // parentX + (parentScaleX * childX)
      previousMatrixArray[5] + previousMatrixArray[3] * matrixArray[5], // parentY + (parentScaleY * childY)
    ];

    // If it's a group, iterate through each <text> element
    for (let i = 0; i < child.children.length; i += 1) {
      // Make sure it's a <text> element
      const element = child.children[i];
      if (element.nodeName === 'text') {
        // Add textObject to text array
        // If <g> had a transform, then pass the same transform to <text> element
        text.push(getTextObject(element, newMatrixArray));
      } else if (element.nodeName === 'g') {
        // If another group is found within the group, call searchChild (recursive)
        searchChild(element, newMatrixArray);
      }
    }
  };

  // If it's a group node, use searchChild function to search recursively
  if (scene.nodeName === 'g') {
    searchChild(scene);
  } else if (scene.nodeName === 'text') {
    text.push(getTextObject(scene));
  }

  // Assemble full text content for <text> or group of <text>
  let textContent = '';
  for (let i = 0; i < text.length; i += 1) {
    textContent += text[i].textContent;
    if (i + 1 < text.length) {
      textContent += ' ';
    }
  }

  // Add (semi-)unique text content as <svg> attribute
  draw.attr({ text: textContent });

  // Add text to global words variable
  // sceneWords.push(...textContent.split(' '));
  checkSpelling(textContent, sceneData);

  // ----------------------------------------------------- //

  // ------ Draw and organize custom text paths based on written text ----- //

  // Duration factor - lower = slower, higher = faster
  const durationFactor = 1;
  const offsetDelay = durationFactor * 200;

  // Delay between subsequent animations
  let dataDelay = 0;

  // Draw the svg based on the entered word(s)
  for (let i = 0; i < text.length; i += 1) {
    // Seed the random number generator with the text string,
    // subsequent calls to Math.random() will be remain the same as long as 'textContent' stays the same
    seedrandom(text[i].textContent, { global: true });

    // Store cumulative x position of letters
    let xPosition = 0;

    // Create <g> to store text
    const textGroup = draw.group();

    for (let j = 0; j < text[i].characters.length; j += 1) {
      // Create <g> to store character
      const characterGroup = draw.group();
      const character = text[i].characters[j];

      // Variable to store type of font (regular, bold, etc.)
      let fontWeight;
      let metrics;

      // Parse numerical fontWeight to fontWeight
      switch (character.fontWeight) {
        case '200':
          fontWeight = 'light';
          metrics = font.metrics.Light;
          break;
        case '700':
          fontWeight = 'bold';
          metrics = font.metrics.Bold;
          break;
        default:
          fontWeight = 'regular';
          metrics = font.metrics.Regular;
      }

      // Variable to hold original bbox of <text> element
      const { position, fontSize, transform } = text[i];

      // Number of pixels per em for 72 DPI
      const pixelsPerEm = 0.29875;

      const { ascender } = metrics;

      const ascenderHeight = ascender * pixelsPerEm;
      // const descenderHeight = descender * pixelsPerEm;

      // Get char info for current character (use currentIndex j and full array for finding html codes)
      const charInfo = getCharInfo(text[i].characters, j, onError);
      const nextCharInfo = text[i].characters[j + 1]
        ? getCharInfo(text[i].characters, j + 1, onError)
        : '';

      // Increment j if html code found; set charName
      if (charInfo.incrementCount) {
        j += charInfo.incrementCount;
      }
      const { charName } = charInfo;
      const glyph = Object.values(metrics.glyphs).filter(
        (g) => g.name === charInfo.charName
      )[0];
      const nextGlyph = nextCharInfo
        ? Object.values(metrics.glyphs).filter(
            (g) => g.name === nextCharInfo.charName
          )[0]
        : '';

      if (!glyph) {
        // Throw error message if couldn't find character in glyph set
        // eslint-disable-next-line no-console
        console.error(character);

        onError(
          `Couldn't find character "${
            character.text
          }" (character code: ${character.text.charCodeAt(0).toString()}) in "${
            text[i].textContent
          }". Please report this as a bug or try a similar character.`
        );
        break;
      }

      // Set width to advance character
      const advanceWidth = glyph.advanceWidth * pixelsPerEm;
      const kern = getKern(glyph, nextGlyph, metrics) * pixelsPerEm;
      const yMax = glyph.yMax * pixelsPerEm;

      // Calculate scale factor
      // For some reason the bbox.h is different on Chrome than other browsers (and also different
      // on Chrome for Windows). This scale factor seems to be constant, but I'm not sure if there's
      // a good way to derive it from the font metrics
      const fontSizeScaleFactor = 0.0033747938552407056;
      const scaleFactor = fontSizeScaleFactor * text[i].fontSize;

      let animPaths;
      let scenePaths;

      // Checks global 'tannerscript' object for character
      if (charName === 'space') {
        xPosition += advanceWidth * scaleFactor;
      } else if (tannerscript[fontWeight][charName]) {
        // Get random index to choose letter
        const randomIndex = randomIntFromInterval(
          0,
          tannerscript[fontWeight][charName].anim.length - 1
        );

        animPaths = getPathsFromHTML(
          tannerscript[fontWeight][charName].anim[randomIndex]
        );
        scenePaths = getPathsFromHTML(
          tannerscript[fontWeight][charName].scene[randomIndex]
        );

        if (animBool) {
          // Create scene and corresponding animation component for the text
          for (let k = 0; k < animPaths.length; k += 1) {
            // Create path shape to clip animation path
            const scenePath = draw
              .path(scenePaths[k].getAttribute('d'))
              .style(scenePaths[k].getAttribute('style'))
              .attr({
                'data-ignore': 'true',
              });

            // Create new path from text
            const animPath = draw
              .path(animPaths[k].getAttribute('d'))
              .style(animPaths[k].getAttribute('style'))
              .style({
                stroke: character.color,
                'fill-opacity': character.fillOpacity,
              });

            // Calculate animation duration based on path length
            const pathDataDuration = Math.ceil(
              animPath.length() / durationFactor
            );
            // Set animation data
            animPath
              .attr({
                'data-duration': pathDataDuration,
                'data-delay': dataDelay,
              })
              .addClass(`${elementId}-anim-path`);

            // Add both to same character group
            characterGroup.add(scenePath);
            characterGroup.add(animPath);

            // Clip the animation <path> with the scene <path>
            const clip = draw.clip().add(scenePath);
            animPath.clipWith(clip);

            // Add a delay (in ms) between subsequent paths (only for paths after the first path)
            dataDelay += offsetDelay + pathDataDuration;
          }
        } else {
          // Create just the scene component of the text without an animation
          for (let k = 0; k < animPaths.length; k += 1) {
            // Create path shape to clip animation path
            const scenePath = draw
              .path(scenePaths[k].getAttribute('d'))
              .style(scenePaths[k].getAttribute('style'))
              .fill(character.color)
              .attr({
                'data-ignore': 'true',
              });

            // Add both to same character group
            characterGroup.add(scenePath);
          }
        }

        // Add fill specified fill opacity to character group
        characterGroup.opacity(character.fillOpacity);

        // Move the character forward and add a random amount of deviation from the center between -10 and 10 pixels
        const randomJitterY = randomIntFromInterval(-10, 10);

        // In theory, there should be a way to calculate these from the font metrics, though I'm not exactly sure how
        // so these are essentially derived from trial and error
        const yConstants = {
          regular: ascenderHeight - 40,
          light: ascenderHeight - 32,
          bold: ascenderHeight - 48,
        };

        const [scaleX, , , scaleY, translateX, translateY] = transform;

        const textTransformX = translateX + position.x * scaleX;
        // const textTransformX = parseFloat(transform[4]) / parseFloat(transform[0]);
        const textTransformY = translateY + position.y * scaleY;
        // const textTransformY = parseFloat(transform[5]) / parseFloat(transform[3]);

        // SCALE CHARACTER //
        characterGroup.scale(scaleFactor * scaleX, scaleFactor * scaleY);

        // TRANSLATE CHARACTER X //
        // Move character group to original <text> x position and then add the current xPosition
        characterGroup.transform({
          x:
            // Add transformX which accounts for scaling and x position
            textTransformX +
            // Add the cumulative x position based on advanceWidth and kerning (adjust with scaleX)
            xPosition * scaleX,
        });

        // TRANSLATE CHARACTER Y //
        // Move character group to original <text> y position and adjust for ascent height and random jitter
        characterGroup.transform({
          y:
            // Add transformY which accounts for scaling and y position
            textTransformY -
            // Subtract the passed font size (adjust with scaleY)
            fontSize * scaleY +
            // Adjust with Y constants (difference in Y position between actual Text and drawn path Text),
            // yMax for the particular letter, and add some random jitter
            (yConstants[fontWeight] - yMax + randomJitterY) *
              scaleFactor *
              scaleY,
        });

        // Increment xPosition by width of character, bearing between letters,
        // and any kerning (all adjusted with scaling factor)
        xPosition += (advanceWidth + kern) * scaleFactor;
      } else {
        // Throw error message if couldn't find character
        // eslint-disable-next-line no-console
        console.error(character);

        onError(
          `Couldn't find character "${
            character.text
          }" (character code: ${character.text.charCodeAt(0).toString()}) in "${
            text[i].textContent
          }". Please report this as a bug or try a similar character.`
        );
      }

      // Add characterGroup to textGroup
      textGroup.add(characterGroup);
    }

    text[i].draw = textGroup;
  }

  // ----------------------------------------------------- //

  bbox = draw.bbox();

  // Set animation (uses default animation as set in Animations.js)
  if (animBool) {
    anim = Anim({
      targets: `.${elementId}-anim-path`,
      strokeDashoffset: [setDashoffset, 0],
      autoplay: false,
      duration(target) {
        // Duration based on every 'data-duration' attribute
        return target.getAttribute('data-duration');
      },
      delay(target) {
        // 100ms delay multiplied by every div index, in ascending order
        return target.getAttribute('data-delay');
      },
      easing: 'easeInOutSine',
    });

    // Set initial value to end of animation
    anim.finish();

    // Create and hide original text for debugging purposes
    const tempId = `temp-${elementId}`;
    const $svgTestContainer = $(
      `<div class="svg-test-container" id=${tempId} style="height:100%;width:100%;position:absolute;display:none;"><div>`
    );
    $(`#${elementId}`).after($svgTestContainer);
    const original = SVG(tempId).svg(scene.outerHTML);

    original
      .style({
        opacity: 0.4,
        fill: 'black',
      })
      .viewbox(0, 0, dimensions.w, dimensions.h);
  }

  return {
    draw,
    anim,
    name,
    type,
    bbox,
  };
}
