import { memo } from 'react';

const SvgStageChildren = (props) => props.children;

const shouldMemo = (prevProps, nextProps) => {
  if (nextProps.isAnnotating) return true;
  return false;
};

export default memo(SvgStageChildren, shouldMemo);
