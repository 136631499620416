import React, { useContext } from 'react';

import { useRouteMatch } from 'react-router-dom';

import { Store } from '../../state/store';
import { setCurrentAnnotationSvg } from '../../state/annotations/actions';

import SavedAnnotation from './SavedAnnotation';
import Annotation from './Annotation';

import { Container } from './AnnotationAreaStyles';

type Props = {
  id?: string;
};

/**
 * Container for either saved or current annotations
 *
 * @class AnnotationArea
 * @extends {Component}
 */
const AnnotationArea: React.FC<Props> = (props: Props) => {
  const { id = 'annotation-area' } = props;

  const {
    dispatch,
    state: { annotationIsActive, savedAnnotationSvg },
  } = useContext(Store);

  /**
   * Callback function that gets the current SVG html
   * and saves it to the store
   *
   * @param {Object} svg
   * @memberof onDragEnd
   */
  const onDragEnd = (svg: SVGElement) =>
    setCurrentAnnotationSvg(svg.outerHTML, dispatch);

  // Match the route for comments
  const commentPanelVisible = !!useRouteMatch(
    '/project/:projectID/files/:fileID/comments'
  );

  return (
    <Container id={id} visible={commentPanelVisible}>
      <SavedAnnotation svg={savedAnnotationSvg} />
      {annotationIsActive && (
        <Annotation isActive={annotationIsActive} onDragEnd={onDragEnd} />
      )}
    </Container>
  );
};

export default AnnotationArea;
