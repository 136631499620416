export default [
  {
    id: 'pen',
    icon: 'fad fa-highlighter',
  },
  {
    id: 'box',
    icon: 'fad fa-draw-square',
  },
];
