import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import { colors } from '../styles/colors';

function SectionPreview(props) {
  const { title } = props;

  return (
    <Container>
      <LeftSectionDivider>
        <img src="https://i.imgur.com/hmMLqNF.png" alt="Divider" />
      </LeftSectionDivider>
      <Header>{title}</Header>
      <RightSectionDivider>
        <img src="https://i.imgur.com/hmMLqNF.png" alt="Divider" />
      </RightSectionDivider>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 800px;

  margin: auto;
  margin-bottom: 15px;
`;

const Header = styled.div`
  font-weight: 700;
  font-size: 32px;
  text-transform: uppercase;

  color: ${colors.blue};

  margin-left: 15px;
  margin-right: 15px;

  text-align: center;
  line-height: 1.2;

  white-space: nowrap;
`;
const RightSectionDivider = styled.div`
  img {
    width: 100%;
  }
`;

const LeftSectionDivider = styled(RightSectionDivider)`
  transform: scaleX(-1);
`;

SectionPreview.propTypes = {
  title: PropTypes.string,
};

SectionPreview.defaultProps = {
  title: '',
};

export default SectionPreview;
