export const post = async (url, body, options = {}) => {
  const { suppressError = false } = options;

  const res = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({
      ...body,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const data = await res.json();

  if (data.error && !suppressError) {
    // Probably should have a different modal here
    alert(data.error.msg || 'Something happened 😧');
  }

  return data;
};

export const get = async (url, options = {}) => {
  const res = await fetch(url, options);
  const data = await res.json();
  return data;
};

export default {
  get,
  post,
};
