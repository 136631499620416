// Master list of characters included with Osmosify
// Any new characters have to be added to this list!

export default {
  letters_lowercase: [
    { name: 'a_', key: 'a' },
    { name: 'b_', key: 'b' },
    { name: 'c_', key: 'c' },
    { name: 'd_', key: 'd' },
    { name: 'e_', key: 'e' },
    { name: 'f_', key: 'f' },
    { name: 'g_', key: 'g' },
    { name: 'h_', key: 'h' },
    { name: 'i_', key: 'i' },
    { name: 'j_', key: 'j' },
    { name: 'k_', key: 'k' },
    { name: 'l_', key: 'l' },
    { name: 'm_', key: 'm' },
    { name: 'n_', key: 'n' },
    { name: 'o_', key: 'o' },
    { name: 'p_', key: 'p' },
    { name: 'q_', key: 'q' },
    { name: 'r_', key: 'r' },
    { name: 's_', key: 's' },
    { name: 't_', key: 't' },
    { name: 'u_', key: 'u' },
    { name: 'v_', key: 'v' },
    { name: 'w_', key: 'w' },
    { name: 'x_', key: 'x' },
    { name: 'y_', key: 'y' },
    { name: 'z_', key: 'z' },
  ],
  letters_uppercase: [
    { name: 'A', key: 'A' },
    { name: 'B', key: 'B' },
    { name: 'C', key: 'C' },
    { name: 'D', key: 'D' },
    { name: 'E', key: 'E' },
    { name: 'F', key: 'F' },
    { name: 'G', key: 'G' },
    { name: 'H', key: 'H' },
    { name: 'I', key: 'I' },
    { name: 'J', key: 'J' },
    { name: 'K', key: 'K' },
    { name: 'L', key: 'L' },
    { name: 'M', key: 'M' },
    { name: 'N', key: 'N' },
    { name: 'O', key: 'O' },
    { name: 'P', key: 'P' },
    { name: 'Q', key: 'Q' },
    { name: 'R', key: 'R' },
    { name: 'S', key: 'S' },
    { name: 'T', key: 'T' },
    { name: 'U', key: 'U' },
    { name: 'V', key: 'V' },
    { name: 'W', key: 'W' },
    { name: 'X', key: 'X' },
    { name: 'Y', key: 'Y' },
    { name: 'Z', key: 'Z' },
  ],
  numbers: [
    { name: 'zero', key: '0' },
    { name: 'one', key: '1' },
    { name: 'two', key: '2' },
    { name: 'three', key: '3' },
    { name: 'four', key: '4' },
    { name: 'five', key: '5' },
    { name: 'six', key: '6' },
    { name: 'seven', key: '7' },
    { name: 'eight', key: '8' },
    { name: 'nine', key: '9' },
  ],
  punctuation: [
    { name: 'colon', key: ':' },
    { name: 'comma', key: ',' },
    { name: 'semicolon', key: ';' },
    { name: 'quotedbl', key: '"', alt: ['“', '”'] },
    { name: 'quotesingle', key: "'" },
    { name: 'period', key: '.' },
    { name: 'exclam', key: '!' },
    { name: 'question', key: '?' },
    { name: 'bracketleft', key: '[' },
    { name: 'bracketright', key: ']' },
    { name: 'curlybracketleft', key: '{' },
    { name: 'curlybracketright', key: '}' },
    { name: 'parenright', key: ')' },
    { name: 'parenleft', key: '(' },
    { name: 'emdash', key: '—' },
    { name: 'hyphen', key: '-' },
  ],
  math: [
    { name: 'greater', key: '>' },
    { name: 'greaterequal', key: '≥' },
    { name: 'equal', key: '=' },
    { name: 'less', key: '<' },
    { name: 'lessequal', key: '≤' },
    { name: 'percent', key: '%' },
    { name: 'plus', key: '+' },
  ],
  symbols: [
    { name: 'ampersand', key: '&' },
    { name: 'asterisk', key: '*' },
    { name: 'commercialat', key: '@' },
    { name: 'degree', key: '˚' },
    { name: 'dollarsign', key: '$' },
    { name: 'slash', key: '/' },
    { name: 'numbersign', key: '#' },
    { name: 'registered', key: '®' },
    { name: 'asciitilde', key: '~' },
  ],
  symbols_custom: [
    { name: 'downarrow', key: '√', keystroke: ['option', 'v'] },
    { name: 'uparrow', key: '◊', keystroke: ['option', 'shift', 'v'] },
    { name: 'leftarrow', key: 'Ç', keystroke: ['option', 'shift', 'c'] },
    { name: 'rightarrow', key: 'ç', keystroke: ['option', 'c'] },
    { name: 'linebullet', key: 'œ', keystroke: ['option', 'q'] },
    { name: 'linearrowbullet', key: 'Œ', keystroke: ['option', 'shift', 'q'] },
    { name: 'starbullet', key: '¡', keystroke: ['option', '1'] },
    { name: 'multiply', key: '•', keystroke: ['option', '8'] },
  ],
  spanish: [
    { name: 'aacute_', key: 'á' },
    { name: 'Aacute', key: 'Á' },
    { name: 'eacute_', key: 'é' },
    { name: 'Eacute', key: 'É' },
    { name: 'iacute_', key: 'í' },
    { name: 'Iacute', key: 'Í' },
    { name: 'oacute_', key: 'ó' },
    { name: 'Oacute', key: 'Ó' },
    { name: 'uacute_', key: 'ú' },
    { name: 'Uacute', key: 'Ú' },
    { name: 'ntilde_', key: 'ñ' },
    { name: 'Ntilde', key: 'Ñ' },
    { name: 'questiondown', key: '¿' },
  ],
  greek: [
    { name: 'alpha', key: 'α' },
    { name: 'beta', key: 'β' },
    { name: 'gamma', key: 'γ' },
    { name: 'delta', key: 'δ' },
    { name: 'epsilon', key: 'ε' },
    { name: 'mu', key: 'μ' },
  ],
};
