import styled, { css } from 'styled-components/macro';

import { colors } from '../styles/colors';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  pointer-events: none;

  ${(props) =>
    props.isActive &&
    css`
      cursor: url(https://d16qt3wv6xm098.cloudfront.net/_gukC53BRkCE0_xw-xyvz-AxQau9NFvX/_.png)
          0 32,
        pointer;
      pointer-events: initial;
    `}

  .box-annotation,
  .pencil-annotation {
    stroke: ${colors.red};
    stroke-width: 6;
    fill-opacity: 0;
    stroke-opacity: 0.75;
  }
`;
