import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import { Flex } from '../../Box';
import Button from '../../Button';

import tools from '../../../app/editor/editorCommentTools';

import { colors } from '../../styles/colors';

const ToolButton = styled(Button)`
  ${(props) =>
    props.isActive &&
    css`
      i,
      svg {
        color: ${colors.teal};
      }
    `}
`;

/**
 */
function CommentsHeader(props) {
  const {
    handleSave,
    toolsVisible,
    activeTool,
    setActiveTool,
    canSave,
  } = props;

  return (
    <Flex style={{ justifyContent: 'space-between', width: '100%' }}>
      <Flex>
        {toolsVisible &&
          tools.map((tool) => (
            <ToolButton
              icon
              onClick={() => setActiveTool(tool.id)}
              key={tool.id}
              isActive={activeTool === tool.id}
              color="white"
            >
              <i className={tool.icon} />
            </ToolButton>
          ))}
      </Flex>
      <Button
        enable={canSave}
        thin
        box
        backgroundColor={colors.green}
        color="white"
        onClick={handleSave}
      >
        Save
      </Button>
    </Flex>
  );
}

const { bool, func, string } = PropTypes;

CommentsHeader.propTypes = {
  handleSave: func,
  toolsVisible: bool,
  activeTool: string,
  setActiveTool: func,
  canSave: bool,
};

CommentsHeader.defaultProps = {
  handleSave: () => {},
  toolsVisible: true,
  activeTool: '',
  setActiveTool: () => {},
  canSave: false,
};

export default CommentsHeader;
