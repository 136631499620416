import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';

import Box from '../../Box';

const IconContainer = styled(Box)`
  width: ${(props) => props.size || 36}px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: white;
  opacity: 0.5;
`;

export { Icon, IconContainer };
