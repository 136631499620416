import { isNumber } from '../../utilities/numbers';

/**
 * Converts string with 'px' to a number
 *
 * @param {String} str - string formatted like '2px'
 * @returns {Number | String}
 */
export const pxToNumber = (str: string): number | string => {
  const number = Number(str.split('px')[0]);
  if (isNumber(number)) return number;

  return str;
};

export const stringToObject = (str = ''): { [index: string]: any } => {
  const styleObj: { [index: string]: any } = {};

  if (typeof str === 'string') {
    str.split(';').forEach((style) => {
      const [name, value] = style.split(':');
      styleObj[name] = value;
    });
  }

  return styleObj;
};

export default {
  pxToNumber,
};
