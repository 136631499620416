class Playback {
  constructor() {
    this.delay = 1000;

    this.timeline = [];
    this.duration = 0;
  }

  /**
   * Updates the timeline data
   * Needs to be called every time something is changed on the timeline
   *
   * @memberof Playback
   */
  updateTimeline(objects) {
    const self = this;

    // Clear timeline array
    this.timeline.length = 0;

    // Reset duration and currentTime
    this.duration = 0;

    // Variable to hold the next animation index in the array
    let nextAnimIndex = null;
    let longestSyncDuration = 0;
    // Load animations into timeline
    for (let i = 0; i < objects.length; i += 1) {
      if (objects[i + 1]) {
        nextAnimIndex = objects[i + 1].animIndex;
      } else {
        nextAnimIndex = null;
      }

      const timelineEntry = {
        id: objects[i].id,
        animIndex: objects[i].animIndex,
        animejs: objects[i].anim.animejs,
        // Add delay to start time and end time (i.e. shift animation by delay)
        start: self.duration + self.delay,
        end: self.duration + objects[i].anim.animejs.duration + self.delay,
      };

      // Add to timeline
      self.timeline.push(timelineEntry);

      // Increment duration if the animation indexes aren't equal and the previous animation wasn't synced
      if (
        timelineEntry.animIndex !== nextAnimIndex &&
        longestSyncDuration === 0
      ) {
        // Increment duration, add delay between animations and longest synchronous animation duration
        self.duration =
          self.duration + timelineEntry.animejs.duration + self.delay;
      } else if (
        timelineEntry.animIndex !== nextAnimIndex &&
        longestSyncDuration > 0
      ) {
        // Keep track of the longest synchronous animation duration
        if (timelineEntry.animejs.duration > longestSyncDuration)
          longestSyncDuration = timelineEntry.animejs.duration;

        // Add the delay and the longest duration recorded for the synchronized animations
        self.duration = self.duration + self.delay + longestSyncDuration;

        // Reset longestSyncDuration
        longestSyncDuration = 0;
      } else if (timelineEntry.animejs.duration > longestSyncDuration) {
        // Keep track of the longest synchronous animation duration
        longestSyncDuration = timelineEntry.animejs.duration;
      }
    }

    // Add delay to the end
    self.duration += self.delay;
  }

  /**
   * Seeks to a given time-point
   *
   * @param {*} progress [number] - number representing the progress in the animation to seek to
   * @memberof Playback
   */
  seek(progress) {
    const { timeline } = this;

    for (let i = 0; i < timeline.length; i += 1) {
      if (timeline[i].start > progress) {
        timeline[i].animejs.seek(0);
      } else if (timeline[i].end <= progress) {
        timeline[i].animejs.seek(timeline[i].animejs.duration);
      } else {
        timeline[i].animejs.seek(progress - timeline[i].start);
      }
    }
  }
}

// Export to window
// Instantiate object on export (so there's only one object instance)
const playback = new Playback();
window.playback = playback;

export default playback;
