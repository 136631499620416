import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useEffect } from 'react';

import FilesList from './FilesList';
import FileInfoDialog from './FileInfoDialog';

import { video as videoFileTypes } from '../../shared/utilities/fileTypes';

import Message from '../Message';
import Box from '../Box';
import Dialog from '../Dialog';
import { colors, lighten } from '../styles/colors';

import { Section } from '../views/ProjectContentsStyles';

function UploadsSection(props) {
  const {
    downloadFile,
    fetchProject,
    downloadError,
    activeProject: { projectID, files },
  } = props;

  const [fileInfoVisible, setFileInfoVisible] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const filesData = [
    {
      title: 'Transcripts',
      color: 'lavender',
      uploader: {
        types: ['md'],
        fileData: {
          projectAssetType: 'transcript',
        },
      },
    },
    {
      title: 'Scripts',
      color: 'lavender',
      uploader: {
        types: ['gdoc'],
        fileData: {
          projectAssetType: 'script',
        },
      },
    },
    {
      title: 'SVGs',
      color: 'blue',
      uploader: {
        types: ['svg'],
        fileData: {
          projectAssetType: 'scene-svg',
        },
      },
    },
    {
      title: 'Design Assets',
      color: 'teal',
      uploader: {
        types: [
          'afdesign',
          'ai',
          'psd',
          'afpalette',
          'dfont',
          'ttf',
          'asa',
          'aep',
          'indd',
          'indt',
          'afpub',
          'pptx',
          'afassets',
          'zip',
        ],
        fileData: {
          projectAssetType: 'design',
        },
      },
    },
    {
      title: 'Voiceover files',
      color: 'olive',
      uploader: {
        types: ['wav', 'mp3'],
        fileData: {
          projectAssetType: 'voiceover',
        },
      },
    },
    {
      title: 'Camtasia files',
      color: 'green',
      uploader: {
        types: ['zip'],
        errorInstructions:
          'Please compress the Camtasia project into a zip file before upload.',
        fileData: {
          projectAssetType: 'camtasia',
        },
      },
    },
    {
      title: 'Video Files',
      color: 'yellow',
      uploader: {
        types: videoFileTypes,
        fileData: {},
      },
    },
    {
      title: 'Image Assets',
      color: 'orange',
      projectAssetType: 'image-asset',
      message: (
        <Message
          style={{ marginTop: 10, width: '100%' }}
          color={colors.red}
          backgroundColor={lighten(colors.red, 40)}
          icon="exclamation-circle"
        >
          <Box>
            Asset uploads are deprecated! Upload assets to a kit instead (see{' '}
            <strong>Kits</strong> section above).
          </Box>
        </Message>
      ),
    },
    {
      title: 'Textbook Images',
      color: 'red',
      uploader: {
        types: ['png', 'jpeg', 'jpg', 'pdf'],
        allowMultiple: true,
        fileData: {
          projectAssetType: 'textbook',
        },
      },
    },
  ];

  const sections = filesData.map((asset) => {
    const filteredFiles = files.filter((file) =>
      // If project asset type is defined for the file types
      asset.projectAssetType
        ? // Only include files with the listed project asset type
          file.data.projectAssetType ===
          (asset.projectAssetType || asset.uploader.fileData.projectAssetType)
        : // Otherwise include all files of the given type
          asset.uploader.types.some((type) => type === file.type)
    );

    return (
      <Section key={asset.title}>
        <FilesList
          sectionTitle={asset.title}
          projectID={projectID}
          files={filteredFiles}
          downloadFile={downloadFile}
          color={asset.color}
          uploader={asset.uploader}
          message={asset.message}
          selectFiles={setSelectedFiles}
          downloadError={downloadError}
        />
      </Section>
    );
  });

  useEffect(() => {
    if (!fileInfoVisible && selectedFiles.length) setFileInfoVisible(true);
    // eslint-disable-next-line
  }, [selectedFiles]);

  return (
    <Container>
      <Dialog
        handleHideDialog={() => setFileInfoVisible(false)}
        isVisible={fileInfoVisible}
      >
        {projectID && (
          <FileInfoDialog
            files={selectedFiles}
            projectID={projectID}
            handleHideDialog={() => setFileInfoVisible(false)}
            onDelete={fetchProject}
          />
        )}
      </Dialog>
      {sections}
    </Container>
  );
}

const Container = styled.div``;

UploadsSection.propTypes = {
  activeProject: PropTypes.shape({
    files: PropTypes.array,
    projectID: PropTypes.number,
  }),
  downloadFile: PropTypes.func.isRequired,
  fetchProject: PropTypes.func.isRequired,
  downloadError: PropTypes.bool,
};

UploadsSection.defaultProps = {
  downloadError: false,
};

export default UploadsSection;
