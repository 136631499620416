export default {
  down: {
    initial: { opacity: 0, y: -10 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -10, transition: { easing: 'ease-in-out' } },
  },
  up: {
    initial: { opacity: 0, y: 10 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 10, transition: { easing: 'ease-in-out' } },
  },
};
