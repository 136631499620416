export const setAnnotationIsActive = (isActive, dispatch) => dispatch({
  type: 'SET_ANNOTATION_IS_ACTIVE',
  payload: isActive,
});

export const setAnnotationMode = (mode, dispatch) => dispatch({
  type: 'SET_ANNOTATION_MODE',
  payload: mode,
});

export const setSavedAnnotationSvg = (annotationSvg, dispatch) => dispatch({
  type: 'SET_SAVED_ANNOTATION_SVG',
  payload: annotationSvg,
});

export const setCurrentAnnotationSvg = (annotationSvg, dispatch) => dispatch({
  type: 'SET_CURRENT_ANNOTATION_SVG',
  payload: annotationSvg,
});
