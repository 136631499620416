import SVG from 'svgjs';

import $ from 'jquery';

import Animations from '../animations';
import drawText from './text';
import nodeSearch from '../nodeSearch';

// Helper function for clipping elements
const createClip = function (child, draw) {
  const clipPath = $(child.parentNode).find(
    child.getAttribute('clip-path').slice(4, -1)
  );
  draw.svg(child.outerHTML).svg(clipPath.outerHTML);
};

export default function (sceneObject, animBool, sceneData, doc) {
  // Create simple SVG of group with if labeled "image"
  const { scene } = sceneObject;
  let { type } = sceneObject;
  let anim;
  let dropdownValues;

  const elementId = sceneObject.id.toString();

  const draw = SVG(elementId);
  draw.viewbox(0, 0, sceneObject.dimensions.w, sceneObject.dimensions.h);

  const group = draw.group();
  group.addClass('image-group');

  const searchChildren = (child) => {
    switch (child.nodeName) {
      case 'g':
        // If the group has a clipping path attribute, find matching <clip-path>
        if (
          child.getAttribute('clip-path') &&
          child.firstChild?.nodeName !== 'use'
        ) {
          createClip(child, draw);
        } // Check if it's a group of text elements
        else if (
          nodeSearch.hasOnlyChildrenOfType(child, ['text', 'tspan', 'g'])
        ) {
          drawText(child, draw.id(), false, sceneData, sceneObject.dimensions);
        } // else recursively search into the <g> element
        else {
          for (let i = 0; i < child.children.length; i += 1) {
            searchChildren(child.children[i]);
          }
        }
        break;

      case 'text':
        drawText(child, draw.id(), false, sceneData, sceneObject.dimensions);
        break;

      case 'use':
        draw.svg(child.outerHTML);
        draw
          .defs()
          .svg($(doc).find(child.getAttribute('xlink:href'))[0].outerHTML);
        break;

      default:
        draw.svg(child.outerHTML).style({
          'stroke-linecap': 'round',
          'stroke-miterlimit': '1.5',
          'stroke-linejoin': 'round',
          'fill-rule': 'evenodd',
        });
    }
  };

  searchChildren(scene);

  draw.each(function () {
    if (this !== group) {
      group.add(this);
    }
  });

  const name = 'SVG Group';
  type = 'svgImage';
  const bbox = group.bbox();

  // eslint-disable-next-line no-param-reassign
  sceneObject.bbox = bbox;
  // eslint-disable-next-line no-param-reassign
  sceneObject.draw = draw;

  // Only attach animation if passed 'true'
  if (animBool) {
    const animations = Animations(sceneObject);
    // Set animation (uses default animation as set in Animations.js)
    anim = animations.anim;
    dropdownValues = animations.dropdownValues;
  }

  return {
    draw,
    anim,
    name,
    type,
    bbox,
    dropdownValues,
  };
}
