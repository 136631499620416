import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';

import React from 'react';

import { Store } from '../../state/store';
import { colors, lighten, darken, fade } from '../styles/colors';

const Container = styled.div`
  p {
    margin: 0;
  }

  a {
    text-decoration: underline;
  }

  > * {
    ${(props) =>
      props.darkMode &&
      css`
        color: ${fade('white', 40)};

        a {
          color: ${lighten(colors.blue, 30)};

          :hover {
            color: ${lighten(colors.blue, 30)};
          }
        }
      `}
  }
`;

const spliceString = (str, start, delCount, newSubStr) =>
  str.slice(0, start) + newSubStr + str.slice(start + Math.abs(delCount));

/**
 * Formats a given chunk of text by bolding any teammate names
 *
 * @class FormattedCommentText
 * @prop {String} text - the text to format
 * @extends {React.PureComponent}
 */
class FormattedCommentText extends React.PureComponent {
  render() {
    const { state } = this.context;
    const { text, darkMode } = this.props;
    const { team, user } = state;

    // Create regex that includes all team members
    const teamRegexString = team.reduce(
      (previous, current, index) =>
        `${previous}@${current.fullName}${
          index < state.team.length - 1 ? '|' : ''
        }`,
      '@here|'
    );

    // Find any matches
    const teammateRegex = RegExp(teamRegexString, 'g');
    const matches = [...text.matchAll(teammateRegex)];

    let newText = text;
    for (let i = 0; i < matches.length; i += 1) {
      const match = matches[i];
      const isUser = `@${user.givenName} ${user.familyName}` === match[0];

      const notUserColor = darkMode
        ? fade('white', 20)
        : darken(colors.grey, 5);

      const userColor = darkMode ? lighten(colors.blue, 20) : colors.blue;

      // Use regular string because we insert via dangerouslySetInnerHTML
      const formattedText = `<span style="font-weight: 700; color: ${
        isUser ? userColor : notUserColor
      }">${match[0]}</span>`;

      newText = spliceString(
        newText,
        match.index,
        match[0].length,
        formattedText
      );
    }

    return (
      <Container
        darkMode={darkMode}
        dangerouslySetInnerHTML={{
          __html: (!!newText.length && newText) || text,
        }}
      />
    );
  }
}

FormattedCommentText.contextType = Store;

const { string, bool } = PropTypes;

FormattedCommentText.propTypes = {
  text: string.isRequired,
  darkMode: bool,
};

export default FormattedCommentText;
