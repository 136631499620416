import ImageElement from '../../editor/ImageElement';
import PathElement from '../../editor/PathElement';
import RectElement from '../../editor/RectElement';
import TextElement from '../../editor/TextElement';
import GroupElement from '../../editor/GroupElement';

type Types = {
  [index: string]: any; // TODO:
};

// Object map used to instantiate SvgElements
const types: Types = {
  image: ImageElement,
  path: PathElement,
  rect: RectElement,
  text: TextElement,
  group: GroupElement,
};

export default types;
