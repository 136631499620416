import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React from 'react';

import SVG from 'svgjs';

class SceneBorder extends React.Component {
  componentDidMount() {
    this.initSceneBorder();
  }

  initSceneBorder() {
    const {
      state: { sceneDimensions },
    } = this.context;

    const draw = SVG('scene-border')
      .viewbox(0, 0, sceneDimensions.w, sceneDimensions.h)
      .width('100%')
      .height('100%');

    draw
      .rect(sceneDimensions.w, sceneDimensions.h)
      .stroke({ width: 1, color: 'red' })
      .attr({ 'stroke-dasharray': '10 10 10 10' })
      .fill('none');
  }

  render() {
    const {
      state: { sceneDimensions },
    } = this.context;

    return (
      <Container
        id="scene-border"
        viewBox={`0 0 ${sceneDimensions.w} ${sceneDimensions.h}`}
      />
    );
  }
}

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

SceneBorder.propTypes = {
  propName: PropTypes.string,
};

SceneBorder.defaultProps = {
  propName: '',
};

export default SceneBorder;
