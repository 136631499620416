import React from 'react';
import styled, { keyframes } from 'styled-components/macro';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from 'rebass/styled-components';
import { colors } from './styles/colors';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const SpinnerIcon = styled(FontAwesomeIcon)`
  animation: ${rotate} 1s linear infinite;
  font-size: ${(props) => props.size}px;

  color: ${colors['light-grey-20']};
`;

type Props = {
  size?: number;
  style?: React.CSSProperties;
  className?: string;
};

function LoadingSpinner({ size, className, style }: Props) {
  return (
    <Box fontSize={size} display="inline-flex">
      <SpinnerIcon
        style={style}
        className={className}
        icon={['fad', 'spinner-third']}
      />
    </Box>
  );
}

LoadingSpinner.defaultProps = {
  size: 32,
  className: '',
  style: {},
};

export default LoadingSpinner;
