import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { memo } from 'react';

import { AnimatePresence } from 'framer-motion';

import Box from '../../Box';
import { colors, lighten } from '../../styles/colors';
import TimelineCommentPoint from './TimelineCommentPoint';

import CommentProps from '../props/CommentProps';

const CommentsContainer = styled(Box)`
  position: relative;
  height: 30px;
  background-color: ${lighten(colors.night, 2)};
`;

const TimelineComments = (props) => {
  const { comments, duration, handleClickComment } = props;

  return (
    <CommentsContainer>
      <AnimatePresence>
        {comments
          .filter((comment) => comment.data.timestamp)
          .map((comment) => (
            <TimelineCommentPoint
              key={comment.commentID}
              handleClickComment={handleClickComment}
              comment={comment}
              duration={duration}
            />
          ))}
      </AnimatePresence>
    </CommentsContainer>
  );
};

const { func, bool, number, arrayOf } = PropTypes;

TimelineComments.propTypes = {
  comments: arrayOf(CommentProps),
  duration: number,
  handleClickComment: func,
  isPlaying: bool.isRequired,
};

TimelineComments.defaultProps = {
  comments: [],
  duration: 0,
  handleClickComment: () => {},
};

export default memo(TimelineComments, (prevProps, nextProps) => {
  if (nextProps.isPlaying) return true;
  return false;
});
