import styled from 'styled-components/macro';

import { P } from '../../styles/typography';
import { colors, lighten } from '../../styles/colors';

export const ProjectLabel = styled.div`
  padding: 3px 6px;
  background: ${colors['light-blue-40']};
  color: ${colors['dark-blue-10']};

  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 3px;

  margin: 4px;

  i,
  svg {
    color: ${colors['light-blue-10']};
    height: unset;
    width: unset;
    padding: 3px;

    cursor: pointer;

    :hover,
    :focus {
      background: ${lighten(colors.blue, 35)};
    }
    :active {
      background: ${lighten(colors.blue, 30)};
    }
  }
`;
export const ProjectName = styled(P)`
  display: inline-block;
  margin-right: 30px;

  cursor: pointer;
`;
