import PropTypes from 'prop-types';
import React from 'react';

class ContentEditable extends React.Component {
  constructor(props) {
    super(props);
    this.ref = props.domRef || React.createRef();
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.html !== this.ref.current.innerHTML;
  }

  emitChange() {
    const html = this.ref.current.innerHTML;
    if (this.props.onChange && html !== this.lastHtml) {
      this.props.onChange({
        target: {
          value: html,
        },
      });
    }
    this.lastHtml = html;
  }

  render() {
    const { style, className, html } = this.props;

    return (
      <div
        ref={this.ref}
        onInput={() => this.emitChange(this)}
        onBlur={() => this.emitChange(this)}
        contentEditable
        dangerouslySetInnerHTML={{ __html: html }}
        style={style}
        className={className}
      ></div>
    );
  }
}

ContentEditable.propTypes = {
  html: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
  className: PropTypes.string,
};

ContentEditable.defaultProps = {
  style: {},
  className: '',
};

export default ContentEditable;
