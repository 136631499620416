import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { ProjectKitProps } from '../../types/KitProps';
import Box from '../Box';
import { Option, OptionsText } from '../OptionSelectorStyles';
import { colors } from '../styles/colors';
import { H3 } from '../styles/typography';
import UploadBox from '../UploadBox';
import FileRow from './FileRow';
import AddToDirectoryDialog from '../assets/AddToDirectoryDialog';
import Dialog from '../Dialog';
import useModal from '../../app/hooks/useModal';
import FileProps from '../editor/types/FileProps';

interface Props {
  kit: ProjectKitProps;
  downloadFile: () => void;
  handleDeleteFile: (fileID: number, kitID: number) => void;
  setSelectedFiles: (files: FileProps[]) => void;
  handleLinkToKit: (kitID: number, fileIDs: number[]) => void;
  getKitData: () => void;
}

const KitSection: React.FC<Props> = (props: Props) => {
  const {
    kit,
    handleDeleteFile,
    downloadFile,
    setSelectedFiles,
    handleLinkToKit,
    getKitData,
  } = props;

  const [
    selectedDirectoryFile,
    setSelectedDirectoryFile,
  ] = useState<FileProps>();

  const addToDirectoryDialog = useModal();

  const handleAddToDirectory = (e: React.MouseEvent, file: FileProps) => {
    e.stopPropagation();

    setSelectedDirectoryFile(file);
    addToDirectoryDialog.show();
  };

  const handleOpenKit = (e: React.MouseEvent, file: FileProps) => {
    e.stopPropagation();
    window.open(`/#/assets/${kit.kitID}?f=${file.fileID}`, '_blank');
  };

  useEffect(() => {
    if (selectedDirectoryFile) {
      // Update the selected file whenever we update the kit
      setSelectedDirectoryFile(
        kit.files.find((f) => f.fileID === selectedDirectoryFile.fileID)
      );
    }
    // eslint-disable-next-line
  }, [kit]);

  return (
    <Box key={kit.kitID} style={{ margin: '15px 0' }}>
      <Dialog
        handleHideDialog={() => addToDirectoryDialog.hide()}
        isVisible={addToDirectoryDialog.isVisible}
      >
        <AddToDirectoryDialog
          kit={kit}
          file={selectedDirectoryFile}
          handleHideDialog={() => addToDirectoryDialog.hide()}
          update={getKitData}
        />
      </Dialog>
      <H3 style={{ margin: '10px 0' }}>{kit.name}</H3>
      <Box
        style={{
          paddingLeft: 10,
          marginLeft: 10,
          borderLeft: `1px solid ${colors['light-grey-40']}`,
        }}
      >
        {kit.files.map((file) => (
          <FileRow
            style={{ width: '100%', display: 'flex' }}
            key={file.fileID}
            file={file}
            handleDeleteFile={() => handleDeleteFile(file.fileID, kit.kitID)}
            onClick={() => setSelectedFiles([file])}
            actions={[
              {
                id: 'add-to-directory',
                icon: ['fad', 'folder'],
                onClick: (e) => handleAddToDirectory(e, file),
              },
            ]}
            options={[
              {
                key: 'add-to-directory',
                id: 'add-to-directory',
                name: 'Add to folder',
                component: (
                  <Option onClick={(e) => handleOpenKit(e, file)}>
                    <OptionsText>
                      <FontAwesomeIcon icon={['fad', 'external-link']} />
                      &nbsp;&nbsp;Open kit
                    </OptionsText>
                  </Option>
                ),
              },
              {
                key: 'add-to-directory',
                id: 'add-to-directory',
                name: 'Add to folder',
                component: (
                  <Option onClick={(e) => handleAddToDirectory(e, file)}>
                    <OptionsText>
                      <FontAwesomeIcon icon={['fad', 'folder']} />
                      &nbsp;&nbsp;Add to kit folder
                    </OptionsText>
                  </Option>
                ),
              },
            ]}
            downloadFile={downloadFile}
          />
        ))}
        <UploadBox
          style={{ marginTop: 10 }}
          allowMultiple={true}
          types={[]}
          onAfterUpload={(res) =>
            handleLinkToKit(
              kit.kitID,
              res.map((file: FileProps) => file.fileID)
            )
          }
        />
      </Box>
    </Box>
  );
};

export default KitSection;
