import React, { ReactNode } from 'react';
import { BoxProps } from 'rebass';
import Box from './Box';

const positive = {
  bg: 'positive',
};

const variants: { [index: string]: any } = {
  positive,
};

type Props = BoxProps & {
  variant?: 'negative' | 'positive';
  startIcon?: ReactNode;
};

const SnackbarContainer: React.FC<Props> = (props: Props) => {
  const { variant, bg, sx, startIcon } = props;
  return (
    <Box
      p={20}
      bg={bg}
      sx={{
        borderRadius: 5,
        fontSize: 18,
        fontWeight: 'bold',
        color: 'white',
        bg: 'dark-1',
        ...sx,
      }}
      {...(variant && variants[variant] ? variants[variant] : {})}
    >
      {startIcon && (
        <Box display="inline-block" mr={10}>
          {startIcon}
        </Box>
      )}
      {props.children}
    </Box>
  );
};

export default SnackbarContainer;
