/**
 * Gets arguments for creating new elements
 *
 * @param {Object} node - an SVG parser object
 * @param {Object} transform - object containing transform properties (translateX, translateY, etc.)
 * @param {Object} [args={}] - arguments to pass directly into new object
 * @param {Object} [options={}] - initialization options
 */
const getElementArgs = (node, args = {}, options = {}) => {
  let name = '';
  if (args.name) {
    ({ name } = args);
  } else if (node.attributes.tag) {
    name = node.attributes.tag;
  } else if (node.attributes.id) {
    name = node.attributes.id;
  }

  return [
    {
      ...args,
      props: {
        ...node.attributes,
        ...args.props,
      },
      // Add name if it's attached to the tag
      name: name.toString(),
    },
    {
      animStart: options.startTime + options.currentTime,
      usePreferenceStore: false,
    },
  ];
};

export default getElementArgs;
