import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useRef, useEffect } from 'react';

import fetchUtil from '../../util/fetchUtil';

import Autocomplete from '../Autocomplete';
import AddResource from '../admin/resources/AddResource';
import Button from '../Button';
import { colors } from '../styles/colors';
import { P, A } from '../styles/typography';

const ResourceRow = styled.div`
  display: flex;

  padding: 5px;
  :not(:last-child) {
    margin-bottom: 10px;
  }

  border-radius: 3px;

  background: ${colors['light-grey-50']};
`;

const Divider = styled.div`
  margin-bottom: 15px;
  margin-top: 15px;
`;

const Label = styled(P)`
  margin-bottom: 5px;
`;

const ButtonContainer = styled(P)`
  display: flex;
  justify-content: center;
`;

const Image = styled.div`
  height: 50px;
  margin-right: 10px;
  border-radius: 3px;
  overflow: hidden;

  img {
    height: 100%;
  }
`;

const ResourceInfo = styled.div`
  display: flex;
  justify-content: space-between;

  flex: 1;
`;

const Info = styled(P)`
  color: ${colors['light-grey-10']};
`;

function AddResourceDialog(props) {
  const { activeProject, getResources, handleHideDialog } = props;

  const [newResourceSearchValue, setNewProjectResourceValue] = useState('');
  const [addingResource, setAddingResource] = useState('');

  const resources = useRef([]);

  useEffect(() => {
    fetchUtil.post('/resources/getAll').then((res) => {
      resources.current = res.resources;
    });
  }, []);

  const handleSearchProjects = (e) => {
    const { value } = e.target;
    setNewProjectResourceValue(value);
  };

  const handleAddResource = (resource) => {
    setNewProjectResourceValue('');
    fetchUtil
      .post('/resources/addProjectResource', {
        resourceID: resource.resourceID,
        projectID: activeProject.projectID,
      })
      .then(() => {
        getResources();
      });
  };

  const handleAfterSave = (resource) => {
    setAddingResource(false);

    handleAddResource(resource);
  };

  return (
    <div>
      <Label>Attach existing resource</Label>
      <Autocomplete
        value={newResourceSearchValue}
        options={resources.current
          .filter((r) =>
            r.name.toLowerCase().includes(newResourceSearchValue.toLowerCase())
          )
          .map((resource) => ({
            ...resource,
            id: resource.resourceID,
            component: (
              <ResourceRow key={resource.resourceID}>
                <Image>
                  <img alt="Resource" src={resource.data.image} />
                </Image>
                <ResourceInfo>
                  <div>
                    <P>
                      <A href={resource.data.link} target="_blank">
                        {resource.name}
                      </A>
                    </P>
                    <Info>
                      {resource.data.attribution} • {resource.data.copyright}
                    </Info>
                  </div>
                </ResourceInfo>
              </ResourceRow>
            ),
          }))}
        placeholder="Search resources to add to this project..."
        onItemSelect={handleAddResource}
        onChange={handleSearchProjects}
      />
      <Divider className="divider text">
        <P>OR</P>
      </Divider>
      {!addingResource && (
        <ButtonContainer>
          <Button className="box" onClick={() => setAddingResource(true)}>
            Create new resource
          </Button>
        </ButtonContainer>
      )}
      {addingResource && (
        <AddResource
          handleHideDialog={handleHideDialog}
          onAfterSave={handleAfterSave}
          rowInfo={{ projects: [activeProject] }}
        />
      )}
    </div>
  );
}

AddResourceDialog.propTypes = {
  activeProject: PropTypes.shape({
    projectID: PropTypes.number,
  }).isRequired,
  getResources: PropTypes.func.isRequired,
  handleHideDialog: PropTypes.func.isRequired,
};

export default AddResourceDialog;
