import SceneObject from './SceneObject';
import drawTextSvg from '../app/draw/text';

class TextSvg extends SceneObject {
  constructor(args) {
    // Call the super constructor
    super(args);

    this.svg = drawTextSvg(
      this.scene,
      this.id,
      true,
      this.sceneData,
      this.dimensions,
      args.onError
    );
    this.anim = this.svg.anim;
    this.name = this.svg.name;
    this.bbox = this.svg.bbox;
    this.type = 'textSvg';
  }
}

export default TextSvg;
