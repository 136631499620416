import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box, { Flex } from '../Box';
import Button from '../RebassButton';
import FileExplorer from './navigation/FileExplorer';
import { post } from '../../util/fetchUtil';

import KitProps from '../../types/KitProps';
import FileProps from '../editor/types/FileProps';
import { KitDirectoryProps } from '../../types/KitDirectoryProps';
import { H3 } from '../styles/typography';
import ImageFileInfo from './ImageFileInfo';
import FileTagger from './controls/FileTagger';
import useFileTagger from '../../app/hooks/useFileTagger';

interface Props {
  kit: KitProps;
  file?: FileProps;
  handleHideDialog: () => void;
  update?: () => void;
}

const AddToDirectoryDialog: React.FC<Props> = (props: Props) => {
  const { kit, file, handleHideDialog, update = () => {} } = props;

  const [selectedDir, setSelectedDir] = useState<KitDirectoryProps>();
  const [tagsVisible, setTagsVisible] = useState(false);
  const [userInput, setUserInput] = useState('');

  const updateTags = () => {
    update();
    setUserInput('');
  };

  const { addTag, createTag, removeTag } = useFileTagger({
    onCreateTag: updateTags,
    onAddTag: updateTags,
    onRemoveTag: updateTags,
  });

  const handleGetAssets = async () => {};

  const handleAddToDirectory = async () => {
    if (file && selectedDir) {
      await post('/assets/addDirectoryFiles', {
        fileIDs: [file.fileID],
        directoryID: selectedDir.directoryID,
        kitID: kit.kitID,
      });
    }

    handleHideDialog();
  };

  const handleOpenKit = () => {
    window.open(`/#/assets/${kit.kitID}?f=${file?.fileID}`, '_blank');
  };

  return (
    <Box width={500}>
      <Box mt={15}>{file && <ImageFileInfo file={file} />}</Box>
      <Box>
        {file && (
          <Box mt={2}>
            <Button
              variant="transparent"
              color="light-6"
              size="s"
              endIcon={
                <FontAwesomeIcon
                  icon={['fas', tagsVisible ? 'chevron-up' : 'chevron-down']}
                />
              }
              onClick={() => setTagsVisible(!tagsVisible)}
            >
              Tags
            </Button>
            {tagsVisible && (
              <FileTagger
                files={[file]}
                canEdit={true}
                labelVisible={false}
                userInput={userInput}
                setUserInput={setUserInput}
                addTag={addTag}
                createTag={createTag}
                removeTag={removeTag}
              />
            )}
          </Box>
        )}
      </Box>

      <H3 mt={30}>Select a folder</H3>

      <Box maxHeight="50vh" overflow="auto" mb={20}>
        <FileExplorer
          kit={kit}
          selectedFiles={[]}
          selectFiles={() => {}}
          filters={[]}
          setFilters={() => {}}
          handleUpdate={handleGetAssets}
          canEdit={true}
          unorganizedVisible={false}
          setSelectedDir={setSelectedDir}
          selectedDir={selectedDir}
        />
      </Box>
      <Flex justifyContent="flex-end">
        <Box mr={10}>
          <Button
            onClick={handleOpenKit}
            variant="outline"
            color="dark-6"
            startIcon={<FontAwesomeIcon icon={['fad', 'external-link']} />}
          >
            Open kit
          </Button>
        </Box>

        <Button enabled={!!selectedDir} onClick={handleAddToDirectory}>
          Add to {selectedDir ? selectedDir.name : 'directory'}
        </Button>
      </Flex>
    </Box>
  );
};

export default AddToDirectoryDialog;
