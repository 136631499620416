import {
  isGroupElement,
  ElementProps,
} from '../../../ui/editor/types/ElementProps';

/**
 * Returns a flattened array of elements
 *
 * @param {[SvgElement]} elements
 * @returns {[SvgElement]} flattened array
 */
const flattenElements = (elements: ElementProps[]): ElementProps[] => {
  const flattenChildren = (el: ElementProps): ElementProps[] => {
    let children: ElementProps[] = [];
    if (isGroupElement(el)) {
      children = el.elements
        .map((e) => flattenChildren(e))
        .reduce((arr, item) => [...arr, ...item], []);
    }

    // Add mask elements
    if (el.maskElements) {
      children.push(
        ...el.maskElements
          .map((e) => flattenChildren(e))
          .reduce((arr, item) => [...arr, ...item], [])
      );
    }

    return [el, ...children];
  };

  const flattened = elements.reduce(
    (arr: ElementProps[], el: ElementProps) => [...arr, ...flattenChildren(el)],
    []
  );

  return flattened;
};

export default flattenElements;
