import React, { useState } from 'react';
import styled from 'styled-components/macro';
import Box, { Flex } from '../../Box';
import Button from '../../Button';
import ListItemSelector from '../../ListItemSelector';
import { H2, P } from '../../styles/typography';
import { SceneProps } from '../types/SceneProps';

type LocalScene = SceneProps & {
  checked: boolean;
  name: string;
  // id: number;
};

const StyledBox = styled(Flex)`
  justify-content: flex-end;
  > * {
    margin-right: 10px;
  }
`;

const getList = (names: string[]) =>
  names.reduce((string: string, name: string, i: number, arr: string[]) => {
    let newString = '';
    if (i < arr.length - 2) newString = ', ';
    else if (i === arr.length - 2) newString = ' and ';
    return `${string}"${name}"${newString}`;
  }, '');

type Props = {
  scenes: SceneProps[];
  onConfirm: (scenes: LocalScene[]) => void;
  handleHideDialog: () => void;
};
/**
 * A <g> component representing a group of elements
 */
function ReplaceScenesDialog(props: Props) {
  const { scenes, handleHideDialog, onConfirm } = props;

  console.log(scenes);
  const [localScenes, setLocalScenes] = useState<LocalScene[]>(
    scenes.map((scene) => ({
      ...scene,
      name: scene.data.name,
      id: scene.sceneID,
      checked: true,
    }))
  );

  const handleSelectScene = (scene: LocalScene) => {
    const newScenes = Array.from(localScenes);
    const newScene = newScenes.find((s) => s.sceneID === scene.sceneID);
    if (newScene) {
      newScene.checked = !newScene.checked;
    }

    setLocalScenes(newScenes);
  };

  const handleConfirm = () => {
    const checkedScenes = localScenes.filter((s) => s.checked);

    onConfirm(checkedScenes);
    handleHideDialog();
  };

  return (
    <Box maxWidth={400}>
      <Box mb={10}>
        <H2 mb={10}>Update scene{scenes.length > 1 ? 's' : ''}</H2>
        {scenes.length > 1 && (
          <P>
            Older scenes named {getList(scenes.map((s) => s.data.name))} already
            exist in this project. Select the scenes you want to replace.
            Existing comments will stay the same.
          </P>
        )}
        {scenes.length === 1 && (
          <P>
            An older scene named &quot;{scenes[0].data.name}&quot; already
            exists in this project. Do you want to replace it? Existing comments
            will stay the same.
          </P>
        )}
      </Box>
      {scenes.length > 1 && (
        <ListItemSelector onItemClick={handleSelectScene} items={localScenes} />
      )}
      <StyledBox mt={10}>
        <Button onClick={() => handleHideDialog && handleHideDialog()}>
          Cancel
        </Button>
        <Button basic color="blue" onClick={handleConfirm}>
          Update
        </Button>
      </StyledBox>
    </Box>
  );
}

export default ReplaceScenesDialog;
