import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import SvgElement from './SvgElement';

const StyledG = styled.g``;

const SvgPreview = ({ svgson }) => {
  const elementRef = useRef();
  const [bbox, setBBox] = useState();
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    setBBox(elementRef.current.getBBox());
    // setLoading(false);
  }, []);

  const padding = 100;
  let viewBox = '';
  let transform = '';
  if (bbox) {
    viewBox = `0 0 ${bbox.width + padding} ${bbox.height + padding}`;
    transform = `translate(${-bbox.x + padding / 2} ${-bbox.y + padding / 2})`;
  }

  let children = [];
  if (svgson.name === 'svg') {
    ({ children } = svgson);
  } else {
    children = [svgson];
  }

  return (
    <svg viewBox={viewBox} strokeLinecap="round">
      <StyledG ref={elementRef} transform={transform}>
        {children.map((childSvgson) => (
          <SvgElement key={childSvgson.id} node={childSvgson} />
        ))}
      </StyledG>
    </svg>
  );
};

const { shape, string } = PropTypes;

SvgPreview.propTypes = {
  svgson: shape({
    name: string,
  }),
};

export default SvgPreview;
