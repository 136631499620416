import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { memo } from 'react';

import { colors } from '../../styles/colors';
import Box from '../../Box';
import { P } from '../../styles/typography';

const TimepointContainer = styled(Box)`
  height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  left: ${(props) => props.left}px;

  pointer-events: none;
`;

const Tic = styled(Box)`
  height: ${(props) => props.height};
  width: 2px;
  background-color: ${colors['light-night-20']};
`;

const formats = [
  {
    pps: 0.01,
    unit: 's',
    step: 5000,
  },
  {
    pps: 0.05,
    unit: 's',
    step: 2000,
  },
  {
    pps: 0.1,
    unit: 's',
    step: 1000,
  },
];

const getTimepoints = (duration, format) => {
  const start = format.step;

  const increment = start;
  let currentTime = start;
  const timepoints = [];

  while (currentTime < duration) {
    timepoints.push(currentTime);
    currentTime += increment;
  }

  return timepoints;
};

const Timepoint = styled(P)``;

/**
 * Memoized display of timepoints in the timeline
 */
const Timepoints = memo((props) => {
  const { duration, pixelsPerMs, layersVisible } = props;

  let format = formats[0];
  formats.forEach((f) => {
    if (f.pps < pixelsPerMs) format = f;
  });

  const timepoints = getTimepoints(duration, format);

  return timepoints.map((timepoint) => (
    <TimepointContainer left={pixelsPerMs * timepoint} key={timepoint}>
      <Timepoint>{`${timepoint / 1000}${format.unit}`}</Timepoint>
      <Tic
        left={pixelsPerMs * timepoint}
        height={layersVisible ? '3px' : '20px'}
      />
    </TimepointContainer>
  ));
});

Timepoints.displayName = Timepoints;
Timepoints.propTypes = {
  duration: PropTypes.number.isRequired,
  pixelsPerMs: PropTypes.number.isRequired,
};

export default Timepoints;
