import { useEffect, useRef, useState } from 'react';

export default function (
  src: string
): {
  play: () => void;
  pause: () => void;
  reset: () => void;
  isPlaying: boolean;
} {
  const audio = useRef<HTMLAudioElement>();
  const [isPlaying, setIsPlaying] = useState(false);

  const reset = () => {
    if (audio.current) {
      audio.current.pause();
      audio.current.currentTime = 0;
    }
  };

  useEffect(() => {
    // Initialize audio element
    audio.current = new Audio(src);
    audio.current.onplay = () => {
      setIsPlaying(true);
    };

    audio.current.onpause = () => {
      setIsPlaying(false);
    };

    audio.current.onended = () => {
      setIsPlaying(false);
      reset();
    };
  }, [src]);

  const play = () => {
    if (audio.current) audio.current.play();
  };

  const pause = () => {
    if (audio.current) audio.current.pause();
  };

  return { play, pause, reset, isPlaying };
}
