import styled from 'styled-components/macro';

import React from 'react';

import { useState, useEffect } from 'react';

import fetchUtil from '../../util/fetchUtil';

import Table from '../Table';
import Button from '../Button';

import StackColumn from './errors/StackColumn';

function AdminErrors() {
  const [errors, setErrors] = useState([]);
  const [type, setType] = useState('server');

  useEffect(() => {
    fetchUtil
      .post('/admin/getRecentErrors', {
        days: 10,
      })
      .then((res) => {
        const newErrors = res.errors.sort(
          (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
        );
        setErrors(newErrors);
      });
  }, []);

  const headers = [
    {
      id: 'source',
      value: 'Source',
    },
    {
      id: 'userID',
      value: 'User ID',
      width: 100,
    },
    {
      id: 'userName',
      value: 'Name',
      width: 100,
    },
    {
      id: 'script',
      value: 'Script',
    },
    {
      id: 'message',
      value: 'Message',
    },
    {
      id: 'date',
      value: 'Date',
    },
  ];

  let filteredErrors = errors.filter((e) => e.source.toLowerCase() !== 'js');
  if (type === 'client')
    filteredErrors = errors.filter((e) => e.source.toLowerCase() === 'js');

  const rows = filteredErrors.map((error) => ({
    source: error.source,
    userID: error.userID,
    userName: error.userName,
    script:
      error.info.script || `${error.info.u}:${error.info.l}:${error.info.c}`,
    message: <StackColumn error={error} />,
    date: error.timestamp,
  }));

  return (
    <Container>
      <ButtonContainer>
        <Button
          onClick={() => setType('server')}
          box
          basic={type !== 'server'}
          thin
          className={type === 'server' && 'blue'}
        >
          Server
        </Button>
        <Button
          onClick={() => setType('client')}
          box
          basic={type !== 'client'}
          thin
          className={type === 'client' && 'blue'}
        >
          Client
        </Button>
      </ButtonContainer>
      <Table headers={headers} rows={rows} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  padding: 10px;

  > * {
    margin-right: 10px;
  }
`;

AdminErrors.propTypes = {};

AdminErrors.defaultProps = {};

export default AdminErrors;
