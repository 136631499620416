import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import React, { useState, useEffect } from 'react';

import { AnimatePresence } from 'framer-motion';

import { faEdit } from '@fortawesome/pro-duotone-svg-icons';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import fetchUtil, { post } from '../../../util/fetchUtil';

import roles from '../../../shared/roles';

import InputConfirm from '../../InputConfirm';
import Button from '../../Button';
import Message from '../../Message';
import Box, { Flex } from '../../Box';
import OptionSelector from '../../OptionSelector';

import { TagContainer, TagText } from '../../views/ProfileStyles';

import { H4, H3 } from '../../styles/typography';
import { colors } from '../../styles/colors';

const Container = styled.div``;
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;
const Section = styled.div`
  margin-bottom: 15px;
`;
const SectionValue = styled(H3)`
  max-width: 300px;
`;
const SectionHeader = styled(H4)`
  color: ${colors['light-grey-30']};
  margin-bottom: 10px;
`;

const EditButton = styled(Button)`
  width: 1.5em;
  height: 1.5em;

  font-size: 14px;

  margin-left: 10px;
`;

function UserInfoDialog(props) {
  const { userID, updateUsers } = props;

  const [user, setUser] = useState({});
  const [newDegree, setNewDegree] = useState('');

  const [isEditingEmail, setIsEditingEmail] = useState(false);

  const [newEmail, setNewEmail] = useState(user.email);
  const [slackId, setSlackId] = useState('');
  const [isEditingSlackId, setIsEditingSlackId] = useState(false);

  const getUser = async () => {
    const res = await fetchUtil.post('/admin/getUser', { userID });

    setUser(res.user);
    setNewEmail(res.user.email);
    const { slackId: oldSlackId } = res.user.data;
    if (oldSlackId) {
      setSlackId(oldSlackId);
    } else {
      setIsEditingSlackId(true);
    }
  };

  const handleSave = async () => {
    await fetchUtil.post('/admin/updateUserEmail', {
      userID: user.userID,
      email: newEmail,
    });

    setIsEditingEmail(false);
    updateUsers();
    getUser();
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  const handleDeactivateUser = async () => {
    await fetchUtil.post('/admin/userToggleActive', {
      userID: user.userID,
    });

    getUser();
  };

  const handleRemoveRole = (role) => {
    fetchUtil
      .post('/admin/removeUserRole', {
        role,
        userID: user.userID,
      })
      .then(() => {
        getUser();
        updateUsers();
      });
  };

  const handleSelectRole = (role) => {
    fetchUtil
      .post('/admin/addUserRole', {
        role: role.name,
        userID: user.userID,
      })
      .then(() => {
        getUser();
        updateUsers();
      });
  };

  const handleAddSlackId = async () => {
    await post('/admin/addSlackUserId', {
      slackId,
      userID: user.userID,
    });

    setIsEditingSlackId(false);
  };

  const handleAddDegree = () => {
    const newDegrees = user.data.degrees || [];
    newDegrees.push(newDegree);

    fetchUtil
      .post('/admin/updateUserDegrees', {
        degrees: newDegrees,
        userID: user.userID,
      })
      .then(() => {
        getUser();
        updateUsers();
      });
  };

  const handleRemoveDegree = (degree) => {
    const newDegrees = user.data.degrees || [];
    newDegrees.splice(
      user.data.degrees.findIndex((d) => d === degree),
      1
    );

    fetchUtil
      .post('/admin/updateUserDegrees', {
        degrees: newDegrees,
        userID: user.userID,
      })
      .then(() => {
        getUser();
        updateUsers();
      });
  };

  const userRoles =
    user.data &&
    user.data.roles &&
    user.data.roles.sort().map((role) => (
      <TagContainer
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, scale: 0.5, transition: { easing: 'easeOut' } }}
        key={role}
      >
        <TagText>{role}</TagText>
        <Button
          className="icon transparent thin"
          onClick={() => handleRemoveRole(role)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </TagContainer>
    ));

  const degreeComponents =
    user.data &&
    user.data.degrees &&
    user.data.degrees.map((degree) => (
      <TagContainer
        key={degree}
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, scale: 0.5 }}
      >
        <TagText>{degree}</TagText>
        <Button
          className="icon transparent thin"
          onClick={() => handleRemoveDegree(degree)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </TagContainer>
    ));

  const availableRoles = roles
    .filter(
      (role) =>
        user.data && user.data.roles && !user.data.roles.find((r) => r === role)
    )
    .map((role) => ({ name: role.taskName, id: role.taskID }));

  return (
    <Container>
      {user.data && user.data.deactivated && (
        <Message style={{ marginBottom: 20, width: '100%' }} negative>
          <Flex c style={{ flexDirection: 'column', width: '100%' }}>
            <Box style={{ marginBottom: 10 }}>
              This user&apos;s account is deactivated
            </Box>
            <Button thin box basic color="red" onClick={handleDeactivateUser}>
              Reactivate
            </Button>
          </Flex>
        </Message>
      )}
      <Section>
        <SectionHeader>Given name</SectionHeader>
        <FlexContainer>
          <SectionValue>{user.givenName}</SectionValue>
        </FlexContainer>
      </Section>
      <Section>
        <SectionHeader>Family name</SectionHeader>
        <FlexContainer>
          <SectionValue>{user.familyName}</SectionValue>
        </FlexContainer>
      </Section>
      <Section>
        <SectionHeader>Email</SectionHeader>
        {!isEditingEmail && (
          <FlexContainer>
            <SectionValue>{user.email}</SectionValue>
            <EditButton
              className="icon transparent"
              onClick={() => setIsEditingEmail(!isEditingEmail)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </EditButton>
          </FlexContainer>
        )}
        {isEditingEmail && (
          <InputConfirm
            handleInputChange={(e) => setNewEmail(e.target.value)}
            handleSaveClick={() => handleSave()}
            handleCancelClick={() => setIsEditingEmail(!isEditingEmail)}
            enableSave={newEmail !== user.email}
            cancelButtonVisible={!!newEmail}
            placeholder={user.email}
            inputValue={newEmail}
          />
        )}
      </Section>
      <Section>
        <SectionHeader>Degrees</SectionHeader>
        <SectionValue>
          <AnimatePresence>{degreeComponents}</AnimatePresence>
          <InputConfirm
            handleInputChange={(e) => setNewDegree(e.target.value)}
            handleSaveClick={() => handleAddDegree()}
            cancelButtonVisible={false}
            placeholder="Add new degree..."
            inputValue={newDegree}
          />
        </SectionValue>
      </Section>
      <Section>
        <SectionHeader>Roles</SectionHeader>
        <SectionValue>
          <AnimatePresence>{userRoles}</AnimatePresence>
          <OptionSelector
            options={availableRoles}
            onSelectOption={handleSelectRole}
            buttonClasses="box basic"
            saveSelection={false}
            placeholder="Add role"
          />
        </SectionValue>
      </Section>
      <Section>
        <SectionHeader>Slack ID</SectionHeader>
        <SectionValue>
          {isEditingSlackId && (
            <InputConfirm
              handleInputChange={(e) => setSlackId(e.target.value)}
              handleSaveClick={handleAddSlackId}
              placeholder="Add slack user id..."
              inputValue={slackId}
              saveButtonProps={{ 'data-testid': 'confirm-button' }}
            />
          )}
          {!isEditingSlackId && <span data-testid="slack-id">{slackId}</span>}
          {!isEditingSlackId && (
            <Box data-testid="edit-slack-button" display="inline">
              <EditButton
                className="icon transparent"
                onClick={() => setIsEditingSlackId(!isEditingEmail)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </EditButton>
            </Box>
          )}
        </SectionValue>
      </Section>
      <Section>
        {user.data && !user.data.deactivated && (
          <Button box basic color="red" onClick={handleDeactivateUser}>
            Deactivate
          </Button>
        )}
      </Section>
    </Container>
  );
}

const { number, func } = PropTypes;

UserInfoDialog.propTypes = {
  userID: number.isRequired,
  updateUsers: func.isRequired,
};

UserInfoDialog.defaultPropss = {};

export default UserInfoDialog;
