import styled from 'styled-components/macro';

import React, { ReactNode } from 'react';

import { colors, lighten, darken } from '../styles/colors';

type Route = {
  component: ReactNode;
  icon: string;
  name: string;
  value: string;
  id: string;
};

type Props = {
  route: Route;
  isAllowed: boolean;
  availablePermissions: string[];
};

export default function AdminContent(props: Props) {
  const { route, isAllowed } = props;

  let activeComponent;

  // Get route if user has permission or is root
  if (isAllowed) {
    activeComponent = route.component;
  } else {
    // If user doesn't have permission for this route
    activeComponent = (
      <PermissionsMessage>
        <span role="img" aria-label="X">
          🙅
        </span>{' '}
        Sorry, you don&apos;t have permission to view {route.name}.{' '}
        <span role="img" aria-label="X">
          🙅
        </span>
      </PermissionsMessage>
    );
  }

  return <Container>{activeComponent}</Container>;
}

const Container = styled.div`
  flex: 4;
  overflow: auto;
`;

const PermissionsMessage = styled.div`
  font-size: 24px;

  background: ${lighten(colors.red, 40)};
  color: ${darken(colors.red, 10)};
  padding: 30px;

  border-radius: 3px;
  margin: 15px;
`;
